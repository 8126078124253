import React from 'react';

import { SellerTypeRender } from 'components/ListingDetail/SellerTypeRender';

import { ListingForSale } from 'lib/types';

import { AcceptListingTerms } from './AcceptListingTerms';
import { DownloadInfoMemoAction } from './DownloadInfoMemoAction';
import { RequestFullDetailsAction } from './RequestFullDetailsAction';

export const ListingActions = ({ listing }: { listing?: ListingForSale }) => {
  if (!listing) {
    return null;
  }

  return (
    <>
      <RequestFullDetailsAction listing={listing} />
      <SellerTypeRender.Broker sellerType={listing?.sellerType}>
        <>
          <AcceptListingTerms listing={listing} />
        </>
      </SellerTypeRender.Broker>
      <SellerTypeRender.Proprietary sellerType={listing?.sellerType}>
        <>
          <AcceptListingTerms listing={listing} />
          <DownloadInfoMemoAction listing={listing} />
        </>
      </SellerTypeRender.Proprietary>
    </>
  );
};
