import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts';

import { IValuationHistogramData } from 'components/ValuationCard/ValuationCard.interface';

import { IHistogramChartProps } from './HistogramChart.interface';
import { chartConfig } from './HistogramChart.utils';
import { MultipleRangeTooltip } from './MultipleRangeTooltip';
import { useHistogramChart } from './useHistogramChart';

export const HistogramChart = ({
  data,
  multiple,
  businessTypeName,
}: IHistogramChartProps) => {
  const { getMatchedIntervalColor, xAxisTicks, renderCustomTick } =
    useHistogramChart(data);

  return (
    <ResponsiveContainer width="100%" height={chartConfig.chartHeight}>
      <BarChart data={data} margin={chartConfig.chartMargin}>
        <CartesianGrid
          vertical={false}
          strokeWidth={chartConfig.cartesianGridStrokeWidth}
          strokeDasharray={chartConfig.cartesianGridStrokeDasharray}
          stroke={chartConfig.cartesianGridStrokeColor}
        />
        <XAxis
          dataKey="floor"
          interval="preserveStartEnd"
          axisLine={false}
          tickLine={false}
          ticks={xAxisTicks}
          tick={renderCustomTick}
        />
        <Tooltip
          cursor={{ fill: 'transparent' }}
          wrapperStyle={{ outline: 'none' }}
          content={
            <MultipleRangeTooltip
              multiple={multiple}
              businessTypeName={businessTypeName}
            />
          }
        />
        <Bar dataKey="count" barSize={chartConfig.barWidth}>
          {data.map((item: IValuationHistogramData, index: number) => (
            <Cell
              key={`${item.count}_${index}`}
              fill={getMatchedIntervalColor(item.match)}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
