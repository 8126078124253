import { useTranslation } from 'react-i18next';

import { ContentCell } from 'ui/ContentCell';

import { DocumentsDetails } from 'components/DocumentsDetails';
import { useSubmitForEvaluationError } from 'components/SubmitForEvaluation';

import { LoanDocumentType } from 'lib/types';

import { BasicInformationDetailsPropsWithoutLoan } from './BasicInformationDetails.interface';
import { useDocumentsProps } from './useDocumentsProps';

export const BasicInformationCohorts = ({
  documents,
  requiredDocuments,
  editable = true,
  showIcon = true,
  path,
}: Exclude<BasicInformationDetailsPropsWithoutLoan, 'paths'> & {
  path: string;
}) => {
  const { t } = useTranslation();

  const { cohorts } = useSubmitForEvaluationError();

  const errorMessage = cohorts
    ? t('BasicInformationDetails:cohorts_required')
    : undefined;

  const {
    visible: cohortsVisible,
    contentCellProps,
    hasValue,
  } = useDocumentsProps(
    { documents, requiredDocuments },
    {
      editable,
      documentType: LoanDocumentType.cohorts,
      baseRoute: path,
      error: errorMessage,
    }
  );

  const children = hasValue ? (
    <DocumentsDetails
      documents={documents}
      documentType={LoanDocumentType.cohorts}
    />
  ) : (
    errorMessage
  );

  if (!cohortsVisible) return null;

  return (
    <ContentCell
      title={t('BasicInformationDetails:cohorts')}
      data-testid="cohorts"
      showIcon={showIcon}
      {...contentCellProps}
    >
      {children}
    </ContentCell>
  );
};
