import { ContentCellDetail } from 'ui/ContentCellDetail';

import { TermSheetLayout } from 'components/TermSheet/TermSheet.styles';
import { TermSheetDownload } from 'components/TermSheet/TermSheetDownload';

export interface IRblTermSheetColumns {
  literals: any;
  maxTermsFormatted: string;
  maxRoyaltyFormatted: string;
  openingFeeFormatted: string;
  loanId?: string;
  loanTitle?: string;

  blur?: boolean;
  bordered?: boolean;
}

export const RblTermSheetColumns = ({
  literals,
  maxTermsFormatted,
  maxRoyaltyFormatted,
  openingFeeFormatted,
  loanTitle,
  loanId,
  blur,
  bordered,
}: IRblTermSheetColumns) => {
  return (
    <>
      <TermSheetLayout.Column>
        <ContentCellDetail.Label>{literals.maxTerms}</ContentCellDetail.Label>
        <ContentCellDetail.Value>{maxTermsFormatted}</ContentCellDetail.Value>
      </TermSheetLayout.Column>
      <TermSheetLayout.Column>
        <ContentCellDetail.Label>{literals.openingFee}</ContentCellDetail.Label>
        <ContentCellDetail.Value>{openingFeeFormatted}</ContentCellDetail.Value>
      </TermSheetLayout.Column>
      <TermSheetLayout.Column bordered={bordered}>
        <ContentCellDetail.Label>{literals.maxRoyalty}</ContentCellDetail.Label>
        <ContentCellDetail.Value>{maxRoyaltyFormatted}</ContentCellDetail.Value>
      </TermSheetLayout.Column>
      <TermSheetLayout.Column blur={blur}>
        <ContentCellDetail.Label>{literals.termSheet}</ContentCellDetail.Label>
        <TermSheetDownload loanId={loanId} title={loanTitle} blur={blur} />
      </TermSheetLayout.Column>
    </>
  );
};
