import * as Sentry from '@sentry/react';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Button } from '@boopos/design-system';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAccountConnectorsStatus } from 'components/AccountConnectors/AccountConnectors.hooks';
import { FullScreenLoader } from 'components/FullScreenLoader';
import { ScreenWrapperContentCentered } from 'components/ScreenLayout';

import { useEffectOnce } from 'lib/hooks/useEffectOnce';

import { IconWrapper, TitleWrapper } from './StripeCallbackScreen.styles';

export const StripeCallbackScreen = () => {
  const { addError } = useAccountConnectorsStatus();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [params] = useSearchParams();
  const { t } = useTranslation();

  const stripeParams = useMemo(() => {
    return params ? Object.fromEntries(params) : undefined;
  }, [params]);

  const handleClose = useCallback(() => {
    window.close();
  }, []);

  const handleStripeCallback = useCallback(
    async (handleStripeParams: any) => {
      if (!window.opener?.stripeCallback || !handleStripeParams) {
        setError(true);
        addError();
        return;
      }

      try {
        await window.opener.stripeCallback(handleStripeParams);
        setSuccess(true);
      } catch (err) {
        setError(true);
        addError();
        Sentry.captureException(err);
      }
    },
    [addError]
  );

  useEffectOnce(() => {
    (async () => {
      await handleStripeCallback(stripeParams?.code);
    })();
  });

  const ErrorFeedback = (
    <>
      <IconWrapper variant="error" data-testid="error-icon">
        <FontAwesomeIcon icon={solid('circle-x')} size="2xl" />
      </IconWrapper>
      <TitleWrapper>{t('StripeCallbackScreen:error_first')}</TitleWrapper>
      <div>{t('StripeCallbackScreen:error_second')}</div>
    </>
  );

  const SuccessFeedback = (
    <>
      <IconWrapper variant="success" data-testid="success-icon">
        <FontAwesomeIcon icon={solid('circle-check')} size="2xl" />
      </IconWrapper>
      <TitleWrapper>{t('StripeCallbackScreen:success')}</TitleWrapper>

      <Button
        variant="secondary"
        fullWidth
        onClick={handleClose}
        css={{ maxWidth: '32rem' }}
      >
        {t('StripeCallbackScreen:close')}
      </Button>
    </>
  );

  if (error || success) {
    return (
      <ScreenWrapperContentCentered>
        {error && ErrorFeedback}
        {success && SuccessFeedback}
      </ScreenWrapperContentCentered>
    );
  }
  return <FullScreenLoader />;
};
