import { useTranslation } from 'react-i18next';

import { DealTimelineStep } from 'components/DealTimelineStep';

import { PastActivityProps } from './PastActivity.interface';

export const BusinessPreApprovedActivity = ({
  children,
  date,
  ...rest
}: PastActivityProps) => {
  const { t } = useTranslation();

  return (
    <DealTimelineStep
      title={t('DealTimelineStep:business_evaluated')}
      date={date}
      data-testid="business-pre-approved-activity"
      {...rest}
    >
      {children}
    </DealTimelineStep>
  );
};
