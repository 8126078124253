import qs from 'query-string';
import React, { useLayoutEffect } from 'react';

import { FullScreenLoader } from 'components/FullScreenLoader';

import { useUnload } from 'lib/hooks/useUnload';

export const ErpCallbackScreen = () => {
  useUnload(() => {
    window.opener.erpCallback?.();
  });

  useLayoutEffect(() => {
    const params = qs.parseUrl(window.location.href);
    //@ts-ignore
    if (params.query.statusCode === '403') window.opener.erpCallback = null;
    window.close();
  }, []);

  return <FullScreenLoader />;
};
