import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Input, Text, Title } from '@boopos/design-system';

import { ConnectionStepWrapper } from 'components/AccountConnectionLayout';

import { IConnectShopifyStepOne } from './ConnectShopifyStepOne.interface';
import { Hint, InputWithHints } from './ConnectShopifyStepOne.styles';

export const ConnectShopifyStepOne: FC<IConnectShopifyStepOne> = ({
  onSubmit,
}) => {
  const { register, handleSubmit } = useForm<{ storeName: string }>();
  const { t } = useTranslation();

  return (
    <ConnectionStepWrapper data-testid="connect-step-1">
      <form onSubmit={handleSubmit(onSubmit)} data-testid="connect-step-1-form">
        <Title
          css={{
            fontSize: '$4',
            textAlign: 'center',
          }}
        >
          {t('ConnectShopifyScreen:title')}
        </Title>
        <Text
          css={{
            textAlign: 'center',
            mt: '$2',
          }}
        >
          {t('ConnectShopifyScreen:shopify_subdomain')}
        </Text>
        <InputWithHints>
          <Hint>https://</Hint>
          <Input
            {...register('storeName')}
            wrapperCss={{ m: 0, p: 0 }}
            placeholder="example"
            data-testid="store-name-input"
          />
          <Hint>.myshopify.com</Hint>
        </InputWithHints>
        <Button fullWidth data-testid="button-next">
          {t('ConnectShopifyScreen:connect_shopify')}
        </Button>
      </form>
    </ConnectionStepWrapper>
  );
};
