import { useMemo } from 'react';
import useSWR from 'swr';

import {
  BenchmarkData,
  IApiBusinessScores,
  ScoreTypesEnum,
} from 'components/BusinessInsights';

import api from 'lib/api';
import { BusinessTypesEnum, IBusinessInsights } from 'lib/types';

import { getPreviewConfig } from './getPreviewConfig';

const benchmarks = Object.keys(ScoreTypesEnum).filter((key) => key);

export interface UseBuyerInsightsData {
  businessInsights?: IBusinessInsights;
  businessId?: string;
}

export const fakeBenchmarkData = {
  value: 20,
  min: 0,
  max: 100,
  avg: 50,
  diff: 0,
  percentage: false,
  percentile: 50,
};

const getScore = (
  type: ScoreTypesEnum,
  {
    locked,
    businessScores,
  }: { locked: boolean; businessScores?: IApiBusinessScores }
) => {
  if (locked) {
    return fakeBenchmarkData;
  }

  return businessScores?.scores?.[type];
};

export const useBuyerInsightsData = ({
  businessInsights,
  businessId,
}: UseBuyerInsightsData) => {
  const businessInsightsId = businessInsights?.id;

  const { locked } = getPreviewConfig({
    businessInsights,
  });

  const { data: businessScores } = useSWR<IApiBusinessScores>(
    !locked &&
      !!businessInsightsId &&
      !!businessId &&
      api.getBusinessInsightsScores(businessInsightsId, businessId)
  );

  const businessType = businessInsights?.scores?.type as BusinessTypesEnum;

  const benchmarkScores = useMemo(
    () =>
      benchmarks.reduce(
        (acc, type) => ({
          ...acc,
          [type]: getScore(type as ScoreTypesEnum, {
            locked,
            businessScores,
          }),
        }),
        {}
      ),
    [businessScores, locked]
  );

  const analysisDate = businessInsights?.scores?.analysisDate;

  return {
    locked,
    businessType,
    benchmarkScores: benchmarkScores as Record<ScoreTypesEnum, BenchmarkData>,
    price: businessInsights?.price,
    analysisDate,
  };
};
