import { Styles } from './InsightsComparisonTable.styles';

export const InsightsComparisonTable = {
  Table: Styles.Table,
  Row: Styles.Row,
  Th: Styles.Th,
  Td: Styles.Td,
  Indicator: Styles.Indicator,
  Badge: Styles.Badge,
};
