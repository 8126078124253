import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { mutate } from 'swr';

import { AcceptTermSheet } from 'components/DealTimeline/AcceptTermSheet';

import { apiV2 } from 'lib/api';
import { ListingForSale } from 'lib/types';

export const AcceptListingTerms = ({
  listing,
}: {
  listing: ListingForSale;
}) => {
  const { t } = useTranslation();
  const handleAcceptedTermSheet = useCallback(async () => {
    await mutate(apiV2.getListing(listing.id));
  }, [listing]);

  if (listing.dealApplication?.termsAcceptedAt) {
    return null;
  }

  if (!listing?.dealApplication?.id) {
    return null;
  }

  return (
    <AcceptTermSheet
      loanId={listing.dealApplication.id}
      termSheet={listing.dealApplication?.termSheet}
      sellerEmail={listing?.dealApplication?.sellerEmail}
      ready={!!listing}
      onSuccess={handleAcceptedTermSheet}
    >
      {t('AcceptListingTerms:start')}
    </AcceptTermSheet>
  );
};
