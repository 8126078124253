import { formatMoneyWithoutSymbol, getCurrencySymbol } from 'lib/format';
import { Money } from 'lib/types';

import { CurrencyValueStyles } from './CurrencyValue.styles';

export const CurrencyValue = ({ value }: { value: Money }) => {
  return (
    <CurrencyValueStyles.Wrapper>
      <CurrencyValueStyles.CurrencyValue>
        {getCurrencySymbol(value)}
        {formatMoneyWithoutSymbol(value)}
      </CurrencyValueStyles.CurrencyValue>
    </CurrencyValueStyles.Wrapper>
  );
};
