import { atomWithStorage } from 'jotai/utils';

import {
  BasePersistenceAtom,
  getPersistenceKey,
} from 'components/ClosingChecklistPersistence';

import { IFundsBankAccountData } from './FundsBankAccount.interface';

export const fundsBankAccountAtom = atomWithStorage<
  BasePersistenceAtom<IFundsBankAccountData>
>(getPersistenceKey('fundsBankAccount'), {});
