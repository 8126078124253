import React from 'react';

import { DealDetail } from 'components/DealDetail';
import { ListingActions } from 'components/ForSaleTimeline/ListingActions';

import { ListingForSale } from 'lib/types';

import { BuySmarterTips } from './BuySmarterTips';
import { WhatsNext } from './WhatsNext';

export const ForSaleSidebar = ({ listing }: { listing?: ListingForSale }) => {
  if (!listing) {
    return null;
  }

  return (
    <DealDetail.SideWrapper showInDesktop={true}>
      <DealDetail.ActionsWrapper>
        <ListingActions listing={listing} />
      </DealDetail.ActionsWrapper>
      <DealDetail.TextWrapper>
        <WhatsNext listing={listing} />
        <BuySmarterTips listing={listing} />
      </DealDetail.TextWrapper>
    </DealDetail.SideWrapper>
  );
};
