import { useCallback, useMemo, useState } from 'react';

import shopifyLogo from 'assets/account-providers/shopify-logo.png';

import { ProviderLogo } from 'components/AccountConnectionLayout';
import { ScreenWrapperContentCentered } from 'components/ScreenLayout';
import { ConnectShopifyStepOne } from 'components/ShopifyConnector/ConnectShopifyStepOne';
import { ConnectShopifyStepTwo } from 'components/ShopifyConnector/ConnectShopifyStepTwo';

export const ShopifyConnector = () => {
  const [storeName, setStoreName] = useState('');
  const [currentStep, setCurrentStep] = useState(0);

  const onFormOneSubmit = useCallback(
    ({ storeName }: { storeName: string }) => {
      if (storeName) {
        setStoreName(storeName);
        setCurrentStep(1);
      }
    },
    []
  );

  const onFormTwoSubmit = useCallback(
    ({ apiToken, apiKey }: { apiToken: string; apiKey: string }) => {
      const generatedPublicToken = [storeName, apiToken, apiKey].join(';');
      window.opener.customCallback(generatedPublicToken);
      window.close();
    },
    [storeName]
  );

  const steps = useMemo(
    () => ({
      0: <ConnectShopifyStepOne onSubmit={onFormOneSubmit} />,
      1: (
        <ConnectShopifyStepTwo
          onSubmit={onFormTwoSubmit}
          storeName={storeName}
        />
      ),
    }),
    [storeName, onFormOneSubmit, onFormTwoSubmit]
  );

  return (
    <ScreenWrapperContentCentered
      data-testid="shopify-connector"
      css={{
        justifyContent: 'unset',
      }}
    >
      <ProviderLogo src={shopifyLogo} alt="Shopify logo" />
      {steps[currentStep as keyof typeof steps]}
    </ScreenWrapperContentCentered>
  );
};
