import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContentCell } from 'ui/ContentCell';

import { TermSheetDetails } from 'components/DealTimeline/TermSheetDetails';
import { SellerTypeRender } from 'components/ListingDetail/SellerTypeRender';

import { ListingForSale } from 'lib/types';

import { ListingUnlockedBusinessDetails } from './ListingUnlockedBusinessDetails';

export interface IListingUnlockedProps {
  listing: ListingForSale;
}

export const ListingUnlocked = ({
  listing,
  showTerms = true,
}: {
  listing: ListingForSale;
  showTerms?: boolean;
}) => {
  const { t } = useTranslation();

  if (!listing.dealApplication?.grantedAt) {
    return null;
  }

  return (
    <>
      <SellerTypeRender.Broker sellerType={listing?.sellerType}>
        <>
          <ContentCell.Divider>
            {t('ListingUnlocked:business_details_title')}
          </ContentCell.Divider>
          <ListingUnlockedBusinessDetails
            cohortsFiles={listing?.cohortsFiles?.value}
            profitAndLossFiles={listing?.profitAndLossFiles?.value}
            businessUrl={listing?.businessUrl?.value}
            sellerEmail={listing?.dealApplication?.sellerEmail}
          />
        </>
      </SellerTypeRender.Broker>
      {showTerms && (
        <TermSheetDetails
          termSheet={listing.dealApplication?.termSheet}
          id={listing.dealApplication.id}
          title={listing.title}
        />
      )}
    </>
  );
};
