import { PortfolioApplicationType } from 'components/PortfolioApplication/PortfolioApplicationFlow';

import { PortfolioApplicationAddBusiness } from './PortfolioApplicationAddBusiness';

export const PortfolioApplicationSell = () => {
  return (
    <PortfolioApplicationAddBusiness
      applicationType={PortfolioApplicationType.sell}
    />
  );
};
