import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonFeedback } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DetailCell } from 'ui/DetailCell';

import { DeleteConfirmationAction } from 'components/ConfirmationModal';

import { AccountIcon } from './AccountIcon';
import { AccountName } from './AccountName';
import { Props } from './ConnectedAccountsDetailCell.interface';
import { AccountStyles } from './Styles';

export const ConnectedAccountDetailCell = ({
  account,
  onDisconnectAccount,
}: Props) => {
  const { t } = useTranslation();

  const [deleting, setDeleting] = useState(false);

  const onDelete = useCallback(async () => {
    setDeleting(true);
    await onDisconnectAccount?.(account);
    setDeleting(false);
  }, [account, onDisconnectAccount]);

  return (
    <DetailCell
      as="div"
      icon={<AccountIcon account={account} />}
      title={<AccountName account={account} />}
      key={account.id}
      action={
        <DeleteConfirmationAction
          title={t('PaymentProcessorsModal:delete_confirmation_title')}
          onDelete={onDelete}
        >
          <ButtonFeedback
            rounded
            leftIcon={<FontAwesomeIcon icon={regular('trash')} size="lg" />}
            variant="nude"
            data-testid="delete-connection"
            size="reduced"
            isLoading={deleting}
          />
        </DeleteConfirmationAction>
      }
    >
      <AccountStyles.StatusMessage>
        <AccountStyles.Pulse /> {t('PaymentProcessorsModal:connected')}
      </AccountStyles.StatusMessage>
    </DetailCell>
  );
};
