import { Outlet } from 'react-router-dom';

import { SlateBackgroundBody } from 'components/GlobalStyles';

import { HSWidgetHide } from 'lib/hubspot';

import { routePaths } from 'router/routePaths';

import {
  ProfileBuyScreen,
  ProfileSellScreen,
} from 'screens/OnboardingProfileScreen';
import { OnboardingOutlet, OnboardingScreen } from 'screens/OnboardingScreen';

import { generateSellerApplicationRoutes } from './sellerApplicationRoutes';

export const onboardingRoutes = {
  path: routePaths.onboarding,
  element: (
    <>
      <SlateBackgroundBody />
      <HSWidgetHide />
      <Outlet />
    </>
  ),
  children: [
    {
      path: '',
      element: <OnboardingOutlet />,
      children: [
        {
          index: true,
          element: <OnboardingScreen />,
        },
        {
          path: routePaths.onboardingProfileBuy,
          element: <ProfileBuyScreen />,
        },
        {
          path: routePaths.onboardingProfileSell,
          element: <ProfileSellScreen />,
        },
        ...generateSellerApplicationRoutes(
          routePaths.onboardingSellerApplication
        ),
      ],
    },
  ],
};
