import React from 'react';

import { PageHeader } from 'ui/PageHeader';

import { LoadingElements } from 'components/DealDetail';
import { ForSaleFavouriteButton } from 'components/ForSaleDealFlowButton';

import { ListingForSale } from 'lib/types';

import { HeaderBackButton } from './HeaderBackButton';

export const ForSalePageHeader = ({
  listing,
  onSuccess,
}: {
  listing?: ListingForSale;
  onSuccess?: () => Promise<void>;
}) => {
  const action = listing && (
    <ForSaleFavouriteButton listing={listing} onSuccess={onSuccess} />
  );

  return (
    <PageHeader
      title={listing ? listing?.title : <LoadingElements.Title />}
      childrenTop={<HeaderBackButton />}
      action={action}
      data-testid="for-sale-page-header"
    />
  );
};
