import { useCallback, useMemo } from 'react';

import { ICategory } from 'lib/types';

import { useBusinessCategories } from './useBusinessCategories';

export const useBusinessCategoriesHandler = (category?: ICategory) => {
  const categoryId = category?.id as string;

  const { categories, setCategories } = useBusinessCategories(
    category?.id as string
  );

  const existingCategories = useMemo(() => {
    return category?.categories?.map((cat) => cat.id) ?? [];
  }, [category]);

  const selectAll = useCallback(
    (selectedId: string) => {
      const newCategories = categories.includes(selectedId) ? [] : [selectedId];
      setCategories(newCategories);
    },
    [setCategories, categories]
  );

  const selectWithAllSelected = useCallback(
    (selectedId: string) => {
      const allCategoriesWithoutSelected =
        existingCategories.filter((cat) => cat !== selectedId) ?? [];

      setCategories(allCategoriesWithoutSelected);
    },
    [existingCategories, setCategories]
  );

  const selectCategory = useCallback(
    (selectedId: string) => {
      const newCategories = categories.includes(selectedId)
        ? categories.filter((id) => id !== selectedId)
        : [...categories, selectedId];

      if (newCategories.length === existingCategories.length) {
        selectAll(categoryId);
        return;
      }

      setCategories(newCategories);
    },
    [setCategories, categories, categoryId, selectAll, existingCategories]
  );

  const handleSelect = useCallback(
    (selectedId: string | null) => {
      if (!selectedId || !categoryId) {
        return;
      }

      const allIsSelected = selectedId === categoryId;

      // When the user selects the "All" category
      if (allIsSelected) {
        selectAll(selectedId);
        return;
      }

      // When the user selects any category, but the "All" category is selected.
      if (categories.includes(categoryId)) {
        selectWithAllSelected(selectedId);
        return;
      }

      selectCategory(selectedId);
    },
    [categoryId, categories, selectAll, selectWithAllSelected, selectCategory]
  );

  const continueDisabled = !categories?.length;

  return {
    onSelect: handleSelect,
    selectedCategories: categories,
    continueDisabled,
  };
};
