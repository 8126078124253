import { Link } from 'react-router-dom';

import { ContentCell } from 'ui/ContentCell';

import { ClosingStepsNames, ILoanApi } from 'lib/types';

import {
  useStepAction,
  useStepDisabled,
  useStepPath,
  useStepTitle,
  useStepVariant,
} from './ClosingChecklistDetails.hooks';
import { StepPreview } from './StepPreview';

interface ClosingChecklistStepItemProps {
  step: ClosingStepsNames;
  loan: ILoanApi;
  editable?: boolean;
}

export const ClosingChecklistStepItem = ({
  step,
  loan,
  editable = true,
  ...props
}: ClosingChecklistStepItemProps) => {
  const loanId = loan?.id;
  const title = useStepTitle(step);

  const to = useStepPath(step, loanId);
  const disabled = useStepDisabled(step, loan);
  const isActive = !!to && editable && !disabled;

  const variant = useStepVariant(step, loan);
  const action = useStepAction(step, loan, isActive);

  if (!loanId) {
    return null;
  }

  return (
    <ContentCell
      title={title}
      variant={variant}
      action={action}
      active={isActive}
      disabled={disabled}
      {...(isActive && { to, as: Link, preventScrollReset: true })}
      {...props}
    >
      <StepPreview loan={loan} step={step} />
    </ContentCell>
  );
};
