import { useTranslation } from 'react-i18next';
import { NavLink, generatePath, useParams } from 'react-router-dom';

import { TabMenuItem, TabsMenu } from 'ui/TabsMenu';

import { routePaths } from 'router/routePaths';

import { ConnectionsMenuItem } from './ConnectionsMenuItem';
import { EnquiriesMenuItem } from './EnquiriesMenuItem';
import { FinancingMenuItem } from './FinancingMenuItem';
import { useMenuItemsVisibility } from './useMenuItemsVisibility';

export const BusinessDetailTabsMenu = () => {
  const { t } = useTranslation();
  const { businessId } = useParams();

  const { listing, enquiries, insights, loans, connections } =
    useMenuItemsVisibility({ businessId });

  if (
    ![listing, enquiries, insights, loans, connections].some((item) => item)
  ) {
    return null;
  }

  return (
    <TabsMenu
      css={{ mb: '$9', '@bp2': { mx: '$8' } }}
      data-testid="portfolio-menu"
    >
      {listing && (
        <TabMenuItem
          as={NavLink}
          to={generatePath(routePaths.portfolioForSale, {
            businessId,
          })}
          end
        >
          {t('BusinessDetailScreen:tab_menu_for_sale')}
        </TabMenuItem>
      )}
      {enquiries && <EnquiriesMenuItem />}
      {insights && (
        <TabMenuItem
          as={NavLink}
          to={generatePath(routePaths.portfolioValuation, {
            businessId,
          })}
          end
        >
          {t('BusinessDetailScreen:tab_menu_valuation_and_insights')}
        </TabMenuItem>
      )}
      {loans && <FinancingMenuItem />}
      {connections && <ConnectionsMenuItem />}
    </TabsMenu>
  );
};
