import { AnimatePresence } from 'framer-motion';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  ButtonFeedback,
  Input,
  createToastFeedback,
} from '@boopos/design-system';

import {
  ActionSideModalCommonStyles,
  bottomNavAnimationVariants,
} from 'components/ActionSideModal';
import { FormFilesUploader } from 'components/FormFilesUploader';

import { ILoanApi } from 'lib/types';

import { AdditionalDetailsModalForm } from './AdditionalDetailsModal.interface';
import { useLoanAdditionalDetails } from './useLoanAdditionalDetails';

export interface LoanAdditionalInfoFormProps {
  loan: ILoanApi;
  onSuccess: () => Promise<void> | void;
}
export const LoanAdditionalInfoForm = ({
  loan,
  onSuccess,
}: LoanAdditionalInfoFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { t } = useTranslation();
  const { id: loanId } = loan;

  const { defaultValues, saveAdditionalDetails } = useLoanAdditionalDetails({
    loan,
  });

  const { handleSubmit, register, control } =
    useForm<AdditionalDetailsModalForm>({
      defaultValues,
    });

  const onSubmit = useCallback(
    async (data: AdditionalDetailsModalForm) => {
      setIsSubmitting(true);

      try {
        await saveAdditionalDetails(data);

        setIsSubmitting(false);
        setIsSuccess(true);
        await onSuccess?.();
      } catch (err) {
        setIsSubmitting(false);
        createToastFeedback({
          type: 'error',
          title: t('AdditionalDetailsModal:save_error'),
        });
      }
    },
    [onSuccess, saveAdditionalDetails, t]
  );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      data-testid="loan-additional-info-form"
    >
      <Input
        label={t('AdditionalDetailsModal:extra_info_label')}
        css={{ minHeight: 110, resize: 'vertical', maxHeight: 250, mb: '$6' }}
        as="textarea"
        {...register('additionalDetails')}
      />

      <FormFilesUploader
        title={t('AdditionalDetailsModal:extra_files_label')}
        control={control}
        rules={{ required: false }}
        name="additionalDetailsFiles"
        maxFiles={5}
        uploadParams={{ loanId }}
      />
      <AnimatePresence>
        <ActionSideModalCommonStyles.BottomNav
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={bottomNavAnimationVariants}
        >
          <ActionSideModalCommonStyles.BottomNavLeftText />
          <ButtonFeedback
            isLoading={isSubmitting}
            isSuccess={isSuccess}
            data-testid="loan-additional-info-form-submit"
          >
            {t('AdditionalDetailsModal:save_action')}
          </ButtonFeedback>
        </ActionSideModalCommonStyles.BottomNav>
      </AnimatePresence>
    </form>
  );
};
