import { useCallback } from 'react';

import { Merchants } from 'components/AccountConnectors';
import { useBankLink } from 'components/AccountConnectors/bank/useBankLink';

import Api from 'lib/api';
import { IBusinessAccount } from 'lib/types';

export interface IRevenueBankAccountPlaidProps {
  businessId: string;
  accounts?: IBusinessAccount[];
  onSuccess?: () => Promise<void>;
}

export const useRevenueBankAccountPlaid = ({
  onSuccess,
  businessId,
  accounts,
}: IRevenueBankAccountPlaidProps) => {
  const handleSuccess = useCallback(async () => {
    await onSuccess?.();
  }, [onSuccess]);

  const disconnectAccount = useCallback(
    async (externalId: string) => {
      await Api.merchantsDisconnectAccount({
        provider: Merchants.bank,
        businessId,
        externalId,
      });
      await onSuccess?.();
    },
    [businessId, onSuccess]
  );

  const { open, interacting } = useBankLink({
    provider: Merchants.bank,
    businessId,
    onSuccess: handleSuccess,
  });

  const bankAccounts = accounts?.filter(
    (account) => account.provider === Merchants.bank
  );

  return {
    open,
    loading: interacting,
    bankAccounts,
    disconnectAccount,
  };
};
