import qs from 'query-string';

import { IApiFile } from 'lib/types';

export const getFileExtension = (fileName: string) => {
  return fileName.split('.').pop();
};

const getOrdinal = (files: unknown[], index: number) => {
  if (files?.length > 1) {
    return `_${index + 1}`;
  }

  return '';
};

const getDownloadUrl = (downloadUrl: string, fileName: string) => {
  if (!downloadUrl) return downloadUrl;

  const parsed = qs.parseUrl(downloadUrl);

  const query = {
    ...parsed.query,
    filename: fileName,
  };

  return `${parsed.url}?${qs.stringify(query)}`;
};

export const parseFileNames = (
  fileName: () => string,
  files?: IApiFile[]
): IApiFile[] => {
  if (!files) {
    return [];
  }

  return files.map((file, index) => {
    const fileExtension = getFileExtension(file.name);

    const modifiedFileName = `${fileName()}${getOrdinal(
      files,
      index
    )}.${fileExtension}`;

    return {
      ...file,
      name: modifiedFileName,
      downloadUrl: getDownloadUrl(file.downloadUrl, modifiedFileName),
    };
  });
};
