import { useProofOfFundsUpdate } from 'components/ProofOfFunds/useProofOfFundsUpdate';
import {
  ArrowComponentProps,
  UploadZone,
  UploadZoneCustomChildren,
} from 'components/UploadZone';

export const UploadProofOfFundsWrapper = ({
  children,
}: {
  children: (props: ArrowComponentProps) => JSX.Element;
}) => {
  const { uploadDocuments } = useProofOfFundsUpdate();

  return (
    <UploadZone onFilesUploaded={uploadDocuments} maxFiles={5} multiple noClick>
      <UploadZoneCustomChildren>{children}</UploadZoneCustomChildren>
    </UploadZone>
  );
};
