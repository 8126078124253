import { motion } from 'framer-motion';

import { styled } from '@boopos/design-system';

const StepSpacing = {
  small: '3.2rem',
  large: '6.4rem',
};

const TimelineStyles = {
  Step: styled(motion.div, {
    width: '100%',
    display: 'flex',
  }),
  StepIndicatorCircle: styled('div', {
    width: '1.2rem',
    height: '2.4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:before': {
      content: '',
      display: 'block',
      width: '1.2rem',
      height: '1.2rem',
      borderRadius: '50%',
    },
    variants: {
      variant: {
        active: {
          '&:before': {
            backgroundColor: '$slate600',
          },
        },
        inactive: {
          '&:before': {
            backgroundColor: '$slate300',
          },
        },
      },
    },
  }),
  Content: styled('div', {
    flex: 1,
    overflow: 'hidden',
    variants: {
      topSpace: {
        small: {
          paddingTop: StepSpacing.small,
        },
        large: {
          paddingTop: StepSpacing.large,
        },
      },
    },
  }),
  StepIndicator: styled('div', {
    width: '1.2rem',
    mr: '$2',
    position: 'relative',
    '@bp2': {
      mr: '2.4rem',
    },
    variants: {
      topSpace: {
        small: {
          paddingTop: StepSpacing.small,
        },
        large: {
          paddingTop: StepSpacing.large,
        },
      },
      showTopLine: {
        true: {
          '&:before': {
            content: '',
            display: 'block',
            width: '1px',
            left: '6px',
            position: 'absolute',
            top: 0,
            background: '$slate200',
          },
        },
      },
      showBottomLine: {
        true: {
          '&:after': {
            content: '',
            display: 'block',
            width: '1px',
            left: '6px',
            position: 'absolute',
            background: '$slate200',
            bottom: 0,
          },
        },
      },
    },
    defaultVariants: {
      showTopLine: true,
      showBottomLine: true,
    },
    compoundVariants: [
      {
        topSpace: 'small',
        showTopLine: true,
        css: {
          '&:before': {
            height: `calc(${StepSpacing.small} + 6px)`,
          },
        },
      },
      {
        topSpace: 'large',
        showTopLine: true,
        css: {
          '&:before': {
            height: `calc(${StepSpacing.large} + 6px)`,
          },
        },
      },
      {
        topSpace: 'small',
        showBottomLine: true,
        css: {
          '&:after': {
            top: `calc(${StepSpacing.small} + 18px)`,
          },
        },
      },
      {
        topSpace: 'large',
        showBottomLine: true,
        css: {
          '&:after': {
            top: `calc(${StepSpacing.large} + 18px)`,
          },
        },
      },
    ],
  }),
  Time: styled('span', {
    fontSize: '1.4rem',
    lineHeight: '2.4rem',
    display: 'inline-block',
    mb: '$2',
    variants: {
      variant: {
        active: {
          color: '$slate900',
          px: '$2',
          borderRadius: '1.6rem',
          backgroundColor: '$slate200',
        },
        inactive: {
          color: '$slate500',
        },
      },
      errorVariant: {
        error: {
          color: '$red900',
          background: '$red100',
        },
        warning: {
          color: '$yellow900',
          background: '$yellow100',
        },
      },
    },
    defaultVariants: {
      variant: 'inactive',
    },
  }),
  Title: styled('div', {
    fontSize: '1.8rem',
    lineHeight: '2.4rem',
    fontWeight: 700,
    variants: {
      variant: {
        active: {
          color: '$slate900',
        },
        inactive: {
          color: '$slate600',
        },
      },
    },
    defaultVariants: {
      variant: 'inactive',
    },
  }),
  Subtitle: styled('div', {
    mt: '$2',
  }),
  StepContent: styled('div', {
    mt: '$4',
  }),
};

export { TimelineStyles };
