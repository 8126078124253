import { useMatch } from 'react-router-dom';

import { generatePathSafe } from 'lib/utils/generatePathSafe';

export const useMatchFromTo = (from: string, to: string) => {
  const match = useMatch(from);
  const generatedPath = generatePathSafe(to, match?.params);

  if (!match || !generatedPath) {
    return false;
  }

  return generatedPath;
};
