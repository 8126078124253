import { ReactNode } from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ListingDetailStyles } from './ListingDetail.styles';

interface ListingInfoContentProps {
  icon: IconProp;
  title?: ReactNode;
  children?: ReactNode;
  childrenAction?: ReactNode;
}

export const ListingInfoContent = ({
  icon,
  title,
  children,
  childrenAction,
}: ListingInfoContentProps) => {
  return (
    <ListingDetailStyles.Section>
      <ListingDetailStyles.SectionIcon>
        <FontAwesomeIcon icon={icon} />
      </ListingDetailStyles.SectionIcon>
      <ListingDetailStyles.SectionContent>
        <ListingDetailStyles.SectionTitle>
          <ListingDetailStyles.SectionTitleGrowth>
            {title}
          </ListingDetailStyles.SectionTitleGrowth>
          {childrenAction}
        </ListingDetailStyles.SectionTitle>
        <ListingDetailStyles.SectionBody>
          {children}
        </ListingDetailStyles.SectionBody>
      </ListingDetailStyles.SectionContent>
    </ListingDetailStyles.Section>
  );
};
