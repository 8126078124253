import 'promise-polyfill/src/polyfill';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import { hotjarWorkaround } from 'lib/hotjarWorkaround';
import { applyHSConversationsSettingsBase } from 'lib/hubspot';
import { initSentry } from 'lib/sentry';

import { App } from 'screens/App';

import reportWebVitals from './reportWebVitals';

applyHSConversationsSettingsBase();
initSentry();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

reportWebVitals();
hotjarWorkaround();
