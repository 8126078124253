import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, ButtonFeedback } from '@boopos/design-system';

import { TTMProfitFilter } from 'components/ForSaleFilters/TTMProfitFilter';

import { GoBackHeader } from './Header';
import { FullScreenFunnelLayout } from './InvestmentCriteriaScreen.styles';
import { useInvestmentCriteriaProfitScreen } from './hooks/useInvestmentCriteriaProfitScreen';

export const InvestmentCriteriaProfitScreen = () => {
  const { t } = useTranslation();

  const { onSubmit, formMethods, loading, success, exitPath } =
    useInvestmentCriteriaProfitScreen();

  return (
    <>
      <GoBackHeader />
      <FullScreenFunnelLayout.Content>
        <FullScreenFunnelLayout.Steps>
          {t('InvestmentCriteria:steps_number', { from: 3, to: 4 })}
        </FullScreenFunnelLayout.Steps>
        <FullScreenFunnelLayout.Title>
          {t('InvestmentCriteria:profit_title')}
        </FullScreenFunnelLayout.Title>

        <FullScreenFunnelLayout.Description>
          {t('InvestmentCriteria:profit_description')}
        </FullScreenFunnelLayout.Description>
        <form onSubmit={onSubmit} data-testid="investment-criteria-profit-form">
          <FullScreenFunnelLayout.FormContainer>
            <FormProvider {...formMethods}>
              <TTMProfitFilter />
            </FormProvider>
          </FullScreenFunnelLayout.FormContainer>
          <FullScreenFunnelLayout.CallToAction>
            <ButtonFeedback
              type="submit"
              fullWidth
              isLoading={loading}
              isSuccess={success}
            >
              {t('InvestmentCriteria:continue')}
            </ButtonFeedback>

            <Button variant="tertiary" fullWidth as={Link} to={exitPath}>
              {t('InvestmentCriteria:leave_for_later')}
            </Button>
          </FullScreenFunnelLayout.CallToAction>
        </form>
      </FullScreenFunnelLayout.Content>
    </>
  );
};
