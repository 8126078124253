import { useTranslation } from 'react-i18next';

import { InlineMessage } from 'ui/InlineMessage';

import { QualificationStatusApi } from 'components/QualificationStatus/QualificationStatus.interface';
import { useQualificationStatus } from 'components/QualificationStatus/hooks/useQualificationStatus';
import { useVerifiedDisposableAmount } from 'components/QualificationStatus/hooks/useVerifiedDisposableAmount';

import { FormatUserInputText } from 'lib/format/literal/FormatUserInputText';

export const QualificationStatusFeedback = () => {
  const { t } = useTranslation();
  const { hasItemsUnderReview, status, notesMissingInfo, notesRejected } =
    useQualificationStatus();
  const verifiedDisposableAmount = useVerifiedDisposableAmount();

  if (!status) {
    return null;
  }

  if (status === QualificationStatusApi.EMPTY) {
    return (
      <InlineMessage
        type="warning"
        heading={t('QualificationStatusFeedback:provide_missing_info_heading')}
        message={t('QualificationStatusFeedback:provide_missing_info_message')}
        data-testid="qualification-feedback-empty"
        css={{ mt: '$4' }}
      />
    );
  }

  if (status === QualificationStatusApi.UNDER_REVIEW) {
    return (
      <InlineMessage
        type="warning"
        heading={t('QualificationStatusFeedback:in_review_heading')}
        message={t('QualificationStatusFeedback:in_review_message')}
        data-testid="qualification-under-review"
        css={{ mt: '$4' }}
      />
    );
  }

  if (status === QualificationStatusApi.MISSING_INFO) {
    return (
      <InlineMessage
        type="warning"
        heading={t('QualificationStatusFeedback:more_info_needed_heading')}
        message={<FormatUserInputText text={notesMissingInfo} />}
        css={{ mt: '$4' }}
      />
    );
  }

  if (status === QualificationStatusApi.QUALIFIED) {
    const message = (
      <>
        {!!verifiedDisposableAmount?.formatted &&
          t('QualificationStatusFeedback:qualified_message_amount', {
            amount: verifiedDisposableAmount?.formatted,
          })}
        {hasItemsUnderReview && (
          <div>{t('QualificationStatusFeedback:qualified_reviewing')}</div>
        )}
      </>
    );

    return (
      <InlineMessage
        type={hasItemsUnderReview ? 'warning' : 'success'}
        heading={t('QualificationStatusFeedback:qualified_heading')}
        message={message}
        data-testid="qualification-qualified"
        css={{ mt: '$4' }}
      />
    );
  }

  if (status === QualificationStatusApi.REJECTED) {
    return (
      <InlineMessage
        type="error"
        heading={t('QualificationStatusFeedback:not_qualified_heading')}
        message={<FormatUserInputText text={notesRejected} />}
        data-testid="qualification-not-qualified"
        css={{ mt: '$4' }}
      />
    );
  }

  return null;
};
