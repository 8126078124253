import { styled } from '@boopos/design-system';

import { CardSection } from 'ui/CardSection';

export const LoadingStyles = {
  Card: styled(CardSection, {}),

  ColumnWrapper: styled('div', {
    display: 'flex',
    flexDirection: 'column',

    '@bp2': {
      flexDirection: 'row',
      gap: '$6',
      mt: 0,
    },

    '> div:nth-child(1)': {
      width: '100%',
      maxWidth: '50rem',
    },
    '> div:nth-child(2)': {
      width: '100%',
    },
  }),
};
