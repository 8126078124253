import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { styled } from '@boopos/design-system';

import insightsPreview from 'assets/insights-preview.png';

import { Modal } from 'components/Modal';

const Styles = {
  Description: styled('div', {
    mb: '2.4rem',
  }),

  ImageWrapper: styled('div', {
    img: {
      maxWidth: '100%',
    },
  }),
  Link: styled('a', {
    border: 0,
    background: 'none',
    padding: 0,
    cursor: 'pointer',
    display: 'inline',
    color: '$textBodyPrimary',
    textDecoration: 'underline',
  }),
};

export const InsightsPreviewAction = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  const onClick = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    setOpened(true);
  }, []);

  const onClose = useCallback(() => {
    setOpened(false);
  }, []);

  return (
    <>
      <Styles.Link onClick={onClick}>{children}</Styles.Link>
      <Modal
        opened={opened}
        onClose={onClose}
        position="rightDrawer"
        maxWidth={610}
        title={t('InsightsPreviewAction:modal_title')}
        renderContentContainer
      >
        <Styles.Description>
          {t('InsightsPreviewAction:modal_description')}
        </Styles.Description>
        <Styles.ImageWrapper>
          <img
            src={insightsPreview}
            alt={t('InsightsPreviewAction:modal_image_alt')}
          />
        </Styles.ImageWrapper>
      </Modal>
    </>
  );
};
