import { styled } from '@boopos/design-system';

const SubNavigationMenuStyles = {
  Menu: styled('ul', {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    background: '$surfaceBase',
    borderRadius: '1.6rem',
  }),
  MenuItem: styled('li', {}),
  MenuItemLink: styled('a', {
    borderRadius: '1.6rem',
    p: '$4',
    textDecoration: 'none',
    color: '$textBodyPrimary',
    display: 'flex',
    alignItems: 'flex-start',
    border: '2px solid $surfaceBase',
    '&.active': {
      background: '$surfaceSelected',
    },
    '&:hover': {
      borderColor: '$borderBaseHover',
    },
    '&:active': {
      borderColor: '$borderBaseHover',
      background: '$surfaceSelected',
    },
    '&:focus': {
      borderColor: '$borderBaseHover',
      outline: 'none',
    },
  }),
  IconContainer: styled('div', {
    minWidth: '4rem',
    minHeight: '4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: '$infoStatic',
  }),
  Container: styled('div', {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  }),
  ContainerTop: styled('div', {
    display: 'flex',
    alignItems: 'flex-start',
    minHeight: '4rem',
  }),
  Content: styled('div', {
    flex: 1,
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    pt: '$2',
  }),
  ActionContainer: styled('div', {
    minWidth: '4rem',
    minHeight: '4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '$infoStatic',
  }),
  Top: styled('div', {
    display: 'flex',
    alignItems: 'center',
    minHeight: '4rem',
  }),
};

export { SubNavigationMenuStyles };
