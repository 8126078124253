import { useCallback } from 'react';
import { To, useLocation, useNavigate } from 'react-router-dom';

import { routePaths } from 'router/routePaths';

import { useNextPath } from './useNextPath';

interface UseInvestmentCriteriaNavigationConfig {
  fallbackPath?: string;
}

export const useInvestmentCriteriaNavigation = (
  config?: UseInvestmentCriteriaNavigationConfig
) => {
  const location = useLocation();
  const localNavigate = useNavigate();
  const { flowStartedFrom } = location?.state ?? {};
  const { fallbackPath = routePaths.forSale } = config ?? {};

  const nextPath = useNextPath() ?? (flowStartedFrom || fallbackPath);

  const navigate = useCallback(
    (path: To | number) => {
      localNavigate(path as To, { state: { flowStartedFrom } });
    },
    [localNavigate, flowStartedFrom]
  );

  const navigateToNextPath = useCallback(() => {
    navigate(nextPath);
  }, [nextPath, navigate]);

  return {
    exitPath: flowStartedFrom ?? fallbackPath,
    navigate,
    nextPath,
    navigateToNextPath,
  };
};
