import { ActionSideModalCommonStyles } from 'components/ActionSideModal';
import { ConnectedAccountCard } from 'components/ConnectedAccountCard';

import { IBusinessAccount } from 'lib/types';

export const ConnectedAccountsSummary = ({
  accounts,
  title,
  onDeleteAccount,
}: {
  accounts?: IBusinessAccount[];
  onDeleteAccount?: () => void;
  title: string;
}) => {
  if (!accounts?.length) {
    return null;
  }

  return (
    <ActionSideModalCommonStyles.Block>
      <ActionSideModalCommonStyles.BlockTitle>
        {title}
      </ActionSideModalCommonStyles.BlockTitle>
      {accounts.map((account) => (
        <ConnectedAccountCard
          key={account.id}
          account={account}
          onDelete={onDeleteAccount}
        />
      ))}
    </ActionSideModalCommonStyles.Block>
  );
};
