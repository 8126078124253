import { styled } from '@boopos/design-system';

export const ForSaleFavouriteButtonStyles = {
  Button: styled('button', {
    border: 0,
    width: '4rem',
    height: '4rem',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 0,
    background: 'transparent',
    color: '$black',
    cursor: 'pointer',
    appearance: 'none',
    position: 'relative',
    svg: {
      transition: 'all 0.2s ease-in-out',
      transform: 'scale(1)',
      position: 'absolute',
    },
    '&:hover': {
      background: 'transparent',
      color: '$red500',
      svg: {
        transition: 'all 0.2s ease-in-out',
        transform: 'scale(1.1)',
      },
    },
    '&:active': {
      svg: {
        transform: 'scale(0.9)',
      },
    },
    variants: {
      favourite: {
        false: {
          '.heart-fill': {
            opacity: 0,
          },
          '.heart-stroke': {
            opacity: 1,
          },
        },
        true: {
          color: '$red500',
          '.heart-fill': {
            opacity: 1,
          },
          '.heart-stroke': {
            opacity: 0,
          },
        },
      },
    },
  }),
};
