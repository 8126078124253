import { IQualificationStatusFunds } from 'components/QualificationStatus';

import { formatMoney } from 'lib/format';
import { MoneyFormat } from 'lib/format/money/formatMoney.interface';
import { parseMoneyFromApi, parseMoneyToApi } from 'lib/parse';
import { Currency } from 'lib/types';

import { AvailableFundsFormValues } from './ProofOfFunds.interface';

export const parseToApi = (data: AvailableFundsFormValues) => {
  return {
    disposableAmount: parseMoneyToApi({
      amount: Number(data.availableFunds),
      currency: data.currency as Currency,
    }),
  };
};

export const parseDefaultValuesFromApi = (funds: IQualificationStatusFunds) => {
  const availableFunds = parseMoneyFromApi(funds?.disposableAmount);

  return {
    availableFunds: availableFunds?.amount?.toString?.() || '',
    currency: availableFunds?.currency || Currency.USD,
  };
};

export const parseAvailableFundsFormatted = (
  funds: IQualificationStatusFunds
) => {
  if (!funds?.disposableAmount) return undefined;

  return formatMoney(funds.disposableAmount, { format: MoneyFormat.long });
};
