import { useCallback, useState } from 'react';

interface UseLoadingSuccessConfig {
  waitAfterSuccess?: number;
  onSuccess?: () => void;
  onError?: (err?: unknown) => void;
}

const defaultConfig: UseLoadingSuccessConfig = {
  waitAfterSuccess: 0,
  onSuccess: () => {
    // noop
  },
  onError: () => {
    // noop
  },
};

export const useLoadingSuccess = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const handle = useCallback(
    async (
      cb: Promise<unknown>,
      config: UseLoadingSuccessConfig = defaultConfig
    ) => {
      const { waitAfterSuccess = 0, onSuccess, onError } = config;
      setIsLoading(true);
      try {
        await cb;
        setIsLoading(false);
        setIsSuccess(true);
        await new Promise<void>((resolve) =>
          setTimeout(() => {
            resolve();
            onSuccess?.();
          }, waitAfterSuccess)
        );
      } catch (err) {
        console.error(err);
        onError?.(err);
      } finally {
        setIsLoading(false);
        setIsSuccess(false);
      }
    },
    []
  );

  return {
    handle,
    isLoading,
    isSuccess,
  };
};
