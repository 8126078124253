import { nearestKDown, nearestKUp } from 'lib/utils';

import { IApiFilterMinMax } from './useForSaleFiltersConfig.interface';

export type IUseProfitMultiplierConfig = IApiFilterMinMax<number>;

export const getProfitMultiplierConfig = (
  config?: IUseProfitMultiplierConfig
) => {
  const step = 0.5;
  const { min = 0, max = 10 } = config ?? {};

  const roundedMin = nearestKDown(min, step);
  const roundedMax = nearestKUp(max, step);

  return {
    min: roundedMin,
    max: roundedMax,
    step,
    defaultValue: [roundedMin, roundedMax],
  };
};
