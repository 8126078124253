import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DetailCell } from 'ui/DetailCell';

import { IApiFile } from 'lib/types';

export const animationVariants = {
  visible: {
    height: 'auto',
  },
  hidden: {
    height: 0,
  },
};

interface DocumentsPreviewProps {
  files?: IApiFile[];
  showIfEmpty?: boolean;
  additionalContent?: ReactNode;
  removeAction: (fileId: string) => JSX.Element;
  variant?: 'default' | 'light';
}

export const DocumentsPreview = ({
  files,
  showIfEmpty = false,
  additionalContent,
  removeAction,
  variant,
}: DocumentsPreviewProps) => {
  if (!showIfEmpty && !files?.length) {
    return null;
  }

  return (
    <DetailCell.Parent variant={variant}>
      <AnimatePresence initial={false}>
        {files?.map?.((file) => (
          <DetailCell
            variant={variant}
            as={motion.a}
            key={file.id}
            href={file.downloadUrl}
            target="_blank"
            icon={<FontAwesomeIcon icon={regular('file')} size="lg" />}
            title={file.name}
            action={removeAction(file.id)}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={animationVariants}
            data-testid="documents-preview-file"
          />
        ))}
      </AnimatePresence>
      {additionalContent}
    </DetailCell.Parent>
  );
};
