import { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  ActionSideModal,
  ActionSideModalCloseHeader,
  ActionSideModalCommonStyles,
  ActionSideModalLoading,
  useNavigateFromActionSideModal,
} from 'components/ActionSideModal';
import { LoanSideModalSendReview } from 'components/LoanSideModalSendReview';

import { routePaths } from 'router/routePaths';

import { useAcquiringLegalEntity } from './AcquiringLegalEntity.hooks';
import { AcquiringLegalEntityFormFields } from './AcquiringLegalEntityFormFields';

export const AcquiringLegalEntityModal = () => {
  const params = useParams<{ loanId: string }>();
  const loanId = params.loanId as string;

  const { t } = useTranslation();

  const { onClose } = useNavigateFromActionSideModal(
    {
      loanId,
    },
    { basePath: routePaths.dealFlowDetail }
  );

  const handleSuccess = useCallback(() => {
    onClose();
  }, [onClose]);

  const {
    formMethods,
    onSubmit,
    isSubmitting,
    isSuccess,
    isReady,
    isWaitingForReview,
    isVerified,
    onDelete,
  } = useAcquiringLegalEntity({
    loanId,
    onSuccess: handleSuccess,
  });

  const { handleSubmit, formState } = formMethods;

  const submitForm = handleSubmit(onSubmit);

  return (
    <ActionSideModal
      header={<ActionSideModalCloseHeader onClose={onClose} />}
      onClose={onClose}
    >
      <ActionSideModalCommonStyles.Title data-testid="acquiring-legal-entity-title">
        {t('AcquiringLegalEntityModal:title')}
      </ActionSideModalCommonStyles.Title>
      <ActionSideModalCommonStyles.Subtitle>
        {t('AcquiringLegalEntityModal:subtitle')}
      </ActionSideModalCommonStyles.Subtitle>
      {isReady && (
        <FormProvider {...formMethods}>
          <ActionSideModalCommonStyles.Form onSubmit={submitForm}>
            <AcquiringLegalEntityFormFields
              loanId={loanId}
              allFieldsDisabled={isWaitingForReview || isVerified}
            />
            <LoanSideModalSendReview
              isLoading={isSubmitting}
              isSuccess={isSuccess}
              isWaitingForReview={isWaitingForReview}
              isVerified={isVerified}
              onSendForReview={submitForm}
              onCancelReview={onDelete}
              isFormValid={formState.isValid}
            />
            <input type="submit" style={{ display: 'none' }} />
          </ActionSideModalCommonStyles.Form>
        </FormProvider>
      )}
      {!isReady && <ActionSideModalLoading />}
    </ActionSideModal>
  );
};
