import { useTranslation } from 'react-i18next';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NavigationCard } from 'ui/NavigationCard';

import { ProofOfFundsVariantProp } from 'components/ProofOfFunds/ProofOfFunds.interface';

import { UploadProofOfFundsWrapper } from './UploadProofOfFundsWrapper';

export const UploadProofOfFundsDocuments = ({
  variant,
  ...props
}: ProofOfFundsVariantProp) => {
  const { t } = useTranslation();

  return (
    <UploadProofOfFundsWrapper>
      {({ open, loading }) => (
        <NavigationCard
          variant={variant}
          icon={<FontAwesomeIcon icon={regular('file')} size="lg" />}
          title={t('ProofOfFundsValidate:upload_title')}
          description={t('ProofOfFundsValidate:upload_description')}
          as="button"
          onClick={open}
          loading={loading}
          {...props}
        />
      )}
    </UploadProofOfFundsWrapper>
  );
};
