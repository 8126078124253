import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { agreementTranslations } from 'components/ConfidentialityAgreement/ConfidentialityAgreement.translations';
import { SlateBackgroundBody } from 'components/GlobalStyles';
import {
  OnboardingHeader,
  OnboardingLayout,
} from 'components/OnboardingLayout';

import { routePaths } from 'router/routePaths';

export const ConfidentialityAgreementFullScreen = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <SlateBackgroundBody />
      <OnboardingLayout.Wrapper data-testid="confidentiality-agreement-full-screen">
        <OnboardingHeader.Wrapper>
          <OnboardingHeader.ActionsWrapper>
            <OnboardingHeader.Action
              as={Link}
              to={location?.state?.backUrl ?? routePaths.forSale}
            >
              <OnboardingHeader.BackArrow />
            </OnboardingHeader.Action>
          </OnboardingHeader.ActionsWrapper>
        </OnboardingHeader.Wrapper>
        <OnboardingHeader.Title size="h1">
          {t('ConfidentialityAgreement:policy_title')}
        </OnboardingHeader.Title>
        <OnboardingLayout.Content>
          <OnboardingLayout.ContentWrapper>
            <OnboardingLayout.PlainText
              dangerouslySetInnerHTML={{
                __html: agreementTranslations,
              }}
            />
          </OnboardingLayout.ContentWrapper>
        </OnboardingLayout.Content>
      </OnboardingLayout.Wrapper>
    </>
  );
};
