import { routePrependWith } from './routePaths.utils';

export interface SellerApplicationRoutePaths {
  index: string;
  valuation: string;
  addBusiness: string;
  applicationFinished: string;
}

export const generateSellerApplicationRoutePaths = (
  basePath: string
): SellerApplicationRoutePaths => {
  const prependRoute = routePrependWith(`${basePath}/application`);

  return {
    index: prependRoute(),
    valuation: prependRoute('valuation'),
    addBusiness: prependRoute('add-business'),
    applicationFinished: prependRoute(':loanId/application-finished'),
  };
};
