import { motion } from 'framer-motion';
import { ReactNode } from 'react';

const animationVariants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

export const AnimatedBox = ({ children }: { children: ReactNode }) => (
  <motion.div
    initial="hidden"
    animate="visible"
    exit="hidden"
    variants={animationVariants}
  >
    {children}
  </motion.div>
);
