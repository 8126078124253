import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { routePaths } from 'router/routePaths';

import { useResidencyCreditReport } from 'screens/QualifyScreens/useResidencyCreditReport';
import { useUserResidency } from 'screens/QualifyScreens/useUserResidency';

export const useQualifyProofOfFundsFlow = () => {
  const navigate = useNavigate();
  const { isUsResident } = useUserResidency();
  const { onFinishQualificationFlow, onUsResident } =
    useResidencyCreditReport();

  const onUpdateAvailableFunds = useCallback(() => {
    navigate(routePaths.qualifyProofOfFundValidate);
  }, [navigate]);

  const onProofOfFundsValidate = useCallback(() => {
    if (isUsResident) {
      onUsResident();
      return;
    }
    onFinishQualificationFlow();
  }, [onFinishQualificationFlow, isUsResident, onUsResident]);

  return {
    onUpdateAvailableFunds,
    onProofOfFundsValidate,
  };
};
