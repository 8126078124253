import { Fragment, useMemo } from 'react';

import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RatingProps } from './Rating.interface';
import { RatingStyles } from './Rating.styles';

export const Rating = ({
  rating = 0,
  maxRating = 5,
  ratedIcon,
  unRatedIcon,
  ...props
}: RatingProps) => {
  const RatedIcon = ratedIcon ?? <FontAwesomeIcon icon={solid('star')} />;
  const UnRatedIcon = unRatedIcon ?? <FontAwesomeIcon icon={regular('star')} />;

  const ratingArray = useMemo(
    () => Array.from({ length: maxRating }, (_, i) => i + 1),
    [maxRating]
  );

  return (
    <RatingStyles.Wrapper {...props}>
      {ratingArray.map((rateN) => (
        <Fragment key={rateN}>
          {rateN <= rating ? RatedIcon : UnRatedIcon}
        </Fragment>
      ))}
    </RatingStyles.Wrapper>
  );
};
