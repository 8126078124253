import { useEffect, useRef } from 'react';

export const BackgroundClassNames = {
  base: 'boopos-base-background',
  light: 'boopos-light-background',
};

export const BackgroundHandling = ({ className }: { className: string }) => {
  const hadBackgroundRef = useRef(false);

  useEffect(() => {
    hadBackgroundRef.current = document.body.classList.contains(className);
    document.body.classList.add(className);

    return () => {
      if (hadBackgroundRef.current) {
        return;
      }
      document.body.classList.remove(className);
    };
  }, [className]);

  return null;
};

export const SlateBackgroundBody = () => (
  <BackgroundHandling className={BackgroundClassNames.base} />
);

export const LightBackgroundBody = () => (
  <BackgroundHandling className={BackgroundClassNames.light} />
);
