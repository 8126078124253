import { useInView } from 'react-intersection-observer';

export const useInfiniteScroll = (
  isReachingEnd?: boolean,
  loadNextPage?: Function
) => {
  const { ref } = useInView({
    onChange: (isVisible) => {
      (async () => {
        if (!isVisible || isReachingEnd || !loadNextPage) return;
        await loadNextPage();
      })();
    },
  });

  return ref;
};
