import { ListCardLoading } from 'ui/ListCard';

export const DealFlowListLoading = () => {
  return (
    <div data-testid="deal-flow-list-loading">
      <ListCardLoading />
      <ListCardLoading />
    </div>
  );
};
