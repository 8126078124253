import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@boopos/design-system';

import { Banner } from 'ui/Banner';

import { ListingForSale } from 'lib/types';

import { useConfidentialityAgreementBanner } from './useConfidentialityAgreementBanner';

export const ConfidentialityAgreementBanner = ({
  listing,
}: {
  listing?: ListingForSale;
}) => {
  const { t } = useTranslation();

  const { showConfidentialityAgreementBanner, path, state } =
    useConfidentialityAgreementBanner({
      requiresAgreement: listing?.requiresAgreement,
    });

  const description = (
    <Trans i18nKey="ConfidentialityAgreementBanner:unlock_deals">
      <strong />
    </Trans>
  );

  if (!showConfidentialityAgreementBanner) {
    return null;
  }

  return (
    <Banner
      css={{ my: '3.2rem' }}
      description={description}
      icon={<>🔒</>}
      data-testid="confidentiality-agreement-banner"
    >
      <Button as={Link} to={path} state={state}>
        {t('ConfidentialityAgreementBanner:unlock_action')}
      </Button>
    </Banner>
  );
};
