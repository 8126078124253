import { useTranslation } from 'react-i18next';

import {
  useSubmitForEvaluationError,
  useSubmitForEvaluationStep,
} from 'components/SubmitForEvaluation';

import { formatMoney } from 'lib/format';
import { MoneyFormat } from 'lib/format/money/formatMoney.interface';
import { SaleSellerApplicationApi } from 'lib/types';

import { AttachAskingPriceProps } from './AttachAskingPrice.interface';

export const useAttachAskingPrice = (props: AttachAskingPriceProps) => {
  const { path, application, editable = true } = props;

  const { t } = useTranslation();
  const { dealStructure } = useSubmitForEvaluationError();

  const errorMessage = dealStructure
    ? t('ForSaleDetailApplication:asking_price_required')
    : undefined;

  const { contentCellProps, hasValue } =
    useSubmitForEvaluationStep<SaleSellerApplicationApi>({
      path,
      entity: application,
      editable,
      actionLiteral: t('ForSaleDetailApplication:set_asking_price'),
      hasValueGetter: (application) => !!application?.askingPrice?.amount,
      error: errorMessage,
    });

  const inventoryPrice = application?.inventoryPrice?.amount;

  const children = (
    <>
      {formatMoney(application?.askingPrice, {
        format: MoneyFormat.long,
      })}
      {!!inventoryPrice &&
        t('ForSaleDetailApplication:inventory_price', {
          amount: formatMoney(application?.inventoryPrice, {
            format: MoneyFormat.long,
          }),
        })}
    </>
  );

  return {
    contentCellProps,
    children: hasValue ? children : errorMessage,
  };
};
