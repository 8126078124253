import Cleave from 'cleave.js/react';
import { forwardRef } from 'react';

import { Input } from '@boopos/design-system';

interface NumberInputProps {
  name: string;
  value?: string | number;
  label?: string;
  placeholder?: string;
  disabled?: boolean;

  [x: string]: any;
}

const numberInputCleaveOptions = {
  numeral: true,
  rawValueTrimPrefix: true,
  numeralPositiveOnly: true,
};

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  (props, ref) => (
    <Input
      {...props}
      as={Cleave}
      htmlRef={ref}
      inputMode="tel"
      options={numberInputCleaveOptions}
    />
  )
);
