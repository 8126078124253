import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useEffectOnce } from 'lib/hooks/useEffectOnce';

import { routePaths } from 'router/routePaths';

export const NavigationCloseScreen = () => {
  const [goOnNavigate, setGoOnNavigate] = useState<string>();
  const location = useLocation();
  const navigate = useNavigate();

  const locationState = (location?.state ?? {}) as { from: string };

  useEffectOnce(() => {
    setGoOnNavigate(locationState.from);
  });

  const goBack = useCallback(() => {
    navigate(goOnNavigate ?? routePaths.forSale);
  }, [navigate, goOnNavigate]);

  return (
    <button onClick={goBack}>
      <FontAwesomeIcon icon={regular('xmark')} size="xl" />
    </button>
  );
};
