import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { parseMoneyFromApiToString, parseMoneyToApi } from 'lib/parse';
import { Currency } from 'lib/types';

import { ProfitForm } from 'screens/InvestmentCriteriaScreen/InvestmentCriteria.interface';

import { useInvestmentCriteriaNavigation } from './useInvestmentCriteriaNavigation';
import { useSaveInvestmentCriteria } from './useSaveInvestmentCriteria';

export const useInvestmentCriteriaProfitScreen = () => {
  const { exitPath, navigateToNextPath } = useInvestmentCriteriaNavigation();

  const { loading, success, investmentCriteria, save } =
    useSaveInvestmentCriteria({
      onSave: navigateToNextPath,
    });

  const formMethods = useForm<ProfitForm>({});
  const { watch, setValue } = formMethods;
  const profitRange = watch('profitRange');

  const hasProfitEmpty = investmentCriteria?.profitRange?.every(
    (value: string) => value === null
  );

  const handleSubmit = useCallback(
    (data: ProfitForm) => {
      save({
        ...investmentCriteria,
        profitRange: [
          parseMoneyToApi({
            amount: data.profitRange[0],
            currency: Currency.USD,
          }),
          parseMoneyToApi({
            amount: data.profitRange[1],
            currency: Currency.USD,
          }),
        ],
      });
    },
    [investmentCriteria, save]
  );

  const onSubmit = formMethods.handleSubmit(handleSubmit);

  useEffect(() => {
    if (!investmentCriteria?.profitRange.length || hasProfitEmpty) return;

    setValue('profitRange', [
      +parseMoneyFromApiToString(investmentCriteria.profitRange[0]),
      +parseMoneyFromApiToString(investmentCriteria.profitRange[1]),
    ]);
  }, [hasProfitEmpty, investmentCriteria?.profitRange, setValue]);

  return {
    onSubmit,
    handleSubmit,
    profitRange,
    formMethods,
    loading,
    success,
    exitPath,
  };
};
