import * as Sentry from '@sentry/react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { FullScreenLoader } from 'components/FullScreenLoader';

import api from 'lib/api';
import { useEffectOnce } from 'lib/hooks/useEffectOnce';

import { defaultLandingUrl, routePaths } from 'router/routePaths';

const noop = () => {
  //noop
};

export const ShareDealBuyerAcceptScreen = () => {
  const { invitationId } = useParams();
  const navigate = useNavigate();

  useEffectOnce(() => {
    const handleAcceptInvitation = async () => {
      try {
        const { data } = await api.acceptInvitation(invitationId as string);
        const { id: loanId } = data;
        const path = generatePath(routePaths.dealFlowDetail, {
          loanId,
        });
        navigate(path);
      } catch (err) {
        Sentry.captureException(err);
        navigate(defaultLandingUrl);
      }
    };

    handleAcceptInvitation().then(noop);
  });

  return <FullScreenLoader data-testid="full-screen-loader" />;
};
