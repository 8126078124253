import { FlowStatusFeedbackStyles } from './FlowStatusFeedback.styles';

interface ILoanStatusFeedbackProps {
  title: string;
  description?: string | React.ReactNode;
  illustration: React.ReactNode;
  titleBig?: boolean;
  showBackground?: boolean;
  fullWidth?: boolean;
  children?: React.ReactNode;
}

export const FlowStatusFeedback = ({
  title,
  description,
  illustration,
  titleBig,
  showBackground,
  fullWidth = true,
  children,
  ...rest
}: ILoanStatusFeedbackProps) => {
  return (
    <FlowStatusFeedbackStyles.Wrapper {...rest} fullWidth={fullWidth}>
      <FlowStatusFeedbackStyles.Draw
        showBackground={showBackground}
        fullWidth={fullWidth}
      >
        {illustration}
      </FlowStatusFeedbackStyles.Draw>
      <FlowStatusFeedbackStyles.Title big={titleBig}>
        {title}
      </FlowStatusFeedbackStyles.Title>
      {description && (
        <FlowStatusFeedbackStyles.Descriptions>
          {description}
        </FlowStatusFeedbackStyles.Descriptions>
      )}
      {children}
    </FlowStatusFeedbackStyles.Wrapper>
  );
};
