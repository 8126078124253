import * as Stitches from '@stitches/react';

import { TooltipStyles } from './InsightsChartTooltip.styles';
import { InsightsChartTooltipRow } from './InsightsChartTooltipRow';

const InsightsChartTooltipWrapper = ({
  children,
  css,
  ...rest
}: {
  children: React.ReactNode;
  css?: Stitches.CSS;
}) => {
  return (
    <TooltipStyles.Wrapper css={css} {...rest}>
      {children}
    </TooltipStyles.Wrapper>
  );
};

export const InsightsChartTooltip = {
  Wrapper: InsightsChartTooltipWrapper,
  Table: TooltipStyles.Table,
  Header: TooltipStyles.Header,
  Row: InsightsChartTooltipRow,
};
