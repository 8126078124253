import { Avatar } from 'ui/Avatar';

import { UserAvatarProps } from './UserAvatar.interface';
import { useInitials } from './useInitials';

export const UserAvatar = (props: UserAvatarProps) => {
  const initials = useInitials(props.name);

  return <Avatar {...props}>{initials}</Avatar>;
};
