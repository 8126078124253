import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { ButtonFeedback, InlineFeedback } from '@boopos/design-system';
import { formatTimeFrom } from '@boopos/utils';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TermSheetStaticSummary } from 'components/TermSheetSummary';

import { useDownloadTermsheet } from 'lib/hooks/useDownloadTermsheet';
import { ILoanApi } from 'lib/types';

import { TermSheetModalStyles } from './TermSheetModal.styles';

interface TernSheetModalContentProps {
  loan?: ILoanApi;
  loanId: string;
  error?: any;
}

export const TermSheetModalContent = ({
  loan,
  loanId,
  error,
}: TernSheetModalContentProps) => {
  const { t } = useTranslation();

  const [handleDownload, termSheetDownloading] = useDownloadTermsheet(
    loanId,
    loan?.business?.name
  );

  const signatureDateFormatted =
    !!loan?.termSheetSignedAt &&
    formatTimeFrom({ date: new Date(loan?.termSheetSignedAt) });

  if (error) {
    return (
      <InlineFeedback
        variant="warning"
        heading={t('TermSheetModal:getting_error')}
      />
    );
  }

  if (!loan) {
    return (
      <div data-testid="modal-loading">
        <Skeleton count={1} height={100} />
        <Skeleton count={3} height={15} />
      </div>
    );
  }

  return (
    <>
      <TermSheetModalStyles.Title>
        {t('TermSheetModal:title')}
      </TermSheetModalStyles.Title>
      <TermSheetModalStyles.HelpText data-testid="terms-accepted">
        {t('TermSheetModal:term_sheet_accepted', {
          dateText: signatureDateFormatted,
        })}
      </TermSheetModalStyles.HelpText>
      <TermSheetModalStyles.ButtonContainer>
        <ButtonFeedback
          leftIcon={<FontAwesomeIcon icon={regular('cloud-arrow-down')} />}
          onClick={handleDownload}
          data-testid="term-sheet-download-button"
          isLoading={termSheetDownloading}
        >
          {t('TermSheetModal:download')}
        </ButtonFeedback>
      </TermSheetModalStyles.ButtonContainer>
      {!!loan && (
        <TermSheetStaticSummary
          loan={loan}
          termSheet={loan.termSheet}
          businessName={loan?.business?.name}
          showScroll={true}
        />
      )}
    </>
  );
};
