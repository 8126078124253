import { styled } from '@boopos/design-system';

export const InsightsChartsCard = {
  Wrapper: styled('div', {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '2.4rem',
    padding: 0,
    '@bp2': {
      padding: '4rem',
      borderRadius: '1.6rem',
      border: '2px solid $borderBaseDefault',
    },
    background: '$backgroundElevated',
  }),
  Title: styled('div', {
    fontSize: '1.4rem',
    color: '$textBodyTertiary',
  }),
  BaseWrapper: styled('div', {}),
  ChartScrollWrapper: styled('div', {
    overflow: 'hidden',
    overflowX: 'visible',
  }),
  ChartWrapper: styled('div', {
    height: 400,
  }),
  TableWrapper: styled('div', {
    maxWidth: 500,
    overflow: 'hidden',
  }),
  Description: styled('div', {
    strong: {
      fontWeight: 500,
    },
  }),
  Hint: styled('div', {
    color: '$textBodyTertiary',
  }),
  Empty: styled('div', {
    background: '$surfaceStatic',
    border: '2px solid $borderBaseDefault',
    borderRadius: '1.6rem',
    padding: '4rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2.4rem',
    color: '$textBodyTertiary',
    svg: {
      color: '$infoStatic',
    },
  }),
  BlurBackdrop: styled('div', {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backdropFilter: 'blur(10px)',
    zIndex: 5,
  }),
};
