import { useEffect, useTransition } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { FullScreenLoader } from 'components/FullScreenLoader';

import { routePaths } from 'router/routePaths';

import { useCompanionMode } from './useCompanionMode';

export const CompanionModeScreen = () => {
  const params = useParams();

  const { token } = params || {};
  const { setToken } = useCompanionMode();
  const navigate = useNavigate();
  const [, startTransition] = useTransition();

  useEffect(() => {
    if (!token) return;
    const handle = async () => {
      await setToken(token);
      startTransition(() => {
        navigate(routePaths.base);
      });
    };
    handle();
  }, [navigate, setToken, token]);

  if (!token) {
    return <Navigate to={routePaths.base} />;
  }

  return <FullScreenLoader />;
};
