import React from 'react';
import { useTranslation } from 'react-i18next';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DetailCell } from 'ui/DetailCell';

import { ConnectedAccountDetailCell } from 'components/ConnectedAccountDetailCell';
import { useConnectedAccounts } from 'components/ForSaleDetailApplication/AttachPaymentProcessors/useConnectedAccounts';

import { IBusinessAccount } from 'lib/types';

export const AccountsConnectedContentPartial = ({
  businessId,
  onConnectClick,
  onDisconnectAccount,
}: {
  businessId?: string;
  onConnectClick?: () => void;
  onDisconnectAccount: (account: IBusinessAccount) => Promise<void>;
}) => {
  const { t } = useTranslation();
  const { accounts: connectedAccounts } = useConnectedAccounts(businessId);

  if (!businessId || !connectedAccounts?.length) {
    return null;
  }

  return (
    <DetailCell.Parent css={{ mb: '2.4rem' }}>
      {connectedAccounts.map((account) => (
        <ConnectedAccountDetailCell
          key={account.id}
          account={account}
          onDisconnectAccount={onDisconnectAccount}
        />
      ))}
      {!!onConnectClick && (
        <DetailCell
          as="button"
          onClick={onConnectClick}
          data-testid="connect-another-account"
          icon={<FontAwesomeIcon icon={regular('plus')} size="lg" fixedWidth />}
          title={t('PaymentProcessorsModal:connect_another')}
        />
      )}
    </DetailCell.Parent>
  );
};
