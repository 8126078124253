import { useTranslation } from 'react-i18next';

import { ContentCell } from 'ui/ContentCell';

import { DealStructureDetails } from 'components/DealTimeline/DealStructureDetails';

import { BasicInformationDetailsProps } from './BasicInformationDetails.interface';
import { useDealStructureProps } from './useDealStructureProps';

export const BasicInformationDealStructure = ({
  loan,
  editable = true,
  path,
}: Exclude<BasicInformationDetailsProps, 'paths'> & { path: string }) => {
  const { t } = useTranslation();

  const { purchaseDealStructure } = loan;

  const { contentCellProps } = useDealStructureProps({
    path,
    loan,
    editable,
  });

  return (
    <ContentCell
      title={t('BasicInformationDetails:deal_structure')}
      data-testid="deal-structure"
      {...contentCellProps}
    >
      <DealStructureDetails purchaseDealStructure={purchaseDealStructure} />
    </ContentCell>
  );
};
