import { useTranslation } from 'react-i18next';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NudeButtonWithShadow } from 'components/NudeButtonWithShadow';

import { ActionSideModalStyles } from './ActionSideModal.styles';

export const ActionSideModalCloseHeader = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <ActionSideModalStyles.CloseWrapper>
      <NudeButtonWithShadow
        leftIcon={<FontAwesomeIcon icon={regular('xmark')} size="lg" />}
        onClick={onClose}
      >
        {t('Common:close')}
      </NudeButtonWithShadow>
    </ActionSideModalStyles.CloseWrapper>
  );
};
