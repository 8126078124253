import { Text, styled } from '@boopos/design-system';

export const ErrorFeedback = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '$4',
  textAlign: 'center',

  '& > button:first-child svg': {
    color: '$textStateNegative',
  },
});

export const MainErrorMessage = styled(Text, {
  color: '$textStateNegative',
  mb: '$2',
});

export const SecondaryErrorMessage = styled(Text, {
  color: '$infoStatic',
});
