import { useTranslation } from 'react-i18next';

import { Input } from '@boopos/design-system';

import { getInputErrorProps } from 'lib/forms';

export const CityField = ({ register, formState, ...props }: any) => {
  const { t } = useTranslation();
  return (
    <Input
      {...props}
      label={t('AcquiringLegalEntityForm:city_name')}
      {...register('city', {
        required: true,
      })}
      {...getInputErrorProps(formState.errors?.city)}
    />
  );
};
