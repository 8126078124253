import React from 'react';
import { useTranslation } from 'react-i18next';

import { merchantLiterals } from 'components/AccountConnectors/literals';

import { IBusinessAccount } from 'lib/types';

import { Styles } from './AccountsDetails.styles';

export const AccountsDetails = ({
  accounts,
}: {
  accounts?: IBusinessAccount[];
}) => {
  const { t } = useTranslation();

  return (
    <Styles.Wrapper>
      {accounts?.map((account) => (
        <Styles.Child key={account.id}>
          {merchantLiterals[account.provider] ?? account.provider} |{' '}
          <Styles.Status>
            {t('AccountsDetails:account_connected')}
          </Styles.Status>
        </Styles.Child>
      ))}
    </Styles.Wrapper>
  );
};
