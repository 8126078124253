import { useTranslation } from 'react-i18next';

export const ERROR_0 = 'error_0%';

export const usePreviewMessageKeys = (
  code?: string
): { title: string; description: string } => {
  const { t } = useTranslation();

  if (!code) {
    return { title: '', description: '' };
  }

  const keys: { [key: string]: { title: string; description: string } } = {
    [ERROR_0]: {
      title: t('DealStructureForm:loan_amount_not_zero_title'),
      description: t('DealStructureForm:loan_amount_not_zero_description'),
    },
  };

  return keys[code];
};
