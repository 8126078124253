import React from 'react';
import { useTranslation } from 'react-i18next';

import { DealDetail } from 'components/DealDetail';

import { ListingForSale } from 'lib/types';

export const BuySmarterTips = ({ listing }: { listing?: ListingForSale }) => {
  const { t } = useTranslation();

  if (!listing?.infoMemoFiles?.value?.length) {
    return null;
  }

  if (listing?.dealApplication?.grantedAt) {
    return null;
  }

  return (
    <>
      <DealDetail.SideTitle>{t('BuySmarterTips:title')}</DealDetail.SideTitle>
      <DealDetail.SideTextContent data-testid="buy-smarter-tips">
        {t('BuySmarterTips:description')}
      </DealDetail.SideTextContent>
    </>
  );
};
