import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import Api from 'lib/api';
import { IBroker } from 'lib/types';

export const otherBrokerSlug = 'other';

export const useBrokersForSelect = () => {
  const { data: brokers } = useSWR<IBroker[]>(Api.getBrokersList());
  const { t } = useTranslation();

  const noBroker = useMemo(
    () => ({
      value: null,
      label: t('AddBusinessForm:no_broker'),
    }),
    [t]
  );

  const otherBroker = useMemo(
    () => ({
      value: otherBrokerSlug,
      label: t('AddBusinessForm:other_broker'),
    }),
    [t]
  );

  const apiBrokers = (brokers || [])
    .filter((broker) => broker.enabledForDealCreation)
    .map((broker) => ({
      value: broker.slug,
      label: broker.name,
    }));

  return [noBroker, ...apiBrokers, otherBroker];
};
