import Skeleton from 'react-loading-skeleton';

import { PortfolioApplicationLayout } from 'components/PortfolioApplication/PortfolioApplicationLayout';

export const Loading = () => (
  <PortfolioApplicationLayout
    title={<Skeleton height={25} borderRadius={8} />}
    contentWrapperProps={{ 'data-testid': 'schedule-chat-screen-loading' }}
  >
    <Skeleton height={25} borderRadius={8} />
    <Skeleton height={25} borderRadius={8} />
  </PortfolioApplicationLayout>
);
