import { CohortsRawData } from 'components/InsightsCharts/InsightsCharts.interface';

import { percentValueFormatter } from './percentValueFormatter';

interface RetentionRow {
  raw?: number | null;
  formatted: string;
}

const getRetention = (retentionArray: (number | null)[]): RetentionRow[] => {
  return retentionArray.map((rawValue) => {
    const value = rawValue !== null ? rawValue * 100 : null;

    return {
      raw: rawValue,
      formatted: value !== null ? percentValueFormatter(value, true) : '',
    };
  });
};

export const prepareCohortsHeatMap = (
  rawData: CohortsRawData[] = [] as CohortsRawData[]
): Map<string, RetentionRow[]> => {
  const heatMap = new Map<string, RetentionRow[]>();

  rawData?.forEach((row: CohortsRawData) => {
    heatMap.set(row.dateAccounting, getRetention(row.retentionPercentage));
  });

  return heatMap;
};
