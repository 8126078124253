import { route } from './routePaths.utils';
import { generateSellerApplicationRoutePaths } from './sellerApplicationRoutePaths';

export const routePaths = {
  base: route(''),
  logout: route('logout'),

  listings: route('listings'),
  listing: route('listings/:listingId'),
  listingsFilter: route('listings/filter'),

  forSale: route('for-sale'),
  forSaleDetail: route('for-sale/:listingId'),
  forSaleRequestFullDetails: route('for-sale/:listingId/request-full-details'),
  forSaleInsights: route('for-sale/:listingId/insights'),
  forSaleFilter: route('for-sale/filter'),

  watchList: route('watchlist'),

  watchListAddBusiness: route('watchlist/add-business'),

  watchlistLoan: route('watchlist/:businessId/:loanId'),
  watchlistLoanDealStructure: route(
    'watchlist/:businessId/:loanId/deal-structure'
  ),
  watchListProfitAndLoss: route(
    'watchlist/:businessId/:loanId/profit-and-loss'
  ),
  watchListBalanceSheet: route('watchlist/:businessId/:loanId/balance-sheet'),
  watchlistCohorts: route('watchlist/:businessId/:loanId/cohorts'),
  watchListTermSheet: route('watchlist/:businessId/:loanId/term-sheet'),
  watchlistLoanEinConfirmation: route(
    'watchlist/:businessId/:loanId/ein-confirmation'
  ),
  watchlistLoanArticlesOfIncorporation: route(
    'watchlist/:businessId/:loanId/articles-incorporation'
  ),
  watchlistLoanAccounts: route('watchlist/:businessId/:loanId/accounts'),
  watchlistShareholders: route('watchlist/:businessId/:loanId/shareholders'),
  watchlistShareholdersAdd: route(
    'watchlist/:businessId/:loanId/shareholders/add'
  ),

  portfolio: route('portfolio'),
  portfolioBusiness: route('portfolio/:businessId'),

  portfolioApplication: generateSellerApplicationRoutePaths('portfolio'),

  portfolioEditBusiness: route('portfolio/:businessId/edit'),
  portfolioRequestLoan: route('portfolio/:businessId/request-loan'),

  portfolioRequestGrowthLoan: route(
    'portfolio/:businessId/request-loan/growth'
  ),

  portfolioValuation: route('portfolio/:businessId/valuation'),
  portfolioFinancing: route('portfolio/:businessId/financing'),
  portfolioLoanDetail: route('portfolio/:businessId/financing/:loanId'),
  portfolioPaymentMethod: route(
    'portfolio/:businessId/financing/:loanId/payment-method'
  ),
  portfolioProfitAndLoss: route(
    'portfolio/:businessId/financing/:loanId/profit-and-loss'
  ),
  portfolioBalanceSheet: route(
    'portfolio/:businessId/financing/:loanId/balance-sheet'
  ),
  portfolioCohorts: route('portfolio/:businessId/financing/:loanId/cohorts'),
  portfolioAdditionalDetails: route(
    'portfolio/:businessId/financing/:loanId/additional-details'
  ),
  portfolioTermSheet: route(
    'portfolio/:businessId/financing/:loanId/term-sheet'
  ),
  portfolioEinConfirmation: route(
    'portfolio/:businessId/financing/:loanId/ein-confirmation'
  ),
  portfolioArticlesOfIncorporation: route(
    'portfolio/:businessId/financing/:loanId/articles-incorporation'
  ),
  portfolioAccounts: route('portfolio/:businessId/financing/:loanId/accounts'),
  portfolioShareholders: route(
    'portfolio/:businessId/financing/:loanId/shareholders'
  ),
  portfolioShareholdersAdd: route(
    'portfolio/:businessId/financing/:loanId/shareholders/add'
  ),

  portfolioForSale: route('portfolio/:businessId/for-sale'),
  portfolioForSaleDealStructure: route(
    'portfolio/:businessId/for-sale/deal-structure'
  ),
  portfolioForSaleBusinessPerformance: route(
    'portfolio/:businessId/for-sale/business-performance'
  ),
  portfolioForSaleProfitAndLoss: route(
    'portfolio/:businessId/for-sale/profit-and-loss'
  ),
  portfolioForSaleCohorts: route('portfolio/:businessId/for-sale/cohorts'),
  portfolioForSaleAdditionalDetails: route(
    'portfolio/:businessId/for-sale/additional-details'
  ),

  portfolioForSalePaymentProcessors: route(
    'portfolio/:businessId/for-sale/payment-processors'
  ),

  portfolioEnquiries: route('portfolio/:businessId/enquiries'),
  portfolioEnquiry: route('portfolio/:businessId/enquiries/:enquiryId'),

  portfolioIntegrations: route('portfolio/:businessId/integrations'),
  portfolioBusinessAccounts: route(
    'portfolio/:businessId/integrations/accounts'
  ),

  portfolioAddBusiness: route('portfolio/add-business'),

  account: route('account'),
  accountProfile: route('account/profile'),
  accountNotifications: route('account/notifications'),
  accountQualification: route('account/qualification'),
  accountInvestmentCriteria: route('account/investment-criteria'),

  qualificationProfile: route('account/qualification/profile'),
  qualificationProofOfFunds: route('account/qualification/proof-of-funds'),
  qualificationCreditReport: route('account/qualification/credit-report'),

  qualify: route('qualify'),
  qualifyProfile: route('qualify/profile'),
  qualifyProofOfFund: route('qualify/proof-of-fund'),
  qualifyProofOfFundValidate: route('qualify/proof-of-fund/validate'),
  qualifyResidency: route('qualify/residency'),
  qualifyCreditReport: route('qualify/credit-report'),

  onboarding: route('onboarding'),
  onboardingBuyTarget: route('onboarding/profile-buy'),
  onboardingProfileBuy: route('onboarding/profile-buy'),
  onboardingProfileSell: route('onboarding/profile-sell'),

  onboardingSellerApplication:
    generateSellerApplicationRoutePaths('onboarding'),

  callbackErp: route('callback/erp'),
  callbackStripe: route('callback/stripe'),
  callbackAmazon: route('callback/amazon'),

  brokerListingGrantAccess: route('broker/listing/:token/grant'),
  brokerLanding: route('broker/:broker/:brokerListingId'),

  companion: route('companion/:token'),

  invitationConnectAccounts: route('invitation/accounts/:businessId/:token'),

  invitationConnectAccountsAdd: route(
    'invitation/accounts/:businessId/:token/accounts'
  ),

  sellerShareDeal: route('share-deal/:invitationId'),

  initCustomProviderConnection: route('connect/:provider'),

  dealFlow: route('deal-flow'),
  dealFlowAddTargetBusiness: route('deal-flow/add-business'),
  dealFlowDetail: route('deal-flow/:loanId'),
  dealFlowTermSheet: route('deal-flow/:loanId/term-sheet'),
  dealFlowLetterOfIntent: route('deal-flow/:loanId/letter-of-intent'),
  dealFlowLegalEntity: route('deal-flow/:loanId/legal-entity'),
  dealFlowFundsBankAccount: route('deal-flow/:loanId/funds-bank-account'),
  dealFlowShareHolders: route('deal-flow/:loanId/shareholders'),
  dealFlowSigners: route('deal-flow/:loanId/signers'),
  dealFlowRevenues: route('deal-flow/:loanId/revenues'),
  paymentMethod: route('deal-flow/:loanId/payment-method'),
  dealFlowRevenueBankAccount: route('deal-flow/:loanId/revenue-bank'),
  dealFlowErp: route('deal-flow/:loanId/erp'),
  dealFlowAssetPurchaseAgreement: route(
    'deal-flow/:loanId/assets-purchase-agreement'
  ),
  dealFlowDealStructure: route('deal-flow/:loanId/deal-structure'),
  dealFlowProfitAndLoss: route('deal-flow/:loanId/profit-and-loss'),
  dealFlowCohorts: route('deal-flow/:loanId/cohorts'),
  dealFlowContactSeller: route('deal-flow/:loanId/contact-seller'),
  dealFlowAdditionalDetails: route('deal-flow/:loanId/additional-details'),
  dealFlowInsights: route('deal-flow/:loanId/insights'),

  getValuationLanding: route('get-valuation-landing'),
  sellBusinessLanding: route('sell-business-landing'),
  freeValuation: route('free-valuation'),

  businessInsightsCallback: route(
    'business-insights/:businessInsightsId/callback'
  ),

  investmentCriteria: route('investment-criteria'),
  investmentCriteriaBusinessTypeOther: route(
    'investment-criteria/other-business'
  ),
  investmentCriteriaPrice: route('investment-criteria/price'),
  investmentCriteriaProfit: route('investment-criteria/profit'),
  investmentCriteriaKeywords: route('investment-criteria/keywords'),
  investmentCriteriaSuccess: route('investment-criteria/success'),
  investmentCriteriaBusinessType: route('investment-criteria/:businessTypeId'),

  confidentialityAgreement: route('confidentiality-agreement'),
  confidentialityAgreementFull: route('confidentiality-agreement/full'),

  unlockListing: route('unlock/:listingId'),
  getFinancing: route('get-financing'),
};

export const defaultLandingUrl = routePaths.forSale;
