import { useTranslation } from 'react-i18next';

import { Badge } from 'ui/Badge';

import { RenderIfNotEmpty } from 'components/RenderIfNotEmpty';

import { ListingForSale } from 'lib/types';

import { ListingDetailStyles } from './ListingDetail.styles';
import {
  ListingInfoItem,
  ListingInfoItemBlurredChild,
} from './ListingInfoItem';
import { SellerTypeRender } from './SellerTypeRender';

export const DealSource = ({ listing }: { listing: ListingForSale }) => {
  const { t } = useTranslation();

  return (
    <ListingDetailStyles.InfoGrid>
      <SellerTypeRender.Broker sellerType={listing.sellerType}>
        <>
          <ListingInfoItem
            title={t('DealSource:deal_sourced_type')}
            variant="body"
            overflow
          >
            <ListingInfoItemBlurredChild blur={listing.dealSource?.redacted}>
              {listing.dealSource?.value?.name}
            </ListingInfoItemBlurredChild>
            <Badge css={{ ml: '$2' }}>{t('DealSource:broker')}</Badge>
          </ListingInfoItem>
          <RenderIfNotEmpty value={listing.dealSource?.value?.externalId}>
            <ListingInfoItem
              title={t('DealSource:broker_id')}
              variant="body"
              blur={listing.dealSource?.redacted}
            >
              {listing.dealSource?.value?.externalId}
            </ListingInfoItem>
          </RenderIfNotEmpty>
        </>
      </SellerTypeRender.Broker>
      <SellerTypeRender.Proprietary sellerType={listing.sellerType}>
        <ListingInfoItem
          title={t('DealSource:deal_sourced_type')}
          variant="body"
          blur={listing.dealSource?.redacted}
        >
          <span>{listing.dealSource?.value?.name}</span>
          <Badge css={{ ml: '$2' }} variant="olive">
            {t('DealSource:proprietary_seller')}
          </Badge>
        </ListingInfoItem>
      </SellerTypeRender.Proprietary>
    </ListingDetailStyles.InfoGrid>
  );
};
