import { useCallback } from 'react';

import { ICreditReportForm } from './CreditReport.interface';
import { parseToApi, shouldUpdateSSN } from './CreditReport.utils';
import creditReportApi from './creditReportApi';
import { useCreditReportData } from './useCreditReportData';

export interface UseQualifyCreditReportProps {
  onSuccess?: () => void;
  cache?: boolean;
}

export const useCreditReportUpdate = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  const updateCreditReport = useCallback(
    async (data: ICreditReportForm, dirtyFields: any) => {
      const parsedData = parseToApi(data);
      if (shouldUpdateSSN(parsedData, dirtyFields)) {
        await creditReportApi.saveSSN(parsedData?.ssn);
      }

      await creditReportApi.save(parsedData);

      const revalidatedData = await creditReportApi.get();

      onSuccess?.();

      return revalidatedData;
    },
    [onSuccess]
  );

  return { updateCreditReport };
};

export const useQualifyCreditReport = (props: UseQualifyCreditReportProps) => {
  const { onSuccess, cache } = props ?? {};

  const { creditReport, parsedCreditReport } = useCreditReportData({ cache });
  const { updateCreditReport } = useCreditReportUpdate({ onSuccess });

  return {
    updateCreditReport,
    creditReport,
    parsedCreditReport,
  };
};
