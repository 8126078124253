import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { hubspotWidgetManuallyRemoved } from './hubspot.utils';

export const HSPageView = () => {
  const [isManuallyRemoved] = useAtom(hubspotWidgetManuallyRemoved);
  const location = useLocation();

  useEffect(() => {
    if (isManuallyRemoved) return;
    window?.HubSpotConversations?.widget?.refresh?.();
  }, [location, isManuallyRemoved]);

  return null;
};
