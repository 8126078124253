import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';

import {
  IDealStructureFields,
  IDealStructurePreviewData,
} from 'components/DealStructureHelpers/DealStructure.interface';
import { parseToApi } from 'components/DealStructureHelpers/DealStructure.utils';

import api from 'lib/api';
import { defaultCurrency } from 'lib/constants';
import { Currency } from 'lib/types';

export const defaultPreviewData = {
  businessPrice: null,
  downPayment: null,
  inventoryPrice: null,
  maxRequestedAmount: null,
  maxSellerFinancing: null,
  requestedAmount: null,
  sellerFinancing: null,
  totalBusinessPrice: null,
  totalRequestedAmount: null,
  upfrontPayment: null,
  message: null,
};

export const useDealStructurePreview = (
  formValues: IDealStructureFields,
  {
    loanId,
    currency = defaultCurrency,
  }: { loanId: string; currency?: Currency }
) => {
  const [previewLoading, setPreviewLoading] = useState(false);

  const [previewData, setPreviewData] =
    useState<IDealStructurePreviewData>(defaultPreviewData);

  const memoForm = JSON.stringify(formValues);

  useDebounce(
    async () => {
      try {
        if (!formValues.businessPrice) return;

        setPreviewLoading(true);
        const { data } = await api.dealStructurePreview(
          loanId,
          parseToApi(formValues, currency)
        );
        setPreviewData(data);
      } catch (err) {
        console.error(err);
        setPreviewData(defaultPreviewData);
      } finally {
        setPreviewLoading(false);
      }
    },
    1000,
    [memoForm, loanId, currency]
  );

  useEffect(() => {
    setPreviewLoading(true);
  }, [memoForm]);

  return { previewData, previewLoading };
};
