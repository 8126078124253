import { styled } from '@boopos/design-system';

export const ConfirmationModalWrapper = styled('div', {
  p: '$4',
});

export const ConfirmationModalTitle = styled('div', {
  fontHeadingSerif: '$h3',
  fontSize: '2.4rem',
  lineHeight: '3.2rem',
  fontWeight: 'bolder',
});

export const ConfirmationModalDescription = styled('div', {
  mt: '$2',
});

export const ConfirmationModalActionsContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1',
  gridGap: '$4',
  mt: '$6',
});
