import { useTranslation } from 'react-i18next';

import { useDealStatusFlags } from 'lib/hooks/useDealStatusFlags';
import { LoanStatus } from 'lib/types';

interface IUseDeleteDealConfirmProps {
  status?: keyof typeof LoanStatus;
}

export const useDeleteDealConfirm = ({
  status,
}: IUseDeleteDealConfirmProps) => {
  const { t } = useTranslation();

  const {
    isMissingInfoBeforeEvaluation,
    isMissingInfoDuringEvaluation,
    isClosingDetailsNeeded,
    isCanceled,
    isTermSheetReadyForReview,
  } = useDealStatusFlags({
    status,
  });

  const isMissingInfo =
    isMissingInfoBeforeEvaluation || isMissingInfoDuringEvaluation;

  // deal needs more info or is rejected
  if (isMissingInfo || isCanceled) {
    return {
      title: t('DealDeleteConfirmMessages:delete_confirm_heading'),
      description: t(
        'DealDeleteConfirmMessages:delete_confirm_no_recovered_1_body'
      ),
    };
  }

  // deal is ready for review or ready for closing
  if (isTermSheetReadyForReview || isClosingDetailsNeeded) {
    return {
      title: t('DealDeleteConfirmMessages:delete_confirm_heading'),
      description: t(
        'DealDeleteConfirmMessages:delete_confirm_no_recovered_2_body'
      ),
    };
  }

  return null;
};
