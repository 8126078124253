import { Link, styled } from '@boopos/design-system';

export const Title = styled('div', {
  fontSize: '1.6rem',
  fontWeight: 700,
  lineHeight: '2.24rem',
});

export const Message = styled('div', {
  '&, & *': {
    fontSize: '1.4rem',
    lineHeight: '1.96rem',
  },
});

export const StyledInlineMessage = {
  Wrapper: styled('div', {
    borderRadius: '$md',
    padding: '$4',
    display: 'flex',

    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '$2',

    variants: {
      type: {
        neutral: {
          boxShadow: `0 0 0 1px $colors$borderStatic`,
          background: '$surfaceStatic',
          color: '$textBodySecondary',
          [`${Link}`]: {
            color: '$textBodyPrimary',
          },
        },
        actionRequired: {
          boxShadow: `0 0 0 1px $colors$borderFeatured`,
          background: '$surfaceFeatured',
          color: '$textStateFeatured',
          [`${Link}`]: {
            color: '$textStateFeatured',
          },
        },
        success: {
          boxShadow: `0 0 0 1px $colors$borderPositive`,
          background: '$surfacePositive',
          color: '$textStatePositive',
          [`${Link}`]: {
            color: '$textStatePositive',
          },
        },
        warning: {
          boxShadow: `0 0 0 1px $colors$borderWarning`,
          background: '$surfaceWarning',
          color: '$textStateWarning',
          [`${Link}`]: {
            color: '$textStateWarning',
          },
        },
        error: {
          boxShadow: `0 0 0 1px $colors$borderNegative`,
          background: '$surfaceNegative',
          color: '$textStateNegative',
          [`${Link}`]: {
            color: '$textStateNegative',
          },
        },
      },
      hasLink: {
        true: {
          [`${Message}`]: {
            color: '$textBodySecondary',
          },
        },
      },
      theme: {
        default: {},
        elevated: {},
      },
      action: {
        primary: {},
        nude: {
          '& button': {
            background: 'transparent',
          },
        },
        descriptive: {
          flexGrow: 1,
          alignItems: 'initial',
          flexDirection: 'column',
        },
      },
    },
    compoundVariants: [
      { theme: 'elevated', type: 'neutral', css: { boxShadow: 'none' } },
      { theme: 'elevated', type: 'actionRequired', css: { boxShadow: 'none' } },
      { theme: 'elevated', type: 'success', css: { boxShadow: 'none' } },
      { theme: 'elevated', type: 'warning', css: { boxShadow: 'none' } },
      { theme: 'elevated', type: 'error', css: { boxShadow: 'none' } },
    ],
    defaultVariants: {
      theme: 'default',
      type: 'neutral',
      action: 'primary',
    },
  }),
  Actions: styled('div', {
    marginLeft: 'auto',
  }),
  DescriptiveActions: styled('div', {
    flexBasis: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    fontWeight: '$bolder',
  }),
  Title,
  Message,
};
