import { YearlyGrowthDataEntry } from './InsightsCharts.interface';
import { YearlyGrowthComparisonChart } from './YearlyGrowthComparisonChart';
import { YearlyGrowthTooltip } from './YearlyGrowthTooltip';

export const TacosGrowthChart = ({
  data,
}: {
  data: YearlyGrowthDataEntry[];
}) => {
  return (
    <YearlyGrowthComparisonChart
      data={data}
      unit="percentage"
      renderTooltip={(props) => (
        <YearlyGrowthTooltip
          unit="percentage"
          negativeVariant="positive"
          positiveVariant="negative"
          {...props}
        />
      )}
    />
  );
};
