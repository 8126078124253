import { Text, styled } from '@boopos/design-system';

export const IntegrationsStyles = {
  StatusLabel: styled(Text, {
    fontSize: '$3',
    mb: '$2',
    color: '$slate500',

    '&:not(:first-child)': {
      mt: '$6',
    },
  }),
};
