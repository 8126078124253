import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAnalytics } from './useAnalytics';

export const AnalyticsPageViewHandler = () => {
  const location = useLocation();
  const analytics = useAnalytics();

  useEffect(() => {
    (async () => {
      await analytics.page();
    })();
  }, [analytics, location]);

  return null;
};
