import { atom } from 'jotai';

import { globalCss } from '@boopos/design-system';

const HS_PARENT_ID = '#hubspot-messages-iframe-container';
const HS_CLASSNAME = 'boopos-hs-mobile';

const removeClass = (elm: Element) => elm?.classList?.remove?.(HS_CLASSNAME);
const addClass = (elm: Element) => elm?.classList?.add?.(HS_CLASSNAME);

const hubspotGlobalStyles = globalCss({
  [`.${HS_CLASSNAME} ${HS_PARENT_ID}:has(.hs-shadow-container:not(.active))`]: {
    bottom: '75px !important',
  },
});

const handleHSClassName = async (
  isDesktop: boolean,
  mainMenuHiddenOn?: any
) => {
  const el = document.body;
  if (!el) return;
  if (!isDesktop && mainMenuHiddenOn === undefined) {
    addClass(el);
    return;
  }
  removeClass(el);
};

const hubspotWidgetManuallyRemoved = atom<boolean>(false);

export {
  HS_PARENT_ID,
  HS_CLASSNAME,
  hubspotGlobalStyles,
  removeClass,
  addClass,
  handleHSClassName,
  hubspotWidgetManuallyRemoved,
};
