import { ReactNode } from 'react';

import { styled } from '@boopos/design-system';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ContentCellDetail } from 'ui/ContentCellDetail';

export const ListingInfoItem = ({
  title,
  numeric = false,
  children,
  size,
  variant = 'value',
  tipHelp,
  blur,
  overflow,
}: {
  title: ReactNode;
  children: ReactNode;
  numeric?: boolean;
  size?: 'medium';
  variant?: 'value' | 'body';
  tipHelp?: string;
  blur?: boolean;
  overflow?: boolean;
}) => (
  <ContentCellDetail.Wrapper>
    <ContentCellDetail.Label>
      {title}
      {!!tipHelp && (
        <ContentCellDetail.IconWrapper
          data-tooltip-id="listing-detail-tooltip"
          data-tooltip-content={tipHelp}
          data-tooltip-place="right"
        >
          <FontAwesomeIcon icon={solid('circle-info')} size="sm" />
        </ContentCellDetail.IconWrapper>
      )}
    </ContentCellDetail.Label>
    <ContentCellDetail.Value
      numeric={numeric}
      size={size}
      variant={variant}
      blur={blur}
      overflow={overflow}
    >
      {children}
    </ContentCellDetail.Value>
  </ContentCellDetail.Wrapper>
);

export const ListingInfoItemBlurredChild = styled('span', {
  variants: {
    blur: {
      true: {
        filter: 'blur(7px)',
        userSelect: 'none',
        pointer: 'default',
      },
    },
  },
});
