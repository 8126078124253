import { MotionProps } from 'framer-motion';

import { DetailCellProps } from './DetailCell.interface';
import { DetailCellStyles } from './DetailCell.styles';

const DetailCellChild = ({
  icon,
  title,
  action,
  children,
  loading,
  ...props
}: DetailCellProps & MotionProps) => {
  return (
    <DetailCellStyles.Wrapper {...props}>
      <DetailCellStyles.IconWrapper loading={loading}>
        {icon}
      </DetailCellStyles.IconWrapper>

      <DetailCellStyles.Content>
        <DetailCellStyles.Header>
          <DetailCellStyles.Title>{title}</DetailCellStyles.Title>
          {!!action && (
            <DetailCellStyles.Action>{action}</DetailCellStyles.Action>
          )}
        </DetailCellStyles.Header>
        {!!children && (
          <DetailCellStyles.Children>{children}</DetailCellStyles.Children>
        )}
      </DetailCellStyles.Content>
    </DetailCellStyles.Wrapper>
  );
};

export const DetailCell = Object.assign(DetailCellChild, {
  Parent: DetailCellStyles.Parent,
});
