import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SubNavigationItemProps } from './SubNavigationMenu.inteface';
import { SubNavigationMenuStyles } from './SubNavigationMenu.styles';

const SubNavigationItem = ({
  children,
  icon,
  actionIcon = <FontAwesomeIcon icon={regular('chevron-right')} size="lg" />,
  extraChildren,
  ...rest
}: SubNavigationItemProps) => {
  return (
    <SubNavigationMenuStyles.MenuItem>
      <SubNavigationMenuStyles.MenuItemLink {...rest}>
        {!!icon && (
          <SubNavigationMenuStyles.IconContainer>
            {icon}
          </SubNavigationMenuStyles.IconContainer>
        )}
        <SubNavigationMenuStyles.Container>
          <SubNavigationMenuStyles.ContainerTop>
            <SubNavigationMenuStyles.Content>
              {children}
            </SubNavigationMenuStyles.Content>
            <SubNavigationMenuStyles.ActionContainer>
              {actionIcon}
            </SubNavigationMenuStyles.ActionContainer>
          </SubNavigationMenuStyles.ContainerTop>
          {!!extraChildren && <div>{extraChildren}</div>}
        </SubNavigationMenuStyles.Container>
      </SubNavigationMenuStyles.MenuItemLink>
    </SubNavigationMenuStyles.MenuItem>
  );
};

export const SubNavigationMenu = {
  Menu: SubNavigationMenuStyles.Menu,
  MenuItem: SubNavigationItem,
};
