import { styled } from '@boopos/design-system';

import { useCompanionMode } from './useCompanionMode';

const Styles = {
  Wrapper: styled('div', {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: '.4rem',
    backgroundColor: '$infoWarning',
    zIndex: 999,
  }),
  Text: styled('div', {
    fontSize: '1.2rem',
    fontWeight: 500,
    lineHeight: '1.4rem',
    color: '$textStateWarning',
    backgroundColor: '$infoWarning',
    borderRadius: '0 0 .8rem .8rem',
    position: 'absolute',
    top: 0,
    left: '50%',
    translateX: '-50%',
    padding: '.4rem .8rem',
    textTransform: 'uppercase',
  }),
};

export const CompanionModeBanner = () => {
  const { isCompanionMode } = useCompanionMode();

  if (!isCompanionMode) return null;

  return (
    <Styles.Wrapper data-testid="companion-mode-banner">
      <Styles.Text>Log-in as</Styles.Text>
    </Styles.Wrapper>
  );
};
