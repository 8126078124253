import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@boopos/design-system';

import { AmortizationTableComponent } from 'components/TermSheetSummary/AmortizationTable';

import { formatMoney } from 'lib/format';
import { MoneyFormat } from 'lib/format/money/formatMoney.interface';
import { MultiplierTuple, TermSheetType } from 'lib/types';

import { ITermSheetSummary } from './TermSheetSummary.interface';
import {
  DetailItem,
  DetailItemAmount,
  DetailItemLiteral,
  HandWrittenCircle,
  HandWrittenLine,
  MultiplierHint,
  MultiplierNumber,
  MultiplierYear,
  MultipliersChild,
  MultipliersContainer,
  MultipliersWrapper,
} from './TermSheetSummary.styles';

const plainMultiplierTuple = (royalties: MultiplierTuple[]) =>
  royalties.map(([multiplier]) => {
    return multiplier;
  });

const useMultipliersWithId = (multipliers: number[]) => {
  return multipliers.map((multiplier, index) => ({
    multiplier,
    id: `${multiplier}-${index}`,
  }));
};

export const TermSheetStaticSummary = (props: ITermSheetSummary) => {
  const { t } = useTranslation();
  const {
    termSheet,
    appendChildren,
    showPaybackText,
    showScroll = false,
  } = props;

  const multipliers = plainMultiplierTuple(termSheet?.capMultipliers ?? []);
  const multipliersWithId = useMultipliersWithId(multipliers);

  const royalties = plainMultiplierTuple(termSheet?.royalties ?? []);
  const maxRoyalty = Math.max(...royalties);
  const hasMultipleRoyalties = royalties.length > 1;

  const duration = termSheet?.maturityLength ?? 0;
  const gracePeriod = termSheet?.gracePeriod ?? 0;
  const openingFee = termSheet?.openingFee ?? 0;
  const annualInterest = termSheet?.annualInterest ?? 0;

  const rblTs = useMemo(() => {
    return (
      <>
        <DetailItem>
          <DetailItemLiteral>{t('TermSheetSummary:amount')}</DetailItemLiteral>
          <DetailItemAmount>
            {formatMoney(termSheet?.amount, { format: MoneyFormat.long })}
            <HandWrittenLine
              css={{
                position: 'absolute',
                bottom: '-.5rem',
                right: '-.5rem',
              }}
            />
          </DetailItemAmount>
        </DetailItem>
        <DetailItem>
          <DetailItemLiteral>
            {t('TermSheetSummary:repaid_in')}
          </DetailItemLiteral>
          <DetailItemAmount data-testid="term-sheet-years">
            {t('TermSheetSummary:up_to', {
              count: multipliers.length,
            })}
            <HandWrittenLine
              css={{
                position: 'absolute',
                bottom: '-.5rem',
                right: '-.5rem',
              }}
            />
          </DetailItemAmount>
        </DetailItem>
        <DetailItem>
          <DetailItemLiteral>
            {t('TermSheetSummary:royalty_repayment')}
          </DetailItemLiteral>
          {!!maxRoyalty && (
            <DetailItemAmount>
              <div data-testid="term-sheet-royalty">
                {hasMultipleRoyalties && (
                  <Box css={{ display: 'inline-block', mr: '$3' }}>
                    {t('TermSheetSummary:up_to')}
                  </Box>
                )}{' '}
                {maxRoyalty}%
              </div>
              <HandWrittenCircle
                css={{ position: 'absolute', top: '-.8rem', right: '-1rem' }}
              />
            </DetailItemAmount>
          )}
        </DetailItem>
        {showPaybackText && (
          <Box css={{ mb: '$4' }}>{t('TermSheetSummary:pay_back')}</Box>
        )}
        <MultipliersWrapper showScroll={showScroll}>
          <MultipliersContainer>
            {multipliersWithId.map((multiplier, key) => (
              <MultipliersChild
                key={multiplier.id}
                data-testid="multiplier-box"
              >
                <MultiplierHint>{t('TermSheetSummary:within')}</MultiplierHint>
                <MultiplierYear>
                  {t('TermSheetSummary:year', { year: key + 1 })}
                </MultiplierYear>
                <MultiplierNumber>{multiplier.multiplier}x</MultiplierNumber>
                <MultiplierHint>
                  {t('TermSheetSummary:repayment_cap')}
                </MultiplierHint>
              </MultipliersChild>
            ))}
          </MultipliersContainer>
        </MultipliersWrapper>
      </>
    );
  }, [
    termSheet,
    multipliers,
    maxRoyalty,
    hasMultipleRoyalties,
    showPaybackText,
    showScroll,
    t,
    multipliersWithId,
  ]);

  const ftlTs = useMemo(() => {
    return (
      <>
        <DetailItem>
          <DetailItemLiteral>{t('TermSheetSummary:amount')}</DetailItemLiteral>
          <DetailItemAmount>
            {formatMoney(termSheet?.amount, { format: MoneyFormat.long })}
            <HandWrittenLine
              css={{
                position: 'absolute',
                bottom: '-.5rem',
                right: '-.5rem',
              }}
            />
          </DetailItemAmount>
        </DetailItem>
        <DetailItem>
          <DetailItemLiteral>
            {t('TermSheetSummary:duration')}
          </DetailItemLiteral>
          <DetailItemAmount>
            {t('TermSheetSummary:{{count}} month', { count: duration })}
            <HandWrittenLine
              css={{
                position: 'absolute',
                bottom: '-.5rem',
                right: '-.5rem',
              }}
            />
          </DetailItemAmount>
        </DetailItem>
        <DetailItem>
          <DetailItemLiteral>
            {t('TermSheetSummary:grace_period')}
          </DetailItemLiteral>
          <DetailItemAmount>
            {t('TermSheetSummary:{{count}} month', { count: gracePeriod })}
            <HandWrittenLine
              css={{
                position: 'absolute',
                bottom: '-.5rem',
                right: '-.5rem',
              }}
            />
          </DetailItemAmount>
        </DetailItem>
        <DetailItem>
          <DetailItemLiteral>
            {t('TermSheetSummary:opening_fee')}
          </DetailItemLiteral>
          <DetailItemAmount>{openingFee}%</DetailItemAmount>
        </DetailItem>
        <DetailItem>
          <DetailItemLiteral>
            {t('TermSheetSummary:annual_interest')}
          </DetailItemLiteral>
          <DetailItemAmount>{annualInterest}%</DetailItemAmount>
        </DetailItem>
      </>
    );
  }, [t, termSheet, annualInterest, duration, gracePeriod, openingFee]);

  const getAmortizationTable = useMemo(() => {
    const amortizationTable = termSheet?.amortizationTable ?? [];

    if (termSheet?.type === TermSheetType.ftl && amortizationTable.length > 0) {
      return (
        <AmortizationTableComponent amortizationTable={amortizationTable} />
      );
    }
    return null;
  }, [termSheet]);

  const getTsDisplay = useMemo(() => {
    if (termSheet?.type === TermSheetType.ftl) {
      return ftlTs;
    }
    return rblTs;
  }, [termSheet, ftlTs, rblTs]);

  if (!termSheet) {
    return null;
  }

  return (
    <>
      {getTsDisplay}
      {appendChildren}
      {getAmortizationTable}
    </>
  );
};
