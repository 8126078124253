import React, { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from 'ui/Badge';

import { ListingStatusDate } from 'components/ForSaleDetailApplication/DateSidebar';
import {
  LayoutSidebarDetail,
  LayoutSidebarDetailContent,
} from 'components/LayoutSidebarDetail';

import { ForSaleApplicationApi, SellApplicationStatus } from 'lib/types';

import { AskingPricePartial } from './AskingPricePartial';
import { ForSaleLink } from './ForSaleLink';
import { ListingImpact } from './ListingImpact';

interface ContentByStatusProps {
  sellApplication?: ForSaleApplicationApi;
}

export const ContentByStatus: {
  [key in SellApplicationStatus]?: (props: ContentByStatusProps) => JSX.Element;
} = {
  [SellApplicationStatus.ready_to_publish]: ({
    sellApplication,
  }: ContentByStatusProps) => {
    const { t } = useTranslation();
    return (
      <LayoutSidebarDetail.ContentCell>
        <AskingPricePartial application={sellApplication?.application} />
        <LayoutSidebarDetail.ContentSubHeader>
          {t('ForSaleDetailApplication:listing_status_header')}
        </LayoutSidebarDetail.ContentSubHeader>
        <LayoutSidebarDetail.BadgeContainer>
          <Badge variant="blue" data-testid="application-ready-to-publish">
            {t('ForSaleDetailApplication:status_ready_to_publish')}
          </Badge>
          <ListingStatusDate sellApplication={sellApplication} />
        </LayoutSidebarDetail.BadgeContainer>
        <LayoutSidebarDetailContent.Hint>
          {t('ForSaleDetailApplication:status_ready_to_publish_hint')}
        </LayoutSidebarDetailContent.Hint>
      </LayoutSidebarDetail.ContentCell>
    );
  },
  [SellApplicationStatus.published]: ({
    sellApplication,
  }: ContentByStatusProps) => {
    const { t } = useTranslation();

    return (
      <LayoutSidebarDetail.ContentCell>
        <AskingPricePartial application={sellApplication?.application} />
        <LayoutSidebarDetail.ContentBlock>
          <LayoutSidebarDetail.ContentSubHeader>
            {t('ForSaleDetailApplication:listing_status_header')}
          </LayoutSidebarDetail.ContentSubHeader>
          <LayoutSidebarDetail.BadgeContainer>
            <Badge variant="green" data-testid="application-published">
              {t('ForSaleDetailApplication:status_published')}
            </Badge>
            <ListingStatusDate sellApplication={sellApplication} />
          </LayoutSidebarDetail.BadgeContainer>
          <ForSaleLink listingId={sellApplication?.listing?.id} />
        </LayoutSidebarDetail.ContentBlock>
        <ListingImpact listingId={sellApplication?.listing?.id} />
      </LayoutSidebarDetail.ContentCell>
    );
  },
  [SellApplicationStatus.rejected]: ({
    sellApplication,
  }: ContentByStatusProps) => {
    const { t } = useTranslation();
    return (
      <LayoutSidebarDetail.ContentCell>
        <AskingPricePartial application={sellApplication?.application} />
        <LayoutSidebarDetail.ContentSubHeader>
          {t('ForSaleDetailApplication:listing_status_header')}
        </LayoutSidebarDetail.ContentSubHeader>
        <LayoutSidebarDetail.BadgeContainer>
          <Badge variant="red" data-testid="application-rejected">
            {t('ForSaleDetailApplication:status_rejected')}
          </Badge>
          <ListingStatusDate sellApplication={sellApplication} />
        </LayoutSidebarDetail.BadgeContainer>
        <LayoutSidebarDetailContent.Hint>
          {t('ForSaleDetailApplication:status_rejected_hint')}
        </LayoutSidebarDetailContent.Hint>
      </LayoutSidebarDetail.ContentCell>
    );
  },
};
