import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { InlineMessage } from 'ui/InlineMessage';

import { InsightsPreviewAction } from 'components/PortfolioApplication/InsightsPreview';

import { FormatUserInputText } from 'lib/format';
import { SellApplicationStatus } from 'lib/types';

export const AttachBusinessDetailsFeedback = ({
  notes,
  status,
}: {
  notes?: string;
  status?: SellApplicationStatus;
}) => {
  const { t } = useTranslation();

  if (status === SellApplicationStatus.under_review) {
    return (
      <InlineMessage
        heading={t('AttachBusinessDetailsFeedback:heading_under_review')}
        message={t('AttachBusinessDetailsFeedback:message_under_review')}
      />
    );
  }

  if (notes) {
    return (
      <InlineMessage
        heading={t('AttachBusinessDetailsFeedback:heading_missing_info')}
        message={<FormatUserInputText text={notes} />}
        type="warning"
      />
    );
  }

  return (
    <div>
      <Trans i18nKey="AttachBusinessDetailsFeedback:before_send_description">
        <InsightsPreviewAction />
      </Trans>
    </div>
  );
};
