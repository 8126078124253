import { useCallback, useState } from 'react';
import useSWR from 'swr';

import { apiV2 } from 'lib/api';

import { InvestmentCriteria } from 'screens/InvestmentCriteriaScreen/InvestmentCriteria.interface';

interface UseSetupInvestmentCriteriaConfig {
  onSave: () => void;
}

export const useSaveInvestmentCriteria = (
  config?: UseSetupInvestmentCriteriaConfig
) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data: investmentCriteria, mutate: refreshInvestmentCriteria } =
    useSWR(apiV2.getInvestmentCriteria());

  const { onSave } = config ?? {};

  const save = useCallback(
    async (data: InvestmentCriteria) => {
      setLoading(true);

      await apiV2.updateInvestmentCriteria(data);
      await new Promise((resolve) => setTimeout(resolve, 500));

      setLoading(false);
      setSuccess(true);

      await new Promise((resolve) => setTimeout(resolve, 500));

      onSave?.();

      setLoading(false);
      setSuccess(false);

      refreshInvestmentCriteria();
    },
    [onSave, refreshInvestmentCriteria]
  );

  return {
    loading,
    success,
    investmentCriteria,
    save,
    refreshInvestmentCriteria,
  };
};
