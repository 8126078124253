import { Link } from '@boopos/design-system';

export const FormatUrl = ({
  url,
  showLink = true,
  ...props
}: {
  url?: string;
  showLink?: boolean;
  target?: string;
}) => {
  if (!url) {
    return null;
  }
  const cleanUrl = url.replace(/^https?:\/\//, '');

  if (showLink) {
    return (
      <Link href={url} {...props}>
        {cleanUrl}
      </Link>
    );
  }

  return <>{cleanUrl}</>;
};
