import { useCallback, useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';

import {
  IUseAccountConnector,
  IUseAccountConnectorReturn,
} from 'components/AccountConnectors/AccountConnectors.interface';

import api from 'lib/api';

export const useBankLink = ({
  provider,
  onSuccess,
  businessId,
}: IUseAccountConnector): IUseAccountConnectorReturn => {
  const [interacting, setInteracting] = useState(false);
  const [initToken, setInitToken] = useState(null);

  const handleSuccess = useCallback(
    (token: string) => {
      (async () => {
        await api.connectMerchantBusiness(provider, businessId, token);
        await onSuccess?.();
        setInteracting(false);
      })();
    },
    [businessId, onSuccess, provider]
  );

  const handleExit = useCallback(() => {
    setInteracting(false);
  }, []);

  const { open: openPlaid } = usePlaidLink({
    token: initToken,
    onSuccess: handleSuccess,
    onExit: handleExit,
  });

  const open = useCallback(async () => {
    setInteracting(true);
    const { data } = await api.initMerchantBusinessConnection(
      provider,
      businessId
    );
    const { token } = data;
    setInitToken(token);
  }, [provider, businessId]);

  useEffect(() => {
    if (initToken) openPlaid();
  }, [openPlaid, initToken]);

  return {
    open,
    ready: true,
    error: null,
    interacting,
  };
};
