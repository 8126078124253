import { defaultCurrency } from 'lib/constants';
import { parseMoneyFromApiToString, parseMoneyToApi } from 'lib/parse';
import { SaleSellerApplicationApi } from 'lib/types';

import { DealStructureFormValues } from './ApplicationDealStructureForm.interface';

export const parseFromApi = (
  application?: SaleSellerApplicationApi
): DealStructureFormValues => {
  return {
    askingPrice: parseMoneyFromApiToString(application?.askingPrice),
    inventoryPrice: parseMoneyFromApiToString(application?.inventoryPrice),
  };
};

export const parseToApi = (data: DealStructureFormValues) => {
  return {
    businessPrice: parseMoneyToApi({
      amount: Number(data.askingPrice),
      currency: defaultCurrency,
    }),
    inventoryPrice: parseMoneyToApi({
      amount: Number(data.inventoryPrice),
      currency: defaultCurrency,
    }),
  };
};
