import posthog, { PostHog } from 'posthog-js';

import { featureFlagDefaults } from 'lib/feature-flags';
import { IUser } from 'lib/types';

import { BaseAnalyticPlugin } from './BaseAnalyticPlugin';

export class PostHogAnalyticsPlugin extends BaseAnalyticPlugin {
  private client: PostHog | null = null;
  private readonly token: string;
  private readonly apiHost: string;
  private readonly uiHost: string = 'https://app.posthog.com';

  constructor({ token, apiHost }: { token: string; apiHost: string }) {
    super();
    this.token = token;
    this.apiHost = apiHost;
  }

  async load(): Promise<void> {
    const client = posthog.init(this.token, {
      ui_host: this.uiHost,
      api_host: this.apiHost,
      capture_pageview: false,
      bootstrap: {
        featureFlags: featureFlagDefaults,
      },
    });

    if (!client) {
      return;
    }

    this.client = client;
  }

  async identify(user: IUser): Promise<void> {
    this.client?.identify(user.id, BaseAnalyticPlugin.getBaseUserTraits(user));
  }
  async track(eventName: string, eventParams?: any): Promise<void> {
    this.client?.capture(eventName, eventParams);
  }
  async reset(): Promise<void> {
    this.client?.reset();
  }
  async page(): Promise<void> {
    this.client?.capture('$pageview');
  }
}
