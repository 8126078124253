import React from 'react';
import { useTranslation } from 'react-i18next';

import { TermSheetHandler } from 'components/TermSheetHandler';

import { WhatsNextProps } from './ForSaleWhatsNext.interface';

export const WhatsNextBroker = ({ children, listing }: WhatsNextProps) => {
  const { t } = useTranslation();
  if (listing.dealApplication?.grantedAt) {
    return children(
      <TermSheetHandler termSheet={listing.dealApplication.termSheet}>
        <TermSheetHandler.Base>
          {t('ForSaleWhatsNext:term_sent')}
        </TermSheetHandler.Base>
        <TermSheetHandler.External>
          {t('ForSaleWhatsNext:term_sent_external')}
        </TermSheetHandler.External>
        <TermSheetHandler.Empty>
          {t('ForSaleWhatsNext:deal_unlocked_non_funding')}
        </TermSheetHandler.Empty>
      </TermSheetHandler>
    );
  }

  if (listing.dealApplication?.requestedAt) {
    return children(t('ForSaleWhatsNext:broker_notified'));
  }

  return children(t('ForSaleWhatsNext:before_broker_notified'));
};
