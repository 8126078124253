import { TermSheetType } from 'lib/types';

import { ChildProps, TermSheetHandlerInternal } from './TermSheetHandler.types';

export const ExternalTermSheet = ({ children }: ChildProps) => <>{children}</>;

ExternalTermSheet.defaultProps = {
  type: TermSheetType.external,
  __internalTermSheetHandler: TermSheetHandlerInternal.ByTpe,
};
