import { atom, useAtom } from 'jotai';
import { useEffect } from 'react';

import { defaultLandingUrl } from 'router/routePaths';

const landingScreenMemoAtom = atom<undefined | string>(undefined);

export const useLandingScreenMemo = () => {
  const [landingScreenMemo] = useAtom(landingScreenMemoAtom);

  return landingScreenMemo ?? defaultLandingUrl;
};

export const LandingScreenMemo = ({ pathname }: { pathname?: string }) => {
  const [, setLandingScreenMemo] = useAtom(landingScreenMemoAtom);

  useEffect(() => {
    setLandingScreenMemo(pathname);
  }, [pathname, setLandingScreenMemo]);

  return null;
};
