import { routePaths } from 'router/routePaths';

import {
  InvestmentCriteriaCategoriesScreen,
  InvestmentCriteriaKeywordsScreen,
  InvestmentCriteriaOtherBusinessScreen,
  InvestmentCriteriaPriceScreen,
  InvestmentCriteriaProfitScreen,
  InvestmentCriteriaScreen,
  InvestmentCriteriaScreensWrapper,
  InvestmentCriteriaSuccessScreen,
} from 'screens/InvestmentCriteriaScreen';

export const investmentCriteriaRoutes = {
  path: routePaths.investmentCriteria,
  element: <InvestmentCriteriaScreensWrapper />,
  children: [
    {
      path: routePaths.investmentCriteria,
      element: <InvestmentCriteriaScreen />,
    },
    {
      path: routePaths.investmentCriteriaBusinessTypeOther,
      element: <InvestmentCriteriaOtherBusinessScreen />,
    },
    {
      path: routePaths.investmentCriteriaPrice,
      element: <InvestmentCriteriaPriceScreen />,
    },
    {
      path: routePaths.investmentCriteriaProfit,
      element: <InvestmentCriteriaProfitScreen />,
    },
    {
      path: routePaths.investmentCriteriaKeywords,
      element: <InvestmentCriteriaKeywordsScreen />,
    },
    {
      path: routePaths.investmentCriteriaSuccess,
      element: <InvestmentCriteriaSuccessScreen />,
    },
    {
      path: routePaths.investmentCriteriaBusinessType,
      element: <InvestmentCriteriaCategoriesScreen />,
    },
  ],
};
