import { atom, useAtom } from 'jotai';

interface ListStatus {
  isError?: boolean;
  isEmpty?: boolean;
  isFirstLoading?: boolean;
}

const listStatusAtom = atom<ListStatus>({});
export const useListStatus = () => {
  const [listStatus, setListStatus] = useAtom(listStatusAtom);

  return {
    listStatus,
    setListStatus,
  };
};
