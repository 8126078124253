import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@boopos/design-system';

import { Modal, ModalActions } from 'components/Modal';

export const NoAccountsConnectedModal = ({
  opened,
  onClose,
  onConnectNow,
  onSubmitAnyways,
}: {
  opened: boolean;
  onClose: () => void;
  onConnectNow?: () => void;
  onSubmitAnyways?: () => void | Promise<void>;
}) => {
  const { t } = useTranslation();

  const handleNow = useCallback(() => {
    onConnectNow?.();
    onClose();
  }, [onConnectNow, onClose]);

  const handleSubmitAnyway = useCallback(() => {
    onSubmitAnyways?.();
    onClose();
  }, [onSubmitAnyways, onClose]);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      position="bottomSheetCentered"
      maxWidth={450}
      title={t('NoAccountsConnectedModal:title')}
      renderContentContainer
    >
      <div>{t('NoAccountsConnectedModal:description')}</div>
      <ModalActions>
        <Button fullWidth onClick={handleNow}>
          {t('NoAccountsConnectedModal:connect_now')}
        </Button>
        <Button variant="secondary" fullWidth onClick={handleSubmitAnyway}>
          {t('NoAccountsConnectedModal:submit_for_evaluation_anyways')}
        </Button>
      </ModalActions>
    </Modal>
  );
};
