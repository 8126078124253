import Skeleton from 'react-loading-skeleton';

import { styled } from '@boopos/design-system';

import { Timeline } from 'ui/Timeline';

export const DealDetail = {
  Wrapper: styled('div', {
    pb: 0,
    '@bp2': {
      pb: '5.6rem',
    },
  }),
  ContainerWrapper: styled('div', {
    px: '1.6rem',
    maxWidth: '110.4rem',
    mx: 'auto',
  }),
  Container: styled('div', {
    '@bp2': {
      display: 'grid',
      gridTemplateColumns: '1fr 30.7rem',
      columnGap: '4.8rem',
    },
  }),
  Content: styled('div', {}),
  Side: styled('div', {
    display: 'none',
    '@bp2': {
      display: 'block',
    },
  }),
  SideWrapper: styled('div', {
    display: 'flex',
    flexDirection: 'column',
    color: '$textBodySecondary',
    paddingBottom: '9.6rem',
    mt: '$4',
    '@bp2': {
      position: 'relative',
      mt: '2.4rem',
    },
    variants: {
      showInDesktop: {
        false: {
          '@bp2': {
            display: 'none',
          },
        },
      },
    },
  }),
  ActionsWrapper: styled('div', {
    display: 'flex',
    gap: '1.6rem',
    flexWrap: 'nowrap',
    flexDirection: 'row-reverse',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '1.6rem',
    background: '$backgroundBase',
    borderTop: '2px solid $borderBaseDefault',
    'button, a': {
      textOverflow: 'ellipsis',
      width: '100%',
      maxWidth: '4.8rem',
      flex: 1,
    },
    '.sidebar-primary': {
      width: '100%',
      maxWidth: 'none',
    },
    '*': {
      whiteSpace: 'nowrap',
    },
    '@bp2': {
      mb: '3.2rem',
      position: 'static',
      gap: '.8rem',
      inset: 'auto',
      padding: 0,
      border: 0,
      background: 'none',
      flexDirection: 'column',
      'button, a': {
        maxWidth: 'none',
        flex: 'auto',
      },
    },
  }),
  TextWrapper: styled('div', {}),
  SideTitle: styled('div', {
    mb: '$2',
    fontWeight: 500,
    color: '$textBodySecondary',
  }),
  SideTextContent: styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '$2',
    mb: '$4',
  }),
};

export const LoadingElements = {
  Title: () => <Skeleton height={24} borderRadius={8} width={200} />,
  Timeline: () => {
    return (
      <>
        <Timeline.Step>
          <Timeline.Time>
            <Skeleton height={24} borderRadius={8} width={64} />
          </Timeline.Time>
          <Timeline.Title>
            <Skeleton height={24} borderRadius={8} width={200} />
          </Timeline.Title>
        </Timeline.Step>
        <Timeline.Step showBottomLine={false} topSpace="large">
          <Timeline.Time>
            <Skeleton height={24} borderRadius={8} width={32} />
          </Timeline.Time>
          <Timeline.Title>
            <Skeleton height={24} borderRadius={8} width={200} />
          </Timeline.Title>
          <Timeline.Content>
            <Skeleton height={24} borderRadius={8} width={250} />
          </Timeline.Content>
        </Timeline.Step>
      </>
    );
  },
};
