import { Link, generatePath } from 'react-router-dom';
import useSWR from 'swr';

import { LoanCard } from 'components/LoanCard';

import api from 'lib/api';
import { IBusinessApi, ILoanApi } from 'lib/types';

import { routePaths } from 'router/routePaths';

interface IUseBusinessLoansProps {
  businessId?: string;
}

export const useBusinessLoans = ({ businessId }: IUseBusinessLoansProps) => {
  const { data: business } = useSWR<IBusinessApi>(
    businessId && api.getPortfolioBusiness(businessId)
  );

  const { financingLoans } = business ?? {};
  const hasLoans = !!financingLoans?.length;

  const loanCards = financingLoans?.map((loan: ILoanApi) => (
    <LoanCard
      key={loan.id}
      loan={loan}
      as={Link}
      to={generatePath(routePaths.portfolioLoanDetail, {
        businessId,
        loanId: loan.id,
      })}
      state={{
        backUrl: generatePath(routePaths.portfolioFinancing, { businessId }),
      }}
      data-testid="loan-card"
    />
  ));

  return {
    loanCards,
    hasLoans,
  };
};
