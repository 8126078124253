import { useMemo } from 'react';
import useSWR from 'swr';

import api, { apiV2 } from 'lib/api';
import { formatMoney } from 'lib/format';
import { ICategory, Money } from 'lib/types';

import { Breakdown } from './InvestmentCriteriaBreakdown.styles';

export const useInvestmentCriteriaBreakdown = () => {
  const { data: categoriesApi } = useSWR(api.getCategories());
  const { data: investmentCriteriaApi } = useSWR(apiV2.getInvestmentCriteria());

  const formattedBusinessKinds = useMemo(() => {
    if (!investmentCriteriaApi?.categories || !categoriesApi?.length) return '';

    const businessKindsNames = Object.keys(investmentCriteriaApi.categories)
      .map(
        (key) => categoriesApi.find((item: ICategory) => item.id === key)?.name
      )
      .filter(Boolean);

    const otherBusinessTypes = investmentCriteriaApi?.otherBusinessTypes || [];
    const allBusinessKinds = [...businessKindsNames, ...otherBusinessTypes];

    return allBusinessKinds.join(', ');
  }, [
    categoriesApi,
    investmentCriteriaApi?.categories,
    investmentCriteriaApi?.otherBusinessTypes,
  ]);

  const formattedAskPriceRange = useMemo(() => {
    const askPriceRange = investmentCriteriaApi?.askPriceRange || [];
    const formattedRanges = askPriceRange.map((item: Money) =>
      formatMoney(item)
    );
    return formattedRanges.join(' - ');
  }, [investmentCriteriaApi?.askPriceRange]);

  const formattedProfitRange = useMemo(() => {
    const profitRange = investmentCriteriaApi?.profitRange || [];
    const formattedRanges = profitRange.map((item: Money) => formatMoney(item));
    return formattedRanges.join(' - ');
  }, [investmentCriteriaApi?.profitRange]);

  const formattedKeywords = useMemo(
    () => investmentCriteriaApi?.keywords?.join(', ') || '',
    [investmentCriteriaApi?.keywords]
  );

  const formattedBusinessCategories = useMemo(() => {
    if (!investmentCriteriaApi?.categories || !categoriesApi?.length) return [];

    return Object.keys(investmentCriteriaApi.categories).map((key) => {
      const item = categoriesApi.find(
        (category: ICategory) => category.id === key
      );
      if (!item) return null;

      const categoryNames = item.categories
        .filter(
          (category: ICategory) =>
            item.id === investmentCriteriaApi.categories[item.id][0] ||
            investmentCriteriaApi.categories[item.id].includes(category.id)
        )
        .map((category: ICategory) => category.name)
        .join(', ');

      return (
        categoryNames && (
          <Breakdown.Item key={item.id}>
            <Breakdown.Title>{item.name}:</Breakdown.Title>
            <Breakdown.Description>{categoryNames}</Breakdown.Description>
          </Breakdown.Item>
        )
      );
    });
  }, [categoriesApi, investmentCriteriaApi?.categories]);

  return {
    formattedBusinessKinds,
    formattedBusinessCategories,
    formattedProfitRange,
    formattedAskPriceRange,
    formattedKeywords,
  };
};
