import { styled } from '@boopos/design-system';

export const MenuItem = styled('li', {
  listStyleType: 'none',
  margin: 0,
  padding: 0,
  flex: 1,
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  ml: '$4',
  '@bp2': {
    flex: 0,
    width: '100%',
    mb: '$4',
    ml: 0,
  },
});
