import { useCallback, useContext } from 'react';

import { IUser } from 'lib/types';
import { noop } from 'lib/utils/noop';

import { AnalyticsEvent } from './AnalyticsEvent.interface';
import { AnalyticsContext } from './AnalyticsProvider';
import { IUseAnalytics } from './useAnalytics.interface';

const debugLog = (method: string, ...params: any) =>
  console.log('[useAnalytics]', method, ...params);

export const useAnalytics = (useAnalyticsParams?: IUseAnalytics) => {
  const { debug = false } = useAnalyticsParams ?? {};

  const analyticsClient = useContext(AnalyticsContext);

  const identify = useCallback(
    async (user?: IUser) => {
      if (!analyticsClient || !user) {
        debug && debugLog('identify', user);
        return;
      }
      await analyticsClient.identify(user);
    },
    [analyticsClient, debug]
  );

  const _track = useCallback(
    async (eventName: AnalyticsEvent, eventParams?: any) => {
      if (!analyticsClient) {
        debug && debugLog('track', eventName, eventParams);
        return;
      }
      await analyticsClient.track(eventName, eventParams);
    },
    [analyticsClient, debug]
  );

  const track = useCallback(
    (eventName: AnalyticsEvent, eventParams?: any) => {
      _track(eventName, eventParams).then(noop).catch(noop);
    },
    [_track]
  );

  const reset = useCallback(async () => {
    if (!analyticsClient) {
      debug && debugLog('reset');
      return;
    }
    await analyticsClient.reset();
  }, [analyticsClient, debug]);

  const page = useCallback(async () => {
    if (!analyticsClient) {
      debug && debugLog('page');
      return;
    }
    await analyticsClient.page();
  }, [analyticsClient, debug]);

  return {
    identify,
    track,
    reset,
    page,
  };
};
