import { AnimatePresence } from 'framer-motion';
import { Outlet } from 'react-router-dom';

import { AppUpdatesHandler } from 'components/AppUpdatesHandler';
import { Layout } from 'components/Layout';
import { QualificationApprovedModal } from 'components/QualificationStatus';

import { CompanionModeBanner } from 'lib/companion';

import { AuthenticatedRoutesProvider } from 'providers/AuthenticatedRoutesProvider';
import { FirstAppScreenDecider } from 'providers/FirstAppScreenDecider';

export const BaseRouterProvider = () => (
  <AuthenticatedRoutesProvider>
    <FirstAppScreenDecider>
      <Outlet />
    </FirstAppScreenDecider>
  </AuthenticatedRoutesProvider>
);

export const BaseRouterProviderLayout = () => (
  <>
    <QualificationApprovedModal />
    <CompanionModeBanner />
    <Layout layoutBanner={<AppUpdatesHandler />}>
      <AnimatePresence>
        <Outlet />
      </AnimatePresence>
    </Layout>
  </>
);
