import { useTranslation } from 'react-i18next';

import { ButtonFeedback, Input } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Rating } from 'ui/Rating';
import { StaticTooltip } from 'ui/Tooltip';

import { EnquiryStatusBadge } from 'components/EnquiryStatusBadge';
import { PageModal } from 'components/Modal';
import { RenderIfDefined } from 'components/RenderIfDefined/RenderIfDefined';
import { UserAvatar } from 'components/UserAvatar';

import { formatMoney } from 'lib/format';
import { MoneyFormat } from 'lib/format/money/formatMoney.interface';
import { EnquiryStatus } from 'lib/types';

import { EnquiryModalStyles } from './EnquiryModal.styles';
import { EnquiryModalLoading } from './EnquiryModalLoading';
import { LinkedInCV } from './LinkedInCV';
import { RejectButton } from './RejectButton';
import { useEnquiryModal } from './useEnquiryModal';

export const EnquiryModal = () => {
  const { t } = useTranslation();

  const {
    enquiry,
    advisorTooltipId,
    fullName,
    firstContactedDate,
    goBackURL,
    privateSellerNotesRef,
    handleSave,
    handleReject,
    isSaving,
  } = useEnquiryModal();

  return (
    <PageModal goBackURL={goBackURL} position="rightDrawer" maxWidth={390}>
      {enquiry ? (
        <EnquiryModalStyles data-testid="enquiry-modal">
          <EnquiryModalStyles.Header>
            <EnquiryModalStyles.FullName>
              {fullName}
            </EnquiryModalStyles.FullName>
            <UserAvatar name={fullName} variant={enquiry?.cssVariant as any} />
          </EnquiryModalStyles.Header>
          <EnquiryModalStyles.Date>
            {t('EnquiryModal:first_contacted_date', {
              firstContactedDate,
            })}
          </EnquiryModalStyles.Date>
          <RenderIfDefined value={enquiry?.advisorRating}>
            <EnquiryModalStyles.Item>
              <EnquiryModalStyles.Label>
                {t('EnquiryModal:advisor_rating')}
                <FontAwesomeIcon
                  icon={regular('circle-info')}
                  size="sm"
                  data-tooltip-id={advisorTooltipId}
                  data-tooltip-place="right"
                  data-tooltip-content={t('EnquiryModal:advisor_tooltip', {
                    name: enquiry?.buyer?.firstName,
                  })}
                />
              </EnquiryModalStyles.Label>
              <Rating rating={enquiry?.advisorRating} maxRating={5} />
              <EnquiryModalStyles.AdvisorNotes>
                {enquiry?.advisorNotes}
              </EnquiryModalStyles.AdvisorNotes>
            </EnquiryModalStyles.Item>
          </RenderIfDefined>
          <EnquiryModalStyles.Item>
            <EnquiryModalStyles.Label>
              {t('EnquiryModal:status')}
            </EnquiryModalStyles.Label>
            <EnquiryStatusBadge status={enquiry?.status} />
          </EnquiryModalStyles.Item>
          <EnquiryModalStyles.Item>
            <EnquiryModalStyles.Label>
              {t('EnquiryModal:verified_funds')}
            </EnquiryModalStyles.Label>
            {formatMoney(enquiry?.buyerQualification?.verifiedFunds, {
              format: MoneyFormat.long,
            })}
          </EnquiryModalStyles.Item>
          <EnquiryModalStyles.Item>
            <EnquiryModalStyles.Label>
              {t('EnquiryModal:linkedin_cv')}
            </EnquiryModalStyles.Label>
            <LinkedInCV enquiry={enquiry} />
          </EnquiryModalStyles.Item>
          <EnquiryModalStyles.Item>
            <EnquiryModalStyles.Label>
              {t('EnquiryModal:email')}
            </EnquiryModalStyles.Label>
            <EnquiryModalStyles.Href
              href={`mailto:${enquiry?.buyer?.email}`}
              title={enquiry?.buyer?.email}
            >
              <FontAwesomeIcon icon={regular('at')} size="sm" />
              {enquiry?.buyer?.email}
            </EnquiryModalStyles.Href>
          </EnquiryModalStyles.Item>
          <EnquiryModalStyles.Item>
            <EnquiryModalStyles.Label>
              {t('EnquiryModal:notes')}
            </EnquiryModalStyles.Label>
            <Input
              name="notes"
              as="textarea"
              rows={5}
              defaultValue={enquiry?.privateSellerNotes}
              ref={privateSellerNotesRef as any}
              css={{ resize: 'vertical' }}
            />
          </EnquiryModalStyles.Item>
          <EnquiryModalStyles.Actions>
            {enquiry?.status !== EnquiryStatus.rejected && (
              <RejectButton onConfirmReject={handleReject} />
            )}
            <ButtonFeedback onClick={handleSave} isLoading={isSaving}>
              {t('EnquiryModal:save_action')}
            </ButtonFeedback>
          </EnquiryModalStyles.Actions>
          <StaticTooltip id={advisorTooltipId} place="right" />
        </EnquiryModalStyles>
      ) : (
        <EnquiryModalLoading />
      )}
    </PageModal>
  );
};
