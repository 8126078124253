import { atomWithStorage } from 'jotai/utils';

import {
  BasePersistenceAtom,
  getPersistenceKey,
} from 'components/ClosingChecklistPersistence';

import { ILetterOfIntentData } from './LetterOfIntent.interface';

export const letterOfIntentAtom = atomWithStorage<
  BasePersistenceAtom<ILetterOfIntentData>
>(getPersistenceKey('letterOfIntent'), {});
