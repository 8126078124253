import { useEffect } from 'react';

import { applyIdentifySettings } from './applyHsSettings';
import { useHubspotToken } from './useHubspotToken';

export const HSIdentify = () => {
  const hubspotIdentify = useHubspotToken();

  const { email, token } = hubspotIdentify ?? {};

  useEffect(() => {
    if (!email || !token) return;
    applyIdentifySettings(email, token);
    window.HubSpotConversations?.widget?.load?.();
  }, [email, token]);

  return null;
};
