import { useAtom } from 'jotai';

import { useEffectOnce } from 'lib/hooks/useEffectOnce';

import { hubspotWidgetManuallyRemoved } from './hubspot.utils';

const HSWidgetHide = () => {
  const [, setHubspotWidgetManuallyRemoved] = useAtom(
    hubspotWidgetManuallyRemoved
  );

  useEffectOnce(() => {
    let shouldLoadOnUnMount: boolean;
    let timeout: ReturnType<typeof setTimeout>;

    timeout = setTimeout(() => {
      shouldLoadOnUnMount =
        !!window?.HubSpotConversations?.widget?.status?.()?.loaded;
      setHubspotWidgetManuallyRemoved(true);
      window?.HubSpotConversations?.widget?.remove?.();
    }, 1200);

    return () => {
      clearTimeout(timeout);
      if (!shouldLoadOnUnMount) return;
      setHubspotWidgetManuallyRemoved(false);
      window?.HubSpotConversations?.widget?.load?.();
    };
  });
  return null;
};

export { HSWidgetHide };
