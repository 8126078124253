import { useTranslation } from 'react-i18next';

import { Box } from '@boopos/design-system';

import { BuyerInsightsTimelinePreview } from 'components/BuyerInsights/BuyerInsightsTimelinePreview';
import { TimelineItemNowProps } from 'components/DealTimeline/DealTimeline.interface';
import { DealTimelineStep } from 'components/DealTimelineStep';

import { FormatUserInputText } from 'lib/format';
import { IBusinessInsights, ILoanApi, LoanStatus } from 'lib/types';

import { routePaths } from 'router/routePaths';

export const shouldShowDealRejected = (loan: ILoanApi) =>
  [LoanStatus.canceled].includes(loan?.status);

const useTitle = (loan: ILoanApi) => {
  const { t } = useTranslation();

  const insightsEnabled = !!loan?.businessInsights;

  return insightsEnabled
    ? t('DealTimelineStep:deal_rejected_title_with_insights')
    : t('DealTimelineStep:deal_rejected_title');
};

const DealRejectedWithInsights = ({
  businessInsights,
  businessId,
}: {
  businessInsights?: IBusinessInsights;
  businessId?: string;
}) => {
  const { t } = useTranslation();

  const insightsEnabled = !!businessInsights;

  if (!insightsEnabled || !businessId) {
    return null;
  }

  return (
    <Box css={{ mt: '$2' }} data-testid="deal-rejected-with-insights">
      <>{t('DealTimelineStep:rejected_loan_insights')}</>
      <BuyerInsightsTimelinePreview
        insightsPath={routePaths.dealFlowInsights}
        businessId={businessId}
        businessInsights={businessInsights}
      />
    </Box>
  );
};

export const DealRejected = ({ loan }: TimelineItemNowProps) => {
  const { t } = useTranslation();

  const title = useTitle(loan);

  const insightsEnabled = !!loan?.businessInsights;

  if (!shouldShowDealRejected(loan)) {
    return null;
  }

  return (
    <DealTimelineStep
      title={title}
      subtitle={t('DealTimelineStep:deal_rejected_subtitle')}
      errorVariant={!insightsEnabled ? 'error' : undefined}
      data-testid="deal-rejected"
    >
      <FormatUserInputText text={loan.notes} />
      <DealRejectedWithInsights
        businessInsights={loan?.businessInsights}
        businessId={loan?.businessId}
      />
    </DealTimelineStep>
  );
};
