import { generatePath, useMatch, useParams } from 'react-router-dom';

export const notEditableProps = {
  props: { active: false, variant: 'success' as any },
};

export const useInfoPath = (path: string) => {
  const params = useParams();

  const url = generatePath(path, params);
  const match = useMatch(path);

  return { url, isMatching: !!match };
};
