import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOutlet, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { TermSheetDetails } from 'components/DealTimeline/TermSheetDetails';
import { LayoutSidebarDetail } from 'components/LayoutSidebarDetail';
import { PageModalWrapper } from 'components/Modal';

import { apiV2 } from 'lib/api';
import { ForSaleApplicationApi } from 'lib/types';

import { BusinessForSaleEmptyState } from 'screens/BusinessForSaleScreen/BusinessForSaleEmptyState';
import { BusinessForSaleLoading } from 'screens/BusinessForSaleScreen/BusinessForSaleLoading';
import { ScreenLayout } from 'screens/BusinessForSaleScreen/BusinessForSaleScreen.styles';

import { ActionsSidebar } from './ActionsSidebar';
import { BusinessDetails } from './BusinessDetails';
import { ListingDetails } from './ListingDetails';
import { WhatsNextSidebar } from './WhatsNextSidebar';

export const ForSaleDetailApplication = () => {
  const { t } = useTranslation();
  const params = useParams();
  const {
    data: sellApplication,
    error,
    mutate,
  } = useSWR<ForSaleApplicationApi>(
    params?.businessId && apiV2.businessSellApplication(params?.businessId)
  );

  const outlet = useOutlet({
    handleRefreshLoan: mutate,
    loanId: sellApplication?.application?.id,
  });

  if (error && !sellApplication) {
    return (
      <ScreenLayout.Wrapper data-testid="for-sale-empty-state">
        <BusinessForSaleEmptyState
          businessId={params?.businessId as string}
          mutate={mutate}
        />
      </ScreenLayout.Wrapper>
    );
  }

  if (!sellApplication) {
    return (
      <ScreenLayout.Wrapper data-testid="for-sale-loading">
        <BusinessForSaleLoading />
      </ScreenLayout.Wrapper>
    );
  }

  return (
    <>
      <LayoutSidebarDetail.PageWrapper>
        <LayoutSidebarDetail.Wrapper data-testid="for-sale-detail-application">
          <LayoutSidebarDetail.Content>
            <ListingDetails sellApplication={sellApplication} />
            <BusinessDetails sellApplication={sellApplication} />

            {!!sellApplication?.loanTermSheet && (
              <>
                <LayoutSidebarDetail.Divider>
                  <LayoutSidebarDetail.ContentHeader>
                    {t('ForSaleDetailApplication:term_sheet_header')}
                  </LayoutSidebarDetail.ContentHeader>
                </LayoutSidebarDetail.Divider>

                <TermSheetDetails
                  showTitle={false}
                  termSheet={sellApplication?.loanTermSheet}
                  id={sellApplication?.application?.id}
                  title={sellApplication?.business?.name}
                />
              </>
            )}
          </LayoutSidebarDetail.Content>
          <LayoutSidebarDetail.Sidebar>
            <ActionsSidebar
              status={sellApplication?.status}
              business={sellApplication?.business}
              application={sellApplication?.application}
              loanTermSheet={sellApplication?.loanTermSheet}
            />
            <WhatsNextSidebar
              status={sellApplication?.status}
              application={sellApplication?.application}
            />
          </LayoutSidebarDetail.Sidebar>
        </LayoutSidebarDetail.Wrapper>
      </LayoutSidebarDetail.PageWrapper>

      <PageModalWrapper>{outlet}</PageModalWrapper>
    </>
  );
};
