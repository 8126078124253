import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { routePaths } from 'router/routePaths';

import { Breakdown } from './InvestmentCriteriaBreakdown.styles';
import { useInvestmentCriteriaBreakdown } from './useInvestmentCriteriaBreakdown';

export const InvestmentCriteriaBreakdown = () => {
  const { t } = useTranslation();

  const {
    formattedBusinessKinds,
    formattedBusinessCategories,
    formattedProfitRange,
    formattedAskPriceRange,
    formattedKeywords,
  } = useInvestmentCriteriaBreakdown();

  return (
    <Breakdown.Wrapper data-testid="investment-criteria-breakdown">
      <Breakdown.Item>
        <Breakdown.Title>
          {t('InvestmentCriteriaBreakdown:business_kinds')}:
        </Breakdown.Title>
        <Breakdown.Description>{formattedBusinessKinds}</Breakdown.Description>
      </Breakdown.Item>
      <div>{formattedBusinessCategories}</div>
      <Breakdown.Item>
        <Breakdown.Title>
          {t('InvestmentCriteriaBreakdown:price_range')}:
        </Breakdown.Title>
        <Breakdown.Description>{formattedAskPriceRange}</Breakdown.Description>
      </Breakdown.Item>
      <Breakdown.Item>
        <Breakdown.Title>
          {t('InvestmentCriteriaBreakdown:profit_range')}:
        </Breakdown.Title>
        <Breakdown.Description>{formattedProfitRange}</Breakdown.Description>
      </Breakdown.Item>
      <Breakdown.Item>
        <Breakdown.Title>
          {t('InvestmentCriteriaBreakdown:keywords')}:
        </Breakdown.Title>
        <Breakdown.Description>{formattedKeywords}</Breakdown.Description>
      </Breakdown.Item>
      <Breakdown.Actions>
        <Button
          as={Link}
          to={routePaths.investmentCriteria}
          state={{ flowStartedFrom: window.location.pathname }}
          variant="secondary"
          fullWidth
          leftIcon={<FontAwesomeIcon icon={regular('edit')} size="lg" />}
        >
          {t('InvestmentCriteriaBreakdown:edit_action')}
        </Button>
      </Breakdown.Actions>
    </Breakdown.Wrapper>
  );
};
