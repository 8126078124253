import { Outlet } from 'react-router-dom';

import { AcquiringLegalEntityModal } from 'components/AcquiringLegalEntityModal';
import { AdditionalDetailsModal } from 'components/AdditionalDetailsModal';
import { AssetPurchaseAgreementModal } from 'components/AssetPurchaseAgreementModal';
import {
  CohortsUploadModal,
  ProfitAndLossUploadModal,
} from 'components/BasicDocumentsUploadModal';
import { ConnectERPModal } from 'components/ConnectERPModal';
import { ContractSignersModal } from 'components/ContractSignersModal';
import { DealStructureModal } from 'components/DealStructureModal';
import { FundsBankAccountModal } from 'components/FundsBankAccountModal';
import { LetterOfIntentModal } from 'components/LetterOfIntentModal';
import { RevenueBankAccountModal } from 'components/RevenueBankAccountModal';
import { RevenueSourcesModal } from 'components/RevenueSourcesModal';
import { ShareholdersAndDirectorsModal } from 'components/ShareholdersAndDirectorsModal';
import { TermSheetModal } from 'components/TermSheetModal';

import { routePaths } from 'router/routePaths';

import { AddTargetBusinessScreen } from 'screens/AddTargetBusinessScreen';
import { DealFlowBusinessInsights } from 'screens/BuyerBusinessInsights';
import {
  DealFlowDetailParent,
  DealFlowDetailScreen,
} from 'screens/DealFlowDetailScreen';
import { DealFlowListScreen } from 'screens/DealFlowListScreen';

const dealFlowDetailRoutes = {
  path: routePaths.dealFlowDetail,
  element: <DealFlowDetailParent />,
  children: [
    {
      element: <DealFlowDetailScreen />,
      path: '',
      children: [
        {
          path: routePaths.dealFlowTermSheet,
          element: <TermSheetModal />,
        },
        {
          path: routePaths.dealFlowLetterOfIntent,
          element: <LetterOfIntentModal />,
        },
        {
          path: routePaths.dealFlowFundsBankAccount,
          element: <FundsBankAccountModal />,
        },
        {
          path: routePaths.dealFlowLegalEntity,
          element: <AcquiringLegalEntityModal />,
        },
        {
          path: routePaths.dealFlowShareHolders,
          element: <ShareholdersAndDirectorsModal />,
        },
        {
          path: routePaths.dealFlowSigners,
          element: <ContractSignersModal />,
        },
        {
          path: routePaths.dealFlowRevenues,
          element: <RevenueSourcesModal />,
        },
        {
          path: routePaths.paymentMethod,
          async lazy() {
            const { DealFlowPaymentMethodModal } = await import(
              'components/PaymentMethodModal/LazyPaymentMethodModal'
            );
            return { Component: DealFlowPaymentMethodModal };
          },
        },
        {
          path: routePaths.dealFlowErp,
          element: <ConnectERPModal />,
        },
        {
          path: routePaths.dealFlowAssetPurchaseAgreement,
          element: <AssetPurchaseAgreementModal />,
        },
        {
          path: routePaths.dealFlowDealStructure,
          element: <DealStructureModal />,
        },
        {
          path: routePaths.dealFlowProfitAndLoss,
          element: (
            <ProfitAndLossUploadModal basePath={routePaths.dealFlowDetail} />
          ),
        },
        {
          path: routePaths.dealFlowCohorts,
          element: <CohortsUploadModal basePath={routePaths.dealFlowDetail} />,
        },
        {
          path: routePaths.dealFlowAdditionalDetails,
          element: (
            <AdditionalDetailsModal basePath={routePaths.dealFlowDetail} />
          ),
        },
        {
          path: routePaths.dealFlowRevenueBankAccount,
          element: <RevenueBankAccountModal />,
        },
      ],
    },
    {
      path: routePaths.dealFlowInsights,
      element: <DealFlowBusinessInsights />,
    },
  ],
};

const dealFlowListRoutes = {
  path: routePaths.dealFlow,
  element: <DealFlowListScreen />,
};

export const dealFlowRoutes = {
  path: routePaths.dealFlow,
  element: <Outlet />,
  children: [
    dealFlowListRoutes,
    {
      path: routePaths.dealFlowAddTargetBusiness,
      element: <AddTargetBusinessScreen />,
    },
    dealFlowDetailRoutes,
  ],
};
