import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { routePaths } from 'router/routePaths';

import { useFinishLandingUrl } from './useFinishLandingUrl';

export const useResidencyCreditReport = () => {
  const navigate = useNavigate();
  const { landingUrl } = useFinishLandingUrl();

  const onFinishQualificationFlow = useCallback(() => {
    navigate(landingUrl, {
      replace: true,
    });
  }, [landingUrl, navigate]);

  const onUsResident = useCallback(() => {
    navigate(routePaths.qualifyCreditReport);
  }, [navigate]);

  return {
    onFinishQualificationFlow,
    onNonUsResident: onFinishQualificationFlow,
    onUsResident,
  };
};
