import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { Input } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { InlineMessage } from 'ui/InlineMessage';

import { ActionSideModalCommonStyles } from 'components/ActionSideModal';

import { generatePathSafe } from 'lib/utils/generatePathSafe';

import { routePaths } from 'router/routePaths';

import { BusinessConnectionCard } from './BusinessConnectionCard';
import { ItemStyles } from './Styles';
import { useConnectionsList } from './useConnectionsList';

type Props = {
  businessId?: string;
  onSuccess: () => void;
};

export const AccountsListContentPartial = ({
  businessId,
  onSuccess,
}: Props) => {
  const { accounts, search, onChangeInput } = useConnectionsList();

  const { t } = useTranslation();
  const params = useParams();
  const extraInfoPath = generatePathSafe(
    routePaths.portfolioForSaleAdditionalDetails,
    params
  );

  if (!businessId) {
    return null;
  }

  return (
    <>
      <ActionSideModalCommonStyles.Title data-testid="payment-processors-account-list-title">
        {t('PaymentProcessorsModal:choose_account_title')}
      </ActionSideModalCommonStyles.Title>
      <div>
        <Input
          name="search"
          placeholder={t('PaymentProcessorsModal:search_placeholder')}
          autoFocus
          leftIcon={<FontAwesomeIcon icon={regular('magnifying-glass')} />}
          value={search}
          onChange={onChangeInput}
        />
        <ItemStyles.Wrapper>
          {accounts.map((merchant) => (
            <BusinessConnectionCard
              key={merchant.value}
              merchant={merchant}
              businessId={businessId}
              onSuccess={onSuccess}
            />
          ))}
          {!!extraInfoPath && (
            <InlineMessage
              css={{ mt: '2.4rem' }}
              heading={t('PaymentProcessorsModal:no_provider_title')}
              message={t('PaymentProcessorsModal:no_provider_message')}
              action="nude"
              actionProps={{
                as: Link,
                to: extraInfoPath,
              }}
            />
          )}
        </ItemStyles.Wrapper>
      </div>
    </>
  );
};
