import { useCallback } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

export const useNavigateFromActionSideModal = (
  params: { [key: string]: string | undefined },
  config: { basePath: string }
) => {
  const basePath = config.basePath;
  const goBackUrl = generatePath(basePath, params);

  const navigate = useNavigate();

  const onClose = useCallback(() => {
    navigate(goBackUrl, { preventScrollReset: true });
  }, [goBackUrl, navigate]);

  return { onClose, goBackUrl };
};

export const useNavigateFromActionSideModalWithParams = (config: {
  basePath: string;
}) => {
  const params = useParams();
  return useNavigateFromActionSideModal(params, config);
};
