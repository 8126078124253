import { BusinessTypesEnum } from 'lib/types';

export enum AverageLevelsEnum {
  low = 'low',
  normal = 'normal',
  high = 'high',
}

export enum ScoreTypesEnum {
  profit = 'profit',
  growth = 'growth',
  tacos = 'tacos',
  seasonality = 'seasonality',
  products = 'products',
}

export interface IScoreData {
  value: number;
  avg: number;
  high: number;
  low: number;
  min: number;
  max: number;
  diff: number | string;
  level: AverageLevelsEnum;
  percentile: number;
  percentage?: boolean;
}

export type IApiScores = {
  [ScoreTypesEnum.profit]: IScoreData;
  [ScoreTypesEnum.growth]: IScoreData;
  [ScoreTypesEnum.tacos]: IScoreData;
  [ScoreTypesEnum.seasonality]: IScoreData;
  [ScoreTypesEnum.products]: IScoreData;
  type: BusinessTypesEnum;
  loanId: string;
  analysisDate?: Date;
};

export type BenchmarkScoreTypes =
  | ScoreTypesEnum.profit
  | ScoreTypesEnum.growth
  | ScoreTypesEnum.tacos
  | ScoreTypesEnum.seasonality
  | ScoreTypesEnum.products;

export type IApiBusinessScores = {
  scores: IApiScores;
};

export type BenchmarkData = Pick<
  IScoreData,
  'value' | 'min' | 'max' | 'avg' | 'diff' | 'percentile' | 'percentage'
>;
