import { styled } from '@boopos/design-system';

const Badge = styled('span', {
  padding: '.4rem .8rem',
  borderRadius: '.8rem',
  fontSize: '1.2rem',
  lineHeight: '1.6rem',
  background: 'red',
  marginLeft: '.8rem',
  display: 'inline-block',
  fontWeight: 400,
});

const Td = styled('div', {
  padding: '.8rem 1.2rem',
  fontWeight: 700,
  border: '0.5px solid $surfaceStatic',
  display: 'block',
  '@bp2': {
    width: '50%',
    display: 'table-cell',
  },
  variants: {
    variant: {
      positive: {
        color: '$infoPositive',
        [`${Badge}`]: {
          color: '$decorationGreenDark',
          background: '$decorationGreenSoft',
        },
      },
      negative: {
        color: '$infoNegative',
        [`${Badge}`]: {
          color: '$decorationRedDark',
          background: '$decorationRedSoft',
        },
      },
    },
  },
});

const Th = styled('div', {
  backgroundColor: 'red',
  padding: '.8rem 1.2rem',
  background: '$surfaceStatic',
  fontWeight: 'normal',
  fontSize: '1.4rem',
  textAlign: 'left',
  color: '$textBodyTertiary',
  whiteSpace: 'nowrap',
  display: 'block',
  '@bp2': {
    width: '50%',
    display: 'table-cell',
  },
});

const Row = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  '@bp2': {
    display: 'table-row',
  },
});

export const Styles = {
  Table: styled('div', {
    display: 'block',
    '@bp2': {
      display: 'table',
    },
    borderRadius: '.8rem',
    border: '1px solid $surfaceStatic',
    overflow: 'hidden',
    width: '100%',
  }),
  Th,
  Row,
  Td,
  Indicator: styled('span', {
    background: 'red',
    width: '1.1rem',
    height: '1.1rem',
    borderRadius: '50%',
    marginRight: '.8rem',
    display: 'inline-block',
  }),
  Badge,
};
