export enum MoneyFormat {
  abbr = 'abbr',
  long = 'long',
}

export type CurrencyFormat = {
  [key in MoneyFormat]: any;
};

export interface IFormatByCurrency {
  [key: string]: CurrencyFormat;
}

export interface MoneyFormatConfig {
  format?: MoneyFormat;
  divider?: number;
  locale?: string;
  additionalOptions?: Intl.NumberFormatOptions;
}
