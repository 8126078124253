import { atomWithStorage } from 'jotai/utils';

import {
  BasePersistenceAtom,
  getPersistenceKey,
} from 'components/ClosingChecklistPersistence';

import { ContractSignersData } from './ContractSignersModal.interface';

export const contractSignersAtom = atomWithStorage<
  BasePersistenceAtom<ContractSignersData>
>(getPersistenceKey('contractSigners'), {});
