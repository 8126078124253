import useSWR from 'swr';

import { IQualificationStatus } from 'components/QualificationStatus';

import api from 'lib/api';
import { getDocumentFiles } from 'lib/files';
import { UserDocumentType } from 'lib/types';

import {
  parseAvailableFundsFormatted,
  parseDefaultValuesFromApi,
} from './ProofOfFunds.utils';

export const useProofOfFundsData = () => {
  const { data } = useSWR<IQualificationStatus>(api.getQualification);

  const funds = data?.funds;

  return {
    loadingData: !data,
    defaultValues: funds ? parseDefaultValuesFromApi(funds) : undefined,
    availableFundsFormatted: funds
      ? parseAvailableFundsFormatted(funds)
      : undefined,
    bankAccounts: funds?.bankAccounts,
    files: getDocumentFiles(UserDocumentType.kycFunds, funds?.documents),
  };
};
