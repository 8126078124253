import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';

import { ContentCellDetail } from 'ui/ContentCellDetail';
import { ListCard } from 'ui/ListCard';

import { AmountOrEmpty } from 'components/AmountOrEmpty';
import { DealStatusFeedback } from 'components/DealStatusFeedback';

import { ILoanApi } from 'lib/types';

import { routePaths } from 'router/routePaths';

import { DealFlowLoanAmount } from './DealFlowLoanAmount';

interface DealFlowCardProps {
  loan: ILoanApi;
}
export const DealFlowCard = forwardRef<HTMLDivElement, DealFlowCardProps>(
  (props, ref) => {
    const { t } = useTranslation();
    const { loan } = props;

    if (!loan) {
      return null;
    }

    const to = generatePath(routePaths.dealFlowDetail, { loanId: loan.id });

    const title = <ListCard.Title>{loan?.title}</ListCard.Title>;

    return (
      <ListCard.Card
        ref={ref as any}
        as={Link}
        to={to}
        data-testid="deal-flow-card"
      >
        <ListCard.TitleWithAction>{title}</ListCard.TitleWithAction>
        <ListCard.ValuesGrid>
          <ListCard.ValueItem>
            <ContentCellDetail.Label>
              {t('DealFlowCard:business_price')}
            </ContentCellDetail.Label>
            <ContentCellDetail.Value>
              <AmountOrEmpty
                amount={loan?.purchaseDealStructure?.businessPrice}
              />
            </ContentCellDetail.Value>
          </ListCard.ValueItem>
          <DealFlowLoanAmount loan={loan} />
        </ListCard.ValuesGrid>
        <DealStatusFeedback loan={loan} />
      </ListCard.Card>
    );
  }
);
