import { IBroker } from 'lib/types';

export type UseBrokersConfig = IBroker[];

export const getBrokersConfig = (apiBrokers?: UseBrokersConfig) => {
  if (!apiBrokers) {
    return {
      brokerList: [],
      availableBrokers: [],
    };
  }

  const availableBrokers = apiBrokers.map((broker) => broker.slug);

  const brokerList = apiBrokers as IBroker[];

  return {
    brokerList,
    availableBrokers,
  };
};
