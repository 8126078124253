import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDate } from '@boopos/utils';

import { ContentCellDetail } from 'ui/ContentCellDetail';

import { LoanFormattedAmount } from 'components/LoanDetailHelper';
import { useLoanBadges } from 'components/LoanStatusBadge/useLoanBadges';

import { formatMoney } from 'lib/format';
import { MoneyFormat } from 'lib/format/money/formatMoney.interface';
import { LoanStatusTags } from 'lib/types';

import { ILoanCard } from './LoanCard.interface';
import { LoanCardStyles } from './LoanCard.styles';
import { useLoanTypeText } from './useLoanTypeText';

export const LoanCard = forwardRef<HTMLAnchorElement, ILoanCard>(
  (props, ref) => {
    const { t } = useTranslation();
    const { loan, ...rest } = props;
    const { type, tags, createdAt, purchaseDealStructure } = loan;
    const loanTypeText = useLoanTypeText(type);
    const loanBadges = useLoanBadges(tags);
    const askingPrice = purchaseDealStructure?.businessPrice;

    const isDraft = loan.tags?.includes(LoanStatusTags.draft);

    const dateCopy = isDraft
      ? t('LoanCard:created_date', {
          requested: formatDate(createdAt, 'DD MMMM, YYYY'),
        })
      : t('LoanCard:requested_date', {
          requested: formatDate(createdAt, 'DD MMMM, YYYY'),
        });

    return (
      <LoanCardStyles.Card ref={ref} {...rest}>
        <LoanCardStyles.Item>
          <LoanCardStyles.Type>{loanTypeText}</LoanCardStyles.Type>
          <LoanCardStyles.Status>{loanBadges}</LoanCardStyles.Status>
        </LoanCardStyles.Item>
        <LoanCardStyles.Item>
          <ContentCellDetail.Label>
            {t('LoanCard:loan_amount')}
          </ContentCellDetail.Label>
          <ContentCellDetail.Value>
            <LoanFormattedAmount loan={loan} />
          </ContentCellDetail.Value>
        </LoanCardStyles.Item>
        {askingPrice && (
          <LoanCardStyles.Item>
            <ContentCellDetail.Label>
              {t('LoanCard:asking_price')}
            </ContentCellDetail.Label>
            <ContentCellDetail.Value>
              {formatMoney(purchaseDealStructure?.businessPrice, {
                format: MoneyFormat.long,
              })}
            </ContentCellDetail.Value>
          </LoanCardStyles.Item>
        )}
        <LoanCardStyles.Item dateColumn columnSpan={!askingPrice}>
          {dateCopy}
        </LoanCardStyles.Item>
      </LoanCardStyles.Card>
    );
  }
);
