import { useTranslation } from 'react-i18next';

import { useSubmitForEvaluationStep } from 'components/SubmitForEvaluation';

import { ILoanApi } from 'lib/types';

interface UseDealStructureProps {
  path: string;
  loan: ILoanApi;
  editable: boolean;
}

export const useDealStructureProps = ({
  path,
  loan,
  editable,
}: UseDealStructureProps) => {
  const { t } = useTranslation();

  const { purchaseDealStructure } = loan;

  const { contentCellProps } = useSubmitForEvaluationStep({
    path,
    editable,
    entity: purchaseDealStructure,
    actionLiteral: t('BasicInformationDetails:set'),
    hasValueGetter: (entity) => !!entity,
  });

  return {
    contentCellProps,
  };
};
