import { ChangeEvent, MouseEvent, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { AccordionItem, Checkbox } from '@boopos/design-system';

import { useBusinessCategories } from 'lib/hooks/useBusinessCategories';
import { ICategory } from 'lib/types';

import { IAccordionCategories } from './AccordionCategories.interface';
import { StyledAccordion } from './AccordionCategories.styled';

const defaultValue: string[] = [];

const SubCategoryItem = ({
  parentId,
  category,
}: {
  category: ICategory;
  parentId: string;
}) => {
  const fieldName = `category.${parentId}`;
  const categoryId = category.id;
  const { watch, setValue } = useFormContext();

  const currentValue = watch(fieldName) ?? defaultValue;
  const checked = currentValue?.includes(category.id);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setValue(fieldName, [...currentValue, categoryId]);
        return;
      }

      setValue(
        fieldName,
        currentValue.filter((value: string) => value !== categoryId)
      );
    },
    [fieldName, currentValue, setValue, categoryId]
  );

  return (
    <Checkbox
      type="checkbox"
      id={`subcategory-${parentId}-${category.id}`}
      value={category.id}
      checked={checked}
      onChange={handleChange}
      label={category.name}
      groupSpacing="none"
    />
  );
};

const CategoryItem = ({ category }: { category: ICategory }) => {
  const { watch, setValue } = useFormContext();

  const fieldName = `category.${category.id}`;
  const value = watch(fieldName);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.checked) {
        setValue(fieldName, []);
        return;
      }

      setValue(
        fieldName,
        category.categories?.map((subCategory: ICategory) => subCategory.id)
      );
    },
    [category, fieldName, setValue]
  );

  const onClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => e.stopPropagation(),
    []
  );

  return (
    <AccordionItem
      value={category.id}
      header={
        <Checkbox
          id={`category-${category.id}`}
          checked={value?.length > 0}
          onChange={handleChange}
          value={category.id}
          label={category.name}
          wrapperProps={{ onClick }}
          groupSpacing="none"
        />
      }
      data-testid={`item-${category.id}`}
    >
      {category?.categories?.map((subCategory: ICategory) => (
        <SubCategoryItem
          key={subCategory.id}
          parentId={category.id}
          category={subCategory}
        />
      ))}
    </AccordionItem>
  );
};

const AccordionCategories = ({
  defaultValue,
  type = 'multiple',
  defaultExpanded = true,
}: IAccordionCategories) => {
  const { categories, isLoading } = useBusinessCategories();

  if (isLoading) {
    return <div data-testid="loading" />;
  }

  return (
    <StyledAccordion
      type={type}
      defaultValue={(defaultExpanded ? defaultValue : []) as any}
      data-testid="categories-wrapper"
    >
      {categories?.map((category: ICategory) => (
        <CategoryItem category={category} key={category.id} />
      ))}
    </StyledAccordion>
  );
};

export { AccordionCategories };
