import { useTranslation } from 'react-i18next';

import config from 'lib/config';
import { LoanDocumentType } from 'lib/types';

import { ILoanDocumentUploadScreenConfig } from './LoanDocumentUploadScreen.interface';

export const useLoanDocumentUploadScreenConfig = ({
  documentType,
}: {
  documentType: LoanDocumentType;
}): ILoanDocumentUploadScreenConfig | null => {
  const { t } = useTranslation();

  const configMap: {
    [key: string]: {
      screenTitle: string;
      screenDescription: string;
      hint?: {
        title: string;
        description: string;
        sampleUrl?: string;
      };
    };
  } = {
    [LoanDocumentType.profitAndLoss]: {
      screenTitle: t('LoanDocumentUploadScreen:title_pnl'),
      screenDescription: t('LoanDocumentUploadScreen:description_pnl'),
      hint: {
        title: t('LoanDocumentUploadScreen:hint_title_pnl'),
        description: t('LoanDocumentUploadScreen:hint_description_pnl'),
        sampleUrl: config.PNL_SAMPLE_URL,
      },
    },
    [LoanDocumentType.balance_sheet]: {
      screenTitle: t('LoanDocumentUploadScreen:title_balance_sheet'),
      screenDescription: t(
        'LoanDocumentUploadScreen:description_balance_sheet'
      ),
      hint: {
        title: t('LoanDocumentUploadScreen:hint_title_balance_sheet'),
        description: t(
          'LoanDocumentUploadScreen:hint_description_balance_sheet'
        ),
      },
    },
    [LoanDocumentType.cohorts]: {
      screenTitle: t('LoanDocumentUploadScreen:title_cohorts'),
      screenDescription: t('LoanDocumentUploadScreen:description_cohorts'),
      hint: {
        title: t('LoanDocumentUploadScreen:hint_title_cohorts'),
        description: t('LoanDocumentUploadScreen:hint_description_cohorts'),
        sampleUrl: config.COHORTS_SAMPLE_URL,
      },
    },
    [LoanDocumentType.companyIdentifier]: {
      screenTitle: t('LoanDocumentUploadScreen:title_ein_letter'),
      screenDescription: t('LoanDocumentUploadScreen:description_ein_letter'),
      hint: {
        title: t('LoanDocumentUploadScreen:hint_title_ein_letter'),
        description: t('LoanDocumentUploadScreen:hint_description_ein_letter'),
      },
    },
    [LoanDocumentType.articlesOfIncorporation]: {
      screenTitle: t(
        'LoanDocumentUploadScreen:title_articles_of_incorporation'
      ),
      screenDescription: t(
        'LoanDocumentUploadScreen:description_articles_of_incorporation'
      ),
      hint: {
        title: t(
          'LoanDocumentUploadScreen:hint_title_articles_of_incorporation'
        ),
        description: t(
          'LoanDocumentUploadScreen:hint_description_articles_of_incorporation'
        ),
      },
    },
  };

  const screenConfig = configMap[documentType];

  if (!screenConfig) {
    return null;
  }

  return screenConfig;
};
