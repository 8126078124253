import { Link } from 'react-router-dom';

import { styled } from '@boopos/design-system';

import { CardSection } from 'ui/CardSection';

export const BuyerInsightsPreview = {
  Container: styled('div', {
    mt: '$4',
  }),
  Wrapper: styled('div', {
    maxWidth: '100%',
    display: 'grid',
    overflow: 'auto',
    gap: '1.6rem',
    variants: {
      columns: {
        3: {
          '@bp2': {
            gridTemplateColumns: 'repeat(3, 1fr)',
          },
        },
        2: {
          '@bp2': {
            gridTemplateColumns: 'repeat(2, 1fr)',
          },
        },
      },
    },
    '& *': {
      cursor: 'pointer',
    },
  }),
  ChartWrapper: styled('div', {
    display: 'flex',
    justifyContent: 'center',
  }),
  CardTitle: styled('div', {
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    color: '$slate800',
  }),
  SeeMoreCardTextWrapper: styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '$3',
    color: '$slate800',
    whiteSpace: 'nowrap',
  }),
  MoreInsightsCard: styled(CardSection, {
    flexDirection: 'row',
    justifyContent: 'center',
  }),
  SeeMoreCard: styled(Link, {
    textDecoration: 'none',
    borderRadius: '1.6rem',
    background: '$slate100',
    border: '1px solid $slate100',
    p: '$4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  SeeMoreCardIcon: styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '4rem',
    height: '4rem',
    lineHeight: 0,
    ml: '$1',
  }),
  Description: styled('div', {
    fontSize: '1.4rem',
    lineHeight: '2.2rem',
    mt: '$3',
    color: '$accent',
  }),
};
