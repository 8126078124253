import { styled } from '@boopos/design-system';

export const ListingDetailStyles = {
  Wrapper: styled('div', {
    backgroundColor: '$surfaceBase',
    borderRadius: '1.6rem',
    display: 'flex',
    flexDirection: 'column',
  }),
  Section: styled('div', {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '1.6rem',
    p: '1.6rem',
  }),
  InfoGrid: styled('div', {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
    columnGap: '1.6rem',
    rowGap: '1.2rem',
    '@bp2': {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },
  }),
  SectionIcon: styled('div', {
    minWidth: '4.2rem',
    maxWidth: '4.2rem',
    height: '4.2rem',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '$decorationBeigeSoft',
    color: '$decorationBeigeBright',
  }),
  SectionContent: styled('div', {
    width: '100%',
  }),
  SectionTitle: styled('div', {
    color: '$textBodyPrimary',
    height: '4.2rem',
    display: 'flex',
    alignItems: 'center',
  }),
  SectionTitleGrowth: styled('div', {
    flex: 1,
  }),
  SectionBody: styled('div', {
    color: '$textBodySecondary',
    mt: '.2rem',
    a: {
      color: '$textBodySecondary',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
  BottomChart: styled('div', {
    textAlign: 'center',
  }),

  StaticText: styled('div', {
    px: '1.6rem',
    py: '2.4rem',
    color: '$textBodyTertiary',
    a: {
      color: '$actionPrimaryDefault',
    },
  }),
};
