import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Input, Text } from '@boopos/design-system';

import paddleLogo from 'assets/account-providers/paddle-logo.png';
import PaddleAuthApiKeyGenerated from 'assets/paddle-auth-api-key-generated.png';
import PaddleAuthApiKeySetup from 'assets/paddle-auth-api-key-setup.png';
import PaddleAuthApiKey from 'assets/paddle-auth-api-key.png';
import PaddleAuthSection from 'assets/paddle-auth-section.png';

import {
  ConnectionStepWrapper,
  ProviderLogo,
} from 'components/AccountConnectionLayout';
import {
  ExplainerImage,
  LinkLike,
  StepContent,
  StepItem,
  StepLabel,
} from 'components/AccountConnectors/AccountConnectorsSteps';
import { ScreenWrapperContentCentered } from 'components/ScreenLayout';

const paddleAuthUrl = 'https://vendors.paddle.com/authentication-v2';

export const PaddleConnector = () => {
  const { t } = useTranslation();

  const { register, handleSubmit } = useForm<{
    apiKey: string;
  }>();

  const onSubmit = useCallback(({ apiKey }: { apiKey: string }) => {
    const generatedPublicToken = [apiKey].join(';');
    window.opener.customCallback(generatedPublicToken);
    window.close();
  }, []);

  return (
    <ScreenWrapperContentCentered
      data-testid="paddle-connector"
      css={{
        justifyContent: 'unset',
      }}
    >
      <ProviderLogo src={paddleLogo} alt="Paddle logo" />
      <ConnectionStepWrapper
        css={{ maxWidth: '60rem', minHeight: '100vh' }}
        data-testid="connect-step-1"
      >
        <StepItem>
          <StepLabel>1</StepLabel>
          <StepContent>
            <LinkLike href={paddleAuthUrl} target="_blank">
              {t('ConnectPaddleScreen:click_paddle_admin_panel')}
            </LinkLike>
          </StepContent>
        </StepItem>
        <StepItem>
          <StepLabel>2</StepLabel>
          <StepContent>
            <Text>{t('ConnectPaddleScreen:go_to_development_tools')}</Text>
          </StepContent>
        </StepItem>
        <StepItem>
          <ExplainerImage src={PaddleAuthSection} alt="" />
        </StepItem>
        <StepItem>
          <StepLabel>3</StepLabel>
          <StepContent>
            <Text>{t('ConnectPaddleScreen:create_api_key')}</Text>
          </StepContent>
        </StepItem>
        <StepItem>
          <ExplainerImage src={PaddleAuthApiKey} alt="" />
        </StepItem>
        <StepItem>
          <StepLabel>4</StepLabel>
          <StepContent>
            <Text>{t('ConnectPaddleScreen:api_key_setup')}</Text>
          </StepContent>
        </StepItem>
        <StepItem>
          <ExplainerImage src={PaddleAuthApiKeySetup} alt="" />
        </StepItem>
        <StepItem>
          <StepLabel>5</StepLabel>
          <StepContent>
            <Text>{t('ConnectPaddleScreen:api_key_generated')}</Text>
          </StepContent>
        </StepItem>
        <StepItem>
          <ExplainerImage src={PaddleAuthApiKeyGenerated} alt="" />
        </StepItem>
        <StepItem>
          <StepLabel>6</StepLabel>
          <StepContent>
            <Text>{t('ConnectPaddleScreen:create_access_token')}</Text>
          </StepContent>
        </StepItem>
        <form
          onSubmit={handleSubmit(onSubmit)}
          data-testid="connect-step-1-form"
        >
          <Input
            {...register('apiKey')}
            type="text"
            placeholder="111114e91e382357bc61b5e7067edd39"
            label={t('ConnectPaddleScreen:api_key_label')}
            required
            fullWidth
            data-testid="api-key-field"
          />
          <Button fullWidth>{t('ConnectPaddleScreen:finish')}</Button>
        </form>
      </ConnectionStepWrapper>
    </ScreenWrapperContentCentered>
  );
};
