import { useTranslation } from 'react-i18next';

import { Badge } from 'ui/Badge';
import { NavigationCard } from 'ui/NavigationCard';

import PlaidLogo from 'assets/plaid-logo.svg';

import { ProofOfFundsVariantProp } from './ProofOfFunds.interface';
import { useProofOfFundsUpdate } from './useProofOfFundsUpdate';

export const ConnectWithPlaid = ({
  variant,
  ...props
}: ProofOfFundsVariantProp) => {
  const { connectWithPlaid, loadingPlaid } = useProofOfFundsUpdate();
  const { t } = useTranslation();

  return (
    <NavigationCard
      variant={variant}
      tag={
        <Badge variant="olive">{t('ProofOfFundsValidate:recommended')}</Badge>
      }
      icon={
        <img
          src={PlaidLogo}
          width={105}
          height={40}
          alt={t('ProofOfFundsValidate:connect_plaid_title')}
        />
      }
      title={t('ProofOfFundsValidate:connect_plaid_title')}
      description={t('ProofOfFundsValidate:connect_plaid_description')}
      as="button"
      onClick={connectWithPlaid}
      loading={loadingPlaid}
      css={{ mb: '$6' }}
      {...props}
    />
  );
};
