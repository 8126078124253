export enum CreditReportSteps {
  residency = 'residency',
  creditReport = 'creditReport',
}

export enum Residency {
  us = 'us',
  nonUs = 'non-us',
}

export interface ICreditReportForm {
  ssn: string;
  residency: string;
  isSharingAuthorized: boolean;
  address: {
    street: string;
    city?: string;
    state?: string;
    zipCode?: string;
    country?: string;
  };
}
