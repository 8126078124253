import { User, useAuth0 } from '@auth0/auth0-react';

import { useCompanionMode } from 'lib/companion';

export const getAuth0PictureUrl = (
  user?: User,
  authUserPassWordSub = 'auth0|'
) => {
  if (!user) {
    return null;
  }

  if (user.sub?.includes(authUserPassWordSub)) {
    return null;
  }

  return user.picture;
};

export const useAuth0PictureUrl = () => {
  const { isCompanionMode } = useCompanionMode();
  const auth0 = useAuth0();

  if (isCompanionMode) {
    return null;
  }

  return getAuth0PictureUrl(auth0?.user);
};
