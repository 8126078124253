import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { AccountConnectionModal } from 'components/AccountConnectionModal';
import { MerchantType } from 'components/AccountConnectors';
import { useNavigateFromActionSideModal } from 'components/ActionSideModal';

import { routePaths } from 'router/routePaths';

export const RevenueSourcesModal = () => {
  const { t } = useTranslation();
  const params = useParams<{ loanId: string }>();
  const loanId = params.loanId as string;

  const { onClose } = useNavigateFromActionSideModal(
    {
      loanId,
    },
    { basePath: routePaths.dealFlowDetail }
  );

  const copies = useMemo(
    () => ({
      mainModalTitle: t('RevenueSourcesModal:title'),
      mainModalSubtitle: t('RevenueSourcesModal:subtitle'),

      inviteTitle: t('RevenueSourcesModal:invite_title'),
      inviteDescription: t('RevenueSourcesModal:invite_description'),
      sendInvite: t('RevenueSourcesModal:send_invite'),
      sendAnotherInvite: t('RevenueSourcesModal:send_another_invite'),
      invitationsTitle: t('RevenueSourcesModal:invitations_title'),

      connectTitle: t('RevenueSourcesModal:connect_title'),
      connectDescription: t('RevenueSourcesModal:connect_description'),
      connectMySelf: t('RevenueSourcesModal:connect_myself'),
      connectedBySellerTitle: t(
        'RevenueSourcesModal:connected_by_seller_title'
      ),
      connectAnother: t('RevenueSourcesModal:connect_another_account'),
      selfConnectedTitle: t('RevenueSourcesModal:self_connected_title'),

      connectModalTitle: t('RevenueSourcesModal:connect_modal_title'),

      inviteModalTitle: t('RevenueSourcesModal:invite_modal_title'),
      inviteModalDescription: t('RevenueSourcesModal:invite_modal_description'),
    }),
    [t]
  );

  return (
    <AccountConnectionModal
      copies={copies}
      onClose={onClose}
      loanId={loanId}
      accountType={MerchantType.revenueSource}
    />
  );
};
