import { useTranslation } from 'react-i18next';
import { NavLink, generatePath, useParams } from 'react-router-dom';

import { TabMenuItem } from 'ui/TabsMenu';

import { routePaths } from 'router/routePaths';

export const FinancingMenuItem = () => {
  const { t } = useTranslation();
  const { businessId } = useParams();

  return (
    <TabMenuItem
      as={NavLink}
      to={generatePath(routePaths.portfolioFinancing, {
        businessId,
      })}
      end
      data-testid="portfolio-menu-financing"
    >
      {t('BusinessDetailScreen:tab_menu_financing')}
    </TabMenuItem>
  );
};
