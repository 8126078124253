import { forwardRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Rating } from 'ui/Rating';

import { EnquiryStatusBadge } from 'components/EnquiryStatusBadge';
import { RenderIfDefined } from 'components/RenderIfDefined/RenderIfDefined';
import { UserAvatar } from 'components/UserAvatar';

import { EnquiryCardProps } from './EnquiryCard.interface';
import { CardStyles } from './EnquiryCard.styles';

export const EnquiryCard = forwardRef<HTMLDivElement, EnquiryCardProps>(
  ({ enquiry, to, ...props }, ref) => {
    const name = `${enquiry?.buyer?.firstName ?? ''} ${
      enquiry?.buyer?.lastName ?? ''
    }`;

    return (
      <CardStyles.Card
        ref={ref}
        {...props}
        {...(to ? { as: Link, to, preventScrollReset: true } : {})}
      >
        <CardStyles.Left>
          <UserAvatar name={name} variant={enquiry?.cssVariant} />
        </CardStyles.Left>
        <CardStyles.Right>
          <CardStyles.Header>
            <CardStyles.Title>{name}</CardStyles.Title>
            <CardStyles.Badges>
              <EnquiryStatusBadge status={enquiry?.status} />
            </CardStyles.Badges>
          </CardStyles.Header>
          <CardStyles.SubHeader>
            <RenderIfDefined value={enquiry?.advisorRating}>
              <Rating rating={enquiry?.advisorRating} maxRating={5} />
            </RenderIfDefined>
          </CardStyles.SubHeader>
          <CardStyles.ContentWrapper>
            <CardStyles.Content>{enquiry?.advisorNotes}</CardStyles.Content>
            <CardStyles.Action>
              <FontAwesomeIcon icon={solid('chevron-right')} />
            </CardStyles.Action>
          </CardStyles.ContentWrapper>
        </CardStyles.Right>
      </CardStyles.Card>
    );
  }
);

export const EnquiryCardLoading = () => (
  <CardStyles.Card>
    <CardStyles.Left>
      <Skeleton circle height={40} width={40} />
    </CardStyles.Left>
    <CardStyles.Right>
      <CardStyles.Header>
        <CardStyles.Title>
          <Skeleton height={16} width={120} />
        </CardStyles.Title>
      </CardStyles.Header>
      <CardStyles.ContentWrapper>
        <CardStyles.Content>
          <Skeleton height={16} width="50%" />
        </CardStyles.Content>
      </CardStyles.ContentWrapper>
    </CardStyles.Right>
  </CardStyles.Card>
);
