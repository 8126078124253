export const shouldHeaderStick = (
  scrollElement?: HTMLElement | null,
  headerElement?: HTMLDivElement | null
): boolean => {
  if (!scrollElement || !headerElement) return false;
  const top = scrollElement.scrollTop || 0;
  const { height = 0 } = headerElement.getBoundingClientRect() || {};

  if (!height) return false;

  return Math.abs(top) > height;
};
