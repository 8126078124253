import { PrimitiveAtom, useAtom } from 'jotai';
import { useCallback } from 'react';

import {
  BasePersistenceAtom,
  SetValueFn,
  UsePersistenceAtomReturn,
} from './ClosingChecklistPersistence.interface';

export const usePersistenceAtom = <T extends unknown>(
  atom: PrimitiveAtom<BasePersistenceAtom<T>>,
  loanId?: string
): UsePersistenceAtomReturn<T> => {
  const [value, setValue] = useAtom(atom);

  const getData = useCallback(
    (
      loanId: string,
      prevData: BasePersistenceAtom<T>,
      dataOrGetter: T | ((currentData: T) => T)
    ) => {
      if (typeof dataOrGetter === 'function') {
        return (dataOrGetter as Function)(prevData[loanId]?.data || ({} as T));
      }
      return dataOrGetter;
    },
    []
  );

  const handleChange = useCallback<SetValueFn<T>>(
    (dataOrDataGetter) => {
      if (!loanId) return;

      setValue((prev) => ({
        ...prev,
        [loanId]: {
          data: getData(loanId, prev, dataOrDataGetter),
        },
      }));
    },
    [setValue, loanId, getData]
  );

  const handleReset = useCallback(() => {
    if (!loanId) return;
    setValue((prevValue) =>
      Object.keys(prevValue)
        .filter((filteredLoanId) => filteredLoanId !== loanId)
        .reduce(
          (result, key) => ({ ...result, [key]: prevValue[key] }),
          {} as any
        )
    );
  }, [setValue, loanId]);

  const returnedValue = loanId ? value[loanId]?.data : undefined;

  return {
    value: returnedValue,
    setValue: handleChange,
    reset: handleReset,
  };
};
