import { useAtom } from 'jotai/index';
import { useCallback } from 'react';

import { listingUnlockModalOpened } from './ListingUnlock.atoms';

export const useListingUnlockModal = () => {
  const [opened, setOpened] = useAtom(listingUnlockModalOpened);

  const close = useCallback(() => {
    setOpened(false);
  }, [setOpened]);

  const open = useCallback(() => {
    setOpened(true);
  }, [setOpened]);

  return {
    opened,
    close,
    open,
  };
};
