import useSWR from 'swr';

import { IApiBusinessScores } from 'components/BusinessInsights';

import api from 'lib/api';
import { apiV2 } from 'lib/api';
import {
  ForSaleApplicationApi,
  IBusinessApi,
  ListingSellerType,
  SellApplicationStatus,
} from 'lib/types';

interface IMenuItemsVisibility {
  listing: boolean;
  enquiries: boolean;
  insights: boolean;
  loans: boolean;
  connections: boolean;
}

export const useMenuItemsVisibility = ({
  businessId,
}: {
  businessId?: string;
}): IMenuItemsVisibility => {
  const { data: business } = useSWR<IBusinessApi>(
    businessId && api.getPortfolioBusiness(businessId)
  );

  const { data: forSaleApplication } = useSWR<ForSaleApplicationApi>(
    businessId && apiV2.businessSellApplication(businessId)
  );

  const { data: insightsData } = useSWR<IApiBusinessScores>(
    businessId && api.getBusinessScores(businessId)
  );

  const enquiries =
    forSaleApplication?.listing?.sellerType === ListingSellerType.proprietary &&
    forSaleApplication?.status === SellApplicationStatus.published;

  const insights = !!Object.keys(insightsData?.scores ?? {}).length;
  const loans = !!business?.financingLoans?.length;
  const connections =
    loans ||
    forSaleApplication?.status === SellApplicationStatus.ready_to_publish;

  const listing = [enquiries, insights, loans, connections].some(
    (item) => item
  );

  return {
    listing,
    enquiries,
    insights,
    loans,
    connections,
  };
};
