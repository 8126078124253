import Skeleton from 'react-loading-skeleton';

import { Box } from '@boopos/design-system';

export const FilterLoading = () => (
  <Box css={{ mb: '$6' }} data-testid="filter-loading">
    <Skeleton height={24} borderRadius={16} width={48} />
    <Skeleton height={24} borderRadius={16} />
  </Box>
);
