import { styled } from '@boopos/design-system';

export const ConnectionStepWrapper = styled('div', {
  maxWidth: '44rem',
  width: '100%',
});

export const ProviderLogo = styled('img', {
  maxWidth: '25rem',
});
