import { Helmet } from 'react-helmet-async';
import {
  Navigate,
  generatePath,
  useLocation,
  useParams,
} from 'react-router-dom';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CenteredContentScreen } from 'ui/CenteredContentScreen';

import { BackButton } from 'components/BackButton';
import { AnimatedScreenOpacityWrapper } from 'components/ScreenLayout';
import { WatchListDocumentsUpload } from 'components/WatchListDocumentsUpload';

import { ILoanDocumentUploadScreen } from './LoanDocumentUploadScreen.interface';
import { useLoanDocumentUploadScreenConfig } from './useLoanDocumentUploadScreenConfig';

export const LoanDocumentUploadScreen = (props: ILoanDocumentUploadScreen) => {
  const params = useParams();
  const location = useLocation();
  const { loanId, businessId } = params;
  const { documentType, goBackUrl, goBackUrlOnError } = props;

  const screenConfig = useLoanDocumentUploadScreenConfig({
    documentType,
  });

  if (!screenConfig || !loanId) {
    return <Navigate to={goBackUrlOnError} />;
  }

  const doneUrl = generatePath(goBackUrl, {
    ...(loanId ? { loanId } : {}),
    ...(businessId ? { businessId } : {}),
  });

  const { screenTitle, screenDescription, hint } = screenConfig;

  return (
    <>
      <Helmet>
        <title>{screenTitle}</title>
      </Helmet>
      <AnimatedScreenOpacityWrapper data-testid="loan-document-upload-screen">
        <CenteredContentScreen.Wrapper>
          <BackButton
            to={doneUrl}
            state={{ backUrl: location.state?.backUrl }}
            hideOnDesktop={false}
            topSpace
            Icon={<FontAwesomeIcon icon={regular('xmark')} size="xl" />}
          />
          <CenteredContentScreen.Content>
            <CenteredContentScreen.Title>
              {screenTitle}
            </CenteredContentScreen.Title>
            <CenteredContentScreen.Description>
              {screenDescription}
            </CenteredContentScreen.Description>
            <WatchListDocumentsUpload
              hint={hint}
              loanId={loanId}
              documentType={documentType}
              doneUrl={doneUrl}
            />
          </CenteredContentScreen.Content>
        </CenteredContentScreen.Wrapper>
      </AnimatedScreenOpacityWrapper>
    </>
  );
};
