import { EnquiryCard } from 'components/EnquiryCard';

import { setLastElementRef } from 'lib/hooks/useInfiniteScroll';
import { EnquiryApi } from 'lib/types';
import { generatePathSafe } from 'lib/utils/generatePathSafe';

import { routePaths } from 'router/routePaths';

export const EnquiriesList = ({
  businessId,
  enquiries,
  lastElementRef,
}: {
  businessId?: string;
  enquiries: EnquiryApi[];
  lastElementRef: any;
}) => {
  return (
    <>
      {enquiries.map((enquiry, index) => (
        <EnquiryCard
          ref={setLastElementRef(enquiries, index, lastElementRef)}
          key={enquiry.id}
          enquiry={enquiry}
          to={generatePathSafe(routePaths.portfolioEnquiry, {
            businessId,
            enquiryId: enquiry.id,
          })}
        />
      ))}
    </>
  );
};
