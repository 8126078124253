import { useTranslation } from 'react-i18next';

import { Box, Button } from '@boopos/design-system';

import { Modal } from 'components/Modal';

interface QualificationProvideLaterModalProps {
  opened: boolean;
  onClose: () => void;
  onLeaveForLater: () => void;
}

export const QualificationProvideLaterModal = ({
  opened,
  onClose,
  onLeaveForLater,
}: QualificationProvideLaterModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      position="centered"
      maxWidth={340}
      title={t('QualificationProvideLaterModal:title')}
    >
      <Box css={{ px: '$4', pb: '$4' }}>
        <Box css={{ mb: '$6' }}>
          {t('QualificationProvideLaterModal:description')}
        </Box>
        <Button fullWidth onClick={onClose} css={{ mb: '$4' }}>
          {t('QualificationProvideLaterModal:provide_now')}
        </Button>
        <Button variant="secondary" fullWidth onClick={onLeaveForLater}>
          {t('QualificationProvideLaterModal:provide_later')}
        </Button>
      </Box>
    </Modal>
  );
};
