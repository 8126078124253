import { useRef } from 'react';
import useSWR from 'swr';

import { parseFromApi } from './CreditReport.utils';
import creditReportApi from './creditReportApi';

export const creditReportWithPrivacyKey = 'credit-report-with-privacy';

export const useCreditReportData = ({ cache = true }: { cache?: boolean }) => {
  const random = useRef(Date.now());
  const endpoint = cache
    ? creditReportWithPrivacyKey
    : [creditReportWithPrivacyKey, random.current];

  const { data: creditReport } = useSWR(endpoint, {
    fetcher: creditReportApi.get.bind(creditReportApi),
  });

  if (!creditReport) {
    return {
      creditReport: undefined,
      parsedCreditReport: undefined,
    };
  }

  return {
    creditReport,
    parsedCreditReport: parseFromApi(creditReport),
  };
};
