import { useTranslation } from 'react-i18next';

import { AcceptedFilesEnum } from 'components/UploadZone/UploadZone.interface';

import { LoanDocumentType } from 'lib/types';

import { BasicDocumentsUploadModal } from './BasicDocumentsUploadModal';

export const BalanceSheetUploadModal = ({ basePath }: { basePath: string }) => {
  const { t } = useTranslation();

  const props = {
    title: t('BasicDocumentsUploadModal:balance_sheet_title'),
    subtitle: t('BasicDocumentsUploadModal:balance_sheet_subtitle'),
    documentType: LoanDocumentType.balance_sheet,
    acceptedFiles: [
      AcceptedFilesEnum.csv,
      AcceptedFilesEnum.xls,
      AcceptedFilesEnum.xlsx,
    ],
  };

  return <BasicDocumentsUploadModal basePath={basePath} {...props} />;
};
