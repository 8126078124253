import React from 'react';

import { Merchants } from 'components/AccountConnectors';
import { merchantLiterals } from 'components/AccountConnectors/literals';
import { AccountHint } from './AccountHint';
import { Props } from './ConnectedAccountsDetailCell.interface';

export const AccountName = ({ account }: Props) => {
  const merchantName = merchantLiterals[account.provider] ?? account.provider;

  const hint = <AccountHint account={account} />;

  if (account.provider !== Merchants.bank) {
    return (
      <>
        {merchantName} {hint}
      </>
    );
  }

  return (
    <>
      {account?.metadata?.institution?.name ?? merchantName} {hint}
    </>
  );
};