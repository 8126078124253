import { Residency } from 'components/CreditReport';
import {
  IQualificationStatus,
  QualificationStatusApi,
  useQualificationStatus,
} from 'components/QualificationStatus';

import { useUser } from 'providers/UserProvider';

import { routePaths } from 'router/routePaths';

export const useUrlBasedOnQualificationStatus = (
  qualificationStatus?: IQualificationStatus
) => {
  const { linkedin, funds, creditReport } = qualificationStatus ?? {};

  if (linkedin?.status === QualificationStatusApi.EMPTY) {
    return routePaths.qualifyProfile;
  }

  if (funds?.status === QualificationStatusApi.EMPTY) {
    if (funds.disposableAmount) {
      return routePaths.qualifyProofOfFundValidate;
    }
    return routePaths.qualifyProofOfFund;
  }

  if (creditReport?.status === QualificationStatusApi.EMPTY) {
    if (!creditReport.residency) {
      return routePaths.onboardingProfileBuy;
    }

    if (creditReport.residency === Residency.us) {
      return routePaths.qualifyCreditReport;
    }

    return routePaths.accountQualification;
  }

  return null;
};

const useUrlBasedOnOnboardingInfo = () => {
  const { user } = useUser();

  const buyerTarget = user?.profile?.preferences?.buyerTarget?.target;

  if (!buyerTarget) {
    return routePaths.onboardingBuyTarget;
  }

  if (!user?.firstName || !user?.lastName || !user?.residency?.country) {
    return routePaths.onboardingProfileBuy;
  }

  return null;
};

export const useQualifyFlowDestination = () => {
  const qualificationStatus = useQualificationStatus();

  const onboardingUrl = useUrlBasedOnOnboardingInfo();

  const goToUrl =
    useUrlBasedOnQualificationStatus(qualificationStatus) ??
    routePaths.accountQualification;

  return {
    goToUrl: onboardingUrl ?? goToUrl,
    nextScreenOverwrite: goToUrl,
  };
};
