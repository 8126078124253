import { useTranslation } from 'react-i18next';

import { InlineFeedback } from '@boopos/design-system';

import { IDealStructureWarningsProps } from './DealStructureWarnings.interface';
import { PreviewAlertMessage } from './PreviewAlertMessage';

export const DealStructureWarnings = ({
  hasApiError,
  message,
}: IDealStructureWarningsProps) => {
  const { t } = useTranslation();

  return (
    <>
      {hasApiError && (
        <InlineFeedback
          data-testid="deal-structure-api-error"
          variant="warning"
          css={{ mb: '$6' }}
          heading={t('DealStructureForm:error_message')}
        />
      )}

      {message && <PreviewAlertMessage message={message} />}
    </>
  );
};
