import useSWR from 'swr';

import { apiV2 } from 'lib/api';
import { Money } from 'lib/types';

import { routePaths } from 'router/routePaths';

export const useInvestmentCriteriaBanner = () => {
  const { data: investmentCriteria } = useSWR(apiV2.getInvestmentCriteria());

  const categories = investmentCriteria?.categories ?? {};
  const hasCategories = !!Object.keys(categories).length;
  const hasKeywords = !!investmentCriteria?.keywords?.length;

  const hasAskPriceRange = investmentCriteria?.askPriceRange?.every(
    (item: Money | null) => typeof item?.amount === 'number'
  );

  const hasProfitRange = investmentCriteria?.profitRange?.every(
    (item: Money | null) => typeof item?.amount === 'number'
  );

  const isInvestmentCriteriaCompleted =
    hasCategories && hasAskPriceRange && hasProfitRange && hasKeywords;

  return {
    investmentCriteriaFlowPath: routePaths.investmentCriteria,
    isInvestmentCriteriaCompleted,
  };
};
