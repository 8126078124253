import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { createToastFeedback } from '@boopos/design-system';

import { usePersistenceAtom } from 'components/ClosingChecklistPersistence';
import { useClosingStepMatchStatus } from 'components/DealClosingCheckList';

import api from 'lib/api';
import { getDocumentsFiles } from 'lib/files';
import {
  ClosingCheckListStepStatus,
  ClosingStepsNames,
  ILoanApi,
} from 'lib/types';

import { letterOfIntentAtom } from './LetterOfIntent.atoms';
import {
  ILetterOfIntentData,
  IUseLetterOfIntentReturn,
} from './LetterOfIntent.interface';
import { parseToApi } from './LetterOfIntent.utils';

interface IUseLetterOfIntent {
  loanId: string;
  onSuccess?: () => void;
}

export const useLetterOfIntent = ({
  loanId,
  onSuccess,
}: IUseLetterOfIntent): IUseLetterOfIntentReturn => {
  const { t } = useTranslation();
  const { data: loan, mutate: refreshLoan } = useSWR<ILoanApi>(
    api.getLoan(loanId)
  );

  const [success, setSuccess] = useState(false);
  const letterOfIntentFiles = useMemo(
    () =>
      loan?.letterOfIntent
        ? getDocumentsFiles(loan?.letterOfIntent?.documents)
        : undefined,
    [loan]
  );

  const isWaitingForReview = useClosingStepMatchStatus({
    loan,
    status: ClosingCheckListStepStatus.waiting,
    name: ClosingStepsNames.letterOfIntent,
  });

  const isVerified = useClosingStepMatchStatus({
    loan,
    status: ClosingCheckListStepStatus.verified,
    name: ClosingStepsNames.letterOfIntent,
  });

  const {
    value: letterOfIntent,
    setValue: setLetterOfIntent,
    reset: resetLetterOfIntent,
  } = usePersistenceAtom<ILetterOfIntentData>(letterOfIntentAtom, loanId);

  const defaultValues = letterOfIntentFiles
    ? { letterOfIntentFiles }
    : letterOfIntent;

  const formMethods = useForm<ILetterOfIntentData>({
    defaultValues,
  });

  const { formState, watch } = formMethods;
  const isSubmitting = formState?.isSubmitting;

  const handleOnChange = useCallback(
    (data: ILetterOfIntentData) => {
      if (letterOfIntentFiles) return;
      setLetterOfIntent(data);
    },
    [setLetterOfIntent, letterOfIntentFiles]
  );

  const onDelete = useCallback(async () => {
    try {
      await api.deleteLetterOfIntent(loanId);
      if (letterOfIntentFiles) setLetterOfIntent({ letterOfIntentFiles });
      setTimeout(() => {
        refreshLoan();
      }, 1000);
    } catch (err) {
      console.error(err);
      createToastFeedback({
        title: t('DealClosingCheckList:unexpected_error_canceling_review'),
        type: 'error',
      });
    }
  }, [loanId, refreshLoan, letterOfIntentFiles, setLetterOfIntent, t]);

  const onSubmit = useCallback(
    async (data: ILetterOfIntentData) => {
      try {
        await api.updateLetterOfIntent(loanId, parseToApi(data));
        resetLetterOfIntent();
        setSuccess(true);

        setTimeout(() => {
          refreshLoan();
          onSuccess?.();
        }, 1000);
      } catch (err) {
        createToastFeedback({
          title: t('DealClosingCheckList:unexpected_error_sending_review'),
          type: 'error',
        });
      }
    },
    [loanId, onSuccess, refreshLoan, resetLetterOfIntent, t]
  );

  useEffect(() => {
    const subscription = watch((value) =>
      handleOnChange(value as ILetterOfIntentData)
    );
    return () => subscription.unsubscribe();
  }, [handleOnChange, watch]);

  return {
    formMethods,
    isSubmitting,
    isSuccess: success,
    isReady: !!loan,
    isWaitingForReview,
    isVerified,
    onSubmit,
    onDelete,
  };
};
