import { Text, styled } from '@boopos/design-system';

export const EnableDisableContent = styled('div', {
  opacity: 0.4,
  pointerEvents: 'none',
  transition: 'opacity 0.4s ease-out',

  variants: {
    enable: {
      true: {
        opacity: 1,
        pointerEvents: 'all',
      },
    },
  },
});

export const SummarySection = styled(EnableDisableContent, {
  mt: '$9',
  mb: '$6',
  p: '$4',
  br: '.8rem',
  bc: '$sky100',
  listStyle: 'none',
});

export const SummaryItem = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '$4',

  '&:last-child': {
    marginBottom: 0,
  },
});

export const TextMoney = styled(Text, {
  fontFamily: '$mono',
});
