import qs from 'query-string';
import { useMemo } from 'react';

import config from 'lib/config';

import { useUser } from 'providers/UserProvider';

export const useMeetingUrls = () => {
  const { user } = useUser();

  const params = useMemo(
    () =>
      user
        ? {
            email: user.email,
            firstname: user.firstName,
            lastname: user.lastName,
          }
        : {},
    [user]
  );

  const getMeetingUrl = (url: string) => `${url}?${qs.stringify(params)}`;

  return {
    qualificationMeetingUrl: getMeetingUrl(config.QUALIFICATION_MEETING_URL),
    sellerMeetingUrl: getMeetingUrl(config.SELLER_MEETING_URL),
  };
};
