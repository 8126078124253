import { Badge } from 'ui/Badge';

import { FormatUrl } from 'components/FormatUrl';

import { IBusinessApi } from 'lib/types';

import { Styles } from './BusinessDetailInfo.styles';

interface IBusinessDetailInfoProps {
  business: IBusinessApi;
}

export const BusinessDetailInfo = ({ business }: IBusinessDetailInfoProps) => {
  return (
    <Styles.Wrapper>
      <Badge>{business.businessTypeLiteral?.name}</Badge>
      <Styles.URLWrapper>
        <FormatUrl url={business.url} showLink={false} />
      </Styles.URLWrapper>
    </Styles.Wrapper>
  );
};
