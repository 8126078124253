import { styled } from '@boopos/design-system';

const Wrapper = styled('div', { display: 'flex', alignItems: 'center' });

const CurrencyValue = styled('div', {
  fontFamily: '$IBMPlexSans',
  color: '$textBodyPrimary',
  fontSize: '3.2rem',
  lineHeight: '3.2rem',
  fontWeight: '500',
});

export const CurrencyValueStyles = {
  Wrapper,
  CurrencyValue,
};
