export const defaultWindowFeatures = {
  width: 900,
  height: 900,
  resizable: 'no',
  toolbar: 'no',
  menubar: 'no',
  location: 'no',
  status: 'no',
};
export const parseWindowFeatures = (windowFeatures: any) =>
  Object.keys(windowFeatures)
    .map((key) => `${key}=${windowFeatures[key]}`)
    .join(',');
