import { Navigate } from 'react-router-dom';

import { useEffectOnce } from 'lib/hooks/useEffectOnce';
import { useTrackUserGoals } from 'lib/hooks/useTrackUserGoals';
import { noop } from 'lib/utils/noop';

import { routePaths } from 'router/routePaths';

export const GetValuationLanding = () => {
  const { trackInsightsGoal } = useTrackUserGoals();

  useEffectOnce(() => {
    trackInsightsGoal().then(noop).catch(noop);
  });

  return (
    <Navigate to={routePaths.onboardingSellerApplication.valuation} replace />
  );
};
