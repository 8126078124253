import { styled } from '@boopos/design-system';

export const BusinessCardStyles = {
  BadgeWrapper: styled('div', {
    display: 'flex',
    gap: '$2',
    marginTop: '$2',
    flexWrap: 'wrap',
    '> *': {
      whiteSpace: 'nowrap',
    },
  }),
  ValuationNotAvailable: styled('div', {
    whiteSpace: 'normal',
  }),
};
