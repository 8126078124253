import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';

import { Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { OnboardingLayout } from 'components/OnboardingLayout';
import { PortfolioApplicationContext } from 'components/PortfolioApplication/PorfolioApplicationRouteWrapper';

import { useMeetingUrls } from 'lib/meetings';
import { useSellApplicationFromUrlParams } from 'lib/sell-application/useSellApplicationFromUrlParams';
import { SellApplicationStatus } from 'lib/types';

import { Loading } from './Loading';
import { MissingInfo } from './MissingInfo';
import { SentForEvaluation } from './SentForEvaluation';

export const PortfolioApplicationFinished = () => {
  const { t } = useTranslation();
  const noCache = useMemo(() => Date.now(), []);
  const { sellApplication } = useSellApplicationFromUrlParams({
    keyModifier: noCache.toString(),
  });
  const navigate = useNavigate();
  const { closePath } = useOutletContext<PortfolioApplicationContext>();

  const { sellerMeetingUrl } = useMeetingUrls();

  const onScheduleMeeting = useCallback(() => {
    if (closePath) navigate(closePath, { replace: true });
    window.open(sellerMeetingUrl, '_blank');
  }, [navigate, sellerMeetingUrl, closePath]);

  if (!sellApplication) {
    return <Loading />;
  }

  const Actions = (
    <OnboardingLayout.InputHalf>
      <Button
        onClick={onScheduleMeeting}
        leftIcon={<FontAwesomeIcon icon={regular('calendar')} />}
      >
        {t('PortfolioApplicationFinished:action')}
      </Button>
      <Button variant="tertiary" as={Link} to={closePath}>
        {t('PortfolioApplicationFinished:not_now')}
      </Button>
    </OnboardingLayout.InputHalf>
  );

  if (sellApplication?.status === SellApplicationStatus.missing_info) {
    return <MissingInfo>{Actions}</MissingInfo>;
  }

  return <SentForEvaluation>{Actions}</SentForEvaluation>;
};
