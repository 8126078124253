import { RouterProvider } from 'react-router-dom';

import { appRoutes } from 'router/appRoutes';
import { createBrowserRouter } from 'router/createBrowserRouter';

const router = createBrowserRouter(appRoutes);

const App = () => <RouterProvider router={router} />;

export default App;
