import { useCallback, useEffect, useState } from 'react';
import { PlaidLinkOnExit, usePlaidLink } from 'react-plaid-link';

import api from 'lib/api';

const PROVIDER = 'bank';

interface BankProviderProps {
  onPreConnect?: () => void;
  onConnect?: Function;
  onExit?: PlaidLinkOnExit;
}

export const useBankProvider = ({
  onPreConnect,
  onConnect,
  onExit,
}: BankProviderProps = {}) => {
  const [initToken, setInitToken] = useState(null);
  const [done, setDone] = useState(false);

  const onSuccess = useCallback(
    (token: string) => {
      (async () => {
        onPreConnect?.();
        await api.connectMerchant(PROVIDER, token);
        setDone(true);
        onConnect?.();
      })();
    },
    [onPreConnect, onConnect]
  );

  const handleExit: PlaidLinkOnExit = useCallback(
    (...params) => {
      onExit?.(...params);
    },
    [onExit]
  );

  const { open: openPlaid } = usePlaidLink({
    token: initToken,
    onSuccess,
    onExit: handleExit,
  });

  const connect = useCallback(async () => {
    const { data } = await api.initMerchantConnection(PROVIDER);
    const { token } = data;
    setInitToken(token);
  }, []);

  useEffect(() => {
    if (initToken) openPlaid();
  }, [openPlaid, initToken]);

  return {
    connect,
    done,
  };
};
