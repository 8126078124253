import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { AddTargetBusinessForm } from 'components/AddTargetBusinessForm';
import { BackButton } from 'components/BackButton';
import { DealDetail } from 'components/DealDetail';
import { LayoutHideMenu } from 'components/Layout';
import {
  AnimatedScreenOpacityWrapper,
  SectionPartials,
} from 'components/ScreenLayout';

import { HSWidgetHide } from 'lib/hubspot';

import { routePaths } from 'router/routePaths';

export const AddTargetBusinessScreen = () => {
  const { t } = useTranslation();

  const title = t('AddTargetBusinessScreen:title');
  const description = t('AddTargetBusinessScreen:subtitle');

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <HSWidgetHide />
      <LayoutHideMenu hideOn="all" />
      <AnimatedScreenOpacityWrapper data-testid="add-target-business-screen">
        <DealDetail.Wrapper>
          <DealDetail.ContainerWrapper>
            <BackButton to={routePaths.dealFlow} hideOnDesktop={false} topSpace>
              {t('DealFlowListScreen:title')}
            </BackButton>
            <SectionPartials.Wrapper>
              <SectionPartials.Title>{title}</SectionPartials.Title>
              <SectionPartials.Description>
                {description}
              </SectionPartials.Description>

              <AddTargetBusinessForm />
            </SectionPartials.Wrapper>
          </DealDetail.ContainerWrapper>
        </DealDetail.Wrapper>
      </AnimatedScreenOpacityWrapper>
    </>
  );
};
