import { styled } from '@boopos/design-system';

export const ConnectedAccountCardStyles = {
  ImageWrapper: styled('div', {
    width: '13rem',
    borderRadius: '.8rem',
    border: '1px solid $slate300',
    p: '$2',
    '> img': {
      width: '100%',
    },
  }),
  Info: styled('div', {
    mt: '$3',
  }),
  AccountName: styled('div', {
    color: '$slate600',
    fontWeight: 'bold',
  }),
  Status: styled('div', {
    flex: 1,
    variants: {
      variant: {
        success: {
          color: '$lime600',
        },
      },
    },
  }),
  DeleteWrapper: styled('div', {
    position: 'absolute',
    top: '$4',
    right: '$4',
  }),
  Bottom: styled('div', {
    display: 'flex',
  }),
  Time: styled('div', {
    color: '$slate500',
  }),
  Card: styled('div', {
    position: 'relative',
    p: '$4',
    borderRadius: '.8rem',
    boxShadow:
      '0px 1px 4px rgba(0, 0, 0, 0.15), 0px 0px 4px rgba(0, 0, 0, 0.05)',
    '&:not(:last-child)': {
      mb: '$6',
    },
  }),
};
