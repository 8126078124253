import { useAtom } from 'jotai';
import { useCallback } from 'react';

import { IUseAccountConnectorsStatusReturn } from './AccountConnectors.interface';
import {
  isConnectingAtom,
  isErrorAtom,
  isSuccessAtom,
} from './ConnectAccountStatusAtoms';

export const useAccountConnectorsStatus =
  (): IUseAccountConnectorsStatusReturn => {
    const [isConnecting, setIsConnecting] = useAtom(isConnectingAtom);
    const [success, setSuccess] = useAtom(isSuccessAtom);
    const [error, setError] = useAtom(isErrorAtom);

    const handleReset = useCallback(() => {
      setIsConnecting(false);
      setSuccess(false);
      setError(false);
    }, [setIsConnecting, setSuccess, setError]);

    const cancelConnection = useCallback(() => {
      handleReset();
      // this could look nicer if it was possible to just store the close() function
      // however this does not work. The destroy() function from rutter can be stored and when called
      // causes the window opened by rutter to close
      // but the windows opened with window.open (erps in this case)
      // throw a "scripts can only close windows opened by them" error
      if ('close' in (window?.currentOpened ?? {}))
        return window.currentOpened?.close?.();
      window.currentOpened.destroy?.();
    }, [handleReset]);

    const startConnection = useCallback(() => {
      setIsConnecting(true);
    }, [setIsConnecting]);

    const endConnection = useCallback(() => {
      setIsConnecting(false);
    }, [setIsConnecting]);

    const addError = useCallback(() => {
      setError(true);
    }, [setError]);

    const removeError = useCallback(() => {
      setError(false);
    }, [setError]);

    const addSuccess = useCallback(() => {
      setSuccess(true);
    }, [setSuccess]);

    const removeSuccess = useCallback(() => {
      setSuccess(false);
    }, [setSuccess]);

    return {
      isConnecting,
      success,
      error,

      startConnection,
      endConnection,

      addError,
      removeError,
      addSuccess,
      removeSuccess,

      reset: handleReset,
      cancelConnection,
    };
  };
