import { Ref, useEffect, useRef, useState } from 'react';

export const useButtonHover = (): [Ref<HTMLButtonElement>, boolean] => {
  const [value, setValue] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  const enableHover = () => setValue(true);
  const disableOver = () => setValue(false);

  useEffect(
    () => {
      const node = ref.current;

      if (node) {
        node.addEventListener('click', disableOver);
        node.addEventListener('mouseenter', enableHover);
        node.addEventListener('mouseleave', disableOver);

        return () => {
          node.removeEventListener('click', disableOver);
          node.removeEventListener('mouseenter', enableHover);
          node.removeEventListener('mouseleave', disableOver);
        };
      }
    },
    [ref] // Recall only if ref changes
  );

  return [ref, value];
};
