import { useCallback, useMemo } from 'react';

import Api from 'lib/api';
import { getDocumentsFiles } from 'lib/files';
import { ILoanApi, LoanDocumentType } from 'lib/types';

import { AdditionalDetailsModalForm } from './AdditionalDetailsModal.interface';

export interface IUseLoanAdditionalDetails {
  loan: ILoanApi;
}

export const useLoanAdditionalDetails = ({
  loan,
}: IUseLoanAdditionalDetails) => {
  const loanId = loan.id;

  const currentDocument = useMemo(
    () =>
      loan?.documents?.filter?.(
        (item) => item.type === LoanDocumentType.additional_details
      ),
    [loan]
  );

  const additionalDetailsFiles = useMemo(
    () => getDocumentsFiles(currentDocument),
    [currentDocument]
  );

  const saveAdditionalDetails = useCallback(
    async (data: AdditionalDetailsModalForm) => {
      await Api.addAdditionalDetails(loanId, {
        additionalDetails: data.additionalDetails,
        document: {
          files: data.additionalDetailsFiles ?? [],
        },
      });
    },
    [loanId]
  );

  const defaultValues = {
    additionalDetails: loan.additionalDetails,
    additionalDetailsFiles: additionalDetailsFiles,
  };

  return { saveAdditionalDetails, defaultValues };
};
