import dayjs from 'dayjs';

import { InsightsRawData } from 'components/InsightsCharts/InsightsCharts.interface';

const REQUIRED_MONTHS = 24;

export const prependMissingMonths = (
  items?: InsightsRawData[]
): InsightsRawData[] => {
  if (!items?.length) {
    return [];
  }

  if (items.length >= REQUIRED_MONTHS) {
    return items;
  }

  const [firstItem] = items;
  const firstDate = dayjs(firstItem.dateAccounting);
  const totalMonths = items.length;
  const missingMonths = REQUIRED_MONTHS - totalMonths;

  const result = [] as InsightsRawData[];
  for (let i = 0; i < missingMonths; i++) {
    const date = firstDate.subtract(i + 1, 'month');

    result.push({
      dateAccounting: date.format('YYYY-MM-DD'),
      profitAmount: 0,
      revenueAmount: 0,
      cogsAmount: 0,
      tacosAmount: 0,
    });
  }

  return [...result.reverse(), ...items];
};
