import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { CenteredContentScreen } from 'ui/CenteredContentScreen';

import { BackButton } from 'components/BackButton';
import { EditBusinessForm } from 'components/EditBusinessForm';
import { usePortfolioBackButton } from 'components/PortfolioBackButton';
import { AnimatedScreenOpacityWrapper } from 'components/ScreenLayout';

import api from 'lib/api';
import { IBusinessApi } from 'lib/types';

export const EditBusinessScreen = () => {
  const { t } = useTranslation();
  const { businessId } = useParams();
  const { backButtonUrl } = usePortfolioBackButton();

  const { data: business } = useSWR<IBusinessApi>(
    businessId && api.getPortfolioBusiness(businessId)
  );

  const businessName = business?.name;

  return (
    <>
      <Helmet>
        <title>
          {t('EditBusinessScreen:title', {
            businessName,
          })}
        </title>
      </Helmet>
      <AnimatedScreenOpacityWrapper data-testid="add-business-screen">
        <CenteredContentScreen.Wrapper>
          <BackButton to={backButtonUrl} hideOnDesktop={false} topSpace>
            {t('PortfolioBackButton:portfolio')}
          </BackButton>
          <CenteredContentScreen.Content>
            <CenteredContentScreen.Title>
              {t('EditBusinessForm:title')}
            </CenteredContentScreen.Title>
            {!!business && (
              <EditBusinessForm business={business} goBackUrl={backButtonUrl} />
            )}
          </CenteredContentScreen.Content>
        </CenteredContentScreen.Wrapper>
      </AnimatedScreenOpacityWrapper>
    </>
  );
};
