import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, Button } from '@boopos/design-system';

import { AccountCard } from 'components/AccountCard';

import { IAccountApi, IBusinessAccount } from 'lib/types';

import { Styles } from 'screens/BusinessIntegrationsScreen/BusinessIntegrationsScreen.styles';

import { IntegrationsStyles } from './BusinessIntegrations.styles';

interface IBusinessIntegrationsProps {
  businessId: string;
  addIntegrationPath: string;
  onRemove?: () => void;
  accounts?: IAccountApi;
  wrapperCss?: any;
  titleCss?: any;
  cardProps?: any;
  hideHeader?: boolean;
}

type KeyOfIAccountApi = { [k in keyof IAccountApi]: string };

export enum ACCOUNT_CONNECTION_STATUSES {
  pending = 'pending',
  connected = 'connected',
}

export const BusinessIntegrations = ({
  businessId,
  accounts,
  onRemove,
  wrapperCss,
  titleCss,
  addIntegrationPath,
  hideHeader,
  cardProps = {},
}: IBusinessIntegrationsProps) => {
  const { t } = useTranslation();

  const ACCOUNT_CONNECTION_STATUS_LABELS: KeyOfIAccountApi = useMemo(
    () => ({
      [ACCOUNT_CONNECTION_STATUSES.pending]: t(
        'BusinessDetailScreen:pending_connection'
      ),
      [ACCOUNT_CONNECTION_STATUSES.connected]: t(
        'BusinessDetailScreen:connected'
      ),
    }),
    [t]
  );

  const numberOfAccounts = useMemo(() => {
    if (accounts) {
      return Object.values(accounts).flat(1).length;
    }
    return 0;
  }, [accounts]);

  const handleOnRemove = useCallback(async () => {
    onRemove?.();
  }, [onRemove]);

  const renderAccounts = useMemo(() => {
    if (!accounts) return null;

    return Object.entries(accounts).map((account) => {
      const [status, accounts] = account as unknown as [
        keyof KeyOfIAccountApi,
        IBusinessAccount[],
      ];

      const accountCards = accounts?.map?.((account: IBusinessAccount) => (
        <AccountCard
          key={account.id}
          account={account}
          businessId={businessId}
          className={Styles.gridItem()}
          onRemove={handleOnRemove}
          addIntegrationPath={addIntegrationPath}
          pendingConnection={status === ACCOUNT_CONNECTION_STATUSES.pending}
          data-testid="account-card"
          {...cardProps}
        />
      ));

      if (!accountCards?.length) return null;

      return (
        <Fragment key={status}>
          <IntegrationsStyles.StatusLabel>
            {ACCOUNT_CONNECTION_STATUS_LABELS[status]}
          </IntegrationsStyles.StatusLabel>
          <Styles.GridWrapper>{accountCards}</Styles.GridWrapper>
        </Fragment>
      );
    });
  }, [
    accounts,
    businessId,
    cardProps,
    handleOnRemove,
    ACCOUNT_CONNECTION_STATUS_LABELS,
    addIntegrationPath,
  ]);

  return (
    <Styles.Section css={wrapperCss}>
      {!hideHeader ? (
        <Styles.TitleWrapper>
          <Styles.Title css={titleCss}>
            {t('BusinessDetailScreen:integrations_title', {
              number: numberOfAccounts,
            })}
          </Styles.Title>
          <Box>
            {!!businessId && (
              <Button variant="secondary" as={Link} to={addIntegrationPath}>
                {t('BusinessDetailScreen:add_action')}
              </Button>
            )}
          </Box>
        </Styles.TitleWrapper>
      ) : null}
      <div>{renderAccounts}</div>
    </Styles.Section>
  );
};
