import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useOutlet } from 'react-router-dom';

import { PageHeader } from 'ui/PageHeader';

import {
  ForSaleInlineFilters,
  IForSaleFiltersContext,
  useForSaleFilters,
} from 'components/ForSaleFilters';
import { ForSaleInvestmentCriteriaBanner } from 'components/ForSaleInvestmentCriteriaBanner';
import { ForSaleList } from 'components/ForSaleList';
import { useInvestmentCriteriaBanner } from 'components/InvestmentCriteriaBanner/useInvestmentCriteriaBanner';
import { ListScreenLayout } from 'components/ListScreenLayout';
import { PageModalWrapper } from 'components/Modal';
import { QualificationBanner } from 'components/QualificationBanner';
import { AnimatedScreenOpacityWrapper } from 'components/ScreenLayout';

export const ForSaleScreen = () => {
  const { t } = useTranslation();

  const { formData, onSubmitFilters, onResetFilters, onApplyPartialFilters } =
    useForSaleFilters();

  const filtersOutletContext: IForSaleFiltersContext = {
    onSubmitFilters,
    onResetFilters,
    defaultFilters: formData,
  };

  const outlet = useOutlet(filtersOutletContext);

  const { isInvestmentCriteriaCompleted } = useInvestmentCriteriaBanner();

  const promoteAction = isInvestmentCriteriaCompleted
    ? 'qualify'
    : 'investmentThesis';

  return (
    <>
      <Helmet>
        <title>{t('ForSaleScreen:title')}</title>
      </Helmet>
      <AnimatedScreenOpacityWrapper data-testid="for-sale-screen">
        <ListScreenLayout.Wrapper>
          <PageHeader title={t('ForSaleScreen:title')} />
          {promoteAction === 'qualify' && (
            <QualificationBanner
              multiple
              visible={promoteAction === 'qualify'}
            />
          )}
          <ForSaleInlineFilters
            filters={formData}
            onApplyFilter={onApplyPartialFilters}
          />
          <ForSaleInvestmentCriteriaBanner
            visible={promoteAction === 'investmentThesis'}
          />
          <ListScreenLayout.List css={{ mt: '1.6rem' }}>
            <ForSaleList
              filters={formData}
              onResetFilters={onResetFilters}
              onApplyPartialFilters={onApplyPartialFilters}
            />
          </ListScreenLayout.List>
        </ListScreenLayout.Wrapper>
        <PageModalWrapper>{outlet}</PageModalWrapper>
      </AnimatedScreenOpacityWrapper>
    </>
  );
};
