import { ReactNode, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ButtonFeedback, Radio } from '@boopos/design-system';

import {
  ICreditReportForm,
  Residency,
} from 'components/CreditReport/CreditReport.interface';
import { parseFromApi } from 'components/CreditReport/CreditReport.utils';
import { IQualificationStatusCreditReport } from 'components/QualificationStatus';

import { UsResidentFields } from './USResidencyFields';

export interface CreditReportFormProps {
  defaultValues: ICreditReportForm;
  onSubmit: (
    data: ICreditReportForm,
    dirtyFields: any
  ) => Promise<IQualificationStatusCreditReport>;
  showResidencyRadios?: boolean;
  submitAction?: ReactNode;
  submitActionFullWidth?: boolean;
  submitActionRightIcon?: ReactNode;
}

export const CreditReportForm = ({
  defaultValues,
  onSubmit,
  showResidencyRadios = false,
  submitActionFullWidth = true,
  submitAction,
  submitActionRightIcon,
}: CreditReportFormProps) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const formMethods = useForm<ICreditReportForm>({
    defaultValues,
  });

  const { reset, register, handleSubmit, watch, formState } = formMethods;

  const residency = watch('residency');

  const _handleSubmit = useCallback(
    async (data: ICreditReportForm) => {
      setIsLoading(true);
      const revalidatedData = await onSubmit(data, formState.dirtyFields);
      reset(parseFromApi(revalidatedData));

      setIsLoading(false);
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 1000);
    },
    [reset, onSubmit, formState.dirtyFields]
  );

  return (
    <form onSubmit={handleSubmit(_handleSubmit)}>
      <FormProvider {...formMethods}>
        {showResidencyRadios && (
          <div data-testid="residency-radios">
            <Radio
              id="resident-us"
              value={Residency.us}
              label={t('CreditReportForm:us_resident_label')}
              data-testid="radio-resident-us"
              groupSpacing="none"
              {...register('residency')}
            />
            <Radio
              id="resident-non-us"
              value={Residency.nonUs}
              label={t('CreditReportForm:non_us_label')}
              data-testid="radio-resident-non-us"
              {...register('residency')}
            />
          </div>
        )}

        {residency === Residency.us && <UsResidentFields />}

        <ButtonFeedback
          fullWidth={submitActionFullWidth}
          isLoading={isLoading}
          isSuccess={isSuccess}
          data-testid="save-credit-report"
          rightIcon={submitActionRightIcon}
        >
          {submitAction}
        </ButtonFeedback>
      </FormProvider>
    </form>
  );
};
