import { CleaveOptions } from 'cleave.js/options';

import { Currency } from 'lib/types';

const defaultMoneyInputOptions = {
  numeral: true,
  rawValueTrimPrefix: true,
  numeralPositiveOnly: true,
};

export const cleaveMoneyInputOptions: {
  [key: string]: CleaveOptions;
} = {
  [Currency.USD]: {
    ...defaultMoneyInputOptions,
  },
  [Currency.EUR]: {
    ...defaultMoneyInputOptions,
    numeralDecimalMark: ',',
    delimiter: '.',
  },
};

export const negativeCleaveMoneyInputOptions: {
  [key: string]: CleaveOptions;
} = {
  [Currency.USD]: {
    ...cleaveMoneyInputOptions[Currency.USD],
    numeralPositiveOnly: false,
  },
  [Currency.EUR]: {
    ...cleaveMoneyInputOptions[Currency.EUR],
    numeralPositiveOnly: false,
  },
};
