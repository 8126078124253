import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createToastFeedback } from '@boopos/design-system';

import api from 'lib/api';
import { downloadFileBlob } from 'lib/files/downloadFileBlob';

export const useDownloadTermsheet = (id?: string, title: string = '') => {
  const [termsheetLoading, setTermsheetLoading] = useState(false);
  const { t } = useTranslation();

  const onDownloadTermSheetClick = useCallback(async () => {
    if (!id) return;
    setTermsheetLoading(true);
    try {
      const response = await api.downloadTermSheet(id);
      const termsheetUrl = response.data.url;

      const fetchedFile = await fetch(termsheetUrl);
      const blob = await fetchedFile.blob();

      await downloadFileBlob(
        blob,
        `${t('ListingInfo:term_sheet_name', { name: title })}.pdf`
      );
    } catch (e) {
      createToastFeedback({
        type: 'error',
        title: t('ListingInfo:term_sheet_download_error'),
      });
    } finally {
      setTermsheetLoading(false);
    }
  }, [id, t, title]);

  return [onDownloadTermSheetClick, termsheetLoading] as const;
};
