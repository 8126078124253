import React from 'react';
import { useTranslation } from 'react-i18next';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DeleteConfirmationAction } from 'components/ConfirmationModal';

import { useButtonHover } from 'lib/hooks/useButtonHover';

import {
  FilePreviewIcon,
  FilePreviewLink,
  FilePreviewName,
  FilePreviewNameText,
  FilePreviewWrapper,
  RemoveFileButton,
} from './FilePreview.styled';

interface IFilePreview {
  disabled?: boolean;
  id: string;
  downloadUrl: string;
  name: string;
  onDeleteFile: (fileId: string) => void;
}

const animationVariants = {
  hidden: {
    opacity: 0,
    height: 0,
    transition: {
      height: {
        duration: 0.4,
      },
      opacity: {
        duration: 0.25,
      },
    },
  },
  visible: {
    opacity: 1,
    height: 'auto',
    transition: {
      height: {
        duration: 0.4,
      },
      opacity: {
        duration: 0.25,
        delay: 0.15,
      },
    },
  },
  remove: { backgroundColor: 'red' },
};

export const FilePreview = ({
  id,
  downloadUrl,
  name,
  onDeleteFile,
  disabled,
}: IFilePreview) => {
  const { t } = useTranslation();
  const [hoverRef, isHovered] = useButtonHover();

  return (
    <FilePreviewWrapper
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={animationVariants}
      data-testid="file-preview"
      removeHover={isHovered}
    >
      <FilePreviewName>
        <FilePreviewLink href={downloadUrl} target="_blank" title={name}>
          <FilePreviewIcon>
            <FontAwesomeIcon icon={regular('file')} size="lg" />
          </FilePreviewIcon>
          <FilePreviewNameText>{name}</FilePreviewNameText>
        </FilePreviewLink>
      </FilePreviewName>
      {!disabled && (
        <DeleteConfirmationAction
          title={t('FileUploader:delete_confirm')}
          onDelete={onDeleteFile}
          deleteParams={id}
        >
          <RemoveFileButton
            type="button"
            ref={hoverRef}
            title={t('FileUploader:delete_action')}
            data-testid="delete-file-button"
          >
            <FontAwesomeIcon icon={regular('trash')} size="lg" />
          </RemoveFileButton>
        </DeleteConfirmationAction>
      )}
    </FilePreviewWrapper>
  );
};
