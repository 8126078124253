import {
  PostHogAnalyticsPlugin,
  SegmentAnalyticsPlugin,
} from 'lib/analytics/plugins';
import config from 'lib/config';

import { AnalyticsClient } from './AnalyticsClient';

const analyticsClientInstance = new AnalyticsClient();

analyticsClientInstance.addPlugin(
  new SegmentAnalyticsPlugin({
    writeKey: config.SEGMENT_KEY,
    cdnURL: config.SEGMENT_CDN,
    apiHost: config.SEGMENT_API,
  })
);

analyticsClientInstance.addPlugin(
  new PostHogAnalyticsPlugin({
    token: config.POSTHOG_TOKEN,
    apiHost: config.POSTHOG_API_HOST,
  })
);

export { analyticsClientInstance };
