import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@boopos/design-system';

import { FlowStatusFeedback } from 'ui/FlowStatusFeedback';

import PortfolioDraw from 'assets/illustrations/portfolio.svg';

import { routePaths } from 'router/routePaths';

export const PortfolioListEmptyState = () => {
  const { t } = useTranslation();

  return (
    <FlowStatusFeedback
      data-testid="portfolio-list-empty"
      title={t('PortfolioList:zero_results_title')}
      description={t('PortfolioList:zero_results_description')}
      illustration={
        <img src={PortfolioDraw} alt={t('PortfolioList:zero_results_title')} />
      }
      fullWidth={false}
    >
      <Button
        as={Link}
        to={routePaths.portfolioApplication.index}
        data-testid="add-business-button"
      >
        {t('PortfolioList:zero_results_add_business_action')}
      </Button>
    </FlowStatusFeedback>
  );
};
