import { Transition, motion } from 'framer-motion';
import { PropsWithChildren, forwardRef } from 'react';
import { useMedia } from 'react-use';

import { config } from '@boopos/design-system';

const transition: Transition = {
  type: 'spring',
  duration: 0.4,
  bounce: 0.25,
};

export const AnimatedScreenSlideWrapper = forwardRef<
  HTMLDivElement,
  PropsWithChildren
>((props, ref) => {
  const isDesktop = useMedia(config.media.bp2);

  const animationProps: any = !isDesktop
    ? {
        initial: { opacity: 0, x: '100%' },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: '100%' },
        transition,
      }
    : {};

  return (
    <motion.div
      ref={ref}
      style={{ position: 'relative', flex: 1 }}
      {...animationProps}
      {...props}
    />
  );
});
