import React from 'react';

import { TermSheetHandler } from 'components/TermSheetHandler';

import { AcceptTermSheetProps } from './AcceptTermSheet.interface';
import { AcceptTermSheetAction } from './AcceptTermSheetAction';
import { ContactUsAction } from './ContactUsAction';

export const AcceptTermSheet = (props: AcceptTermSheetProps) => {
  const { termSheet, ready } = props;

  if (!ready) return null;

  return (
    <TermSheetHandler termSheet={termSheet}>
      <TermSheetHandler.Base>
        <AcceptTermSheetAction {...props} />
      </TermSheetHandler.Base>
      <TermSheetHandler.External>
        <ContactUsAction />
      </TermSheetHandler.External>
      <TermSheetHandler.Empty>
        <ContactUsAction />
      </TermSheetHandler.Empty>
    </TermSheetHandler>
  );
};
