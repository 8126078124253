import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';

import { Button, config } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useListingUnlockCapture } from 'components/ListingUnlock';

import { ListingForSale } from 'lib/types';

export interface DownloadInfoMemoActionProps {
  listing?: ListingForSale;
}

export const DownloadInfoMemoAction = ({
  listing,
}: DownloadInfoMemoActionProps) => {
  const { t } = useTranslation();

  const disabled = listing?.infoMemoFiles?.redacted;
  const isDesktop = useMedia(config.media.bp2);
  const [infoMemo] = listing?.infoMemoFiles?.value ?? [];

  const actionProps = useListingUnlockCapture(disabled, {
    requiresAgreement: listing?.requiresAgreement,
    originalProps: {
      href: infoMemo?.downloadUrl,
    },
  });

  if (!infoMemo) {
    return null;
  }

  return (
    <Button
      as="a"
      target="_blank"
      data-testid="download-info-memo"
      variant="secondary"
      leftIcon={<FontAwesomeIcon icon={regular('file-arrow-down')} size="lg" />}
      rounded={!isDesktop}
      fullWidth={isDesktop}
      {...actionProps}
    >
      {t('ForSaleDetail:download_info_memo_action')}
    </Button>
  );
};
