import { useTranslation } from 'react-i18next';

import { Button } from '@boopos/design-system';

import { ModalOverSideModal } from 'components/ModalOverSideModal';

interface Props {
  onNotCancelClick: () => void;
  onCancelClick: () => void;
  visible: boolean;
}

export const CancelReviewConfirmation = ({
  visible,
  onCancelClick,
  onNotCancelClick,
}: Props) => {
  const { t } = useTranslation();
  return (
    <ModalOverSideModal
      visible={visible}
      data-testid="cancel-review-confirmation"
    >
      <ModalOverSideModal.Title>
        {t('CancelReviewConfirmation:title')}
      </ModalOverSideModal.Title>
      <ModalOverSideModal.Description>
        {t('CancelReviewConfirmation:description')}
      </ModalOverSideModal.Description>
      <ModalOverSideModal.CTAContainer>
        <Button
          type="button"
          variant="secondary"
          onClick={onNotCancelClick}
          data-testid="not-cancel-review-button"
        >
          {t('CancelReviewConfirmation:not_cancel')}
        </Button>
        <Button
          type="button"
          variant="error"
          onClick={onCancelClick}
          data-testid="confirm-cancel-review-button"
        >
          {t('CancelReviewConfirmation:cancel')}
        </Button>
      </ModalOverSideModal.CTAContainer>
    </ModalOverSideModal>
  );
};
