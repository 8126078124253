import { useTranslation } from 'react-i18next';

import { DealTimelineStep } from 'components/DealTimelineStep';

import { ILoanApi } from 'lib/types';

import { PastActivityProps } from './PastActivity.interface';

const useTitle = (loan?: ILoanApi) => {
  const { t } = useTranslation();
  const buyerInsightsEnabled = !!loan?.businessInsights;

  return buyerInsightsEnabled
    ? t('DealTimelineStep:accepted_loan_terms_title_with_insights')
    : t('DealTimelineStep:accepted_loan_terms_title');
};

export const AcceptedLoanTermsActivity = ({
  children,
  date,
  loan,
  ...rest
}: PastActivityProps) => {
  const title = useTitle(loan);

  return (
    <DealTimelineStep
      title={title}
      date={date}
      data-testid="activity-accepted-loan-terms"
      {...rest}
    >
      {children}
    </DealTimelineStep>
  );
};
