import { IForSaleFiltersForm } from './ForSaleFilters.interface';
import { useForSaleFiltersConfig } from './useForSaleFiltersConfig';

export const useForSaleInitialValues = (): IForSaleFiltersForm => {
  const filtersConfig = useForSaleFiltersConfig();

  if (filtersConfig.isLoading) {
    return {};
  }

  return {
    currency: filtersConfig.currency,
    askPriceRange: filtersConfig.askPrice.defaultValue,
    profitMultiplierRange: filtersConfig.profitMultiplier.defaultValue,
    revenue: filtersConfig.revenue.defaultValue,
    profitRange: filtersConfig.profit.defaultValue,
    brokers: [],
    otherBrokers: false,
    favorite: false,
    contacted: false,
    canAfford: false,
    lendingAvailable: false,
    category: {},
    isProprietary: false,
  };
};
