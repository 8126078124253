import { useCallback, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { routePaths } from 'router/routePaths';

export const useRequestLoanForms = () => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { businessId } = useParams();
  const navigate = useNavigate();

  const handleErrorRemove = useCallback(() => {
    setError(false);
  }, []);

  const navigateToLoanDetail = useCallback(
    (loanId: string) => {
      const loanDetailUrl = generatePath(routePaths.portfolioLoanDetail, {
        businessId,
        loanId,
      });

      navigate(loanDetailUrl, {
        state: {
          backUrl: generatePath(routePaths.portfolioFinancing, {
            businessId,
          }),
        },
      });
    },
    [businessId, navigate]
  );

  return {
    businessId,
    error,
    success,
    setError,
    setSuccess,
    handleErrorRemove,
    navigateToLoanDetail,
  };
};
