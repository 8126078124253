import useSWR from 'swr';

import Api from 'lib/api';

import { useUser } from 'providers/UserProvider';

interface IHubspotToken {
  token: string;
}

export const useHubspotToken = () => {
  const useUserReturn = useUser();
  const { user } = useUserReturn ?? {};
  const { data } = useSWR<IHubspotToken>(user && Api.getHubspotToken());

  if (!user || !data) {
    return null;
  }

  return { token: data.token, email: user.email };
};
