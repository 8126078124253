import { styled } from '@boopos/design-system';

export const Styles = {
  NotesWrapper: styled('div', {
    py: '$3',
    fontStyle: 'italic',
    '> div:not(:last-child)': {
      mb: '$2',
    },
  }),
};
