import { useMemo } from 'react';

import { useParentCategories } from './useParentCategories';
import { useParsedCategories } from './useParsedCategories';

export const GENERIC_OTHER = 'other-business';

export const useRollingFlow = () => {
  const { parentCategories } = useParentCategories();
  const parsedCategories = useParsedCategories();

  const rollTroughIds = useMemo(
    () =>
      parentCategories.filter((id) => {
        if (id === GENERIC_OTHER) {
          return true;
        }

        return !!parsedCategories?.find((category) => category.id === id)
          ?.categories?.length;
      }),
    [parsedCategories, parentCategories]
  );

  return {
    rollTroughIds,
  };
};
