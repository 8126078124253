import { useTranslation } from 'react-i18next';

import { Button } from '@boopos/design-system';

import { useListingUnlockAction } from './useListingUnlockAction';

export const ListingUnlockAction = ({
  requiresAgreement,
}: {
  requiresAgreement?: boolean;
}) => {
  const { open } = useListingUnlockAction(requiresAgreement);

  const { t } = useTranslation();

  return (
    <Button
      variant="secondary"
      size="reduced"
      onClick={open}
      data-testid="listing-unlock-action"
    >
      {t('ListingUnlockAction:unlock_listing')}
    </Button>
  );
};
