import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NavigationCard } from 'ui/NavigationCard';

import { Avatar } from 'components/MainMenu/Nav.styled';
import {
  OnboardingHeader,
  OnboardingLayout,
} from 'components/OnboardingLayout';

import { AuthParamsUserGoals } from 'lib/auth/authParams';
import { useTrackUserGoals } from 'lib/hooks/useTrackUserGoals';

import { routePaths } from 'router/routePaths';

import { OnboardingLocationState } from 'screens/LandingScreens/useOnboardingState';

export const OnboardingNavigation = () => {
  const location = useLocation() as { state: OnboardingLocationState };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { trackBuyerGoal, trackSellerGoal } = useTrackUserGoals();

  const navigateToQualify = useCallback(async () => {
    await trackBuyerGoal();
    navigate(routePaths.onboardingProfileBuy, { state: location?.state });
  }, [navigate, trackBuyerGoal, location?.state]);

  const navigateToAddBusinessSale = useCallback(async () => {
    await trackSellerGoal();
    navigate(routePaths.onboardingProfileSell, { state: location?.state });
  }, [navigate, trackSellerGoal, location?.state]);

  const autoSetGoalActions = {
    [AuthParamsUserGoals.seller]: navigateToAddBusinessSale,
    [AuthParamsUserGoals.buyer]: navigateToQualify,
  };

  const autoSetGoalState = location?.state?.autoSetGoal;

  const autoSetGoal =
    !!autoSetGoalState && autoSetGoalActions[autoSetGoalState];

  useEffect(() => {
    if (!autoSetGoal) return;
    autoSetGoal();
  }, [autoSetGoal]);

  return (
    <OnboardingLayout.Wrapper
      data-testid="onboarding-navigation-screen"
      verticalSpacing="xl"
    >
      <OnboardingHeader.Wrapper>
        <OnboardingHeader.Steps>
          {t('OnBoardingNavigation:greeting')}
        </OnboardingHeader.Steps>
        <OnboardingHeader.Title>
          {t('OnBoardingNavigation:title')}
        </OnboardingHeader.Title>
      </OnboardingHeader.Wrapper>
      <OnboardingLayout.Content>
        <OnboardingLayout.ContentWrapper>
          <NavigationCard
            title={t('OnBoardingNavigation:acquire_business_title')}
            description=""
            onClick={navigateToQualify}
            data-testid="acquire-business-button"
            variant="light"
            icon={
              <Avatar.AvatarCrop>
                <FontAwesomeIcon icon={solid('magnifying-glass')} size="lg" />
              </Avatar.AvatarCrop>
            }
          />
          <NavigationCard
            title={t('OnBoardingNavigation:sell_business_title')}
            description=""
            onClick={navigateToAddBusinessSale}
            data-testid="sell-business-button"
            variant="light"
            icon={
              <Avatar.AvatarCrop>
                <FontAwesomeIcon icon={solid('briefcase')} size="lg" />
              </Avatar.AvatarCrop>
            }
          />
        </OnboardingLayout.ContentWrapper>
      </OnboardingLayout.Content>
    </OnboardingLayout.Wrapper>
  );
};
