import { Modal } from 'components/Modal';

import { ListingForSale } from 'lib/types';

import { useListingUnlockModal } from './useListingUnlockModal';
import { useListingUnlockModalConfig } from './useListingUnlockModalConfig';

export const ListingUnlockModal = ({
  listing,
}: {
  listing?: ListingForSale;
}) => {
  const { opened, close } = useListingUnlockModal();

  const modalConfig = useListingUnlockModalConfig(listing);

  if (!modalConfig) {
    return null;
  }

  const { title, content } = modalConfig;

  return (
    <Modal
      opened={opened}
      onClose={close}
      position="bottomSheetCentered"
      maxWidth={522}
      title={title}
      renderContentContainer
    >
      {content}
    </Modal>
  );
};
