import {
  ClosingCheckListStepStatus,
  ClosingStepsNames,
  ILoanApi,
} from 'lib/types';

export const useClosingStepMatchStatus = ({
  loan,
  status,
  name,
}: {
  loan?: ILoanApi;
  status: ClosingCheckListStepStatus;
  name: ClosingStepsNames;
}): boolean =>
  !!loan?.closingChecklist?.data?.find(
    (item) => item.status === status && item.name === name
  );
