import { styled } from '@boopos/design-system';

const IconWrapper = styled('div', {
  color: '$textBodyTertiary',
  lineHeight: 0,
  width: '2.4rem',
  height: '2.4rem',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const HintWrapper = styled('div', {
  color: '$textBodyTertiary',
  fontSize: '1.6rem',
  lineHeight: '2.4rem',
  fontFamily: '$mono',
});

export const FilterItemStyles = {
  ScrollWrapper: styled('div', {
    overflowX: 'scroll',
    position: 'relative',
    left: '-$4',
    width: '100vw',
    paddingLeft: '$4',
    '&::-webkit-scrollbar': {
      height: 0,
    },
    '&::-webkit-scrollbar-track': {
      background: '$surfaceSelected',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '$surfaceElevated',
    },
    '@bp2': {
      overflowX: 'unset',
      left: 0,
      width: 'auto',
      paddingLeft: 0,
    },
  }),
  Wrapper: styled('div', {
    display: 'flex',
    gap: '$2',
    flexWrap: 'nowrap',

    '&:after': {
      content: '',
      minWidth: '1.6rem',
      display: 'inline-block',

      '@bp2': {
        display: 'none',
      },
    },
  }),
  ItemWrapper: styled('button', {
    textDecoration: 'none',
    display: 'inline-flex',
    whiteSpace: 'nowrap',
    gap: '$2',
    cursor: 'pointer',
    appearance: 'none',
    outline: 'none',
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    color: '$textBodySecondary',
    background: '$surfaceElevated',
    border: '2px solid $borderInteractiveDefault',
    py: '$2',
    px: '$4',
    borderRadius: '.8rem',
    '&:hover': {
      background: '$surfaceSelected',
      borderColor: '$borderInteractiveHover',
    },
    '&:active': {
      borderColor: '$borderInteractiveFocus',
    },
    '&:focus-visible': {
      boxShadow: '0 0 0 4px $colors$borderInteractiveFocus',
    },
    variants: {
      disabled: {
        true: {
          '&, &:hover, &:active, &:focus, &:focus-visible': {
            background: '$actionDisabledDisabled',
            borderColor: '$borderInteractiveDisabled',
            color: '$textBodyTertiary',
          },
          [`${IconWrapper}`]: {
            color: '$textBodyTertiary',
          },
          [`${HintWrapper}`]: {
            color: '$textBodyTertiary',
          },
        },
      },
      selected: {
        true: {
          background: '$surfaceSelected',
          borderColor: '$borderInteractiveActive',
          color: '$textBodyPrimary',
          '&:hover': {
            borderColor: '$borderInteractiveActive',
          },
          '&:active': {
            borderColor: '$borderInteractiveFocus',
          },
        },
      },
    },
    compoundVariants: [
      {
        disabled: true,
        selected: true,
        css: {
          '&, &:hover, &:active, &:focus': {
            background: '$actionDisabledDisabled',
            borderColor: '$borderInteractiveDisabled',
            color: '$textBodyTertiary',
          },
        },
      },
    ],
  }),
  IconWrapper,
  HintWrapper,
};
