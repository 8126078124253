import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { useListingUnlockAction } from 'components/ListingUnlock/useListingUnlockAction';

import { apiV2 } from 'lib/api';
import { ListingForSale } from 'lib/types';

import { BuyerBusinessInsights } from './BuyerBusinessInsights';
import { BuyerBusinessInsightsEmpty } from './BuyerBusinessInsightsEmpty';

export const ForSaleBusinessInsights = () => {
  const params = useParams();
  const { listingId } = params ?? {};
  const { data: listing } = useSWR<ListingForSale>(
    listingId && apiV2.getListing(listingId)
  );

  const { open } = useListingUnlockAction(listing?.requiresAgreement);

  if (listing && !listing.businessInsights) {
    return <BuyerBusinessInsightsEmpty businessName="" />;
  }

  return (
    <BuyerBusinessInsights
      businessName={listing?.title}
      businessInsights={listing?.businessInsights}
      data-testid="for-sale-business-insights"
      onUnlock={open}
    />
  );
};
