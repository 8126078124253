import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ShareSellingDealButton } from 'components/ShareSellingDealButton';
import { TermSheetHandler } from 'components/TermSheetHandler';

import {
  ApplicationBusinessApi,
  ILoanApi,
  ITermSheetApi,
  SaleSellerApplicationApi,
  SellApplicationStatus,
} from 'lib/types';

export const ActionShareListing = ({
  application,
  business,
  loanTermSheet,
  status,
  ...rest
}: {
  application?: SaleSellerApplicationApi;
  business?: ApplicationBusinessApi;
  loanTermSheet?: ITermSheetApi;
  status?: SellApplicationStatus;
}) => {
  const { t } = useTranslation();

  const fakeLoan = useMemo(
    () => ({
      id: application?.id,
      isSellerSaleLoan: true,
      business: {
        name: business?.name,
      },
      amount: loanTermSheet?.amount,
    }),
    [application, business, loanTermSheet]
  );

  return (
    <TermSheetHandler termSheet={loanTermSheet}>
      <TermSheetHandler.Base>
        <ShareSellingDealButton
          loan={fakeLoan as ILoanApi}
          variant="secondary"
          css={{ mb: 0 }}
          {...rest}
        >
          {t('ForSaleDetailApplication:actions_share_listing')}
        </ShareSellingDealButton>
      </TermSheetHandler.Base>
      <TermSheetHandler.Empty>{null}</TermSheetHandler.Empty>
      <TermSheetHandler.External>{null}</TermSheetHandler.External>
    </TermSheetHandler>
  );
};
