import { AxiosError } from 'axios';

import { ApiError, ApiErrorData, ApiErrorMessage } from 'lib/types';

interface ParsedError {
  name: string;
  error: string;
}

const getErrors = (errors: ApiError[], path: string) => {
  return (errors || []).reduce(
    (errorAcc, err) => [...errorAcc, { name: path, error: err.code }],
    [] as ParsedError[]
  );
};

const getMessagesArray = (message?: string | ApiErrorMessage[]) => {
  if (!message || typeof message === 'string') return [];
  return message;
};

const parseApiError = (err: AxiosError<ApiErrorData>) => {
  const messages = getMessagesArray(err?.response?.data?.message);

  return messages.reduce(
    (acc, { path, errors }) => [...acc, ...getErrors(errors, path)],
    [] as ParsedError[]
  );
};

export { parseApiError };
