import qs from 'query-string';
import { useEffect } from 'react';

export const useAutoOpenConnection = (
  provider: string,
  open: () => void,
  ready: boolean
) => {
  useEffect(() => {
    const { provider: qsProvider } = qs.parse(window.location.search) || {};
    let opened = false;

    if (opened) return;

    if (provider.toLowerCase() === qsProvider) {
      if (ready) {
        open();
        opened = true;
        return;
      }
      const interval = setInterval(() => {
        if (ready) {
          open();
          opened = true;
          clearInterval(interval);
        }
      }, 200);
    }
  }, [provider, open, ready]);
};
