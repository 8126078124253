import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CenteredContentScreen } from 'ui/CenteredContentScreen';

import { AddShareholderForm } from 'components/AddShareholderForm';
import { BackButton } from 'components/BackButton';
import { AnimatedScreenOpacityWrapper } from 'components/ScreenLayout';

import api from 'lib/api';
import { ILoanApi } from 'lib/types';

export const AddShareholderScreen = ({ goBackUrl }: { goBackUrl: string }) => {
  const { t } = useTranslation();
  const title = t('ShareHoldersScreen:add_shareholder_action');
  const { businessId, loanId } = useParams();

  const goBackUrlGenerated = generatePath(goBackUrl, {
    ...(businessId ? { businessId } : {}),
    ...(loanId ? { loanId } : {}),
  });

  const { data: loan } = useSWR<ILoanApi>(
    businessId && loanId && api.getPortfolioLoan(businessId, loanId)
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AnimatedScreenOpacityWrapper data-testid="add-shareholder-screen">
        <CenteredContentScreen.Wrapper>
          <BackButton
            to={goBackUrlGenerated}
            hideOnDesktop={false}
            topSpace
            Icon={<FontAwesomeIcon icon={regular('xmark')} size="xl" />}
          />
          <CenteredContentScreen.Content>
            <CenteredContentScreen.Title>
              {t('ShareHoldersScreen:add_shareholder_action')}
            </CenteredContentScreen.Title>
            {!!loan && (
              <AddShareholderForm
                goBackUrl={goBackUrlGenerated}
                businessId={loan?.business?.id}
                loanId={loan?.id}
              />
            )}
          </CenteredContentScreen.Content>
        </CenteredContentScreen.Wrapper>
      </AnimatedScreenOpacityWrapper>
    </>
  );
};
