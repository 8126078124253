import { Box } from '@boopos/design-system';

import { InvestmentCriteriaBanner } from 'components/InvestmentCriteriaBanner';
import { useInvestmentCriteriaBanner } from 'components/InvestmentCriteriaBanner/useInvestmentCriteriaBanner';
import {
  QualificationStatusApi,
  useQualificationStatus,
} from 'components/QualificationStatus';

export const ForSaleInvestmentCriteriaBanner = ({
  visible = false,
}: {
  visible?: boolean;
}) => {
  const { isInvestmentCriteriaCompleted } = useInvestmentCriteriaBanner();
  const { status } = useQualificationStatus() || {};
  const isUserQualified = status === QualificationStatusApi.QUALIFIED;

  const isVisible =
    (visible && !isInvestmentCriteriaCompleted) ||
    (!isUserQualified && !isInvestmentCriteriaCompleted);

  if (!isVisible) {
    return null;
  }

  return (
    <Box css={{ mt: '1.6rem' }}>
      <InvestmentCriteriaBanner />
    </Box>
  );
};
