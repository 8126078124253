import {
  IDealStructureFields,
  IDealStructurePreviewData,
} from 'components/DealStructureHelpers/DealStructure.interface';

import {
  parseMoneyFromApi,
  parseMoneyFromApiToString,
  parseMoneyToApiOrUndefined,
} from 'lib/parse';
import { Currency, IDealStructure, Money } from 'lib/types';

export const parseToApi = (data: IDealStructureFields, currency: Currency) => ({
  businessPrice: parseMoneyToApiOrUndefined(data?.businessPrice, currency),
  inventoryPrice: parseMoneyToApiOrUndefined(data?.inventoryPrice, currency),
  sellerFinancing: parseMoneyToApiOrUndefined(data?.sellerFinancing, currency),
  requestedAmount: parseMoneyToApiOrUndefined(data?.requestedAmount, currency),
});

export const getDealStructureDefaultValues = (
  data: IDealStructure
): IDealStructureFields => {
  const { businessPrice, inventoryPrice, sellerFinancing, requestedAmount } =
    data || {};

  return {
    businessPrice: parseMoneyFromApiToString(businessPrice),
    inventoryPrice: parseMoneyFromApiToString(inventoryPrice) || '0',
    sellerFinancing: parseMoneyFromApiToString(sellerFinancing),
    requestedAmount: parseMoneyFromApiToString(requestedAmount),
  };
};

export const getDealStructureParsedValues = ({
  requestedAmount,
  sellerFinancing,
  maxRequestedAmount,
  maxSellerFinancing,
}: IDealStructurePreviewData) => {
  return {
    parsedRequestedAmount: parseMoneyFromApi(requestedAmount as Money),
    parsedSellerFinancing: parseMoneyFromApi(sellerFinancing as Money),
    parsedMaxRequestedAmount: parseMoneyFromApi(maxRequestedAmount as Money),
    parsedMaxSellerFinancing: parseMoneyFromApi(maxSellerFinancing as Money),
  };
};

export const getFixedMaxValue = (value: number, max: number) =>
  value >= max ? max : value;
