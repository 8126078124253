export const useStepVariant = (
  isMatching: boolean,
  { hasValue, hasError }: { hasValue?: boolean; hasError?: boolean }
): 'active' | 'success' | 'error' | undefined => {
  if (isMatching) {
    return 'active';
  }

  if (hasValue) {
    return 'success';
  }

  if (hasError) {
    return 'error';
  }

  return undefined;
};
