import { ContactSellerModal } from 'components/ContactSellerModal';
import { ForSaleDetail, ForSaleDetailParent } from 'components/ForSaleDetail';
import { ForSaleSidebar } from 'components/ForSaleFilters';

import { routePaths } from 'router/routePaths';

import { ForSaleBusinessInsights } from 'screens/BuyerBusinessInsights';
import { ForSaleScreen } from 'screens/ForSaleScreen';

export const forSaleRoutes = [
  {
    path: routePaths.forSale,
    element: <ForSaleScreen />,
    children: [
      {
        path: routePaths.forSaleFilter,
        element: <ForSaleSidebar goBackURL={routePaths.forSale} />,
      },
    ],
  },
];

export const forSaleDetailRoutes = [
  {
    path: routePaths.forSaleDetail,
    element: <ForSaleDetailParent />,
    children: [
      {
        path: '',
        element: <ForSaleDetail />,
        children: [
          {
            path: routePaths.forSaleRequestFullDetails,
            element: <ContactSellerModal basePath={routePaths.forSaleDetail} />,
          },
        ],
      },
      {
        path: routePaths.forSaleInsights,
        element: <ForSaleBusinessInsights />,
      },
    ],
  },
];
