import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { Box, Button, createToastFeedback } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UploadDocument from 'assets/illustrations/upload-document.svg';

import { UploadButton } from 'components/UploadButton';

import { DocumentHint } from './DocumentHint';
import { FilesPreview } from './FilesPreview';
import { IWatchListDocumentsUpload } from './WatchListDocumentsUpload.interface';
import { ButtonsWrapper } from './WatchListDocumentsUpload.styles';
import { useWatchListDocumentsUpload } from './useWatchListDocumentsUpload';

export const WatchListDocumentsUpload = ({
  hint,
  loanId,
  documentType,
  doneUrl,
}: IWatchListDocumentsUpload) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onGeneralError = useCallback(async () => {
    navigate(doneUrl);
    createToastFeedback({
      type: 'warning',
      title: t('WatchListDocumentsUpload:error_message'),
    });
  }, [navigate, doneUrl, t]);

  const {
    isLoading,
    isUploading,
    onStart,
    onFinish,
    files,
    onFilesUploaded,
    deleteFile,
  } = useWatchListDocumentsUpload({
    documentType,
    loanId,
    onGeneralError,
  });

  const filesIsEmpty = files.length === 0;

  const FormContent = (
    <>
      {filesIsEmpty && (
        <Box css={{ my: '$4', textAlign: 'center' }}>
          <img
            src={UploadDocument}
            alt={t('WatchListDocumentsUpload:upload_document')}
          />
        </Box>
      )}
      <DocumentHint visible={filesIsEmpty} hint={hint} />
      <FilesPreview files={files} onDeleteFile={deleteFile} />
    </>
  );

  const FormFooter = !isLoading ? (
    <ButtonsWrapper css={{ my: '$4' }}>
      <UploadButton
        autoOpen={filesIsEmpty}
        onStart={onStart}
        onFinish={onFinish}
        onFilesUploaded={onFilesUploaded}
        disabled={isUploading || isLoading}
        buttonChildren={
          filesIsEmpty
            ? t('WatchListDocumentsUpload:upload_document')
            : t('WatchListDocumentsUpload:upload_another_document')
        }
        dropChildren={t('WatchListDocumentsUpload:drop_here')}
        multiple={true}
        accept={{
          'application/pdf': ['.pdf'],
          'application/vnd.ms-excel': ['.xls'],
          'text/csv': ['.csv'],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
            '.xlsx',
          ],
        }}
        acceptLiteral="PDF, CSV and Excel"
        buttonProps={{
          variant: 'secondary',
          leftIcon: <FontAwesomeIcon icon={regular('circle-plus')} size="lg" />,
          'data-testid': 'upload-button',
        }}
        wrapperProps={{
          css: {
            p: 0,
          },
        }}
        uploadParams={{
          loanId,
        }}
      />
      <Button as={Link} fullWidth to={doneUrl} data-testid="done-button">
        {t('WatchListDocumentsUpload:done')}
      </Button>
    </ButtonsWrapper>
  ) : null;

  return (
    <>
      {FormContent}
      {FormFooter}
    </>
  );
};
