import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { EmailBadgeStyles } from 'components/AccountConnectionModal/AccountConnectionModal.styles';

export const EmailBadge = ({ email }: { email: string }) => {
  return (
    <EmailBadgeStyles.Badge variant="green">
      <FontAwesomeIcon icon={regular('check')} size="lg" pull="left" />
      <EmailBadgeStyles.Text>{email}</EmailBadgeStyles.Text>
    </EmailBadgeStyles.Badge>
  );
};
