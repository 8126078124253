import { TimelineItemNowProps } from 'components/DealTimeline/DealTimeline.interface';
import { stepAnimatedProps } from 'components/DealTimeline/StepAnimation';
import { DealTimelineStep } from 'components/DealTimelineStep';

import { ILoanApi, LoanStatus } from 'lib/types';

import { useSubtitle, useTitle } from './DealAcceptLoanTerms.utils';

export const shouldShowDealAcceptLoanTerms = (loan: ILoanApi) => {
  return [LoanStatus.termsheet_sent].includes(loan?.status);
};

export const DealAcceptLoanTerms = ({
  children,
  loan,
  ...rest
}: TimelineItemNowProps) => {
  const title = useTitle(loan);
  const subtitle = useSubtitle(loan);

  if (!shouldShowDealAcceptLoanTerms(loan)) {
    return null;
  }

  return (
    <DealTimelineStep
      title={title}
      subtitle={subtitle}
      data-testid="deal-accept-loan-terms"
      {...stepAnimatedProps}
      {...rest}
    >
      {children}
    </DealTimelineStep>
  );
};
