import { useTranslation } from 'react-i18next';

import { FilterLoading } from 'components/ForSaleFilters/FilterLoading';
import { useForSaleFiltersConfig } from 'components/ForSaleFilters/useForSaleFiltersConfig';
import { RangeFilter } from 'components/RangeFilter';

export const TTMRevenueFilter = () => {
  const { t } = useTranslation();
  const filtersConfig = useForSaleFiltersConfig();

  if (filtersConfig.isLoading) {
    return <FilterLoading />;
  }

  const { min, max, defaultValue, step } = filtersConfig.revenue;

  return (
    <RangeFilter
      name="revenue"
      minLabel={t('ForSaleFilters:ttm_revenue_min_label')}
      maxLabel={t('ForSaleFilters:ttm_revenue_max_label')}
      min={min}
      max={max}
      step={step}
      defaultValue={defaultValue}
    />
  );
};
