import React, { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PageHeader } from 'ui/PageHeader';

import { DeleteDealConfirm } from 'components/DeleteDealConfirm';

import { routePaths } from 'router/routePaths';

import { IDetailPageHeaderProps } from './DetailPageHeader.interface';

export const DetailPageHeader = ({ loan }: IDetailPageHeaderProps) => {
  const navigate = useNavigate();

  const handleDeleteSuccess = useCallback(() => {
    setTimeout(() => {
      navigate(routePaths.dealFlow);
    }, 1000);
  }, [navigate]);

  const title = loan?.title;

  return (
    <PageHeader
      title={title}
      childrenTop={
        <Link to={routePaths.dealFlow}>
          <FontAwesomeIcon icon={regular('arrow-left-long')} size="lg" />
        </Link>
      }
      action={
        loan?.canBeArchived && (
          <DeleteDealConfirm
            loan={loan}
            onActionSuccess={handleDeleteSuccess}
          />
        )
      }
    />
  );
};
