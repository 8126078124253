import { MouseEvent, useCallback } from 'react';

import { useListingUnlockAction } from './useListingUnlockAction';

export interface UseListingUnlockCaptureConfig {
  originalProps?: Record<string, unknown>;
  requiresAgreement?: boolean;
}

export const useListingUnlockCapture = (
  redacted?: boolean,
  config?: UseListingUnlockCaptureConfig
) => {
  const { originalProps = {}, requiresAgreement } = config ?? {};

  const { open } = useListingUnlockAction(requiresAgreement);

  const onClick = useCallback(
    (e: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLAnchorElement>) => {
      if (!redacted) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      open();
    },
    [redacted, open]
  );

  const extraProps = redacted ? {} : originalProps;

  return { onClick, ...extraProps };
};
