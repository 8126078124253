import { mutate } from 'swr';

import { IQualificationStatus } from 'components/QualificationStatus';

import api from 'lib/api';

const updateWithData = async (data: IQualificationStatus) =>
  mutate(api.getQualification, data, false);

export const preloadProofOfFunds = async (
  preloadedData?: IQualificationStatus
) => {
  if (preloadedData) {
    return await updateWithData(preloadedData);
  }
  const { data } = await api.getQualificationHttp();
  return await updateWithData(data);
};
