import React from 'react';
import useSWR from 'swr';

import { ListingUnlocked } from 'components/ForSaleTimeline/ListingUnlocked';

import { apiV2 } from 'lib/api';
import { ListingForSale } from 'lib/types';

export const ListingUnlockedActivity = ({
  listingId,
}: {
  listingId?: string;
}) => {
  const { data: listing } = useSWR<ListingForSale>(
    listingId && apiV2.getListing(listingId)
  );

  if (!listing) return null;

  return <ListingUnlocked listing={listing} showTerms={false} />;
};
