import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { theme } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PortfolioApplicationLayout } from 'components/PortfolioApplication/PortfolioApplicationLayout';

import { PartialsProps } from './Partials.interface';

export const MissingInfo = ({ children }: PartialsProps) => {
  const { t } = useTranslation();

  const title = t('PortfolioApplicationFinished:missing_info_title');
  const description = (
    <div>{t('PortfolioApplicationFinished:missing_info_description')}</div>
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PortfolioApplicationLayout
        title={title}
        subtitle={description}
        preTitle={
          <div>
            <FontAwesomeIcon
              icon={regular('calendar-days')}
              size="4x"
              color={theme.colors.infoStatic.value}
            />
          </div>
        }
        contentWrapperProps={{
          'data-testid': 'missing-info-screen',
        }}
      >
        {children}
      </PortfolioApplicationLayout>
    </>
  );
};
