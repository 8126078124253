import Skeleton from 'react-loading-skeleton';

import { SkeletonWrapper } from './BusinessDetailLoading.styles';

export const BusinessDetailLoading = () => (
  <SkeletonWrapper data-testid="loading-wrapper">
    <Skeleton count={1} height={50} style={{ maxWidth: 320 }} />
    <Skeleton count={1} height={15} style={{ maxWidth: 320 }} />
    <Skeleton
      count={1}
      height={40}
      style={{ maxWidth: 380, marginTop: '4rem' }}
    />
    <Skeleton
      count={1}
      height={40}
      style={{ maxWidth: 360, marginTop: '4rem' }}
    />
  </SkeletonWrapper>
);
