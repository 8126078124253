import { Trans, useTranslation } from 'react-i18next';

import { theme } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Banner, BannerPrimaryAction } from 'ui/Banner';

import { useMeetingUrls } from 'lib/meetings';

import { useSellBusinessBanner } from './useSellBusinessBanner';

export const SellBusinessBanner = () => {
  const { t } = useTranslation();

  const { sellerMeetingUrl } = useMeetingUrls();
  const { financingAmount } = useSellBusinessBanner();

  const bannerTitle =
    financingAmount &&
    t('BusinessValuation:sell_business_boopos_financing_title');

  const bannerDescription = financingAmount ? (
    t('BusinessValuation:sell_business_boopos_financing_description', {
      financingAmount,
    })
  ) : (
    <Trans i18nKey="BusinessValuation:sell_business_brokers_description">
      <strong style={{ color: theme.colors.slate800.computedValue }} />
    </Trans>
  );

  const bannerTestId = financingAmount
    ? 'selling-banner-with-financing'
    : 'selling-banner-without-financing';

  return (
    <Banner
      icon={<>💸</>}
      data-testid={bannerTestId}
      title={bannerTitle}
      description={bannerDescription}
    >
      <BannerPrimaryAction
        as="a"
        href={sellerMeetingUrl}
        leftIcon={<FontAwesomeIcon icon={regular('comments')} />}
        target="_blank"
        data-testid="sell-business-button"
      >
        {t('BusinessValuation:actions_talk_to_advisor')}
      </BannerPrimaryAction>
    </Banner>
  );
};
