import { useTranslation } from 'react-i18next';

import { LoanStatusTags } from 'lib/types';

export const useLoanBadgeData = (
  status: LoanStatusTags
): { literal: string; variant: any } | undefined => {
  const { t } = useTranslation();

  const badgeData = {
    [LoanStatusTags.draft]: {
      literal: t('LoanCommons:draft'),
      variant: 'neutral',
    },
    [LoanStatusTags.terminated]: {
      literal: t('LoanCommons:terminated'),
      variant: 'neutral',
    },
    [LoanStatusTags.applied]: {
      literal: t('LoanCommons:applying'),
      variant: 'yellow',
    },
    [LoanStatusTags.funded]: {
      literal: t('LoanCommons:funded'),
      variant: 'green',
    },
    [LoanStatusTags.repaid]: {
      literal: t('LoanCommons:repaid'),
      variant: 'green',
    },
    [LoanStatusTags.listedForSale]: {
      literal: t('LoanCommons:listed_for_sale'),
      variant: 'green',
    },
    [LoanStatusTags.missingInfo]: {
      literal: t('LoanCommons:missing_info'),
      variant: 'red',
    },
    [LoanStatusTags.overdue]: {
      literal: t('LoanCommons:overdue'),
      variant: 'red',
    },
    [LoanStatusTags.denied]: {
      literal: t('LoanCommons:denied'),
      variant: 'red',
    },
    [LoanStatusTags.preApproved]: {
      literal: t('LoanCommons:pre_approved'),
      variant: 'blue',
    },
    [LoanStatusTags.dueDiligence]: {
      literal: t('LoanCommons:due_diligence'),
      variant: 'pink',
    },
  };

  return badgeData[status];
};
