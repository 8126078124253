import { useCallback, useEffect } from 'react';

import { FullScreenLoader } from 'components/FullScreenLoader';

import { useAnalytics } from 'lib/analytics';
import { useAuth } from 'lib/auth';

export const LogoutScreen = () => {
  const { logout } = useAuth();
  const { reset: resetAnalytics } = useAnalytics();

  const scriptsReset = useCallback(async () => {
    await resetAnalytics();
  }, [resetAnalytics]);

  const handleLogout = useCallback(async () => {
    await logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
    await scriptsReset();
  }, [logout, scriptsReset]);

  useEffect(() => {
    (async () => {
      await handleLogout();
    })();
  }, [handleLogout]);

  return <FullScreenLoader data-testid="loading" />;
};
