import { styled } from '@boopos/design-system';

export const IllustrationBg = styled('div', {
  background: '$lime100',
  borderRadius: '1.6rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  mb: '4.4rem',
  position: 'relative',
  height: '16rem',
  '> svg, > img': {
    position: 'absolute',
    bottom: '-4.4rem',
  },
});

export const ContentWrapper = styled('div', {
  pt: '$4',
  pb: '$6',
});

export const ButtonsWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  mb: '$4',
  '& > *:not(:last-child)': {
    mb: '$4',
  },
});
