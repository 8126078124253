import { Link } from 'react-router-dom';

import { styled } from '@boopos/design-system';

export const BackButtonStyles = {
  Wrapper: styled('div', {
    mb: '$4',
    variants: {
      hideOnDesktop: {
        true: {
          '@bp2': { display: 'none' },
        },
      },
      topSpace: {
        true: {
          mt: '4rem',
        },
      },
    },
  }),
  Link: styled(Link, {
    color: '$black',
    textDecoration: 'none',
    cursor: 'pointer',
    border: 0,
    background: 'transparent',
    display: 'inline-flex',
    alignItems: 'center',
  }),
  IconWrapper: styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '2.4rem',
    height: '2.4rem',
  }),
  Text: styled('div', {
    ml: '$4',
    color: '$slate500',
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
  }),
};
