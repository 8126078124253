import { styled } from '@boopos/design-system';

export const ScreenLayout = {
  Wrapper: styled('div', {
    px: '$4',
    pb: '$4',
    '@bp2': {
      px: '$8',
    },
  }),
  Heading: styled('p', {
    fontFamily: '$RecifeDisplay',
    fontSize: '3rem',
    lineHeight: '4rem',
    fontWeight: '$extraBold',
    color: '$slate800',
    mt: '4.8rem',
    mb: '$6',

    '&:first-of-type': {
      mt: 0,
    },
  }),
  ScoresGrid: styled('div', {
    display: 'grid',
    gap: '$6',
    mb: '$9',

    '@bp2': {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  }),
  InsightsCards: styled('div', {
    display: 'grid',
    gap: '$6',
    mb: '$9',
    '@bp2': {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  }),
  ColumnsWrapper: styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  Date: styled('p', {
    color: '$slate500',
    mb: '$6',
  }),
  BenchmarksSection: styled('div', {
    mt: '5.5rem',
  }),
  BenchmarksDescription: styled('p', {
    color: '$textBodyTertiary',
    mb: '$6',
  }),
};
