import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';

import {
  Box,
  ButtonFeedback,
  Checkbox,
  Input,
  createToastFeedback,
} from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CountryFlagImage } from 'components/CountryFlagImage';
import { SelectHookForm } from 'components/SelectHookForm';

import api from 'lib/api';
import { countriesForReactSelect, defaultCountry } from 'lib/constants';
import { ILoanContractPerson, IUploadedFile } from 'lib/types';

import { AddShareholderErrorSummary } from './AddShareholderErrorSummary';
import { IAddShareholderForm } from './AddShareholderForm.interface';
import {
  cleanUndefinedFormErrors,
  parsePersonToApi,
} from './AddShareholderForm.utils';
import { UploadWithPreview } from './UploadWithPreview';

export const AddShareholderForm = ({
  goBackUrl,
  businessId,
  loanId,
}: IAddShareholderForm) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<IUploadedFile[]>([]);
  const navigate = useNavigate();
  const formMethods = useForm<ILoanContractPerson>();

  const { register, handleSubmit, control, formState, watch, setValue } =
    formMethods;

  const parseFormErrors = () => {
    const { firstName, lastName, email, identifier } = formState?.errors ?? {};

    const errors = {
      firstName,
      lastName,
      email,
      identifier,
      shareholder: undefined,
      files: undefined,
    };

    if (!watch('shareholder') && !watch('director')) {
      errors.shareholder = {
        message: t('ShareHoldersScreen:shareholder_validation'),
      } as any;
    }

    if (files.length === 0) {
      errors.files = {
        message: t('ShareHoldersScreen:passport_required'),
      } as any;
    }

    return cleanUndefinedFormErrors(errors);
  };

  const watchedCountry = watch('country');
  const isFormValid = Object.keys(parseFormErrors()).length === 0;
  const showUploadButtonError = formState.isSubmitted && files.length === 0;
  const showFormErrorsSummary = formState.isSubmitted && !isFormValid;
  const isSubmitting = formState?.isSubmitting;

  const showShareholdersDirectorError =
    formState.isSubmitted && !watch('shareholder') && !watch('director');

  const handleEmailValidation = (email: string) =>
    isEmail(email) || t('ShareHoldersScreen:email_invalid');

  const onFilesChange = useCallback((newFiles: IUploadedFile[]) => {
    setFiles(newFiles);
  }, []);

  const onSubmit = useCallback(
    async (data: ILoanContractPerson) => {
      try {
        if (!isFormValid) return;

        const bodyParsed = parsePersonToApi({
          person: data,
          files,
        });

        await api.createContractPerson(
          {
            businessId,
            loanId,
          },
          bodyParsed
        );

        createToastFeedback({
          type: 'success',
          title: t('ShareHoldersScreen:shareholder_added'),
        });
        navigate(goBackUrl);
      } catch (e) {
        console.error(e);
      }
    },
    [isFormValid, files, businessId, loanId, t, navigate, goBackUrl]
  );

  const FormFooter = (
    <>
      {showFormErrorsSummary && (
        <Box css={{ mt: '$6' }}>
          <AddShareholderErrorSummary errors={parseFormErrors()} />
        </Box>
      )}
      <Box css={{ py: '$6' }}>
        <ButtonFeedback
          fullWidth
          type="submit"
          leftIcon={<FontAwesomeIcon icon={regular('user-plus')} size="lg" />}
          data-testid="submit-button"
          isLoading={isSubmitting}
        >
          {t('ShareHoldersScreen:add_shareholder_action')}
        </ButtonFeedback>
      </Box>
    </>
  );

  const FormContent = (
    <>
      <Checkbox
        id="person-form-shareholder"
        data-testid="shareholder-checkbox"
        label={t('ShareHoldersScreen:shareholder_title')}
        variant={showShareholdersDirectorError ? 'error' : undefined}
        {...register('shareholder')}
        groupSpacing="none"
      />
      <Checkbox
        id="person-form-director"
        label={t('ShareHoldersScreen:direction')}
        variant={showShareholdersDirectorError ? 'error' : undefined}
        groupSpacing="none"
        {...register('director')}
      />
      <Input
        data-testid="first-name-field"
        label={t('ShareHoldersScreen:first_name_label')}
        variant={formState.errors?.firstName ? 'error' : undefined}
        {...register('firstName', {
          required: t('ShareHoldersScreen:first_name_required'),
        })}
      />
      <Input
        data-testid="last-name-field"
        label={t('ShareHoldersScreen:last_name')}
        variant={formState.errors?.lastName ? 'error' : undefined}
        {...register('lastName', {
          required: t('ShareHoldersScreen:last_name_required'),
        })}
      />
      <Input
        data-testid="email-field"
        label={t('ShareHoldersScreen:email_label')}
        variant={formState.errors?.email ? 'error' : undefined}
        {...register('email', {
          required: t('ShareHoldersScreen:email_required'),
          validate: handleEmailValidation,
        })}
      />
      <SelectHookForm
        inputId="country-id"
        name="country"
        label={t('ShareHoldersScreen:country_label')}
        control={control}
        rules={{
          required: t('ShareHoldersScreen:country_required'),
        }}
        options={countriesForReactSelect}
        value={countriesForReactSelect.find(
          ({ value }) => value === watchedCountry
        )}
        formatOptionLabel={(country: any) => (
          <Box css={{ display: 'flex', alignItems: 'center' }}>
            <CountryFlagImage countryCode={country.value} css={{ mr: '$2' }} />
            <span>{country.label}</span>
          </Box>
        )}
      />
      <Input
        data-testid="ssn-field"
        label={t('ShareHoldersScreen:identifier_label')}
        variant={formState.errors?.identifier ? 'error' : undefined}
        {...register('identifier', {
          required: t('ShareHoldersScreen:identifier_required'),
        })}
      />
      <Box css={{ mt: '$6', mb: '$9' }}>
        <UploadWithPreview
          loanId={loanId}
          businessId={businessId}
          onFilesChange={onFilesChange}
          variant={showUploadButtonError ? 'error' : 'secondary'}
        >
          {t('ShareHoldersScreen:upload')}
        </UploadWithPreview>
      </Box>
      <Checkbox
        id="person-form-signer"
        label={t('ShareHoldersScreen:signs_label')}
        {...register('signer')}
      />
    </>
  );

  useEffect(() => {
    setValue('country', defaultCountry);
  }, [setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="shareholders-form">
      {FormContent}
      {FormFooter}
    </form>
  );
};
