import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  LayoutSidebarDetail,
  LayoutSidebarDetailContent,
} from 'components/LayoutSidebarDetail';

import { formatMoney } from 'lib/format';
import { MoneyFormat } from 'lib/format/money/formatMoney.interface';
import { SaleSellerApplicationApi } from 'lib/types';

interface Props {
  application?: SaleSellerApplicationApi;
}

export const AskingPricePartial = ({ application }: Props) => {
  const { t } = useTranslation();

  return (
    <LayoutSidebarDetail.ContentBlock>
      <LayoutSidebarDetail.ContentSubHeader>
        {t('ForSaleDetailApplication:asking_price_cell_title')}
      </LayoutSidebarDetail.ContentSubHeader>
      <LayoutSidebarDetailContent.BigNumber>
        {formatMoney(application?.askingPrice, {
          format: MoneyFormat.long,
        })}
      </LayoutSidebarDetailContent.BigNumber>
    </LayoutSidebarDetail.ContentBlock>
  );
};
