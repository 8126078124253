import { styled } from '@boopos/design-system';

export const Styles = {
  Heading: styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '$2',
    marginBottom: '$4',
    color: '$textBodyPrimary',
    lineHeight: '2.4rem',
  }),
};
