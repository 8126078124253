import { useParams } from 'react-router-dom';

import { AmazonConnector } from 'components/AmazonConnector';
import { PaddleConnector } from 'components/PaddleConnector';
import { ShopifyConnector } from 'components/ShopifyConnector';

const PROVIDER_CONFIG = {
  amazon: <AmazonConnector />,
  shopify: <ShopifyConnector />,
  paddle: <PaddleConnector />,
};
export const CustomConnectionScreen = () => {
  const { provider } = useParams();
  if (!provider || !(provider in PROVIDER_CONFIG)) {
    window.close();
    return null;
  }
  return PROVIDER_CONFIG[provider as keyof typeof PROVIDER_CONFIG];
};
