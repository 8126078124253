import { Badge } from 'ui/Badge';

import { LoanStatusTags } from 'lib/types';

import { useLoanBadgeData } from './useLoanBadgeData';

export const LoanStatusBadge = ({ status }: { status: LoanStatusTags }) => {
  const badgeData = useLoanBadgeData(status);
  const testId = `loan-${status}-badge`;

  if (!badgeData) {
    return null;
  }

  return (
    <Badge variant={badgeData.variant} data-testid={testId}>
      {badgeData.literal}
    </Badge>
  );
};
