import { getDocumentsFiles } from 'lib/files';
import { IAcquiringLegalEntity } from 'lib/types';

import { defaultFormValues } from './AcquiringLegalEntity.constants';
import { IAcquiringLegalEntityData } from './AcquiringLegalEntity.interface';

export const parseToApi = (data: IAcquiringLegalEntityData) => ({
  name: data.name,
  taxId: data.taxId,
  address: {
    country: data.country,
    state: data.state,
    street: data.street,
    city: data.city,
    zipCode: data.zipCode,
  },
  einConfirmationLetter: {
    files: data.einConfirmationLetterFiles,
  },
  articlesOfIncorporation: {
    files: data.articlesOfIncorporationFiles,
  },
});

export const parseFromApi = (
  acquiringLegalEntity: IAcquiringLegalEntity
): IAcquiringLegalEntityData => ({
  ...defaultFormValues,
  name: acquiringLegalEntity?.name,
  taxId: acquiringLegalEntity?.taxId,
  country: acquiringLegalEntity?.address?.country,
  state: acquiringLegalEntity?.address?.state,
  street: acquiringLegalEntity?.address?.street,
  city: acquiringLegalEntity?.address?.city,
  zipCode: acquiringLegalEntity?.address?.zipCode,
  einConfirmationLetterFiles: getDocumentsFiles(
    acquiringLegalEntity?.einConfirmationLetter
  ),
  articlesOfIncorporationFiles: getDocumentsFiles(
    acquiringLegalEntity?.articlesOfIncorporation
  ),
});
