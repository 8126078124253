import { styled } from '@boopos/design-system';

const Styles = {
  Parent: styled('div', {
    background: '$surfaceBase',
    borderRadius: '1.6rem',
    variants: {
      bottomSpaced: {
        true: {
          mb: '$4',
        },
      },
    },
  }),
  Divider: styled('div', {
    fontSize: '1.4rem',
    lineHeight: '2.4rem',
    fontWeight: 400,
    color: '$textBodyTertiary',
    py: '$4',
  }),
  HintMessage: styled('div', {
    fontSize: '1.4rem',
    lineHeight: '2.4rem',
    color: '$textBodyTertiary',
  }),
  LightContent: styled('div', {
    color: '$textBodyTertiary',
  }),
  Wrapper: styled('div', {
    display: 'flex',
    textDecoration: 'none',
    flexDirection: 'column',
    color: '$textBodyPrimary',
    p: '$4',
    background: '$surfaceBase',
    transition: 'all 0.1s ease-in-out',
    borderRadius: '1.6rem',
    '&:hover': {
      boxShadow: 'inset 0 0 0 2px $colors$borderBaseHover !important',
    },
    '&:active': {
      background: '$surfaceBase',
    },
    '&:focus': {
      boxShadow: 'inset 0 0 0 2px $colors$borderBasePressed',
      outline: 'none',
    },
    'a, a:visited': {
      color: '$textBodyPrimary',
      textDecoration: 'underline',
    },
    variants: {
      active: {
        false: {
          cursor: 'default',
          '&:hover, &:active': {
            boxShadow: 'none !important',
            background: 'none',
          },
        },
      },
      variant: {
        active: {
          background: '$surfaceSelected',
        },
        idle: {},
        success: {},
        warning: {},
        error: {},
      },
      disabled: {
        true: {
          opacity: 0.3,
        },
      },
    },
  }),
  Header: styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '$4',
    minHeight: '4.8rem',
  }),
  Content: styled('div', {
    color: '$textBodySecondary',
    variants: {
      padded: {
        true: {
          pl: '4rem',
        },
      },
      hasErrors: {
        true: {
          color: '$textStateNegative',
        },
      },
    },
  }),
  Action: styled('div', {}),
  Title: styled('div', {
    flex: 1,
  }),
  CustomIcon: styled('div', {
    minWidth: '2.4rem',
    display: 'flex',
    justifyContent: 'center',
  }),
  Icon: styled('div', {
    lineHeight: 0,
    minWidth: '2.4rem',
    variants: {
      variant: {
        idle: {
          '> svg': {
            color: '$decorationSlateBright',
          },
        },
        active: {
          '> svg': {
            color: '$decorationSlateDark',
          },
        },
        success: {
          '> svg': {
            color: '$infoPositive',
          },
        },
        warning: {
          '> svg': {
            color: '$infoWarning',
          },
        },
        error: {
          '> svg': {
            color: '$infoNegative',
          },
        },
      },
    },
    defaultVariants: {
      variant: 'idle',
    },
  }),
};
export { Styles };
