import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { StaticTooltip } from 'ui/Tooltip';

import { Styles } from './ForSaleSidebarFilters.styles';

interface InfoTooltipProps {
  content: string;
}

const infoTooltipId = 'sidebar-info-tooltip';

export const InfoTooltip = ({ content }: InfoTooltipProps) => {
  return (
    <Styles.InfoTooltip
      data-tooltip-id={infoTooltipId}
      data-tooltip-content={content}
    >
      <FontAwesomeIcon icon={regular('circle-info')} />
    </Styles.InfoTooltip>
  );
};

export const InfoTooltipController = () => (
  <StaticTooltip id={infoTooltipId} place="right" />
);
