import { Currency, Money } from 'lib/types';

import { parseMoneyToApi } from './parseMoneyToApi';

export const parseMoneyToApiOrUndefined = (
  amount: string | null | undefined | number,
  currency: Currency
): Money | undefined => {
  if (typeof amount === 'undefined' || amount === null || amount === '') {
    return undefined;
  }

  return parseMoneyToApi({
    amount: Number(amount),
    currency,
  });
};
