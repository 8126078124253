import { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  ActionSideModal,
  ActionSideModalCloseHeader,
  ActionSideModalCommonStyles,
  ActionSideModalLoading,
  useNavigateFromActionSideModal,
} from 'components/ActionSideModal';
import { LoanSideModalSendReview } from 'components/LoanSideModalSendReview';

import { routePaths } from 'router/routePaths';

import { useFundsBankAccount } from './FundsBankAccount.hooks';
import { FundsBankAccountFields } from './FundsBankAccountFields';

export const FundsBankAccountModal = () => {
  const { t } = useTranslation();
  const params = useParams<{ loanId: string }>();
  const loanId = params.loanId as string;

  const { onClose } = useNavigateFromActionSideModal(
    {
      loanId,
    },
    { basePath: routePaths.dealFlowDetail }
  );

  const handleSuccess = useCallback(() => {
    onClose();
  }, [onClose]);

  const {
    isWaitingForReview,
    isVerified,
    isSubmitting,
    formMethods,
    onSubmit,
    onDelete,
    isSuccess,
    isReady,
  } = useFundsBankAccount({
    loanId,
    onSuccess: handleSuccess,
  });

  const { formState, handleSubmit } = formMethods;

  const submitForm = handleSubmit(onSubmit);

  return (
    <ActionSideModal
      header={<ActionSideModalCloseHeader onClose={onClose} />}
      onClose={onClose}
    >
      <ActionSideModalCommonStyles.Title data-testid="funds-bank-account-modal-title">
        {t('FundsBankAccountModal:title')}
      </ActionSideModalCommonStyles.Title>
      <ActionSideModalCommonStyles.Subtitle>
        {t('FundsBankAccountModal:subtitle')}
      </ActionSideModalCommonStyles.Subtitle>

      {isReady && (
        <FormProvider {...formMethods}>
          <ActionSideModalCommonStyles.Form onSubmit={submitForm}>
            <FundsBankAccountFields
              allFieldsDisabled={isWaitingForReview || isVerified}
            />
            <LoanSideModalSendReview
              isLoading={isSubmitting}
              isSuccess={isSuccess}
              isWaitingForReview={isWaitingForReview}
              isVerified={isVerified}
              onSendForReview={submitForm}
              onCancelReview={onDelete}
              isFormValid={formState.isValid}
            />
          </ActionSideModalCommonStyles.Form>
        </FormProvider>
      )}

      {!isReady && <ActionSideModalLoading />}
    </ActionSideModal>
  );
};
