import { useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import {
  AuthForcedState,
  AuthHint,
  AuthParams,
  AuthParamsBuyerTargets,
  AuthParamsUserGoals,
} from 'lib/auth/authParams';

export const useBuyerInferredAuthParams = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const search = useMemo(() => {
    const search = new URLSearchParams(searchParams);

    search.set(AuthParams.authHint, AuthHint.signup);
    search.set(AuthParams.userGoal, AuthParamsUserGoals.buyer);
    search.set(AuthParams.buyerTarget, AuthParamsBuyerTargets.none);

    return `?${search.toString()}`;
  }, [searchParams]);

  const state = {
    ...(location?.state ?? {}),
    authHint: AuthHint.signup,
    userGoal: AuthParamsUserGoals.buyer,
  } as AuthForcedState;

  return {
    search,
    state,
  };
};
