import { ReactNode } from 'react';

import { formatMoney } from 'lib/format';
import { MoneyFormat } from 'lib/format/money/formatMoney.interface';
import { Money } from 'lib/types';

export const AmountOrEmpty = ({
  amount,
  emptyAmount = '$---',
  format = MoneyFormat.long,
}: {
  amount?: Money;
  emptyAmount?: ReactNode;
  format?: MoneyFormat;
}) => {
  if (!amount || !amount?.amount) {
    return <>{emptyAmount}</>;
  }

  return <>{formatMoney(amount, { format })}</>;
};
