import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import useSWRImmutable from 'swr/immutable';

import { Button, ButtonFeedback } from '@boopos/design-system';

import api from 'lib/api';
import { ICategory } from 'lib/types';

import { Categories } from './Categories';
import { GoBackHeader } from './Header';
import { FullScreenFunnelLayout } from './InvestmentCriteriaScreen.styles';
import { useBusinessCategoriesHandler } from './hooks/useBusinessCategoriesHandler';
import { useInvestmentCriteriaNavigation } from './hooks/useInvestmentCriteriaNavigation';
import { useSaveInvestmentCriteria } from './hooks/useSaveInvestmentCriteria';

export const InvestmentCriteriaCategoriesScreen = () => {
  const { t } = useTranslation();
  const { businessTypeId } = useParams<{ businessTypeId: string }>();
  const { exitPath, navigateToNextPath } = useInvestmentCriteriaNavigation();

  const { loading, success, investmentCriteria, save } =
    useSaveInvestmentCriteria({
      onSave: navigateToNextPath,
    });

  const { data } = useSWRImmutable<ICategory[]>(api.getCategories);
  const category = data?.find((c) => c.id === businessTypeId);

  const { onSelect, selectedCategories, continueDisabled } =
    useBusinessCategoriesHandler(category);

  const handleSave = useCallback(() => {
    save({
      ...investmentCriteria,
      categories: {
        ...investmentCriteria?.categories,
        [`${businessTypeId}`]: selectedCategories,
      },
    });
  }, [save, investmentCriteria, businessTypeId, selectedCategories]);

  const isLoadingScreen = !category;

  const Loading = <FullScreenFunnelLayout.LoadingContent />;

  const Content = (
    <>
      <FullScreenFunnelLayout.Title>
        {t('InvestmentCriteria:business_type_title', {
          category: category?.name,
        })}
      </FullScreenFunnelLayout.Title>
      <FullScreenFunnelLayout.Description>
        {t('InvestmentCriteria:business_type_description')}
      </FullScreenFunnelLayout.Description>
      <FullScreenFunnelLayout.CloudWords>
        <Categories
          categories={category?.categories}
          onSelect={onSelect}
          checkAllId={category?.id}
          selectedIds={selectedCategories}
        />
      </FullScreenFunnelLayout.CloudWords>
      <FullScreenFunnelLayout.CallToAction>
        <ButtonFeedback
          fullWidth
          onClick={handleSave}
          disabled={continueDisabled}
          isSuccess={success}
          isLoading={loading}
        >
          {t('InvestmentCriteria:continue')}
        </ButtonFeedback>
        <Button variant="tertiary" fullWidth as={Link} to={exitPath}>
          {t('InvestmentCriteria:leave_for_later')}
        </Button>
      </FullScreenFunnelLayout.CallToAction>
    </>
  );

  return (
    <>
      <GoBackHeader />
      <FullScreenFunnelLayout.Content data-testid="business-type-screen">
        <FullScreenFunnelLayout.Steps>
          {t('InvestmentCriteria:steps_number', { from: 1, to: 4 })}
        </FullScreenFunnelLayout.Steps>
        {isLoadingScreen ? Loading : Content}
      </FullScreenFunnelLayout.Content>
    </>
  );
};
