import {
  IApiDocument,
  IApiFile,
  IUploadedFile,
  UserDocumentType,
} from 'lib/types';

const getDocumentsArray = (document: {
  type: UserDocumentType;
  _id?: string;
  files?: {}[];
}) => ({
  documents: [document],
});

export const getFilesForUpload = ({
  currenFiles = [],
  uploadedFiles = [],
  firstDocument,
}: {
  currenFiles?: IApiFile[];
  uploadedFiles?: IUploadedFile[];
  firstDocument?: IApiDocument;
}) =>
  getDocumentsArray({
    type: UserDocumentType.kycFunds,
    _id: firstDocument?.id,
    files: [
      ...currenFiles,
      ...uploadedFiles.map((file) => ({
        name: file.fileName,
        url: file.url,
      })),
    ],
  });

export const getFilesForDelete = ({
  currenFiles = [],
  fileId,
  firstDocument,
}: {
  currenFiles?: IApiFile[];
  fileId: string;
  firstDocument?: IApiDocument;
}) =>
  getDocumentsArray({
    type: UserDocumentType.kycFunds,
    _id: firstDocument?.id,
    files: currenFiles.filter((file) => file.id !== fileId),
  });
