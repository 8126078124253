import { useLocation, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { PortfolioApplicationFlowPaths } from 'components/PortfolioApplication/PortfolioApplication.interface';

import api from 'lib/api';
import { ILoanApi, LoanTypesEnum } from 'lib/types';
import { generatePathSafe } from 'lib/utils/generatePathSafe';

import { routePaths } from 'router/routePaths';

export const useClosePath = (flowPaths: PortfolioApplicationFlowPaths) => {
  const params: { loanId?: string } = useParams();

  const location = useLocation();

  const exitPath = location.state?.exitPath;

  const { data: loan } = useSWR<ILoanApi>(
    params?.loanId && api.getLoan(params?.loanId)
  );

  const pathParams = { loanId: params?.loanId, businessId: loan?.businessId };

  const pathsByLoanType: { [key in keyof typeof LoanTypesEnum]?: string } = {
    [LoanTypesEnum.sale_seller]: generatePathSafe(
      flowPaths.sellLandingAfterFinish,
      pathParams
    ),
    [LoanTypesEnum.insights]: generatePathSafe(
      flowPaths.valuationLandingAfterFinish,
      pathParams
    ),
  };

  const loanAlreadyCreated = !!params?.loanId;

  if (loanAlreadyCreated) {
    return (
      (loan?.type ? pathsByLoanType?.[loan.type] : undefined) ??
      flowPaths.fallback
    );
  }

  if (exitPath) return exitPath;

  return routePaths.forSale;
};
