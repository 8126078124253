const stitchesPrefix = 'boopos-styles-';

declare global {
  interface Window {
    hj: any;
  }
}

function handleRequestAnimationFrame() {
  const elements = document.querySelectorAll<HTMLStyleElement>('head style');

  if (!elements.length) return '';

  return Array.from(elements)
    .reduce<string[]>((previousValue, element) => {
      if (element.dataset.hotjarWorkaround) return previousValue;

      if (!element.sheet) return previousValue;

      const rulesString = Array.from(element.sheet.cssRules)
        .reduce<string[]>((previousRuleValue, rule) => {
          if (!rule.cssText.includes(stitchesPrefix)) return previousRuleValue;
          return previousRuleValue.concat(rule.cssText);
        }, [])
        .join(' ');

      return previousValue.concat(rulesString);
    }, [])
    .join(' ');
}

export function hotjarWorkaround() {
  if (typeof window === 'undefined') return;

  const syncStyles = document.createElement('style');
  syncStyles.dataset.hotjarWorkaround = 'true';
  syncStyles.type = 'text/css';

  // insert style element into head
  document.head.insertBefore(syncStyles, document.head.children[0]);

  // start styles update interval

  const intervalID = window.setInterval(() => {
    const isHotjarRecording = window?.hj?.settings?.record;
    if (!isHotjarRecording) return;

    window.clearInterval(intervalID);

    let lastStyles: string;

    window.setInterval(() => {
      window.requestAnimationFrame(() => {
        const styles = handleRequestAnimationFrame();
        if (styles === lastStyles) return;

        syncStyles.textContent = styles;
        lastStyles = styles;
      });
    }, 500);
  }, 50);
}
