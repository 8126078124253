import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';

import { Box, Button } from '@boopos/design-system';

import { Modal } from 'components/Modal';

import { routePaths } from 'router/routePaths';

export const MatchingListingFoundModal = ({
  matchingListingId,
  onClose,
}: {
  matchingListingId?: string;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  const forSaleDetail =
    !!matchingListingId &&
    generatePath(routePaths.forSaleDetail, { listingId: matchingListingId });

  return (
    <Modal
      opened={!!matchingListingId}
      onClose={onClose}
      position="bottomSheetCentered"
      title={t('MatchingListingFoundModal:title')}
      maxWidth={320}
    >
      <Box css={{ px: '$4', pb: '$4' }}>
        <div>{t('MatchingListingFoundModal:description')}</div>
        <Button
          as={Link}
          to={forSaleDetail}
          fullWidth
          css={{ mt: '$4' }}
          replace
        >
          {t('MatchingListingFoundModal:action')}
        </Button>
      </Box>
    </Modal>
  );
};
