import { theme } from '@boopos/design-system';

export const chartConfig = {
  chartHeight: 200,
  chartMargin: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  barWidth: 10,
  barColor: theme.colors.bl200.computedValue,
  barColorMatched: theme.colors.decorationBlueBright.computedValue,
  cartesianGridStrokeWidth: 1,
  cartesianGridStrokeColor: theme.colors.slate200.computedValue,
  cartesianGridStrokeDasharray: '4 4',
  xAxisTickStyles: {
    fontSize: 12,
    lineHeigth: '16px',
    fill: theme.colors.slate500.computedValue,
  },
};
