import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { styled } from '@boopos/design-system';

import { parseRevenueHistoryForChart } from 'lib/parse';

import { IListingChart } from './ListingChart.interface';
import { ListingChartTooltip } from './ListingChartTooltip';

const YAxisText = styled('div', {
  textAlign: 'center',
});

const ListingChart: FC<IListingChart> = ({
  revenueHistory,
  currency,
  height = 180,
  fill = '#A7CF3B',
  tooltipReduced = false,
  areaProps = {},
}) => {
  const { t } = useTranslation();

  const data = useMemo(() => {
    return parseRevenueHistoryForChart(revenueHistory);
  }, [revenueHistory]);

  if (!revenueHistory?.length) return null;

  return (
    <>
      <ResponsiveContainer width="100%" height={height}>
        <AreaChart data={data}>
          <Tooltip
            wrapperStyle={{ outline: 'none' }}
            content={
              <ListingChartTooltip
                currency={currency}
                reduced={tooltipReduced}
              />
            }
          />
          <Area
            dataKey="revenue"
            fill={fill}
            stroke="none"
            isAnimationActive={false}
            {...(areaProps as any)}
          />
          <XAxis dataKey="month" hide label={t('ListingChart:month_label')} />
          <YAxis dataKey="revenue" hide />
        </AreaChart>
      </ResponsiveContainer>
      <YAxisText>{t('ListingChart:revenue_month')}</YAxisText>
    </>
  );
};

export { ListingChart };
