import { FilesUploader } from 'components/FilesUploader';
import { AcceptedFilesEnum } from 'components/UploadZone/UploadZone.interface';

import { useFormFilesUploader } from './useFormFilesUploader';

export interface FormFilesUploaderProps {
  title?: string;
  control: any;
  name: string;
  maxFiles: number;
  disabled?: boolean;
  uploadParams?: { [key: string]: string };
  accept?: AcceptedFilesEnum[];
  maxSizeMb?: number;
  rules?: any;
}

export const FormFilesUploader = ({
  title,
  control,
  name,
  maxFiles,
  maxSizeMb,
  disabled,
  uploadParams,
  accept,
  rules,
}: FormFilesUploaderProps) => {
  const { onFilesUploaded, onDeleteFile, files, error } = useFormFilesUploader({
    name,
    control,
    rules,
  });

  return (
    <FilesUploader
      disabled={disabled}
      upload={{
        accept,
        maxSizeMb,
        uploadParams,
        maxFiles,
        onFilesUploaded,
        externalError: error,
      }}
      preview={{
        title,
        files,
        onDeleteFile,
      }}
    />
  );
};
