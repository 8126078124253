import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ButtonFeedback } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Categories } from './Categories';
import { Header } from './Header';
import { FullScreenFunnelLayout } from './InvestmentCriteriaScreen.styles';
import { useInvestmentCriteriaNavigation } from './hooks/useInvestmentCriteriaNavigation';
import { useInvestmentCriteriaScreen } from './hooks/useInvestmentCriteriaScreen';
import { useParsedCategories } from './hooks/useParsedCategories';

export const InvestmentCriteriaScreen = () => {
  const { t } = useTranslation();
  const { exitPath } = useInvestmentCriteriaNavigation();
  const categories = useParsedCategories();

  const {
    parentCategories,
    continueDisabled,
    loading,
    success,
    genericOtherId,
    save,
    handleSelectParentCategories,
  } = useInvestmentCriteriaScreen();

  return (
    <>
      <Header
        icon={<FontAwesomeIcon icon={regular('close')} size="xl" />}
        actionProps={{
          as: Link,
          to: exitPath,
        }}
      />
      <FullScreenFunnelLayout.Content>
        <FullScreenFunnelLayout.Steps>
          {t('InvestmentCriteria:steps_number', { from: 1, to: 4 })}
        </FullScreenFunnelLayout.Steps>
        <FullScreenFunnelLayout.Title>
          {t('InvestmentCriteria:parent_business_title')}
        </FullScreenFunnelLayout.Title>
        <FullScreenFunnelLayout.Description>
          {t('InvestmentCriteria:parent_business_description')}
        </FullScreenFunnelLayout.Description>
        <FullScreenFunnelLayout.CloudWords>
          <Categories
            categories={categories}
            genericOtherId={genericOtherId}
            onSelect={handleSelectParentCategories}
            selectedIds={parentCategories}
          />
        </FullScreenFunnelLayout.CloudWords>
        <FullScreenFunnelLayout.CallToAction>
          <ButtonFeedback
            fullWidth
            onClick={save}
            disabled={continueDisabled}
            isLoading={loading}
            isSuccess={success}
          >
            {t('InvestmentCriteria:continue')}
          </ButtonFeedback>
        </FullScreenFunnelLayout.CallToAction>
      </FullScreenFunnelLayout.Content>
    </>
  );
};
