import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useSessionStorage from 'react-use/lib/useSessionStorage';

import { AnalyticsEvent, useAnalytics } from 'lib/analytics';

import { routePaths } from 'router/routePaths';

import { IForSaleFiltersForm } from './ForSaleFilters.interface';

export const useForSaleFilters = () => {
  const { track } = useAnalytics();

  const [formData, setFormData] = useSessionStorage<IForSaleFiltersForm>(
    'forSaleFilters/v2',
    {}
  );
  const navigate = useNavigate();

  const onSubmitFilters = useCallback(
    (data: IForSaleFiltersForm) => {
      setFormData(data);
      track(AnalyticsEvent.forSaleFilter, {
        filters: data,
      });

      navigate(routePaths.forSale);
    },
    [track, navigate, setFormData]
  );

  const onResetFilters = useCallback(() => {
    setFormData({});
  }, [setFormData]);

  const onApplyPartialFilters = useCallback(
    (data: Partial<IForSaleFiltersForm>) => {
      const newData = { ...formData, ...data };
      setFormData(newData);
      track(AnalyticsEvent.forSaleFilter, {
        filters: newData,
      });
    },
    [setFormData, formData, track]
  );

  return {
    onResetFilters,
    onSubmitFilters,
    onApplyPartialFilters,
    formData,
  };
};
