import {
  FtlTermSheetColumns,
  IFtlTermSheetColumns,
} from 'components/TermSheet/FtlTermSheetColumns';
import {
  IRblTermSheetColumns,
  RblTermSheetColumns,
} from 'components/TermSheet/RblTermSheetColumns';

import { TermSheetType } from 'lib/types';

interface ITermSheetColumnsShared
  extends IFtlTermSheetColumns,
    IRblTermSheetColumns {
  tsType?: TermSheetType;
  blur?: boolean;
}

export const TermSheetColumnsShared = ({
  tsType,
  ...props
}: ITermSheetColumnsShared) => {
  return (
    <>
      {tsType === TermSheetType.ftl ? (
        <FtlTermSheetColumns {...props} />
      ) : (
        <RblTermSheetColumns {...props} />
      )}
    </>
  );
};
