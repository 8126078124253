import { useTranslation } from 'react-i18next';

import { Styles } from 'components/DealTimeline/DealMissingInfo/DealMissingInfo.styles';

import { FormatUserInputText } from 'lib/format';
import { ILoanApi, LoanStatus } from 'lib/types';

export const DueDiligenceMissingInfo = ({ loan }: { loan?: ILoanApi }) => {
  const { t } = useTranslation();
  if (loan?.status !== LoanStatus.due_diligence_missing_info || !loan?.notes) {
    return null;
  }

  return (
    <>
      <div>{t('DealTimelineStep:due_diligence_missing_info')}</div>
      <Styles.NotesWrapper>
        <FormatUserInputText text={loan.notes} />
      </Styles.NotesWrapper>
    </>
  );
};
