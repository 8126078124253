import { useParams } from 'react-router-dom';

import { useBuyerInferredAuthParams } from 'lib/hooks/useBuyerInferredAuthParams';
import { generatePathSafe } from 'lib/utils/generatePathSafe';

import { routePaths } from 'router/routePaths';

export const useUnlockNavigate = () => {
  const { search, state } = useBuyerInferredAuthParams();

  const params = useParams();

  const redirectUrl = generatePathSafe(routePaths.forSaleDetail, params);

  if (!redirectUrl) {
    return null;
  }

  return {
    pathname: `${redirectUrl}${search}`,
    state,
  };
};
