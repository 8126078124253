import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Input } from '@boopos/design-system';
import { validLinkedinUrl } from '@boopos/utils';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FormFilesUploader } from 'components/FormFilesUploader';
import { UploadCVModal } from 'components/UploadCVModal';

import config from 'lib/config';
import { IApiFile } from 'lib/types';

import { Styles } from './LinkedInCVInput.styles';

export interface ILinkedInCVInputForm {
  linkedinUrl?: string;
  cvFiles?: IApiFile[];
}

export const LinkedInCVInput = () => {
  const { t } = useTranslation();

  const [uploadCVModalOpened, setUploadCVModalOpened] = useState(false);

  const {
    register,
    formState,
    control,
    trigger,
    setValue,
    watch,
    clearErrors,
  } = useFormContext<ILinkedInCVInputForm>();

  const handleOpenModal = useCallback(() => {
    clearErrors(['linkedinUrl', 'cvFiles']);
    setUploadCVModalOpened(true);
  }, [clearErrors]);

  const handleCloseModal = useCallback(() => {
    setValue('cvFiles', []);
    setUploadCVModalOpened(false);
  }, [setValue]);

  const handleUploadCV = useCallback(async () => {
    const isValid = await trigger('cvFiles');
    if (!isValid) return;
    setValue('linkedinUrl', '');
    setUploadCVModalOpened(false);
  }, [trigger, setValue]);

  const cvFiles = watch('cvFiles');
  const showLinkedin = !cvFiles?.length || uploadCVModalOpened;
  const showFiles = !uploadCVModalOpened && !!cvFiles?.length;

  return (
    <>
      {showLinkedin && (
        <div>
          <Input
            label={t('ProfileScreen:linkedin_label')}
            id="profileFormLinkedinProfile"
            type="url"
            variant={
              formState?.errors?.linkedinUrl?.message ? 'error' : undefined
            }
            subtext={formState?.errors?.linkedinUrl?.message}
            {...register('linkedinUrl', {
              required: t('ProfileScreen:linkedin_required_error'),
              validate: (v) =>
                (!!v && validLinkedinUrl(v)) ||
                t('ProfileScreen:linkedin_invalid_error'),
              shouldUnregister: true,
            })}
          />
          <Styles.ActionsContainer>
            <Button
              as="a"
              href={config.LINKEDIN_SELF_PROFILE_URL}
              target="_blank"
              rel="noopener noreferer"
              variant="tertiary"
              rightIcon={
                <FontAwesomeIcon icon={regular('arrow-up-right-from-square')} />
              }
            >
              {t('ProfileScreen:open_linkedin')}
            </Button>
            {!!formState?.errors?.linkedinUrl?.message && (
              <Button
                variant="tertiary"
                rightIcon={<FontAwesomeIcon icon={regular('upload')} />}
                onClick={handleOpenModal}
              >
                {t('ProfileScreen:upload_cv')}
              </Button>
            )}
          </Styles.ActionsContainer>
        </div>
      )}

      {showFiles && (
        <Styles.FilesWrapper>
          <FormFilesUploader control={control} name="cvFiles" maxFiles={1} />
        </Styles.FilesWrapper>
      )}

      <UploadCVModal
        control={control}
        name="cvFiles"
        opened={uploadCVModalOpened}
        onClose={handleCloseModal}
        onSubmit={handleUploadCV}
      />
    </>
  );
};
