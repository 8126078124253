import React from 'react';
import useSWR from 'swr';

import { ListingDetail } from 'components/ListingDetail';
import { BusinessPreApprovedActivity } from 'components/TimelineActivities/BusinessPreApprovedActivity';

import { apiV2 } from 'lib/api';
import { ListingForSale } from 'lib/types';

export const DealTimelineListing = ({ listingId }: { listingId?: string }) => {
  const { data: listing } = useSWR<ListingForSale>(
    listingId && apiV2.getListing(listingId)
  );

  if (!listing) return null;

  return (
    <BusinessPreApprovedActivity date={listing.publishedAt} isLast>
      <ListingDetail listing={listing} />
    </BusinessPreApprovedActivity>
  );
};
