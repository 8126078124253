import amazon from 'assets/account-providers/amazon-logo.png';
import amazonRounded from 'assets/account-providers/amazon-rounded.png';
import bankRounded from 'assets/account-providers/bank-rounded.png';
import ebay from 'assets/account-providers/ebay-logo.png';
import ebayRounded from 'assets/account-providers/ebay-rounded.png';
import magento from 'assets/account-providers/magento-logo.png';
import magentoRounded from 'assets/account-providers/magento-rounded.png';
import paddle from 'assets/account-providers/paddle-logo.png';
import paddleRounded from 'assets/account-providers/paddle-rounded.png';
import paypal from 'assets/account-providers/paypal-logo.png';
import paypalRounded from 'assets/account-providers/paypal-rounded.png';
import quickbooks from 'assets/account-providers/quickbooks-logo.png';
import quickbooksRounded from 'assets/account-providers/quickbooks-rounded.png';
import sage from 'assets/account-providers/sage-logo.png';
import sageRounded from 'assets/account-providers/sage-rounded.png';
import shopify from 'assets/account-providers/shopify-logo.png';
import shopifyRounded from 'assets/account-providers/shopify-rounded.png';
import square from 'assets/account-providers/square-logo.png';
import squareRounded from 'assets/account-providers/square-rounded.png';
import stripe from 'assets/account-providers/stripe-logo.png';
import stripeRounded from 'assets/account-providers/stripe-rounded.png';
import woocommerce from 'assets/account-providers/woocommerce-logo.png';
import woocommerceRounded from 'assets/account-providers/woocommerce-rounded.png';
import xero from 'assets/account-providers/xero-logo.png';
import xeroRounded from 'assets/account-providers/xero-rounded.png';
import zoho from 'assets/account-providers/zoho-logo.png';
import zohoRounded from 'assets/account-providers/zoho-rounded.png';

type LogoMap = Record<string, any>;

export const imagesMap: { base: LogoMap; rounded: LogoMap } = {
  base: {
    amazon,
    ebay,
    magento,
    paypal,
    quickbooks,
    sage,
    shopify,
    square,
    stripe,
    woocommerce,
    xero,
    zoho,
    paddle,
  },
  rounded: {
    amazon: amazonRounded,
    ebay: ebayRounded,
    magento: magentoRounded,
    paypal: paypalRounded,
    quickbooks: quickbooksRounded,
    sage: sageRounded,
    shopify: shopifyRounded,
    square: squareRounded,
    stripe: stripeRounded,
    woocommerce: woocommerceRounded,
    xero: xeroRounded,
    zoho: zohoRounded,
    paddle: paddleRounded,
    bank: bankRounded,
  },
};

export const ProviderImage = ({
  provider,
  imageType = 'base',
}: {
  provider: string;
  imageType?: 'base' | 'rounded';
}) => {
  if (!imagesMap[imageType][provider]) {
    return null;
  }

  return (
    <img
      src={imagesMap[imageType][provider]}
      alt={provider}
      data-testid={`provider-image-${provider}`}
    />
  );
};
