import { Money } from 'lib/types';

import { parseMoneyFromApi } from './parseMoneyFromApi';

export const parseMoneyFromApiToString = (money: Money | undefined): string => {
  if (!money) {
    return '';
  }

  if (!money.amount) {
    return '';
  }

  const parsedMoney = parseMoneyFromApi(money);

  return String(parsedMoney.amount);
};
