import { MouseEvent, ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { InlineFeedback, Link } from '@boopos/design-system';

export const DocumentHint = ({
  hint,
  visible = true,
}: {
  visible: boolean;
  hint?: { title: string; description: ReactNode; sampleUrl?: string };
}) => {
  const { t } = useTranslation();
  const onDownloadClick = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
  }, []);

  const hintActions = useMemo(() => {
    if (!hint?.sampleUrl) {
      return undefined;
    }
    return {
      primary: (
        <Link
          href={hint.sampleUrl}
          target="_blank"
          css={{ color: '$slate700' }}
          onClick={onDownloadClick}
          data-testid="download-link"
        >
          {t('WatchListDocumentsUpload:download_sample')}
        </Link>
      ),
    };
  }, [hint, t, onDownloadClick]);

  if (!hint || !visible) {
    return null;
  }

  return (
    <InlineFeedback
      variant="info"
      heading={hint.title}
      expandable
      actions={hintActions}
    >
      {hint.description}
    </InlineFeedback>
  );
};
