import { Controller } from 'react-hook-form';

import { Select } from '@boopos/design-system';

import { ISelectHookForm } from './SelectHookForm.interface';

export const SelectHookForm = ({
  name,
  control,
  rules,
  shouldUnregister,
  ...props
}: ISelectHookForm) => {
  return (
    <Controller
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      rules={rules}
      render={({ field }) => (
        <Select
          {...props}
          ref={field.ref}
          name={field.name}
          onChange={(newValue: any) => {
            field.onChange(newValue.value);
          }}
          onBlur={field.onBlur}
        />
      )}
    />
  );
};
