import React from 'react';
import { useTranslation } from 'react-i18next';

import { TermSheetHandler } from 'components/TermSheetHandler';

import { WhatsNextProps } from './ForSaleWhatsNext.interface';

export const WhatsNextProprietary = ({ listing, children }: WhatsNextProps) => {
  const { t } = useTranslation();

  if (listing?.dealApplication?.grantedAt) {
    return children(
      <TermSheetHandler termSheet={listing.dealApplication?.termSheet}>
        <TermSheetHandler.Base>
          <>
            <div>{t('ForSaleWhatsNext:proprietary_closing_terms')}</div>
          </>
        </TermSheetHandler.Base>
        <TermSheetHandler.Empty>
          <>{t('ForSaleWhatsNext:proprietary_no_terms')}</>
        </TermSheetHandler.Empty>
        <TermSheetHandler.External>
          <>{t('ForSaleWhatsNext:proprietary_no_terms')}</>
        </TermSheetHandler.External>
      </TermSheetHandler>
    );
  }

  return null;
};
