import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { BackButton } from 'components/BackButton';
import { LayoutHideMenu } from 'components/Layout';
import {
  AnimatedScreenSlideWrapper,
  SubNavigationFullWidth,
} from 'components/ScreenLayout';
import { SubScreenTitle } from 'components/SubScreenTitle';
import { UserNotifications } from 'components/UserNotifications';

import { HSWidgetHide } from 'lib/hubspot';

import { routePaths } from 'router/routePaths';

export const NotificationsScreen = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('AccountScreen:notifications_title')}</title>
      </Helmet>
      <AnimatedScreenSlideWrapper>
        <LayoutHideMenu hideOn="all" shouldShowMenuOnUnmount={false} />
        <SubNavigationFullWidth>
          <BackButton to={routePaths.account} />
          <SubScreenTitle>
            {t('AccountScreen:notifications_title')}
          </SubScreenTitle>
          <UserNotifications />
        </SubNavigationFullWidth>
        <HSWidgetHide />
      </AnimatedScreenSlideWrapper>
    </>
  );
};
