import { IBusinessInsights, IBusinessInsightsStatus } from 'lib/types';

export const getPreviewConfig = ({
  businessInsights,
}: {
  businessInsights?: IBusinessInsights;
}) => {
  const locked = businessInsights?.status === IBusinessInsightsStatus.locked;

  const price = businessInsights?.price;

  return {
    locked,
    price,
  };
};
