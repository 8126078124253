import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { PageModal } from 'components/Modal';

import { HSWidgetHide } from 'lib/hubspot';

import { routePaths } from 'router/routePaths';

import { IForSaleFiltersContext } from './ForSaleFilters.interface';
import { ForSaleSidebarFilters } from './ForSaleSidebarFilters';

export const ForSaleSidebar = ({
  goBackURL = routePaths.forSale,
}: {
  goBackURL?: string;
}) => {
  const { t } = useTranslation();
  const context = useOutletContext<IForSaleFiltersContext>();
  const { onSubmitFilters, defaultFilters, onResetFilters } = context;

  return (
    <PageModal
      goBackURL={goBackURL}
      title={t('ForSaleFilters:sidebar_title')}
      position="rightDrawer"
      maxWidth={390}
    >
      <ForSaleSidebarFilters
        onSubmit={onSubmitFilters}
        onReset={onResetFilters}
        defaultValues={defaultFilters}
      />
      <HSWidgetHide />
    </PageModal>
  );
};
