import { truncateValue } from '@boopos/utils';

import { Styles } from './MultiplierValue.styles';

export const MultiplierValue = ({ value }: { value: number }) => (
  <Styles.Wrapper>
    <Styles.Value>
      {truncateValue(value)}
      <Styles.Symbol>×</Styles.Symbol>
    </Styles.Value>
  </Styles.Wrapper>
);
