import { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  ActionSideModal,
  ActionSideModalCloseHeader,
  ActionSideModalCommonStyles,
  ActionSideModalLoading,
  useNavigateFromActionSideModal,
} from 'components/ActionSideModal';
import { FormFilesUploader } from 'components/FormFilesUploader';
import { LoanSideModalSendReview } from 'components/LoanSideModalSendReview';
import { AcceptedFilesEnum } from 'components/UploadZone/UploadZone.interface';

import { routePaths } from 'router/routePaths';

import { useLetterOfIntent } from './LetterOfIntent.hooks';

const letterOfIntentAcceptedFiles = [
  AcceptedFilesEnum.pdf,
  AcceptedFilesEnum.images,
];

export const LetterOfIntentModal = () => {
  const { t } = useTranslation();
  const params = useParams<{ loanId: string }>();
  const loanId = params.loanId as string;

  const { onClose } = useNavigateFromActionSideModal(
    {
      loanId,
    },
    { basePath: routePaths.dealFlowDetail }
  );

  const handleSuccess = useCallback(() => {
    onClose();
  }, [onClose]);

  const {
    onSubmit,
    onDelete,
    formMethods,
    isSubmitting,
    isSuccess,
    isReady,
    isWaitingForReview,
    isVerified,
  } = useLetterOfIntent({
    loanId,
    onSuccess: handleSuccess,
  });

  const { handleSubmit, control, formState } = formMethods;

  const submitForm = handleSubmit(onSubmit);

  return (
    <ActionSideModal
      header={<ActionSideModalCloseHeader onClose={onClose} />}
      onClose={onClose}
    >
      <ActionSideModalCommonStyles.Title data-testid="letter-of-intent-modal-title">
        {t('LetterOfIntentModal:title')}
      </ActionSideModalCommonStyles.Title>
      <ActionSideModalCommonStyles.Subtitle>
        {t('LetterOfIntentModal:subtitle')}
      </ActionSideModalCommonStyles.Subtitle>
      {isReady && (
        <FormProvider {...formMethods}>
          <ActionSideModalCommonStyles.Form onSubmit={submitForm}>
            <FormFilesUploader
              control={control}
              name="letterOfIntentFiles"
              maxFiles={1}
              accept={letterOfIntentAcceptedFiles}
              uploadParams={{ loanId }}
              disabled={isWaitingForReview || isVerified}
            />
            <LoanSideModalSendReview
              isLoading={isSubmitting}
              isSuccess={isSuccess}
              isWaitingForReview={isWaitingForReview}
              isVerified={isVerified}
              onSendForReview={submitForm}
              onCancelReview={onDelete}
              isFormValid={formState.isValid}
            />
          </ActionSideModalCommonStyles.Form>
        </FormProvider>
      )}
      {!isReady && <ActionSideModalLoading />}
    </ActionSideModal>
  );
};
