import { NavLink } from 'react-router-dom';

import { styled } from '@boopos/design-system';

import { MenuItemText } from './MenuItemText.styled';

export const MenuItemNavLink = styled(NavLink, {
  display: 'flex',
  textDecoration: 'none',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'relative',
  p: '$2',
  width: '8.6rem',
  borderRadius: '.8rem',
  outline: 'none',
  svg: {
    color: '$infoStatic',
  },
  background: '$surfaceElevated',
  [`&, ${MenuItemText}`]: {
    color: '$textBodySecondary',
  },
  '&:hover': {
    boxShadow: 'inset 0 0 0 2px $colors$borderBaseHover',
  },
  '&:active': {
    boxShadow: 'inset 0 0 0 2px $colors$borderBasePressed',
  },
  '&:focus-visible': {
    outline: '4px solid $colors$borderInteractiveFocus',
  },
  [`&.active`]: {
    background: '$surfaceSelected',
    [`&, ${MenuItemText}, svg`]: {
      color: '$textBodyPrimary',
    },
  },
});
