import { Text, styled } from '@boopos/design-system';

export const Dropzone = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '$4',
  width: '100%',
  minHeight: '16rem',
  border: '2px dashed $slate300',
  borderRadius: '2rem',
  padding: '$9',
  position: 'relative',
  transition: 'all .2s ease-out',

  variants: {
    isUploading: {
      true: {
        border: 'none',
        backgroundColor: '$slate200',
      },
    },
    active: {
      true: {
        border: '2px dashed $slate900',
        backgroundColor: '$slate200',
      },
    },
    activeError: {
      true: {
        border: '2px dashed $slate900',
        backgroundColor: '$white',
      },
    },
    externalError: {
      true: {
        border: '2px dashed $infoNegative',
        backgroundColor: '$red100',
      },
    },
  },
});

export const HelpText = styled(Text, {
  color: '$infoStatic',
  variants: {
    error: {
      true: {
        color: '$textStateNegative',
      },
    },
  },
});
