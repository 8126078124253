import { motion } from 'framer-motion';
import { PropsWithChildren, forwardRef } from 'react';

export const AnimatedScreenOpacityWrapper = forwardRef<
  HTMLDivElement,
  PropsWithChildren
>((props, ref) => (
  <motion.div
    ref={ref}
    initial={{ opacity: 0 }}
    exit={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    {...props}
  />
));
