import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BackButtonStyles } from './BackButton.styles';

interface BackButtonLocationState {
  backButtonRoute: string;
}

export const BackButton = ({
  to,
  hideOnDesktop = true,
  Icon = <FontAwesomeIcon icon={regular('arrow-left-long')} size="lg" />,
  topSpace,
  children,
  ...props
}: {
  to: string;
  state?: { [key: string]: any };
  hideOnDesktop?: boolean;
  topSpace?: boolean;
  Icon?: ReactNode;
  children?: ReactNode;
}) => {
  const location = useLocation();

  const backToRoute =
    (location?.state as BackButtonLocationState)?.backButtonRoute || to;

  return (
    <BackButtonStyles.Wrapper hideOnDesktop={hideOnDesktop} topSpace={topSpace}>
      <BackButtonStyles.Link to={backToRoute} {...props}>
        <BackButtonStyles.IconWrapper>{Icon}</BackButtonStyles.IconWrapper>
        {!!children && (
          <BackButtonStyles.Text>{children}</BackButtonStyles.Text>
        )}
      </BackButtonStyles.Link>
    </BackButtonStyles.Wrapper>
  );
};
