import React, { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentsDetails } from 'components/DocumentsDetails';
import { ListingDetailStyles } from 'components/ListingDetail/ListingDetail.styles';
import { ListingInfoItem } from 'components/ListingDetail/ListingInfoItem';

import { ListingForSale, ListingSellerType } from 'lib/types';

import { parseFileNames } from './FinancialDocuments.utils';

export const FinancialDocuments = ({
  listing,
  children,
}: {
  listing: ListingForSale;
  children?: (children: ReactNode) => ReactElement;
}) => {
  const { t } = useTranslation();

  if (listing.sellerType !== ListingSellerType.proprietary) {
    return null;
  }

  const { profitAndLossFiles, cohortsFiles } = listing;

  const hasFiles =
    !!profitAndLossFiles?.value?.length || !!cohortsFiles?.value?.length;

  if (!hasFiles) {
    return null;
  }

  const Content = (
    <ListingDetailStyles.InfoGrid>
      <DocumentsDetails
        files={parseFileNames(
          () =>
            t('ListingDetail:profit_and_loss_filename', {
              title: listing?.title,
            }),
          listing?.profitAndLossFiles?.value
        )}
        blur={listing?.profitAndLossFiles?.redacted}
        itemExtraProps={{
          'data-testid': 'unlocked-listing-download-pnl',
        }}
        showLink
      >
        {(children) => (
          <ListingInfoItem
            title={t('ListingDetail:profit_and_loss_title')}
            tipHelp={t('ListingDetail:profit_and_loss_hint')}
            variant="body"
          >
            {children}
          </ListingInfoItem>
        )}
      </DocumentsDetails>

      <DocumentsDetails
        files={parseFileNames(
          () =>
            t('ListingDetail:cohorts_filename', {
              title: listing?.title,
            }),
          listing?.cohortsFiles?.value
        )}
        blur={listing?.cohortsFiles?.redacted}
        showLink
        itemExtraProps={{
          'data-testid': 'unlocked-listing-download-cohorts',
        }}
      >
        {(children) => (
          <ListingInfoItem
            title={t('ListingDetail:cohorts_title')}
            tipHelp={t('ListingDetail:cohorts_hint')}
            variant="body"
          >
            {children}
          </ListingInfoItem>
        )}
      </DocumentsDetails>
    </ListingDetailStyles.InfoGrid>
  );

  return children?.(Content) ?? null;
};
