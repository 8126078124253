import { styled } from '@boopos/design-system';

export const CardSection = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '$6',
  backgroundColor: '$surfaceBase',
  borderRadius: '1.6rem',
  textDecoration: 'none',
  variants: {
    transparent: {
      true: {
        backgroundColor: 'transparent',
      },
    },
    border: {
      true: {
        border: '1px solid $borderInteractiveDefault',
      },
    },
    reduced: {
      true: {
        padding: '$4',
      },
    },
  },
});
