import { ICountryFlagImage } from './CountryFlagImage.interface';
import { StyledImage } from './CountryFlahImage.styles';

const IMAGE_URL =
  'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/4.1.4/flags/4x3/';
const IMAGE_EXT = '.svg';

export const CountryFlagImage = ({
  countryCode,
  css,
  ...props
}: ICountryFlagImage) => {
  const src = `${IMAGE_URL}${(countryCode || '').toLowerCase()}${IMAGE_EXT}`;

  return <StyledImage {...props} css={css} alt={countryCode} src={src} />;
};
