import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ShareholderCard } from 'components/ShareholderCard';

import { IShareholdersList } from './ShareholdersList.interface';

export const ShareholdersList = ({
  goBackUrl,
  addUrl,
  persons,
  businessId,
  loanId,
  mutate,
}: IShareholdersList) => {
  const { t } = useTranslation();

  return (
    <form data-testid="shareholders-list">
      {!!persons?.length &&
        persons.map((person) => (
          <ShareholderCard
            key={person.id}
            person={person}
            businessId={businessId}
            loanId={loanId}
            mutate={mutate}
          />
        ))}
      {(persons || []).length === 0 ? (
        <Button
          fullWidth
          type="button"
          leftIcon={<FontAwesomeIcon icon={regular('user-plus')} size="lg" />}
          as={Link}
          to={addUrl}
          data-testid="add-shareholder-button"
          css={{ mt: '$6' }}
        >
          {t('ShareHoldersScreen:add_shareholder_action')}
        </Button>
      ) : (
        <>
          <Button
            fullWidth
            variant="secondary"
            type="button"
            leftIcon={<FontAwesomeIcon icon={regular('user-plus')} size="lg" />}
            as={Link}
            to={addUrl}
            css={{ my: '$6' }}
            data-testid="add-another-shareholder-button"
          >
            {t('ShareHoldersScreen:add_other')}
          </Button>
          <Button fullWidth as={Link} to={goBackUrl}>
            {t('ShareHoldersScreen:done')}
          </Button>
        </>
      )}
    </form>
  );
};
