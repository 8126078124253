import { useAtom } from 'jotai';
import { useCallback } from 'react';
import useSWR from 'swr';

import { apiV2 } from 'lib/api';

import { parentCategoriesAtom } from 'screens/InvestmentCriteriaScreen/InvestmentCriteria.atoms';

import { useOtherCategoriesUtils } from './useOtherCategoriesUtils';
import { GENERIC_OTHER } from './useRollingFlow';

export const useParentCategoriesApiUtils = () => {
  const { data: investmentCriteria } = useSWR(apiV2.getInvestmentCriteria());
  const [parentCategories] = useAtom(parentCategoriesAtom);
  const { otherParentCategoryId, otherCategoryIds } = useOtherCategoriesUtils();

  const parseCategoriesToApi = useCallback(() => {
    const categories = { ...investmentCriteria?.categories };

    Object.keys(categories).forEach((categoryId) => {
      if (!parentCategories.includes(categoryId)) {
        delete categories[categoryId];
      }
    });

    parentCategories.forEach((id) => {
      if (otherCategoryIds.includes(id)) {
        categories[otherParentCategoryId] = [
          ...(categories[otherParentCategoryId] || []),
          id,
        ];
      }
    });

    return categories;
  }, [
    investmentCriteria?.categories,
    otherParentCategoryId,
    otherCategoryIds,
    parentCategories,
  ]);

  const parseOtherBusinessTypesToApi = useCallback(() => {
    return parentCategories.includes(GENERIC_OTHER)
      ? investmentCriteria.otherBusinessTypes
      : [];
  }, [investmentCriteria, parentCategories]);

  return {
    parseCategoriesToApi,
    parseOtherBusinessTypesToApi,
  };
};
