import React, { MouseEvent, cloneElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ConfirmationModal } from './ConfirmationModal';
import { IDeleteConfirmationAction } from './ConfirmationModal.interface';

export const DeleteConfirmationAction = ({
  title,
  description,
  children,
  onDelete,
  deleteText,
  deleteIcon = <FontAwesomeIcon icon={regular('trash')} size="lg" />,
  cancelText,
  deleteParams,
}: IDeleteConfirmationAction) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  const onClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setOpened(true);
  }, []);

  const onCancel = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setOpened(false);
  }, []);

  const handleDelete = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setOpened(false);
      onDelete(deleteParams);
    },
    [onDelete, deleteParams]
  );

  const confirmButtonProps = {
    leftIcon: deleteIcon,
    variant: 'error',
    onClick: handleDelete,
    children: deleteText || t('DeleteConfirmationAction:delete'),
    'data-testid': 'delete-button',
  };

  const cancelButtonProps = {
    variant: 'secondary',
    onClick: onCancel,
    children: cancelText || t('DeleteConfirmationAction:cancel'),
    'data-testid': 'cancel-button',
  };

  return (
    <>
      {cloneElement(children, {
        ...children.props,
        onClick,
      })}
      <ConfirmationModal
        opened={opened}
        title={title}
        description={description}
        confirmButtonProps={confirmButtonProps}
        cancelButtonProps={cancelButtonProps}
      />
    </>
  );
};
