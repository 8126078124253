import { ComponentProps } from 'react';

import { Button } from '@boopos/design-system';

type NudeButtonWithShadowProps = ComponentProps<typeof Button>;

export const NudeButtonWithShadow = (props: NudeButtonWithShadowProps) => (
  <Button
    variant="nude"
    css={{
      boxShadow:
        '0px 1px 4px rgba(0, 0, 0, 0.15), 0px 0px 4px rgba(0, 0, 0, 0.05)',
    }}
    {...props}
  />
);
