import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CenteredContentScreen } from 'ui/CenteredContentScreen';

import { BackButton } from 'components/BackButton';
import { AnimatedScreenOpacityWrapper } from 'components/ScreenLayout';
import { ShareholdersList } from 'components/ShareholdersList';

import api from 'lib/api';
import { ILoanApi } from 'lib/types';

import { IShareholdersScreen } from './ShareholdersScreen.interface';

export const ShareholdersScreen = ({
  goBackUrl,
  addShareholderPath,
}: IShareholdersScreen) => {
  const { t } = useTranslation();
  const title = t('ShareHoldersScreen:add_shareholder_title');
  const { businessId, loanId } = useParams();

  const goBackUrlGenerated = generatePath(goBackUrl, {
    ...(businessId ? { businessId } : {}),
    ...(loanId ? { loanId } : {}),
  });

  const addShareholderPathGenerated = generatePath(addShareholderPath, {
    ...(businessId ? { businessId } : {}),
    ...(loanId ? { loanId } : {}),
  });

  const { data: loan, mutate } = useSWR<ILoanApi>(
    businessId && loanId && api.getPortfolioLoan(businessId, loanId)
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AnimatedScreenOpacityWrapper>
        <CenteredContentScreen.Wrapper>
          <BackButton
            to={goBackUrlGenerated}
            hideOnDesktop={false}
            topSpace
            Icon={<FontAwesomeIcon icon={regular('xmark')} size="xl" />}
          />
          <CenteredContentScreen.Content>
            <CenteredContentScreen.Title>{title}</CenteredContentScreen.Title>
            <CenteredContentScreen.Description>
              {t('ShareHoldersScreen:description')}
            </CenteredContentScreen.Description>
            <ShareholdersList
              title={title}
              addUrl={addShareholderPathGenerated}
              goBackUrl={goBackUrlGenerated}
              persons={loan?.legalEntity?.persons}
              businessId={businessId as string}
              loanId={loanId as string}
              mutate={mutate}
            />
          </CenteredContentScreen.Content>
        </CenteredContentScreen.Wrapper>
      </AnimatedScreenOpacityWrapper>
    </>
  );
};
