import { useMemo } from 'react';

import {
  QualificationStatusApi,
  useQualificationStatus,
} from 'components/QualificationStatus';

import { useUser } from 'providers/UserProvider';

import { routePaths } from 'router/routePaths';

export interface useConfidentialityAgreementBannerProps {
  requiresAgreement?: boolean;
}

export const useConfidentialityAgreementBanner = (
  props?: useConfidentialityAgreementBannerProps
) => {
  const qualificationStatus = useQualificationStatus();
  const { user } = useUser();
  const isQualified =
    qualificationStatus?.status === QualificationStatusApi.QUALIFIED;

  const hasAgreedConfidentialityAgreement = !!user?.confidentialityAgreement;

  const showConfidentialityAgreementBanner =
    isQualified && !hasAgreedConfidentialityAgreement;

  const state = useMemo(() => ({ backUrl: window.location.pathname }), []);

  if (!props?.requiresAgreement) {
    return {
      showConfidentialityAgreementBanner: false,
    };
  }

  return {
    showConfidentialityAgreementBanner,
    path: routePaths.confidentialityAgreement,
    state,
  };
};
