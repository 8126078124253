import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { apiV2 } from 'lib/api';
import { ForSaleApplicationApi } from 'lib/types';

export const useSellApplicationFromUrlParams = (config?: {
  loanId?: string;
  keyModifier?: string;
}) => {
  const params = useParams();
  const applicationId = config?.loanId ?? (params.loanId as string);

  const { data: sellApplication, mutate } = useSWR<ForSaleApplicationApi>([
    apiV2.sellApplication(applicationId),
    config?.keyModifier,
  ]);

  return {
    applicationId,
    sellApplication,
    mutate,
  };
};
