import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Badge } from 'ui/Badge';

import { DeleteConfirmationAction } from 'components/ConfirmationModal';
import { CountryFlagImage } from 'components/CountryFlagImage';

import api from 'lib/api';

import { IShareholderCard } from './ShareholderCard.interface';
import {
  StyledBadgesContainer,
  StyledCard,
  StyledData,
  StyledName,
  StyledNameContainer,
} from './ShareholderCard.styles';

export const ShareholderCard = ({
  person,
  businessId,
  loanId,
  mutate,
}: IShareholderCard) => {
  const { t } = useTranslation();

  const personId = person.id;

  const handleDelete = useCallback(async () => {
    await api.removeContractPerson({ businessId, loanId, personId });
    await mutate();
  }, [businessId, loanId, personId, mutate]);

  return (
    <StyledCard data-testid="shareholder-card">
      <StyledNameContainer>
        <StyledName>
          <CountryFlagImage
            countryCode={person.country}
            css={{ mr: '$2', borderRadius: '.8rem' }}
          />
          <span>
            {person.firstName} {person.lastName}
          </span>
        </StyledName>
        <DeleteConfirmationAction
          title={t('ShareHoldersScreen:delete_confirmation')}
          onDelete={handleDelete}
        >
          <Button
            variant="secondary"
            rounded
            leftIcon={<FontAwesomeIcon icon={regular('trash')} size="lg" />}
            type="button"
          >
            {t('ShareHoldersScreen:delete_shareholder')}
          </Button>
        </DeleteConfirmationAction>
      </StyledNameContainer>
      <StyledBadgesContainer>
        <Badge variant={person.signer ? 'blue' : 'neutral'}>
          {t('ShareHoldersScreen:signs_summary')}
        </Badge>
        <Badge>{t('ShareHoldersScreen:direction')}</Badge>
        <Badge>{t('ShareHoldersScreen:shareholder_title')}</Badge>
      </StyledBadgesContainer>
      <StyledData>{person.email}</StyledData>
      <StyledData>
        {t('ShareHoldersScreen:identifier')}
        {person.identifier}
      </StyledData>
    </StyledCard>
  );
};
