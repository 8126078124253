import { ErrorMessage } from '@hookform/error-message';
import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InlineFeedback } from '@boopos/design-system';

export const AddShareholderErrorSummary = ({
  errors,
  variant = 'error',
}: {
  errors: FieldErrors;
  variant?: 'error' | 'warning' | 'info';
}) => {
  const { t } = useTranslation();

  const keys = Object.keys(errors);

  if (keys.length === 0) {
    return null;
  }

  return (
    <InlineFeedback
      heading={t('ShareHoldersScreen:error_missing_info')}
      variant={variant}
      defaultExpanded={true}
      data-testid="form-summary-errors"
    >
      <ul>
        {keys.map((fieldName) => (
          <ErrorMessage
            errors={errors}
            name={fieldName as any}
            as="li"
            key={fieldName}
          />
        ))}
      </ul>
    </InlineFeedback>
  );
};
