import { apiV2 } from 'lib/api';

import { UpdateSSNArgs, UserVault } from './privacy-ssn-api.types';
import { PrivacyVault } from './privacy-vault';

export class PrivacySSNApi {
  privacyVault: PrivacyVault;

  constructor() {
    this.privacyVault = new PrivacyVault();
  }

  async getUserVault() {
    const response = await apiV2.getVault<UserVault>();
    return response?.data;
  }

  validUserVault(userVault: UserVault | undefined) {
    return userVault?.collectionId && userVault?.objectId;
  }

  async updateSSN({ ssn = '' }: UpdateSSNArgs): Promise<void> {
    const userVault = await this.getUserVault();

    if (!this.validUserVault(userVault)) {
      return;
    }

    await this.privacyVault.updateObjectById({
      collectionId: userVault.collectionId,
      objectId: userVault.objectId,
      requestBody: {
        ssn,
      },
    });
  }

  async getSSN(): Promise<string | undefined> {
    const userVault = await this.getUserVault();

    if (!this.validUserVault(userVault)) {
      return undefined;
    }

    const data = await this.privacyVault.getObjectById({
      collectionId: userVault.collectionId,
      objectId: userVault.objectId,
      props: ['ssn.mask'],
    });

    return data['ssn.mask'];
  }
}
