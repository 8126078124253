import { Text, styled } from '@boopos/design-system';

export const StepLabel = styled('div', {
  width: '4rem',
  height: '4rem',
  display: 'flex',
  alignItems: 'center',
  jc: 'center',
  bc: '$slate700',
  color: 'white',
  borderRadius: '50%',
  flexShrink: 0,
  mr: '$4',
});

export const StepContent = styled('div', {});

export const FlexWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  my: '$2',
});

export const StepItem = styled('div', {
  display: 'flex',
  alignItems: 'center',
  jc: 'flex-start',
  mb: '$5',
});

export const HighlightedText = styled(Text, {
  ml: '$2',
  bc: '$slate100',
  p: '$1 $4',
});

export const ExplainerImage = styled('img', {
  maxWidth: '100%',
});

export const LinkLike = styled('a', {
  textDecoration: 'underline',
  cursor: 'pointer',
});
