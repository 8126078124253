import { MerchantType, Merchants } from 'components/AccountConnectors';
import { DocumentsDetails } from 'components/DocumentsDetails';

import {
  IBusinessAccount,
  ILoanApi,
  IPersonData,
  LoanDocumentType,
} from 'lib/types';

export interface ChildByStepProps {
  loan: ILoanApi;
}

const PeoplePreview = ({ people }: { people?: IPersonData[] }) => {
  const names = people?.map(
    (person) => `${person.firstName} ${person.lastName}`
  );
  if (!names?.length) {
    return null;
  }
  return <>{names.join(', ')}</>;
};

const filterAccounts = ({
  accounts,
  merchantType,
  provider,
  excludedProvider,
}: {
  accounts?: IBusinessAccount[];
  merchantType?: MerchantType;
  provider?: Merchants;
  excludedProvider?: Merchants;
}) => {
  if (merchantType) {
    return accounts
      ?.filter(
        (account) => !excludedProvider || account.provider !== excludedProvider
      )
      ?.filter((account) => account.accountType === merchantType);
  }

  if (provider) {
    return accounts?.filter((account) => account.provider === provider);
  }

  return [];
};

const MerchantsPreview = ({
  loan,
  merchantType,
  provider,
  excludedProvider = Merchants.bank,
}: {
  loan: ILoanApi;
  merchantType?: MerchantType;
  provider?: Merchants;
  excludedProvider?: Merchants;
}) => {
  const merchants = filterAccounts({
    accounts: loan?.accounts,
    merchantType,
    provider,
    excludedProvider,
  })?.map((account) => account.name);

  const invitations = merchantType
    ? loan?.businessInvitations
        ?.filter((invitation) => invitation.accountType === merchantType)
        .map((invitation) => invitation.email)
    : undefined;

  if (merchants?.length) {
    return <>{merchants.join(', ')}</>;
  }

  if (invitations?.length) {
    return <>{invitations.join(', ')}</>;
  }

  return null;
};

export const LetterOfIntentPreview = ({ loan }: ChildByStepProps) => (
  <DocumentsDetails
    documents={loan.documents}
    documentType={LoanDocumentType.letter_of_intent}
  />
);

export const FundsBankAccountPreview = ({ loan }: ChildByStepProps) =>
  loan?.fundsBankAccount?.accountNumber ? (
    <>{loan?.fundsBankAccount?.accountNumber}</>
  ) : null;

export const LegalEntityPreview = ({ loan }: ChildByStepProps) =>
  loan?.acquiringLegalEntity?.name ? (
    <>{loan?.acquiringLegalEntity?.name}</>
  ) : null;

export const ShareholdersAndDirectorsPreview = ({ loan }: ChildByStepProps) => (
  <PeoplePreview people={loan?.contractPeople} />
);

export const ContractSignersPreview = ({ loan }: ChildByStepProps) => (
  <PeoplePreview
    people={loan?.contractPeople?.filter((person) => person.signer)}
  />
);

export const RevenueSourcesPreview = ({ loan }: ChildByStepProps) => (
  <MerchantsPreview loan={loan} merchantType={MerchantType.revenueSource} />
);

export const RevenueBankAccount = ({ loan }: ChildByStepProps) => (
  <MerchantsPreview loan={loan} provider={Merchants.bank} />
);

export const ERPToolsPreview = ({ loan }: ChildByStepProps) => (
  <MerchantsPreview loan={loan} merchantType={MerchantType.erp} />
);

export const APAPreview = ({ loan }: ChildByStepProps) => (
  <DocumentsDetails
    documents={loan.documents}
    documentType={LoanDocumentType.asset_purchase_agreement}
  />
);
