import { motion } from 'framer-motion';

import { styled } from '@boopos/design-system';

export const PageHeaderStyles = {
  Header: styled(motion.div, {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    pt: '$4',
    '@bp2': {
      pt: '4rem',
    },
    '> *:not(:first-child)': {
      mt: '$2',
    },
    variants: {
      sticky: {
        true: {
          position: 'sticky',
          top: 0,
          zIndex: 4,
          pb: '$4',
          boxShadow: '0 2px 0 0 $colors$slate100',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          '> *:not(:first-child)': {
            mt: 0,
          },
        },
      },
      variant: {
        slate: {
          backgroundColor: '$slate50',
        },
        white: {
          backgroundColor: '$white',
        },
      },
    },
    defaultVariants: {
      variant: 'slate',
    },
  }),
  Title: styled(motion.h1, {
    fontHeadingSerif: '$h2',
    flex: 1,
    variants: {
      sticky: {
        true: {
          fontHeadingSerif: '$h3',
          '@bp2': {
            fontHeadingSerif: '$h2',
          },
        },
      },
    },
    '@bp2': {
      fontHeadingSerif: '$h1',
    },
  }),
  Subtitle: styled('h2', {
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    color: '$slate500',
    fontWeight: 'normal',
  }),
  Action: styled('div', {}),
  Top: styled(motion.div, {
    'a, button': {
      color: '$black',
      height: '4rem',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '4rem',
      lineHeight: 0,
      textDecoration: 'none',
      cursor: 'pointer',
      border: 0,
      background: 'transparent',
    },
  }),
  Middle: styled(motion.div, {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '$4',
    variants: {
      sticky: {
        true: {
          alignItems: 'center',
          flex: 1,
        },
      },
    },
  }),
  Bottom: styled(motion.div, {
    '@bp2': {
      mt: '-$2',
    },
  }),
};
