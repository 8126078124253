import { InsightsBarChart } from 'ui/InsightsBarChart';
import { TooltipRenderProps } from 'ui/InsightsLineChart';

import { InsightsPreparedData } from './InsightsCharts.interface';
import { UnitEconomicsTooltip } from './UnitEconomicsTooltip';
import { axisMoneyFormat } from './utils/axisMoneyFormat';
import { dateLabelShort } from './utils/dateLabelFormatter';

const renderTooltip = (props: TooltipRenderProps) => {
  return <UnitEconomicsTooltip {...props} />;
};
const barDataKeys = [
  'revenueAmount',
  'cogsAmount',
  'profitAmount',
] as (keyof InsightsPreparedData)[];

const xAxisDataKey = (entry: InsightsPreparedData) => entry.dateAccounting;

export const UnitEconomicsChart = ({
  data,
}: {
  data: InsightsPreparedData[];
}) => {
  return (
    <InsightsBarChart<InsightsPreparedData>
      data={data}
      xAxisDataKey={xAxisDataKey}
      xAxisTickFormatter={dateLabelShort}
      yAxisTickFormatter={axisMoneyFormat}
      barDataKeys={barDataKeys}
      renderTooltip={renderTooltip}
    />
  );
};
