import { keyframes, styled } from '@boopos/design-system';

import { Badge } from 'ui/Badge';

const spin = keyframes({
  to: { transform: 'rotate(360deg)' },
});

const NavigationCardStyles = {
  Wrapper: styled('div', {
    background: '$slate50',
    p: '$6',
    borderRadius: '1.6rem',
    textDecoration: 'none',
    appearance: 'none',
    border: 0,
    textAlign: 'left',
    width: '100%',
    cursor: 'pointer',
    display: 'block',
    color: '$accent',
    '& + &': {
      mt: '$6',
    },
    '&:hover': {
      background: '$slate100',
    },
    variants: {
      variant: {
        default: {
          background: '$slate50',
        },
        light: {
          background: '$white',
        },
      },
    },
  }),
  Container: styled('div', {
    display: 'flex',
    gap: '$4',
    alignItems: 'flex-end',
  }),
  Content: styled('div', {
    flex: 1,
  }),
  ArrowContainer: styled('div', {
    lineHeight: 0,
    variants: {
      loading: {
        true: {
          animation: `${spin} .675s linear 0s infinite normal`,
        },
      },
    },
  }),
  IconContainer: styled('div', {
    lineHeight: 0,
    color: '$slate800',
    mb: '$4',
  }),
  Tag: styled(Badge, {}),
  TagContainer: styled('div', {
    minHeight: '2.4rem',
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  Title: styled('div', {
    color: '$accent',
    fontSize: '1.8rem',
    lineHeight: '2.4rem',
    mb: '$1',
    fontWeight: 700,
  }),
  Description: styled('div', {
    fontSize: '1.4rem',
    lineHeight: '2.4rem',
    color: '$slate600',
    fontWeight: 400,
  }),
};

export { NavigationCardStyles };
