import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { StepAction } from './StepAction';
import { useStepVariant } from './useStepVariant';

export const useContentCellProps = ({
  editable,
  isMatching,
  hasValue,
  actionLiteral,
  toPath,
  error,
}: {
  toPath?: string;
  isMatching: boolean;
  editable?: boolean;
  hasValue?: boolean;
  actionLiteral?: ReactNode;
  error?: string;
}) => {
  const variant = useStepVariant(isMatching, { hasValue, hasError: !!error });

  if (editable && toPath) {
    return {
      as: Link,
      to: toPath,
      action: (
        <StepAction isMatching={isMatching} hasValue={hasValue}>
          {actionLiteral}
        </StepAction>
      ),
      variant,
    };
  }

  return { active: false, variant };
};
