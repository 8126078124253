import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { v4 as uuidv4 } from 'uuid';

import { createToastFeedback } from '@boopos/design-system';

import { usePersistenceAtom } from 'components/ClosingChecklistPersistence';
import { useClosingStepMatchStatus } from 'components/DealClosingCheckList';

import Api from 'lib/api';
import {
  ClosingCheckListStepStatus,
  ClosingStepsNames,
  ILoanApi,
} from 'lib/types';

import { shareHoldersAndDirectorsAtom } from './ShareHoldersAndDirectors.atoms';
import {
  IPersonData,
  IShareholdersAndDirectorsData,
  IUseShareholdersAndDirectors,
  IUseShareholdersAndDirectorsReturn,
} from './ShareholdersAndDirectors.interface';
import { parseToApi } from './ShareholdersAndDirectors.utils';

const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  country: 'US',
};

const emptyArray: IPersonData[] = [];

const newPersonId = 'new-person';

export const useShareholdersAndDirectors = ({
  loanId,
  onSuccess,
}: IUseShareholdersAndDirectors): IUseShareholdersAndDirectorsReturn => {
  const { data: loan, mutate: refreshLoan } = useSWR<ILoanApi>(
    Api.getLoan(loanId)
  );
  const { t } = useTranslation();
  const [personId, setPersonId] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isWaitingForReview = useClosingStepMatchStatus({
    loan,
    status: ClosingCheckListStepStatus.waiting,
    name: ClosingStepsNames.shareholdersAndDirectors,
  });

  const isVerified = useClosingStepMatchStatus({
    loan,
    status: ClosingCheckListStepStatus.verified,
    name: ClosingStepsNames.shareholdersAndDirectors,
  });

  const formMethods = useForm<IPersonData>({
    defaultValues,
  });

  const {
    value,
    setValue,
    reset: resetPeople,
  } = usePersistenceAtom<IShareholdersAndDirectorsData>(
    shareHoldersAndDirectorsAtom,
    loanId
  );

  const { reset } = formMethods;

  const people = value?.people || loan?.contractPeople || emptyArray;
  const selectedPerson = people.find((person) => person.id === personId);
  const isReady = !!loan;

  const persistPeople = useCallback(() => {
    setValue({ people });
  }, [people, setValue]);

  const handleEditPerson = useCallback(
    (data: IPersonData) => {
      const newPeople = people.map((person) => {
        if (person.id === personId) {
          return {
            ...person,
            ...data,
          };
        }
        return person;
      });

      setValue({
        people: newPeople,
      });
    },
    [people, personId, setValue]
  );

  const handleCreatePerson = useCallback(
    (data: IPersonData) => {
      setValue({
        people: [...people, { ...data, id: uuidv4() }],
      });
    },
    [setValue, people]
  );

  const onDeletePerson = useCallback(() => {
    const newPeople = people.filter((person) => person.id !== personId);
    setValue({
      people: newPeople,
    });
    setPersonId(null);
  }, [people, setValue, personId]);

  const onSubmit = useCallback(
    (data: IPersonData) => {
      if (personId === newPersonId) {
        handleCreatePerson(data);
        setPersonId(null);
        return;
      }

      handleEditPerson(data);

      setPersonId(null);
    },
    [personId, handleCreatePerson, handleEditPerson]
  );

  const onAddNewPerson = useCallback(() => {
    setPersonId(newPersonId);
  }, []);

  const onCancelReview = useCallback(async () => {
    try {
      await Api.deleteContractPeople(loanId);
      persistPeople();

      setTimeout(() => {
        refreshLoan();
      }, 1000);
    } catch (err) {
      console.error(err);
      createToastFeedback({
        title: t('DealClosingCheckList:unexpected_error_canceling_review'),
        type: 'error',
      });
    }
  }, [persistPeople, loanId, refreshLoan, t]);

  const onDetailBack = useCallback(() => {
    setPersonId(null);
  }, []);

  const onEditPerson = useCallback((personId: string) => {
    setPersonId(personId);
  }, []);

  const onSendForReview = useCallback(async () => {
    try {
      setIsLoading(true);
      await Api.updateContractPeople(loanId, parseToApi(people));
      setSuccess(true);

      setTimeout(() => {
        refreshLoan();
        onSuccess?.();
        resetPeople();
      }, 1000);
    } catch (err) {
      console.error(err);
      createToastFeedback({
        title: t('DealClosingCheckList:unexpected_error_sending_review'),
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }, [loanId, people, onSuccess, t, resetPeople, refreshLoan]);

  useEffect(() => {
    if (!personId) {
      reset(defaultValues);
      return;
    }
    reset(selectedPerson);
  }, [personId, reset, selectedPerson]);

  useEffect(() => {
    if (people?.length) return;

    setPersonId(newPersonId);
  }, [people]);

  return {
    formMethods,
    onSubmit,
    isDetailView: !!personId,
    onAddNewPerson,
    onDeletePerson,
    onCancelReview,
    onDetailBack,
    onEditPerson,
    people,
    selectedPerson,
    onSendForReview,
    isLoading,
    isSuccess: success,
    isWaitingForReview,
    isVerified,
    isReady,
  };
};
