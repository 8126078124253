import { useEffect } from 'react';

import { DealFlowCard } from 'components/DealFlowCard';

import api from 'lib/api';
import { useInfiniteScroll } from 'lib/hooks/useInfiniteScroll';
import { usePaginatedResponse } from 'lib/hooks/usePaginatedResponse';
import { ILoanApi } from 'lib/types';

import { DealFlowListEmpty } from './DealFlowListEmpty';
import { DealFlowListError } from './DealFlowListError';
import { DealFlowListLoading } from './DealFlowListLoading';

type NotifyStatusFn = (props: {
  isError: boolean;
  isEmpty: boolean;
  isFirstLoading: boolean;
}) => void;

export const DealFlowList = ({
  notifyStatus,
}: {
  notifyStatus?: NotifyStatusFn;
}) => {
  const { data, isReachingEnd, loadNextPage, isEmpty, isFirstLoading, error } =
    usePaginatedResponse<ILoanApi>(api.getDeals);

  const lastElementRef = useInfiniteScroll(isReachingEnd, loadNextPage);

  const isError = error && !data.length;

  useEffect(() => {
    notifyStatus?.({ isError, isEmpty, isFirstLoading });
  }, [notifyStatus, isEmpty, isFirstLoading, isError]);

  if (isError) {
    return <DealFlowListError />;
  }

  if (isFirstLoading) {
    return <DealFlowListLoading />;
  }

  if (isEmpty) {
    return <DealFlowListEmpty />;
  }

  return (
    <>
      {data.map((loan) => (
        <DealFlowCard ref={lastElementRef} key={loan.id} loan={loan} />
      ))}
    </>
  );
};
