import { InlineFeedback } from '@boopos/design-system';

import { IPreviewAlertMessageProps } from './PreviewAlertMessage.interface';
import { usePreviewMessageKeys } from './usePreviewMessageKeys';

export const ERROR_0 = 'error_0%';

export const PreviewAlertMessage = ({ message }: IPreviewAlertMessageProps) => {
  const literals = usePreviewMessageKeys(message?.code);

  if (!message) {
    return null;
  }

  if (!literals) {
    return null;
  }

  const { title: titleKey, description: descriptionKey } = literals;

  return (
    <InlineFeedback
      heading={titleKey}
      variant={message.type}
      defaultExpanded
      css={{ mb: '$4' }}
      data-testid="preview-alert-message"
    >
      {descriptionKey}
    </InlineFeedback>
  );
};
