import { theme } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { StaticTooltip } from 'ui/Tooltip';

export interface TooltipProps {
  id: string;
  description: string;
}

export const Tooltip = ({ id, description }: TooltipProps) => {
  return (
    <>
      <FontAwesomeIcon
        icon={regular('circle-info')}
        size="sm"
        color={theme.colors.textBodyTertiary.computedValue}
        data-testid={id}
        data-tooltip-id={id}
        data-tooltip-content={description}
        data-tooltip-place="top"
        data-tooltip-position-strategy="fixed"
      />
      <StaticTooltip id={id} place="right" />
    </>
  );
};
