import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { createToastFeedback } from '@boopos/design-system';

import {
  IDealStructureFields,
  IUseDealStructure,
} from 'components/DealStructureHelpers/DealStructure.interface';
import {
  getDealStructureDefaultValues,
  parseToApi,
} from 'components/DealStructureHelpers/DealStructure.utils';

import api from 'lib/api';
import { defaultCurrency } from 'lib/constants';

export const useDealStructure = ({
  loanId,
  currency = defaultCurrency,
  handleSuccess,
}: IUseDealStructure) => {
  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { data: deal } = useSWR(loanId && api.getLoan(loanId));
  const isReady = !!deal;

  const defaultValues = useMemo(
    () => getDealStructureDefaultValues(deal?.purchaseDealStructure),
    [deal]
  );

  const formMethods = useForm<IDealStructureFields>({
    defaultValues,
  });

  const isSubmitting = formMethods.formState.isSubmitting;

  const handleRemoveError = useCallback(() => {
    setHasError(false);
  }, []);

  const handleFormSubmitPrevention = useCallback(
    (e: React.KeyboardEvent) =>
      ['Enter', 'NumpadEnter'].includes(e.code) && e.preventDefault(),
    []
  );

  const handleFormSubmit = useCallback(
    async (data: IDealStructureFields) => {
      try {
        await api.dealStructureSave(loanId, parseToApi(data, currency));
        await handleSuccess?.();
        setIsSuccess(true);
      } catch (err) {
        console.error(err);
        setHasError(true);
      }
    },
    [currency, loanId, handleSuccess]
  );

  useEffect(() => {
    if (!hasError) return;

    createToastFeedback({
      type: 'warning',
      title: t('DealStructureScreen:error'),
    });
  }, [hasError, t]);

  return {
    formMethods,
    defaultValues,
    isReady,
    isSuccess,
    isSubmitting,
    hasError,
    handleFormSubmitPrevention,
    handleRemoveError,
    handleFormSubmit,
  };
};
