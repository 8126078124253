import { LogoutOptions, useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';

import { useCompanionMode } from 'lib/companion';

export const useAuth = () => {
  const {
    isCompanionMode,
    logout: companionModeLogout,
    getAccessToken: companionModeGetAccessToken,
  } = useCompanionMode();

  const {
    isAuthenticated,
    getAccessTokenSilently,
    logout: auth0Logout,
    isLoading: isAuthLoading,
  } = useAuth0();

  const getAccessToken = useCallback(async () => {
    if (isCompanionMode) {
      return companionModeGetAccessToken();
    }

    return getAccessTokenSilently();
  }, [getAccessTokenSilently, isCompanionMode, companionModeGetAccessToken]);

  const logout = useCallback(
    async (params?: LogoutOptions) => {
      companionModeLogout();
      auth0Logout(params);
    },
    [companionModeLogout, auth0Logout]
  );

  return {
    isAuthenticated: isAuthenticated || isCompanionMode,
    isAuthLoading,
    getAccessToken,
    logout,
  };
};
