import { FC } from 'react';
import { useInView } from 'react-intersection-observer';

import { PageHeaderProps } from './PageHeader.interface';
import { PageHeaderStyles } from './PageHeader.styles';

export const PageHeader: FC<PageHeaderProps> = ({
  action,
  title,
  subtitle,
  childrenTop,
  defaultSticky,
  stickOnScroll = true,
  ...props
}) => {
  const { ref: sentinelRef, inView } = useInView({
    initialInView: true,
  });
  const sticky = defaultSticky || (stickOnScroll && !inView);

  return (
    <>
      <PageHeaderStyles.Header sticky={sticky} {...props}>
        {!!childrenTop && (
          <PageHeaderStyles.Top>{childrenTop}</PageHeaderStyles.Top>
        )}
        <PageHeaderStyles.Middle sticky={sticky}>
          <PageHeaderStyles.Title sticky={sticky}>
            {title}
          </PageHeaderStyles.Title>
          {!!action && (
            <PageHeaderStyles.Action>{action}</PageHeaderStyles.Action>
          )}
        </PageHeaderStyles.Middle>
        {!sticky && !!subtitle && (
          <PageHeaderStyles.Bottom>
            <PageHeaderStyles.Subtitle>{subtitle}</PageHeaderStyles.Subtitle>
          </PageHeaderStyles.Bottom>
        )}
      </PageHeaderStyles.Header>
      <div ref={sentinelRef} />
    </>
  );
};
