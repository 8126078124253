import { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { LandingScreenMemo } from 'providers/FirstAppScreenDecider/LandingScreenMemo';

import { useQualifyFlowDestination } from './useQualifyFlowDestination';

interface QualifyActionChildrenProps {
  as: typeof Link;
  to: string;
  state?: { nextScreenOverwrite: string };
}

interface QualifyActionProps {
  children: (props: QualifyActionChildrenProps) => ReactNode;
}

export const QualifyAction = ({ children }: QualifyActionProps) => {
  const location = useLocation();
  const { goToUrl, nextScreenOverwrite } = useQualifyFlowDestination();

  const props = {
    as: Link,
    to: goToUrl,
    state: { nextScreenOverwrite },
  };

  return (
    <>
      <LandingScreenMemo pathname={location.pathname} />
      {children(props)}
    </>
  );
};
