import { IAssetPurchaseAgreementData } from './AssetPurchaseAgreement.interface';

export const parseToApi = ({
  assetPurchaseAgreementFiles,
}: IAssetPurchaseAgreementData) => {
  return {
    document: {
      files: assetPurchaseAgreementFiles?.map((item) => ({
        name: item?.name,
        url: item?.url,
      })),
    },
  };
};
