import React, { MouseEvent, useCallback } from 'react';

import { Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IUploadedFile } from 'lib/types';

import {
  FilePreviewLink,
  FilePreviewName,
  FilePreviewWrapper,
  animationVariants,
} from './FilePreview.styles';

export const FilePreview = ({
  file,
  onDelete,
}: {
  file: IUploadedFile;
  onDelete: (file: IUploadedFile) => void;
}) => {
  const handleDelete = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      onDelete(file);
    },
    [file, onDelete]
  );

  return (
    <FilePreviewWrapper
      key={file.downloadUrl}
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={animationVariants}
    >
      <FilePreviewName>
        <FontAwesomeIcon icon={regular('file-check')} size="lg" />
        <FilePreviewLink
          href={file.downloadUrl}
          title={file.fileName}
          target="_blank"
        >
          {file.fileName}
        </FilePreviewLink>
      </FilePreviewName>
      <Button
        rounded
        leftIcon={<FontAwesomeIcon icon={regular('trash')} size="lg" />}
        variant="nude"
        onClick={handleDelete}
      />
    </FilePreviewWrapper>
  );
};
