import { useTranslation } from 'react-i18next';

import { ContentCell } from 'ui/ContentCell';

import { LoanTermSheetColumns } from 'components/TermSheet';
import { TermSheetHandler } from 'components/TermSheetHandler';

import { ITermSheetApi } from 'lib/types';

import { Styles } from './TermSheetDetails.styles';

export const TermSheetDetails = ({
  termSheet,
  id,
  title,
  showTitle = true,
}: {
  termSheet?: ITermSheetApi;
  id?: string;
  title?: string;
  showTitle?: boolean;
}) => {
  const { t } = useTranslation();

  if (!termSheet) {
    return null;
  }

  return (
    <TermSheetHandler termSheet={termSheet}>
      <TermSheetHandler.Base>
        {showTitle && (
          <ContentCell.Divider>
            {t('TermSheetDetails:main_title')}
          </ContentCell.Divider>
        )}
        <Styles.Wrapper>
          <LoanTermSheetColumns termSheet={termSheet} id={id} title={title} />
        </Styles.Wrapper>
      </TermSheetHandler.Base>
      <TermSheetHandler.External>
        {showTitle && (
          <ContentCell.Divider>
            {t('TermSheetDetails:main_title_external')}
          </ContentCell.Divider>
        )}
        <Styles.Wrapper>
          {t('TermSheetDetails:external_terms_description')}
        </Styles.Wrapper>
      </TermSheetHandler.External>
    </TermSheetHandler>
  );
};
