import { ILinkedInCVInputForm } from 'components/LinkedInCVInput';

import { IApiFile, IUser } from 'lib/types';

export const parseFromApi = (user?: IUser) => ({
  linkedinUrl: user?.linkedinUrl,
  cvFiles: user?.cv?.files,
});

export const parseToApi = (data: ILinkedInCVInputForm) => {
  const cvFiles = data?.cvFiles;
  const hasCvFiles = !!cvFiles && cvFiles?.length > 0;
  const linkedinUrl = hasCvFiles ? '' : data?.linkedinUrl?.trim() ?? '';

  return {
    linkedinUrl,
    cv: hasCvFiles ? { files: data.cvFiles } : { files: [] as IApiFile[] },
  };
};
