import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiErrorCodes } from 'lib/types';

export const useApiErrorsLiterals = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      taxId: {
        [ApiErrorCodes.alreadyExists]: t(
          'AcquiringLegalEntityModal:tax_id_already_exists'
        ),
      },
    }),
    [t]
  );
};
