import { IPersonData } from 'lib/types';

export const parseToApi = (people: IPersonData[]) => ({
  contractPeople: people.map(({ id, passport, ...person }) => {
    return {
      ...person,
      passport: {
        files: passport?.files?.map(({ name, url }) => ({ name, url })),
      },
    };
  }),
});
