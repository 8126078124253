import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input } from '@boopos/design-system';
import { requiredWithTrim } from '@boopos/utils';

import { SelectHookForm } from 'components/SelectHookForm';

import { otherBrokerSlug, useBrokersForSelect } from 'lib/brokers';
import { otherCategory, useBusinessTypesForSelect } from 'lib/businessType';
import { getInputErrorProps } from 'lib/forms';
import { useBusinessWebsiteValidation } from 'lib/hooks';

import { IAddTargetBusinessFormFields } from './AddTargetBusinessForm.interface';
import { notUndefinedBroker } from './AddTargetBusinessForm.utils';

export const AddTargetBusinessFormFields = () => {
  const { t } = useTranslation();

  const brokersForSelect = useBrokersForSelect();
  const businessTypesForSelect = useBusinessTypesForSelect();

  const { isValidUrl, isNotABrokerUrl } = useBusinessWebsiteValidation({
    shouldValidateBrokerUrl: true,
    invalidUrlMessage: t('AddTargetBusinessForm:url_error'),
    brokerUrlMessage: t('AddTargetBusinessForm:broker_url_error'),
  });

  const { register, formState, control, watch } =
    useFormContext<IAddTargetBusinessFormFields>();

  const watchedType = watch('businessType');
  const watchedBroker = watch('broker');

  const businessWebsiteValidations = {
    isValidUrl,
    isNotABrokerUrl,
  };

  const isOtherCategory = watchedType === otherCategory;

  return (
    <>
      <Input
        label={t('AddTargetBusinessForm:name_label')}
        autoFocus
        {...register('name', {
          validate: requiredWithTrim(
            t('AddTargetBusinessForm:name_required_error')
          ),
        })}
        {...getInputErrorProps(formState?.errors?.name)}
        data-testid="name-input"
      />
      <Input
        label={t('AddTargetBusinessForm:website_label')}
        {...register('url', {
          required: t('AddTargetBusinessForm:website_required_error'),
          validate: businessWebsiteValidations,
        })}
        {...getInputErrorProps(formState?.errors?.url)}
        data-testid="url-input"
      />
      <SelectHookForm
        name="businessType"
        control={control}
        rules={{
          required: t('AddTargetBusinessForm:business_type_required_error'),
        }}
        label={t('AddTargetBusinessForm:business_type_label')}
        placeholder={t('AddTargetBusinessForm:business_type_placeholder')}
        options={businessTypesForSelect}
        value={businessTypesForSelect.find(
          ({ value }) => value === watchedType
        )}
        inputId="business-type-id"
        {...getInputErrorProps(formState.errors?.businessType)}
      />
      {isOtherCategory && (
        <Input
          label={t('AddTargetBusinessForm:business_type_other_label')}
          {...register('businessTypeOther', {
            required: t(
              'AddTargetBusinessForm:business_type_other_required_error'
            ),
            shouldUnregister: true,
          })}
          {...getInputErrorProps(formState.errors?.businessTypeOther)}
          data-testid="business-type-other-input"
        />
      )}
      <SelectHookForm
        name="broker"
        control={control}
        label={t('AddTargetBusinessForm:broker_label')}
        placeholder={t('AddTargetBusinessForm:broker_placeholder')}
        rules={{
          validate: {
            notUndefinedBroker: notUndefinedBroker(
              t('AddTargetBusinessForm:broker_required_error')
            ),
          },
        }}
        options={brokersForSelect}
        value={businessTypesForSelect.find(
          ({ value }) => value === watchedBroker
        )}
        inputId="broker-id"
        {...getInputErrorProps(formState.errors?.broker)}
      />
      {watchedBroker === otherBrokerSlug && (
        <Input
          label={t('AddTargetBusinessForm:broker_name_label')}
          {...register('brokerLongName', {
            required: t('AddTargetBusinessForm:broker_name_required'),
            shouldUnregister: true,
          })}
          {...getInputErrorProps(formState.errors?.brokerLongName)}
          data-testid="broker-other-name-input"
        />
      )}
    </>
  );
};
