import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Button, createToastFeedback } from '@boopos/design-system';

import api from 'lib/api';

export const TermSheetSummaryFooter = ({
  loanId,
  goBackUrl,
  isSellerSaleLoan,
}: {
  loanId?: string;
  goBackUrl: string;
  isSellerSaleLoan?: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleAccept = useCallback(async () => {
    if (!loanId) return;

    try {
      await api.signTermSheet(loanId);
      createToastFeedback({
        type: 'success',
        title: t('AddBusinessForm:term_sheet_accepted'),
      });
      navigate(goBackUrl);
    } catch (err) {}
  }, [t, goBackUrl, navigate, loanId]);

  if (isSellerSaleLoan) {
    return null;
  }

  return (
    <Box css={{ mb: '$4' }}>
      <Button
        fullWidth
        onClick={handleAccept}
        data-testid="term-sheet-accept-button"
      >
        {t('TermSheetSummary:accept')}
      </Button>
    </Box>
  );
};
