import { Navigate } from 'react-router-dom';

import { QualifyRouterProvider } from 'providers/QualifyRouterProvider';

import { routePaths } from 'router/routePaths';

import { QualifyCreditReport } from 'screens/QualifyScreens/QualifyCreditReport';
import { QualifyProfile } from 'screens/QualifyScreens/QualifyProfile';
import {
  QualifyProofOfFunds,
  QualifyProofOfFundsValidate,
} from 'screens/QualifyScreens/QualifyProofOfFunds';

export const qualifyRoutes = {
  path: routePaths.qualify,
  element: <QualifyRouterProvider />,
  children: [
    {
      index: true,
      element: <Navigate to={routePaths.qualifyProfile} replace />,
    },
    {
      path: routePaths.qualifyProfile,
      element: <QualifyProfile />,
    },
    {
      path: routePaths.qualifyProofOfFund,
      element: <QualifyProofOfFunds />,
    },
    {
      path: routePaths.qualifyProofOfFundValidate,
      element: <QualifyProofOfFundsValidate />,
    },
    {
      path: routePaths.qualifyCreditReport,
      element: <QualifyCreditReport />,
    },
  ],
};
