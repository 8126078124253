import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { BackButton } from 'components/BackButton';
import { LayoutHideMenu } from 'components/Layout';
import { ProofOfFundsAccount } from 'components/ProofOfFunds';
import {
  AnimatedScreenSlideWrapper,
  SubNavigationFirstLevelContent,
} from 'components/ScreenLayout';
import { SubScreenTitle } from 'components/SubScreenTitle';

import { routePaths } from 'router/routePaths';

export const ProofOfFundsScreen = () => {
  const { t } = useTranslation();

  const title = t('QualificationScreenMenu:proof_of_funds_section');

  return (
    <AnimatedScreenSlideWrapper>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <LayoutHideMenu hideOn="all" shouldShowMenuOnUnmount={false} />
      <SubNavigationFirstLevelContent>
        <BackButton to={routePaths.accountQualification} />
        <SubScreenTitle>{title}</SubScreenTitle>
        <ProofOfFundsAccount />
      </SubNavigationFirstLevelContent>
    </AnimatedScreenSlideWrapper>
  );
};
