import { styled } from '@boopos/design-system';

export const Styles = {
  Wrapper: styled('div', {
    display: 'flex',
    alignItems: 'baseline',
  }),
  Value: styled('p', {
    color: '$textBodyPrimary',
    fontWeight: '500',
    fontSize: '3.2rem',
    variants: {
      blur: {
        true: {
          filter: 'blur(7px)',
          userSelect: 'none',
          pointer: 'default',
        },
      },
    },
  }),
  MaxValue: styled('p', {
    color: '$textBodyTertiary',
    fontWeight: '500',
    fontSize: '2.4rem',
  }),
  Light: styled('div', {
    width: '2.4rem',
    height: '2.4rem',
    borderRadius: '1.8rem',
    display: 'flex',
    marginRight: '$2',

    variants: {
      status: {
        ok: {
          backgroundColor: '$infoPositive',
        },
        ko: {
          backgroundColor: '$infoStatic',
        },
      },
    },
  }),
};
