import { ModalUploadErrors } from './ModalUploadErrors';
import { UploadZoneBaseProps } from './UploadZone';

export interface ArrowComponentProps {
  open: () => void;
  loading: boolean;
}

interface UploadZoneCustomChildrenProps {
  children: (props: ArrowComponentProps) => JSX.Element;
}

export const UploadZoneCustomChildren = ({
  children,
  ...props
}: UploadZoneCustomChildrenProps) => {
  const {
    getRootProps,
    getInputProps,
    open,
    isUploading,
    hasErrors,
    handleRemoveError,
    errors,
    fileRejections,
  } = props as UploadZoneBaseProps;

  return (
    <>
      <div {...getRootProps()}>
        <input data-testid="custom-drop-input" {...getInputProps()} />
        {children({ open, loading: isUploading })}
      </div>
      <ModalUploadErrors
        opened={hasErrors}
        fileRejections={fileRejections}
        onClose={handleRemoveError}
        errors={errors}
      />
    </>
  );
};
