import { useCallback } from 'react';

import api from 'lib/api';
import { UserGoals } from 'lib/types';

export const useTrackUserGoals = () => {
  const trackGoal = useCallback(async (goal: UserGoals) => {
    try {
      return await api.addUserGoal({
        goal,
      });
    } catch (err: any) {}
  }, []);

  const trackBuyerGoal = useCallback(async () => {
    return trackGoal(UserGoals.buy);
  }, [trackGoal]);

  const trackSellerGoal = useCallback(async () => {
    return trackGoal(UserGoals.sell);
  }, [trackGoal]);

  const trackInsightsGoal = useCallback(async () => {
    return trackGoal(UserGoals.insights);
  }, [trackGoal]);

  return {
    trackBuyerGoal,
    trackSellerGoal,
    trackInsightsGoal,
  };
};
