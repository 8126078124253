import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  ButtonFeedback,
  InlineFeedback,
  createToastFeedback,
} from '@boopos/design-system';

import { BusinessForm, IBusinessFormFields } from 'components/BusinessForm';

import api from 'lib/api';

import { IEditBusinessForm } from './EditBusinessForm.interface';

export const EditBusinessForm = ({
  goBackUrl,
  business,
  buttonFullWidth = true,
}: IEditBusinessForm) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const formMethods = useForm<IBusinessFormFields>({
    shouldUseNativeValidation: false,
    defaultValues: {
      name: business.name,
      businessType: business.businessType as any,
      businessTypeOther: business.businessTypeOther,
      url: business.url,
    },
  });

  const { handleSubmit, formState } = formMethods;
  const businessId = business.id;

  const onSubmit = useCallback(
    async (data: IBusinessFormFields) => {
      try {
        await api.editPortfolioBusiness(businessId, data);
        setSuccess(true);
        createToastFeedback({
          type: 'success',
          title: t('EditBusinessForm:success_message'),
        });
        setTimeout(() => {
          navigate(goBackUrl);
        }, 1000);
      } catch (err) {
        setError(true);
        setSuccess(false);
      }
    },
    [t, navigate, businessId, goBackUrl]
  );

  const handleErrorRemove = useCallback(() => {
    setError(false);
  }, []);

  const isLoading = formState.isSubmitting;
  const isSuccess = success;

  const copies = {
    title: t('EditBusinessForm:title'),
    submitButtonLabel: t('EditBusinessForm:save_action'),
    nameLabel: t('AddBusinessForm:name_label'),
    websiteLabel: t('AddBusinessForm:website_label'),
    typeLabel: t('AddBusinessForm:type_label'),
  };

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={handleErrorRemove}
        data-testid="edit-business-form"
      >
        <BusinessForm copies={copies} />
        {error && (
          <InlineFeedback
            variant="warning"
            css={{ mb: '$8' }}
            data-testid="edit-business-form-error"
            heading={t('EditBusinessForm:error_message')}
          />
        )}
        <ButtonFeedback
          type="submit"
          fullWidth={buttonFullWidth}
          isLoading={isLoading}
          isSuccess={isSuccess && !error}
          data-testid="edit-business-form-submit"
        >
          {t('EditBusinessForm:save_action')}
        </ButtonFeedback>
      </form>
    </FormProvider>
  );
};
