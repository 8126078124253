import { AxiosError } from 'axios';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { DealDetail } from 'components/DealDetail';
import { SlateBackgroundBody } from 'components/GlobalStyles';
import { LayoutHideMenu } from 'components/Layout';
import { ListingUnlockModal } from 'components/ListingUnlock';
import { AnimatedScreenOpacityWrapper } from 'components/ScreenLayout';

import { apiV2 } from 'lib/api';
import { ApiErrorBody, ListingForSale } from 'lib/types';

import { routePaths } from 'router/routePaths';

import { BuyerInsightsTabMenu } from 'screens/BuyerBusinessInsights';

import { ForSalePageError } from './ForSalePageError';
import { ForSalePageHeader } from './ForSalePageHeader';

export const ForSaleDetailParent = () => {
  const params = useParams();
  const { listingId } = params || {};

  const {
    data: listing,
    mutate,
    error,
  } = useSWR<ListingForSale, AxiosError<ApiErrorBody>>(
    listingId && apiV2.getListing(listingId)
  );

  const onSuccess = useCallback(async () => {
    await mutate();
  }, [mutate]);

  const Content = (
    <DealDetail.Wrapper data-testid="for-sale-detail-parent">
      <DealDetail.ContainerWrapper>
        <ForSalePageHeader listing={listing} onSuccess={onSuccess} />
        <BuyerInsightsTabMenu
          loanApplicationPath={routePaths.forSaleDetail}
          businessInsightsPath={routePaths.forSaleInsights}
          businessInsights={listing?.businessInsights}
        />

        <Outlet />
      </DealDetail.ContainerWrapper>
    </DealDetail.Wrapper>
  );

  const PageError = <ForSalePageError error={error?.response?.data} />;

  const shouldRenderError = !listing && error;

  return (
    <>
      <SlateBackgroundBody />
      <Helmet>{!!listing?.title && <title>{listing?.title}</title>}</Helmet>
      <AnimatedScreenOpacityWrapper>
        <LayoutHideMenu hideOn="all" />
        {shouldRenderError ? PageError : Content}
      </AnimatedScreenOpacityWrapper>
      <ListingUnlockModal listing={listing} />
    </>
  );
};
