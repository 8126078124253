import { styled } from '@boopos/design-system';

export const QualificationDot = styled('div', {
  width: '.8rem',
  height: '.8rem',
  borderRadius: '.8rem',
  backgroundColor: '$red500',
  position: 'absolute',
  top: '1.6rem',
  marginRight: '-2.8rem',
});
