import { motion } from 'framer-motion';

import { styled } from '@boopos/design-system';

export const ActionSideModalStyles = {
  CloseWrapper: styled('div', {
    p: '1.6rem',
    '@bp2': {
      p: '3.2rem',
      pb: 0,
    },
  }),
  BodyWrapper: styled('div', {
    p: '1.6rem',
    '@bp2': {
      pt: '2.4rem',
      p: '3.2rem',
    },
  }),

  Footer: styled('div', {
    p: '$6',
    boxShadow:
      '0px -2px 8px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.05)',
  }),
};

export const ActionSideModalCommonStyles = {
  Title: styled('h2', {
    fontHeadingSerif: '$h2',
    color: '$textBodyPrimary',
    mb: '2.4rem',
  }),
  Subtitle: styled('div', {
    color: '$textBodySecondary',
    mb: '4.8rem',
    '& a': {
      color: '$slate500',
      '&:hover': {
        color: '$slate800',
      },
    },
    variants: {
      mbSmall: {
        true: {
          mb: '$6',
        },
      },
      toc: {
        true: {
          mt: '$9',
        },
      },
    },
  }),
  BottomActionWrapper: styled('div', {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  BottomNavError: styled('div', {
    flex: 1,
    color: '$red600',
    alignSelf: 'center',
    mr: '$3',
  }),
  BottomNavLeftText: styled('div', {
    flex: 1,
    color: '$slate500',
  }),
  BottomNavCenterText: styled('div', {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    color: '$slate500',
    svg: {
      color: '$lime500',
      mr: '$2',
    },
  }),
  BottomNav: styled(motion.div, {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    bottom: 0,
    left: 0,
    right: 0,
    background: '#fff',
    zIndex: 99,
    padding: '2.4rem',
    boxShadow: '0px -2px 8px rgb(0 0 0 / 10%), 0px 0px 8px rgb(0 0 0 / 5%)',
  }),
  Form: styled('form', {
    pb: '9.6rem',
  }),
  Block: styled('div', {
    mb: '5.6rem',
    variants: {
      bordered: {
        true: {
          borderBottom: '1px solid $slate300',
          pb: '5.6rem',
        },
      },
    },
  }),
  BlockTitle: styled('div', {
    mb: '$6',
    fontWeight: 'bold',
  }),
  BlockDescription: styled('div', {
    mb: '$6',
    color: '$textBodySecondary',
  }),
  DescriptionParagraph: styled('div', {
    mb: '$6',
  }),
};

export const bottomNavAnimationVariants = {
  visible: {
    bottom: 0,
    transition: { delay: 0.3, bounce: 0 },
  },
  hidden: {
    bottom: '-100%',
    transition: { delay: 0, bounce: 0 },
  },
};
