import { ContentCellDetail } from 'ui/ContentCellDetail';

import { TermSheetLayout } from 'components/TermSheet/TermSheet.styles';
import { TermSheetDownload } from 'components/TermSheet/TermSheetDownload';

export interface IFtlTermSheetColumns {
  literals: any;
  maturityLengthFormatted: string;
  openingFeeFormatted: string;
  annualInterestFormatted: string;
  loanId?: string;
  loanTitle?: string;

  blur?: boolean;
  bordered?: boolean;
}

export const FtlTermSheetColumns = ({
  literals,
  maturityLengthFormatted,
  openingFeeFormatted,
  annualInterestFormatted,
  loanId,
  loanTitle,
  blur,
  bordered,
}: IFtlTermSheetColumns) => {
  return (
    <>
      <TermSheetLayout.Column>
        <ContentCellDetail.Label>{literals.duration}</ContentCellDetail.Label>
        <ContentCellDetail.Value>
          {maturityLengthFormatted}
        </ContentCellDetail.Value>
      </TermSheetLayout.Column>
      <TermSheetLayout.Column>
        <ContentCellDetail.Label>{literals.openingFee}</ContentCellDetail.Label>
        <ContentCellDetail.Value>{openingFeeFormatted}</ContentCellDetail.Value>
      </TermSheetLayout.Column>
      <TermSheetLayout.Column bordered={bordered}>
        <ContentCellDetail.Label>
          {literals.annualInterest}
        </ContentCellDetail.Label>
        <ContentCellDetail.Value>
          {annualInterestFormatted}
        </ContentCellDetail.Value>
      </TermSheetLayout.Column>
      <TermSheetLayout.Column blur={blur}>
        <ContentCellDetail.Label>{literals.termSheet}</ContentCellDetail.Label>
        <TermSheetDownload loanId={loanId} title={loanTitle} blur={blur} />
      </TermSheetLayout.Column>
    </>
  );
};
