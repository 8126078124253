import {
  MerchantType,
  Merchants,
} from 'components/AccountConnectors/AccountConnectors.interface';
import { IApiScores } from 'components/BusinessInsights';
import { IValuationApi } from 'components/ValuationCard/ValuationCard.interface';

export enum BankAccountType {
  escrow = 'escrow',
  seller = 'seller',
  buyer = 'buyer',
}
export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
}

export type Money = {
  amount: number;
  currency: Currency;
};

export enum ClosingStepsNames {
  termSheet = 'termSheet',
  letterOfIntent = 'letterOfIntent',
  legalEntity = 'legalEntity',
  shareholdersAndDirectors = 'shareholdersAndDirectors',
  contractSigners = 'contractSigners',
  revenueSources = 'revenueSources',
  ERPTools = 'ERPTools',
  assetPurchaseAgreement = 'assetPurchaseAgreement',
  paymentMethod = 'paymentMethod',
  fundsBankAccount = 'fundsBankAccount',
  revenueBankAccount = 'revenueBankAccount',
}

export enum ClosingCheckListStepStatus {
  pending = 'pending',
  disabled = 'disabled',
  completed = 'completed',
  waiting = 'waiting',
  verified = 'verified',
  missingInfo = 'missing_info',
}

export type GenericData<T> = {
  data: T;
};

export interface ClosingChecklist {
  name: ClosingStepsNames;
  required: boolean;
  status: ClosingCheckListStepStatus;
}

export type RevenueHistory = number[][];

export type ListingShort = {
  id: string;
  title: string;
  bookmark: boolean;
  revenueMonth: Money;
  profitMonth: Money;
  amount: Money;
  askPrice: Money;
  downPayment: Money;
  revenueHistory: RevenueHistory;
};

export interface ICategory {
  id: string;
  name: string;
  categories: ICategory[];
}

export interface IBroker {
  slug: string;
  name: string;
  url: string;
  referral: string;
  enabledForDealCreation: boolean;
}

export interface ICategoriesApi {
  [key: string]: string[];
}

export interface IBooposWaitList {
  joinedAt?: string;
  dismissedAt?: string;
}

export enum PreferenceType {
  booposPlusWaitingList = 'booposPlusWaitingList',
  buyerTarget = 'buyerTarget',
}

export enum BuyerTarget {
  none = 'none',
  specific = 'specific',
}

export interface IBuyerTarget {
  target: BuyerTarget;
}

export interface IPreferences {
  [PreferenceType.booposPlusWaitingList]: IBooposWaitList;
  [PreferenceType.buyerTarget]: IBuyerTarget;
}

export interface LegalTerms {
  version: string;
  acceptedAt: string;
  source: string;
}

export interface ConfidentialityAgreement extends LegalTerms {}

export interface UserResidency {
  country?: string;
}

export interface IUser {
  id: string;
  email: string;
  phone: string;
  isBroker: boolean;
  createdAt: string;
  qualifiedAt: string;
  isBusinessOwner: boolean;
  firstName: string;
  lastName: string;
  fullName: string;
  linkedinUrl: string;
  qualified: boolean;
  marketingOptIn: boolean;
  residency?: UserResidency;
  categories: ICategoriesApi;
  confidentialityAgreement?: ConfidentialityAgreement;
  legalTerms?: LegalTerms;

  profile: {
    goals?: UserGoals[];
    preferences?: IPreferences;
  };

  cv?: {
    files?: IApiFile[];
  };
}

export interface IFile {
  file: File;
  fileName: string;
  params?: {
    [key: string]: string;
  };
}

export interface IUploadedFile {
  fileName: string;
  downloadUrl: string;
  url: string;
}

export enum FileVisibility {
  public = 'public',
  hidden = 'hidden',
}

export interface IApiFile {
  id: string;
  name: string;
  url: string;
  downloadUrl: string;
  visibility?: FileVisibility;
}

export interface IApiDocument {
  type: LoanDocumentType | UserDocumentType;
  id: string;
  files: IApiFile[];
}

export interface ICategoriesForm {
  category: {
    [key: string]: string[] | boolean;
  };
}

export interface IPaginatedResponseApi<T> {
  docs?: T[];
  totalPages: number;
  nextPage: number;
}

export interface IDealStructure {
  businessPrice?: Money;
  inventoryPrice?: Money;
  sellerFinancing?: Money;
  requestedAmount?: Money;
}

export enum LoanDocumentType {
  letter_of_intent = 'letter_of_intent',
  asset_purchase_agreement = 'asset_purchase_agreement',
  balance_sheet = 'balance_sheet',
  profitAndLoss = 'tax_monthly_pnl',
  companyIdentifier = 'company_identifier',
  articlesOfIncorporation = 'articles_of_incorporation',
  additional_details = 'additional_details',
  cohorts = 'sku_analysis',
  kyc = 'kyc',
}

export enum UserDocumentType {
  kycFunds = 'kyc_funds',
}

export enum LoanTypesEnum {
  growth = 'growth',
  sale_seller = 'sale_seller',
  sale_buyer = 'sale_buyer',
  purchase = 'purchase',
  insights = 'insights',
}

export enum LoanStatus {
  draft = 'draft',
  missing_info = 'missing_info',
  applied = 'applied',
  analysis = 'analysis',
  canceled = 'canceled',
  termsheet_sent = 'termsheet_sent',
  termsheet_signed = 'termsheet_signed',
  due_diligence_applied = 'due_diligence_applied',
  due_diligence_analysis = 'due_diligence_analysis',
  due_diligence_missing_info = 'due_diligence_missing_info',
  contract_sent = 'contract_sent',
  contract_signed = 'contract_signed',
  funded = 'funded',
  repaid = 'repaid',
  rolled_up = 'rolled_up',
  taken_over = 'taken_over',
  refinanced = 'refinanced',
  write_off = 'write_off',
  defaulted = 'defaulted',
}

export interface BankAccountInstitution {
  name: string;
  url: string;
  primaryColor: string;
  logo: string;
}

export interface BankAccountMetadata {
  institution?: BankAccountInstitution;
  initialBalance?: Money;
}

export interface IBusinessAccount {
  id: string;
  description: string;
  name: string;
  provider: Merchants;
  externalId: string;
  businessId?: string;
  requestedAt?: string;
  createdAt?: string;
  connectedByMe?: boolean;
  accountType?: MerchantType;
  metadata?: BankAccountMetadata;
}

export interface IAccountApi {
  pending: IBusinessAccount[];
  connected: IBusinessAccount[];
}

export interface IBusinessApi {
  id: string;
  name: string;
  url: string;
  businessType: string;
  businessTypeLiteral: { id: string; name: string };
  businessTypeOther: string;
  loans?: ILoanApi[];
  insightsLoan?: ILoanApi;
  forSaleLoans?: ILoanApi[];
  accounts?: IAccountApi;
  accountsConnectedPending?: IAccountApi;
  broker: string | null;
  brokerLongName?: string;
  isApprovedByUnderwriting: boolean;
  financingLoans: ILoanApi[];
}

export interface IBusinessApiV2 {
  business: {
    id: string;
    name: string;
    url: string;
  };
  hasInsights: boolean;
  listedforSale: boolean;
  loans?: ILoanApi[];
  scores?: IApiScores;
  valuation?: IValuationApi['valuation'];
}

export type MultiplierTuple = [number, number | null];

export enum TermSheetType {
  rbl = 'rbl',
  ftl = 'ftl',
  external = 'external',
}

export interface IAmortizationTableItem {
  total: Money;
  capital: Money;
  interest: Money;
}
export interface ITermSheetApi {
  amount: Money;
  royalties: MultiplierTuple[];
  capMultipliers: MultiplierTuple[];
  type?: TermSheetType;
  annualInterest?: number;
  openingFee?: number;
  maturityLength?: number;
  amortizationTable?: IAmortizationTableItem[];
  gracePeriod?: number;
}

export interface ILoanContractPerson {
  country: string;
  documentId: string;
  email: string;
  firstName: string;
  id: string;
  identifier: string;
  lastName: string;
  director?: boolean;
  shareholder?: boolean;
  signer?: boolean;
}

export interface IAddress {
  street: string;
  country: string;
  city?: string;
  state?: string;
  zipCode?: string;
}
export interface ILegalEntity {
  id?: string;
  name: string;
  taxId: string;
  address: IAddress;
  persons: ILoanContractPerson[];
}

export interface ILetterOfIntent {
  documents: IApiDocument[];
}

export interface IAssetPurchaseAgreement {
  documents: IApiDocument[];
}

export interface IAcquiringLegalEntity extends ILegalEntity {
  articlesOfIncorporation: IApiDocument[];
  einConfirmationLetter: IApiDocument[];
}

export interface IPersonData {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  identifier: string;
  country: string;
  shareholder: boolean;
  director: boolean;
  signer?: boolean;
  passport: {
    files: IApiFile[];
  };
}

export interface IBusinessInvitation {
  id: string;
  email: string;
  accountType?: MerchantType;
}

export interface IFundsBankAccountApi {
  accountHolderName: string;
  accountHolderAddress: string;
  accountType: BankAccountType;
  bankName: string;
  country: string;
  state: string;
  city: string;
  accountNumber: string;
  routingNumber: string;
}

export enum IBusinessInsightsStatus {
  locked = 'locked',
  available = 'available',
  empty = 'empty',
}

export interface IBusinessInsights {
  id: string;
  businessId?: string;
  status: IBusinessInsightsStatus;
  price: Money;
  scores?: IApiScores;
}

export interface BaseListing {
  published?: boolean;
}

export interface ILoanApi {
  id: string;
  baseListing?: BaseListing;
  createdAt: string;
  amount: Money;
  maxAmount?: Money;
  requestedAmount?: Money;
  isSellerSaleLoan: boolean;
  termSheet?: ITermSheetApi;
  business: IBusinessApi;
  type: LoanTypesEnum;
  status: LoanStatus;
  documents: IApiDocument[];
  requiredDocuments: LoanDocumentType[];
  pendingDocuments: LoanDocumentType[];
  legalEntity: ILegalEntity;
  acquiringLegalEntity?: IAcquiringLegalEntity;
  letterOfIntent: ILetterOfIntent;
  assetPurchaseAgreement: IAssetPurchaseAgreement;
  pendingContractRequirements: string[];
  accounts?: IBusinessAccount[];
  accountsConnectedPending?: IAccountApi;
  businessId: string;
  termSheetSignedAt?: string;
  closingChecklist?: GenericData<ClosingChecklist[]>;
  contractPeople?: IPersonData[];
  businessInvitations?: IBusinessInvitation[];
  notes?: string;
  statusUpdatedAt?: string;
  fundsBankAccount?: IFundsBankAccountApi;
  purchaseDealStructure?: IDealStructure;
  canSendToEvaluation?: boolean;
  canBeArchived?: boolean;
  listing?: ListingForSale;
  title?: string;
  sellerLoan?: ISellerLoan;
  additionalDetails?: string;
  hasInsights?: boolean;
  hasPendingInsights?: boolean;
  businessInsights?: IBusinessInsights;
  tags: LoanStatusTags[];
}

export enum ActivityName {
  targetBusinessAdded = 'targetBusinessAdded',
  sendForEvaluation = 'sendForEvaluation',
  termSheetSigned = 'termSheetSigned',
  closingInformationCompleted = 'closingInformationCompleted',
  sellerContacted = 'sellerContacted',
  dealUnlocked = 'dealUnlocked',
}

export interface IActivityApi {
  id: string;
  name: ActivityName;
  createdAt: string;
  date: string;
}

export interface IWatchListDeal {
  id: string;
  businessId: string;
  requestedAmount: Money;
  business: IBusinessApi;
  purchaseDealStructure: IDealStructure;
  documents: IApiDocument[];
  pendingDocuments: string[];
  canSendToEvaluation: boolean;
  status: LoanStatus;
  notes: string;
  canBeArchived: boolean;
}

export interface IBuyerInvitationApi {
  id: string;
  businessId: string;
  loanId: string;
}

export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;

export interface IPayment {
  id: string;
  year: number;
  month: number;
  total: Money;
  invoiceUrl: string;
}

export type ICountry = [string, string, string];

export interface IAmazonInitConnectionApi {
  countries: ICountry[];
  path: string;
}

export enum ApiErrorCodes {
  alreadyExists = 'alreadyExists',
}

export interface ApiError {
  code: ApiErrorCodes;
  message: string;
}

export interface ApiErrorMessage {
  path: string;
  errors: ApiError[];
}

export interface ApiErrorData {
  message: ApiErrorMessage[];
}

export interface ApiErrorBody {
  code: number;
  error: string;
  errors: [];
  message: string;
}

export enum PaymentMethodTypes {
  us_bank_account = 'us_bank_account',
  card = 'card',
}

export interface IPaymentMethod {
  id: string;
  businessId: string;
  createdAt: string;
  updatedAt: string;
  expMonth: number;
  expYear: number;
  funding: string;
  last4: string;
  provider: string;
  providerId: string;
  type: PaymentMethodTypes;
  isDefault: boolean;
  bankName: string;
}
export interface IPaymentMethodApi {
  default: IPaymentMethod | null;
  other: IPaymentMethod[];
}

export interface IStripeResponse {
  clientSecret: string;
  customerId: string;
}

export interface ISellerLoan {
  documents?: IApiDocument[];
  sellerEmail?: string;
  businessUrl?: string;
}

export enum LoanAffordability {
  notAffordable = 'not_affordable',
  affordable = 'affordable',
  unknown = 'unknown',
}

export enum ListingTagName {
  new = 'new',
  exclusive = 'exclusive',
  matching = 'matching',
}

export interface ListingTag {
  name: ListingTagName;
  matching?: string | number;
}

export enum ListingSellerType {
  proprietary = 'owner',
  broker = 'broker',
}

export interface RedactedField<T> {
  redacted?: boolean;
  value?: T;
}

export interface ListingDealSource {
  name: string;
  url: string;
  externalId: string;
}

export interface ListingDealApplication {
  id?: string;
  requestedAt?: string;
  grantedAt?: string;
  termsAcceptedAt?: string;
  termSheet?: ITermSheetApi;
  sellerEmail?: string;
  sellerName?: string;
}

export enum ListingActionName {
  requestAccess = 'request_access',
}

export interface ListingAction {
  name: ListingActionName;
  disabled: boolean;
}

export interface ListingForSale {
  amount: Money;
  askPrice: Money;
  bookmark?: boolean;
  businessInsights?: IBusinessInsights;
  businessTypeName: string;

  description: string;
  id: string;
  loanAffordability?: LoanAffordability;
  profit: Money;
  profitMonth: Money;
  publishedAt: string;
  requiredFunds?: Money;
  revenue: Money;
  revenueHistory: RevenueHistory;
  revenueMonth: Money;
  tags?: ListingTag[];
  title: string;

  // New fields
  sellingReason?: string;
  sellerType?: ListingSellerType;

  businessLocation?: RedactedField<string>;
  businessAge?: RedactedField<string>;
  teamSize?: RedactedField<string>;
  activeCustomersNumber?: RedactedField<string>;

  brokerUrl?: RedactedField<string>;
  businessUrl?: RedactedField<string>;
  profitAndLossFiles?: RedactedField<IApiFile[]>;
  cohortsFiles?: RedactedField<IApiFile[]>;
  infoMemoFiles?: RedactedField<IApiFile[]>;
  termSheet?: RedactedField<ITermSheetApi>;
  dealSource?: RedactedField<ListingDealSource>;

  dealApplication?: ListingDealApplication;

  listingActions?: ListingAction[];
  requiresAgreement?: boolean;
  redacted?: boolean;
}

export interface ListingForSaleReduced {
  id: string;
  title: string;
  askPrice: Money;
  tags?: ListingTag[];
  bookmark?: boolean;
  revenue: Money;
  revenueHistory: RevenueHistory;
  profit: Money;
  termSheet?: RedactedField<ITermSheetApi>;
  dealApplication?: ListingDealApplication;
  dealSource?: RedactedField<ListingDealSource>;
  loanAffordability?: LoanAffordability;
  sellerType?: ListingSellerType;
}

export enum BusinessTypesEnum {
  amazonBrands = 'Amazon brands',
  other = 'Other',
  eCommerce = 'E-commerce',
  subscriptionBased = 'Subscription-based',
}

export enum LoanStatusTags {
  draft = 'draft',
  missingInfo = 'missing_info',
  applied = 'applied',
  preApproved = 'pre_approved',
  listedForSale = 'listed_for_sale',
  funded = 'funded',
  denied = 'denied',
  repaid = 'repaid',
  terminated = 'terminated',
  overdue = 'overdue',
  dueDiligence = 'due_diligence',
}

export enum UserGoals {
  buy = 'buy',
  sell = 'sell',
  insights = 'insights',
}

export type ByTermSheetType<T> = {
  [key in TermSheetType]?: T;
};

export enum EnquiryStatus {
  started = 'started',
  in_conversations = 'in_conversations',
  due_diligence = 'due_diligence',
  closing = 'closing',
  sold = 'sold',
  rejected = 'rejected',
}

type EnquiryCssVariant = 'pink' | 'orange' | 'blue' | 'yellow';

export interface EnquiryBuyerQualification {
  linkedin?: string;
  cvFiles?: IApiFile[];
  verifiedFunds?: Money;
}

export interface EnquiryBuyer {
  firstName?: string;
  lastName?: string;
  email?: string;
}

export interface EnquiryApi {
  id: string;
  advisorRating?: number;
  advisorNotes?: string;
  status?: EnquiryStatus;
  buyer?: EnquiryBuyer;
  buyerQualification?: EnquiryBuyerQualification;
  cssVariant?: EnquiryCssVariant;
  privateSellerNotes?: string;
  createdAt?: string;
}

export enum SellApplicationStatus {
  missing_info = 'missing_info',
  under_review = 'under_review',
  ready_to_publish = 'ready_to_publish',
  published = 'published',
  rejected = 'rejected',
}

export enum SubmitForEvaluationErrorCodes {
  askingPrice = 'missing_asking_price',
  businessPerformance = 'missing_business_performance',
  missingPnL = 'missing_document_tax_monthly_pnl',
  missingCohorts = 'missing_document_sku_analysis',
}

export interface SubmitForEvaluationError {
  code: SubmitForEvaluationErrorCodes;
}

export interface SubmitForEvaluationErrorResponse {
  errors: SubmitForEvaluationError[];
}

export interface SaleSellerApplicationApi {
  id: string;
  askingPrice: Money;
  inventoryPrice?: Money;
  ttmRevenue?: Money;
  ttmProfit?: Money;
  documents?: IApiDocument[];
  requiredDocuments?: LoanDocumentType[];
  additionalDetails?: string;
  readyForAnalysis?: boolean;
  notes?: string;
}

export interface ApplicationBusinessApi {
  id: string;
  name?: string;
  typeLiteral: {
    id: string;
    name: string;
    nameOther: string;
  };
  url: string;
}

export interface ListingStatsApi {
  stats: {
    views?: number;
    favorites?: number;
    enquiries?: number;
  };
}
export interface ApplicationListingApi {
  id: string;
  sellerType?: ListingSellerType;
  title?: string;
  description?: string;
  status?: string;
}

export interface ForSaleApplicationApi {
  id: string;
  status: SellApplicationStatus;
  business: ApplicationBusinessApi;
  application: SaleSellerApplicationApi;
  listing?: ApplicationListingApi;
  loanTermSheet?: ITermSheetApi;
  createdAt: string;
  reviewedAt?: string;
  listedAt?: string;
}
