import { useTranslation } from 'react-i18next';

import { TimelineItemNowProps } from 'components/DealTimeline/DealTimeline.interface';
import { stepAnimatedProps } from 'components/DealTimeline/StepAnimation';
import { DealTimelineStep } from 'components/DealTimelineStep';

import { ILoanApi, LoanStatus } from 'lib/types';

export const shouldShowCompleteClosingInformation = (loan: ILoanApi) => {
  return [
    LoanStatus.termsheet_signed,
    LoanStatus.due_diligence_applied,
    LoanStatus.due_diligence_missing_info,
    LoanStatus.due_diligence_analysis,
  ].includes(loan?.status);
};

export const CompleteClosingInformation = ({
  children,
  loan,
}: TimelineItemNowProps) => {
  const { t } = useTranslation();

  if (!shouldShowCompleteClosingInformation(loan)) {
    return null;
  }

  const errorVariant =
    loan?.status === LoanStatus.due_diligence_missing_info
      ? 'warning'
      : undefined;

  return (
    <DealTimelineStep
      title={t('DealTimelineStep:complete_closing_title')}
      subtitle={t('DealTimelineStep:complete_closing_subtitle')}
      data-testid="deal-complete-closing"
      errorVariant={errorVariant}
      {...stepAnimatedProps}
    >
      {children}
    </DealTimelineStep>
  );
};
