import { useTranslation } from 'react-i18next';

import { DealTimelineStep } from 'components/DealTimelineStep';

import { PastActivityProps } from './PastActivity.interface';

export const SellerContactedActivity = ({
  children,
  date,
  loan,
  ...rest
}: PastActivityProps) => {
  const { t } = useTranslation();

  return (
    <DealTimelineStep
      title={t('DealTimelineStep:full_request_requested')}
      date={date}
      data-testid="activity-seller-contacted"
      {...rest}
    />
  );
};
