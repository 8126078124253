import { useTranslation } from 'react-i18next';

import {
  IForSaleFiltersForm,
  useForSaleSelectedFilters,
} from 'components/ForSaleFilters';
import { useForSaleInitialValues } from 'components/ForSaleFilters/useForSaleInitialValues';

export type IUseForSaleListMatching = {
  key: string;
  text: string;
  selected: boolean;
  initialValue: Record<string, any>;
};

export const useForSaleListMatching = (filters: IForSaleFiltersForm) => {
  const { t } = useTranslation();

  const selectedFilters = useForSaleSelectedFilters({ filters });

  const {
    brokers,
    otherBrokers,
    isProprietary,
    revenue,
    profitRange,
    profitMultiplierRange,
    category,
    askPriceRange,
  } = useForSaleInitialValues();

  const filterItems: IUseForSaleListMatching[] = [
    {
      key: 'brokers',
      text: t('ForSaleFilters:deal_source_label'),
      selected: selectedFilters.activeBrokers,
      initialValue: {
        brokers,
        otherBrokers,
        isProprietary,
      },
    },
    {
      key: 'revenue',
      text: t('ForSaleFilters:ttm_revenue_label'),
      selected: selectedFilters.activeRevenue,
      initialValue: { revenue },
    },
    {
      key: 'profit',
      text: t('ForSaleFilters:ttm_profit_label'),
      selected: selectedFilters.activeProfit,
      initialValue: { profitRange },
    },
    {
      key: 'profitMultiplierRange',
      text: t('ForSaleFilters:multiplier_label'),
      selected: selectedFilters.activeProfitMultiplier,
      initialValue: { profitMultiplierRange },
    },
    {
      key: 'category',
      text: t('ForSaleFilters:type_categories_label'),
      selected: !!selectedFilters.activeCategoriesSelected,
      initialValue: { category },
    },
    {
      key: 'askPriceRange',
      text: t('ForSaleFilters:ask_price_label'),
      selected: selectedFilters.activeAskPrice,
      initialValue: { askPriceRange },
    },
  ];

  return filterItems;
};
