import { ILetterOfIntentData } from './LetterOfIntent.interface';

export const parseToApi = ({ letterOfIntentFiles }: ILetterOfIntentData) => {
  return {
    document: {
      files: letterOfIntentFiles?.map((item) => ({
        name: item?.name,
        url: item?.url,
      })),
    },
  };
};
