import useSWRInmutable from 'swr/immutable';

import { apiV2 } from 'lib/api';
import { defaultCurrency } from 'lib/constants';

import { getBrokersConfig } from './getBrokersConfig';
import { getMoneyFilterConfig } from './getMoneyFilterConfig';
import { getProfitMultiplierConfig } from './getProfitMultiplierConfig';
import {
  IApiFiltersConfig,
  UseForSaleFiltersConfigReturn,
} from './useForSaleFiltersConfig.interface';

const useForSaleFiltersConfig = (): UseForSaleFiltersConfigReturn => {
  const { data: filtersConfig } = useSWRInmutable<IApiFiltersConfig>(
    apiV2.getForSaleFiltersConfig.bind(apiV2)
  );

  if (filtersConfig === undefined) {
    return {
      isLoading: true,
      askPrice: undefined,
      profitMultiplier: undefined,
      profit: undefined,
      revenue: undefined,
      brokers: undefined,
      currency: undefined,
    };
  }

  const askPrice = getMoneyFilterConfig(filtersConfig.askPrice);
  const profit = getMoneyFilterConfig(filtersConfig.profit);
  const revenue = getMoneyFilterConfig(filtersConfig.revenue);

  const profitMultiplier = getProfitMultiplierConfig(
    filtersConfig.profitMultiplier
  );
  const brokers = getBrokersConfig(filtersConfig.brokers);

  return {
    isLoading: false,
    askPrice,
    profitMultiplier,
    profit,
    revenue,
    brokers,
    currency: defaultCurrency,
  };
};

export { useForSaleFiltersConfig };
