import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Input } from '@boopos/design-system';

import {
  AddressAutocomplete,
  useOnPlaceSelected,
} from 'components/AddressAutocomplete';
import { CleaveHookFormInput } from 'components/CleaveHookFormInput';
import { ICreditReportForm } from 'components/CreditReport/CreditReport.interface';
import { SelectHookForm } from 'components/SelectHookForm';

import { statesForReactSelect } from 'lib/constants';
import { getInputErrorProps } from 'lib/forms';

import { AuthorizeCheckbox } from './AuthorizeCheckbox';

export const UsResidentFields = () => {
  const { t } = useTranslation();
  const formMethods = useFormContext<ICreditReportForm>();
  const onPlaceSelected = useOnPlaceSelected();
  const { register, watch, formState, control } = formMethods;
  const state = watch('address.state');

  const onlyNumbers = useCallback((e: any) => {
    e.target.value = e.target.value.replace(/\D+/g, '');
  }, []);

  return (
    <>
      <AddressAutocomplete
        label={t('CreditReportForm:address_line_label')}
        data-testid="address-line"
        control={control}
        name="address.street"
        rules={{
          required: t('CreditReportForm:address_required_error'),
        }}
        onPlaceSelected={onPlaceSelected}
        {...getInputErrorProps(formState.errors?.address?.street)}
      />
      <input type="hidden" {...register('address.country')} value="US" />

      <Box
        css={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
          gridGap: '$4',
        }}
      >
        <Box css={{ gridColumn: 'span 2 / span 2' }}>
          <SelectHookForm
            name="address.state"
            control={control}
            rules={{
              required: t('CreditReportForm:state_required_error'),
            }}
            label={t('CreditReportForm:state_label')}
            options={statesForReactSelect}
            value={statesForReactSelect.find(({ value }) => value === state)}
            inputId="address-state-id"
            {...getInputErrorProps(formState.errors?.address?.state)}
          />
        </Box>
        <Input
          type="text"
          label={t('CreditReportForm:zipcode_label')}
          inputMode="tel"
          data-testid="address-zipcode"
          {...register('address.zipCode', {
            required: true,
          })}
          {...getInputErrorProps(formState.errors?.address?.zipCode)}
        />
      </Box>

      <Input
        type="text"
        label={t('CreditReportForm:city_label')}
        data-testid="address-city"
        autoComplete="off"
        {...register('address.city', {
          required: t('CreditReportForm:city_required_error'),
        })}
        {...getInputErrorProps(formState.errors?.address?.city)}
      />

      <CleaveHookFormInput
        control={control}
        name="ssn"
        data-testid="ssn"
        inputMode="tel"
        onInput={onlyNumbers}
        label={t('CreditReportForm:ssn_label')}
        placeholder={t('CreditReportForm:ssn_placeholder')}
        css={{ fontFamily: '$mono' }}
        targetProp="value"
        rules={{
          required: t('CreditReportForm:ssn_required_error'),
          minLength: {
            value: 9,
            message: t('CreditReportForm:ssn_required_error'),
          },
        }}
        options={{
          blocks: [3, 2, 4],
          delimiter: '-',
          numericOnly: false,
        }}
        {...getInputErrorProps(formState?.errors?.ssn, {
          subtext: t('CreditReportForm:not_storing_ssn'),
        })}
      />

      <AuthorizeCheckbox />
    </>
  );
};
