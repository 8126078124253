import { styled } from '@boopos/design-system';

export const Styles = {
  Wrapper: styled('div', {
    backgroundColor: '$white',
    borderRadius: '1.6rem',
  }),
  Group: styled('div', {
    mb: '4rem',
    variants: {
      last: {
        true: {
          mb: 0,
        },
      },
    },
  }),
  Title: styled('div', {
    mb: '1rem',
  }),
  Value: styled('div', {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&, & a': {
      textDecoration: 'none',
      color: '$slate600',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
};
