import { AnimatePresence } from 'framer-motion';

import { Box } from '@boopos/design-system';

import { BasicInformationDetails } from 'components/BasicInformationDetails';
import { BuyerInsightsTimelinePreview } from 'components/BuyerInsights/BuyerInsightsTimelinePreview';
import { AcceptTermSheet } from 'components/DealTimeline/AcceptTermSheet';
import { ClosingChecklistDetails } from 'components/DealTimeline/ClosingChecklistDetails';
import {
  CompleteClosingInformation,
  shouldShowCompleteClosingInformation,
} from 'components/DealTimeline/CompleteClosingInformation';
import {
  DealAcceptLoanTerms,
  shouldShowDealAcceptLoanTerms,
} from 'components/DealTimeline/DealAcceptLoanTerms';
import {
  DealBasicInformation,
  shouldShowDealBasicInformation,
} from 'components/DealTimeline/DealBasicInformation';
import {
  DealEvaluating,
  shouldShowDealEvaluating,
} from 'components/DealTimeline/DealEvaluating';
import {
  DealMissingInfo,
  shouldShowDealMissingInfo,
} from 'components/DealTimeline/DealMissingInfo';
import {
  DealRejected,
  shouldShowDealRejected,
} from 'components/DealTimeline/DealRejected';
import {
  FundingApproved,
  shouldShowFundingApproved,
} from 'components/DealTimeline/FundingApproved';
import { SubmitForEvaluation } from 'components/DealTimeline/SubmitForEvaluation';
import { TermSheetDetails } from 'components/DealTimeline/TermSheetDetails';
import { TimelineWhatsNext } from 'components/TimelineWhatsNext';

import { ILoanApi } from 'lib/types';

import { routePaths } from 'router/routePaths';

interface DealTimelineNowProps {
  loan?: ILoanApi;
  onSuccess: (cb?: () => void) => Promise<void>;
  isLast?: boolean;
}

export const DealTimelineNow = ({
  onSuccess,
  loan,
  isLast,
}: DealTimelineNowProps) => {
  if (!loan) return null;

  const itemProps = { isLast, loan };

  return (
    <>
      <AnimatePresence initial={false} mode="wait">
        {shouldShowFundingApproved(loan) && (
          <FundingApproved key="deal-funding-approved" {...itemProps} />
        )}
        {shouldShowCompleteClosingInformation(loan) && (
          <CompleteClosingInformation
            key="deal-closing-checklist"
            {...itemProps}
          >
            <ClosingChecklistDetails loan={loan} />
          </CompleteClosingInformation>
        )}
        {shouldShowDealAcceptLoanTerms(loan) && (
          <DealAcceptLoanTerms key="deal-accept-loan-terms" {...itemProps}>
            <BuyerInsightsTimelinePreview
              businessInsights={loan?.businessInsights}
              businessId={loan?.businessId}
              insightsPath={routePaths.dealFlowInsights}
            />
            <TermSheetDetails
              termSheet={loan?.termSheet}
              id={loan?.id}
              title={loan?.title}
            />
            <Box css={{ mt: '1.6rem' }} />
            <AcceptTermSheet
              loanId={loan?.id}
              termSheet={loan?.termSheet}
              ready={!!loan}
              onSuccess={onSuccess}
            />
            <TimelineWhatsNext loan={loan} />
          </DealAcceptLoanTerms>
        )}
        {shouldShowDealEvaluating(loan) && (
          <DealEvaluating key="deal-evaluating" {...itemProps}>
            <TimelineWhatsNext loan={loan} />
          </DealEvaluating>
        )}
        {shouldShowDealMissingInfo(loan) && (
          <DealMissingInfo key="deal-missing-info" {...itemProps}>
            <BasicInformationDetails loan={loan} />
            <SubmitForEvaluation onSuccess={onSuccess} loan={loan} />
            <TimelineWhatsNext loan={loan} />
          </DealMissingInfo>
        )}
        {shouldShowDealBasicInformation(loan) && (
          <DealBasicInformation key="deal-basic-info" {...itemProps}>
            <BasicInformationDetails loan={loan} />
            <SubmitForEvaluation onSuccess={onSuccess} loan={loan} />
            <TimelineWhatsNext loan={loan} />
          </DealBasicInformation>
        )}
        {shouldShowDealRejected(loan) && (
          <DealRejected key="deal-rejected" {...itemProps}>
            <TimelineWhatsNext loan={loan} />
          </DealRejected>
        )}
      </AnimatePresence>
    </>
  );
};
