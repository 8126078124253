import { styled } from '@boopos/design-system';

export const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  px: '$4',
  pb: '$6',
});

export const Header = styled('div', {
  position: 'sticky',
  zIndex: '$appOnTop',
  top: 0,
  px: '$4',
  py: '$4',
  mt: '$4',
  background: '$white',
  display: 'flex',
  alignItems: 'center',
  variants: {
    sticky: {
      true: {
        boxShadow: 'rgba(0, 0, 0, .1) 0px 0px 10px',
      },
    },
  },
});

export const Footer = styled('div', {
  position: 'sticky',
  bottom: 0,
  p: '$4',
  background: '$white',
  borderTop: '1px solid $slate100',
  zIndex: '$1',
});

export const StyledForm = styled('form', {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

export const Styles = {
  SwitchFilterLabel: styled('div', {
    fontWeight: 700,
  }),
  FilterLabel: styled('div', {
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    fontWeight: 700,
    py: '$4',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  }),
  InfoTooltip: styled('div', {
    display: 'inline-flex',
    ml: '1rem',
    color: '$infoStatic',
  }),
  SortByWrapper: styled('div', {
    flex: '0 0 24.5rem',
    ml: 'auto',
  }),
};

export const ButtonTotalNumber = styled('span', {
  display: 'inline-block',
  width: '4rem',
  textAlign: 'center',
});
