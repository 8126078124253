import { useCallback, useMemo, useState } from 'react';
import useSWR from 'swr';

import { IQualificationStatus } from 'components/QualificationStatus';

import api from 'lib/api';
import { wait } from 'lib/forms';
import { useBankProvider } from 'lib/hooks/useBankProvider';
import { IUploadedFile } from 'lib/types';

import { AvailableFundsFormValues } from './ProofOfFunds.interface';
import { parseToApi } from './ProofOfFunds.utils';
import {
  getFilesForDelete,
  getFilesForUpload,
} from './useProofOfFundsUpdate.utils';

export const useProofOfFundsUpdate = () => {
  const { data, mutate } = useSWR<IQualificationStatus>(api.getQualification);

  const [firstDocument] = data?.funds?.documents ?? [];
  const currentApiFiles = useMemo(
    () => firstDocument?.files ?? [],
    [firstDocument?.files]
  );

  const [loadingPlaid, setLoadingPlaid] = useState(false);

  const onPreConnect = useCallback(() => {
    setLoadingPlaid(true);
  }, []);

  const onConnect = useCallback(async () => {
    await wait(500);
    await mutate();
    setLoadingPlaid(false);
  }, [mutate]);

  const onExit = useCallback(() => {
    setLoadingPlaid(false);
  }, []);

  const { connect } = useBankProvider({ onPreConnect, onConnect, onExit });

  const connectWithPlaid = useCallback(async () => {
    setLoadingPlaid(true);
    await connect();
    await wait(2000);
    setLoadingPlaid(false);
  }, [connect]);

  const disconnectAccount = useCallback(
    async (externalId: string) => {
      await api.disconnectMerchant('bank', externalId);
      await wait(500);
      await mutate();
    },
    [mutate]
  );

  const updateDisposableAmount = useCallback(
    async (data: AvailableFundsFormValues) => {
      return await api.updateQualificationStatusFunds(parseToApi(data));
    },
    []
  );

  const uploadDocuments = useCallback(
    async (uploadedFiles: IUploadedFile[]) => {
      await api.updateQualificationStatusFunds(
        getFilesForUpload({
          currenFiles: currentApiFiles,
          uploadedFiles,
          firstDocument,
        })
      );
      await mutate();
    },
    [firstDocument, currentApiFiles, mutate]
  );

  const deleteFile = useCallback(
    async (fileId: string) => {
      await api.updateQualificationStatusFunds(
        getFilesForDelete({
          currenFiles: currentApiFiles,
          fileId,
          firstDocument,
        })
      );
      await mutate();
    },
    [firstDocument, currentApiFiles, mutate]
  );

  return {
    updateDisposableAmount,
    connectWithPlaid,
    loadingPlaid,
    disconnectAccount,
    uploadDocuments,
    deleteFile,
  };
};
