import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { OptInForm } from 'screens/InvestmentCriteriaScreen/InvestmentCriteria.interface';

import { useInvestmentCriteriaNavigation } from './useInvestmentCriteriaNavigation';
import { useSaveInvestmentCriteria } from './useSaveInvestmentCriteria';

export const useInvestmentCriteriaSuccessScreen = () => {
  const { navigateToNextPath } = useInvestmentCriteriaNavigation();

  const { loading, success, investmentCriteria, save } =
    useSaveInvestmentCriteria({
      onSave: navigateToNextPath,
    });

  const formMethods = useForm<OptInForm>({
    defaultValues: {
      optIn: true,
    },
  });

  const handleSubmit = useCallback(
    (data: OptInForm) => {
      save({
        ...investmentCriteria,
        optIn: data.optIn,
      });
    },
    [investmentCriteria, save]
  );

  const onSubmit = formMethods.handleSubmit(handleSubmit);

  return {
    onSubmit,
    loading,
    success,
    handleSubmit,
    formMethods,
  };
};
