import { styled } from '@boopos/design-system';

export const CategoriesWrapper = styled('div', {
  '.accordion-header[data-state="open"]': {
    top: '0', // set sticky categories position
  },
});

export const SwitchBox = styled('div', {
  display: 'flex',
  alignItems: ' flex-start',
  background: '$backgroundElevated',
  borderRadius: '.8rem',
  m: '$4 0',
  p: '$3',
});

export const EmailTxt = styled('p', {
  color: '$textBodyTertiary',
  mt: '$1',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
