import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { styled } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { StaticTooltip } from 'ui/Tooltip';

import { CurrencyHookInput } from 'components/CurrencyInput';

import { defaultCurrency } from 'lib/constants';
import { getInputErrorProps } from 'lib/forms';

import {
  BusinessPerformanceFormProps,
  BusinessPerformanceFormValues,
} from './BusinessPerformanceForm.interface';

const LabelWithTooltip = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
});

const InfoTooltip = styled('div', {
  color: '$infoStatic',
});

export const BusinessPerformanceForm = ({
  defaultValues,
  actionsComponent,
  onSubmit,
  onError,
  as,
}: BusinessPerformanceFormProps) => {
  const Form = as ?? 'form';
  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState(false);

  const { control, formState, handleSubmit } =
    useForm<BusinessPerformanceFormValues>({
      defaultValues,
    });

  const onSubmitInternal = useCallback(
    async (data: BusinessPerformanceFormValues) => {
      try {
        await onSubmit(data);
        setIsSuccess(true);
      } catch (err) {
        onError?.(err);
        console.error(err);
        setIsSuccess(false);
      }
    },
    [onSubmit, onError]
  );

  const isLoading = formState.isSubmitting;

  return (
    <Form
      onSubmit={handleSubmit(onSubmitInternal)}
      data-testid="business-performance-form"
    >
      <CurrencyHookInput
        label={t('BusinessPerformanceForm:revenue_label')}
        data-testid="revenue-input"
        name="revenue"
        currency={defaultCurrency}
        control={control}
        rules={{
          required: t('BusinessPerformanceForm:revenue_required'),
        }}
        autoFocus
        {...getInputErrorProps(formState.errors.revenue)}
      />
      <CurrencyHookInput
        label={
          (
            <LabelWithTooltip>
              {t('BusinessPerformanceForm:profit_label')}{' '}
              <InfoTooltip
                data-tooltip-id="business-performance-form-help"
                data-tooltip-content={t(
                  'BusinessPerformanceForm:profit_tooltip'
                )}
              >
                <FontAwesomeIcon icon={regular('circle-info')} />
              </InfoTooltip>
            </LabelWithTooltip>
          ) as any
        }
        data-testid="profit-input"
        name="profit"
        currency={defaultCurrency}
        control={control}
        groupSpacing="xl"
        allowNegative
        rules={{
          required: t('BusinessPerformanceForm:profit_required'),
        }}
        {...getInputErrorProps(formState.errors.profit)}
      />
      {actionsComponent?.({ isSuccess, isLoading })}
      <StaticTooltip id="business-performance-form-help" place="right" />
    </Form>
  );
};
