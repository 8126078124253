import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import {
  OnboardingHeader,
  OnboardingLayout,
} from 'components/OnboardingLayout';

import { routePaths } from 'router/routePaths';

import { SaveButtonProps, UserProfileForm } from './UserProfileForm';
import { useOnboardingProfileScreen } from './useOnboardingProfileScreen';

const goBackProps = {
  as: Link,
  to: routePaths.onboarding,
};

interface OnboardingProfileScreenProps {
  showResidencyField?: boolean;
  copies?: {
    title?: ReactNode;
  };
  saveButtonProps: SaveButtonProps;
  nextScreen?: string;
}

export const OnboardingProfileScreen = (
  props: OnboardingProfileScreenProps
) => {
  const {
    copies,
    showResidencyField = true,
    saveButtonProps,
    nextScreen,
    ...rest
  } = props;

  const { onSubmit, onSuccess, defaultValues, allowGoBack } =
    useOnboardingProfileScreen({
      showResidencyField,
      nextScreen,
    });

  return (
    <OnboardingLayout.Wrapper {...rest}>
      <OnboardingHeader.Wrapper>
        <OnboardingHeader.ActionsWrapper>
          <OnboardingHeader.Action {...(allowGoBack ? goBackProps : {})}>
            {allowGoBack && <OnboardingHeader.BackArrow />}
          </OnboardingHeader.Action>
        </OnboardingHeader.ActionsWrapper>
        <OnboardingHeader.Steps />
        <OnboardingHeader.Title>{copies?.title}</OnboardingHeader.Title>
      </OnboardingHeader.Wrapper>
      <OnboardingLayout.Content>
        <OnboardingLayout.ContentWrapper>
          <UserProfileForm
            onSubmit={onSubmit}
            onSuccess={onSuccess}
            defaultValues={defaultValues}
            showResidencyField={showResidencyField}
            saveButtonProps={saveButtonProps}
          />
        </OnboardingLayout.ContentWrapper>
      </OnboardingLayout.Content>
    </OnboardingLayout.Wrapper>
  );
};
