import { motion } from 'framer-motion';
import { Outlet, useLocation } from 'react-router-dom';

import { SlateBackgroundBody } from 'components/GlobalStyles';

const pageVariants = {
  initial: {
    translateX: 200,
    opacity: 0,
  },
  in: {
    translateX: 0,
    opacity: 1,
  },
  out: {
    translateX: -200,
    opacity: 0,
  },
};

const pageTransition = {
  type: 'tween',
  ease: 'easeInOut',
  duration: 0.2,
};

export const QualifyRouterProvider = () => {
  const { pathname } = useLocation();
  return (
    <>
      <SlateBackgroundBody />
      <motion.div
        key={pathname}
        initial="initial"
        animate="in"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Outlet />
      </motion.div>
    </>
  );
};
