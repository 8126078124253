import { styled } from '@boopos/design-system';

export const ConfidentialityAgreementStyles = {
  Wrapper: styled('div', {
    width: '100%',
    height: '100vh',
    px: '$4',
    pb: '$6',
    display: 'flex',
    alignItems: 'center',

    '@bp2': {
      pt: 0,
      pb: 0,
    },
  }),
  Content: styled('div', {
    width: '39rem',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  }),
  Title: styled('h1', {
    fontHeadingSerif: '$h2',
    fontSize: '2.6rem',
    fontWeight: '$bolder',
    lineHeight: '3.2rem',
    marginBottom: '$5',
    color: '$slate900',
  }),
  Description: styled('p', {
    color: '$textBodyPrimary',
    lineHeight: '2.4rem',
    marginBottom: '$8',
  }),
  AgreementContent: styled('div', {
    color: '$textBodySecondary',
    border: '2px solid $slate400',
    background: '$surfaceBase',
    borderRadius: '.8rem',
    py: '$2',
    mb: '4rem',
  }),
  AgreementScrollContent: styled('div', {
    px: '$4',
    overflowY: 'auto',
    scroll: 'smooth',
    height: '27rem',

    '& *': {
      fontSize: '1.4rem',
    },

    '& p:not(:last-child)': {
      mb: '$2',

      '&:first-child': {
        fontWeight: '$bolder',
      },
    },

    '& a': {
      textDecoration: 'none',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
  Actions: styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '$2',
  }),
};
