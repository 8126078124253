import { useTranslation } from 'react-i18next';

import { PageHeader } from 'ui/PageHeader';

import { NavigationCloseScreen } from 'components/NavigationCloseScreen';

import { useUser } from 'providers/UserProvider';

import { LogoutButton } from './LogoutButton';

export const AccountScreenHeader = () => {
  const { user } = useUser();
  const { t } = useTranslation();

  return (
    <PageHeader
      title={user?.fullName ?? t('AccountScreen:title')}
      action={<LogoutButton />}
      childrenTop={<NavigationCloseScreen />}
      data-testid="title"
    />
  );
};
