import { motion } from 'framer-motion';

import { styled } from '@boopos/design-system';

import { Header } from 'components/ScrollStickyHeader/ScrollStickyHeader.styles';

export const Content = styled(motion.div, {
  position: 'absolute',
  bottom: 0,
  top: 0,
  maxWidth: 1200,
  width: '100%',
  background: '$white',
  overflow: 'hidden',
  display: 'flex',
  zIndex: 11,

  variants: {
    position: {
      bottomSheet: {
        inset: 0,
        '@bp2': {
          borderRadius: '1.6rem',
          left: '50%',
          top: '5%',
          bottom: '5%',
        },
      },
      bottomSheetCentered: {
        inset: 0,
        '@bp2': {
          borderRadius: '1.6rem',
          top: '50%',
          left: '50%',
          bottom: 'auto',
          right: 'auto',
        },
      },
      rightDrawer: {
        inset: 0,
        borderRadius: 0,
        '@bp2': {
          right: 0,
          top: 0,
          bottom: 0,
          left: 'auto',
          [`${Header}`]: {
            px: '$4',
          },
        },
      },
      centered: {
        borderRadius: '1.6rem',
        top: '50%',
        left: '50%',
        bottom: 'auto',
        right: 'auto',
        width: 'calc(100% - 3.2rem)',
      },
      rightDrawerBottom: {
        inset: 0,
        borderRadius: 0,
        top: 'auto',
        '@bp2': {
          right: 0,
          top: 'auto',
          bottom: 0,
          left: 'auto',
          [`${Header}`]: {
            px: '$4',
          },
        },
      },
    },
  },
});

export const ContentWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  width: '100%',
});

export const Backdrop = styled(motion.div, {
  background: '$slate900',
  opacity: 0.7,
  inset: 0,
  position: 'absolute',
});

export const Wrapper = styled('div', {
  position: 'fixed',
  inset: 0,
  zIndex: 10,
});

export const ContentContainer = styled('div', {
  p: '$4',
  pt: 0,
});

const bottomSheetBase = {
  style: { x: 0 },
  initial: { y: '200%', opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: '200%', opacity: 0 },
  transition: { duration: 0.4, ease: 'easeInOut' },
};

const centeredBase = {
  style: { x: '-50%' },
  initial: { y: '100%', opacity: 0 },
  animate: { y: '-50%', opacity: 1 },
  exit: { y: '100%', opacity: 0 },
  transition: { duration: 0.4, ease: 'easeInOut' },
};

export const animationVariantProps = {
  bottomSheet: {
    mobile: { ...bottomSheetBase },
    desktop: {
      ...bottomSheetBase,
      style: { x: '-50%' },
    },
  },
  bottomSheetCentered: {
    mobile: { ...bottomSheetBase },
    desktop: { ...centeredBase },
  },
  rightDrawer: {
    mobile: {
      initial: { y: '200%', opacity: 0 },
      animate: { y: 0, opacity: 1 },
      exit: { y: '200%', opacity: 0 },
      transition: { duration: 0.4, ease: 'easeInOut' },
    },
    desktop: {
      initial: { x: '100%', opacity: 0 },
      animate: { x: 0, opacity: 1 },
      exit: { x: '100%', opacity: 0 },
      transition: { duration: 0.4, ease: 'easeInOut' },
    },
  },
  rightDrawerBottom: {
    mobile: { ...bottomSheetBase },
    desktop: { ...bottomSheetBase },
  },
  centered: {
    mobile: { ...centeredBase },
    desktop: { ...centeredBase },
  },
  default: {
    mobile: {},
    desktop: {},
  },
};

export const backdropAnimationProps = {
  initial: { opacity: 0 },
  animate: { opacity: 0.7 },
  exit: { opacity: 0 },
  transition: { duration: 0.5, ease: 'easeInOut' },
};
