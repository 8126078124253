import * as Sentry from '@sentry/react';

import config from 'lib/config';

import { getSentryIntegrations } from './getSentryIntegrations';

export const initSentry = () => {
  if (!config.SENTRY_DSN) {
    return;
  }

  Sentry.init({
    dsn: config.SENTRY_DSN,
    integrations: getSentryIntegrations({
      organization: config.SENTRY_ORGANIZATION,
      project: config.SENTRY_PROJECT,
    }),

    tracesSampleRate: 1.0,
    environment: config.ENVIRONMENT,
    release: config.APP_VERSION,
  });
};
