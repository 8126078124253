import Skeleton from 'react-loading-skeleton';

import { Box } from '@boopos/design-system';

export const ActionSideModalLoading = () => {
  return (
    <div data-testid="modal-loading">
      <Box css={{ mb: '$4' }}>
        <Skeleton count={1} height={46} />
      </Box>
      <Box css={{ mb: '$4' }}>
        <Skeleton count={1} height={23} width="90%" />
      </Box>
      <Box css={{ mb: '$4' }}>
        <Skeleton count={1} height={23} width="80%" />
      </Box>
    </div>
  );
};
