import { ReactElement, ReactNode } from 'react';

import { ITermSheetApi, TermSheetType } from 'lib/types';

enum TermSheetHandlerInternal {
  NoTermSheet = 'NoTermSheet',
  BaseTermSheet = 'BaseTermSheet',
  ByTpe = 'ByType',
}

type BaseProps = {
  type?: TermSheetType;
  __internalTermSheetHandler?: TermSheetHandlerInternal;
};

type ChildProps = { children?: ReactNode };

type TermSheetHandlerProps = {
  children: ReactElement<BaseProps>[] | ReactElement<BaseProps>;
  termSheet?: ITermSheetApi;
};

type TermSheetHandlerTypeProps = {
  type: TermSheetType;
} & ChildProps;

export type {
  BaseProps,
  ChildProps,
  TermSheetHandlerProps,
  TermSheetHandlerTypeProps,
};

export { TermSheetHandlerInternal };
