import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { IApiFile } from 'lib/types';

import { FilePreview } from './FilePreview';
import {
  FilesPreviewTitle,
  FilesPreviewWrapper,
} from './WatchListDocumentsUpload.styles';

export const FilesPreview = ({
  files,
  onDeleteFile,
}: {
  files: IApiFile[];
  onDeleteFile: (params: any) => any;
}) => {
  const { t } = useTranslation();

  if (!files.length) return null;

  return (
    <FilesPreviewWrapper>
      <FilesPreviewTitle>
        {t('WatchListDocumentsUpload:uploaded')}
      </FilesPreviewTitle>
      <AnimatePresence>
        {files.map(({ id, downloadUrl, name }) => (
          <FilePreview
            key={id}
            id={id}
            downloadUrl={downloadUrl}
            name={name}
            onDelete={onDeleteFile}
          />
        ))}
      </AnimatePresence>
    </FilesPreviewWrapper>
  );
};
