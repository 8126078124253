import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Button } from '@boopos/design-system';

import {
  OnboardingHeader,
  OnboardingLayout,
} from 'components/OnboardingLayout';
import { InsightsPreviewAction } from 'components/PortfolioApplication/InsightsPreview';

import { routePaths } from 'router/routePaths';

import { Styles } from './Styles';

export const PortfolioApplicationAdvantages = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const notNowPath = location?.state?.exitPath ?? routePaths.forSale;

  const steps = [
    {
      title: t('PortfolioApplicationAdvantages:step_1_title'),
      description: (
        <Trans i18nKey="PortfolioApplicationAdvantages:step_1_description">
          <InsightsPreviewAction />
        </Trans>
      ),
    },
    {
      title: t('PortfolioApplicationAdvantages:step_2_title'),
      description: t('PortfolioApplicationAdvantages:step_2_description'),
    },
    {
      title: t('PortfolioApplicationAdvantages:step_3_title'),
      description: t('PortfolioApplicationAdvantages:step_3_description'),
    },
    {
      title: t('PortfolioApplicationAdvantages:step_4_title'),
      description: t('PortfolioApplicationAdvantages:step_4_description'),
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t('PortfolioApplicationAdvantages:title')}</title>
      </Helmet>

      <OnboardingHeader.Wrapper data-testid="portfolio-application-advantages">
        <OnboardingHeader.ActionsWrapper>
          <OnboardingHeader.Action />
        </OnboardingHeader.ActionsWrapper>
        <OnboardingHeader.Title size="h2" css={{ maxWidth: 'none' }}>
          {t('PortfolioApplicationAdvantages:title')}
        </OnboardingHeader.Title>
      </OnboardingHeader.Wrapper>
      <OnboardingLayout.Content>
        <div>
          <Styles.AdvantagesWrapper>
            {steps.map((step, index) => (
              <Styles.Advantage key={index}>
                <Styles.Step>
                  {t('PortfolioApplicationAdvantages:step', {
                    stepN: index + 1,
                  })}
                </Styles.Step>
                <Styles.AdvantageTitle>{step.title}</Styles.AdvantageTitle>
                <Styles.AdvantageDescription>
                  {step.description}
                </Styles.AdvantageDescription>
              </Styles.Advantage>
            ))}
          </Styles.AdvantagesWrapper>
          <OnboardingLayout.ActionsWrapper>
            <Button
              as={Link}
              to={routePaths.onboardingSellerApplication.addBusiness}
              data-action-primary
            >
              {t('PortfolioApplicationAdvantages:start_now')}
            </Button>
            <Button variant="tertiary" as={Link} to={notNowPath}>
              {t('PortfolioApplicationAdvantages:start_later')}
            </Button>
          </OnboardingLayout.ActionsWrapper>
        </div>
      </OnboardingLayout.Content>
    </>
  );
};
