import { useAtom } from 'jotai';
import { PropsWithChildren, useEffect } from 'react';

import { MainMenuHidden, mainMenuHiddenAtom } from './Layout.atoms';

interface LayoutHideMenuProps {
  shouldShowMenuOnUnmount?: boolean;
  hideOn?: MainMenuHidden;
}

export const LayoutHideMenu = ({
  children,
  shouldShowMenuOnUnmount = true,
  hideOn = 'mobile',
}: PropsWithChildren<LayoutHideMenuProps>) => {
  const [, setMainMenuHiddenOn] = useAtom(mainMenuHiddenAtom);

  useEffect(() => {
    setMainMenuHiddenOn(hideOn);
    return () => {
      shouldShowMenuOnUnmount && setMainMenuHiddenOn(undefined);
    };
  }, [setMainMenuHiddenOn, shouldShowMenuOnUnmount, hideOn]);

  return <>{children}</>;
};
