import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { Box } from '@boopos/design-system';

import {
  ActionSideModal,
  ActionSideModalCloseHeader,
  ActionSideModalCommonStyles,
  ActionSideModalLoading,
  useNavigateFromActionSideModal,
} from 'components/ActionSideModal';
import { useClosingStepMatchStatus } from 'components/DealClosingCheckList';

import Api from 'lib/api';
import {
  ClosingCheckListStepStatus,
  ClosingStepsNames,
  ILoanApi,
} from 'lib/types';

import { routePaths } from 'router/routePaths';

import { ContinueAction } from './ContinueAction';
import { RevenueBankAccountPlaid } from './RevenueBankAccountPlaid';

export const RevenueBankAccountModal = () => {
  const { t } = useTranslation();
  const params = useParams<{ loanId: string }>();
  const loanId = params.loanId as string;

  const { data: loan, mutate } = useSWR<ILoanApi>(Api.getLoan(loanId));

  const { onClose } = useNavigateFromActionSideModal(
    {
      loanId,
    },
    { basePath: routePaths.dealFlowDetail }
  );

  const onSuccess = useCallback(async () => {
    await mutate();
  }, [mutate]);

  const isVerified = useClosingStepMatchStatus({
    loan,
    status: ClosingCheckListStepStatus.verified,
    name: ClosingStepsNames.revenueBankAccount,
  });

  const isMissingInfo = useClosingStepMatchStatus({
    loan,
    status: ClosingCheckListStepStatus.missingInfo,
    name: ClosingStepsNames.revenueBankAccount,
  });

  return (
    <ActionSideModal
      header={<ActionSideModalCloseHeader onClose={onClose} />}
      onClose={onClose}
    >
      <ActionSideModalCommonStyles.Title data-testid="revenue-bank-account-modal-title">
        {t('RevenueBankAccountModal:title')}
      </ActionSideModalCommonStyles.Title>
      <ActionSideModalCommonStyles.BlockDescription>
        <Trans i18nKey="RevenueBankAccountModal:description">
          <Box css={{ fontWeight: 'bold' }} />
        </Trans>
      </ActionSideModalCommonStyles.BlockDescription>
      {!loan ? (
        <ActionSideModalLoading />
      ) : (
        <>
          <RevenueBankAccountPlaid
            businessId={loan.businessId}
            accounts={loan.accounts}
            onSuccess={onSuccess}
            disabled={isVerified}
          />
          {!!loan.accounts?.length && !isMissingInfo && (
            <ContinueAction isVerified={isVerified} onContinue={onClose} />
          )}
        </>
      )}
    </ActionSideModal>
  );
};
