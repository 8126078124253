import { Box, Button, Link } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IActionButton, IInlineMessageProps } from './InlineMessage.interface';
import { StyledInlineMessage } from './InlineMessage.styles';

const ActionButton = ({
  variant,
  actionIcon,
  onClick,
  ...rest
}: IActionButton) => {
  const fill = variant === 'nude' ? '#000' : '#fff';
  const icon = actionIcon ?? (
    <FontAwesomeIcon icon={regular('arrow-right')} fill={fill} />
  );

  return (
    <Button
      variant={variant}
      rounded
      leftIcon={icon}
      onClick={onClick}
      data-testid="inline-message-action-button"
      {...rest}
    />
  );
};

export const InlineMessage = ({
  type = 'neutral',
  theme = 'default',
  action,
  actionIcon,
  actionLinkText,
  heading,
  message,
  onClick,
  actionProps = {},
  ...rest
}: IInlineMessageProps) => {
  const isPrimaryAction = action === 'primary';
  const isNudeAction = action === 'nude';

  const isDescriptiveAction = action === 'descriptive';

  const hasAction = isPrimaryAction || isNudeAction;

  return (
    <StyledInlineMessage.Wrapper
      type={type}
      theme={theme}
      action={action}
      hasLink={!!actionLinkText}
      {...rest}
    >
      <Box>
        <StyledInlineMessage.Title>{heading}</StyledInlineMessage.Title>
        {message && (
          <StyledInlineMessage.Message data-testid="inline-message-text-message">
            {message}
          </StyledInlineMessage.Message>
        )}
      </Box>
      {hasAction && (
        <StyledInlineMessage.Actions>
          {isPrimaryAction && (
            <ActionButton
              actionIcon={actionIcon}
              onClick={onClick}
              {...actionProps}
            />
          )}
          {isNudeAction && (
            <ActionButton
              variant="nude"
              actionIcon={actionIcon}
              onClick={onClick}
              {...actionProps}
            />
          )}
        </StyledInlineMessage.Actions>
      )}

      {isDescriptiveAction && (
        <StyledInlineMessage.DescriptiveActions>
          <Link data-testid="inline-message-action-link" onClick={onClick}>
            {actionLinkText}
          </Link>
        </StyledInlineMessage.DescriptiveActions>
      )}
    </StyledInlineMessage.Wrapper>
  );
};
