import { Helmet } from 'react-helmet-async';
import { Outlet, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { DealDetail } from 'components/DealDetail';
import { LayoutHideMenu } from 'components/Layout';
import { AnimatedScreenOpacityWrapper } from 'components/ScreenLayout';

import Api from 'lib/api';
import { ILoanApi } from 'lib/types';

import { routePaths } from 'router/routePaths';

import { BuyerInsightsTabMenu } from 'screens/BuyerBusinessInsights';

import { DetailPageHeader } from './DetailPageHeader';

export const DealFlowDetailParent = () => {
  const params = useParams();
  const { loanId } = params ?? {};

  const { data: loan } = useSWR<ILoanApi>(loanId && Api.getLoan(loanId));

  return (
    <>
      <Helmet>{!!loan?.title && <title>{loan.title}</title>}</Helmet>
      <AnimatedScreenOpacityWrapper>
        <LayoutHideMenu hideOn="all" />
        <DealDetail.Wrapper data-testid="deal-flow-detail-parent">
          <DealDetail.ContainerWrapper>
            <DetailPageHeader loan={loan} />
            <BuyerInsightsTabMenu
              loanApplicationPath={routePaths.dealFlowDetail}
              businessInsightsPath={routePaths.dealFlowInsights}
              businessInsights={loan?.businessInsights}
            />

            <Outlet />
          </DealDetail.ContainerWrapper>
        </DealDetail.Wrapper>
      </AnimatedScreenOpacityWrapper>
    </>
  );
};
