import { styled } from '@boopos/design-system';

import { Badge } from 'ui/Badge';

export const EmailBadgeStyles = {
  Badge: styled(Badge, {
    display: 'flex',
    width: 'fit-content',
    py: '$1',
    mb: '$4',
    pl: '$2',
    pr: '$4',
    alignItems: 'center',
    svg: { lineHeight: 0 },
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
  }),
  Text: styled('span', {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '32rem',
  }),
};
