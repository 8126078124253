import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { apiV2 } from 'lib/api';
import { legalAgreements } from 'lib/legal';

import { useLandingScreenMemo } from 'providers/FirstAppScreenDecider/LandingScreenMemo';
import { useUser } from 'providers/UserProvider';

import { agreementTranslations } from './ConfidentialityAgreement.translations';

export const useConfidentialityAgreement = () => {
  const { mutate: mutateUser } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const stateBackUrl = location?.state?.backUrl;
  const landingUrl = useLandingScreenMemo();
  const backUrl = stateBackUrl ?? landingUrl;

  const handleBack = useCallback(() => {
    navigate(backUrl);
  }, [navigate, backUrl]);

  const handleAgree = useCallback(async () => {
    await apiV2.setConfidentialityAgreement(legalAgreements.confidentiality());
    await mutateUser();
    navigate(backUrl);
  }, [backUrl, navigate, mutateUser]);

  return {
    handleBack,
    handleAgree,
    agreementTranslations,
  };
};
