import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { styled } from '@boopos/design-system';

import { SelectHookForm } from 'components/SelectHookForm';

import { statesForReactSelect } from 'lib/constants';

import { IUSStateSelect } from './USStateSelect.interface';

const SelectWrapper = styled('div', {
  flex: '1',
});

export const USStateSelect = ({
  name,
  control,
  rules,
  ...props
}: IUSStateSelect) => {
  const { field } = useController({
    name,
    control,
  });

  const { t } = useTranslation();
  const watchedState = field.value;

  return (
    <SelectWrapper>
      <SelectHookForm
        {...props}
        name={name}
        label={t('USStateSelect:label')}
        control={control}
        rules={
          rules || {
            required: t('USStateSelect:required_error'),
          }
        }
        options={statesForReactSelect}
        value={statesForReactSelect.find(({ value }) => value === watchedState)}
      />
    </SelectWrapper>
  );
};
