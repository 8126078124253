import React, { ReactNode, useMemo, useState } from 'react';

import { getChildProps } from './MotionTabs.utils';

interface UseMotionTabsProps {
  initial?: string;
  children: ReactNode[];
}

export const useMotionTabs = ({ initial, children }: UseMotionTabsProps) => {
  const tabsChildren = useMemo(
    () => React.Children.toArray(children),
    [children]
  );

  const [hovered, setHovered] = useState<number | null>(null);

  const [selected, setSelected] = useState<string>(() => {
    const firstId = getChildProps(tabsChildren[0]).id;

    const initialId = tabsChildren
      .filter((child) => getChildProps(child).id === initial)
      .map((child) => getChildProps(child).id)[0];

    return initialId ?? firstId;
  });

  return {
    tabsChildren,
    hovered,
    selected,
    setHovered,
    setSelected,
  };
};
