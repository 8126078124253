import { styled } from '@boopos/design-system';

export const ListingChartWrapper = styled('div', {
  mb: '$4',
  minHeight: 200,
  width: '100%',
  '@bp2': {
    flex: 1,
    order: 0,
    mb: 0,
    mr: '$4',
  },
});

export const ChartTooltip = styled('div', {
  padding: '$3',
  border: '1px solid $slate400',
  borderRadius: '.3rem',
  backgroundColor: '$white',
});
