import { motion } from 'framer-motion';

import { Link, styled } from '@boopos/design-system';

export const FilesPreviewTitle = styled('div', {
  fontWeight: 700,
});

export const FilesPreviewWrapper = styled('div', {});

export const FilePreviewWrapper = styled(motion.div, {
  py: '$1',
  display: 'flex',
  ai: 'center',
});

export const FilePreviewName = styled('div', {
  flex: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const FilePreviewLink = styled(Link, {
  color: '$slate900',
  whiteSpace: 'nowrap',
  textDecoration: 'underline',
});

export const ButtonsWrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1',
  gridGap: '$4',
});
