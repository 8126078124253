import { formatMoneyWithSpacedSymbol } from 'lib/format/money/formatMoney';
import { MoneyFormat } from 'lib/format/money/formatMoney.interface';
import { Currency } from 'lib/types';

export const moneyValueFormatter = (rawValue?: string | number): string => {
  const moneyValue = { amount: Number(rawValue), currency: Currency.USD };

  return formatMoneyWithSpacedSymbol(moneyValue, {
    divider: 1,
    format: MoneyFormat.long,
  });
};
