import { styled } from '@boopos/design-system';

export const TermSheetModalStyles = {
  Title: styled('h3', {
    fontSize: '3.2rem',
    lineHeight: '4rem',
  }),
  HelpText: styled('div', {
    color: '$slate600',
    my: '2.4rem',
  }),
  ButtonContainer: styled('div', {
    mb: '4rem',
  }),
};
