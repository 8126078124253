import { useTranslation } from 'react-i18next';

import { ButtonFeedback } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ILoanApi } from 'lib/types';

import { useUser } from 'providers/UserProvider';

import { useSubmitForEvaluation } from './SubmitForEvaluation.hooks';

export const SubmitForEvaluation = ({
  onSuccess,
  loan,
}: {
  onSuccess: () => Promise<void>;
  loan: ILoanApi;
}) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const locked = user && !user.qualified;

  const { evaluate, isResubmit, loading, success, disabled } =
    useSubmitForEvaluation({ onSuccess, loan });

  const cta = isResubmit
    ? t('DealTimelineStep:resubmit_for_evaluation')
    : t('DealTimelineStep:submit_for_evaluation');

  return (
    <>
      <ButtonFeedback
        css={{ mt: '$6', maxWidth: 256 }}
        onClick={evaluate}
        isLoading={loading}
        isSuccess={success}
        disabled={disabled || locked}
        fullWidth
        data-testid="submit-for-evaluation"
        leftIcon={
          locked ? <FontAwesomeIcon icon={regular('lock')} size="lg" /> : null
        }
      >
        {cta}
      </ButtonFeedback>
    </>
  );
};
