import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import useSWR from 'swr';

import api from 'lib/api';
import { getCurrentOrEmptyDocument, getDocumentsFiles } from 'lib/files';
import { ILoanApi } from 'lib/types';

import {
  IDocumentsData,
  IUseBasicDocumentsUpload,
} from './BasicDocumentsUploadModal.interface';

export const useBasicDocumentsUpload = ({
  loanId,
  documentType,
  onSuccess,
}: IUseBasicDocumentsUpload) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const { data: deal } = useSWR<ILoanApi>(api.getLoan(loanId));

  const isLoading = typeof deal === 'undefined';

  const currentDocument = useMemo(
    () => deal?.documents.filter((item) => item.type === documentType),
    [deal, documentType]
  );

  const defaultValues = useMemo(
    () => ({
      documentFiles: getDocumentsFiles(currentDocument),
    }),
    [currentDocument]
  );

  const formMethods = useForm<any>({
    defaultValues,
  });

  const { formState, reset } = formMethods;
  const isSubmitting = formState?.isSubmitting;
  const isDirty = formState?.isDirty;

  const onSubmit = useCallback(
    async ({ documentFiles }: IDocumentsData) => {
      const document = getCurrentOrEmptyDocument(documentType, currentDocument);

      const documents = [
        {
          ...document,
          files: documentFiles,
        },
      ];

      await api.updateBusinessDealDocuments(loanId, {
        documents,
      });

      await onSuccess?.();
      setIsSuccess(true);
    },
    [currentDocument, documentType, loanId, onSuccess]
  );

  useEffect(() => {
    reset(defaultValues);
    /** This is to make sure we don't reset the form when the
     * user already changed one file of the list.
     * **/
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, isLoading]);

  return {
    formMethods,
    isSubmitting,
    isSuccess,
    onSubmit,
    isDirty,
  };
};
