import { useTranslation } from 'react-i18next';

import { QualifyCreditReportForm } from 'components/QualifyCreditReport/QualifyCreditReportForm';
import { QualifyScreenWrapper } from 'components/QualifyScreenWrapper';

import { routePaths } from 'router/routePaths';

import { useResidencyCreditReport } from './useResidencyCreditReport';

export const QualifyCreditReport = () => {
  const { t } = useTranslation();
  const { onFinishQualificationFlow } = useResidencyCreditReport();

  return (
    <QualifyScreenWrapper
      step={4}
      totalSteps={4}
      title={t('QualifyCreditReport:credit_report_title')}
      subtitle={t('QualifyCreditReport:credit_report_subtitle')}
      prevScreen={routePaths.qualifyProofOfFundValidate}
      data-testid="qualify-credit-report"
      allowSkip={true}
    >
      <QualifyCreditReportForm onSuccess={onFinishQualificationFlow} />
    </QualifyScreenWrapper>
  );
};
