import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { routePaths } from 'router/routePaths';

import { OnboardingProfileScreen } from './OnboardingProfileScreen';

export const ProfileSellScreen = () => {
  const { t } = useTranslation();

  const copies = useMemo(
    () => ({
      title: t('OnboardingProfileScreen:title_sell'),
    }),
    [t]
  );

  return (
    <OnboardingProfileScreen
      data-testid="profile-sell-screen"
      copies={copies}
      showResidencyField={false}
      saveButtonProps={{
        children: t('OnboardingProfileScreen:save_cta_sell'),
        rightIcon: <FontAwesomeIcon icon={regular('arrow-right')} />,
      }}
      nextScreen={routePaths.onboardingSellerApplication.index}
    />
  );
};
