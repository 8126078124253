import { useCallback } from 'react';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { HeaderProps } from './InvestmentCriteria.interface';
import { FullScreenFunnelLayout } from './InvestmentCriteriaScreen.styles';
import { useInvestmentCriteriaNavigation } from './hooks/useInvestmentCriteriaNavigation';

export const Header = ({ icon, actionProps }: HeaderProps) => {
  return (
    <FullScreenFunnelLayout.Header>
      <FullScreenFunnelLayout.ExitLink {...actionProps}>
        {icon}
      </FullScreenFunnelLayout.ExitLink>
    </FullScreenFunnelLayout.Header>
  );
};

export const GoBackHeader = () => {
  const { navigate } = useInvestmentCriteriaNavigation();

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Header
      icon={<FontAwesomeIcon icon={regular('arrow-left-long')} size="xl" />}
      actionProps={{
        as: 'a',
        onClick: handleBack,
        'data-testid': 'go-back-link',
      }}
    />
  );
};
