import { LoanStatus } from 'lib/types';

interface IUseDealStatusFlagsProps {
  status?: keyof typeof LoanStatus;
  notes?: string;
  isReadyForEvaluation?: boolean;
}

export const useDealStatusFlags = ({
  status,
  notes,
  isReadyForEvaluation,
}: IUseDealStatusFlagsProps) => {
  const isMissingInfoBeforeEvaluation =
    LoanStatus.missing_info === status && !isReadyForEvaluation && !notes;

  const isMissingInfoDuringEvaluation =
    LoanStatus.missing_info === status && notes;

  const isTermSheetReadyForReview = LoanStatus.termsheet_sent === status;

  const isEvaluatingForFinancing = [
    LoanStatus.applied,
    LoanStatus.analysis,
  ].includes(status as LoanStatus);

  const isClosingDetailsNeeded = [
    LoanStatus.termsheet_signed,
    LoanStatus.due_diligence_applied,
  ].includes(status as LoanStatus);

  const isClosingDetailsReviewing = [
    LoanStatus.due_diligence_analysis,
  ].includes(status as LoanStatus);

  const isClosingMissingInfo = LoanStatus.due_diligence_missing_info === status;

  const isContractSent = LoanStatus.contract_sent === status;
  const isContractSigned = LoanStatus.contract_signed === status;
  const isCanceled = LoanStatus.canceled === status;

  return {
    isMissingInfoBeforeEvaluation,
    isMissingInfoDuringEvaluation,
    isTermSheetReadyForReview,
    isEvaluatingForFinancing,
    isClosingMissingInfo,
    isContractSent,
    isContractSigned,
    isCanceled,

    isClosingDetailsNeeded,
    isClosingDetailsReviewing,
  };
};
