import { QualificationDot } from './QualificationMenuBadge.styles';
import { QualificationStatusApi } from './QualificationStatus.interface';
import { useQualificationStatus } from './hooks';

const showBadgeStatuses = [
  QualificationStatusApi.MISSING_INFO,
  QualificationStatusApi.REJECTED,
];

export const QualificationMenuBadge = () => {
  const { status } = useQualificationStatus();

  if (!status || !showBadgeStatuses.includes(status)) {
    return null;
  }

  return <QualificationDot />;
};
