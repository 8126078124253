import { useCallback, useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';

import { useUser } from 'providers/UserProvider';

import { QualificationStatusApi } from './QualificationStatus.interface';
import { useQualificationStatus } from './hooks';

export const useQualificationApprovedModal = () => {
  const { status } = useQualificationStatus();
  const { user } = useUser();

  const closeModal = useCallback(() => {
    setOpened(false);
  }, []);

  const [shownAt, setShownAt] = useLocalStorage<null | string>(
    `qualificationApprovedModalShownAt-${user?.id}`,
    null
  );

  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (status !== QualificationStatusApi.QUALIFIED || !!shownAt) {
      return;
    }
    setShownAt(new Date().toISOString());
    setOpened(true);
  }, [status, shownAt, setShownAt]);

  return {
    opened,
    closeModal,
  };
};
