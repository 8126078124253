import {
  InsightsPreparedData,
  InsightsRawData,
} from 'components/InsightsCharts/InsightsCharts.interface';

import { getPercentageChange } from './getPercentageChange';

const prepareDataItem = (item: InsightsRawData): InsightsPreparedData => {
  const revenueAmount = item.revenueAmount;

  const dateAccounting = item.dateAccounting;
  const cogsAmount = Math.abs(item.cogsAmount);
  const profitAmount = item.profitAmount;
  const tacosAmount = Math.abs(item.tacosAmount);

  const cogsPercentageOfRevenue = getPercentageChange(
    cogsAmount,
    revenueAmount
  );
  const profitPercentageOfRevenue = getPercentageChange(
    profitAmount,
    revenueAmount
  );

  const tacosPercentageOfRevenue = getPercentageChange(
    tacosAmount,
    revenueAmount
  );

  return {
    dateAccounting,
    revenueAmount,
    cogsAmount,
    profitAmount,
    tacosAmount,
    cogsPercentageOfRevenue: cogsPercentageOfRevenue ?? 0,
    profitPercentageOfRevenue: profitPercentageOfRevenue ?? 0,
    tacosPercentageOfRevenue: tacosPercentageOfRevenue ?? 0,
  };
};

export const prepareData = (
  data: InsightsRawData[]
): InsightsPreparedData[] => {
  return data.map(prepareDataItem);
};
