import { Navigate } from 'react-router-dom';

import { routePaths } from 'router/routePaths';

import { useGetFinancingNavigate } from './useGetFinancingNavigate';

export const GetFinancingScreen = () => {
  const navigateTo = useGetFinancingNavigate(routePaths.dealFlow);

  return <Navigate to={navigateTo.pathname} state={navigateTo.state} replace />;
};
