import { ClosingStepsNames } from 'lib/types';

export const dealClosingSteps = [
  ClosingStepsNames.termSheet,
  ClosingStepsNames.letterOfIntent,
];

export const prepareContractSteps = [
  ClosingStepsNames.fundsBankAccount,
  ClosingStepsNames.legalEntity,
  ClosingStepsNames.shareholdersAndDirectors,
  ClosingStepsNames.contractSigners,
];

export const repaymentSteps = [
  ClosingStepsNames.revenueBankAccount,
  ClosingStepsNames.revenueSources,
  ClosingStepsNames.ERPTools,
  ClosingStepsNames.paymentMethod,
];

export const businessApaConfirmation = [
  ClosingStepsNames.assetPurchaseAgreement,
];
