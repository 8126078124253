import React from 'react';
import { useTranslation } from 'react-i18next';

import { AttachBusinessDetails } from 'components/ForSaleDetailApplication/AttachBusinessDetails';
import { ReadBusinessDetails } from 'components/ForSaleDetailApplication/ReadBusinessDetails';
import { LayoutSidebarDetail } from 'components/LayoutSidebarDetail';
import { SubmitForEvaluationErrorContext } from 'components/SubmitForEvaluation';

import { ForSaleApplicationApi, SellApplicationStatus } from 'lib/types';

interface BusinessDetailsProps {
  sellApplication?: ForSaleApplicationApi;
}

export const BusinessDetails = ({ sellApplication }: BusinessDetailsProps) => {
  const { t } = useTranslation();

  if (!sellApplication) return null;

  if (
    [
      SellApplicationStatus.missing_info,
      SellApplicationStatus.under_review,
    ].includes(sellApplication?.status)
  ) {
    return (
      <SubmitForEvaluationErrorContext>
        <AttachBusinessDetails
          business={sellApplication?.business}
          application={sellApplication?.application}
          status={sellApplication?.status}
          editable={
            sellApplication?.status === SellApplicationStatus.missing_info
          }
        />
      </SubmitForEvaluationErrorContext>
    );
  }

  return (
    <>
      <LayoutSidebarDetail.Divider>
        <LayoutSidebarDetail.ContentHeader>
          {t('ForSaleDetailApplication:business_details_header')}
        </LayoutSidebarDetail.ContentHeader>
      </LayoutSidebarDetail.Divider>
      <ReadBusinessDetails sellApplication={sellApplication} />
    </>
  );
};
