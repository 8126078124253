import { useAtom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { SyncStorage } from 'jotai/vanilla/utils/atomWithStorage';
import { useCallback } from 'react';

const storage = createJSONStorage(() => sessionStorage) as SyncStorage<
  string | undefined
>;

const tokenAtom = atomWithStorage<string | undefined>(
  'companionMode',
  undefined,
  storage
);

export const useCompanionMode = () => {
  const [token, _setToken] = useAtom(tokenAtom);

  const logout = useCallback(() => {
    _setToken(undefined);
  }, [_setToken]);

  const getAccessToken = useCallback(() => {
    return token;
  }, [token]);

  const setToken = useCallback(
    async (newToken: string) => {
      _setToken(newToken);
    },
    [_setToken]
  );

  return {
    setToken,
    token,
    isCompanionMode: !!token,
    logout,
    getAccessToken,
  };
};
