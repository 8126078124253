import React from 'react';
import { useOutlet, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { ConfidentialityAgreementBanner } from 'components/ConfidentialityAgreementBanner';
import { DealDetail, LoadingElements } from 'components/DealDetail';
import { ForSaleSidebar, ForSaleTimeline } from 'components/ForSaleTimeline';
import { PageModalWrapper } from 'components/Modal';
import { QualificationBanner } from 'components/QualificationBanner';

import { apiV2 } from 'lib/api';
import { ListingForSale } from 'lib/types';

import { DealOutsideLendingScope } from './DealOutsideLendingScope';

export const ForSaleDetail = () => {
  const params = useParams();
  const { listingId } = params || {};

  const { data: listing } = useSWR<ListingForSale>(
    listingId && apiV2.getListing(listingId)
  );

  const outlet = useOutlet({ handleRefreshLoan: () => {} });

  return (
    <>
      <DealDetail.Container data-testid="for-sale-detail">
        <DealDetail.Content>
          <QualificationBanner />
          <DealOutsideLendingScope listing={listing} />
          <ConfidentialityAgreementBanner listing={listing} />
          {!listing && <LoadingElements.Timeline />}
          {!!listing && <ForSaleTimeline listing={listing} />}
        </DealDetail.Content>
        <div>
          <ForSaleSidebar listing={listing} />
        </div>
      </DealDetail.Container>
      <PageModalWrapper>{outlet}</PageModalWrapper>
    </>
  );
};
