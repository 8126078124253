import { VaultClient } from '@piiano/vault-client';

import { apiV2 } from 'lib/api';

import {
  PrivacyVaultConfig,
  UpdateObjectByIdArgs,
} from './privacy-vault.types';

export class PrivacyVault {
  vaultClient?: VaultClient;

  async getVaultConfig() {
    return await apiV2.getVaultConfig<PrivacyVaultConfig>();
  }

  async getClient(): Promise<VaultClient> {
    if (this.vaultClient) {
      return this.vaultClient;
    }

    const response = await this.getVaultConfig();

    const { vaultUrl, apiKey } = response?.data ?? {};

    this.vaultClient = new VaultClient({
      vaultURL: vaultUrl,
      apiKey,
    });

    return this.vaultClient;
  }

  async updateObjectById({
    collectionId,
    objectId,
    requestBody,
  }: UpdateObjectByIdArgs) {
    const vaultClient = await this.getClient();

    return vaultClient.objects.updateObjectById({
      collection: collectionId,
      id: objectId,
      reason: 'AppFunctionality',
      requestBody,
    });
  }

  async getObjectById({
    collectionId,
    objectId,
    props,
  }: {
    collectionId: string;
    objectId: string;
    props: string[];
  }) {
    const vaultClient = await this.getClient();

    return vaultClient.objects.getObjectById({
      collection: collectionId,
      id: objectId,
      props,
      reason: 'AppFunctionality',
    });
  }
}
