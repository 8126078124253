import { useTranslation } from 'react-i18next';

import { Text } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ErrorIconWrapper, ErrorWrapper } from './UnauthorizedError.styles';

export const UnauthorizedError = () => {
  const { t } = useTranslation();

  return (
    <ErrorWrapper data-testid="unauthorized-error">
      <ErrorIconWrapper>
        <FontAwesomeIcon icon={regular('eye-slash')} size="2xl" />
      </ErrorIconWrapper>
      <Text bold>{t('ConnectAccountInvitationScreen:expired_error')}</Text>
      <Text>{t('ConnectAccountInvitationScreen:request_access')}</Text>
    </ErrorWrapper>
  );
};
