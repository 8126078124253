import { PropsWithChildren } from 'react';

import { TimelineStepVariant } from './Timeline.interface';
import { TimelineStyles } from './Timeline.styles';

interface StepTimeProps {
  variant?: TimelineStepVariant;
  errorVariant?: 'error' | 'warning';
}

export const StepTime = ({
  children,
  variant,
  errorVariant,
}: PropsWithChildren<StepTimeProps>) => (
  <TimelineStyles.Time variant={variant} errorVariant={errorVariant}>
    {children}
  </TimelineStyles.Time>
);
