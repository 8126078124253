import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { EnquiryApi } from 'lib/types';

import { EnquiryModalStyles } from './EnquiryModal.styles';

export const LinkedInCV = ({ enquiry }: { enquiry?: EnquiryApi }) => {
  const [cvFile] = enquiry?.buyerQualification?.cvFiles ?? [];

  if (cvFile) {
    return (
      <EnquiryModalStyles.Href
        href={cvFile.downloadUrl}
        title={cvFile.downloadUrl}
        target="_blank"
      >
        <FontAwesomeIcon icon={regular('file-arrow-down')} size="sm" />
        <EnquiryModalStyles.HrefText>{cvFile.name}</EnquiryModalStyles.HrefText>
      </EnquiryModalStyles.Href>
    );
  }

  if (!enquiry?.buyerQualification?.linkedin) return null;

  return (
    <EnquiryModalStyles.Href
      href={enquiry?.buyerQualification?.linkedin}
      title={enquiry?.buyerQualification?.linkedin}
      target="_blank"
    >
      <FontAwesomeIcon icon={regular('link')} size="sm" />
      <EnquiryModalStyles.HrefText>
        {enquiry?.buyerQualification?.linkedin}
      </EnquiryModalStyles.HrefText>
    </EnquiryModalStyles.Href>
  );
};
