import { ButtonFeedback } from '@boopos/design-system';

import { IUseAccountConnector } from 'components/AccountConnectors/AccountConnectors.interface';
import { useAutoOpenConnection } from 'components/AccountConnectors/useAutoOpenConnection';

import { useBankLink } from './useBankLink';

export const BankLink = (props: IUseAccountConnector) => {
  const { provider, businessId, onSuccess, children, render, ...rest } = props;
  const { ready, open, interacting } = useBankLink({
    provider,
    businessId,
    onSuccess,
  });

  useAutoOpenConnection(provider, open, ready);

  if (render) {
    const renderProps = {
      ...rest,
      disabled: !ready,
      onClick: open,
    };

    return render(renderProps);
  }

  return (
    <ButtonFeedback
      disabled={!ready || interacting}
      isLoading={interacting}
      onClick={open}
      data-testid="bank-link"
      fullWidth
      variant="secondary"
      {...rest}
    >
      {children}
    </ButtonFeedback>
  );
};
