import { useAtom } from 'jotai';
import { useMedia } from 'react-use';

import { config } from '@boopos/design-system';

import { mainMenuHiddenAtom } from 'components/Layout';

export const useHubspotWidgetPosition = () => {
  const isDesktop = useMedia(config.media.bp2);
  const [mainMenuHiddenOn] = useAtom(mainMenuHiddenAtom);

  return { isDesktop, mainMenuHiddenOn };
};
