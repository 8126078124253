import { css, styled } from '@boopos/design-system';

export const Wrapper = styled('div', {
  maxWidth: 390,
  mx: 'auto',
});

export const BoxesWrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  columnGap: '$2',
  rowGap: '$4',
  mb: '$6',
});

export const buttonClassName = css({
  cursor: 'pointer',
  border: 0,
  margin: 0,
  padding: 0,
  background: '$slate100',
  width: '100%',
  borderRadius: '1.6rem',
  px: '$4',
  py: '$2',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&[disabled]': {
    opacity: 0.5,
  },
  img: {
    maxWidth: '100%',
  },
});

export const BankAccountWrapper = styled('div', {
  border: '1px solid $slate200',
  p: '$4',
  br: '.8rem',
  mb: '$6',
  mt: '$6',
});

export const BankAccountIconWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  lineHeight: 0,
  svg: {
    color: '$slate900',
  },
  mb: '$4',
});
