import { styled } from '@boopos/design-system';

export const Hint = styled('div', {
  display: 'flex',
  alignItems: 'center',
  height: '4.2rem',
  px: '$3',
  bc: '$slate100',
});

export const InputWithHints = styled('div', {
  display: 'flex',
  alignItems: 'center',
  jc: 'center',
  width: '100%',
  my: '$6',
});
