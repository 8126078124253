import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonFeedback } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DetailCell } from 'ui/DetailCell';

import { DeleteConfirmationAction } from 'components/ConfirmationModal';

import { formatMoney } from 'lib/format';
import { MoneyFormat } from 'lib/format/money/formatMoney.interface';

import { BankAccountLogo } from './BankAccountLogo';
import { BankAccountPreviewProps } from './BankAccountsPreview.interface';

export const animationVariants = {
  visible: {
    height: 'auto',
  },
  hidden: {
    height: 0,
  },
};

export const BankAccountPreview = ({
  bankAccount,
  onDisconnectAccount,
  variant,
}: BankAccountPreviewProps) => {
  const { t } = useTranslation();
  const [deleting, setDeleting] = useState(false);
  const balance = bankAccount?.metadata?.initialBalance;

  const balanceFormatted = balance
    ? formatMoney(balance, { format: MoneyFormat.long })
    : undefined;

  const onDelete = useCallback(async () => {
    setDeleting(true);
    await onDisconnectAccount?.(bankAccount.externalId);
    setDeleting(false);
  }, [bankAccount, onDisconnectAccount]);

  return (
    <DetailCell
      variant={variant}
      icon={
        <BankAccountLogo institution={bankAccount?.metadata?.institution} />
      }
      title={bankAccount.name}
      action={
        !!onDisconnectAccount && (
          <DeleteConfirmationAction
            title={t('BankAccountPreview:delete_confirmation_title')}
            onDelete={onDelete}
          >
            <ButtonFeedback
              rounded
              leftIcon={<FontAwesomeIcon icon={regular('trash')} size="lg" />}
              isLoading={deleting}
              variant="nude"
              data-testid="delete-bank-account"
              size="reduced"
            />
          </DeleteConfirmationAction>
        )
      }
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={animationVariants}
    >
      {!!balanceFormatted && balanceFormatted}
    </DetailCell>
  );
};
