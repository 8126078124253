import { useTranslation } from 'react-i18next';

import { ButtonFeedback, Checkbox } from '@boopos/design-system';

import { GoBackHeader } from './Header';
import { FullScreenFunnelLayout } from './InvestmentCriteriaScreen.styles';
import { useInvestmentCriteriaSuccessScreen } from './hooks/useInvestmentCriteriaSuccessScreen';

export const InvestmentCriteriaSuccessScreen = () => {
  const { t } = useTranslation();

  const { onSubmit, formMethods, loading, success } =
    useInvestmentCriteriaSuccessScreen();

  return (
    <>
      <GoBackHeader />
      <FullScreenFunnelLayout.Content>
        <FullScreenFunnelLayout.Title>
          {t('InvestmentCriteria:success_title')}
        </FullScreenFunnelLayout.Title>

        <FullScreenFunnelLayout.Description>
          {t('InvestmentCriteria:success_description')}
        </FullScreenFunnelLayout.Description>
        <form
          onSubmit={onSubmit}
          data-testid="investment-criteria-success-form"
        >
          <FullScreenFunnelLayout.FormContainer>
            <Checkbox
              id="opt-in-marketing"
              label={t('InvestmentCriteria:opt_in_marketing')}
              {...formMethods.register('optIn')}
              groupSpacing="none"
            />
          </FullScreenFunnelLayout.FormContainer>
          <FullScreenFunnelLayout.CallToAction>
            <ButtonFeedback
              type="submit"
              fullWidth
              isLoading={loading}
              isSuccess={success}
            >
              {t('InvestmentCriteria:done')}
            </ButtonFeedback>
          </FullScreenFunnelLayout.CallToAction>
        </form>
      </FullScreenFunnelLayout.Content>
    </>
  );
};
