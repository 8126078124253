import useSWR from 'swr';

import api from 'lib/api';
import { IBusinessApi } from 'lib/types';

export const useBusinessInsightsLoan = ({
  businessId,
}: {
  businessId?: string;
}) => {
  const { data: business } = useSWR<IBusinessApi>(
    businessId && api.getPortfolioBusiness(businessId)
  );

  const insightsLoan = business?.insightsLoan;

  return {
    insightsLoan,
  };
};
