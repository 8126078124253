import {
  ApplicationBusinessApi,
  ITermSheetApi,
  SaleSellerApplicationApi,
  SellApplicationStatus,
} from 'lib/types';

export interface ActionsSidebarProps {
  status?: SellApplicationStatus;
  application?: SaleSellerApplicationApi;
  business?: ApplicationBusinessApi;
  loanTermSheet?: ITermSheetApi;
}

export enum PrimaryActionsEnum {
  sendToAnalysis = 'sendToAnalysis',
}
