import { styled } from '@boopos/design-system';

export const RatingStyles = {
  Wrapper: styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '.4rem',
    color: '$infoStatic',
  }),
  Child: styled('div', {
    width: '1.8rem',
    height: '1.6rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
};
