import Skeleton from 'react-loading-skeleton';

import { styled } from '@boopos/design-system';

const Title = styled('h2', {
  fontHeadingSerif: '$h3',
  color: '$textBodyPrimary',
});

export const ListCard = {
  Title,
  TitleWithAction: styled('div', {
    display: 'flex',
    alignItems: 'flex-start',
    [`${Title}`]: {
      minHeight: '4rem',
      display: 'flex',
      alignItems: 'center',
      flex: 1,
    },
  }),
  TitleContainer: styled('div', {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '$2',
    '@bp2': {
      flexDirection: 'row',
      alignItems: 'center',
      gap: '$2',
    },
  }),
  TitleAction: styled('div', {
    ml: '$2',
    display: 'flex',
    alignItems: 'center',
  }),
  TagsContainer: styled('div', {
    display: 'flex',
    gap: '$2',
  }),
  Card: styled('div', {
    textDecoration: 'none',
    cursor: 'pointer',
    p: '1.6rem',
    color: '$textBodyPrimary',
    backgroundColor: '$surfaceBase',
    mb: '1.6rem',
    borderRadius: '1.6rem',
    display: 'grid',
    gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
    gap: '1.6rem',
    border: '2px solid $borderBaseDefault',
    transition: 'border-color .2s ease-in-out',
    '@bp2': {
      gap: '2.4rem',
      p: '3.2rem',
    },
    '&:hover, &:focus': {
      border: '2px solid $colors$borderBaseHover',
      outline: 'none',
      transition: 'border-color .2s ease-in-out',
    },
    '&:active': {
      border: '2px solid $colors$borderBasePressed',
    },
  }),
  ValuesGrid: styled('div', {
    display: 'grid',
    py: '$2',
    gap: '2.4rem',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    '@bp2': {
      gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    },
  }),
  ValueItem: styled('div', {}),
};

export const ListCardLoading = () => (
  <ListCard.Card>
    <ListCard.Title>
      <Skeleton height={32} width={348} />
    </ListCard.Title>
    <ListCard.ValuesGrid>
      <ListCard.ValueItem>
        <Skeleton height={24} width={100} />
      </ListCard.ValueItem>
    </ListCard.ValuesGrid>
  </ListCard.Card>
);
