import { motion } from 'framer-motion';

import { keyframes, styled } from '@boopos/design-system';

const spin = keyframes({
  to: { transform: 'rotate(360deg)' },
});

const DetailCellStyles = {
  Wrapper: styled(motion.div, {
    p: '$4',
    background: '$slate50',
    borderRadius: '1.6rem',
    display: 'flex',
    alignItems: 'flex-start',
    gap: '$4',
    color: '$slate800',
    border: 0,
    outline: 0,
    width: '100%',
    textAlign: 'left',
    overflow: 'hidden',
    'a&, button&': {
      textDecoration: 'underline',
      textUnderlineOffset: '0.2rem',
    },
    'a&:hover, button&:hover': {
      boxShadow: 'inset 0 0 0 2px $colors$slate200',
      cursor: 'pointer',
    },
    'a&:focus, button&:focus': {
      boxShadow: 'inset 0 0 0 2px $colors$sky800',
      outline: 'none',
    },
    variants: {
      variant: {
        light: {
          background: '$white',
        },
        default: {
          background: '$slate50',
        },
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }),
  Content: styled('div', {
    minWidth: 0,
    flex: 1,
  }),
  IconWrapper: styled('div', {
    minHeight: '4rem',
    minWidth: '2.4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '$slate500',
    lineHeight: 0,
    img: {
      width: '4rem',
    },
    variants: {
      loading: {
        true: {
          animation: `${spin} .675s linear 0s infinite normal`,
        },
      },
    },
  }),
  Title: styled('div', {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flex: 1,
    fontWeight: 500,
  }),
  Action: styled('div', {
    button: {
      background: 'none',
    },
    'button:hover': {
      background: '$slate100',
    },
  }),
  Header: styled('div', {
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    gap: '$4',
    minHeight: '4rem',
    flex: 1,
  }),
  Children: styled('div', {
    color: '$slate600',
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
  }),
  Parent: styled('div', {
    borderRadius: '1.6rem',
    variants: {
      variant: {
        light: {
          background: '$white',
        },
        default: {
          background: '$slate50',
        },
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }),
};

export { DetailCellStyles };
