import { apiV2 } from 'lib/api';
import { parseMoneyFromApiToString, parseMoneyToApi } from 'lib/parse';
import { Currency, ForSaleApplicationApi } from 'lib/types';

import { BusinessPerformanceFormValues } from './BusinessPerformanceForm.interface';

export const updateApplication = async (
  loanId: string,
  data: BusinessPerformanceFormValues
) =>
  apiV2.updateApplication(loanId, {
    application: {
      ttmRevenue: parseMoneyToApi({
        amount: Number(data.revenue),
        currency: Currency.USD,
      }),
      ttmProfit: parseMoneyToApi({
        amount: Number(data.profit),
        currency: Currency.USD,
      }),
    },
  });

export const parseFromApi = (sellApplication: ForSaleApplicationApi) => ({
  revenue: parseMoneyFromApiToString(sellApplication?.application?.ttmRevenue),
  profit: parseMoneyFromApiToString(sellApplication?.application?.ttmProfit),
});
