import { useAtom } from 'jotai';
import { useCallback } from 'react';
import useSWR from 'swr';

import { apiV2 } from 'lib/api';

import { parentCategoriesAtom } from 'screens/InvestmentCriteriaScreen/InvestmentCriteria.atoms';

import { useOtherCategoriesUtils } from './useOtherCategoriesUtils';
import { GENERIC_OTHER } from './useRollingFlow';

export const useParentCategories = () => {
  const { data: investmentCriteria } = useSWR(apiV2.getInvestmentCriteria());
  const [parentCategories, setParentCategories] = useAtom(parentCategoriesAtom);
  const { otherParentCategoryId } = useOtherCategoriesUtils();

  const handleSelectParentCategories = useCallback(
    (id: string | null) => {
      if (!id) return;

      if (parentCategories.includes(id)) {
        setParentCategories((prevCategories) => {
          return prevCategories.filter((categoryId) => categoryId !== id);
        });

        return;
      }

      setParentCategories((prevCategories) => {
        return [...prevCategories, id];
      });
    },
    [parentCategories, setParentCategories]
  );

  const getParentCategoriesIds = useCallback(() => {
    const { categories, otherBusinessTypes } = investmentCriteria ?? {};
    const hasCustomOtherCategory = otherBusinessTypes?.length;

    const newCategories = Object.entries(categories ?? {}).flatMap(
      ([key, ids]) => {
        if (key === otherParentCategoryId) {
          return ids;
        }
        return [key];
      }
    ) as string[];

    if (hasCustomOtherCategory) {
      newCategories.push(GENERIC_OTHER);
    }

    return newCategories;
  }, [investmentCriteria, otherParentCategoryId]);

  const syncParentCategories = useCallback(() => {
    setParentCategories((prevCategories) => {
      return Array.from(
        new Set([...prevCategories, ...getParentCategoriesIds()])
      );
    });
  }, [getParentCategoriesIds, setParentCategories]);

  return {
    parentCategories,
    syncParentCategories,
    handleSelectParentCategories,
  };
};
