import { useTranslation } from 'react-i18next';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DetailCell } from 'ui/DetailCell';

import { ProofOfFundsVariantProp } from 'components/ProofOfFunds/ProofOfFunds.interface';

import { UploadProofOfFundsWrapper } from './UploadProofOfFundsWrapper';

export const UploadAdditionalDocuments = ({
  variant,
}: ProofOfFundsVariantProp) => {
  const { t } = useTranslation();
  return (
    <UploadProofOfFundsWrapper>
      {({ open, loading }) => (
        <DetailCell
          as="a"
          variant={variant}
          icon={
            loading ? (
              <FontAwesomeIcon
                icon={regular('arrow-rotate-right')}
                size="lg"
                fixedWidth
              />
            ) : (
              <FontAwesomeIcon icon={regular('plus')} size="lg" fixedWidth />
            )
          }
          title={t('DocumentsPreview:add_additional_document')}
          onClick={open}
          loading={loading}
        />
      )}
    </UploadProofOfFundsWrapper>
  );
};
