import { styled } from '@boopos/design-system';

export const Styles = {
  Header: styled('div', {
    py: '1.6rem',
    fontWeight: '$bold',
    color: '$textBodyTertiary',
  }),
  Timeline: styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  }),
  TimelineItem: styled('div', {
    pl: '2.1rem',
    position: 'relative',
    ':before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      mt: '-.6rem',
      left: 0,
      width: '1.2rem',
      height: '1.2rem',
      borderRadius: '50%',
      backgroundColor: '$decorationBeigeSoft',
    },
    ':after': {
      content: '""',
      width: '.1rem',
      height: 'calc(100% + .5rem)',
      position: 'absolute',
      backgroundColor: '$decorationBeigeSoft',
      top: '1.5rem',
      left: '.55rem',
    },
    '&:nth-last-child(2)': {
      ':after': {
        display: 'none',
      },
    },
    '&:last-child': {
      ':after, :before': {
        display: 'none',
      },
    },
  }),
};
