import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';
import useSWR from 'swr';

import {
  ActionSideModal,
  ActionSideModalCloseHeader,
  ActionSideModalCommonStyles,
  ActionSideModalLoading,
  useNavigateFromActionSideModal,
} from 'components/ActionSideModal';

import Api from 'lib/api';
import { ILoanApi } from 'lib/types';

import { LoanAdditionalInfoForm } from './LoanAdditionalInfo';

interface IOutletContext {
  handleRefreshLoan: () => Promise<void>;
  loanId?: string;
}

export const AdditionalDetailsModal = ({ basePath }: { basePath: string }) => {
  const { t } = useTranslation();
  const outletContext = useOutletContext<IOutletContext>() ?? {};
  const params = useParams<{ loanId: string }>();
  const loanId = outletContext?.loanId ?? (params.loanId as string);

  const { data: loan, mutate } = useSWR<ILoanApi>(Api.getLoan(loanId));

  const { onClose } = useNavigateFromActionSideModal(params, { basePath });

  const onSuccess = useCallback(async () => {
    await mutate();
    setTimeout(() => {
      onClose();
    }, 400);
  }, [mutate, onClose]);

  return (
    <ActionSideModal
      header={<ActionSideModalCloseHeader onClose={onClose} />}
      onClose={onClose}
    >
      <ActionSideModalCommonStyles.Title data-testid="additional-details-modal-title">
        {t('AdditionalDetailsModal:title')}
      </ActionSideModalCommonStyles.Title>
      <ActionSideModalCommonStyles.BlockDescription>
        {t('AdditionalDetailsModal:description')}
      </ActionSideModalCommonStyles.BlockDescription>
      {!loan ? (
        <ActionSideModalLoading />
      ) : (
        <LoanAdditionalInfoForm loan={loan} onSuccess={onSuccess} />
      )}
    </ActionSideModal>
  );
};
