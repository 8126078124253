import { styled } from '@boopos/design-system';

export const FlowStatusFeedbackStyles = {
  Wrapper: styled('div', {
    width: '100%',
    maxWidth: '48rem',
    margin: '0 auto',
    pb: '$8',

    [`& > a, & > button`]: {
      display: 'flex',
      width: '25rem',
      textAlign: 'center',
      mx: 'auto',
      mb: '$6',
    },

    variants: {
      fullWidth: {
        true: {
          maxWidth: '100%',
        },
      },
    },
  }),
  Draw: styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '$6',

    variants: {
      fullWidth: {
        true: {},
      },
      showBackground: {
        true: {
          width: '100%',
          maxWidth: '34.3rem',
          margin: '0 auto',
          height: '16rem',
          borderRadius: '1.6rem',
          background: '$slate100',
          marginBottom: 'calc(4rem + 1.6rem)',
          position: 'relative',

          '> svg, > img': {
            position: 'absolute',
            bottom: '-4rem',
          },
        },
      },
    },
    compoundVariants: [
      {
        showBackground: true,
        fullWidth: true,
        css: {
          maxWidth: '100%',
        },
      },
    ],
  }),
  Title: styled('p', {
    fontWeight: 'bold',
    textAlign: 'center',
    mb: '$4',

    variants: {
      big: {
        true: {
          fontSize: '$5',
          mb: '$6',
        },
      },
    },
  }),
  Descriptions: styled('div', {
    color: '$slate600',
    textAlign: 'center',

    '& + *': {
      mt: '$6',
    },

    '& > *': {
      mt: '$4',
    },
  }),
  Description: styled('p', {
    color: '$slate600',
    textAlign: 'center',
    mt: '$4',
  }),
};
