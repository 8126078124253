import { styled } from '@boopos/design-system';

export const Styles = {
  Wrapper: styled('div', {
    display: 'flex',
    alignItems: 'center',
  }),
  Symbol: styled('span', {
    fontSize: '3.2rem',
    verticalAlign: 'bottom',
  }),
  Value: styled('div', {
    fontFamily: '$IBMPlexSans',
    color: '$textBodyPrimary',
    fontSize: '3.2rem',
    lineHeight: '3.2rem',
    fontWeight: '500',
  }),
};
