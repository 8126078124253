import { useFormContext } from 'react-hook-form';

import { useForSaleFiltersConfig } from 'components/ForSaleFilters/useForSaleFiltersConfig';

export const HiddenCurrency = () => {
  const filtersConfig = useForSaleFiltersConfig();
  const { register } = useFormContext();

  if (filtersConfig.isLoading) {
    return null;
  }

  return (
    <input
      type="hidden"
      value={filtersConfig.currency}
      {...register('currency')}
    />
  );
};
