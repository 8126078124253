import { MotionProps } from 'framer-motion';
import { PropsWithChildren } from 'react';

import { TimelineStepVariant } from './Timeline.interface';
import { TimelineStyles } from './Timeline.styles';

interface StepWrapperProps {
  showBottomLine?: boolean;
  topSpace?: 'small' | 'large';
  variant?: TimelineStepVariant;
}

export const StepWrapper = ({
  children,
  showBottomLine,
  topSpace = 'small',
  variant = 'inactive',
  ...rest
}: PropsWithChildren<StepWrapperProps & MotionProps>) => {
  return (
    <TimelineStyles.Step {...rest}>
      <TimelineStyles.StepIndicator
        showBottomLine={showBottomLine}
        topSpace={topSpace}
      >
        <TimelineStyles.StepIndicatorCircle variant={variant} />
      </TimelineStyles.StepIndicator>
      <TimelineStyles.Content topSpace={topSpace}>
        {children}
      </TimelineStyles.Content>
    </TimelineStyles.Step>
  );
};
