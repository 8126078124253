import { styled } from '@boopos/design-system';

export const ScreenLayout = {
  Wrapper: styled('div', {
    px: '$4',
    mb: '4rem',
    mt: '$9',

    '@bp2': {
      px: '$8',
    },
  }),
  Content: styled('div', {}),
};
