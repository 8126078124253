import { css } from '@boopos/design-system';

export const reactTooltipClassName = css({
  '&&': {
    fontSize: '1.4rem',
    lineHeight: '2.4rem',
    color: '$textBodyInverted',
    padding: '.4rem .8rem',
    maxWidth: '20rem',
    borderRadius: '.8rem',
    background: '$backgroundInverted',
    textAlign: 'center',
    textWrap: 'balance',
  },
  '&.react-tooltip__show': {
    opacity: 1,
  },
});
