import { FieldErrors } from 'react-hook-form';

import { LoanDocumentType } from 'lib/types';

import { IParsePersonToApi } from './AddShareholderForm.interface';

export const parsePersonToApi = ({ person, files }: IParsePersonToApi) => {
  return {
    person,
    document: {
      type: LoanDocumentType.kyc,
      files: files.map((fileToUpload) => ({
        name: fileToUpload.fileName,
        url: fileToUpload.url,
      })),
    },
  };
};

export const cleanUndefinedFormErrors = (errors: FieldErrors) => {
  Object.keys(errors).forEach((key) => {
    if (errors[key] === undefined) {
      delete errors[key];
    }
  });

  return errors;
};
