import { IGetKey, IGetUrl } from './usePaginatedResponse.interface';

const emptyArray: any[] = [];

const paginate =
  <ResponseObject, QueryParams extends {}>(
    getUrl: IGetUrl<QueryParams>,
    queryParams?: QueryParams,
    limit?: number
  ): IGetKey<ResponseObject> =>
  (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData?.nextPage) return null;

    const pageNumber = pageIndex === 0 ? 1 : previousPageData?.nextPage;

    const params = { ...(queryParams || {}), page: pageNumber, limit };

    return getUrl(params as any);
  };

export { paginate, emptyArray };
