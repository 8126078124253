import { styled } from '@boopos/design-system';

export const TooltipWrapper = styled('div', {
  padding: '$3',
  border: '1px solid $slate400',
  borderRadius: '.3rem',
  backgroundColor: '$white',
  variants: {
    reduced: {
      true: {
        padding: '$2',
        border: '1px solid $slate200',
        color: '$slate500',
        '&, & > p': {
          fontSize: '1.4rem',
          lineHeight: '2rem',
          m: 0,
        },
      },
    },
  },
});
