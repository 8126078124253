import { useMemo } from 'react';

import { useBuyerInferredAuthParams } from 'lib/hooks/useBuyerInferredAuthParams';

export const useGetFinancingNavigate = (basePath: string) => {
  const { search, state } = useBuyerInferredAuthParams();

  return useMemo(
    () => ({ pathname: `${basePath}${search}`, state }),
    [basePath, search, state]
  );
};
