import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DetailCell } from 'ui/DetailCell';

import { BankAccountPreview } from './BankAccountPreview';
import { BankAccountsPreviewProps } from './BankAccountsPreview.interface';

export const BankAccountsPreview = ({
  bankAccounts,
  onConnectAccount,
  onDisconnectAccount,
  isLoadingConnect,
  variant,
}: BankAccountsPreviewProps) => {
  const { t } = useTranslation();

  if (!bankAccounts?.length) {
    return null;
  }

  return (
    <DetailCell.Parent css={{ mb: '$6' }} variant={variant}>
      <AnimatePresence initial={false}>
        {bankAccounts.map((bankAccount) => (
          <BankAccountPreview
            variant={variant}
            key={bankAccount.id}
            bankAccount={bankAccount}
            onDisconnectAccount={onDisconnectAccount}
          />
        ))}
      </AnimatePresence>
      {!!onConnectAccount && (
        <DetailCell
          as="button"
          variant={variant}
          onClick={onConnectAccount}
          data-testid="connect-another-account"
          icon={
            isLoadingConnect ? (
              <FontAwesomeIcon
                icon={regular('arrow-rotate-right')}
                size="lg"
                fixedWidth
              />
            ) : (
              <FontAwesomeIcon icon={regular('plus')} size="lg" fixedWidth />
            )
          }
          title={t('BankAccountsPreview:connect_another')}
          loading={isLoadingConnect}
        />
      )}
    </DetailCell.Parent>
  );
};
