import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { createToastFeedback } from '@boopos/design-system';

import api, { apiV2 } from 'lib/api';
import { ListingSellerType } from 'lib/types';

import { useUser } from 'providers/UserProvider';

import {
  IContactSellerFormValues,
  IUseContactSeller,
} from './ContactSellerModal.interface';

export const useContactSeller = ({
  listingId,
  handleSuccess,
}: IUseContactSeller) => {
  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { user } = useUser();

  const { data: listing, mutate } = useSWR(
    listingId && apiV2.getListing(listingId)
  );
  const isReady = !!listing;

  const { fullName } = user || {};
  const businessName = listing?.title;

  const defaultValues = useMemo(
    () => ({
      message: t('ContactSellerModal:message', {
        fullName,
        businessName,
      }),
    }),
    [t, fullName, businessName]
  );

  const formMethods = useForm<IContactSellerFormValues>({
    defaultValues,
    mode: 'onChange',
  });

  const isSubmitting = formMethods.formState.isSubmitting;

  const handleRemoveError = useCallback(() => {
    setHasError(false);
  }, []);

  const handleFormSubmitPrevention = useCallback(
    (e: React.KeyboardEvent) =>
      ['Enter', 'NumpadEnter'].includes(e.code) && e.preventDefault(),
    []
  );

  const handleFormSubmit = useCallback(
    async (data: IContactSellerFormValues) => {
      try {
        await api.requestFullBusinessDetails(listingId, {
          message: data.message,
        });
        await mutate();
        await handleSuccess?.();
        setIsSuccess(true);
      } catch (err) {
        console.error(err);

        createToastFeedback({
          type: 'warning',
          title: t('ContactSellerModal:error'),
        });

        setHasError(true);
      }
    },
    [handleSuccess, listingId, t, mutate]
  );

  return {
    sellerType: listing?.sellerType as ListingSellerType,
    formMethods,
    defaultValues,
    isReady,
    isSuccess,
    isSubmitting,
    hasError,
    handleFormSubmitPrevention,
    handleRemoveError,
    handleFormSubmit,
  };
};
