import { MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterItem } from 'ui/Filter';

import { CategoriesProps } from './InvestmentCriteria.interface';

export const Categories = ({
  categories,
  checkAllId,
  genericOtherId,
  onSelect,
  selectedIds,
}: CategoriesProps) => {
  const { t } = useTranslation();

  const handleSelect = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (!onSelect) return;
      const categoryId = e.currentTarget.getAttribute('data-category-id');
      onSelect(categoryId);
    },
    [onSelect]
  );

  const handleAll = useCallback(() => {
    if (!onSelect || !checkAllId) return;
    onSelect(checkAllId);
  }, [onSelect, checkAllId]);

  if (!categories?.length) {
    return null;
  }

  const allSelected = !!checkAllId && selectedIds?.includes(checkAllId);

  return (
    <>
      {!!checkAllId && (
        <FilterItem onClick={handleAll} selected={allSelected}>
          {t('InvestmentCriteria:select_all')}
        </FilterItem>
      )}
      {categories?.map((category) => (
        <FilterItem
          onClick={handleSelect}
          key={category.id}
          selected={
            (checkAllId && selectedIds?.includes(checkAllId)) ||
            selectedIds?.includes(category.id)
          }
          data-category-id={category.id}
          data-testid="category-item"
        >
          {category.name}
        </FilterItem>
      ))}
      {genericOtherId && (
        <FilterItem
          selected={selectedIds?.includes(genericOtherId)}
          onClick={handleSelect}
          data-category-id={genericOtherId}
          data-testid="other-category-item"
        >
          {t('InvestmentCriteria:generic_other')}
        </FilterItem>
      )}
    </>
  );
};
