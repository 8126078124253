import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { Button } from '@boopos/design-system';

import { ConnectAccountModalStyles } from 'components/AccountConnectionModal/partials/ConnectAccountModal.styles';
import { useAccountConnectorsStatus } from 'components/AccountConnectors';
import { Loader } from 'components/FullScreenLoader';
import { ModalOverSideModal } from 'components/ModalOverSideModal';

const animationVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

export const ConnectionStatusModal = () => {
  const { t } = useTranslation();

  const { isConnecting, cancelConnection } = useAccountConnectorsStatus();

  return (
    <ModalOverSideModal
      visible={isConnecting}
      onClose={cancelConnection}
      data-testid="connect-account-modal"
    >
      <AnimatePresence>
        {isConnecting && (
          <ConnectAccountModalStyles.WaitingWrapper
            variants={animationVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            css={{ position: 'static' }}
          >
            <ConnectAccountModalStyles.WaitingContent>
              <Loader />
              <ConnectAccountModalStyles.WaitingText>
                {t('ConnectAccountModal:waiting')}
              </ConnectAccountModalStyles.WaitingText>
              <Button variant="secondary" fullWidth onClick={cancelConnection}>
                {t('ConnectAccountModal:cancel')}
              </Button>
            </ConnectAccountModalStyles.WaitingContent>
          </ConnectAccountModalStyles.WaitingWrapper>
        )}
      </AnimatePresence>
    </ModalOverSideModal>
  );
};
