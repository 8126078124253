import { useCallback, useMemo, useState } from 'react';
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { ButtonFeedback, InlineFeedback } from '@boopos/design-system';

import { useUploadFiles } from 'lib/files';
import { useEffectOnce } from 'lib/hooks/useEffectOnce';

import { IUploadButton } from './UploadButton.interface';
import { Dropzone, StyledDropPlaceholder } from './UploadButton.styled';

export const UploadButton = ({
  onFilesUploaded,
  onStart,
  onFinish,
  disabled,
  buttonChildren,
  dropChildren,
  accept = {
    'application/pdf': ['.pdf'],
    'image/*': ['.png', '.jpg', '.jpeg', '.gif'],
  },
  acceptLiteral = 'PDF and images',
  multiple,
  maxSizeMegabytes = 50,
  buttonProps = {},
  wrapperProps = {},
  uploadParams,
  autoOpen,
}: IUploadButton) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string[]>([]);
  const { uploadFiles } = useUploadFiles();

  const maxSizeBytes = maxSizeMegabytes * Math.pow(1024, 2);

  const removeError = useCallback(() => setError([]), []);

  const rejectedErrorLiteral: {
    [key in ErrorCode]: string;
  } = useMemo(
    () => ({
      [ErrorCode.FileInvalidType]: t('FileUploaderErrors:invalid_file_error', {
        accepted: acceptLiteral,
      }),
      [ErrorCode.FileTooLarge]: t('FileUploaderErrors:big_file_error', {
        max: maxSizeMegabytes,
      }),
      [ErrorCode.FileTooSmall]: t('FileUploaderErrors:small_file_error'),
      [ErrorCode.TooManyFiles]: t('FileUploaderErrors:too_many_files_error'),
    }),
    [t, acceptLiteral, maxSizeMegabytes]
  );

  const errorLiterals = useMemo(
    () => ({
      generalError: t('FileUploaderErrors:unknown_error'),
    }),
    [t]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      (async () => {
        onStart();
        removeError();
        const parsedFiles = acceptedFiles.map((file: File) => ({
          file,
          fileName: file.name,
          params: uploadParams,
        }));
        try {
          const uploadedFiles = await uploadFiles(parsedFiles);
          onFilesUploaded(uploadedFiles);
        } catch (err: any) {
          setError([errorLiterals.generalError]);
        } finally {
          onFinish();
        }
      })();
    },
    [
      errorLiterals,
      removeError,
      onFinish,
      onStart,
      onFilesUploaded,
      uploadFiles,
      uploadParams,
    ]
  );

  const onDropRejected = useCallback(
    (fileRejected: FileRejection[]) => {
      if (fileRejected.length > 0) {
        const [fileErrors] = fileRejected.map((file) => {
          const [firstError] = file.errors;
          const { code } = firstError;
          return (
            rejectedErrorLiteral[code as ErrorCode] ||
            errorLiterals.generalError
          );
        });
        setError([fileErrors]);
      }
    },
    [errorLiterals, rejectedErrorLiteral]
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    onFileDialogOpen: removeError,
    accept,
    multiple,
    onDropRejected,
    maxSize: maxSizeBytes,
  });

  useEffectOnce(() => {
    if (!open || !autoOpen) return;
    open();
  });

  return (
    <Dropzone
      {...getRootProps()}
      active={isDragActive}
      data-testid="drop-wrapper"
      {...wrapperProps}
    >
      <input data-testid="drop-input" {...getInputProps()} />
      {isDragActive && (
        <StyledDropPlaceholder>{dropChildren}</StyledDropPlaceholder>
      )}
      {!!error.length && (
        <InlineFeedback
          variant="warning"
          css={{ mb: '$4' }}
          heading={error.map((errorText) => (
            <div key={errorText} data-testid="upload-error">
              {errorText}
            </div>
          ))}
        />
      )}
      <ButtonFeedback
        variant="secondary"
        fullWidth
        disabled={disabled}
        isLoading={disabled}
        {...buttonProps}
      >
        {buttonChildren}
      </ButtonFeedback>
    </Dropzone>
  );
};
