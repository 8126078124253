import React from 'react';

import { DealMovingForwardModal } from 'components/DealMovingForwardModal';

import { ListingForSale } from 'lib/types';

import { DealUnlocked, shouldShowDealUnlocked } from './DealUnlocked';
import { ForSaleBusinessPreApproved } from './ForSaleBusinessPreApproved';
import { RequestedFullDetails } from './RequestedFullDetails';

export const ForSaleTimeline = ({ listing }: { listing: ListingForSale }) => {
  const notShouldShowDealUnlocked = !shouldShowDealUnlocked(listing);

  return (
    <>
      <DealUnlocked listing={listing} />
      <RequestedFullDetails
        listing={listing}
        showDescription={notShouldShowDealUnlocked}
      />
      <ForSaleBusinessPreApproved listing={listing} showInsights={true} />

      <DealMovingForwardModal
        loanId={listing.dealApplication?.id}
        termsAccepted={!!listing.dealApplication?.termsAcceptedAt}
      />
    </>
  );
};
