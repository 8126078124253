import { useTranslation } from 'react-i18next';

import { ContentCell } from 'ui/ContentCell';

import { DocumentsDetails } from 'components/DocumentsDetails';

import { LoanDocumentType } from 'lib/types';

import { BasicInformationDetailsPropsWithoutLoan } from './BasicInformationDetails.interface';
import { useDocumentsProps } from './useDocumentsProps';

export const BasicInformationBalanceSheet = ({
  documents,
  requiredDocuments,
  editable = true,
  path,
}: Exclude<BasicInformationDetailsPropsWithoutLoan, 'paths'> & {
  path: string;
}) => {
  const { t } = useTranslation();

  const { visible, contentCellProps } = useDocumentsProps(
    { documents, requiredDocuments },
    {
      editable,
      documentType: LoanDocumentType.balance_sheet,
      baseRoute: path,
    }
  );

  if (!visible) return null;

  return (
    <ContentCell
      title={t('BasicInformationDetails:balance_sheet')}
      data-testid="balance-sheet"
      {...contentCellProps}
    >
      <DocumentsDetails
        documents={documents}
        documentType={LoanDocumentType.balance_sheet}
      />
    </ContentCell>
  );
};
