import { useState } from 'react';

import { getDocumentFiles } from 'lib/files';
import { IApiFile, ILoanApi, LoanDocumentType, LoanStatus } from 'lib/types';

export const safeStringify = (obj?: any) => {
  try {
    return JSON.stringify(obj);
  } catch (err) {
    return '';
  }
};

export const parseFiles = (files: IApiFile[]) => {
  return files.map((file) => ({
    id: file.id,
    name: file.name,
  }));
};

export const getPurchaseDealStructure = (loan: ILoanApi) =>
  safeStringify(loan?.purchaseDealStructure);

export const getFiles = (loan: ILoanApi) => {
  const pnlFiles = parseFiles(
    getDocumentFiles(LoanDocumentType.profitAndLoss, loan.documents)
  );

  const cohortsFiles = parseFiles(
    getDocumentFiles(LoanDocumentType.cohorts, loan.documents)
  );

  const additionalFiles = parseFiles(
    getDocumentFiles(LoanDocumentType.additional_details, loan.documents)
  );

  return safeStringify([...pnlFiles, ...cohortsFiles, ...additionalFiles]);
};

const getAdditionalDetails = (loan: ILoanApi) => loan?.additionalDetails;

export const useHasChangesHandler = (loan: ILoanApi) => {
  const [memoizedPurchaseDealStructure] = useState(
    getPurchaseDealStructure(loan)
  );

  const [memoizedFiles] = useState(getFiles(loan));

  const [memoizedAdditionalDetails] = useState(getAdditionalDetails(loan));

  const currentPurchaseDealStructure = getPurchaseDealStructure(loan);
  const currentFiles = getFiles(loan);
  const additionalDetails = getAdditionalDetails(loan);

  const hasChangedDealStructure =
    memoizedPurchaseDealStructure !== currentPurchaseDealStructure;

  const hasChangedFiles = memoizedFiles !== currentFiles;
  const hasAdditionalDetailsChanged =
    memoizedAdditionalDetails !== additionalDetails;

  const hasChanges =
    hasChangedDealStructure || hasChangedFiles || hasAdditionalDetailsChanged;

  return { hasChanges };
};

export const useSubmitActionStatus = (
  loan: ILoanApi
): { disabled: boolean; isResubmit: boolean } => {
  const isResubmit = loan.status === LoanStatus.missing_info && !!loan.notes;

  const { hasChanges } = useHasChangesHandler(loan);

  if (!loan?.canSendToEvaluation) {
    return {
      disabled: true,
      isResubmit,
    };
  }

  if (isResubmit) {
    return {
      disabled: !hasChanges,
      isResubmit,
    };
  }

  return {
    disabled: false,
    isResubmit,
  };
};
