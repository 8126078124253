import { formatMoney } from 'lib/format';
import { MoneyFormat } from 'lib/format/money/formatMoney.interface';
import { Money } from 'lib/types';

export const BusinessRange = ({
  low,
  high,
}: {
  low: Money | undefined;
  high: Money | undefined;
}) => {
  if (low === undefined || high === undefined) {
    return <>-</>;
  }

  return (
    <div data-testid="business-range">
      {formatMoney(low, { format: MoneyFormat.abbr })} -{' '}
      {formatMoney(high, { format: MoneyFormat.abbr })}
    </div>
  );
};
