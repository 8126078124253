import { Link } from 'react-router-dom';

import { keyframes, styled } from '@boopos/design-system';

const moveDown = keyframes({
  from: { transform: 'translateY(0)', height: 'auto' },
  to: { transform: 'translateY(100%)', height: 0 },
});

const moveUp = keyframes({
  from: { transform: 'translateY(100%)', height: 0 },
  to: { transform: 'translateY(0)', height: 'auto' },
});

export const Nav = styled('nav', {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  zIndex: '$2',
  backgroundColor: '$slate50',
  order: 1,
  animation: `${moveUp} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) normal both`,
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  minHeight: '7.2rem',
  alignItems: 'center',
  px: '$4',
  boxShadow: '0 -2px 0 0 $colors$slate100',
  '@bp2': {
    width: 'auto',
    order: 0,
    minWidth: 118,
    animation: 'unset',
    minHeight: '100vh',
    position: 'sticky',
    inset: 'auto',
    top: 0,
    flexDirection: 'column',
    pt: '4rem',
    alignItems: 'center',
    px: 0,
    boxShadow: 'none',
  },
  variants: {
    hiddenOn: {
      mobile: {
        animation: `${moveDown} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) normal both`,
        animationDirection: 'normal',
        '@bp2': {
          display: 'flex',
          animation: 'unset',
        },
      },
      all: {
        animation: `${moveDown} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) normal both`,
        animationDirection: 'normal',
        '@bp2': {
          display: 'none',
          animation: 'unset',
        },
      },
    },
  },
});

export const Avatar = {
  Alert: styled('div', {
    width: '1.4rem',
    height: '1.4rem',
    borderRadius: '50%',
    display: 'block',
    backgroundColor: '$red600',
    position: 'absolute',
    top: 0,
    right: 0,
    border: '1px solid $white',
  }),

  Container: styled('div', {
    position: 'relative',
    flex: 1,
    '@bp2': {
      flex: 0,
      width: '7.2rem',
      display: 'flex',
      justifyContent: 'center',
      mb: '2.4rem',
    },
  }),

  Wrapper: styled(Link, {
    height: '4.2rem',
    width: '4.2rem',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'center',
    cursor: 'pointer',
    textDecoration: 'none',
  }),

  AvatarCrop: styled('div', {
    height: '4.2rem',
    width: '4.2rem',
    borderRadius: '50%',
    overflow: 'hidden',
    backgroundColor: '$decorationBeigeSoft',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '$decorationBeigeBright',
  }),

  Avatar: styled('img', {
    height: '4.2rem',
    width: '4.2rem',
  }),
};
