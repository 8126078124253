export const AuthParams = {
  authHint: 'auth_hint',
  userGoal: 'user_goal',
  buyerTarget: 'buyer_target',
  landingPage: 'landing_page',
  firstName: 'firstname',
  lastName: 'lastname',
  userEmail: 'email',
};

export const AuthHint = {
  login: 'login',
  signup: 'signup',
};

export const AuthParamsDefault = {
  authHint: AuthHint.login,
  userGoal: 'unknown',
};

export const AuthParamsUserGoals = {
  buyer: 'buyer',
  seller: 'seller',
};

export const AuthParamsBuyerTargets = {
  specific: 'specific',
  none: 'none',
};

export interface AuthForcedState {
  authHint?: string;
  userGoal?: keyof typeof AuthParamsUserGoals;
}
