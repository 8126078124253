import { useTranslation } from 'react-i18next';

import { EnquiryStatusBadge } from 'components/EnquiryStatusBadge';

import { EnquiryStatus } from 'lib/types';

import { Styles } from './ListingSaleProcess.styles';

const sortedStatuses = [
  EnquiryStatus.in_conversations,
  EnquiryStatus.due_diligence,
  EnquiryStatus.closing,
  EnquiryStatus.sold,
  EnquiryStatus.rejected,
];

export const ListingSaleProcess = () => {
  const { t } = useTranslation();
  return (
    <>
      <Styles.Header>{t('EnquiriesScreen:sale_process_title')}</Styles.Header>

      <Styles.Timeline data-testid="listing-sale-process">
        {sortedStatuses.map((status) => (
          <Styles.TimelineItem key={status}>
            <EnquiryStatusBadge status={status} />
          </Styles.TimelineItem>
        ))}
      </Styles.Timeline>
    </>
  );
};
