import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';

import { ButtonFeedback, Input } from '@boopos/design-system';

import { LayoutHideMenu } from 'components/Layout';
import { Modal } from 'components/Modal';

import api from 'lib/api';
import { getInputErrorProps } from 'lib/forms';

import {
  IConnectAccountInvitationForm,
  IConnectAccountInvitationModal,
} from './ConnectAccountInvitationModal.interface';
import * as ConnectInvitationStyled from './ConnectAccountInvitationModal.styles';

export const ConnectAccountInvitationModal = ({
  opened,
  onClose,
}: IConnectAccountInvitationModal) => {
  const { t } = useTranslation();
  const { businessId } = useParams();
  const [isFormSuccess, setFormSuccess] = useState(false);

  const title = t('ConnectAccountInvitationModal:title');

  const { register, handleSubmit, formState, reset } =
    useForm<IConnectAccountInvitationForm>({
      defaultValues: {
        email: '',
      },
      mode: 'onChange',
      shouldUseNativeValidation: false,
      delayError: 500,
    });

  const { isSubmitting } = formState;

  const _onClose = useCallback(() => {
    setFormSuccess(false);
    reset();
    onClose();
  }, [onClose, reset]);

  const onSubmit = useCallback(
    async ({ email }: IConnectAccountInvitationForm) => {
      try {
        await api.inviteToMerchantConnection(businessId as string, {
          email,
        });

        setFormSuccess(true);

        setTimeout(() => {
          _onClose();
        }, 1500);
      } catch (err) {}
    },
    [_onClose, businessId]
  );

  const handleEmailValidation = (email: string) =>
    isEmail(email) || t('ConnectAccountInvitationModal:email_not_valid');

  return (
    <>
      <Modal
        opened={opened}
        onClose={_onClose}
        title={title}
        position="bottomSheetCentered"
        maxWidth={450}
      >
        <LayoutHideMenu shouldShowMenuOnUnmount={false}>
          <ConnectInvitationStyled.Form onSubmit={handleSubmit(onSubmit)}>
            <ConnectInvitationStyled.Content>
              <Input
                type="email"
                data-testid="admin-email-input"
                label={t('ConnectAccountInvitationModal:admin_email')}
                autoFocus
                groupSpacing="none"
                {...getInputErrorProps(formState?.errors?.email)}
                {...register('email', {
                  required: t('ConnectAccountInvitationModal:email_required'),
                  validate: handleEmailValidation,
                })}
              />
            </ConnectInvitationStyled.Content>
            <ConnectInvitationStyled.Footer>
              <ButtonFeedback
                type="submit"
                fullWidth
                isLoading={isSubmitting}
                isSuccess={isFormSuccess}
                data-testid="send-invitation-submit"
              >
                {t('ConnectAccountInvitationModal:action')}
              </ButtonFeedback>
            </ConnectInvitationStyled.Footer>
          </ConnectInvitationStyled.Form>
        </LayoutHideMenu>
      </Modal>
    </>
  );
};
