import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectHookForm } from 'components/SelectHookForm';

import { countriesForReactSelect } from 'lib/constants';

import { ICountrySelect } from './ICountrySelect.interface';

export const CountrySelect = ({
  name,
  control,
  rules,
  ...props
}: ICountrySelect) => {
  const { field } = useController({
    name,
    control,
  });

  const { t } = useTranslation();
  const country = field.value;

  return (
    <SelectHookForm
      {...props}
      name={name}
      label={t('CountrySelect:label')}
      control={control}
      rules={
        rules || {
          required: t('CountrySelect:error_required'),
        }
      }
      options={countriesForReactSelect}
      value={countriesForReactSelect.find(({ value }) => value === country)}
    />
  );
};
