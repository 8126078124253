import { useCallback } from 'react';

import api from 'lib/api';

import { useAccountConnectorsStatus } from './AccountConnectors.hooks';
import { IUseAccountConnector } from './AccountConnectors.interface';
import { useCheckWindowOpened } from './useCheckWindowOpened';

export const useSharedHandlers = ({
  provider,
  businessId,
  onSuccess,
}: IUseAccountConnector) => {
  const { startConnection, endConnection, addError } =
    useAccountConnectorsStatus();

  const handleSuccess = useCallback(
    async (publicToken: string) => {
      try {
        await api.connectMerchantBusiness(provider, businessId, publicToken);
        endConnection();
        onSuccess && onSuccess();
      } catch (e) {
        addError();
      }
    },
    [businessId, provider, onSuccess, endConnection, addError]
  );

  const handleClosed = useCallback(() => {
    endConnection();
  }, [endConnection]);

  const { setWindowRef } = useCheckWindowOpened({
    onClosed: handleClosed,
  });

  return {
    handleSuccess,
    handleClosed,
    setWindowRef,
    startConnection,
    addError,
  };
};
