import { BaseAnalyticPlugin } from 'lib/analytics/plugins';
import { IUser } from 'lib/types';

export class AnalyticsClient {
  plugins: BaseAnalyticPlugin[] = [];

  addPlugin(plugin: BaseAnalyticPlugin) {
    this.plugins.push(plugin);
    return this;
  }

  async load(): Promise<any[]> {
    return Promise.all(this.plugins.map((plugin) => plugin.load.call(plugin)));
  }

  async identify(user: IUser): Promise<any[]> {
    return Promise.all(
      this.plugins.map((plugin) => plugin.identify?.call(plugin, user))
    );
  }

  page(): Promise<any[]> {
    return Promise.all(this.plugins.map((plugin) => plugin.page?.call(plugin)));
  }

  reset(): Promise<any[]> {
    return Promise.all(
      this.plugins.map((plugin) => plugin.reset?.call(plugin))
    );
  }

  track(eventName: string, eventParams?: any): Promise<any[]> {
    return Promise.all(
      this.plugins.map((plugin) =>
        plugin.track?.call(plugin, eventName, eventParams)
      )
    );
  }
}
