import { ConfidentialityAgreement } from 'components/ConfidentialityAgreement';
import { SlateBackgroundBody } from 'components/GlobalStyles';

import { HSWidgetHide } from 'lib/hubspot';

export const ConfidentialityAgreementScreen = () => {
  return (
    <>
      <HSWidgetHide />
      <SlateBackgroundBody />
      <ConfidentialityAgreement />
    </>
  );
};
