import { motion } from 'framer-motion';

import { keyframes, styled } from '@boopos/design-system';

import BackgroundPattern from 'assets/illustrations/background-squares-pattern.svg';

const spin = keyframes({
  from: { transform: 'translateX(50%) translateY(-50%) rotate(0deg)' },
  to: { transform: 'translateX(50%) translateY(-50%) rotate(360deg)' },
});

const OnboardingPattern = {
  Wrapper: styled(motion.div, {
    position: 'fixed',
    top: '50%',
    right: 0,
    zIndex: -1,
    animation: `${spin} 60s linear infinite`,
  }),
};

const animationVariants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
  },
};

export const OnboardingBackgroundPattern = () => (
  <OnboardingPattern.Wrapper
    initial="hidden"
    animate="visible"
    exit="hidden"
    variants={animationVariants}
  >
    <img src={BackgroundPattern} alt="Background pattern" />
  </OnboardingPattern.Wrapper>
);
