import { Trans } from 'react-i18next';

import { Box } from '@boopos/design-system';

export const InfoMemoDescription = () => {
  return (
    <Box css={{ mt: '$2' }}>
      <Trans i18nKey="ListingDetail:information_memorandum_description">
        <strong />
      </Trans>
    </Box>
  );
};
