import React from 'react';
import { Navigate } from 'react-router-dom';

import { useMatchFromTo } from './RedirectionHandler.hooks';

type Redirections = string[][];

export const RedirectionHandling = ({
  from,
  to,
}: {
  from: string;
  to: string;
}) => {
  const route = useMatchFromTo(from, to);

  if (!route) {
    return null;
  }

  return <Navigate to={route} replace />;
};

export const generateRedirectionChild = ({
  redirections,
}: {
  redirections: Redirections;
}) =>
  redirections
    .map(([from, to]) =>
      React.createElement(RedirectionHandling, {
        key: `${from}-${to}`,
        from,
        to,
      })
    )
    .find((child) => !!child.type(child.props));
