import Skeleton from 'react-loading-skeleton';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NavigationCardProps } from './NavigationCard.interface';
import { NavigationCardStyles } from './NavigationCard.styles';

export const NavigationCardChild = ({
  title,
  description,
  icon,
  tag,
  actionIcon = (
    <FontAwesomeIcon icon={regular('arrow-right')} size="lg" fixedWidth />
  ),
  loadingIcon = (
    <FontAwesomeIcon
      icon={regular('arrow-rotate-right')}
      size="lg"
      fixedWidth
    />
  ),
  loading,
  showActionIcon = true,
  ...props
}: NavigationCardProps) => (
  <NavigationCardStyles.Wrapper {...props}>
    {tag && (
      <NavigationCardStyles.TagContainer>
        {tag}
      </NavigationCardStyles.TagContainer>
    )}
    <NavigationCardStyles.Container>
      <NavigationCardStyles.Content>
        {icon && (
          <NavigationCardStyles.IconContainer>
            {icon}
          </NavigationCardStyles.IconContainer>
        )}
        <NavigationCardStyles.Title>{title}</NavigationCardStyles.Title>
        <NavigationCardStyles.Description>
          {description}
        </NavigationCardStyles.Description>
      </NavigationCardStyles.Content>
      {showActionIcon && (
        <NavigationCardStyles.ArrowContainer loading={loading}>
          {loading ? loadingIcon : actionIcon}
        </NavigationCardStyles.ArrowContainer>
      )}
    </NavigationCardStyles.Container>
  </NavigationCardStyles.Wrapper>
);

export const NavigationCard = Object.assign(NavigationCardChild, {
  Tag: NavigationCardStyles.Tag,
});

export const NavigationCardSkeleton = (
  props?: Partial<NavigationCardProps>
) => (
  <NavigationCard
    showActionIcon={false}
    icon={<Skeleton height={40} width={40} borderRadius="100%" />}
    title={<Skeleton height={24} width={210} borderRadius={8} />}
    description={<Skeleton height={16} borderRadius={8} />}
    {...props}
  />
);
