import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@boopos/design-system';
import { everyItemTrue, formatTimeFrom } from '@boopos/utils';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ProviderImage } from 'components/AccountConnectors';
import { DeleteConfirmationAction } from 'components/ConfirmationModal';

import Api from 'lib/api';

import { ConnectedAccountCardProps } from './ConnectedAccountCard.interface';
import { ConnectedAccountCardStyles } from './ConnectedAccountCard.styles';

export const ConnectedAccountCard = ({
  account,
  onDelete,
}: ConnectedAccountCardProps) => {
  const { t } = useTranslation();

  const { provider, businessId, externalId, connectedByMe } = account || {};

  const handleDisconnect = useCallback(async () => {
    if (!everyItemTrue([businessId, provider, externalId])) {
      return;
    }
    try {
      await Api.merchantsDisconnectAccount({
        provider: provider as string,
        businessId: businessId as string,
        externalId: externalId as string,
      });
      onDelete?.();
    } catch (err) {
      console.log(err);
    }
  }, [onDelete, businessId, provider, externalId]);

  const showDeleteButton: boolean =
    (typeof connectedByMe === 'undefined' || connectedByMe) && !!onDelete;

  if (!account) {
    return null;
  }

  return (
    <ConnectedAccountCardStyles.Card>
      <ConnectedAccountCardStyles.ImageWrapper>
        <ProviderImage provider={account.provider} />
      </ConnectedAccountCardStyles.ImageWrapper>
      <ConnectedAccountCardStyles.Info>
        <ConnectedAccountCardStyles.AccountName>
          {account.name}
        </ConnectedAccountCardStyles.AccountName>
        <ConnectedAccountCardStyles.Bottom>
          <ConnectedAccountCardStyles.Status variant="success">
            {t('ConnectedAccountCard:connected')}
          </ConnectedAccountCardStyles.Status>
          {!!account.createdAt && (
            <ConnectedAccountCardStyles.Time>
              {formatTimeFrom({ date: dayjs(account.createdAt).toDate() })}
            </ConnectedAccountCardStyles.Time>
          )}
        </ConnectedAccountCardStyles.Bottom>
      </ConnectedAccountCardStyles.Info>

      {showDeleteButton && (
        <ConnectedAccountCardStyles.DeleteWrapper>
          <DeleteConfirmationAction
            title={t('ConnectedAccountCard:delete_confirmation')}
            onDelete={handleDisconnect}
          >
            <Button
              variant="secondary"
              rounded
              onClick={handleDisconnect}
              data-testid="disconnect-account-button"
              leftIcon={<FontAwesomeIcon icon={regular('trash')} size="lg" />}
            />
          </DeleteConfirmationAction>
        </ConnectedAccountCardStyles.DeleteWrapper>
      )}
    </ConnectedAccountCardStyles.Card>
  );
};
