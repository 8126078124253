import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { CenteredContentScreen } from 'ui/CenteredContentScreen';

import { BackButton } from 'components/BackButton';
import { usePortfolioBackButton } from 'components/PortfolioBackButton';
import { RequestGrowthLoanForm } from 'components/RequestLoanForms';
import { AnimatedScreenOpacityWrapper } from 'components/ScreenLayout';

export const PortfolioRequestGrowthLoanScreen = () => {
  const { t } = useTranslation();
  const { backButtonUrl, businessName } = usePortfolioBackButton();
  const title = t('RequestGrowthLoanScreen:title');

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AnimatedScreenOpacityWrapper>
        <CenteredContentScreen.Wrapper>
          <BackButton to={backButtonUrl} hideOnDesktop={false} topSpace>
            {businessName}
          </BackButton>
          <CenteredContentScreen.Content>
            <CenteredContentScreen.Title>{title}</CenteredContentScreen.Title>
            <CenteredContentScreen.Description>
              {t('RequestGrowthLoanScreen:description')}
            </CenteredContentScreen.Description>
            <RequestGrowthLoanForm />
          </CenteredContentScreen.Content>
        </CenteredContentScreen.Wrapper>
      </AnimatedScreenOpacityWrapper>
    </>
  );
};
