import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';

import { Button } from '@boopos/design-system';

import { FlowStatusFeedback } from 'ui/FlowStatusFeedback';

import IntegrationsDraw from 'assets/illustrations/integrations.svg';

import { routePaths } from 'router/routePaths';

export const BusinessIntegrationsEmptyState = ({
  businessId,
}: {
  businessId: string;
}) => {
  const { t } = useTranslation();

  return (
    <FlowStatusFeedback
      data-testid="integrations-empty-state"
      title={t('BusinessIntegrationsScreen:zero_results_title')}
      description={t('BusinessIntegrationsScreen:zero_results_subtitle')}
      illustration={
        <img
          src={IntegrationsDraw}
          alt={t('BusinessIntegrationsScreen:zero_results_title')}
        />
      }
      fullWidth={false}
      showBackground
      titleBig
    >
      <Button
        fullWidth
        as={Link}
        to={generatePath(routePaths.portfolioBusinessAccounts, {
          businessId,
        })}
      >
        {t('BusinessIntegrationsScreen:zero_results_action')}
      </Button>
    </FlowStatusFeedback>
  );
};
