import { Trans, useTranslation } from 'react-i18next';

import { AcceptedFilesEnum } from 'components/UploadZone/UploadZone.interface';

import config from 'lib/config';
import { LoanDocumentType } from 'lib/types';
import { useStopPropagation } from 'lib/useStopPropagation';

import { BasicDocumentsUploadModal } from './BasicDocumentsUploadModal';

export const ProfitAndLossUploadModal = ({
  basePath,
}: {
  basePath: string;
}) => {
  const { t } = useTranslation();
  const stopPropagation = useStopPropagation();

  const props = {
    title: t('BasicDocumentsUploadModal:profit_and_loss_title'),
    subtitle: (
      <Trans i18nKey="BasicDocumentsUploadModal:profit_and_loss_subtitle">
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a
          href={config.PNL_SAMPLE_URL}
          target="_blank"
          onClick={stopPropagation}
          rel="noreferrer"
        />
      </Trans>
    ),
    documentType: LoanDocumentType.profitAndLoss,
    acceptedFiles: [
      AcceptedFilesEnum.csv,
      AcceptedFilesEnum.xls,
      AcceptedFilesEnum.xlsx,
    ],
  };

  return <BasicDocumentsUploadModal basePath={basePath} {...props} />;
};
