export const searchInList = <T>(
  list: T[],
  config: { key: keyof T; search: string }
) => {
  const normalizedSearch = config.search
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

  return list.filter((item) => {
    const itemKey = item[config.key] as string;
    const normalizedItem = itemKey
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();

    return normalizedItem.includes(normalizedSearch);
  });
};
