import { useCallback } from 'react';
import { useController } from 'react-hook-form';

import { Slider, SliderProps } from 'ui/Slider';

interface SliderHookFormProps extends SliderProps {
  name: string;
  control: any;
}

export const SliderHookForm = ({
  name,
  control,
  min,
  max,
  step,
  defaultValue,
}: SliderHookFormProps) => {
  const { field } = useController({
    defaultValue,
    name,
    control,
  });

  const handleOnChange = useCallback(
    (value: number[]) => {
      field.onChange(value);
    },
    [field]
  );

  return (
    <Slider
      name={name}
      value={field.value}
      onValueChange={handleOnChange}
      min={min}
      max={max}
      step={step}
      minStepsBetweenThumbs={1}
    />
  );
};
