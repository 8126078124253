import { IApiDocument, IApiFile, LoanDocumentType } from 'lib/types';

export const getDocumentFiles = (
  documentType: LoanDocumentType | string,
  documents: IApiDocument[] | undefined
): IApiFile[] =>
  (documents || []).find((document) => document.type === documentType)?.files ||
  [];

export const getCurrentOrEmptyDocument = (
  documentType: LoanDocumentType | string,
  documents: IApiDocument[] | undefined
): IApiDocument | Omit<IApiDocument, 'id'> =>
  (documents || []).find((document) => document.type === documentType) || {
    files: [],
    type: documentType as LoanDocumentType,
  };
