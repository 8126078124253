import { routePaths } from 'router/routePaths';

import { ConfidentialityAgreementScreen } from 'screens/ConfidentialityAgreementScreen';
import { ConfidentialityAgreementFullScreen } from 'screens/ConfidentialityAgreementScreen/ConfidentialityAgreementFullScreen';

export const confidentialityAgreementRoutes = [
  {
    path: routePaths.confidentialityAgreement,
    element: <ConfidentialityAgreementScreen />,
  },
  {
    path: routePaths.confidentialityAgreementFull,
    element: <ConfidentialityAgreementFullScreen />,
  },
];
