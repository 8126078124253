import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, ButtonFeedback, Input } from '@boopos/design-system';

import { GoBackHeader } from './Header';
import { OtherBusinessTypeForm } from './InvestmentCriteria.interface';
import { FullScreenFunnelLayout } from './InvestmentCriteriaScreen.styles';
import { useInvestmentCriteriaNavigation } from './hooks/useInvestmentCriteriaNavigation';
import { useSaveInvestmentCriteria } from './hooks/useSaveInvestmentCriteria';

export const InvestmentCriteriaOtherBusinessScreen = () => {
  const { t } = useTranslation();
  const { exitPath, navigateToNextPath } = useInvestmentCriteriaNavigation();

  const { loading, success, investmentCriteria, save } =
    useSaveInvestmentCriteria({
      onSave: navigateToNextPath,
    });

  const { register, watch, handleSubmit } = useForm<OtherBusinessTypeForm>({
    defaultValues: {
      otherBusinessType: investmentCriteria?.otherBusinessTypes?.[0],
    },
  });

  const otherBusinessType = watch('otherBusinessType');

  const onSave = useCallback(
    (data: OtherBusinessTypeForm) => {
      save({
        ...investmentCriteria,
        otherBusinessTypes: [data.otherBusinessType],
      });
    },
    [investmentCriteria, save]
  );

  return (
    <>
      <GoBackHeader />
      <FullScreenFunnelLayout.Content data-testid="business-type-other-screen">
        <FullScreenFunnelLayout.Steps>
          {t('InvestmentCriteria:steps_number', { from: 1, to: 4 })}
        </FullScreenFunnelLayout.Steps>
        <FullScreenFunnelLayout.Title>
          {t('InvestmentCriteria:other_business_title')}
        </FullScreenFunnelLayout.Title>

        <form onSubmit={handleSubmit(onSave)}>
          <FullScreenFunnelLayout.FormContainer>
            <Input
              label={t('InvestmentCriteria:other_business_label')}
              {...register('otherBusinessType', { required: true })}
            />
          </FullScreenFunnelLayout.FormContainer>
          <FullScreenFunnelLayout.CallToAction>
            <ButtonFeedback
              fullWidth
              type="submit"
              disabled={!otherBusinessType}
              isLoading={loading}
              isSuccess={success}
            >
              {t('InvestmentCriteria:continue')}
            </ButtonFeedback>

            <Button variant="tertiary" fullWidth as={Link} to={exitPath}>
              {t('InvestmentCriteria:leave_for_later')}
            </Button>
          </FullScreenFunnelLayout.CallToAction>
        </form>
      </FullScreenFunnelLayout.Content>
    </>
  );
};
