import { useTranslation } from 'react-i18next';

import { InlineMessage } from 'ui/InlineMessage';

import { SellerTypeRender } from 'components/ListingDetail/SellerTypeRender';

import { ListingForSaleReduced, LoanAffordability } from 'lib/types';

const BrokerFeedback = ({ listing }: { listing: ListingForSaleReduced }) => {
  const { t } = useTranslation();

  if (listing?.dealApplication?.grantedAt) {
    return (
      <InlineMessage
        heading={t('ForSaleCardFeedback:broker_granted_title')}
        message={t('ForSaleCardFeedback:broker_granted_message')}
        theme="elevated"
        action="primary"
        type="actionRequired"
        data-testid="feedback-terms-ready"
      />
    );
  }

  if (listing?.dealApplication?.requestedAt) {
    return (
      <InlineMessage
        heading={t('ForSaleCardFeedback:broker_requested_title')}
        message={t('ForSaleCardFeedback:broker_requested_message')}
        theme="elevated"
        data-testid="feedback-applied"
      />
    );
  }

  if (listing.loanAffordability === LoanAffordability.notAffordable) {
    return (
      <InlineMessage
        heading={t('ForSaleCard:feedback_not_financial_fit_heading')}
        message={t('ForSaleCard:feedback_not_financial_fit_message')}
        theme="elevated"
        data-testid="feedback-outside-lending"
      />
    );
  }

  return (
    <InlineMessage
      heading={t('ForSaleCardFeedback:broker_base_title')}
      message={t('ForSaleCardFeedback:broker_base_message')}
      theme="elevated"
      action="primary"
      type="actionRequired"
      data-testid="feedback-default"
    />
  );
};

const ProprietaryFeedback = ({
  listing,
}: {
  listing: ListingForSaleReduced;
}) => {
  const { t } = useTranslation();

  if (listing?.dealApplication?.grantedAt) {
    if (listing?.termSheet?.value) {
      return (
        <InlineMessage
          heading={t('ForSaleCardFeedback:proprietary_granted_terms_title')}
          message={t('ForSaleCardFeedback:proprietary_granted_terms_message')}
          theme="elevated"
          action="primary"
          type="actionRequired"
          data-testid="feedback-terms-ready"
        />
      );
    }
    return (
      <InlineMessage
        heading={t('ForSaleCardFeedback:proprietary_granted_title')}
        message={t('ForSaleCardFeedback:proprietary_granted_message')}
        theme="elevated"
        action="primary"
        type="actionRequired"
        data-testid="feedback-applied"
      />
    );
  }

  if (listing?.dealApplication?.requestedAt) {
    return (
      <InlineMessage
        heading={t('ForSaleCardFeedback:proprietary_requested_title')}
        message={t('ForSaleCardFeedback:proprietary_requested_message')}
        theme="elevated"
        data-testid="feedback-applied"
      />
    );
  }

  if (listing.loanAffordability === LoanAffordability.notAffordable) {
    return (
      <InlineMessage
        heading={t('ForSaleCard:feedback_not_financial_fit_heading')}
        message={t('ForSaleCard:feedback_not_financial_fit_message')}
        theme="elevated"
        data-testid="feedback-outside-lending"
      />
    );
  }

  return (
    <InlineMessage
      heading={t('ForSaleCardFeedback:proprietary_base_title')}
      message={t('ForSaleCardFeedback:proprietary_base_message')}
      theme="elevated"
      action="primary"
      type="actionRequired"
      data-testid="feedback-default"
    />
  );
};

export const ForSaleCardFeedback = ({
  listing,
}: {
  listing: ListingForSaleReduced;
}) => {
  return (
    <>
      <SellerTypeRender.Proprietary sellerType={listing.sellerType}>
        <ProprietaryFeedback listing={listing} />
      </SellerTypeRender.Proprietary>
      <SellerTypeRender.Broker sellerType={listing.sellerType}>
        <BrokerFeedback listing={listing} />
      </SellerTypeRender.Broker>
    </>
  );
};
