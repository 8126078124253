import { useEffect } from 'react';

import { ON_APP_READY_EVENT } from 'lib/constants';
import { publish } from 'lib/events';

export const BooposAnimatedLogoHandler = () => {
  useEffect(() => {
    publish(ON_APP_READY_EVENT);
  }, []);

  return null;
};
