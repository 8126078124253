import { ListCardLoading } from 'ui/ListCard';

export const ForSaleListLoading = () => {
  return (
    <div data-testid="for-sale-list-loading">
      <ListCardLoading />
      <ListCardLoading />
    </div>
  );
};
