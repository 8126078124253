import { useTranslation } from 'react-i18next';

import { RedactedField } from 'lib/types';

export const BusinessAge = ({
  businessAge,
}: {
  businessAge?: RedactedField<string>;
}) => {
  const { t } = useTranslation();

  if (businessAge?.redacted) {
    return <>{businessAge?.value}</>;
  }

  const numericValue = parseInt(businessAge?.value as string, 10);

  const isNumeric = numericValue === +(businessAge?.value ?? 0);

  if (isNumeric) {
    return (
      <>
        {t('BusinessOverview:years', {
          count: numericValue,
        })}
      </>
    );
  }

  return <>{businessAge?.value}</>;
};
