import { Children } from 'react';

import { BaseTermSheet } from './BaseTermSheet';
import { ExternalTermSheet } from './ExternalTermSheet';
import { NoTermSheet } from './NoTermSheet';
import { TermSheetByType } from './TermSheetByType';
import type {
  BaseProps,
  TermSheetHandlerProps,
} from './TermSheetHandler.types';
import { TermSheetHandlerInternal } from './TermSheetHandler.types';

const byInternalTermSheetHandler = (
  props: BaseProps,
  type: TermSheetHandlerInternal
) => props?.__internalTermSheetHandler === type;

const TermSheetHandlerParent = ({
  children,
  termSheet,
}: TermSheetHandlerProps) => {
  const validChild = Children.map(children, (c) => {
    return c;
  });

  const NoTermSheetChildren = validChild.find((c) =>
    byInternalTermSheetHandler(c?.props, TermSheetHandlerInternal.NoTermSheet)
  );

  const BaseTermSheetChildren = validChild.find((c) => {
    return byInternalTermSheetHandler(
      c?.props,
      TermSheetHandlerInternal.BaseTermSheet
    );
  });

  const ByTypeChildren = validChild.find(
    (c) =>
      byInternalTermSheetHandler(c?.props, TermSheetHandlerInternal.ByTpe) &&
      c?.props?.type === termSheet?.type
  );

  if (!termSheet?.type) {
    return NoTermSheetChildren ?? null;
  }

  return ByTypeChildren ?? BaseTermSheetChildren ?? null;
};

export const TermSheetHandler = Object.assign(TermSheetHandlerParent, {
  Empty: NoTermSheet,
  External: ExternalTermSheet,
  Base: BaseTermSheet,
  ByType: TermSheetByType,
});
