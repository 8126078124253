import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Box, theme } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PortfolioApplicationLayout } from 'components/PortfolioApplication/PortfolioApplicationLayout';

import { PartialsProps } from './Partials.interface';

export const SentForEvaluation = ({ children }: PartialsProps) => {
  const { t } = useTranslation();

  const title = t('PortfolioApplicationFinished:sent_for_evaluation_title');
  const description = (
    <>
      <Box css={{ mb: '$4' }}>
        {t('PortfolioApplicationFinished:sent_for_evaluation_description')}
      </Box>
      <div>
        {t(
          'PortfolioApplicationFinished:sent_for_evaluation_description_second'
        )}
      </div>
    </>
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PortfolioApplicationLayout
        title={title}
        subtitle={description}
        preTitle={
          <div>
            <FontAwesomeIcon
              icon={regular('check-circle')}
              size="4x"
              color={theme.colors.infoPositive.value}
            />
          </div>
        }
        contentWrapperProps={{
          'data-testid': 'sent-for-evaluation-screen',
        }}
      >
        {children}
      </PortfolioApplicationLayout>
    </>
  );
};
