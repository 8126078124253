import { useParams } from 'react-router-dom';

import { formatMoney } from 'lib/format';
import { useBusinessInsightsLoan } from 'lib/hooks/useBusinessInsightsLoan';

export const useSellBusinessBanner = () => {
  const { businessId } = useParams() ?? {};
  const { insightsLoan } = useBusinessInsightsLoan({ businessId });

  const termSheet = insightsLoan?.termSheet;
  const financingAmount = formatMoney(termSheet?.amount);

  return {
    financingAmount,
  };
};
