import { YearlyGrowthDataEntry } from './InsightsCharts.interface';
import { YearlyGrowthComparisonChart } from './YearlyGrowthComparisonChart';
import { YearlyGrowthTooltip } from './YearlyGrowthTooltip';

export const ProfitGrowthChart = ({
  data,
}: {
  data: YearlyGrowthDataEntry[];
}) => {
  return (
    <YearlyGrowthComparisonChart
      data={data}
      unit="percentage"
      renderTooltip={(props) => (
        <YearlyGrowthTooltip unit="percentage" {...props} />
      )}
    />
  );
};
