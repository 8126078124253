import { generatePath, useOutlet, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { EnquiriesList } from 'components/EnquiriesList';
import { EnquiryCardLoading } from 'components/EnquiryCard';
import { LayoutSidebarDetail } from 'components/LayoutSidebarDetail';
import { ListingSaleProcess } from 'components/ListingSaleProcess';
import { PageModalWrapper } from 'components/Modal';

import { apiV2 } from 'lib/api';
import { useInfiniteScroll } from 'lib/hooks/useInfiniteScroll';
import { usePaginatedResponse } from 'lib/hooks/usePaginatedResponse';
import { EnquiryApi, ForSaleApplicationApi } from 'lib/types';

import { routePaths } from 'router/routePaths';

import { EmptyState } from './EmptyState/EmptyState';

export const EnquiriesScreenList = () => {
  const params = useParams();
  const { businessId } = params ?? {};

  const { data: sellApplication } = useSWR<ForSaleApplicationApi>(
    businessId && apiV2.businessSellApplication(businessId)
  );

  const listingId = sellApplication?.listing?.id;

  const {
    data,
    error,
    isEmpty,
    isFirstLoading,
    isReachingEnd,
    loadNextPage,
    mutate: handleRefreshEnquiries,
  } = usePaginatedResponse<EnquiryApi>(apiV2.getListingEnquiries, {
    fetcher: apiV2.resolvePaginated.bind(apiV2),
    queryParams: {
      listingId,
    },
  });

  const goBackURL = generatePath(routePaths.portfolioEnquiries, { businessId });

  const outlet = useOutlet({
    goBackURL,
    handleRefreshEnquiries,
  });

  const lastElementRef = useInfiniteScroll(isReachingEnd, loadNextPage);

  if (data?.length) {
    return (
      <LayoutSidebarDetail.PageWrapper>
        <LayoutSidebarDetail.Wrapper data-testid="enquiries-screen-list">
          <LayoutSidebarDetail.ListContent>
            <EnquiriesList
              enquiries={data}
              businessId={businessId}
              lastElementRef={lastElementRef}
            />
          </LayoutSidebarDetail.ListContent>
          <LayoutSidebarDetail.Sidebar>
            <ListingSaleProcess />
          </LayoutSidebarDetail.Sidebar>
        </LayoutSidebarDetail.Wrapper>
        <PageModalWrapper>{outlet}</PageModalWrapper>
      </LayoutSidebarDetail.PageWrapper>
    );
  }

  if (isFirstLoading) {
    return (
      <LayoutSidebarDetail.PageWrapper data-testid="enquiries-screen-loading">
        <LayoutSidebarDetail.Wrapper>
          <LayoutSidebarDetail.ListContent>
            <EnquiryCardLoading />
            <EnquiryCardLoading />
            <EnquiryCardLoading />
          </LayoutSidebarDetail.ListContent>
        </LayoutSidebarDetail.Wrapper>
      </LayoutSidebarDetail.PageWrapper>
    );
  }

  if (isEmpty) {
    return (
      <LayoutSidebarDetail.PageWrapper data-testid="enquiries-screen-empty">
        <EmptyState />
      </LayoutSidebarDetail.PageWrapper>
    );
  }

  if (error) {
    return (
      <LayoutSidebarDetail.PageWrapper data-testid="enquiries-screen-error">
        Error
      </LayoutSidebarDetail.PageWrapper>
    );
  }

  return null;
};
