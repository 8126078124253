import React from 'react';

import { ContentByStatus } from './ContentByStatus';
import { Header } from './Header';
import { ListingDetailsProps } from './ListingDetails.interface';

export const ListingDetails = ({ sellApplication }: ListingDetailsProps) => {
  if (!sellApplication?.status) {
    return null;
  }

  const Content = ContentByStatus[sellApplication?.status]
    ? ContentByStatus[sellApplication.status]
    : null;

  if (!Content) {
    return null;
  }

  return (
    <>
      <Header />
      <Content sellApplication={sellApplication} />
    </>
  );
};
