import { styled } from '@boopos/design-system';

export const Styles = {
  Wrapper: styled('div', {
    color: '$textBodySecondary',
  }),
  Item: styled('div', {
    display: 'flex',
    alignItems: 'center',
    color: '$textBodySecondary',
    textDecoration: 'none',
    variants: {
      isLink: {
        true: {
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
      blur: {
        true: {
          pointerEvents: 'none',
        },
      },
    },
  }),
  ItemIcon: styled('div', {
    lineHeight: 0,
    marginRight: '$2',
  }),
  ItemLiteral: styled('div', {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    variants: {
      blur: {
        true: {
          filter: 'blur(4px)',
          pointerEvents: 'none',
        },
      },
    },
  }),
  FileVisibility: styled('span', {
    ml: '1.6rem',
    color: '$textBodyTertiary',
    display: 'inline-block',
    fontSize: '1.4rem',
    lineHeight: '2.4rem',
  }),
};
