import { styled } from '@boopos/design-system';

export const Form = styled('form', {
  flex: '1 1 0%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export const Content = styled('div', {
  px: '$4',
  mb: '$6',
});

export const Footer = styled('div', {
  position: 'sticky',
  bottom: 0,
  p: '$4',
  background: '$white',
  borderTop: '1px solid $slate100',
  zIndex: '$1',

  '@bp2': { p: '$6' },
});
