import { useCallback, useMemo } from 'react';
import useSWRInfinite from 'swr/infinite';

import api from 'lib/api';
import { IPaginatedResponseApi } from 'lib/types';

import {
  IGetUrl,
  IPaginatedResponseProps,
  IUsePaginatedResponseReturn,
} from './usePaginatedResponse.interface';
import { emptyArray, paginate } from './usePaginatedResponse.utils';

const NUMBER_OF_PAGES = 15;

/**
 * Custom hook for managing paginated responses from the Boopos API.
 *
 * @param getServiceURL - the function for getting the URL that will be requested {@link IGetUrl}
 * @param props - some configuration props {@link IPaginatedResponseProps}
 *
 * @returns params - some variables to be used for rendering the requested data. {@link IUsePaginatedResponseReturn}
 *
 * @example
 * ```tsx
 * interface IResponseExample {
 *     id: string
 * }
 *
 * const getServiceURL = ({ page, limit }) {
 *     return `/custom-api-service?page=${page}&limit=${limit}`
 * }
 *
 * const { data } = usePaginatedResponse<IResponseExample>(getServiceURL)
 *```
 */
const usePaginatedResponse = <ResponseObject, QueryParams extends {} = any>(
  getServiceURL: IGetUrl<QueryParams>,
  props?: IPaginatedResponseProps<QueryParams>
): IUsePaginatedResponseReturn<ResponseObject> => {
  const {
    fetcher,
    queryParams,
    limit = NUMBER_OF_PAGES,
    swrConfig,
  } = props || {};

  const { data, size, isValidating, error, setSize, mutate } = useSWRInfinite<
    IPaginatedResponseApi<ResponseObject>
  >(
    paginate<ResponseObject, QueryParams>(getServiceURL, queryParams, limit),
    fetcher || api.swrFetch.bind(api),
    swrConfig
  );

  const safeData = data || emptyArray;
  const [firstPage] = safeData;
  const isReachingEnd = firstPage?.totalPages === size;
  const isRefreshing = isValidating && data?.length === size;
  const isFirstLoading = typeof data === 'undefined';
  const isEmpty = !firstPage?.docs?.length && !error;

  const loadNextPage = useCallback(async () => {
    await setSize((currentSize) => currentSize + 1);
  }, [setSize]);

  const plainData = useMemo<ResponseObject[]>(
    () =>
      safeData.reduce(
        (acc, paginatedResponse) => [...acc, ...(paginatedResponse.docs || [])],
        emptyArray
      ),
    [safeData]
  );

  return {
    data: plainData,
    error,
    isEmpty,
    isFirstLoading,
    isReachingEnd,
    isRefreshing,
    loadNextPage,
    mutate,
  };
};

export { usePaginatedResponse };
