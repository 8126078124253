import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@boopos/design-system';

import { SellerTypeRender } from 'components/ListingDetail/SellerTypeRender';
import { useListingUnlockCapture } from 'components/ListingUnlock';

import { ListingForSale } from 'lib/types';
import { generatePathSafe } from 'lib/utils/generatePathSafe';

import { routePaths } from 'router/routePaths';

import { useActionsDisabled } from './ListingActions.utils';

export interface RequestFullDetailsActionProps {
  listing?: ListingForSale;
}

const useRequestFullDetailsAction = (listing?: ListingForSale) => {
  const disabled = useActionsDisabled(listing);

  const active = !listing?.dealApplication?.requestedAt;

  return {
    disabled,
    active,
  };
};

export const RequestFullDetailsAction = ({
  listing,
}: RequestFullDetailsActionProps) => {
  const { t } = useTranslation();

  const { disabled, active } = useRequestFullDetailsAction(listing);

  const requestFullDetailsUrl = generatePathSafe(
    routePaths.forSaleRequestFullDetails,
    {
      listingId: listing?.id,
    }
  );

  const actionProps = useListingUnlockCapture(disabled, {
    requiresAgreement: listing?.requiresAgreement,
    originalProps: {
      to: requestFullDetailsUrl,
    },
  });

  if (!listing || !active) {
    return null;
  }

  return (
    <Button
      fullWidth
      as={Link}
      data-testid="request-full-details"
      className="sidebar-primary"
      {...actionProps}
    >
      <SellerTypeRender.Broker sellerType={listing?.sellerType}>
        {t('ForSaleDetail:request_details_broker')}
      </SellerTypeRender.Broker>
      <SellerTypeRender.Proprietary sellerType={listing?.sellerType}>
        {t('ForSaleDetail:request_details_proprietary')}
      </SellerTypeRender.Proprietary>
    </Button>
  );
};
