import { useTranslation } from 'react-i18next';

import { Box, ButtonFeedback } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ShareSellingDealButton } from 'components/ShareSellingDealButton';

import { useDownloadTermsheet } from 'lib/hooks/useDownloadTermsheet';

import { TermSheetStaticSummary } from './TermSheetStaticSummary';
import { ITermSheetSummary } from './TermSheetSummary.interface';
import { TermSheetSummaryFooter } from './TermSheetSummaryFooter';

export const TermSheetSummary = (props: ITermSheetSummary) => {
  const { t } = useTranslation();
  const { loan, businessName, goBackUrl } = props;
  const { id: loanId, isSellerSaleLoan } = loan ?? {};

  const [handleDownload, termsheetLoading] = useDownloadTermsheet(
    loanId,
    businessName
  );

  const appendChildren = (
    <>
      <Box css={{ mt: '$6' }}>
        <ButtonFeedback
          variant="secondary"
          fullWidth
          leftIcon={<FontAwesomeIcon icon={regular('down-to-bracket')} />}
          onClick={handleDownload}
          data-testid="term-sheet-download-button"
          isLoading={termsheetLoading}
        >
          {t('TermSheetSummary:download')}
        </ButtonFeedback>
      </Box>
      <Box css={{ mt: '$6' }}>
        <TermSheetSummaryFooter
          loanId={loanId}
          goBackUrl={goBackUrl ?? ''}
          isSellerSaleLoan={isSellerSaleLoan}
        />
      </Box>
      {loan?.isSellerSaleLoan && (
        <Box css={{ mt: '$6' }}>
          <ShareSellingDealButton
            loan={loan}
            data-testid="share-selling-deal-button"
          />
        </Box>
      )}
    </>
  );
  return <TermSheetStaticSummary {...props} appendChildren={appendChildren} />;
};
