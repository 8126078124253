import { MotionProps } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { formatDateBasedOnHoursDiff } from '@boopos/utils';

import { Timeline } from 'ui/Timeline';

import { DealTimelineStepProps } from './DealTimelineStep.interface';

export const DealTimelineStep = (
  props: DealTimelineStepProps & MotionProps
) => {
  const {
    date,
    title,
    subtitle,
    children,
    isLast,
    errorVariant,
    forceNowVariant,
    ...rest
  } = props;
  const { t } = useTranslation();

  const isNow = !date;
  const dateLiteral = isNow
    ? t('DealTimelineStep:now')
    : formatDateBasedOnHoursDiff(date);

  const isNowVariant = isNow || forceNowVariant;

  const variant = isNowVariant ? 'active' : 'inactive';
  const topSpace = isNowVariant ? 'small' : 'large';

  return (
    <Timeline.Step
      variant={variant}
      topSpace={topSpace}
      showBottomLine={!isLast}
      {...rest}
    >
      <Timeline.Time variant={variant} errorVariant={errorVariant}>
        {!!errorVariant && <>⚠ </>}
        {dateLiteral}
      </Timeline.Time>
      <Timeline.Title variant={variant}>{title}</Timeline.Title>
      {!!subtitle && <Timeline.Subtitle>{subtitle}</Timeline.Subtitle>}
      {!!children && <Timeline.Content>{children}</Timeline.Content>}
    </Timeline.Step>
  );
};
