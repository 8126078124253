import { forwardRef } from 'react';

import { FilterItemProps } from './FilterItem.interface';
import { FilterItemStyles } from './FilterItem.styles';

const FilterItemChildren = forwardRef<HTMLButtonElement, FilterItemProps>(
  ({ children, prevChildren, postChildren, ...props }, ref) => {
    return (
      <FilterItemStyles.ItemWrapper ref={ref} {...props}>
        {!!prevChildren && <>{prevChildren}</>}
        <div>{children}</div>
        {!!postChildren && <>{postChildren}</>}
      </FilterItemStyles.ItemWrapper>
    );
  }
);

export const FilterItem = Object.assign(FilterItemChildren, {
  ScrollWrapper: FilterItemStyles.ScrollWrapper,
  Wrapper: FilterItemStyles.Wrapper,
  Icon: FilterItemStyles.IconWrapper,
  Hint: FilterItemStyles.HintWrapper,
});
