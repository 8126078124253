import { Trans, useTranslation } from 'react-i18next';

import { ButtonFeedback } from '@boopos/design-system';

import { Banner, BannerProps } from 'ui/Banner';

import { formatMoney } from 'lib/format';
import { Money } from 'lib/types';

export interface BuyerInsightsBuyBannerProps extends BannerProps {
  price?: Money;
  longDescription?: boolean;
  businessInsightsId?: string;
  businessId?: string;
  insightsPath?: string;
  onUnlock?: () => void;
}

export const BuyerInsightsBuyBanner = ({
  price,
  longDescription,
  insightsPath,
  businessInsightsId,
  businessId,
  onUnlock,
  ...rest
}: BuyerInsightsBuyBannerProps) => {
  const { t } = useTranslation();
  const formattedPrice = formatMoney(price);

  const description = longDescription ? (
    <Trans
      i18nKey="BuyerInsights:unlock_description_long"
      values={{ price: formattedPrice }}
    >
      <strong />
    </Trans>
  ) : (
    <Trans
      i18nKey="BuyerInsights:unlock_description"
      values={{ price: formattedPrice }}
    >
      <strong />
    </Trans>
  );

  return (
    <Banner icon={<>🔒</>} description={description} {...rest}>
      <ButtonFeedback data-testid="unlock-buyer-insights" onClick={onUnlock}>
        {t('BuyerInsights:unlock_button')}
      </ButtonFeedback>
    </Banner>
  );
};
