import { useCallback } from 'react';

import { IFile, IUploadedFile } from 'lib/types';

import { uploadFileToS3 } from './uploadFileToS3';

export const useUploadFiles = () => {
  const uploadFile = useCallback((file: IFile): Promise<IUploadedFile> => {
    return uploadFileToS3(file);
  }, []);

  const uploadFiles = useCallback(
    (files: IFile[]): Promise<IUploadedFile[]> => {
      return Promise.all(files.map(uploadFile));
    },
    [uploadFile]
  );

  return {
    uploadFile,
    uploadFiles,
  };
};
