import React, { ReactNode } from 'react';
import { useOutletContext } from 'react-router-dom';

import {
  OnboardingHeader,
  OnboardingLayout,
} from 'components/OnboardingLayout';

import { PortfolioApplicationContext } from './PorfolioApplicationRouteWrapper';

interface PortfolioApplicationLayoutProps {
  children: ReactNode;
  contentWrapperProps?: Record<string, any>;
  preTitle?: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
  asideChildren?: ReactNode;
  allowClose?: boolean;
}

export const PortfolioApplicationLayout = ({
  children,
  preTitle,
  title,
  contentWrapperProps,
  subtitle,
  asideChildren,
  allowClose = true,
}: PortfolioApplicationLayoutProps) => {
  const { closePath, onCloseClick } =
    useOutletContext<PortfolioApplicationContext>();

  return (
    <>
      <OnboardingHeader.Wrapper>
        <OnboardingHeader.ActionsWrapper>
          <OnboardingHeader.Action />
          {!!closePath && allowClose && (
            <OnboardingHeader.Action
              as="a"
              onClick={onCloseClick}
              data-testid="skip-button"
              alignment="right"
            >
              <OnboardingHeader.CloseXMark />
            </OnboardingHeader.Action>
          )}
        </OnboardingHeader.ActionsWrapper>
        {preTitle}
        <OnboardingHeader.Title>{title}</OnboardingHeader.Title>
        {!!subtitle && (
          <OnboardingHeader.Subtitle>{subtitle}</OnboardingHeader.Subtitle>
        )}
      </OnboardingHeader.Wrapper>
      <OnboardingLayout.Content>
        <OnboardingLayout.ContentWrapper {...contentWrapperProps}>
          {children}
        </OnboardingLayout.ContentWrapper>
        {asideChildren}
      </OnboardingLayout.Content>
    </>
  );
};
