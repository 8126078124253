import React from 'react';
import { useTranslation } from 'react-i18next';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { DocumentsDetails } from 'components/DocumentsDetails';
import { FormatUrl } from 'components/FormatUrl';
import { ListingDetailStyles } from 'components/ListingDetail/ListingDetail.styles';
import { ListingInfoContent } from 'components/ListingDetail/ListingInfoContent';
import { ListingInfoItem } from 'components/ListingDetail/ListingInfoItem';

import { IApiFile } from 'lib/types';

import { Styles } from './ListingUnlockedBusinessDetails.styles';

export interface ListingUnlockedBusinessDetailsProps {
  profitAndLossFiles?: IApiFile[];
  cohortsFiles?: IApiFile[];
  businessUrl?: string;
  sellerEmail?: string;
}

export const ListingUnlockedBusinessDetails = ({
  profitAndLossFiles,
  businessUrl,
  sellerEmail,
  cohortsFiles,
}: ListingUnlockedBusinessDetailsProps) => {
  const { t } = useTranslation();

  if (!sellerEmail) {
    return null;
  }

  return (
    <Styles.Wrapper>
      <ListingInfoContent
        icon={regular('paperclip')}
        title={t('ListingDetail:financial_documents')}
      >
        <ListingDetailStyles.InfoGrid>
          <DocumentsDetails
            itemExtraProps={{
              'data-testid': 'unlocked-listing-download-pnl',
            }}
            showLink
            files={profitAndLossFiles}
          >
            {(children) => (
              <ListingInfoItem
                title={t('ListingDetail:profit_and_loss_title')}
                tipHelp={t('ListingDetail:profit_and_loss_hint')}
                variant="body"
              >
                {children}
              </ListingInfoItem>
            )}
          </DocumentsDetails>

          <DocumentsDetails
            showLink
            itemExtraProps={{
              'data-testid': 'unlocked-listing-download-cohorts',
            }}
            files={cohortsFiles}
          >
            {(children) => (
              <ListingInfoItem
                title={t('ListingDetail:cohorts_title')}
                tipHelp={t('ListingDetail:cohorts_hint')}
                variant="body"
              >
                {children}
              </ListingInfoItem>
            )}
          </DocumentsDetails>
        </ListingDetailStyles.InfoGrid>
      </ListingInfoContent>

      <ListingInfoContent
        icon={regular('earth')}
        title={t('ListingDetail:website_title')}
      >
        {!!businessUrl && (
          <FormatUrl
            url={businessUrl}
            target="_blank"
            data-testid="unlocked-listing-url"
          />
        )}
      </ListingInfoContent>

      <ListingInfoContent
        icon={regular('user')}
        title={t('ListingDetail:email_title')}
      >
        <a href={`mailto:${sellerEmail}`} data-testid="unlocked-listing-email">
          {sellerEmail}
        </a>
      </ListingInfoContent>
    </Styles.Wrapper>
  );
};
