import { styled } from '@boopos/design-system';

export const StyledDropPlaceholder = styled('div', {
  position: 'absolute',
  zIndex: '$app1',
  inset: 0,
  background: '$white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Dropzone = styled('div', {
  position: 'relative',
  p: '$4',
});
