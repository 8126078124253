import { ReactNode } from 'react';

import { MerchantType } from 'components/AccountConnectors';

export interface Props {
  invitationAccountType: MerchantType | undefined;
  desiredType: MerchantType | undefined;
  children: ReactNode;
}

export const RenderBasedOnInvitationAccountType = (props: Props) => {
  const { invitationAccountType, desiredType, children } = props;

  if (!invitationAccountType) {
    return <>{children}</>;
  }

  if (invitationAccountType === desiredType) {
    return <>{children}</>;
  }

  return null;
};
