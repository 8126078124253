import { AnimatePresence } from 'framer-motion';
import { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { AccountScreenMenu } from 'components/AccountScreenMenu';
import { LayoutHideMenu } from 'components/Layout';
import { ListScreenLayout } from 'components/ListScreenLayout';
import {
  AnimatedScreenOpacityWrapper,
  SubNavigationContainer,
  SubNavigationContent,
  SubNavigationMenuContainer,
  screenMaxWidthCentered,
} from 'components/ScreenLayout';

import { AccountScreenHeader } from './AccountScreenHeader';

export const AccountScreen = memo(
  ({ renderWrapperElements = true }: { renderWrapperElements?: boolean }) => {
    const { t } = useTranslation();

    return (
      <>
        <Helmet>
          <title>{t('AccountScreen:title')}</title>
        </Helmet>
        <LayoutHideMenu hideOn="all" />
        <AnimatedScreenOpacityWrapper>
          <ListScreenLayout.Wrapper>
            {renderWrapperElements && <AccountScreenHeader />}
            <SubNavigationContainer className={screenMaxWidthCentered()}>
              {renderWrapperElements && (
                <SubNavigationMenuContainer
                  data-testid="menu"
                  css={{ '@bp2': { flex: '0 0 310px' } }}
                >
                  <AccountScreenMenu />
                </SubNavigationMenuContainer>
              )}
              <SubNavigationContent>
                <AnimatePresence>
                  <Outlet />
                </AnimatePresence>
              </SubNavigationContent>
            </SubNavigationContainer>
          </ListScreenLayout.Wrapper>
        </AnimatedScreenOpacityWrapper>
      </>
    );
  }
);
