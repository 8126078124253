import { AnimatePresence } from 'framer-motion';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, ButtonFeedback } from '@boopos/design-system';

import {
  ActionSideModalCommonStyles,
  bottomNavAnimationVariants,
} from 'components/ActionSideModal';
import { PersonFormFields } from 'components/PersonFormFields';

import { IPersonData } from 'lib/types';

export interface PersonDetailFormProps {
  person?: IPersonData;
  formMethods: UseFormReturn<IPersonData>;
  onSubmit: (data: IPersonData) => void;
  onDeletePerson: () => void;
  allowEdit: boolean;
  loanId: string;
}

export const PersonDetailForm = ({
  person,
  formMethods,
  onSubmit,
  onDeletePerson,
  allowEdit,
  loanId,
}: PersonDetailFormProps) => {
  const { t } = useTranslation();

  const { handleSubmit } = formMethods;

  return (
    <>
      {person ? (
        <ActionSideModalCommonStyles.Title>
          {person.firstName} {person.lastName}
        </ActionSideModalCommonStyles.Title>
      ) : (
        <ActionSideModalCommonStyles.Title data-testid="add-new-person-title">
          {t('ShareholdersAndDirectorsModal:new_person')}
        </ActionSideModalCommonStyles.Title>
      )}
      <FormProvider {...formMethods}>
        <ActionSideModalCommonStyles.Form onSubmit={handleSubmit(onSubmit)}>
          <PersonFormFields allFieldsDisabled={!allowEdit} loanId={loanId} />
          {!!person && allowEdit && (
            <Button
              variant="error"
              onClick={onDeletePerson}
              type="button"
              css={{ mt: '4.8rem' }}
              data-testid="delete-person-button"
            >
              {t('ShareholdersAndDirectorsModal:remove_person')}
            </Button>
          )}
          {allowEdit && (
            <AnimatePresence>
              <ActionSideModalCommonStyles.BottomNav
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={bottomNavAnimationVariants}
              >
                <ActionSideModalCommonStyles.BottomNavLeftText />
                <ButtonFeedback>
                  {!!person
                    ? t('ShareholdersAndDirectorsModal:save_changes')
                    : t('ShareholdersAndDirectorsModal:add_person')}
                </ButtonFeedback>
              </ActionSideModalCommonStyles.BottomNav>
            </AnimatePresence>
          )}
        </ActionSideModalCommonStyles.Form>
      </FormProvider>
    </>
  );
};
