import { useTranslation } from 'react-i18next';

import { InsightsChartTooltip } from 'ui/InsightsChartTooltip';
import { TooltipRenderProps } from 'ui/InsightsLineChart';

import { ChangeUnit, ChangeVariant } from './InsightsCharts.interface';
import { calculateChanges } from './utils/calculateChanges';
import { dateLabelShort } from './utils/dateLabelFormatter';
import { moneyValueFormatter } from './utils/moneyValueFormatter';
import { percentValueFormatter } from './utils/percentValueFormatter';

const useChangeRow = ({
  oldestValue,
  newestValue,
  unit,
  positiveVariant,
  negativeVariant,
}: {
  oldestValue: number;
  newestValue: number;
  unit: ChangeUnit;
  negativeVariant?: ChangeVariant;
  positiveVariant?: ChangeVariant;
}) => {
  const { absoluteChange, percentageChange } = calculateChanges(
    oldestValue,
    newestValue
  );

  const variant = absoluteChange >= 0 ? positiveVariant : negativeVariant;

  const { t } = useTranslation();

  if (unit === 'percentage') {
    return {
      variant,
      label: t('InsightsCharts:change_tooltip'),
      value: percentValueFormatter(absoluteChange),
    };
  }

  const absoluteValueFormatted = moneyValueFormatter(absoluteChange);

  const changeLabel = t('InsightsCharts:change_tooltip_percentage', {
    value: percentValueFormatter(percentageChange),
  });

  return {
    variant,
    label: changeLabel,
    value: absoluteValueFormatted,
  };
};

export const YearlyGrowthTooltip = ({
  active,
  payload,
  negativeVariant = 'negative',
  positiveVariant = 'positive',
  unit,
}: TooltipRenderProps & {
  negativeVariant?: ChangeVariant;
  positiveVariant?: ChangeVariant;
  unit: ChangeUnit;
}) => {
  const [oldPayload, newPayload] = payload ?? [];

  const oldestValue = oldPayload?.value as number;
  const newestValue = newPayload?.value as number;

  const changeRow = useChangeRow({
    oldestValue,
    newestValue,
    unit,
    negativeVariant,
    positiveVariant,
  });

  if (!active) {
    return null;
  }

  const oldValueFormatted =
    unit === 'percentage'
      ? percentValueFormatter(oldestValue)
      : moneyValueFormatter(oldestValue);
  const newValueFormatted =
    unit === 'percentage'
      ? percentValueFormatter(newestValue)
      : moneyValueFormatter(newestValue);

  return (
    <InsightsChartTooltip.Wrapper
      css={{ maxWidth: 'min-content' }}
      data-testid="yearly-growth-tooltip"
    >
      <InsightsChartTooltip.Table>
        <InsightsChartTooltip.Row
          variant="light"
          indicatorColor={oldPayload?.color}
          label={dateLabelShort(oldPayload?.payload?.rangeStartDate)}
          value={oldValueFormatted}
        />
        <InsightsChartTooltip.Row
          indicatorColor={newPayload?.color}
          label={dateLabelShort(newPayload?.payload?.rangeEndDate)}
          value={newValueFormatted}
        />
        <InsightsChartTooltip.Row
          variant={changeRow.variant}
          indicatorColor="white"
          label={changeRow.label}
          value={changeRow.value}
        />
      </InsightsChartTooltip.Table>
    </InsightsChartTooltip.Wrapper>
  );
};
