import { useLocation } from 'react-router-dom';

export const usePathWithExitPath = (path: string) => {
  const location = useLocation();

  return {
    to: path,
    state: { exitPath: location.pathname },
  };
};
