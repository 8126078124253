import { css, styled } from '@boopos/design-system';

const Title = styled('div', {
  fontSize: '2.4rem',
  lineHeight: '3.2rem',
  fontWeight: 700,
  m: 0,
});

const gridItem = css({
  gridColumn: 'span 12',

  '@bp2': {
    gridColumn: 'span 4',
  },
});

export const Styles = {
  Section: styled('div', {
    px: '$4',
    mb: '4rem',
    mt: '$9',

    '@bp2': {
      px: '$8',
    },
  }),
  GridWrapper: styled('div', {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
    gap: '$4',

    '@bp2': {
      gap: '3.2rem',
    },
  }),
  TitleWrapper: styled('div', {
    display: 'flex',
    alignItems: 'center',
    mb: '$4',

    [`${Title}`]: {
      flex: 1,

      '@bp2': {
        mr: '$4',
        flex: 'none',
      },
    },
  }),
  Title,
  gridItem,
};
