import { Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ActionSideModalCommonStyles } from 'components/ActionSideModal';

import { IBusinessAccount, IBusinessInvitation } from 'lib/types';

import { ConnectedAccountsSummary } from './ConnectedAccountsSummary';
import { EmailBadge } from './EmailBadge';

export interface InvitationsSummaryProps {
  title: string;
  accountsTitle: string;
  invitations?: IBusinessInvitation[];
  accounts?: IBusinessAccount[];
  onInvite: () => void;
  buttonText: string;
  onDeleteAccount?: () => void;
  allowEdit?: boolean;
}
export const InvitationsSummary = ({
  title,
  accountsTitle,
  invitations,
  accounts,
  onInvite,
  buttonText,
  onDeleteAccount,
  allowEdit = true,
}: InvitationsSummaryProps) => {
  if (!invitations?.length) {
    return null;
  }

  return (
    <>
      <ConnectedAccountsSummary
        title={accountsTitle}
        accounts={accounts}
        onDeleteAccount={allowEdit ? onDeleteAccount : undefined}
      />
      <ActionSideModalCommonStyles.Block>
        <ActionSideModalCommonStyles.BlockTitle>
          {title}
        </ActionSideModalCommonStyles.BlockTitle>
        <div>
          {invitations.map((invitation) => (
            <EmailBadge key={invitation.id} email={invitation.email} />
          ))}
        </div>
        {allowEdit && (
          <Button
            leftIcon={
              <FontAwesomeIcon icon={regular('circle-plus')} size="lg" />
            }
            variant="secondary"
            onClick={onInvite}
          >
            {buttonText}
          </Button>
        )}
      </ActionSideModalCommonStyles.Block>
    </>
  );
};
