import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getDocumentFiles } from 'lib/files';
import { LoanDocumentType } from 'lib/types';

import { DocumentsDetailsProps } from './DocumentsDetails.inteface';
import { Styles } from './DocumentsDetails.styles';
import { FileVisibilityRender } from './FileVisibilityRender';
import { getExtraProps } from './getExtraProps';

export const DocumentsDetails = ({
  documents,
  files,
  documentType,
  showLink,
  itemExtraProps,
  children,
  blur,
  showVisibility,
}: DocumentsDetailsProps) => {
  const notHaveDocuments = !documents?.length || !documentType;
  const notHaveFiles = !files?.length;

  if (notHaveDocuments && notHaveFiles) {
    return null;
  }

  const renderedFiles = notHaveFiles
    ? getDocumentFiles(documentType as LoanDocumentType, documents)
    : files;

  if (!renderedFiles?.length) {
    return null;
  }

  const Content = (
    <Styles.Wrapper>
      {renderedFiles.map((file) => (
        <Styles.Item
          key={file.id}
          blur={blur}
          {...getExtraProps(showLink, file)}
          {...(itemExtraProps ?? {})}
        >
          <Styles.ItemIcon>
            <FontAwesomeIcon icon={regular('file-check')} />
          </Styles.ItemIcon>
          <Styles.ItemLiteral blur={blur}>
            {file.name}
            {showVisibility && (
              <FileVisibilityRender visibility={file.visibility} />
            )}
          </Styles.ItemLiteral>
        </Styles.Item>
      ))}
    </Styles.Wrapper>
  );

  if (!children) {
    return <>{Content}</>;
  }

  return <>{children(Content)}</>;
};
