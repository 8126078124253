import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Residency } from 'components/CreditReport';

import api from 'lib/api';
import { legalAgreements } from 'lib/legal';

import { useUser } from 'providers/UserProvider';

import { defaultLandingUrl } from 'router/routePaths';

import { UserProfileFieldValues } from './UserProfileForm.interface';

interface UseOnboardingProfileScreenProps {
  showResidencyField?: boolean;
  nextScreen?: string;
}

export const useOnboardingProfileScreen = (
  config: UseOnboardingProfileScreenProps = { showResidencyField: true }
) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { showResidencyField, nextScreen } = config ?? {};

  const nextScreenOverwrite = location.state?.nextScreenOverwrite;
  const { firstName, lastName, phoneNumber } = location.state ?? {};

  const { updateUser, user } = useUser();

  const saveResidency = useCallback(async (country: string) => {
    const residency = country === 'US' ? Residency.us : Residency.nonUs;
    await api.updateQualificationStatusCreditReport({ residency });
  }, []);

  const onSubmit = useCallback(
    async (data: UserProfileFieldValues) => {
      const residencyField = showResidencyField
        ? { residency: { country: data.country } }
        : {};

      await updateUser(
        {
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phoneNumber,
          legalTerms: legalAgreements.legalTerms(),
          ...residencyField,
        },
        { v2: true }
      );

      if (showResidencyField) {
        await saveResidency(data.country);
      }
    },
    [updateUser, saveResidency, showResidencyField]
  );

  const onSuccess = useCallback(() => {
    navigate(nextScreenOverwrite ?? nextScreen ?? defaultLandingUrl);
  }, [nextScreen, nextScreenOverwrite, navigate]);

  const defaultValues = useMemo(
    () => ({
      firstName: user?.firstName ? user?.firstName : firstName ?? '',
      lastName: user?.lastName ? user?.lastName : lastName ?? '',
      phoneNumber: user?.phone ? user?.phone : phoneNumber ?? '',
      country: user?.residency?.country ?? '',
      policyAgreement: !!user?.legalTerms,
    }),
    [user, firstName, lastName, phoneNumber]
  );

  return {
    onSubmit,
    onSuccess,
    defaultValues,
    allowGoBack: !nextScreenOverwrite,
  };
};
