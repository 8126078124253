import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { Button } from '@boopos/design-system';

import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  ActionSideModalCommonStyles,
  ActionSideModalLoading,
  bottomNavAnimationVariants,
} from 'components/ActionSideModal';
import { useConnectedAccounts } from 'components/ForSaleDetailApplication/AttachPaymentProcessors/useConnectedAccounts';

import { IBusinessAccount } from 'lib/types';

import { AccountsConnectedContentPartial } from './AccountsConnectedContent.partial';
import { Styles } from './Styles';

interface Props {
  onConnectClick: () => void;
  onClose: () => void;
  businessId?: string;
  onDisconnectAccount: (account: IBusinessAccount) => Promise<void>;
}

export const BaseContentPartial = ({
  onConnectClick,
  businessId,
  onClose,
  onDisconnectAccount,
}: Props) => {
  const { t } = useTranslation();

  const { accounts: connectedAccounts, isLoading } =
    useConnectedAccounts(businessId);

  const hasAccountsConnected = connectedAccounts?.length;

  if (!businessId) {
    return null;
  }

  if (isLoading) {
    return <ActionSideModalLoading />;
  }

  return (
    <>
      <ActionSideModalCommonStyles.Title data-testid="payment-processors-base-title">
        {t('PaymentProcessorsModal:title')}
      </ActionSideModalCommonStyles.Title>
      <ActionSideModalCommonStyles.BlockDescription>
        <ActionSideModalCommonStyles.DescriptionParagraph>
          {t('PaymentProcessorsModal:description_box_1')}
        </ActionSideModalCommonStyles.DescriptionParagraph>
        <ActionSideModalCommonStyles.DescriptionParagraph>
          {t('PaymentProcessorsModal:description_box_2')}
        </ActionSideModalCommonStyles.DescriptionParagraph>
        <AccountsConnectedContentPartial
          businessId={businessId}
          onConnectClick={onConnectClick}
          onDisconnectAccount={onDisconnectAccount}
        />
        <ActionSideModalCommonStyles.DescriptionParagraph>
          <Styles.TrustBadge>
            <FontAwesomeIcon icon={solid('shield-check')} size="lg" />
            <div>{t('PaymentProcessorsModal:description_trust')}</div>
          </Styles.TrustBadge>
        </ActionSideModalCommonStyles.DescriptionParagraph>
      </ActionSideModalCommonStyles.BlockDescription>
      <AnimatePresence>
        <ActionSideModalCommonStyles.BottomNav
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={bottomNavAnimationVariants}
        >
          <ActionSideModalCommonStyles.BottomNavLeftText />
          {!!hasAccountsConnected && (
            <Button
              data-testid="payment-processors-modal-close-button"
              onClick={onClose}
            >
              {t('PaymentProcessorsModal:save_and_close')}
            </Button>
          )}
          {!hasAccountsConnected && (
            <Button
              onClick={onConnectClick}
              data-testid="payment-processors-modal-connect-button"
              leftIcon={<FontAwesomeIcon icon={regular('add')} />}
            >
              {t('PaymentProcessorsModal:connect_cta')}
            </Button>
          )}
        </ActionSideModalCommonStyles.BottomNav>
      </AnimatePresence>
    </>
  );
};
