import React from 'react';
import { useTranslation } from 'react-i18next';

import { LayoutSidebarDetail } from 'components/LayoutSidebarDetail';

export const Header = () => {
  const { t } = useTranslation();

  return (
    <LayoutSidebarDetail.Divider>
      <LayoutSidebarDetail.ContentHeader>
        {t('ForSaleDetailApplication:listing_details_header')}
      </LayoutSidebarDetail.ContentHeader>
    </LayoutSidebarDetail.Divider>
  );
};
