import { useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';

import api from 'lib/api';
import { ICategory } from 'lib/types';

const parseFromApi = (categories?: ICategory[]) =>
  categories?.reduce(
    (acc, category) => [
      ...acc,
      ...(category.name === 'Other' ? category.categories : [category]),
    ],
    [] as ICategory[]
  );

export const useParsedCategories = () => {
  const { data } = useSWRImmutable<ICategory[]>(api.getCategories);

  return useMemo(() => parseFromApi(data), [data]);
};
