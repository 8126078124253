import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';

import { Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PageHeader } from 'ui/PageHeader';

import { routePaths } from 'router/routePaths';

import { HeaderStyles } from './BusinessDetailHeader.styles';

interface IBusinessDetailHeaderProps {
  title: string | undefined;
  businessId: string | undefined;
}

export const BusinessDetailHeader = ({
  title,
  businessId,
}: IBusinessDetailHeaderProps) => {
  const { t } = useTranslation();

  return (
    <HeaderStyles.Wrapper>
      <PageHeader
        title={title}
        childrenTop={
          <Link to={generatePath(routePaths.portfolio, { businessId })}>
            <FontAwesomeIcon icon={regular('arrow-left')} size="lg" />
          </Link>
        }
        action={
          <Button
            rounded
            leftIcon={
              <FontAwesomeIcon icon={regular('pen-to-square')} size="lg" />
            }
            variant="secondary"
            as={Link}
            to={generatePath(routePaths.portfolioEditBusiness, {
              businessId,
            })}
            state={{ backUrl: window.location.pathname }}
          >
            {t('BusinessDetailScreen:edit')}
          </Button>
        }
        data-testid="portfolio-page-header"
      />
    </HeaderStyles.Wrapper>
  );
};
