import { Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ActionSideModalCommonStyles } from 'components/ActionSideModal';

export interface EmptyStateProps {
  inviteTitle: string;
  inviteDescription: string;
  inviteButtonText: string;
  onInvite: () => void;

  connectTitle: string;
  connectDescription: string;
  connectButtonText: string;
  onConnect: () => void;
}

export const EmptyState = (props: EmptyStateProps) => {
  const {
    inviteTitle,
    inviteDescription,
    inviteButtonText,
    onInvite,
    connectTitle,
    connectDescription,
    connectButtonText,
    onConnect,
  } = props;
  return (
    <>
      <ActionSideModalCommonStyles.Block bordered data-testid="empty-state">
        <ActionSideModalCommonStyles.BlockTitle>
          {inviteTitle}
        </ActionSideModalCommonStyles.BlockTitle>
        <ActionSideModalCommonStyles.BlockDescription>
          {inviteDescription}
        </ActionSideModalCommonStyles.BlockDescription>
        <Button
          leftIcon={<FontAwesomeIcon icon={regular('user-plus')} />}
          onClick={onInvite}
        >
          {inviteButtonText}
        </Button>
      </ActionSideModalCommonStyles.Block>

      <ActionSideModalCommonStyles.Block>
        <ActionSideModalCommonStyles.BlockTitle>
          {connectTitle}
        </ActionSideModalCommonStyles.BlockTitle>
        <ActionSideModalCommonStyles.BlockDescription>
          {connectDescription}
        </ActionSideModalCommonStyles.BlockDescription>
        <Button
          leftIcon={<FontAwesomeIcon icon={regular('user-plus')} size="lg" />}
          variant="secondary"
          onClick={onConnect}
        >
          {connectButtonText}
        </Button>
      </ActionSideModalCommonStyles.Block>
    </>
  );
};
