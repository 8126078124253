import useSWR from 'swr';

import Api from 'lib/api/api';
import { IBusinessAccount } from 'lib/types';

const emptyArray: IBusinessAccount[] = [];

export const useConnectedAccounts = (businessId?: string) => {
  const {
    data: business,
    error,
    mutate,
  } = useSWR<{ accounts: IBusinessAccount[] }>(
    businessId && Api.getPortfolioBusiness(businessId)
  );

  const accounts = business?.accounts ?? emptyArray;
  const isLoading = !accounts && !error;

  return { accounts, isLoading, mutate };
};
