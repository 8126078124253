import { IUseAccountConnector } from 'components/AccountConnectors/AccountConnectors.interface';
import { ProviderImage } from 'components/AccountConnectors/ProviderImage';
import { useAutoOpenConnection } from 'components/AccountConnectors/useAutoOpenConnection';

import { useStripeLink } from './useStripeLink';

export const StripeLink = (props: IUseAccountConnector) => {
  const { provider, businessId, onSuccess, render, ...rest } = props;

  const { ready, open } = useStripeLink({
    provider,
    businessId,
    onSuccess,
  });

  useAutoOpenConnection(provider, open, ready);

  if (render) {
    const renderProps = {
      ...rest,
      disabled: !ready,
      onClick: open,
    };

    return render(renderProps);
  }

  return (
    <button
      disabled={!ready}
      onClick={open}
      data-testid="stripe-link"
      {...rest}
    >
      <ProviderImage provider={provider} />
    </button>
  );
};
