import { useUserResidency } from './useUserResidency';

export enum QualificationScreens {
  profile = 'profile',
  proofOfFunds = 'proofOfFunds',
  proofOfFundsValidate = 'proofOfFundsValidate',
}
export const useStepsCounter = (currentScreen: QualificationScreens) => {
  const { isUsResident } = useUserResidency();

  const totalSteps = isUsResident ? 4 : 3;

  if (currentScreen === QualificationScreens.profile) {
    return { step: 1, totalSteps };
  }

  if (currentScreen === QualificationScreens.proofOfFunds) {
    return { step: 2, totalSteps };
  }

  if (currentScreen === QualificationScreens.proofOfFundsValidate) {
    return { step: 3, totalSteps };
  }

  return { step: 0, totalSteps: 0 };
};
