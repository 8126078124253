import { ReactElement } from 'react';

import { IUploadedFile } from 'lib/types';

export enum AcceptedMimeTypes {
  pdf = 'application/pdf',
  csv = 'text/csv',
  xls = 'application/vnd.ms-excel',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  images = 'image/*',
}

export enum AcceptedFilesEnum {
  pdf = 'pdf',
  xls = 'xls',
  xlsx = 'xlsx',
  csv = 'csv',
  images = 'images',
}

export interface IUploadZone {
  maxFiles?: number;
  accept?: AcceptedFilesEnum[];
  multiple?: boolean;
  maxSizeMb?: number;
  uploadParams?: { [key: string]: any };
  onFilesUploaded: (files: IUploadedFile[]) => Promise<void> | void;
  externalError?: boolean;
  noDrag?: boolean;
  children?: ReactElement;
  noClick?: boolean;
}
