import Cleave from 'cleave.js/react';
import { useCallback } from 'react';
import { useController } from 'react-hook-form';

import { Input } from '@boopos/design-system';

import { ICleaveHookFormInput } from './CleaveHookFormInput.interface';

export const CleaveHookFormInput = ({
  control,
  name,
  rules,
  shouldUnregister,
  targetProp = 'rawValue',
  ...props
}: ICleaveHookFormInput) => {
  const { field } = useController({
    name,
    control,
    rules,
    shouldUnregister,
  });

  const handleChange = useCallback(
    (e: any) => {
      field.onChange(e.target[targetProp]);
    },
    [field, targetProp]
  );

  return (
    <Input
      {...props}
      as={Cleave}
      htmlRef={field.ref}
      name={name}
      value={field.value}
      onChange={handleChange}
    />
  );
};
