import { PropsWithChildren, ReactNode } from 'react';

import { PageModal } from 'components/Modal';

import { HSWidgetHide } from 'lib/hubspot';

import { ActionSideModalStyles } from './ActionSideModal.styles';

export const ActionSideModal = ({
  children,
  header,
  onClose,
}: PropsWithChildren<{ header: ReactNode; onClose: () => void }>) => (
  <PageModal
    onClose={onClose}
    position="rightDrawer"
    maxWidth={546}
    header={header}
  >
    <HSWidgetHide />
    <ActionSideModalStyles.BodyWrapper>
      {children}
    </ActionSideModalStyles.BodyWrapper>
  </PageModal>
);
