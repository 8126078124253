import { defaultCurrency } from 'lib/constants';
import { parseMoneyToApiOrUndefined } from 'lib/parse';

import { IRequestGrowthLoanForm } from './RequestGrowthLoanForm.interface';

export const parseGrowthLoanToApi = (formData: IRequestGrowthLoanForm) => {
  const { type, requestedAmount } = formData;

  return {
    type,
    requestedAmount: parseMoneyToApiOrUndefined(
      requestedAmount || 0,
      defaultCurrency
    ),
  };
};
