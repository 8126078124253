import { useCallback } from 'react';
import { Rutter } from 'react-rutter-link/src/types';
import useScript from 'react-script-hook';

import { useAccountConnectorsStatus } from 'components/AccountConnectors/AccountConnectors.hooks';
import {
  IUseAccountConnector,
  IUseAccountConnectorReturn,
} from 'components/AccountConnectors/AccountConnectors.interface';

import api from 'lib/api';

const RUTTER_PKG = 'https://unpkg.com/@rutter/rutter-link@latest';

const instances: { [key: string]: Rutter } = {};

export const useRutterLink = ({
  provider,
  onSuccess,
  businessId,
}: IUseAccountConnector): IUseAccountConnectorReturn => {
  const { startConnection, endConnection, addError } =
    useAccountConnectorsStatus();

  const [loading, error] = useScript({
    src: RUTTER_PKG,
    checkForExisting: true,
  });

  const destroy = useCallback(() => {
    instances?.[provider]?.exit?.({ force: true });
    instances?.[provider]?.destroy?.();
  }, [provider]);

  const handleSuccess = useCallback(
    async (publicToken: string) => {
      try {
        await api.connectMerchantBusiness(provider, businessId, publicToken);
        onSuccess && onSuccess();
        endConnection();
        destroy();
      } catch (e) {
        addError();
      }
    },
    [destroy, businessId, provider, onSuccess, endConnection, addError]
  );

  const onExit = useCallback(() => {
    destroy();
    endConnection();
  }, [destroy, endConnection]);

  const open = useCallback(async () => {
    startConnection();
    const { data } = await api.initMerchantBusinessConnection(
      provider,
      businessId
    );

    const publicKey = data.token;

    instances[provider] = window.Rutter.create({
      publicKey,
      onSuccess: handleSuccess,
      onExit,
    });

    instances[provider].open({
      platform: provider,
    });

    window.currentOpened = instances[provider];
  }, [onExit, businessId, provider, handleSuccess, startConnection]);

  return { open, ready: !loading && !error, error };
};
