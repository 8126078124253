import { MouseEvent, useCallback, useEffect, useState } from 'react';

import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useForSaleFavourite } from 'components/DealFlowHandling';

import { ListingForSaleReduced } from 'lib/types';

import { ForSaleFavouriteButtonStyles } from './ForSaleFavouriteButton.styles';

export const ForSaleFavouriteButton = ({
  listing,
  onSuccess,
}: {
  listing?: ListingForSaleReduced;
  onSuccess?: () => Promise<void>;
}) => {
  const [added, setAdded] = useState<boolean>(!!listing?.bookmark);
  const { addFavourite, removeFavourite } = useForSaleFavourite({
    listingId: listing?.id,
    onSuccess,
  });

  const handleAdd = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      setAdded(true);
      await addFavourite();
    },
    [addFavourite]
  );

  const handleRemove = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      setAdded(false);
      await removeFavourite();
    },
    [removeFavourite]
  );

  useEffect(() => {
    setAdded(!!listing?.bookmark);
  }, [listing?.bookmark]);

  if (!listing) {
    return null;
  }

  return (
    <ForSaleFavouriteButtonStyles.Button
      favourite={added}
      onClick={added ? handleRemove : handleAdd}
      data-testid={added ? 'remove-favourite' : 'add-favourite'}
    >
      <FontAwesomeIcon icon={solid('heart')} className="heart-fill" size="xl" />
      <FontAwesomeIcon
        icon={regular('heart')}
        className="heart-stroke"
        size="xl"
      />
    </ForSaleFavouriteButtonStyles.Button>
  );
};
