import { Range, Text, useRankStatus } from './';

export interface CommonViewProps {
  percentile: number;
  valueDescription?: string;
  rankDescription: string;
  basicView?: boolean;
  blur?: boolean;
}

export const CommonView = ({
  percentile,
  valueDescription,
  rankDescription,
  blur,
  basicView,
}: CommonViewProps) => {
  const status = useRankStatus(percentile);

  return (
    <>
      <Range value={percentile} maxValue={100} status={status} blur={blur} />
      {!basicView && (
        <>
          {valueDescription && <Text text={valueDescription} />}
          <Text text={rankDescription} />
        </>
      )}
    </>
  );
};
