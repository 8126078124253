import { styled } from '@boopos/design-system';

export const Styles = {
  TrustBadge: styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '0.8rem',
    fontWeight: 500,
    color: '$textStatePromotion',
    '& svg': {
      color: '$infoPositive',
    },
  }),
};

export const ItemStyles = {
  Wrapper: styled('div', {
    display: 'flex',
    gap: '.8rem',
    flexDirection: 'column',
  }),
  Card: styled('div', {
    p: '$4',
    backgroundColor: '$backgroundBase',
    borderRadius: '1.6rem',
    display: 'flex',
    alignItems: 'center',
  }),

  Name: styled('div', {
    flex: 1,
  }),
  Subtitle: styled('span', {
    color: '$textBodyTertiary',
    ml: '.8rem',
  }),
  Icon: styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    width: '4rem',
    height: '4rem',
    borderRadius: '50%',
    mr: '1.6rem',
    img: {
      maxWidth: '100%',
    },
  }),
};
