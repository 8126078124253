import { useFormContext } from 'react-hook-form';

import { Checkbox } from '@boopos/design-system';

import { IPersonData } from 'lib/types';

interface Props {
  person: IPersonData;
  disabled?: boolean;
}

export const ContractSignerCheckbox = ({ person, disabled }: Props) => {
  const formMethods = useFormContext();

  const hasError = !!formMethods.formState.errors?.signerIds;

  if (!person) {
    return null;
  }

  return (
    <Checkbox
      variant={hasError ? 'error' : undefined}
      data-testid="person-checkbox"
      label={`${person.firstName} ${person.lastName}`}
      id={`person-checkbox-${person.id}`}
      value={person.id}
      disabled={disabled}
      {...formMethods.register('signerIds', {
        required: true,
      })}
      groupSpacing="none"
    />
  );
};
