import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AddBusinessForm } from 'components/AddBusinessForm';
import { OnboardingLayout } from 'components/OnboardingLayout';
import {
  PortfolioApplicationSteps,
  PortfolioApplicationType,
  usePortfolioApplicationFlow,
} from 'components/PortfolioApplication/PortfolioApplicationFlow';
import { PortfolioApplicationLayout } from 'components/PortfolioApplication/PortfolioApplicationLayout';

import { useAddApplicationBusiness } from './useAddApplicationBusiness';

export interface PortfolioApplicationAddBusinessProps {
  applicationType: PortfolioApplicationType;
}

export const PortfolioApplicationAddBusiness = ({
  applicationType,
}: PortfolioApplicationAddBusinessProps) => {
  const { loanType, afterSuccessPath } = usePortfolioApplicationFlow({
    applicationType,
    applicationStep: PortfolioApplicationSteps.addBusiness,
  });

  const { t } = useTranslation();

  const { addBusiness, onSuccess } = useAddApplicationBusiness({
    loanType,
    afterSuccessPath,
  });

  const title = t('PortfolioApplicationAddBusiness:title');

  const pageTitle = `${title}`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PortfolioApplicationLayout
        title={title}
        contentWrapperProps={{
          'data-testid': 'portfolio-application-add-business',
          'data-application-type': applicationType,
        }}
      >
        <AddBusinessForm
          copies={{
            nameLabel: t('PortfolioApplicationAddBusiness:name_label'),
            websiteLabel: t('PortfolioApplicationAddBusiness:website_label'),
            typeLabel: t('PortfolioApplicationAddBusiness:business_type_label'),
            submitButtonLabel: t(
              'PortfolioApplicationAddBusiness:submit_button_label'
            ),
          }}
          saveEndpoint={addBusiness}
          onSuccess={onSuccess}
          submitButtonProps={{
            fullWidth: false,
            rightIcon: <FontAwesomeIcon icon={regular('arrow-right')} />,
          }}
          renderActionChildren={(children) => (
            <OnboardingLayout.ActionsWrapper>
              {children}
            </OnboardingLayout.ActionsWrapper>
          )}
        />
      </PortfolioApplicationLayout>
    </>
  );
};
