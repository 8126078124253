import { parseMoneyFromApi } from 'lib/parse';
import { Money } from 'lib/types';
import { nearestKDown, nearestKUp } from 'lib/utils';

import { IApiFilterMinMax } from './useForSaleFiltersConfig.interface';

export type GetMoneyFilterConfig = IApiFilterMinMax<Money>;

export const getMoneyFilterConfig = (config?: GetMoneyFilterConfig) => {
  const { min, max } = config ?? {};
  const step = 50_000;
  const minMoney = parseMoneyFromApi(min);
  const maxMoney = parseMoneyFromApi(max);

  const minRounded = nearestKDown(minMoney.amount, step);
  const maxRounded = nearestKUp(maxMoney.amount, step);

  const defaultValue = [minRounded, maxRounded];

  return {
    min: minRounded,
    max: maxRounded,
    defaultValue,
    step,
  };
};
