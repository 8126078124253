import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { chartCSS, defaultChartColors } from 'ui/InsightsShared';

import {
  InsightsBarChartProps,
  TooltipRenderProps,
} from './InsightsBarChart.interface';
import { useChartStyles } from './InsightsBarChart.styles';

export type { TooltipRenderProps };

export const InsightsBarChart = <DataEntry extends {}>(
  props: InsightsBarChartProps<DataEntry>
) => {
  const {
    xAxisDataKey,
    xAxisTickFormatter,
    yAxisTickFormatter,
    chartColors = defaultChartColors,
    renderTooltip,
    barDataKeys,
    data,
  } = props;

  const styles = useChartStyles(chartColors);
  const { className } = chartCSS();

  const [firstBarDataKey, secondBarDataKey, thirdBarDataKey] = barDataKeys;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        className={className}
        data={data}
        margin={styles.chartMargin}
        barCategoryGap={12}
        barGap={0}
      >
        <CartesianGrid
          strokeDasharray="4 4"
          vertical={false}
          stroke={chartColors.cartesianGrid}
          strokeOpacity={0.3}
        />
        <XAxis
          dataKey={xAxisDataKey}
          padding={styles.xAxisPadding}
          tickFormatter={xAxisTickFormatter}
          interval={0}
          angle={-45}
          axisLine={false}
          tickMargin={20}
          tickSize={0}
          tickLine={false}
          tick={styles.xAxisTick}
        />
        <YAxis
          tick={styles.axisTick}
          tickFormatter={yAxisTickFormatter}
          tickLine={false}
          axisLine={false}
          tickSize={0}
        />
        <Tooltip cursor={styles.tooltipCursor} content={renderTooltip} />
        <Bar
          dataKey={firstBarDataKey as string}
          fill={chartColors.bl200}
          barSize={8}
          isAnimationActive={false}
        />
        <Bar
          dataKey={secondBarDataKey as string}
          fill={chartColors.bl500}
          barSize={8}
        />
        <Bar
          dataKey={thirdBarDataKey as string}
          fill={chartColors.bl900}
          barSize={8}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
