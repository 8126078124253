import * as RadixSlider from '@radix-ui/react-slider';
import React, { forwardRef } from 'react';

import {
  SliderRange,
  SliderRoot,
  SliderThumb,
  SliderTrack,
} from './Slider.styles';

export interface SliderProps extends RadixSlider.SliderProps {}

const Slider = forwardRef<HTMLInputElement, SliderProps>((props, ref) => {
  const value = props.value ?? props.defaultValue ?? [];

  return (
    <SliderRoot ref={ref} {...props}>
      <SliderTrack>
        <SliderRange />
      </SliderTrack>
      {value.map((_, i) => (
        <SliderThumb key={i} />
      ))}
    </SliderRoot>
  );
});

export { Slider };
