import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutlet, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { InlineMessage } from 'ui/InlineMessage';

import { DealDetail, LoadingElements } from 'components/DealDetail';
import { DealTimeline } from 'components/DealTimeline';
import { PageModalWrapper } from 'components/Modal';
import { QualificationBanner } from 'components/QualificationBanner';
import { TimelineWhatsNext } from 'components/TimelineWhatsNext';

import Api from 'lib/api';
import { ILoanApi } from 'lib/types';

const DealFlowDetailScreen = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { loanId } = params || {};

  const {
    data: loan,
    mutate: mutateLoan,
    error,
  } = useSWR<ILoanApi>(loanId && Api.getLoan(loanId));

  const handleRefreshLoan = useCallback(async () => {
    await mutateLoan();
  }, [mutateLoan]);

  const outlet = useOutlet({ handleRefreshLoan });

  const Content =
    error && !loan ? (
      <InlineMessage
        type="warning"
        heading={t('DealFlowDetailScreen:loading_error')}
      />
    ) : (
      <>
        {!!loan && <QualificationBanner />}
        {!loan && <LoadingElements.Timeline />}
        {!!loan && <DealTimeline loan={loan} />}
      </>
    );

  const Side = !error ? <TimelineWhatsNext loan={loan} showInDesktop /> : null;

  return (
    <>
      <DealDetail.Container data-testid="deal-flow-detail-screen">
        <DealDetail.Content>{Content}</DealDetail.Content>
        <DealDetail.Side>{Side}</DealDetail.Side>
      </DealDetail.Container>
      <PageModalWrapper>{outlet}</PageModalWrapper>
    </>
  );
};

export { DealFlowDetailScreen };
