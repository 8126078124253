import React from 'react';

import { Merchants, ProviderImage } from 'components/AccountConnectors';
import { BankAccountLogo } from 'components/BankAccountsPreview/BankAccountLogo';

import { Props } from './ConnectedAccountsDetailCell.interface';

export const AccountIcon = ({ account }: Props) => {
  if (account.provider === Merchants.bank) {
    return <BankAccountLogo institution={account?.metadata?.institution} />;
  }

  return <ProviderImage provider={account.provider} imageType="rounded" />;
};
