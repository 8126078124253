import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { parseMoneyFromApiToString, parseMoneyToApi } from 'lib/parse';
import { Currency } from 'lib/types';

import { PriceForm } from 'screens/InvestmentCriteriaScreen/InvestmentCriteria.interface';

import { useInvestmentCriteriaNavigation } from './useInvestmentCriteriaNavigation';
import { useSaveInvestmentCriteria } from './useSaveInvestmentCriteria';

export const useInvestmentCriteriaPriceScreen = () => {
  const { exitPath, navigateToNextPath } = useInvestmentCriteriaNavigation();

  const { loading, success, save, investmentCriteria } =
    useSaveInvestmentCriteria({
      onSave: navigateToNextPath,
    });

  const formMethods = useForm<PriceForm>({});
  const { watch, setValue } = formMethods;
  const askPriceRange = watch('askPriceRange');

  const hasAskPriceEmpty = investmentCriteria?.askPriceRange?.every(
    (value: string) => value === null
  );

  const handleSubmit = useCallback(
    (data: PriceForm) => {
      save({
        ...investmentCriteria,
        askPriceRange: [
          parseMoneyToApi({
            amount: data.askPriceRange[0],
            currency: Currency.USD,
          }),
          parseMoneyToApi({
            amount: data.askPriceRange[1],
            currency: Currency.USD,
          }),
        ],
      });
    },
    [investmentCriteria, save]
  );

  const onSubmit = formMethods.handleSubmit(handleSubmit);

  useEffect(() => {
    if (!investmentCriteria?.askPriceRange.length || hasAskPriceEmpty) return;

    setValue('askPriceRange', [
      +parseMoneyFromApiToString(investmentCriteria.askPriceRange[0]),
      +parseMoneyFromApiToString(investmentCriteria.askPriceRange[1]),
    ]);
  }, [hasAskPriceEmpty, investmentCriteria?.askPriceRange, setValue]);

  return {
    askPriceRange,
    formMethods,
    loading,
    success,
    exitPath,
    handleSubmit,
    onSubmit,
  };
};
