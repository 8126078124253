import { IUser, UserGoals } from 'lib/types';

export abstract class BaseAnalyticPlugin {
  abstract load(): Promise<void>;
  abstract identify(user: IUser): Promise<void>;
  abstract track(eventName: string, eventParams?: any): Promise<void>;
  abstract reset(): Promise<void>;
  abstract page(): Promise<void>;

  static getBaseUserTraits(user: IUser | Partial<IUser>) {
    if (!user) {
      return {};
    }

    return {
      id: user.id,
      email: user.email,
      qualified: user.qualified,
      qualifiedAt: user.qualifiedAt,
      createdAt: user.createdAt,
      name: user.fullName,
      firstName: user.firstName,
      lastName: user.lastName,
      linkedin: user.linkedinUrl,
      goal_seller: user?.profile?.goals?.includes(UserGoals.sell),
      goal_insights: user?.profile?.goals?.includes(UserGoals.insights),
      goal_buyer: user?.profile?.goals?.includes(UserGoals.buy),
    };
  }
}
