import { AxiosError } from 'axios';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ButtonFeedback } from '@boopos/design-system';

import { useConnectedAccounts } from 'components/ForSaleDetailApplication/AttachPaymentProcessors/useConnectedAccounts';
import { useSubmitForEvaluationError } from 'components/SubmitForEvaluation';

import { apiV2 } from 'lib/api';
import {
  SellApplicationStatus,
  SubmitForEvaluationErrorResponse,
} from 'lib/types';
import { generatePathSafe } from 'lib/utils/generatePathSafe';

import { routePaths } from 'router/routePaths';

import {
  ActionsSidebarProps,
  PrimaryActionsEnum,
} from './ActionsSidebar.interface';
import { NoAccountsConnectedModal } from './NoAccountsConnectedModal';

export const getPrimaryAction = (status?: SellApplicationStatus) => {
  if (!status) {
    return null;
  }
  if (status === SellApplicationStatus.missing_info) {
    return PrimaryActionsEnum.sendToAnalysis;
  }

  return null;
};

export const PrimaryActions = {
  [PrimaryActionsEnum.sendToAnalysis]: ({
    application,
    business,
  }: ActionsSidebarProps) => {
    const navigate = useNavigate();
    const { handler: errorHandler } = useSubmitForEvaluationError();
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const { accounts } = useConnectedAccounts(business?.id);
    const applicationId = application?.id;

    const onClose = useCallback(() => {
      setShowModal(false);
    }, []);

    const onSubmit = useCallback(async () => {
      if (!applicationId) {
        return;
      }
      setLoading(true);
      try {
        await apiV2.submitApplication(applicationId);
        const successPath = generatePathSafe(
          routePaths.portfolioApplication.applicationFinished,
          { loanId: applicationId }
        );
        successPath && navigate(successPath);
      } catch (err) {
        const error = err as AxiosError<SubmitForEvaluationErrorResponse>;
        errorHandler(error?.response?.data);
      } finally {
        setLoading(false);
      }
    }, [applicationId, navigate, errorHandler]);

    const onClick = useCallback(async () => {
      if (!accounts?.length) {
        setShowModal(true);
        return;
      }
      await onSubmit();
    }, [onSubmit, accounts]);

    return (
      <>
        <ButtonFeedback
          fullWidth
          className="sidebar-primary"
          onClick={onClick}
          isLoading={loading}
          data-testid="send-to-analysis"
        >
          {t('ForSaleDetailApplication:submit_for_evaluation')}
        </ButtonFeedback>
        <NoAccountsConnectedModal
          opened={showModal}
          onClose={onClose}
          onSubmitAnyways={onSubmit}
        />
      </>
    );
  },
};
