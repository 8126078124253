import { styled } from '@boopos/design-system';

export const IconWrapper = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  mb: '$6',
  variants: {
    variant: {
      success: {
        '& > svg': {
          color: '$lime600',
        },
      },
      error: {
        '& > svg': {
          color: '$red600',
        },
      },
    },
  },
});

export const TitleWrapper = styled('div', {
  fontSize: '2rem',
  mb: '$6',
});
