import Skeleton from 'react-loading-skeleton';

import { Box } from '@boopos/design-system';

export const Loading = () => (
  <Box data-testid="insight-card-loading">
    <Skeleton width="100%" borderRadius={8} />
    <Skeleton width="100%" borderRadius={8} />
    <Skeleton width="100%" borderRadius={8} />
    <Skeleton width="100%" borderRadius={8} />
    <Skeleton width="50%" borderRadius={8} />
  </Box>
);
