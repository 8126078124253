import { BankAccountInstitution } from 'lib/types';

import { BankAccountImage } from './BankAccountImage';
import { EmptyWithIcon } from './EmptyWithIcon';

interface BankAccountLogoProps {
  institution?: BankAccountInstitution;
}

export const BankAccountLogo = ({
  institution,
  ...props
}: BankAccountLogoProps) => {
  if (!institution) {
    return <EmptyWithIcon {...props} />;
  }

  if (institution?.logo) {
    return (
      <BankAccountImage
        logo={institution.logo}
        name={institution.name}
        fallback={<EmptyWithIcon primaryColor={institution.primaryColor} />}
        {...props}
      />
    );
  }

  return <EmptyWithIcon primaryColor={institution.primaryColor} {...props} />;
};
