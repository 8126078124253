import React from 'react';

import { SaleSellerApplicationApi, SellApplicationStatus } from 'lib/types';

import { ContentByStatus } from './ContentByStatus';

interface WhatsNextSidebarProps {
  status?: SellApplicationStatus;
  application?: SaleSellerApplicationApi;
}

export const WhatsNextSidebar = ({
  application,
  status,
}: WhatsNextSidebarProps) => {
  const Content = status ? ContentByStatus[status] : () => null;

  return <Content application={application} />;
};
