import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { HeatMapTable } from 'ui/HeatMapTable';

import { CohortsRawData } from './InsightsCharts.interface';
import { dateLabelShort } from './utils/dateLabelFormatter';
import { isCohortsEmpty } from './utils/isCohortsEmpty';
import { prepareCohortsHeatMap } from './utils/prepareCohortsHeatMap';
import { useCohortsCellColor } from './utils/useCohortsCellColor';

export const CohortsTable: React.FC<{
  children: (content: ReactElement) => ReactElement;
  rawData?: CohortsRawData[];
}> = ({ rawData, children }) => {
  const { t } = useTranslation();

  const preparedData = useMemo(() => prepareCohortsHeatMap(rawData), [rawData]);

  const months = useMemo(
    () => rawData?.map((row) => row.dateAccounting) ?? [],
    [rawData]
  );

  const retentionJoin = useMemo(() => {
    return (
      rawData?.reduce(
        (acc, row) => {
          return [...acc, ...row.retentionPercentage];
        },
        [] as (number | null)[]
      ) ?? []
    );
  }, [rawData]);

  const { getColor } = useCohortsCellColor(retentionJoin);

  if (isCohortsEmpty(rawData)) {
    return null;
  }

  return children(
    <HeatMapTable.Wrapper>
      <HeatMapTable.Table>
        <HeatMapTable.THead>
          <HeatMapTable.Tr>
            <HeatMapTable.Th></HeatMapTable.Th>
            {months.map((_, index) => (
              <HeatMapTable.Th key={index}>
                {t('InsightsCharts:month_header', { monthNumber: index + 1 })}
              </HeatMapTable.Th>
            ))}
          </HeatMapTable.Tr>
        </HeatMapTable.THead>
        <HeatMapTable.TBody>
          {months.map((month, rowIndex) => (
            <HeatMapTable.Tr key={rowIndex}>
              <HeatMapTable.Td>{dateLabelShort(month)}</HeatMapTable.Td>
              {preparedData.get(month)?.map(({ raw, formatted }, colIndex) => (
                <HeatMapTable.Td
                  key={colIndex}
                  css={{
                    background: getColor(raw),
                  }}
                >
                  {formatted}
                </HeatMapTable.Td>
              ))}
            </HeatMapTable.Tr>
          ))}
        </HeatMapTable.TBody>
      </HeatMapTable.Table>
    </HeatMapTable.Wrapper>
  );
};
