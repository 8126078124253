import { AnimatePresence } from 'framer-motion';
import { t } from 'i18next';
import { useCallback } from 'react';
import { useOutletContext } from 'react-router-dom';

import { ButtonFeedback } from '@boopos/design-system';

import {
  ActionSideModal,
  ActionSideModalCloseHeader,
  ActionSideModalCommonStyles,
  ActionSideModalLoading,
  bottomNavAnimationVariants,
  useNavigateFromActionSideModalWithParams,
} from 'components/ActionSideModal';
import {
  BusinessPerformanceForm,
  BusinessPerformanceFormValues,
  parseFromApi,
  updateApplication,
} from 'components/PortfolioApplication/PortfolioApplicationBusinessPerformance';

import { useSellApplicationFromUrlParams } from 'lib/sell-application/useSellApplicationFromUrlParams';

interface IOutletContext {
  handleRefreshLoan: () => Promise<void>;
  loanId?: string;
}

export const ApplicationBusinessPerformanceModal = ({
  basePath,
}: {
  basePath: string;
}) => {
  const outletContext = useOutletContext<IOutletContext>() || {};

  const { applicationId, sellApplication, mutate } =
    useSellApplicationFromUrlParams({
      loanId: outletContext.loanId,
    });
  const { handleRefreshLoan } = outletContext;

  const { onClose } = useNavigateFromActionSideModalWithParams({ basePath });

  const onSubmit = useCallback(
    async (data: BusinessPerformanceFormValues) => {
      if (!applicationId) return;
      await updateApplication(applicationId, data);
      await handleRefreshLoan?.();
      await mutate();
      onClose();
    },
    [mutate, handleRefreshLoan, onClose, applicationId]
  );

  return (
    <ActionSideModal
      header={<ActionSideModalCloseHeader onClose={onClose} />}
      onClose={onClose}
    >
      <ActionSideModalCommonStyles.Title data-testid="business-performance-title">
        {t('ApplicationBusinessPerformanceModal:modal_title')}
      </ActionSideModalCommonStyles.Title>

      <ActionSideModalCommonStyles.Subtitle mbSmall>
        {t('ApplicationBusinessPerformanceModal:modal_description')}
      </ActionSideModalCommonStyles.Subtitle>

      {!sellApplication ? (
        <ActionSideModalLoading />
      ) : (
        <BusinessPerformanceForm
          as={ActionSideModalCommonStyles.Form}
          defaultValues={parseFromApi(sellApplication)}
          onSubmit={onSubmit}
          actionsComponent={({ isLoading, isSuccess }) => (
            <AnimatePresence>
              <ActionSideModalCommonStyles.BottomNav
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={bottomNavAnimationVariants}
              >
                <ActionSideModalCommonStyles.BottomNavLeftText />
                <ButtonFeedback
                  isLoading={isLoading}
                  isSuccess={isSuccess}
                  data-testid="save-button"
                >
                  {t('ApplicationBusinessPerformanceModal:modal_save')}
                </ButtonFeedback>
              </ActionSideModalCommonStyles.BottomNav>
            </AnimatePresence>
          )}
        />
      )}
    </ActionSideModal>
  );
};
