import { Outlet } from 'react-router-dom';

import { LightBackgroundBody } from 'components/GlobalStyles';

import { FullScreenFunnelLayout } from './InvestmentCriteriaScreen.styles';

export const InvestmentCriteriaScreensWrapper = () => {
  return (
    <>
      <LightBackgroundBody />
      <FullScreenFunnelLayout.Wrapper>
        <Outlet />
      </FullScreenFunnelLayout.Wrapper>
    </>
  );
};
