import type * as Stitches from '@stitches/react';

import { styled } from '@boopos/design-system';

import { modifyVariantsForStory } from 'ui/storyUtils';

export const badgeColorsVariants = {
  neutral: {
    background: '$decorationSlateSoft',
    color: '$decorationSlateDark',
  },
  yellow: {
    background: '$decorationYellowSoft',
    color: '$decorationYellowDark',
  },
  green: {
    background: '$decorationGreenSoft',
    color: '$decorationGreenDark',
  },
  red: {
    background: '$decorationRedSoft',
    color: '$decorationRedDark',
  },
  pink: {
    background: '$decorationPinkSoft',
    color: '$decorationPinkDark',
  },
  blue: {
    background: '$decorationBlueSoft',
    color: '$decorationBlueDark',
  },
  olive: {
    background: '$decorationOliveSoft',
    color: '$decorationOliveDark',
  },
  beige: {
    background: '$decorationBeigeSoft',
    color: '$decorationBeigeDark',
  },
};

export const Badge = styled('div', {
  fontSize: '1.2rem',
  lineHeight: '1.6rem',
  fontWeight: 500,
  px: '.8rem',
  py: '.4rem',
  borderRadius: '.8rem',
  display: 'inline-flex',
  color: '$accent',

  variants: {
    variant: badgeColorsVariants,
    size: {
      default: {},
    },
  },
  defaultVariants: {
    variant: 'neutral',
    size: 'default',
  },
});

type Props = {};
type BadgeVariants = Stitches.VariantProps<typeof Badge>;

export const BadgeStory = modifyVariantsForStory<
  BadgeVariants,
  Props,
  typeof Badge
>(Badge);
