import { styled } from '@boopos/design-system';

export const ErrorWrapper = styled('div', {
  maxWidth: '40rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '15% auto',
  position: 'absolute',
  inset: 0,
  overflow: 'hidden',
  px: '$8',
  textAlign: 'center',

  '& > p': {
    mb: '$4',
  },
});

export const ErrorIconWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '50%',
  backgroundColor: '$slate200',
  p: '$8',
  alignItems: 'center',
  mb: '$4',
  color: '$slate500',
});
