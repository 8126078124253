import { Accordion, styled } from '@boopos/design-system';

export const StyledAccordion = styled(Accordion, {
  background: '$surfaceElevated',
  borderRadius: '1.6rem',
  px: '1.6rem',
  '> div, > div:first-child': {
    border: 0,
    borderTop: 0,
  },
  '.accordion-content > div': {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    borderLeft: 0,
  },
  button: {
    background: 'transparent',
  },
});
