import { startTransition, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DeleteConfirmationAction } from 'components/ConfirmationModal';

import { routePaths } from 'router/routePaths';

export const LogoutButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSignOut = useCallback(() => {
    startTransition(() => {
      navigate(routePaths.logout);
    });
  }, [navigate]);

  return (
    <DeleteConfirmationAction
      title={t('AccountScreen:sign_out_confirmation_title')}
      deleteText={t('AccountScreen:sign_out')}
      deleteIcon={
        <FontAwesomeIcon icon={regular('arrow-right-from-bracket')} />
      }
      onDelete={onSignOut}
    >
      <Button variant="secondary">{t('AccountScreen:sign_out')}</Button>
    </DeleteConfirmationAction>
  );
};
