import Skeleton from 'react-loading-skeleton';

export const BusinessForSaleLoading = () => {
  return (
    <>
      <Skeleton count={3} />
      <Skeleton width={200} />
    </>
  );
};
