import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@boopos/design-system';

import config from 'lib/config';

export const ContactUsAction = () => {
  const { t } = useTranslation();
  return (
    <Button
      as="a"
      href={`mailto:${config.ORIGINATION_EMAIL}`}
      fullWidth
      data-testid="loan-contact-button"
      className="sidebar-primary"
    >
      {t('ListingUnlocked:contact_us')}
    </Button>
  );
};
