import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Checkbox, RangeSlider } from '@boopos/design-system';

import { CurrencyHookInput } from 'components/CurrencyInput';
import { IDealStructureFields } from 'components/DealStructureHelpers/DealStructure.interface';
import {
  getDealStructureParsedValues,
  getFixedMaxValue,
} from 'components/DealStructureHelpers/DealStructure.utils';
import { ERROR_0 } from 'components/DealStructureWarnings/PreviewAlertMessage';

import { defaultCurrency } from 'lib/constants';
import { getInputErrorProps } from 'lib/forms';
import { nearestKUp } from 'lib/utils';

import { IDealStructureFieldsProps } from './DealStructureFields.interface';
import {
  RangeSliderContent,
  RequestedAmountHeading,
  RequestedAmountSection,
  SellerFinancingSection,
} from './DealStructureFields.styles';

export const DealStructureFields = ({
  currency = defaultCurrency,
  defaultValues,
  previewData,
}: IDealStructureFieldsProps) => {
  const { t } = useTranslation();

  const [showSellerFinancing, setShowSellerFinancing] = useState(
    !!defaultValues?.sellerFinancing
  );

  const { formState, control, watch, setValue, reset } = useFormContext();

  const { message: alertMessage } = previewData;

  const currencyInputProps = {
    currency,
    control,
  };

  const {
    parsedRequestedAmount,
    parsedSellerFinancing,
    parsedMaxRequestedAmount,
    parsedMaxSellerFinancing,
  } = getDealStructureParsedValues(previewData);

  const handleSliderChange = useCallback(
    (value: number, max: number, fieldName: string) => {
      setValue(
        fieldName as keyof IDealStructureFields,
        `${getFixedMaxValue(value, max)}`
      );
    },
    [setValue]
  );

  const handleSellerFinancingCheckbox = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const checked = e.currentTarget.checked;
      setValue('sellerFinancing', '0');
      setShowSellerFinancing(checked);
    },
    [setValue]
  );

  // We need listen any previewData change to refresh fixed calcs from backend.
  useEffect(() => {
    setValue('sellerFinancing', `${parsedSellerFinancing.amount}`);

    setValue('requestedAmount', `${parsedRequestedAmount.amount}`, {
      shouldValidate: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewData, setValue]);

  // reset form defaultValues
  useEffect(() => {
    reset(defaultValues);
    setShowSellerFinancing(!!defaultValues?.sellerFinancing);
  }, [defaultValues, reset]);

  return (
    <>
      <CurrencyHookInput
        label={t('DealStructureForm:business_price_label')}
        data-testid="business-price-input"
        name="businessPrice"
        rules={{
          required: t('DealStructureForm:business_price_required'),
        }}
        {...getInputErrorProps(formState?.errors?.businessPrice)}
        {...currencyInputProps}
        required
        autoFocus
      />
      <CurrencyHookInput
        label={t('DealStructureForm:inventory_label')}
        data-testid="inventory-price"
        name="inventoryPrice"
        {...currencyInputProps}
      />

      <SellerFinancingSection enable={!!watch('businessPrice')}>
        <Checkbox
          name="hasSellerFinance"
          data-testid="seller-financing-checkbox"
          id="hasSellerFinance"
          label={t('DealStructureForm:seller_has_financing')}
          onChange={handleSellerFinancingCheckbox}
          checked={showSellerFinancing}
        />
        {showSellerFinancing && (
          <Box css={{ mt: '$8' }}>
            <CurrencyHookInput
              label={t('DealStructureForm:seller_financing_label')}
              data-testid="seller-financing-input"
              name="sellerFinancing"
              {...currencyInputProps}
            />
            <RangeSliderContent>
              <RangeSlider
                data-testid="seller-financing-slider"
                step={1000}
                min={0}
                max={nearestKUp(parsedMaxSellerFinancing?.amount, 1000)}
                defaultValue={parsedSellerFinancing?.amount}
                onChange={(e) =>
                  handleSliderChange(
                    +e.target.value,
                    parsedMaxSellerFinancing?.amount,
                    'sellerFinancing'
                  )
                }
              />
            </RangeSliderContent>
          </Box>
        )}
      </SellerFinancingSection>

      <RequestedAmountSection enable={!!watch('businessPrice')}>
        <RequestedAmountHeading>
          {t('DealStructureForm:how_much_label')}
        </RequestedAmountHeading>
        <CurrencyHookInput
          data-testid="requested-amount-input"
          label={t('DealStructureForm:loan_amount_label')}
          name="requestedAmount"
          rules={{
            validate: () => alertMessage?.code !== ERROR_0,
          }}
          {...currencyInputProps}
          {...getInputErrorProps(formState?.errors?.requestedAmount)}
        />
        <RangeSliderContent>
          <RangeSlider
            data-testid="requested-amount-slider"
            step={1000}
            min={0}
            max={nearestKUp(parsedMaxRequestedAmount?.amount, 1000)}
            defaultValue={parsedRequestedAmount?.amount}
            onChange={(e) =>
              handleSliderChange(
                +e.target.value,
                parsedMaxRequestedAmount?.amount,
                'requestedAmount'
              )
            }
          />
        </RangeSliderContent>
      </RequestedAmountSection>
    </>
  );
};
