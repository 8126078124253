import { AnimatePresence } from 'framer-motion';
import { useCallback, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ButtonFeedback, Input } from '@boopos/design-system';

import {
  ActionSideModal,
  ActionSideModalCloseHeader,
  ActionSideModalCommonStyles,
  ActionSideModalLoading,
  bottomNavAnimationVariants,
  useNavigateFromActionSideModal,
} from 'components/ActionSideModal';

import { getInputErrorProps } from 'lib/forms';
import { ListingSellerType } from 'lib/types';

import { useContactSeller } from './useContactSeller';

interface ContactSellerModalProps {
  basePath: string;
}

type CopiesDic = { [key in ListingSellerType]: string };

export const ContactSellerModal = ({ basePath }: ContactSellerModalProps) => {
  const { t } = useTranslation();
  const params = useParams<{ listingId: string }>();
  const listingId = params.listingId as string;

  const { onClose } = useNavigateFromActionSideModal(params, { basePath });

  const handleSuccess = useCallback(async () => {
    setTimeout(onClose, 1000);
  }, [onClose]);

  const {
    sellerType,
    isReady,
    isSuccess,
    isSubmitting,
    defaultValues,
    formMethods,
    handleFormSubmitPrevention,
    handleRemoveError,
    handleFormSubmit,
  } = useContactSeller({
    listingId,
    handleSuccess,
  });

  const { register, handleSubmit, formState, reset } = formMethods;
  const messageErrors = formState?.errors?.message;
  const isEmptyMessage = !!messageErrors;

  const titles: CopiesDic = {
    [ListingSellerType.broker]: t('ContactSellerModal:title'),
    [ListingSellerType.proprietary]: t('ContactSellerModal:title_proprietary'),
  };

  const labels: CopiesDic = {
    [ListingSellerType.broker]: t('ContactSellerModal:label'),
    [ListingSellerType.proprietary]: t('ContactSellerModal:label_proprietary'),
  };

  const actions: CopiesDic = {
    [ListingSellerType.broker]: t('ContactSellerModal:save_action'),
    [ListingSellerType.proprietary]: t(
      'ContactSellerModal:save_action_proprietary'
    ),
  };

  const title = sellerType ? titles[sellerType] : '';
  const label = sellerType ? labels[sellerType] : '';
  const saveAction = sellerType ? actions[sellerType] : '';

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <ActionSideModal
      header={<ActionSideModalCloseHeader onClose={onClose} />}
      onClose={onClose}
    >
      <ActionSideModalCommonStyles.Title data-testid="contact-seller-title">
        {title}
      </ActionSideModalCommonStyles.Title>
      {isReady ? (
        <FormProvider {...formMethods}>
          <ActionSideModalCommonStyles.Form
            onSubmit={handleSubmit(handleFormSubmit)}
            onChange={handleRemoveError}
            onKeyDown={handleFormSubmitPrevention}
            data-testid="contact-seller-form"
          >
            <Input
              as="textarea"
              data-testid="contact-seller-message-input"
              label={label}
              rows={10}
              css={{ resize: 'none' }}
              {...getInputErrorProps(messageErrors)}
              {...register('message', {
                required: true,
              })}
            />
            <AnimatePresence>
              <ActionSideModalCommonStyles.BottomNav
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={bottomNavAnimationVariants}
              >
                <ActionSideModalCommonStyles.BottomNavLeftText />
                <ButtonFeedback
                  isLoading={isSubmitting}
                  isSuccess={isSuccess}
                  disabled={isEmptyMessage}
                  fullWidth
                >
                  {saveAction}
                </ButtonFeedback>
              </ActionSideModalCommonStyles.BottomNav>
            </AnimatePresence>
          </ActionSideModalCommonStyles.Form>
        </FormProvider>
      ) : (
        <ActionSideModalLoading />
      )}
    </ActionSideModal>
  );
};
