import React from 'react';
import { Navigate } from 'react-router-dom';

import { routePaths } from 'router/routePaths';

import { generateRedirectionChild } from './RedirectionHandler.helpers';

const redirectionMapping = [
  [routePaths.watchList, routePaths.dealFlow],
  [routePaths.watchlistLoan, routePaths.dealFlowDetail],

  [routePaths.watchlistLoanDealStructure, routePaths.dealFlowDealStructure],
  [routePaths.watchListProfitAndLoss, routePaths.dealFlowProfitAndLoss],
  [routePaths.watchlistCohorts, routePaths.dealFlowCohorts],

  [routePaths.watchListTermSheet, routePaths.dealFlowDetail],
  [routePaths.watchListAddBusiness, routePaths.dealFlowAddTargetBusiness],
  [routePaths.watchlistLoanArticlesOfIncorporation, routePaths.dealFlowDetail],
  [routePaths.watchlistLoanAccounts, routePaths.dealFlowDetail],
  [routePaths.watchlistShareholders, routePaths.dealFlowDetail],
  [routePaths.watchlistShareholdersAdd, routePaths.dealFlowDetail],
];

export const DealFlowRedirectionHandler = () => {
  const RedirectionChild = generateRedirectionChild({
    redirections: redirectionMapping,
  });

  return RedirectionChild ? (
    RedirectionChild
  ) : (
    <Navigate to={routePaths.dealFlow} replace />
  );
};
