import { useTranslation } from 'react-i18next';

import { InlineMessage } from 'ui/InlineMessage';

export const DealFlowListError = () => {
  const { t } = useTranslation();
  return (
    <div data-testid="deal-flow-list-error">
      <InlineMessage
        type="warning"
        heading={t('DealFlowListScreen:loading_error')}
      />
    </div>
  );
};
