import { useTranslation } from 'react-i18next';

import { Box, Button } from '@boopos/design-system';

import { FormFilesUploader } from 'components/FormFilesUploader';
import { Modal } from 'components/Modal';
import { AcceptedFilesEnum } from 'components/UploadZone/UploadZone.interface';

export const UploadCVModal = ({
  opened,
  control,
  onSubmit,
  name,
  onClose,
}: {
  opened: boolean;
  control: any;
  name: string;
  onClose: () => void;
  onSubmit: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      position="bottomSheetCentered"
      maxWidth={340}
      title={t('UploadCVModal:title')}
    >
      <Box css={{ px: '$4', pb: '$4' }}>
        <Box css={{ mb: '$4' }}>{t('UploadCVModal:content')}</Box>
        <FormFilesUploader
          control={control}
          name={name}
          maxFiles={1}
          accept={[AcceptedFilesEnum.pdf, AcceptedFilesEnum.images]}
        />
        <Button fullWidth css={{ mt: '2.4rem' }} onClick={onSubmit}>
          {t('UploadCVModal:upload')}
        </Button>
      </Box>
    </Modal>
  );
};
