export const applyHSSettings = (settings: Record<string, any>) => {
  window.hsConversationsSettings = {
    ...(window?.hsConversationsSettings ?? {}),
    ...settings,
  };
};

export const applyIdentifySettings = (email: string, token: string) => {
  applyHSSettings({
    identificationEmail: email,
    identificationToken: token,
  });
};

export const applyHSConversationsSettingsBase = () => {
  applyHSSettings({
    loadImmediately: false,
  });
};
