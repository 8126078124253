import { AnimatePresence } from 'framer-motion';
import { t } from 'i18next';
import { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { useOutletContext, useParams } from 'react-router-dom';

import { ButtonFeedback } from '@boopos/design-system';

import {
  ActionSideModal,
  ActionSideModalCloseHeader,
  ActionSideModalCommonStyles,
  ActionSideModalLoading,
  bottomNavAnimationVariants,
  useNavigateFromActionSideModal,
} from 'components/ActionSideModal';
import { DealStructureFields } from 'components/DealStructureFields';
import { useDealStructurePreview } from 'components/DealStructureHelpers';
import { useDealStructure } from 'components/DealStructureHelpers/useDealStructure';
import { DealStructurePreview } from 'components/DealStructurePreview';
import { DealStructureWarnings } from 'components/DealStructureWarnings';

import { routePaths } from 'router/routePaths';

interface IOutletContext {
  handleRefreshLoan: () => Promise<void>;
}

export const DealStructureModal = () => {
  const params = useParams<{ loanId: string }>();
  const loanId = params.loanId as string;

  const { onClose } = useNavigateFromActionSideModal(
    {
      loanId,
    },
    { basePath: routePaths.dealFlowDetail }
  );

  const { handleRefreshLoan } = useOutletContext<IOutletContext>();

  const handleSuccess = useCallback(async () => {
    await handleRefreshLoan();
    onClose();
  }, [onClose, handleRefreshLoan]);

  const {
    isReady,
    isSuccess,
    isSubmitting,
    defaultValues,
    formMethods,
    hasError,
    handleFormSubmitPrevention,
    handleRemoveError,
    handleFormSubmit,
  } = useDealStructure({
    loanId,
    handleSuccess,
  });

  const { handleSubmit, watch } = formMethods;

  const { previewData } = useDealStructurePreview(watch(), {
    loanId,
  });

  return (
    <ActionSideModal
      header={<ActionSideModalCloseHeader onClose={onClose} />}
      onClose={onClose}
    >
      <ActionSideModalCommonStyles.Title data-testid="deal-structure-title">
        {t('DealStructureModal:title')}
      </ActionSideModalCommonStyles.Title>
      {isReady ? (
        <FormProvider {...formMethods}>
          <ActionSideModalCommonStyles.Form
            onSubmit={handleSubmit(handleFormSubmit)}
            onChange={handleRemoveError}
            onKeyDown={handleFormSubmitPrevention}
            data-testid="deal-structure-form"
          >
            <DealStructureFields
              defaultValues={defaultValues}
              previewData={previewData}
            />
            <DealStructurePreview
              previewData={previewData}
              enable={!!watch('businessPrice')}
            />
            <DealStructureWarnings
              hasApiError={hasError}
              message={previewData.message}
            />
            <AnimatePresence>
              <ActionSideModalCommonStyles.BottomNav
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={bottomNavAnimationVariants}
              >
                <ActionSideModalCommonStyles.BottomNavLeftText />
                <ButtonFeedback isLoading={isSubmitting} isSuccess={isSuccess}>
                  {t('DealStructureModal:save_action')}
                </ButtonFeedback>
              </ActionSideModalCommonStyles.BottomNav>
            </AnimatePresence>
          </ActionSideModalCommonStyles.Form>
        </FormProvider>
      ) : (
        <ActionSideModalLoading />
      )}
    </ActionSideModal>
  );
};
