import { generatePath, useLocation, useParams } from 'react-router-dom';
import useSWR from 'swr';

import api from 'lib/api';
import { IBusinessApi } from 'lib/types';

import { routePaths } from 'router/routePaths';

export const usePortfolioBackButton = () => {
  const params = useParams();
  const location = useLocation();
  const { businessId } = params ?? {};

  const { data: business } = useSWR<IBusinessApi>(
    businessId && api.getPortfolioBusiness(businessId)
  );

  const businessName = business?.name ?? '';

  const fallbackUrl = businessId
    ? generatePath(routePaths.portfolioBusiness, params)
    : routePaths.portfolio;

  const backButtonUrl = location.state?.backUrl ?? fallbackUrl;

  return {
    backButtonUrl,
    businessName,
  };
};
