import { AnimatePresence } from 'framer-motion';

import { FilePreview } from './FilePreview';
import { IFilesPreview } from './FilesPreview.interface';
import { FilesPreviewTitle, FilesPreviewWrapper } from './FilesPreview.styled';

export const FilesPreview = ({
  disabled,
  files,
  onDeleteFile,
  title,
  showEmpty = true,
}: IFilesPreview) => {
  if (!files?.length && !showEmpty) return null;

  return (
    <FilesPreviewWrapper>
      {title && <FilesPreviewTitle>{title}</FilesPreviewTitle>}
      <AnimatePresence initial={false}>
        {(files || []).map(({ id, downloadUrl, name }) => (
          <FilePreview
            key={id}
            id={id}
            downloadUrl={downloadUrl}
            name={name}
            onDeleteFile={onDeleteFile}
            disabled={disabled}
          />
        ))}
      </AnimatePresence>
    </FilesPreviewWrapper>
  );
};
