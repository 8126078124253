import { AnimatePresence } from 'framer-motion';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Text } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { UploadButton } from 'components/UploadButton';

import { IUploadedFile } from 'lib/types';

import { FilePreview } from './FilePreview';
import { UploadButtonWrapper } from './UploadWithPreview.styles';

export const UploadWithPreview = ({
  loanId,
  businessId,
  children,
  onFilesChange,
  variant,
}: {
  loanId: string;
  businessId: string;
  children: ReactNode;
  onFilesChange: (files: IUploadedFile[]) => void;
  variant?: string;
}) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<IUploadedFile[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const onFilesUploaded = useCallback((newFiles: IUploadedFile[]) => {
    setFiles((currentFiles) => [...currentFiles, ...newFiles]);
  }, []);

  const onStart = useCallback(() => {
    setIsUploading(true);
  }, []);

  const onFinish = useCallback(() => {
    setIsUploading(false);
  }, []);

  const onDelete = useCallback((file: IUploadedFile) => {
    setFiles((currentFiles) =>
      currentFiles.filter((f) => f.downloadUrl !== file.downloadUrl)
    );
  }, []);

  useEffect(() => {
    onFilesChange(files);
  }, [onFilesChange, files]);

  return (
    <>
      <UploadButtonWrapper>
        <Text bold>{t('ShareHoldersScreen:upload_passport_label')}</Text>
        <UploadButton
          onFilesUploaded={onFilesUploaded}
          disabled={isUploading}
          onStart={onStart}
          onFinish={onFinish}
          buttonChildren={children}
          dropChildren=""
          multiple={true}
          buttonProps={{
            type: 'button',
            css: { width: 'auto', px: '$6' },
            leftIcon: (
              <FontAwesomeIcon icon={regular('arrow-up-from-bracket')} />
            ),
            'data-testid': 'upload-button',
            variant,
          }}
          wrapperProps={{
            css: { padding: 0, border: 0 },
          }}
          uploadParams={{
            loanId,
            businessId,
          }}
        />
      </UploadButtonWrapper>
      {files.length > 0 && (
        <Box css={{ mt: '$6', mb: '$9' }}>
          <AnimatePresence>
            {files.map((file) => (
              <FilePreview
                data-testid="file-preview"
                key={file.downloadUrl}
                file={file}
                onDelete={onDelete}
              />
            ))}
          </AnimatePresence>
        </Box>
      )}
    </>
  );
};
