import { ReactElement } from 'react';

import { ListingSellerType } from 'lib/types';

export const SellerTypeRender = {
  Proprietary: (props: {
    sellerType?: ListingSellerType;
    children: ReactElement;
  }): ReactElement | null =>
    props?.sellerType === ListingSellerType.proprietary ? props.children : null,
  Broker: (props: {
    sellerType?: ListingSellerType;
    children: ReactElement;
  }): ReactElement | null =>
    props?.sellerType === ListingSellerType.broker ? props.children : null,
};
