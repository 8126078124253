import { css, styled } from '@boopos/design-system';

export const BoxesWrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  columnGap: '$4',
  rowGap: '$4',
  mb: '$6',
});

export const accountButtonClass = css({
  cursor: 'pointer',
  border: 0,
  margin: 0,
  padding: 0,
  background: '$slate100',
  width: '100%',
  borderRadius: '1.6rem',
  px: '$4',
  py: '$2',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&[disabled]': {
    opacity: 0.5,
  },
  img: {
    maxWidth: '100%',
  },
});

export const accountCardClass = css({
  gridColumn: 'span 12',

  '@bp2': {
    gridColumn: 'span 4',
  },
});
