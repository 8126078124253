import { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { Merchants } from 'components/AccountConnectors';
import {
  merchantLiterals,
  merchantLiteralsSubtitles,
} from 'components/AccountConnectors/literals';

import { searchInList } from 'lib/search/searchInList';

import { MerchantObject } from './BusinessConnections.types';

const sortedMerchants = Object.keys(merchantLiterals).map((provider) => ({
  label: merchantLiterals[provider as Merchants],
  value: provider,
  subtitle: merchantLiteralsSubtitles[provider as Merchants],
})) as MerchantObject[];

export const useConnectionsList = () => {
  const [search, setSearch] = useState('');
  const filteredAccounts = useMemo(() => {
    return searchInList<MerchantObject>(sortedMerchants, {
      key: 'label',
      search,
    });
  }, [search]);

  const onChangeInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  return { accounts: filteredAccounts, search, onChangeInput };
};
