import {
  InsightsPreparedData,
  YearlyGrowthDataEntry,
} from 'components/InsightsCharts/InsightsCharts.interface';

export const generateYearlyGrowth = (
  rawValues: InsightsPreparedData[],
  {
    valueKey,
    slice = 12,
  }: { valueKey: keyof InsightsPreparedData; slice?: number }
): YearlyGrowthDataEntry[] => {
  if (rawValues?.length < slice * 2) {
    return [];
  }

  const firstHalf = rawValues.slice(0, slice);
  const secondHalf = rawValues.slice(slice);

  const combinedData = firstHalf.map((item, index) => ({
    rangeStartDate: item.dateAccounting,
    rangeStartValue: item[valueKey],
    rangeEndDate: secondHalf[index].dateAccounting,
    rangeEndValue: secondHalf[index][valueKey],
  }));

  return combinedData;
};
