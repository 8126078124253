import { useOutletContext } from 'react-router-dom';

import { PortfolioApplicationContext } from 'components/PortfolioApplication/PorfolioApplicationRouteWrapper';

import {
  LoanTypeByApplicationType,
  UsePortfolioApplicationFlowProps,
} from './PortfolioApplicationFlow.interface';
import {
  getApplicationTypeByLoanType,
  getNavigationPaths,
} from './PortfolioApplicationFlow.utils';

export {
  PortfolioApplicationSteps,
  PortfolioApplicationType,
} from './PortfolioApplicationFlow.interface';

export const usePortfolioApplicationFlow = ({
  loanType: loanTypeProp,
  applicationType: applicationTypeProp,
  applicationStep,
}: UsePortfolioApplicationFlowProps) => {
  const outletContext = useOutletContext<PortfolioApplicationContext>();
  const { flowPaths } = outletContext ?? {};

  const navigationPaths = getNavigationPaths(flowPaths);

  const applicationType = getApplicationTypeByLoanType({
    loanType: loanTypeProp,
    applicationType: applicationTypeProp,
  });

  const loanType = applicationType
    ? LoanTypeByApplicationType[applicationType]
    : loanTypeProp;

  const afterSuccessPath =
    navigationPaths?.[applicationType]?.[applicationStep] ?? flowPaths.fallback;

  return {
    loanType,
    afterSuccessPath,
  };
};
