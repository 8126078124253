import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { routePaths } from 'router/routePaths';

const removeBackground = {
  '&, &:hover': {
    background: 'none',
  },
  padding: 0,
  mt: '1.6rem',
};

export const ForSaleLink = ({ listingId }: { listingId?: string }) => {
  const { t } = useTranslation();
  if (!listingId) {
    return null;
  }

  const forSaleDetail = generatePath(routePaths.forSaleDetail, {
    listingId,
  });

  return (
    <div>
      <Button
        as="a"
        href={forSaleDetail}
        target="_blank"
        variant="tertiary"
        leftIcon={
          <FontAwesomeIcon icon={regular('arrow-up-right-from-square')} />
        }
        css={removeBackground}
        data-testid="for-sale-link"
      >
        {t('ForSaleDetailApplication:how_buyer_see')}
      </Button>
    </div>
  );
};
