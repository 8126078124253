import { useTranslation } from 'react-i18next';

import { ContentCell } from 'ui/ContentCell';

import { DocumentsDetails } from 'components/DocumentsDetails';

import { getDocumentFiles } from 'lib/files';
import { ILoanApi, LoanDocumentType } from 'lib/types';

import {
  AdditionalDetailsParent,
  AdditionalDetailsPreview,
} from './AdditionalDetails.styles';
import { BasicInformationDetailsPropsWithoutLoan } from './BasicInformationDetails.interface';
import { useAdditionalDetailsProps } from './useAdditionalDetailsProps';

export const BasicInformationAdditionalDetails = ({
  documents,
  editable = true,
  showIcon = true,
  additionalDetails,
  path,
}: Exclude<BasicInformationDetailsPropsWithoutLoan, 'paths'> & {
  path: string;
  additionalDetails: string;
}) => {
  const { t } = useTranslation();

  const additionalDetailsProps = useAdditionalDetailsProps({} as ILoanApi, {
    editable,
    path,
  });

  const hasContent =
    getDocumentFiles(LoanDocumentType.additional_details, documents).length >
      0 || !!additionalDetails;

  if (!editable && !hasContent) return null;

  return (
    <ContentCell
      title={t('BasicInformationDetails:additional_details')}
      data-testid="additional-details"
      showIcon={showIcon}
      {...additionalDetailsProps}
    >
      <AdditionalDetailsParent>
        <AdditionalDetailsPreview additionalDetails={additionalDetails} />
        <DocumentsDetails
          documents={documents}
          documentType={LoanDocumentType.additional_details}
        />
      </AdditionalDetailsParent>
    </ContentCell>
  );
};
