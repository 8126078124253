import useSWR from 'swr';

import api from 'lib/api';

export const useConnectAccountInvitation = ({
  businessId,
}: {
  businessId?: string;
}) => {
  const {
    data,
    error,
    mutate: refreshBusinessAccounts,
  } = useSWR(api.getBusinessAsGuest(businessId as string));

  const isUnauthorized = error?.response?.status === 401;
  const isLoading = !data;

  const {
    name: businessName,
    accountsConnectedPending: businessAccounts,
    users,
    invitationAccountType,
  } = data || {};
  const { firstName, lastName } = users?.[0] || {};
  const userName = firstName && lastName ? `${firstName} ${lastName}` : {};

  return {
    isUnauthorized,
    isLoading,
    businessName,
    businessAccounts,
    userName,
    refreshBusinessAccounts,
    invitationAccountType,
  };
};
