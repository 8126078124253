import { ErrorCode } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { AcceptedFilesEnum, AcceptedMimeTypes } from './UploadZone.interface';

export const fileExtensions = {
  pdf: ['.pdf'],
  csv: ['.csv'],
  xls: ['.xls'],
  xlsx: ['.xlsx'],
  images: ['.png', '.jpg', '.jpeg', '.gif'],
};

const getImageMimeTypes = (type: AcceptedFilesEnum) => {
  const imageFileExtensions = fileExtensions[type];
  return imageFileExtensions.reduce(
    (acc, ext) => ({
      ...acc,
      [`image/${ext.replace('.', '')}`]: [ext],
    }),
    {}
  );
};

const getPlainAcceptedFiles = (type: AcceptedFilesEnum) => {
  if (type === AcceptedFilesEnum.images) {
    return getImageMimeTypes(type);
  }

  return {
    [AcceptedMimeTypes[type]]: fileExtensions[type],
  };
};

export const getAcceptedFileTypes = (types: AcceptedFilesEnum[]) => {
  return types.reduce(
    (acc, type) => ({
      ...acc,
      ...getPlainAcceptedFiles(type),
    }),
    {}
  );
};

export const defaultAcceptedFileTypes = Object.values(AcceptedFilesEnum);
export const defaultMaxSizeMb = 50;

export const useUnknownErrorLiteral = () => {
  const { t } = useTranslation();
  return t('FileUploaderErrors:unknown_error');
};

export const useGetGeneralErrorLiteral = ({
  acceptedFilesLiteral,
  maxSizeMb,
}: {
  acceptedFilesLiteral: string;
  maxSizeMb: number;
}) => {
  const { t } = useTranslation();
  return t('FileUploaderErrors:general_upload_process_error_message', {
    acceptedFiles: acceptedFilesLiteral,
    maxSizeMb,
  });
};

export const useGetRejectedErrorLiterals = ({
  accept,
  acceptedFilesLiteral,
  maxSizeMb,
}: {
  accept: AcceptedFilesEnum[];
  acceptedFilesLiteral?: string;
  maxSizeMb?: number;
}) => {
  const { t } = useTranslation();

  const errors: { [key: string]: any } = {
    [ErrorCode.FileTooSmall]: t('FileUploaderErrors:small_file_error'),
    [ErrorCode.TooManyFiles]: t('FileUploaderErrors:too_many_files_error'),
  };

  if (accept) {
    errors[ErrorCode.FileInvalidType] = t(
      'FileUploaderErrors:invalid_file_error',
      {
        accepted: acceptedFilesLiteral,
      }
    );
  }

  if (maxSizeMb) {
    errors[ErrorCode.FileTooLarge] = t('FileUploaderErrors:big_file_error', {
      max: maxSizeMb,
    });
  }

  return errors;
};

export const getAcceptedFilesMessage = (accept: AcceptedFilesEnum[]) =>
  accept
    .map((type: AcceptedFilesEnum) =>
      type === AcceptedFilesEnum.images ? type : type.toUpperCase()
    )
    .join(', ')
    .replace(/, ([^,]*)$/, ' and $1');

export const getBytesMaxSize = (maxSizeMb: number) =>
  maxSizeMb * Math.pow(1024, 2);

export const parseFilesToApi = (
  files: File[],
  params?: {
    [key: string]: string;
  }
) =>
  files.map((file: File) => ({
    file,
    fileName: file.name,
    params,
  }));
