import { ReactElement, cloneElement } from 'react';
import { Navigate, useLocation, useMatch } from 'react-router-dom';
import useMedia from 'react-use/lib/useMedia';

export const RenderOrNavigate = ({
  component: Component,
  basePath,
  mediaQuery,
  navigateTo,
}: {
  component: ReactElement;
  basePath: string;
  mediaQuery: string;
  navigateTo: string;
}) => {
  const location = useLocation();
  const isBasePath = useMatch(basePath);
  const isMatching = useMedia(mediaQuery);

  if (isBasePath && isMatching) {
    return <Navigate to={navigateTo} replace state={location.state} />;
  }

  return cloneElement(Component, {
    renderWrapperElements: !!isBasePath || isMatching,
  });
};
