import { Control, FormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CurrencyHookInput } from 'components/CurrencyInput';

import { defaultCurrency } from 'lib/constants';
import { getInputErrorProps } from 'lib/forms';

import { DealStructureFormValues } from './ApplicationDealStructureForm.interface';

export const DealStructureFormFields = ({
  control,
  formState,
}: {
  control: Control<DealStructureFormValues>;
  formState: FormState<DealStructureFormValues>;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <CurrencyHookInput
        label={t('PortfolioDealStructureForm:asking_price_label')}
        data-testid="asking-price-input"
        name="askingPrice"
        currency={defaultCurrency}
        control={control}
        rules={{
          required: t('PortfolioDealStructureForm:asking_price_required'),
          min: {
            value: 1,
            message: t('PortfolioDealStructureForm:asking_price_required'),
          },
        }}
        autoFocus
        {...getInputErrorProps(formState?.errors?.askingPrice)}
      />
      <CurrencyHookInput
        label={t('PortfolioDealStructureForm:inventory_label')}
        data-testid="inventory-input"
        name="inventoryPrice"
        currency={defaultCurrency}
        control={control}
        groupSpacing="xl"
        subtext={t('PortfolioDealStructureForm:inventory_optional')}
      />
    </>
  );
};
