import qs from 'query-string';

import config from 'lib/config';

import { ApiClient } from './api-client';

class Api extends ApiClient {
  getBrokerListing(broker: string, brokerListingId: string) {
    return `/listing/${broker}/${brokerListingId}`;
  }

  getCategories() {
    return '/listings/categories';
  }

  getBrokersList() {
    return '/brokers';
  }

  getQualification() {
    return '/me/business-owner/qualification';
  }

  getQualificationHttp() {
    return this.http.get(this.getQualification());
  }

  getDeals() {
    return '/me/deals';
  }

  getWatchlistDeal(loanId: string) {
    return `/me/watchlist/deals/${loanId}`;
  }

  getHubspotToken() {
    return '/me/crm/conversations/token';
  }

  getPortfolioBusiness(businessId: string) {
    return `/me/portfolio/businesses/${businessId}`;
  }

  getPortfolioLoan(businessId: string, loanId: string) {
    return `/me/portfolio/businesses/${businessId}/loans/${loanId}`;
  }

  editPortfolioBusiness(businessId: string, body: any) {
    return this.http.post(`/me/portfolio/businesses/${businessId}`, body);
  }

  updateMe(body: any) {
    return this.http.put('/me/business-owner', body);
  }

  addFavourite(listingId: string) {
    return this.http.post(`/me/listings/${listingId}/bookmarks`);
  }

  removeFavourite(listingId: string) {
    return this.http.delete(`/me/listings/${listingId}/bookmarks`);
  }

  getUploadUrl(file: any) {
    return this.http.post('/me/documents/upload-url', file);
  }

  updateQualificationStatusFunds(body: any) {
    return this.http.post('/me/business-owner/qualification/funds', body);
  }

  updateQualificationStatusCreditReport(body: any) {
    return this.http.post(
      '/me/business-owner/qualification/credit-report',
      body
    );
  }

  initMerchantConnection(provider: string) {
    return this.http.get(`/me/merchants/${provider}/connect`);
  }

  connectMerchant(provider: string, token: string) {
    return this.http.post(`/me/merchants/${provider}/connect`, {
      token,
    });
  }

  disconnectMerchant(provider: string, externalId: string) {
    return this.http.post(`/me/merchants/${provider}/disconnect`, {
      externalId,
    });
  }

  initMerchantBusinessConnection(provider: string, businessId: string) {
    return this.http.get(`/me/merchants/${provider}/connect/${businessId}`);
  }
  initMerchantBusinessConnectionSWR(provider: string, businessId: string) {
    return `/me/merchants/${provider}/connect/${businessId}`;
  }

  connectMerchantBusiness(provider: string, businessId: string, token: string) {
    return this.http.post(`/me/merchants/${provider}/connect/${businessId}`, {
      token,
    });
  }

  merchantsDisconnectAccount({
    provider,
    businessId,
    externalId,
  }: {
    provider: string;
    businessId: string;
    externalId: string;
  }) {
    return this.http.post(
      `/me/businesses/${businessId}/merchants/${provider}/disconnect`,
      { externalId }
    );
  }

  downloadTermSheet(loanId: string) {
    return this.http.get(`/me/business-owner/loans/${loanId}/term-sheet`);
  }

  signTermSheet(loanId: string) {
    return this.http.post(`/me/loans/${loanId}/term-sheet/sign`);
  }

  createWatchlistDeal(body: any) {
    return this.http.post('/me/watchlist/deals', body);
  }

  dealStructurePreview(loanId: string, body: any) {
    return this.http.post(
      `/me/watchlist/deals/${loanId}/structure/preview`,
      body
    );
  }

  dealStructureSave(loanId: string, body: any) {
    return this.http.post(`/me/watchlist/deals/${loanId}/structure`, body);
  }

  deleteBusinessDeal(loanId: string) {
    return this.http.delete(`/me/watchlist/deals/${loanId}`);
  }

  updateBusinessDealDocuments(loanId: string, body: any) {
    return this.http.post(`/me/watchlist/deals/${loanId}/documents`, body);
  }

  requestFullBusinessDetails(listingId: string, body: any) {
    return this.http.post(
      `/me/watchlist/listings/${listingId}/request-access`,
      body
    );
  }

  sendDealToEvaluation(loanId: string) {
    return this.http.post(`/me/watchlist/deals/${loanId}/evaluate`);
  }

  createLoan(businessId: string, body: any) {
    return this.http.post(`/me/portfolio/businesses/${businessId}/loans`, body);
  }

  sendLoanToEvaluation({
    businessId,
    loanId,
  }: {
    businessId: string;
    loanId: string;
  }) {
    return this.http.post(
      `/me/portfolio/businesses/${businessId}/loans/${loanId}/evaluate`
    );
  }

  sendToDueDiligence({
    businessId,
    loanId,
  }: {
    businessId: string;
    loanId: string;
  }) {
    return this.http.post(
      `/me/portfolio/businesses/${businessId}/loans/${loanId}/submit-contract`
    );
  }

  updateLoan(
    { businessId, loanId }: { businessId: string; loanId: string },
    body: any
  ) {
    return this.http.post(
      `/me/portfolio/businesses/${businessId}/loans/${loanId}`,
      body
    );
  }

  updateLegalEntity({ loanId }: { loanId: string }, body: any) {
    return this.http.put(`/me/loans/${loanId}/acquiring-legal-entity`, body);
  }

  deleteLegalEntity({ loanId }: { loanId: string }) {
    return this.http.delete(`/me/loans/${loanId}/acquiring-legal-entity`);
  }

  updateFundsBankAccount({ loanId }: { loanId: string }, body: any) {
    return this.http.put(`/me/loans/${loanId}/funds-bank-account`, body);
  }

  deleteFundsBankAccount({ loanId }: { loanId: string }) {
    return this.http.delete(`/me/loans/${loanId}/funds-bank-account`);
  }

  createContractPerson(
    { businessId, loanId }: { businessId: string; loanId: string },
    body: any
  ) {
    return this.http.post(
      `/me/portfolio/businesses/${businessId}/loans/${loanId}/contract/persons`,
      body
    );
  }

  removeContractPerson({
    businessId,
    loanId,
    personId,
  }: {
    businessId: string;
    loanId: string;
    personId: string;
  }) {
    return this.http.delete(
      `/me/portfolio/businesses/${businessId}/loans/${loanId}/contract/persons/${personId}`
    );
  }

  inviteToMerchantConnection(businessId: string, body: any) {
    return this.http.post(`/me/businesses/${businessId}/invitations`, body);
  }

  getBusinessAsGuest(businessId: string) {
    return `/me/portfolio/businesses/${businessId}/guest`;
  }

  shareSellingDeal(loanId: string, email: string) {
    return this.http.post(`/me/loans/${loanId}/invite-buyer`, { email });
  }

  generateSellingDeal(loanId: string) {
    return this.http.post('/users/loans/invitations', {
      loanId,
    });
  }

  getDealInvitation(invitationId: string) {
    return `/users/loans/invitations/${invitationId}`;
  }

  acceptInvitation(invitationId: string) {
    return this.http.post(`/me/loans/invitations/${invitationId}`);
  }

  getLoanPayments(loanId: string) {
    return `/me/business-owner/loans/${loanId}/payments`;
  }

  async downloadPayment(invoiceUrl: string) {
    const response = await this.http.get(invoiceUrl);

    const { url } = response.data;
    const fileResponse = await fetch(url);

    return fileResponse.blob();
  }

  getPrivacyConfig() {
    return this.http.get('/me/privacy');
  }

  getPrivacyBearerToken() {
    return this.http.get('/me/privacy/token');
  }

  getLoan(loanId: string) {
    return `/me/loans/${loanId}`;
  }

  getLoanHttp(loanId: string) {
    return this.http.get(this.getLoan(loanId));
  }

  updateLetterOfIntent(loanId: string, body: any) {
    return this.http.put(`/me/loans/${loanId}/letter-of-intent`, body);
  }

  deleteLetterOfIntent(loanId: string) {
    return this.http.delete(`/me/loans/${loanId}/letter-of-intent`);
  }

  updateContractPeople(loanId: string, body: any) {
    return this.http.put(`/me/loans/${loanId}/contract-people`, body);
  }

  updateAssetPurchaseAgreement(loanId: string, body: any) {
    return this.http.put(`/me/loans/${loanId}/asset-purchase-agreement`, body);
  }

  deleteAssetPurchaseAgreement(loanId: string) {
    return this.http.delete(`/me/loans/${loanId}/asset-purchase-agreement`);
  }

  deleteContractPeople(loanId: string) {
    return this.http.delete(`/me/loans/${loanId}/contract-people`);
  }

  updateContractSigners(loanId: string, body: any) {
    return this.http.put(`/me/loans/${loanId}/signers`, body);
  }

  deleteContractSigners(loanId: string) {
    return this.http.delete(`/me/loans/${loanId}/signers`);
  }
  getPaymentMethodsList(loansId: string) {
    return `/me/loans/${loansId}/payment-methods`;
  }
  addBankAccount(loanId: string, body: any) {
    return this.http.post(
      `/me/loans/${loanId}/payment-methods/bank-account`,
      body
    );
  }
  deletePaymentMethod(
    loanId: string,
    paymentMethodId: string,
    newDefaultPaymentMethodId?: string
  ) {
    return this.http.delete(
      `/me/loans/${loanId}/payment-methods/${paymentMethodId}${
        newDefaultPaymentMethodId
          ? `?defaultPaymentMethodId=${newDefaultPaymentMethodId}`
          : ''
      }`
    );
  }

  setDefaultPaymentMethod(loanId: string, paymentMethodId: string) {
    return this.http.post(
      `/me/loans/${loanId}/payment-methods/${paymentMethodId}/default`
    );
  }

  setupStripe(loanId: string) {
    return this.http.post(`/me/loans/${loanId}/payment-methods/card/setup`);
  }

  addCard(loanId: string, body: any) {
    return this.http.post(`/me/loans/${loanId}/payment-methods/card`, body);
  }

  getLoanActivity({ loanId, ...rest }: { loanId: string }) {
    return `/me/loans/${loanId}/activities?${qs.stringify(rest)}`;
  }

  getBusinessScores(businessId: string) {
    return `/me/portfolio/businesses/${businessId}/scores`;
  }

  getBusinessValuation(businessId: string) {
    return `/me/portfolio/businesses/${businessId}/valuation`;
  }

  addAdditionalDetails(loanId: string, body: any) {
    return this.http.post(`/me/loans/${loanId}/additional-details`, body);
  }

  getBusinessInsightsScores(businessInsightsId: string, businessId: string) {
    return `/me/business-insights/${businessInsightsId}?forBusinessId=${businessId}`;
  }

  getBusinessInsightsCheckout<T>(
    businessInsightsId: string,
    businessId: string,
    body: any
  ) {
    return this.http.post<T>(
      `/me/business-insights/${businessInsightsId}/checkout?forBusinessId=${businessId}`,
      body
    );
  }

  addUserGoal(body: any) {
    return this.http.post('/me/goals', body);
  }
}

class ApiV2 extends Api {
  getSwrPath(path: string) {
    return `${this.apiPrefix}${path}`;
  }

  async createBusinessWithLoan(body: any) {
    return this.http.post('/business-owner/portfolio/applications', body);
  }

  updateApplication(applicationId: string, body: any) {
    return this.http.put(
      `/business-owner/portfolio/applications/${applicationId}`,
      body
    );
  }

  submitApplication(applicationId: string) {
    return this.http.post(
      `/business-owner/portfolio/applications/${applicationId}/submit`
    );
  }

  getForSaleFiltersConfig() {
    return this.getSwrPath('/listings/search-config');
  }

  getInvestmentCriteria() {
    return this.getSwrPath('/business-owner/investment-criteria');
  }

  updateInvestmentCriteria(body: any) {
    return this.http.put(`/business-owner/investment-criteria`, body);
  }

  getBusinessPortfolio(params: any) {
    return `/business-owner/portfolio?${qs.stringify(params)}`;
  }

  businessSellApplication(businessId: string) {
    return this.getSwrPath(
      `/business-owner/portfolio/businesses/${businessId}/sell-application`
    );
  }

  businessInsightsChartsForBuyer(businessId: string) {
    if (!businessId) {
      return;
    }
    return this.getSwrPath(
      `/business-owner/businesses/${businessId}/insights-charts`
    );
  }

  businessInsightsCharts(businessId: string) {
    if (!businessId) {
      return;
    }

    return this.getSwrPath(
      `/business-owner/portfolio/businesses/${businessId}/insights-charts`
    );
  }

  businessCohortsChartsForBuyer(businessId: string) {
    if (!businessId) {
      return;
    }
    return this.getSwrPath(
      `/business-owner/businesses/${businessId}/insights-cohorts`
    );
  }

  businessCohortsCharts(businessId: string) {
    if (!businessId) {
      return;
    }

    return this.getSwrPath(
      `/business-owner/portfolio/businesses/${businessId}/insights-cohorts`
    );
  }

  sellApplication(applicationId: string) {
    return this.getSwrPath(
      `/business-owner/portfolio/applications/${applicationId}`
    );
  }

  getListing(id: string) {
    return this.getSwrPath(`/listings/${id}`);
  }

  getListingSlug(id: string) {
    return this.getSwrPath(`/listings/${id}/slug`);
  }

  getMe(): string {
    return this.getSwrPath('/business-owner/profile');
  }

  setConfidentialityAgreement(body: any) {
    return this.http.post(
      '/business-owner/profile/confidentiality-agreement',
      body
    );
  }

  getListings(params: any) {
    return `/listings/search?params=${JSON.stringify(params)}`;
  }

  filterListingsPost(urlWithParams: string) {
    const { query, url } = qs.parseUrl(urlWithParams);
    const { params } = query;
    return this.http
      .post(url, JSON.parse(params as string))
      .then((response) => response.data);
  }

  getListingStats(listingId: string) {
    return this.getSwrPath(
      `/business-owner/portfolio/listings/${listingId}/stats`
    );
  }

  getListingEnquiries(params: { listingId: string }) {
    const { listingId, ...rest } = params;
    if (!listingId) {
      return null;
    }

    return `/business-owner/portfolio/buy-applications?${qs.stringify({
      listingId,
      ...rest,
    })}`;
  }

  async resolvePaginated(url: string) {
    const result = await this.http.get(url);
    return result.data;
  }

  getListingEnquiry(enquiryId: string) {
    return this.getSwrPath(
      `/business-owner/portfolio/buy-applications/${enquiryId}`
    );
  }

  getListingEnquiryByToken(token: string) {
    return this.getSwrPath(`/listings/${token}/enquiries/grant-access`);
  }

  saveEnquiry(enquiryId: string, body: any) {
    return this.http.patch(
      `/business-owner/portfolio/buy-applications/${enquiryId}`,
      body
    );
  }
  rejectEnquiry(enquiryId: string) {
    return this.http.post(
      `/business-owner/portfolio/buy-applications/${enquiryId}/reject`
    );
  }

  acceptListingEnquiry(listingId: string, token: string) {
    return this.http.post(`/listings/${listingId}/enquiries/grant-access`, {
      token,
    });
  }

  updateMe(body: any) {
    return this.http.put('/business-owner/profile', body);
  }

  getVaultConfig<T>() {
    return this.http.get<T>('/vault/config');
  }

  getVault<T>() {
    return this.http.get<T>('/vault');
  }

  updatePreferences(preferenceName: string, body: any) {
    return this.http.patch(
      `/business-owner/preferences/${preferenceName}`,
      body
    );
  }
}

const api = new Api({
  baseURL: config.API_BASE_URL,
});

export const apiV2 = new ApiV2({
  baseURL: config.API_BASE_URL,
  apiPrefix: '/v2',
});

export default api;
