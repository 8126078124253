import { atom } from 'jotai';
import { focusAtom } from 'jotai-optics';

import { IQualificationStatus } from './QualificationStatus.interface';

export const qualificationStatusAtom = atom<IQualificationStatus>({
  funds: {},
  creditReport: {},
  linkedin: {},
});

const fundsAtom = focusAtom(qualificationStatusAtom, (optic) =>
  optic.prop('funds')
);

const creditReportAtom = focusAtom(qualificationStatusAtom, (optic) =>
  optic.prop('creditReport')
);

const profileAtom = focusAtom(qualificationStatusAtom, (optic) =>
  optic.prop('linkedin')
);

export const proofOfFundStatusAtom = focusAtom(fundsAtom, (optic) =>
  optic.prop('status')
);

export const creditReportStatusAtom = focusAtom(creditReportAtom, (optic) =>
  optic.prop('status')
);

export const profileStatusAtom = focusAtom(profileAtom, (optic) =>
  optic.prop('status')
);
