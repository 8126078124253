import { styled } from '@boopos/design-system';

import { CardSection } from 'ui/CardSection';

export const Styles = {
  ValuationSection: styled(CardSection, {
    mb: '$6',
  }),
  EstimatedValuesSection: styled('div', {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      '&:first-child': {
        mr: '9rem',
      },
      mb: '3rem',
    },
  }),
  HistogramSection: styled(CardSection, {
    padding: '4rem 4rem 3rem',
  }),
  Text: styled('p', {
    lineHeight: '2.4rem',
    color: '$textBodyTertiary',
    mb: '$9',
  }),
};
