import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Box } from '@boopos/design-system';

import { NavigationCard } from 'ui/NavigationCard';

import { routePaths } from 'router/routePaths';

export const RequestLoanNavigation = () => {
  const { t } = useTranslation();
  const { businessId } = useParams();
  const navigate = useNavigate();

  const handleRequestGrowthLoan = useCallback(() => {
    navigate(
      generatePath(routePaths.portfolioRequestGrowthLoan, { businessId }),
      {
        state: {
          backUrl: generatePath(routePaths.portfolioFinancing, { businessId }),
        },
      }
    );
  }, [businessId, navigate]);

  return (
    <Box data-testid="request-loan-navigation">
      <NavigationCard
        variant="light"
        title={t('RequestLoanNavigation:growth_title')}
        description={t('RequestLoanNavigation:growth_description')}
        onClick={handleRequestGrowthLoan}
        data-testid="request-growth-loan-action"
      />
    </Box>
  );
};
