import { UserGoals } from 'lib/types';

import { useUser } from './useUser';

export const useUserGoals = () => {
  const { user } = useUser();

  const { profile } = user ?? {};
  const goals = profile?.goals ?? [];
  const hasGoals = !!goals?.length;
  const isBuyer = goals.includes(UserGoals.buy as UserGoals);

  return { hasGoals, isBuyer };
};
