import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { chartCSS, defaultChartColors } from 'ui/InsightsShared';

import {
  InsightsLineChartProps,
  TooltipRenderProps,
} from './InsightsLineChart.interface';
import { useChartStyles } from './InsightsLineChart.styles';

export type { TooltipRenderProps };

export const InsightsLineChart = <DataEntry extends {}>(
  props: InsightsLineChartProps<DataEntry>
) => {
  const {
    data,
    xAxisDataKey,
    xAxisTickFormatter,
    yAxisTickFormatter,
    oldestValuesLineDataKey,
    newestValuesLineDataKey,
    chartColors = defaultChartColors,
    renderTooltip,
  } = props;

  const styles = useChartStyles(chartColors);
  const { className } = chartCSS();

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart className={className} data={data} margin={styles.chartMargin}>
        <CartesianGrid
          strokeDasharray="4 4"
          vertical={false}
          stroke={chartColors.cartesianGrid}
          strokeOpacity={0.3}
        />
        <XAxis
          dataKey={xAxisDataKey}
          tickFormatter={xAxisTickFormatter}
          axisLine={false}
          tick={styles.axisTick}
          tickSize={2}
          tickMargin={10}
        />
        <YAxis
          axisLine={false}
          tickFormatter={yAxisTickFormatter}
          tickSize={2}
          tickMargin={10}
          tick={styles.axisTick}
        />
        <Tooltip
          offset={20}
          cursor={styles.tooltipCursor}
          content={renderTooltip}
        />
        <Line
          dataKey={oldestValuesLineDataKey}
          stroke={chartColors.bl200}
          strokeWidth={4}
          dot={false}
          activeDot={styles.lineActiveDot}
        />
        <Line
          dataKey={newestValuesLineDataKey}
          stroke={chartColors.bl500}
          strokeWidth={4}
          dot={false}
          activeDot={styles.lineActiveDot}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
