import Lottie from 'lottie-react';
import { useCallback, useEffect, useState } from 'react';

import { ON_APP_READY_EVENT } from 'lib/constants';
import { subscribe, unsubscribe } from 'lib/events';

import { AnimationWrapper } from './BooposAnimatedLogo.styles';
import animationData from './animationData.json';

export const BooposAnimatedLogo = () => {
  const [isAppReady, setAppReady] = useState(false);
  const [isAnimationComplete, setAnimationComplete] = useState(false);
  const isAppLoading = !(isAppReady && isAnimationComplete);

  const handleAppReady = useCallback(() => setAppReady(true), []);

  const handleComplete = () => {
    setAnimationComplete(true);
  };

  const render = () => {
    if (isAppLoading) {
      return (
        <AnimationWrapper>
          <Lottie
            animationData={animationData}
            onLoopComplete={handleComplete}
          />
        </AnimationWrapper>
      );
    }

    return null;
  };

  useEffect(() => {
    subscribe(ON_APP_READY_EVENT, handleAppReady);

    return () => {
      unsubscribe(ON_APP_READY_EVENT, handleAppReady);
    };
  }, [handleAppReady, isAppReady]);

  return <>{render()}</>;
};
