import dayjs from 'dayjs';

const now = () => dayjs().toISOString();

export const legalAgreements = {
  confidentiality: () => ({
    acceptedAt: now(),
    version: '2023-12-01',
    source: 'user',
  }),

  legalTerms: () => ({
    acceptedAt: now(),
    version: '2024-01-24',
    source: 'onboarding',
  }),
};
