import React from 'react';
import { Navigate } from 'react-router-dom';

import { routePaths } from 'router/routePaths';

import { generateRedirectionChild } from './RedirectionHandler.helpers';

const redirectionMapping = [
  [routePaths.listings, routePaths.forSale],
  [routePaths.listing, routePaths.forSaleDetail],

  [routePaths.listingsFilter, routePaths.forSaleFilter],
];

export const ForSaleRedirectionHandler = () => {
  const RedirectionChild = generateRedirectionChild({
    redirections: redirectionMapping,
  });

  return RedirectionChild ? (
    RedirectionChild
  ) : (
    <Navigate to={routePaths.forSale} replace />
  );
};
