import { ReactNode } from 'react';

export const RenderIfNotEmpty = ({
  children,
  value,
}: {
  children: ReactNode;
  value?: any;
}) => {
  if (!value) {
    return null;
  }

  return <>{children}</>;
};
