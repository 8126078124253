import { ContentCell } from 'ui/ContentCell';

import { IApiDocument } from 'lib/types';

import { routePaths } from 'router/routePaths';

import { BasicInformationAdditionalDetails } from './BasicInformationAdditionalDetails';
import { BasicInformationBalanceSheet } from './BasicInformationBalanceSheet';
import { BasicInformationCohorts } from './BasicInformationCohorts';
import { BasicInformationDealStructure } from './BasicInformationDealStructure';
import { BasicInformationDetailsProps } from './BasicInformationDetails.interface';
import { BasicInformationProfitAndLoss } from './BasicInformationProfitAndLoss';

export const defaultPaths = {
  dealStructure: routePaths.dealFlowDealStructure,
  profitAndLoss: routePaths.dealFlowProfitAndLoss,
  cohorts: routePaths.dealFlowCohorts,
  additionalDetails: routePaths.dealFlowAdditionalDetails,
};

const emptyArray: unknown[] = [];

export const BasicInformationDetails = ({
  loan,
  editable = true,
  paths = defaultPaths,
}: BasicInformationDetailsProps) => {
  const documents = loan?.documents ?? (emptyArray as IApiDocument[]);
  const requiredDocuments =
    loan?.requiredDocuments ?? (emptyArray as DocumentType[]);

  return (
    <ContentCell.Parent>
      {!!paths.dealStructure && (
        <BasicInformationDealStructure
          loan={loan}
          editable={editable}
          path={paths.dealStructure}
        />
      )}

      {!!paths?.profitAndLoss && (
        <BasicInformationProfitAndLoss
          documents={documents}
          requiredDocuments={requiredDocuments}
          editable={editable}
          path={paths.profitAndLoss}
        />
      )}

      {!!paths?.cohorts && (
        <BasicInformationCohorts
          documents={documents}
          requiredDocuments={requiredDocuments}
          editable={editable}
          path={paths.cohorts}
        />
      )}

      {!!paths?.balanceSheet && (
        <BasicInformationBalanceSheet
          documents={documents}
          requiredDocuments={requiredDocuments}
          editable={editable}
          path={paths.balanceSheet}
        />
      )}

      {!!paths?.additionalDetails && (
        <BasicInformationAdditionalDetails
          documents={documents}
          additionalDetails={loan?.additionalDetails ?? ''}
          editable={editable}
          path={paths.additionalDetails}
        />
      )}
    </ContentCell.Parent>
  );
};
