import { useTranslation } from 'react-i18next';

import { formatMoney } from 'lib/format';

import { TooltipWrapper } from './ListingChartTooltip.styled';

export const ListingChartTooltip = ({
  active,
  payload,
  label,
  currency,
  reduced,
}: any) => {
  const { t } = useTranslation();

  if (active && payload?.length) {
    return (
      <TooltipWrapper reduced={reduced}>
        <p data-testid="month">
          {t('ListingCard:month_txt', { month: label })}
        </p>
        <p data-testid="revenue">{`${t(
          'ListingCard:revenue_label'
        )}: ${formatMoney({
          amount: payload[0].value,
          currency,
        })}`}</p>
      </TooltipWrapper>
    );
  }

  return null;
};
