import { ComponentProps, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { ButtonFeedback, Checkbox, Input } from '@boopos/design-system';
import { validPhone } from '@boopos/utils';

import { OnboardingLayout } from 'components/OnboardingLayout';
import { SelectHookForm } from 'components/SelectHookForm';

import config from 'lib/config';
import { countriesForReactSelect } from 'lib/constants';
import { getInputErrorProps } from 'lib/forms';
import { useLoadingSuccess } from 'lib/hooks/useLoadingSuccess';

import { UserProfileFieldValues } from './UserProfileForm.interface';

export type SaveButtonProps = ComponentProps<typeof ButtonFeedback>;

export interface UserProfileFormProps {
  onSubmit: (data: UserProfileFieldValues) => Promise<void>;
  onSuccess: () => void;
  defaultValues?: UserProfileFieldValues;
  showResidencyField?: boolean;
  saveButtonProps: SaveButtonProps;
}

export const UserProfileForm = ({
  onSubmit,
  onSuccess,
  defaultValues,
  showResidencyField = true,
  saveButtonProps,
}: UserProfileFormProps) => {
  const { t } = useTranslation();

  const { register, formState, control, handleSubmit, watch } =
    useForm<UserProfileFieldValues>({
      defaultValues,
    });

  const { handle, isLoading, isSuccess } = useLoadingSuccess();

  const onSubmitInternal = useCallback(
    async (data: UserProfileFieldValues) => {
      await handle(onSubmit(data), { waitAfterSuccess: 1000, onSuccess });
    },
    [handle, onSubmit, onSuccess]
  );

  const country = watch('country');

  return (
    <form onSubmit={handleSubmit(onSubmitInternal)}>
      <OnboardingLayout.InputHalf>
        <Input
          label={t('UserProfileForm:name_label')}
          id="first-name"
          autoFocus
          {...register('firstName', {
            required: t('UserProfileForm:first_name_required'),
          })}
          {...getInputErrorProps(formState?.errors?.firstName)}
        />
        <Input
          label={t('UserProfileForm:last_name_label')}
          id="last-name"
          {...register('lastName', {
            required: t('UserProfileForm:last_name_required'),
          })}
          {...getInputErrorProps(formState?.errors?.lastName)}
        />
      </OnboardingLayout.InputHalf>
      {!!showResidencyField && (
        <SelectHookForm
          label={t('UserProfileForm:residency_label')}
          placeholder=""
          id="country"
          name="country"
          options={countriesForReactSelect}
          value={countriesForReactSelect.find(({ value }) => value === country)}
          control={control}
          rules={{
            required: t('UserProfileForm:country_required'),
          }}
          {...getInputErrorProps(formState?.errors?.country)}
        />
      )}
      <OnboardingLayout.InputHalf>
        <Input
          label={t('UserProfileForm:phone_number_label')}
          variant={
            formState?.errors?.phoneNumber?.message ? 'error' : undefined
          }
          subtext={formState?.errors?.phoneNumber?.message}
          id="phone-number"
          {...register('phoneNumber', {
            validate: (v) =>
              v === '' ||
              validPhone(v) ||
              t('UserProfileForm:phone_number_invalid'),
          })}
        />
      </OnboardingLayout.InputHalf>
      <Checkbox
        label={
          <Trans i18nKey="UserProfileForm:policy">
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a
              href={config.TERMS_AND_CONDITIONS_URL}
              target="_blank"
              rel="noreferrer"
            />
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a
              href={config.PRIVACY_POLICY_URL}
              target="_blank"
              rel="noreferrer"
            />
          </Trans>
        }
        id="policyAgreement"
        data-testid={'check-policy-agreement'}
        {...register('policyAgreement', {
          required: t('UserProfileForm:policy_required'),
        })}
        groupSpacing="xl"
        {...getInputErrorProps(formState?.errors?.policyAgreement)}
      />
      <ButtonFeedback
        isLoading={isLoading}
        isSuccess={isSuccess}
        {...saveButtonProps}
      />
    </form>
  );
};
