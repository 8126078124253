import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { CohortsTable } from './CohortsTable';
import { CohortsResponse, InsightsRawData } from './InsightsCharts.interface';
import { InsightsChartsCard } from './InsightsChartsCard.styles';
import { ProfitGrowthChart } from './ProfitGrowthChart';
import { RevenueGrowthChart } from './RevenueGrowthChart';
import { TacosGrowthChart } from './TacosGrowthChart';
import { UnitEconomicsChart } from './UnitEconomicsChart';
import { UnitEconomicsTable } from './UnitEconomicsTable';
import { YearlyGrowthTable } from './YearlyGrowthTable';
import { useChartsOrEmpty } from './useInsightsCharts.utils';
import { calculateTotalChange } from './utils/calculateTotalChange';
import { calculateUnitEconomicTotals } from './utils/calculateUnitEconomicTotals';
import { generateYearlyGrowth } from './utils/generateYearlyGrowth';
import { isCohortsEmpty } from './utils/isCohortsEmpty';
import { prepareData } from './utils/prepareData';
import { prependMissingMonths } from './utils/prependMissingMonths';

export const useInsightsCharts = ({
  chartsService,
  cohortsService,
}: {
  chartsService?: string;
  cohortsService?: string;
}) => {
  const { t } = useTranslation();

  const { data: rawData, error } = useSWR<InsightsRawData[]>(chartsService);
  const { data: cohortsRawData, error: cohortsError } =
    useSWR<CohortsResponse>(cohortsService);

  const { customers, revenues } = cohortsRawData ?? {};

  const { getChart } = useChartsOrEmpty<InsightsRawData[]>(
    (rawData) => !!rawData?.length,
    rawData,
    error
  );

  const { getChart: getCohortsChart } = useChartsOrEmpty<CohortsResponse>(
    (data) => {
      return (
        !isCohortsEmpty(data?.revenues) || !isCohortsEmpty(data?.customers)
      );
    },
    cohortsRawData,
    cohortsError
  );

  const preparedData = useMemo(
    () => prepareData(prependMissingMonths(rawData)),
    [rawData]
  );

  const revenueGrowthData = useMemo(
    () =>
      generateYearlyGrowth(preparedData, {
        valueKey: 'revenueAmount',
      }),
    [preparedData]
  );

  const profitGrowthData = useMemo(
    () =>
      generateYearlyGrowth(preparedData, {
        valueKey: 'profitPercentageOfRevenue',
      }),
    [preparedData]
  );

  const tacosGrowthData = useMemo(
    () =>
      generateYearlyGrowth(preparedData, {
        valueKey: 'tacosPercentageOfRevenue',
      }),
    [preparedData]
  );

  const unitEconomicsTotals = useMemo(
    () => calculateUnitEconomicTotals(preparedData),
    [preparedData]
  );

  if (error) {
    console.log('Error', error);
  }

  const revenueGrowth = useMemo(
    () => (
      <InsightsChartsCard.Wrapper>
        <InsightsChartsCard.Title>
          {t('InsightsCharts:chart_title_revenues')}
        </InsightsChartsCard.Title>
        <InsightsChartsCard.ChartWrapper>
          <RevenueGrowthChart data={revenueGrowthData} />
        </InsightsChartsCard.ChartWrapper>
        <InsightsChartsCard.TableWrapper>
          <YearlyGrowthTable
            totalChange={calculateTotalChange(revenueGrowthData)}
            unit="money"
          />
        </InsightsChartsCard.TableWrapper>
      </InsightsChartsCard.Wrapper>
    ),
    [revenueGrowthData, t]
  );

  const customerRetentionCohorts = useMemo(
    () => (
      <CohortsTable rawData={customers}>
        {(content) => (
          <InsightsChartsCard.Wrapper>
            <InsightsChartsCard.Title>
              {t('InsightsCharts:customer_retention_cohorts')}
            </InsightsChartsCard.Title>
            <InsightsChartsCard.BaseWrapper>
              {content}
            </InsightsChartsCard.BaseWrapper>
          </InsightsChartsCard.Wrapper>
        )}
      </CohortsTable>
    ),
    [t, customers]
  );

  const revenueRetentionCohorts = useMemo(
    () => (
      <CohortsTable rawData={revenues}>
        {(content) => (
          <InsightsChartsCard.Wrapper>
            <InsightsChartsCard.Title>
              {t('InsightsCharts:revenue_retention_cohorts')}
            </InsightsChartsCard.Title>
            <InsightsChartsCard.BaseWrapper>
              {content}
            </InsightsChartsCard.BaseWrapper>
          </InsightsChartsCard.Wrapper>
        )}
      </CohortsTable>
    ),
    [t, revenues]
  );

  const profitGrowth = useMemo(
    () => (
      <InsightsChartsCard.Wrapper>
        <InsightsChartsCard.Title>
          {t('InsightsCharts:chart_title_sde')}
        </InsightsChartsCard.Title>
        <InsightsChartsCard.ChartWrapper>
          <ProfitGrowthChart data={profitGrowthData} />
        </InsightsChartsCard.ChartWrapper>
        <InsightsChartsCard.TableWrapper>
          <YearlyGrowthTable
            totalChange={calculateTotalChange(profitGrowthData)}
            unit="percentage"
          />
        </InsightsChartsCard.TableWrapper>
      </InsightsChartsCard.Wrapper>
    ),
    [profitGrowthData, t]
  );

  const tacosGrowth = useMemo(
    () => (
      <InsightsChartsCard.Wrapper>
        <InsightsChartsCard.Title>
          {t('InsightsCharts:chart_title_tacos')}
        </InsightsChartsCard.Title>
        <InsightsChartsCard.ChartWrapper>
          <TacosGrowthChart data={tacosGrowthData} />
        </InsightsChartsCard.ChartWrapper>
        <InsightsChartsCard.TableWrapper>
          <YearlyGrowthTable
            totalChange={calculateTotalChange(tacosGrowthData)}
            negativeVariant="positive"
            positiveVariant="negative"
            unit="percentage"
          />
        </InsightsChartsCard.TableWrapper>
      </InsightsChartsCard.Wrapper>
    ),
    [tacosGrowthData, t]
  );

  const unitEconomics = useMemo(
    () => (
      <InsightsChartsCard.Wrapper>
        <InsightsChartsCard.Title>
          {t('InsightsCharts:chart_title_unit_economics')}
        </InsightsChartsCard.Title>
        <InsightsChartsCard.ChartScrollWrapper>
          <InsightsChartsCard.ChartWrapper css={{ minWidth: '650px' }}>
            <UnitEconomicsChart data={preparedData} />
          </InsightsChartsCard.ChartWrapper>
        </InsightsChartsCard.ChartScrollWrapper>
        <InsightsChartsCard.TableWrapper>
          <UnitEconomicsTable totals={unitEconomicsTotals} />
        </InsightsChartsCard.TableWrapper>
      </InsightsChartsCard.Wrapper>
    ),
    [preparedData, unitEconomicsTotals, t]
  );

  return {
    isLoading: !rawData && !error,
    revenueGrowth: getChart(revenueGrowth),
    customerRetentionCohorts: getCohortsChart(customerRetentionCohorts),
    revenueRetentionCohorts: getCohortsChart(revenueRetentionCohorts),
    profitGrowth: getChart(profitGrowth),
    tacosGrowth: getChart(tacosGrowth),
    unitEconomics: getChart(unitEconomics),
  };
};
