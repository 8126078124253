import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DropdownFilterItem } from 'ui/DropdownFilter';
import { FilterItem } from 'ui/Filter';
import { StaticTooltip } from 'ui/Tooltip';

import { routePaths } from 'router/routePaths';

import {
  ForSaleInlineFiltersProps,
  ForSaleSortedByEnum,
} from './ForSaleFilters.interface';
import { Styles } from './ForSaleSidebarFilters.styles';
import { useForSaleInlineFilters } from './useForSaleInlineFilters';

const tooltipId = 'filter-tooltip';

export const ForSaleInlineFilters = ({
  filters,
  onApplyFilter,
}: ForSaleInlineFiltersProps) => {
  const { t } = useTranslation();

  const {
    handleFavorite,
    handleContacted,
    handleCanAfford,
    handleSortedBy,
    sortedBySelected,
    favoriteSelected,
    contactedSelected,
    canAffordSelected,
    moreFiltersCount,
    moreFiltersSelected,
    showCanAfford,
  } = useForSaleInlineFilters({ filters, onApplyFilter });

  const moreFiltersProps = {
    prevChildren: (
      <FilterItem.Icon>
        <FontAwesomeIcon icon={regular('bars-filter')} size="lg" />
      </FilterItem.Icon>
    ),
    postChildren: !!moreFiltersCount && (
      <FilterItem.Hint data-testid="more-filters-count">
        {moreFiltersCount}
      </FilterItem.Hint>
    ),
    selected: moreFiltersSelected,
  };

  const sortedByLiterals = {
    [ForSaleSortedByEnum.Newest]: t('ForSaleFilters:newest_first'),
    [ForSaleSortedByEnum.Relevance]: t('ForSaleFilters:relevance'),
  };

  const sortedByOptions = [
    {
      label: sortedByLiterals[ForSaleSortedByEnum.Relevance],
      value: ForSaleSortedByEnum.Relevance,
    },
    {
      label: sortedByLiterals[ForSaleSortedByEnum.Newest],
      value: ForSaleSortedByEnum.Newest,
    },
  ];

  return (
    <FilterItem.ScrollWrapper css={{ mt: '$4' }}>
      <FilterItem.Wrapper data-testid="for-sale-inline-filters">
        <FilterItem
          onClick={handleFavorite}
          selected={favoriteSelected}
          data-testid="filter-favorite"
        >
          {t('ForSaleFilters:favourites')}
        </FilterItem>
        <FilterItem
          onClick={handleContacted}
          selected={contactedSelected}
          data-testid="filter-contacted"
        >
          {t('ForSaleFilters:contacted')}
        </FilterItem>
        {showCanAfford && (
          <FilterItem
            onClick={handleCanAfford}
            selected={canAffordSelected}
            postChildren={
              <FilterItem.Icon
                data-tooltip-id={tooltipId}
                data-tooltip-content={t('ForSaleFilters:financial_fit_tooltip')}
              >
                <FontAwesomeIcon icon={regular('circle-info')} size="lg" />
              </FilterItem.Icon>
            }
            data-testid="filter-financial-fit"
          >
            {t('ForSaleFilters:financial_fit')}
          </FilterItem>
        )}
        <FilterItem
          as={Link}
          to={routePaths.forSaleFilter}
          {...moreFiltersProps}
          data-testid="filter-more"
        >
          {t('ForSaleFilters:more_filters')}
        </FilterItem>
        <Styles.SortByWrapper>
          <DropdownFilterItem
            filterName={t('ForSaleFilters:sort_by')}
            options={sortedByOptions}
            selected={sortedBySelected}
            onSelect={handleSortedBy as (value: string) => void}
          />
        </Styles.SortByWrapper>
      </FilterItem.Wrapper>
      <StaticTooltip id={tooltipId} place="right" />
    </FilterItem.ScrollWrapper>
  );
};
