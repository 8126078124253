import { useMemo } from 'react';

export const getInitials = (name?: string | null): string => {
  return (
    name
      ?.match(/(^\S\S?|\s\S)?/g)
      ?.map((v) => v.trim())
      ?.join('')
      ?.match(/(^\S|\S$)?/g)
      ?.join('')
      ?.toLocaleUpperCase() ?? ''
  );
};

export const useInitials = (name?: string | null): string => {
  const initials = useMemo(() => {
    return getInitials(name);
  }, [name]);

  if (!name) return '';

  return initials;
};
