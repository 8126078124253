import { ListingTermSheetColumns } from 'components/TermSheet';

import { ListingForSale } from 'lib/types';

export const TermSheetDetails = ({
  listing,
  blur,
  wrap = false,
}: {
  listing: ListingForSale;
  wrap?: boolean;
  blur?: boolean;
}) => {
  return <ListingTermSheetColumns listing={listing} blur={blur} wrap={wrap} />;
};
