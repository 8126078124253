import { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  ActionSideModal,
  ActionSideModalCloseHeader,
  ActionSideModalCommonStyles,
  useNavigateFromActionSideModal,
} from 'components/ActionSideModal';
import { LoanSideModalSendReview } from 'components/LoanSideModalSendReview';

import { routePaths } from 'router/routePaths';

import { ContractSignerCheckbox } from './ContractSignerCheckbox';
import { useContractSigners } from './ContractSignersModal.hooks';

export const ContractSignersModal = () => {
  const { t } = useTranslation();
  const params = useParams<{ loanId: string }>();
  const loanId = params.loanId as string;

  const { onClose } = useNavigateFromActionSideModal(
    {
      loanId,
    },
    { basePath: routePaths.dealFlowDetail }
  );

  const handleSuccess = useCallback(() => {
    onClose();
  }, [onClose]);

  const {
    isWaitingForReview,
    isVerified,
    isSubmitting,
    people,
    formMethods,
    onSubmit,
    onDelete,
    isSuccess,
  } = useContractSigners({
    loanId,
    onSuccess: handleSuccess,
  });

  const submitForm = formMethods.handleSubmit(onSubmit);

  return (
    <ActionSideModal
      header={<ActionSideModalCloseHeader onClose={onClose} />}
      onClose={onClose}
    >
      <ActionSideModalCommonStyles.Title data-testid="contract-signers-modal-title">
        {t('ContractSignersModal:title')}
      </ActionSideModalCommonStyles.Title>
      <ActionSideModalCommonStyles.Subtitle>
        {t('ContractSignersModal:subtitle')}
      </ActionSideModalCommonStyles.Subtitle>

      <FormProvider {...formMethods}>
        <ActionSideModalCommonStyles.Form onSubmit={submitForm}>
          {people.map((person) => (
            <ContractSignerCheckbox
              key={person.id}
              person={person}
              disabled={isWaitingForReview || isVerified}
            />
          ))}

          <LoanSideModalSendReview
            isLoading={isSubmitting}
            isSuccess={isSuccess}
            isWaitingForReview={isWaitingForReview}
            isVerified={isVerified}
            onSendForReview={submitForm}
            onCancelReview={onDelete}
          />
        </ActionSideModalCommonStyles.Form>
      </FormProvider>
    </ActionSideModal>
  );
};
