import { LayoutSidebarDetail } from 'components/LayoutSidebarDetail';

import { ListingStatusDateProps } from './ListingStatusDate.inteface';
import { useListingStatusDate } from './useListingStatusDate';

export const ListingStatusDate = ({
  sellApplication,
}: ListingStatusDateProps) => {
  const dateFormatted = useListingStatusDate({ sellApplication });

  if (!dateFormatted) {
    return null;
  }

  return (
    <LayoutSidebarDetail.DateContainer>
      {dateFormatted}
    </LayoutSidebarDetail.DateContainer>
  );
};
