import { FC } from 'react';

import { ContentCell } from 'ui/ContentCell';

import { ClosingStepsNames, ILoanApi } from 'lib/types';

import {
  APAPreview,
  ChildByStepProps,
  ContractSignersPreview,
  ERPToolsPreview,
  FundsBankAccountPreview,
  LegalEntityPreview,
  LetterOfIntentPreview,
  RevenueBankAccount,
  RevenueSourcesPreview,
  ShareholdersAndDirectorsPreview,
} from './previews';

interface StepPreviewProps {
  step: ClosingStepsNames;
  loan: ILoanApi;
}

const ContentByStep: { [key in ClosingStepsNames]?: FC<ChildByStepProps> } = {
  [ClosingStepsNames.letterOfIntent]: LetterOfIntentPreview,
  [ClosingStepsNames.fundsBankAccount]: FundsBankAccountPreview,
  [ClosingStepsNames.legalEntity]: LegalEntityPreview,
  [ClosingStepsNames.shareholdersAndDirectors]: ShareholdersAndDirectorsPreview,
  [ClosingStepsNames.contractSigners]: ContractSignersPreview,
  [ClosingStepsNames.revenueSources]: RevenueSourcesPreview,
  [ClosingStepsNames.revenueBankAccount]: RevenueBankAccount,
  [ClosingStepsNames.ERPTools]: ERPToolsPreview,
  [ClosingStepsNames.assetPurchaseAgreement]: APAPreview,
};
export const StepPreview = ({ step, loan }: StepPreviewProps) => {
  const Child = ContentByStep[step] ?? null;

  if (!Child) {
    return null;
  }

  return (
    <ContentCell.LightContent data-testid={`step-preview-${step}`}>
      <Child loan={loan} />
    </ContentCell.LightContent>
  );
};
