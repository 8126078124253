import { useTranslation } from 'react-i18next';

import { Badge } from 'ui/Badge';

import { ListingForSaleReduced, ListingTag, ListingTagName } from 'lib/types';

export interface ForSaleTagsProps {
  listing?: ListingForSaleReduced;
}

const TagsComponent = {
  [ListingTagName.matching]: (tag: ListingTag) => {
    const { t } = useTranslation();
    const matching = tag.matching;

    if (!matching) {
      return null;
    }

    return (
      <Badge data-testid="listing-tag-matching">
        {t('ForSaleCard:tags_matching', { matching })}
      </Badge>
    );
  },
  [ListingTagName.exclusive]: () => {
    const { t } = useTranslation();

    return (
      <Badge data-testid="listing-tag-exclusive" variant="olive">
        {t('ForSaleCard:tags_exclusive')}
      </Badge>
    );
  },
  [ListingTagName.new]: () => {
    const { t } = useTranslation();

    return (
      <Badge variant="beige" data-testid="listing-tag-new">
        {t('ForSaleCard:tags_new')}
      </Badge>
    );
  },
};

const ForSaleTag = ({ tag }: { tag: ListingTag }) => {
  const TagComponent = TagsComponent[tag.name];

  if (!TagComponent) {
    return null;
  }

  return <TagComponent {...tag} />;
};

export const ForSaleTags = ({ listing }: ForSaleTagsProps) => {
  const tags = listing?.tags ?? [];

  return (
    <>
      {tags.map((tag) => (
        <ForSaleTag tag={tag} key={tag.name} />
      ))}
    </>
  );
};
