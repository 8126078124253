import { ReactNode } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';

import {
  ThemeComponentsProvider,
  globalCss,
  globalStyles,
} from '@boopos/design-system';

import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BackgroundClassNames } from './BackgroundSwitch';

const localGlobalStyles = globalCss({
  'html, body': { height: '100%' },
  'html *': { boxSizing: 'border-box' },
  a: {
    color: '$sky600',
    textDecoration: 'underline',
  },
  [`body.${BackgroundClassNames.base}`]: {
    background: '$backgroundBase',
  },
  [`body.${BackgroundClassNames.light}`]: {
    background: '$white',
  },
});

const themeComponents = {
  buttonFeedbackSuccess: <FontAwesomeIcon icon={solid('check')} size="xl" />,
  defaultIcon: <FontAwesomeIcon icon={regular('circle-info')} />,
  errorIcon: <FontAwesomeIcon icon={regular('circle-exclamation')} />,
  warningIcon: <FontAwesomeIcon icon={regular('circle-exclamation')} />,
  successIcon: <FontAwesomeIcon icon={regular('circle-check')} />,
  actionRequiredIcon: <FontAwesomeIcon icon={solid('circle-info')} />,
  feedbackCollapseIcon: <FontAwesomeIcon icon={regular('chevron-down')} />,
  accordionCollapseIcon: (
    <FontAwesomeIcon icon={regular('chevron-down')} size="xl" />
  ),
  caretDownIcon: <FontAwesomeIcon icon={solid('chevron-down')} />,
  checkIcon: <FontAwesomeIcon icon={solid('check')} />,
  circleIcon: <FontAwesomeIcon icon={solid('circle')} size="2xs" />,
};

export const ThemeProvider = ({ children }: { children: ReactNode }) => (
  <ThemeComponentsProvider components={themeComponents}>
    {children}
  </ThemeComponentsProvider>
);

export const GlobalStyles = () => {
  globalStyles();
  localGlobalStyles();

  return null;
};
