import { styled } from '@boopos/design-system';

export const CardWrapper = styled('div', {
  position: 'relative',
  p: '$4',
  borderRadius: '.8rem',
  backgroundColor: '$white',
  boxShadow:
    '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
  variants: {
    pendingConnection: {
      true: {
        display: 'flex',
        ai: 'center',
        jc: 'space-between',
      },
    },
  },
});

export const DeleteWrapper = styled('div', {
  position: 'absolute',
  top: '$4',
  right: '$4',
});

export const AccountDetails = styled('div', {
  display: 'flex',
  alignItems: 'center',
  color: '$slate600',
  mt: '$3',
  '&:not(:last-child)': {
    mb: '$3',
  },
  'div:first-child': {
    flex: 1,
  },
});

export const AccountName = styled('div', {
  textAlign: 'right',
});

export const ImageWrapper = styled('div', {
  display: 'inline-flex',
  borderRadius: '.8rem',
  height: '7.2rem',
  width: '8em',
  textAlign: 'center',
  border: '1px solid $slate200',
  lineHeight: 0,
  p: '$2',

  '& > img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
});
