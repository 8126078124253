import { useTranslation } from 'react-i18next';

import { BannerPrimaryAction } from 'ui/Banner';

import { QualifyAction } from './QualifyAction';

export const QualificationFlowButton = () => {
  const { t } = useTranslation();

  return (
    <QualifyAction>
      {(props) => (
        <BannerPrimaryAction
          data-testid="qualify-to-qualification"
          {...props}
          size="reduced"
        >
          {t('QualificationBanner:qualify')}
        </BannerPrimaryAction>
      )}
    </QualifyAction>
  );
};
