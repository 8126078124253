import { FC } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { ScrollRestoration } from 'react-router-dom';
import { SWRConfig } from 'swr';

import { ToastFeedbackRoot } from '@boopos/design-system';

import { BooposAnimatedLogo } from 'components/BooposAnimatedLogo';
import { GlobalStyles, ThemeProvider } from 'components/GlobalStyles';
import { MultiProvider } from 'components/MultiProvider';

import { AnalyticsProvider } from 'lib/analytics/AnalyticsProvider';
import api from 'lib/api';
import i18n from 'lib/i18';

import { AuthProvider } from 'providers/AuthProvider';

import { MaintenanceModeScreen } from 'screens/MaintenanceModeScreen';

import { IRootProvider } from './RootProvider.interface';

export const RootProvider: FC<IRootProvider> = ({ children }) => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <ToastFeedbackRoot />
      <ScrollRestoration />
      <HelmetProvider>
        <MultiProvider
          providers={[
            <MaintenanceModeScreen key="maintenance-mode-screen" />,
            <AnalyticsProvider key="analytics-provider" />,
            <SWRConfig
              value={{ fetcher: api.swrFetch.bind(api) }}
              key="swr-config"
            />,
            <I18nextProvider i18n={i18n} key="i18next-provider" />,
            <AuthProvider key="auth-provider" />,
          ]}
        >
          <>
            <Helmet defaultTitle="Boopos" titleTemplate="%s - Boopos" />
            <BooposAnimatedLogo />
            {children}
          </>
        </MultiProvider>
      </HelmetProvider>
    </ThemeProvider>
  );
};
