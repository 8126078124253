import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import useSWRImmutable from 'swr/immutable';

import { apiV2 } from 'lib/api';

import { categoriesAtom } from 'screens/InvestmentCriteriaScreen/InvestmentCriteria.atoms';

export const useBusinessCategories = (businessId: string) => {
  const [categories, syncCategories] = useAtom(categoriesAtom);
  const { data } = useSWRImmutable(apiV2.getInvestmentCriteria());

  const handleSetCategories = useCallback(
    (selectedIds: string[]) => {
      syncCategories((current) => ({
        ...current,
        [businessId]: selectedIds,
      }));
    },
    [syncCategories, businessId]
  );

  useEffect(() => {
    data?.categories && syncCategories(data?.categories);
  }, [data?.categories, syncCategories]);

  return {
    setCategories: handleSetCategories,
    categories: categories[businessId] ?? [],
  };
};
