import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { Text } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LayoutSidebarDetail } from 'components/LayoutSidebarDetail';

import { apiV2 } from 'lib/api';
import { ListingStatsApi } from 'lib/types';

interface Props {
  listingImpact?: ListingStatsApi;
  listingId?: string;
}

const RenderIfDefined = ({
  value,
  children,
}: {
  value?: number;
  children: ReactNode;
}) => {
  if (value === undefined) {
    return null;
  }

  return <>{children}</>;
};

export const ListingImpact = ({ listingId }: Props) => {
  const { t } = useTranslation();

  const { data: listingStats } = useSWR<ListingStatsApi>(
    listingId && apiV2.getListingStats(listingId)
  );

  if (!listingStats?.stats) {
    return null;
  }

  return (
    <LayoutSidebarDetail.ContentBlock data-testid="listing-impact">
      <LayoutSidebarDetail.ContentSubHeader>
        {t('ListingImpact:title')}
      </LayoutSidebarDetail.ContentSubHeader>
      <LayoutSidebarDetail.ContentGrid>
        <RenderIfDefined value={listingStats?.stats?.views}>
          <LayoutSidebarDetail.ContentGridCell>
            <LayoutSidebarDetail.ContentCellSubHeader>
              {t('ListingImpact:visited')}
            </LayoutSidebarDetail.ContentCellSubHeader>
            <LayoutSidebarDetail.ContentCellIconValue>
              <FontAwesomeIcon icon={regular('eye')} />
              <Text bold>{listingStats?.stats?.views}</Text>
            </LayoutSidebarDetail.ContentCellIconValue>
          </LayoutSidebarDetail.ContentGridCell>
        </RenderIfDefined>
        <RenderIfDefined value={listingStats?.stats?.favorites}>
          <LayoutSidebarDetail.ContentGridCell>
            <LayoutSidebarDetail.ContentCellSubHeader>
              {t('ListingImpact:favorite')}
            </LayoutSidebarDetail.ContentCellSubHeader>
            <LayoutSidebarDetail.ContentCellIconValue>
              <FontAwesomeIcon icon={regular('heart')} />
              <Text bold>{listingStats?.stats?.favorites}</Text>
            </LayoutSidebarDetail.ContentCellIconValue>
          </LayoutSidebarDetail.ContentGridCell>
        </RenderIfDefined>
        <RenderIfDefined value={listingStats?.stats?.enquiries}>
          <LayoutSidebarDetail.ContentGridCell>
            <LayoutSidebarDetail.ContentCellSubHeader>
              {t('ListingImpact:enquiries')}
            </LayoutSidebarDetail.ContentCellSubHeader>
            <LayoutSidebarDetail.ContentCellIconValue>
              <FontAwesomeIcon icon={regular('comments')} />
              <Text bold>{listingStats?.stats?.enquiries}</Text>
            </LayoutSidebarDetail.ContentCellIconValue>
          </LayoutSidebarDetail.ContentGridCell>
        </RenderIfDefined>
      </LayoutSidebarDetail.ContentGrid>
    </LayoutSidebarDetail.ContentBlock>
  );
};
