import { useTranslation } from 'react-i18next';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  CreditReportFormProps,
  CreditReportFormWithData,
  UseQualifyCreditReportProps,
} from 'components/CreditReport';

export const QualifyCreditReportForm = (
  props: Partial<CreditReportFormProps> & Partial<UseQualifyCreditReportProps>
) => {
  const { t } = useTranslation();
  return (
    <>
      <CreditReportFormWithData
        onSuccess={props.onSuccess}
        submitAction={t('QualifyCreditReport:credit_report_continue')}
        cache={true}
        submitActionFullWidth={false}
        submitActionRightIcon={
          <FontAwesomeIcon icon={regular('arrow-right')} />
        }
      />
    </>
  );
};
