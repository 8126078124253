import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

import { useUser } from 'providers/UserProvider';

export const SentryUserHandler = () => {
  const { isAuthenticated, user } = useUser();

  useEffect(() => {
    if (!isAuthenticated || !user) {
      return;
    }
    Sentry.setUser({
      id: user.id,
      email: user.email,
    });
  }, [isAuthenticated, user]);

  return null;
};
