import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  ListingUnlockAction,
  useListingUnlockCapture,
} from 'components/ListingUnlock';

import { ListingForSale } from 'lib/types';

import { FilePreview } from './FilePreview';

export const InfoMemoPreview = ({
  listing,
  children,
}: {
  listing: ListingForSale;
  children?: (children: ReactNode) => ReactNode;
}) => {
  const { t } = useTranslation();
  const [file] = listing?.infoMemoFiles?.value ?? [];

  const blur = listing?.infoMemoFiles?.redacted;

  const actionProps = useListingUnlockCapture(blur, {
    requiresAgreement: listing?.requiresAgreement,
    originalProps: {
      href: file?.downloadUrl,
      target: '_blank',
    },
  });

  if (!file || !children) {
    return null;
  }

  const Content = (
    <FilePreview.Wrapper {...actionProps}>
      <FilePreview.Thumbnail>
        {t('ListingDetail:info_memo')}
      </FilePreview.Thumbnail>
      <FilePreview.Content blur={blur}>{file.name}</FilePreview.Content>

      {blur ? (
        <ListingUnlockAction requiresAgreement={listing?.requiresAgreement} />
      ) : (
        <FilePreview.Action>
          <FontAwesomeIcon icon={regular('file-arrow-down')} />
        </FilePreview.Action>
      )}
    </FilePreview.Wrapper>
  );

  return <>{children(Content)}</>;
};
