import { useTranslation } from 'react-i18next';

import { Button } from '@boopos/design-system';

import { IForSaleFiltersForm } from 'components/ForSaleFilters';
import { ForSaleListMatchingStyles } from 'components/ForSaleListMatching/ForSaleListMatching.styles';
import { useForSaleListMatching } from 'components/ForSaleListMatching/useForSaleListMatching';

export type ForSaleListMatchingProps = {
  isEmpty: boolean;
  filters: IForSaleFiltersForm;
  onResetFilters: () => void;
  onApplyPartialFilters: (value: Partial<IForSaleFiltersForm>) => void;
};

const FILTER_ITEMS_TO_SHOW = 2;

export const ForSaleListMatching = ({
  isEmpty,
  filters,
  onResetFilters,
  onApplyPartialFilters,
}: ForSaleListMatchingProps) => {
  const { t } = useTranslation();

  const filterItems = useForSaleListMatching(filters);

  return (
    <ForSaleListMatchingStyles.Wrapper data-testid="for-sale-list-matching">
      <ForSaleListMatchingStyles.Title>
        {isEmpty
          ? t('ForSaleListMatching:title')
          : t('ForSaleListMatching:title_more')}
      </ForSaleListMatchingStyles.Title>
      <ForSaleListMatchingStyles.Description>
        {t('ForSaleListMatching:description')}
      </ForSaleListMatchingStyles.Description>
      <ForSaleListMatchingStyles.Items>
        <Button
          variant="secondary"
          size="reduced"
          onClick={onResetFilters}
          data-testid="reset-filters-button"
        >
          {t('ForSaleListMatching:remove_all')}
        </Button>
        {filterItems
          .filter((item) => item.selected)
          .slice(0, FILTER_ITEMS_TO_SHOW)
          .map((item) => (
            <Button
              variant="secondary"
              size="reduced"
              data-testid="reset-filter-button"
              key={item.key}
              onClick={() => onApplyPartialFilters(item.initialValue)}
            >
              {t('ForSaleListMatching:remove')} {item.text}
            </Button>
          ))}
      </ForSaleListMatchingStyles.Items>
    </ForSaleListMatchingStyles.Wrapper>
  );
};
