import { ICountry } from 'lib/types';

export const parseAmazonRegionsToSelect = (
  countries: ICountry[],
  path: string
) => {
  return countries.map(([label, code, link]) => ({
    value: `${code};${link}${path}`,
    label,
  }));
};
