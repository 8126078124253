import { useTranslation } from 'react-i18next';

import { ContentCellDetail } from 'ui/ContentCellDetail';
import { ListCard } from 'ui/ListCard';

import { AmountOrEmpty } from 'components/AmountOrEmpty';
import { TermSheetHandler } from 'components/TermSheetHandler';

import { ILoanApi } from 'lib/types';

export const DealFlowLoanAmount = ({ loan }: { loan?: ILoanApi }) => {
  const { t } = useTranslation();

  const LoanAmount = (
    <>
      <TermSheetHandler.Base>
        <ContentCellDetail.Label>
          {t('DealFlowCard:loan_amount')}
        </ContentCellDetail.Label>
        <ContentCellDetail.Value>
          <AmountOrEmpty amount={loan?.amount} />
        </ContentCellDetail.Value>
      </TermSheetHandler.Base>
    </>
  );

  return (
    <ListCard.ValueItem>
      <TermSheetHandler termSheet={loan?.termSheet}>
        <TermSheetHandler.Empty>{LoanAmount}</TermSheetHandler.Empty>
        <TermSheetHandler.Base>{LoanAmount}</TermSheetHandler.Base>
        <TermSheetHandler.External>
          <ContentCellDetail.Label>
            {t('DealFlowCard:loan_through')}
          </ContentCellDetail.Label>
          <ContentCellDetail.Value>
            {t('DealFlowCard:lending_partners')}
          </ContentCellDetail.Value>
        </TermSheetHandler.External>
      </TermSheetHandler>
    </ListCard.ValueItem>
  );
};
