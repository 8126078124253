import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@boopos/design-system';

import { FlowStatusFeedback } from 'ui/FlowStatusFeedback';

import ForSaleDraw from 'assets/illustrations/for-sale.svg';

import Api from 'lib/api';
import { useTrackUserGoals } from 'lib/hooks/useTrackUserGoals';
import { LoanTypesEnum } from 'lib/types';

export const BusinessForSaleEmptyState = ({
  businessId,
  mutate,
}: {
  businessId: string;
  mutate?: () => Promise<unknown>;
}) => {
  const { t } = useTranslation();
  const { trackSellerGoal } = useTrackUserGoals();

  const navigateToSaleLoanForm = useCallback(async () => {
    await trackSellerGoal();

    await Api.createLoan(businessId, {
      type: LoanTypesEnum.sale_seller,
    });
    await mutate?.();
  }, [mutate, trackSellerGoal, businessId]);

  return (
    <FlowStatusFeedback
      title={t('BusinessForSaleScreen:zero_results_pre_approved_title')}
      description={t(
        'BusinessForSaleScreen:zero_results_pre_approved_description'
      )}
      illustration={
        <img
          src={ForSaleDraw}
          alt={t('BusinessForSaleScreen:zero_results_pre_approved_title')}
        />
      }
      fullWidth={false}
      showBackground
      titleBig
    >
      <Button
        fullWidth
        as={Link}
        onClick={navigateToSaleLoanForm}
        data-testid="sell-business-button"
      >
        {t('BusinessForSaleScreen:zero_results_pre_approved_action')}
      </Button>
    </FlowStatusFeedback>
  );
};
