import { ApplicationBusinessApi } from 'lib/types';

export const BusinessType = ({
  business,
}: {
  business?: ApplicationBusinessApi;
}) => {
  return (
    <>
      {business?.typeLiteral?.name}
      {!!business?.typeLiteral?.nameOther &&
        ` (${business?.typeLiteral?.nameOther})`}
    </>
  );
};
