import { useCallback, useEffect, useState } from 'react';
import { FileRejection } from 'react-dropzone';

import { useUploadFiles } from 'lib/files';
import { IUploadedFile } from 'lib/types';

import { AcceptedFilesEnum } from './UploadZone.interface';
import {
  getAcceptedFileTypes,
  getAcceptedFilesMessage,
  getBytesMaxSize,
  parseFilesToApi,
  useGetGeneralErrorLiteral,
  useGetRejectedErrorLiterals,
  useUnknownErrorLiteral,
} from './UploadZone.utils';

interface IUseUploadZone {
  onFilesUploaded: (files: IUploadedFile[]) => Promise<void> | void;
  accept: AcceptedFilesEnum[];
  maxSizeMb: number;
  uploadParams?: { [key: string]: any };
}

export const useUploadZone = ({
  accept,
  maxSizeMb,
  uploadParams,
  onFilesUploaded,
}: IUseUploadZone) => {
  const [rejectedErrors, setRejectedErrors] = useState<string[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [isUploading, setUploading] = useState<boolean>(false);
  const { uploadFiles } = useUploadFiles();

  const maxSizeBytes = getBytesMaxSize(maxSizeMb);
  const acceptedFileTypes = getAcceptedFileTypes(accept);

  const acceptedFilesLiteral = getAcceptedFilesMessage(accept);
  const unknownErrorLiteral = useUnknownErrorLiteral();

  const rejectedErrorLiterals = useGetRejectedErrorLiterals({
    accept,
    acceptedFilesLiteral,
    maxSizeMb,
  });

  const generalErrorLiteral = useGetGeneralErrorLiteral({
    acceptedFilesLiteral,
    maxSizeMb,
  });

  const handleRemoveError = useCallback(() => {
    setErrors([]);
    setRejectedErrors([]);
  }, []);

  const onFileError = useCallback(() => {
    setErrors([unknownErrorLiteral]);
  }, [unknownErrorLiteral]);

  const onDropFilesAccepted = useCallback(
    (files: File[]) => {
      (async () => {
        setUploading(true);

        if (errors.length) {
          handleRemoveError();
        }

        const parsedFiles = parseFilesToApi(files, uploadParams);

        try {
          const uploadedFiles = await uploadFiles(parsedFiles);
          await onFilesUploaded(uploadedFiles);
        } catch (err: any) {
          setUploading(false);
          setErrors([unknownErrorLiteral]);
        } finally {
          setUploading(false);
        }
      })();
    },
    [
      errors.length,
      uploadParams,
      handleRemoveError,
      uploadFiles,
      onFilesUploaded,
      unknownErrorLiteral,
    ]
  );

  const onDropFilesRejected = useCallback(
    (filesRejected: FileRejection[]) => {
      const errors = filesRejected.map((file) => {
        const [firstError] = file.errors;
        const { code } = firstError;
        return rejectedErrorLiterals[code] || generalErrorLiteral;
      });

      setRejectedErrors(errors);
    },
    [generalErrorLiteral, rejectedErrorLiterals]
  );

  useEffect(() => {
    if (!isUploading && !!rejectedErrors.length) {
      setErrors(rejectedErrors);
    }
  }, [errors, isUploading, rejectedErrors, rejectedErrors.length]);

  return {
    acceptedFileTypes,
    maxSizeBytes,
    isUploading,
    errors,
    onDropFilesAccepted,
    onDropFilesRejected,
    onFileError,
    handleRemoveError,
  };
};
