import { useTranslation } from 'react-i18next';

import { ContentCellDetail } from 'ui/ContentCellDetail';
import { ListCard } from 'ui/ListCard';

import { AmountOrEmpty } from 'components/AmountOrEmpty';
import { TermSheetHandler } from 'components/TermSheetHandler';

import { ListingForSaleReduced } from 'lib/types';

export const ListingLoanAmount = ({
  listing,
}: {
  listing: ListingForSaleReduced;
}) => {
  const { t } = useTranslation();

  return (
    <ListCard.ValueItem>
      <TermSheetHandler termSheet={listing?.termSheet?.value}>
        <TermSheetHandler.Base>
          <ContentCellDetail.Label>
            {t('ForSaleCard:loan_up_to')}
          </ContentCellDetail.Label>
          <ContentCellDetail.Value>
            <AmountOrEmpty amount={listing?.termSheet?.value?.amount} />
          </ContentCellDetail.Value>
        </TermSheetHandler.Base>
        <TermSheetHandler.External>
          <ContentCellDetail.Label>
            {t('ForSaleCard:loan_through')}
          </ContentCellDetail.Label>
          <ContentCellDetail.Value>
            {t('ForSaleCard:lending_partners')}
          </ContentCellDetail.Value>
        </TermSheetHandler.External>
      </TermSheetHandler>
    </ListCard.ValueItem>
  );
};
