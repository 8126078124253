import { AnalyticsBrowser } from '@segment/analytics-next';

import { IUser } from 'lib/types';

import { BaseAnalyticPlugin } from './BaseAnalyticPlugin';

export class SegmentAnalyticsPlugin extends BaseAnalyticPlugin {
  private client: AnalyticsBrowser | null = null;
  private readonly writeKey: string;
  private readonly cdnURL: string;
  private readonly apiHost: string;

  constructor({
    writeKey,
    cdnURL,
    apiHost,
  }: {
    writeKey: string;
    cdnURL: string;
    apiHost: string;
  }) {
    super();
    this.writeKey = writeKey;
    this.cdnURL = cdnURL;
    this.apiHost = apiHost;
  }

  async load(): Promise<any> {
    this.client = AnalyticsBrowser.load(
      {
        writeKey: this.writeKey,
        cdnURL: this.cdnURL,
      },
      {
        integrations: {
          'Segment.io': {
            apiHost: this.apiHost,
          },
        },
      }
    );
    return null;
  }

  async identify(user: IUser): Promise<void> {
    await this.client?.identify(
      user.id,
      BaseAnalyticPlugin.getBaseUserTraits(user)
    );
  }
  async track(eventName: string, eventParams?: any): Promise<void> {
    await this.client?.track(eventName, eventParams);
  }
  async reset(): Promise<void> {
    await this.client?.reset();
  }
  async page(): Promise<void> {
    await this.client?.page();
  }
}
