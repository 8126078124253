import { useTranslation } from 'react-i18next';

import { TimelineItemNowProps } from 'components/DealTimeline/DealTimeline.interface';
import { stepAnimatedProps } from 'components/DealTimeline/StepAnimation';
import { DealTimelineStep } from 'components/DealTimelineStep';

import { FormatUserInputText } from 'lib/format';
import { ILoanApi, LoanStatus } from 'lib/types';

import { Styles } from './DealMissingInfo.styles';

export const shouldShowDealMissingInfo = (loan: ILoanApi) => {
  return [LoanStatus.missing_info].includes(loan?.status) && !!loan?.notes;
};

export const DealMissingInfo = ({ loan, children }: TimelineItemNowProps) => {
  const { t } = useTranslation();

  if (!shouldShowDealMissingInfo(loan)) {
    return null;
  }

  const subtitle = (
    <>
      {t('DealTimelineStep:deal_missing_info_subtitle')}
      <Styles.NotesWrapper>
        <FormatUserInputText text={loan.notes} />
      </Styles.NotesWrapper>
      {t('DealTimelineStep:deal_missing_info_add_modify')}
    </>
  );

  return (
    <DealTimelineStep
      title={t('DealTimelineStep:deal_missing_info_title')}
      subtitle={subtitle}
      errorVariant="warning"
      data-testid="deal-missing-info"
      {...stepAnimatedProps}
    >
      {children}
    </DealTimelineStep>
  );
};
