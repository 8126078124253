import { FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '@boopos/design-system';

import { Modal } from 'components/Modal';

export const ModalUploadErrors = ({
  opened,
  errors,
  onClose,
  fileRejections,
}: {
  opened: boolean;
  errors: string[];
  fileRejections: FileRejection[];
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      position="bottomSheetCentered"
      title={t('FileUploaderErrors:upload_process_error', {
        count: +(fileRejections.length === 1),
      })}
      maxWidth={380}
    >
      <Box css={{ p: '$4', pt: 0 }}>
        <Box css={{ pb: '$6' }}>{(errors ?? [])[0]}</Box>
        <Button fullWidth onClick={onClose} data-testid="try-again-button">
          {t('FileUploaderErrors:try_again')}
        </Button>
      </Box>
    </Modal>
  );
};
