import { useTranslation } from 'react-i18next';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { usePathWithExitPath } from 'components/PortfolioApplication/ApplicationExitPath';
import { usePortfolioList } from 'components/PortfolioList/PortfolioList.hooks';

import { routePaths } from 'router/routePaths';

import { MenuItem } from './MenuItem.styled';
import { MenuItemNavLink } from './MenuItemNavLink.styled';
import { MenuItemText } from './MenuItemText.styled';

export const ListBusinessMenuItem = () => {
  const { t } = useTranslation();

  const { isEmpty } = usePortfolioList();

  const { to, state } = usePathWithExitPath(
    routePaths.portfolioApplication.index
  );

  if (!isEmpty) return null;

  return (
    <>
      <MenuItem>
        <MenuItemNavLink to={to} data-testid="list-business" state={state}>
          <>
            <FontAwesomeIcon icon={regular('plus-circle')} />
            <MenuItemText>{t('MainMenu:list_business')}</MenuItemText>
          </>
        </MenuItemNavLink>
      </MenuItem>
    </>
  );
};
