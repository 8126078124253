import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { createToastFeedback } from '@boopos/design-system';

import { usePersistenceAtom } from 'components/ClosingChecklistPersistence';
import { useClosingStepMatchStatus } from 'components/DealClosingCheckList';

import api from 'lib/api';
import { getDocumentsFiles } from 'lib/files';
import {
  ClosingCheckListStepStatus,
  ClosingStepsNames,
  ILoanApi,
} from 'lib/types';

import { assetPurchaseAgreementAtom } from './AssetPurchaseAgreement.atoms';
import {
  IAssetPurchaseAgreementData,
  IUseAssetPurchaseAgreementReturn,
} from './AssetPurchaseAgreement.interface';
import { parseToApi } from './AssetPurchaseAgreement.utils';

interface IUseAssetPurchaseAgreement {
  loanId: string;
  onSuccess?: () => void;
}

export const useAssetPurchaseAgreement = ({
  loanId,
  onSuccess,
}: IUseAssetPurchaseAgreement): IUseAssetPurchaseAgreementReturn => {
  const { t } = useTranslation();
  const { data: loan, mutate: refreshLoan } = useSWR<ILoanApi>(
    api.getLoan(loanId)
  );

  const [success, setSuccess] = useState(false);
  const assetPurchaseAgreementFiles = useMemo(
    () =>
      loan?.assetPurchaseAgreement
        ? getDocumentsFiles(loan?.assetPurchaseAgreement?.documents)
        : undefined,
    [loan]
  );

  const isWaitingForReview = useClosingStepMatchStatus({
    loan,
    status: ClosingCheckListStepStatus.waiting,
    name: ClosingStepsNames.assetPurchaseAgreement,
  });

  const isVerified = useClosingStepMatchStatus({
    loan,
    status: ClosingCheckListStepStatus.verified,
    name: ClosingStepsNames.assetPurchaseAgreement,
  });

  const {
    value: assetPurchaseAgreement,
    setValue: setAssetPurchaseAgreement,
    reset: resetAssetPurchaseAgreement,
  } = usePersistenceAtom<IAssetPurchaseAgreementData>(
    assetPurchaseAgreementAtom,
    loanId
  );

  const defaultValues = assetPurchaseAgreementFiles
    ? { assetPurchaseAgreementFiles }
    : assetPurchaseAgreement;

  const formMethods = useForm<IAssetPurchaseAgreementData>({
    defaultValues,
  });

  const { formState, watch } = formMethods;
  const isSubmitting = formState?.isSubmitting;

  const handleOnChange = useCallback(
    (data: IAssetPurchaseAgreementData) => {
      if (assetPurchaseAgreementFiles) return;
      setAssetPurchaseAgreement(data);
    },
    [setAssetPurchaseAgreement, assetPurchaseAgreementFiles]
  );

  const onDelete = useCallback(async () => {
    try {
      await api.deleteAssetPurchaseAgreement(loanId);
      if (assetPurchaseAgreementFiles)
        setAssetPurchaseAgreement({ assetPurchaseAgreementFiles });
      setTimeout(() => {
        refreshLoan();
      }, 1000);
    } catch (err) {
      console.error(err);
      createToastFeedback({
        title: t('DealClosingCheckList:unexpected_error_canceling_review'),
        type: 'error',
      });
    }
  }, [
    loanId,
    refreshLoan,
    assetPurchaseAgreementFiles,
    setAssetPurchaseAgreement,
    t,
  ]);

  const onSubmit = useCallback(
    async (data: IAssetPurchaseAgreementData) => {
      try {
        await api.updateAssetPurchaseAgreement(loanId, parseToApi(data));
        resetAssetPurchaseAgreement();
        setSuccess(true);

        setTimeout(() => {
          refreshLoan();
          onSuccess?.();
        }, 1000);
      } catch (err) {
        console.error(err);
        createToastFeedback({
          title: t('DealClosingCheckList:unexpected_error_sending_review'),
          type: 'error',
        });
      }
    },
    [loanId, onSuccess, refreshLoan, resetAssetPurchaseAgreement, t]
  );

  useEffect(() => {
    const subscription = watch((value) =>
      handleOnChange(value as IAssetPurchaseAgreementData)
    );
    return () => subscription.unsubscribe();
  }, [handleOnChange, watch]);

  return {
    formMethods,
    isSubmitting,
    isSuccess: success,
    isReady: !!loan,
    isWaitingForReview,
    isVerified,
    onSubmit,
    onDelete,
  };
};
