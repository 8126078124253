import { useTranslation } from 'react-i18next';

import { InsightsChartTooltip } from 'ui/InsightsChartTooltip';
import { TooltipRenderProps } from 'ui/InsightsShared';

import { InsightsPreparedData } from './InsightsCharts.interface';
import { dateLabelLong } from './utils/dateLabelFormatter';
import { moneyValueFormatter } from './utils/moneyValueFormatter';
import { percentValueFormatter } from './utils/percentValueFormatter';

export const UnitEconomicsTooltip = ({
  active,
  payload,
}: TooltipRenderProps) => {
  const { t } = useTranslation();

  if (!active) {
    return null;
  }

  const [revenuePayload, cogsPayload, sdePayload] = payload ?? [];

  const revenueValue = revenuePayload?.value as number;
  const cogsValue = cogsPayload?.value as number;
  const sdeValue = sdePayload?.value as number;

  const cogsInternalPayload = cogsPayload?.payload as InsightsPreparedData;
  const profitInternalPayload = sdePayload?.payload as InsightsPreparedData;

  const revenueLabel = t('UnitEconomicsTooltip:revenue', {
    percentage: '100%',
  });
  const cogsLabel = t('UnitEconomicsTooltip:cogs', {
    percentage: percentValueFormatter(
      cogsInternalPayload?.cogsPercentageOfRevenue
    ),
  });
  const sdeLabel = t('UnitEconomicsTooltip:sde', {
    percentage: percentValueFormatter(
      profitInternalPayload?.profitPercentageOfRevenue
    ),
  });

  return (
    <InsightsChartTooltip.Wrapper
      css={{ maxWidth: 'min-content' }}
      data-testid="unit-economics-tooltip"
    >
      <InsightsChartTooltip.Table>
        <InsightsChartTooltip.Header>
          {dateLabelLong(revenuePayload.payload?.dateAccounting)}
        </InsightsChartTooltip.Header>

        <InsightsChartTooltip.Row
          indicatorColor={revenuePayload.color}
          label={revenueLabel}
          value={moneyValueFormatter(revenueValue)}
        />
        <InsightsChartTooltip.Row
          indicatorColor={cogsPayload.color}
          label={cogsLabel}
          value={moneyValueFormatter(cogsValue)}
        />
        <InsightsChartTooltip.Row
          indicatorColor={sdePayload.color}
          label={sdeLabel}
          value={moneyValueFormatter(sdeValue)}
        />
      </InsightsChartTooltip.Table>
    </InsightsChartTooltip.Wrapper>
  );
};
