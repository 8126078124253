import { useTranslation } from 'react-i18next';

import { FileVisibility } from 'lib/types';

import { Styles } from './DocumentsDetails.styles';

export const FileVisibilityRender = ({
  visibility,
}: {
  visibility?: FileVisibility;
}) => {
  const { t } = useTranslation();

  const visibilityCopies = {
    [FileVisibility.public]: t('FileVisibility:published'),
    [FileVisibility.hidden]: t('FileVisibility:hidden'),
  };

  return (
    <Styles.FileVisibility data-testid="file-visibility">
      {visibility
        ? visibilityCopies[visibility]
        : visibilityCopies[FileVisibility.public]}
    </Styles.FileVisibility>
  );
};
