import { useTranslation } from 'react-i18next';

import { TermSheetHandler } from 'components/TermSheetHandler';

import { useDealStatusFlags } from 'lib/hooks/useDealStatusFlags';
import { ILoanApi, LoanDocumentType } from 'lib/types';

const RenderTermSheetReadyHeading = ({ loan }: { loan: ILoanApi }) => {
  const { t } = useTranslation();

  return (
    <TermSheetHandler termSheet={loan.termSheet}>
      <TermSheetHandler.Base>
        {t('DealStatusFeedback:termsheet_ready_for_review_heading')}
      </TermSheetHandler.Base>
      <TermSheetHandler.External>
        {t('DealStatusFeedback:external_termsheet_ready_heading')}
      </TermSheetHandler.External>
    </TermSheetHandler>
  );
};

const RenderTermSheetForReviewBody = ({ loan }: { loan: ILoanApi }) => {
  const { t } = useTranslation();

  const isInternal = !!loan.listing;

  return (
    <TermSheetHandler termSheet={loan.termSheet}>
      <TermSheetHandler.Base>
        {isInternal
          ? t('DealStatusFeedback:termsheet_ready_for_review_internal_body')
          : t('DealStatusFeedback:termsheet_ready_for_review_body')}
      </TermSheetHandler.Base>
      <TermSheetHandler.External>
        {t('DealStatusFeedback:external_termsheet_ready_body')}
      </TermSheetHandler.External>
    </TermSheetHandler>
  );
};

export const useDealStatusFeedback = ({ loan }: { loan: ILoanApi }) => {
  const { t } = useTranslation();

  const {
    isMissingInfoBeforeEvaluation,
    isMissingInfoDuringEvaluation,
    isTermSheetReadyForReview,
    isEvaluatingForFinancing,
    isClosingMissingInfo,
    isContractSent,
    isContractSigned,
    isCanceled,

    isClosingDetailsNeeded,
    isClosingDetailsReviewing,
  } = useDealStatusFlags({
    status: loan.status,
    notes: loan.notes,
    isReadyForEvaluation: loan.canSendToEvaluation,
  });

  const hasBuyerInsights = !!loan.businessInsights;

  const { pendingDocuments, notes, canSendToEvaluation } = loan;

  if (isMissingInfoBeforeEvaluation) {
    const shouldShowCohorts = pendingDocuments?.includes(
      LoanDocumentType.cohorts
    );

    const message = shouldShowCohorts
      ? t('DealStatusFeedback:missing_info_all_before_evaluation_body')
      : t('DealStatusFeedback:missing_info_before_evaluation_body');

    return {
      action: 'primary',
      type: 'actionRequired',
      heading: t('DealStatusFeedback:missing_info_heading'),
      message,
      'data-testid': 'missing-info-before-evaluation-feedback',
    };
  }

  if (canSendToEvaluation && !notes) {
    return {
      action: 'primary',
      type: 'actionRequired',
      heading: t('DealStatusFeedback:ready_for_evaluation_heading'),
      message: t('DealStatusFeedback:ready_for_evaluation_body'),
      'data-testid': 'ready-to-evaluation-feedback',
    };
  }

  if (isEvaluatingForFinancing) {
    return {
      type: 'neutral',
      heading: t('DealStatusFeedback:evaluating_for_financing_heading'),
      message: t('DealStatusFeedback:evaluating_for_financing_body'),
      'data-testid': 'evaluating-feedback',
    };
  }

  if (isMissingInfoDuringEvaluation) {
    return {
      action: 'primary',
      type: 'warning',
      heading: t('DealStatusFeedback:missing_info_heading'),
      message: t('DealStatusFeedback:missing_info_message_shorter'),
      'data-testid': 'missing-info-during-evaluation-feedback',
    };
  }

  if (isTermSheetReadyForReview) {
    return {
      action: 'primary',
      type: 'actionRequired',
      heading: <RenderTermSheetReadyHeading loan={loan} />,
      message: <RenderTermSheetForReviewBody loan={loan} />,
      'data-testid': 'termsheet-ready-for-review-feedback',
    };
  }

  if (isClosingDetailsNeeded) {
    return {
      action: 'primary',
      type: 'actionRequired',
      heading: t('DealStatusFeedback:closing_details_needed_heading'),
      message: t('DealStatusFeedback:closing_details_needed_body'),
      'data-testid': 'closing-details-needed-feedback',
    };
  }

  if (isClosingDetailsReviewing) {
    return {
      type: 'neutral',
      heading: t('DealStatusFeedback:closing_details_reviewing_heading'),
      message: t('DealStatusFeedback:closing_details_reviewing_body'),
      'data-testid': 'closing-details-reviewing-feedback',
    };
  }

  if (isClosingMissingInfo) {
    return {
      action: 'primary',
      type: 'warning',
      heading: t('DealStatusFeedback:more_missing_info_heading'),
      message: t('DealStatusFeedback:due_diligence_missing_info_message'),
      'data-testid': 'more-missing-info-feedback',
    };
  }

  if (isContractSent) {
    return {
      action: 'primary',
      type: 'actionRequired',
      heading: t('DealStatusFeedback:funding_approved_heading'),
      message: t('DealStatusFeedback:contract_sent_body'),
      'data-testid': 'contract-sent-feedback',
    };
  }

  if (isContractSigned) {
    return {
      action: 'primary',
      type: 'actionRequired',
      heading: t('DealStatusFeedback:funding_approved_heading'),
      message: t('DealStatusFeedback:contract_signed_body'),
      'data-testid': 'contract-signed-feedback',
    };
  }

  if (isCanceled) {
    return {
      type: 'error',
      heading: t('DealStatusFeedback:canceled_heading'),
      message: hasBuyerInsights
        ? t('DealStatusFeedback:canceled_body_with_insights')
        : t('DealStatusFeedback:canceled_body'),
      'data-testid': 'canceled-feedback',
    };
  }

  return null;
};
