import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath, useNavigate } from 'react-router-dom';

import { Box, Button } from '@boopos/design-system';

import { Modal } from 'components/Modal';

import { routePaths } from 'router/routePaths';

import { FakeMenuDealFlowSelected } from './FakeMenuDealFlowSelected';

const noop = () => {
  // do nothing
};

export const DealMovingForwardModal = ({
  termsAccepted,
  loanId,
}: {
  loanId?: string;
  termsAccepted?: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goToPath = loanId
    ? generatePath(routePaths.dealFlowDetail, { loanId })
    : '';

  const handleFakeMenuClick = useCallback(() => {
    if (!goToPath) return;
    navigate(goToPath, { replace: true });
  }, [navigate, goToPath]);

  if (!loanId) {
    return null;
  }

  return (
    <Modal
      opened={Boolean(termsAccepted)}
      onClose={noop}
      allowClose={false}
      position="bottomSheetCentered"
      maxWidth={340}
      title={t('DealMovingForwardModal:title')}
    >
      <Box css={{ px: '$4', pb: '$4' }} data-testid="deal-moving-forward-modal">
        {t('DealMovingForwardModal:description')}
        <FakeMenuDealFlowSelected onClick={handleFakeMenuClick} />
        <Button fullWidth css={{ mt: '$4' }} as={Link} to={goToPath} replace>
          {t('DealMovingForwardModal:action')}
        </Button>
      </Box>
    </Modal>
  );
};
