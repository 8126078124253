import { useTranslation } from 'react-i18next';

import { Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  ActionSideModalCommonStyles,
  bottomNavAnimationVariants,
} from 'components/ActionSideModal';

import { FormatUserInputText } from 'lib/format';

export interface ContinueActionProps {
  isVerified: boolean;
  onContinue: () => void;
}

export const ContinueAction = ({
  isVerified,
  onContinue,
}: ContinueActionProps) => {
  const { t } = useTranslation();

  return (
    <ActionSideModalCommonStyles.BottomNav
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={bottomNavAnimationVariants}
      data-testid="revenue-bank-acccount-modal-bottom-nav"
    >
      {isVerified && (
        <ActionSideModalCommonStyles.BottomNavCenterText data-testid="verified-text">
          <FontAwesomeIcon
            icon={regular('check-double')}
            size="lg"
            pull="left"
          />
          {t('DealClosingCheckList:verified')}
        </ActionSideModalCommonStyles.BottomNavCenterText>
      )}
      {!isVerified && (
        <>
          <ActionSideModalCommonStyles.BottomNavLeftText data-testid="not-verified-text">
            <FormatUserInputText
              text={t('RevenueBankAccountModal:accounts_will_be_reviewed')}
            />
          </ActionSideModalCommonStyles.BottomNavLeftText>
          <Button
            type="button"
            variant="secondary"
            data-testid="continue-button"
            onClick={onContinue}
            rightIcon={<FontAwesomeIcon icon={regular('arrow-right')} />}
          >
            {t('DealClosingCheckList:continue')}
          </Button>
        </>
      )}
    </ActionSideModalCommonStyles.BottomNav>
  );
};
