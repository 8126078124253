import { styled } from '@boopos/design-system';

export const CardStyles = {
  Card: styled('div', {
    backgroundColor: '$surfaceBase',
    borderRadius: '1.6rem',
    padding: '1.6rem',
    display: 'flex',
    gap: '1.6rem',
    color: '$textBodyPrimary',
    textDecoration: 'none',
    '&:hover': {
      boxShadow: 'inset 0 0 0 2px $colors$borderBaseHover',
    },
    '&:active': {
      boxShadow: 'inset 0 0 0 2px $colors$borderBasePressed',
    },
    '&:focus-visible': {
      outline: '4px solid $colors$borderInteractiveFocus',
    },
  }),
  Left: styled('div', {}),
  Right: styled('div', {
    flex: 1,
    overflow: 'hidden',
  }),
  Header: styled('div', {
    minHeight: '4.2rem',
    display: 'flex',
    alignItems: 'center',
    gap: '.8rem',
  }),
  Title: styled('div', {
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  Badges: styled('div', {}),
  SubHeader: styled('div', {}),
  ContentWrapper: styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  }),
  Content: styled('div', {
    flex: 1,
    color: '$textBodyTertiary',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  Action: styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '4.8rem',
    height: '4.8rem',
  }),
};
