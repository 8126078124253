import * as Sentry from '@sentry/react';
import React, { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAccountConnectorsStatus } from 'components/AccountConnectors/AccountConnectors.hooks';
import { FullScreenLoader } from 'components/FullScreenLoader';

import { useEffectOnce } from 'lib/hooks/useEffectOnce';

export const AmazonCallbackScreen = () => {
  const { addError } = useAccountConnectorsStatus();
  const [, setSuccess] = useState(false);
  const [params] = useSearchParams();

  const amazonParams = useMemo(() => {
    return params ? Object.fromEntries(params) : undefined;
  }, [params]);

  const handleClose = useCallback(() => {
    window.close();
  }, []);

  const handleAmazonCallback = useCallback(
    async (amazonParams: any) => {
      if (!window.opener?.customCallback || !amazonParams) {
        addError();
        handleClose();
        return;
      }

      try {
        await window.opener.customCallback(amazonParams);
        setSuccess(true);
        handleClose();
      } catch (err) {
        addError();
        Sentry.captureException(err);
        handleClose();
      }
    },
    [addError, handleClose]
  );

  useEffectOnce(() => {
    (async () => {
      const { spapi_oauth_code, state } = amazonParams || {};
      if (!spapi_oauth_code || !state) return await handleAmazonCallback(null);
      const generatedPublicToken = [state, spapi_oauth_code].join(';');
      await handleAmazonCallback(generatedPublicToken);
    })();
  });

  return <FullScreenLoader />;
};
