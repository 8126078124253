import { IUseAccountConnector } from 'components/AccountConnectors/AccountConnectors.interface';
import { ProviderImage } from 'components/AccountConnectors/ProviderImage';
import { useAutoOpenConnection } from 'components/AccountConnectors/useAutoOpenConnection';

import { useCustomLink } from './useCustomLink';

export const CustomConnectionLink = (props: IUseAccountConnector) => {
  const { provider, businessId, onSuccess, token, render, ...rest } = props;

  const { ready, open } = useCustomLink({
    provider,
    businessId,
    onSuccess,
    token,
  });

  useAutoOpenConnection(provider, open, ready);

  if (render) {
    const renderProps = {
      ...rest,
      disabled: !ready,
      onClick: open,
    };

    return render(renderProps);
  }

  return (
    <button
      disabled={!ready}
      onClick={open}
      data-testid={`custom-link-${provider}`}
      {...rest}
    >
      <ProviderImage provider={provider} />
    </button>
  );
};
