import { useCallback, useRef } from 'react';

import { useAccountConnectorsStatus } from './AccountConnectors.hooks';

interface IUseCheckWindowOpened {
  onClosed: () => void;
  interval?: number;
}

export const useCheckWindowOpened = ({
  onClosed,
  interval = 3000,
}: IUseCheckWindowOpened) => {
  const checkOpenedInterval = useRef<undefined | number>();
  const { endConnection } = useAccountConnectorsStatus();

  const setWindowRef = useCallback(
    (windowRef: WindowProxy | null) => {
      if (!windowRef) return;
      window.currentOpened = windowRef;

      checkOpenedInterval.current = window.setInterval(() => {
        if (!windowRef) clearInterval(checkOpenedInterval.current);
        if (windowRef.closed) {
          onClosed();
          endConnection();
          clearInterval(checkOpenedInterval.current);
        }
      }, interval);
    },
    [onClosed, interval, endConnection]
  );

  return { setWindowRef };
};
