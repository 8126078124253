import { styled } from '@boopos/design-system';

export const NewUpdateBanner = styled('div', {
  backgroundColor: '$surfacePromoted',
  color: '$infoPromoted',
  p: '$4',
  display: 'flex',
  alignItems: 'center',
  '> div:first-child': {
    marginRight: '$6',
  },
});
