import useSWR from 'swr';

import Api from 'lib/api';

import { mapBusinessTypesForSelect } from './businessType';

export const useBusinessTypesForSelect = () => {
  const { data: categories } = useSWR(Api.getCategories());

  return mapBusinessTypesForSelect(categories);
};
