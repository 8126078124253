import {
  QualificationStatusApi,
  useQualificationStatus,
} from 'components/QualificationStatus';

import { Avatar } from './Nav.styled';

const showBadgeStatuses = [
  QualificationStatusApi.MISSING_INFO,
  QualificationStatusApi.REJECTED,
];

export const AvatarAlert = () => {
  const { status } = useQualificationStatus();

  const showStatusAlert = status && showBadgeStatuses.includes(status);

  if (!showStatusAlert) {
    return null;
  }

  return <Avatar.Alert />;
};
