import { PropsWithChildren, createContext, useMemo } from 'react';

import { AnalyticsClient, analyticsClientInstance } from './AnalyticsClient';

export const AnalyticsContext = createContext<AnalyticsClient | undefined>(
  undefined
);

interface IAnalyticsProvider {}

export const AnalyticsProvider = ({
  children,
}: PropsWithChildren<IAnalyticsProvider>) => {
  const analytics = useMemo(() => {
    analyticsClientInstance.load().then(() => {
      //noop
    });

    return analyticsClientInstance;
  }, []);

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};
