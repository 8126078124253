import type * as Stitches from '@stitches/react';

import { keyframes, styled } from '@boopos/design-system';

const spin = keyframes({
  to: { transform: 'rotate(360deg)' },
});

const Wrapper = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Loader = styled('div', {
  borderRadius: '80%',
  display: 'block',
  height: 50,
  width: 50,
  position: 'relative',
  animation: `${spin} .675s linear 0s infinite normal`,

  '&:before, &:after': {
    content: '',
    display: 'block',
    position: 'absolute',
  },
  '&:before': {
    borderRadius: '0 90px 90px 0',
    height: 50,
    width: '50%',
    top: 0,
    right: 0,
    zIndex: '$1',
    background: '$slate800',
    backgroundImage: `linear-gradient($white, $slate800)`,
  },
  '&:after': {
    borderRadius: '80%',
    height: 40,
    width: 40,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '$1',
    background: '$white',
  },

  variants: {
    fileUpload: {
      true: {
        background: 'transparent',

        '&:before': {
          backgroundImage: 'none',
        },

        '&:after': {
          background: '$slate200',
        },
      },
    },
  },
});

export const FullScreenLoader = (
  props: Stitches.ComponentProps<typeof Wrapper>
) => (
  <Wrapper {...props}>
    <Loader />
  </Wrapper>
);
