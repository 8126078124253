import { useTranslation } from 'react-i18next';

import { ContentCell } from 'ui/ContentCell';
import { ContentTable } from 'ui/ContentTable';

import { FormatUrl } from 'components/FormatUrl';

import { otherCategory } from 'lib/businessType';
import { IBusinessApi } from 'lib/types';

export interface TargetBusinessDetailsProps {
  business: IBusinessApi;
}

export const TargetBusinessDetails = ({
  business,
}: TargetBusinessDetailsProps) => {
  const { t } = useTranslation();

  if (!business) return null;

  return (
    <ContentCell
      title={t('TargetBusinessDetails:title')}
      variant="success"
      active={false}
    >
      <ContentTable.Wrapper>
        <ContentTable.Label>
          {t('TargetBusinessDetails:business_name')}
        </ContentTable.Label>
        <ContentTable.Value>{business.name}</ContentTable.Value>
        <ContentTable.Label>
          {t('TargetBusinessDetails:business_website')}
        </ContentTable.Label>
        <ContentTable.Value noWrap>
          <FormatUrl url={business.url} target="_blank" />
        </ContentTable.Value>
        {!!business.businessTypeLiteral && (
          <>
            <ContentTable.Label>
              {t('TargetBusinessDetails:business_type')}
            </ContentTable.Label>
            <ContentTable.Value>
              {business.businessTypeLiteral.id === otherCategory
                ? business.businessTypeOther
                : business.businessTypeLiteral.name}
            </ContentTable.Value>
          </>
        )}
        {!!business?.brokerLongName && (
          <>
            <ContentTable.Label>
              {t('TargetBusinessDetails:broker')}
            </ContentTable.Label>
            <ContentTable.Value>{business.brokerLongName}</ContentTable.Value>
          </>
        )}
      </ContentTable.Wrapper>
    </ContentCell>
  );
};
