import { styled } from '@boopos/design-system';

export const Styles = {
  Wrapper: styled('div', {
    display: 'flex',
    alignItems: 'baseline',
    gap: '.8rem',
  }),
  Dash: styled('span', {
    color: '$textBodyPlaceholder',
    fontSize: '3.2rem',
    lineHeight: '3.2rem',
    fontWeight: '$bold',
  }),
  NotAvailable: styled('div', {
    color: '$textBodyTertiary',
    fontWeight: '$bold',
  }),
};
