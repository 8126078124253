import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Banner, BannerPrimaryAction } from 'ui/Banner';

import { routePaths } from 'router/routePaths';

export const InvestmentCriteriaBanner = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToInvestmentCriteria = useCallback(async () => {
    navigate(routePaths.investmentCriteria, {
      state: {
        backUrl: window.location.pathname,
        flowStartedFrom: window.location.pathname,
      },
    });
  }, [navigate]);

  const bannerDescription = (
    <Trans i18nKey="InvestmentCriteriaBanner:description">
      <strong />
    </Trans>
  );

  return (
    <Banner
      icon={<>✨</>}
      data-testid="investment-criteria-banner"
      description={bannerDescription}
      variant="secondary"
    >
      <BannerPrimaryAction
        as="a"
        onClick={navigateToInvestmentCriteria}
        data-testid="investment-criteria-button"
        variant="secondary"
      >
        {t('InvestmentCriteriaBanner:action')}
      </BannerPrimaryAction>
    </Banner>
  );
};
