import React, { ReactNode } from 'react';

import { Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StepAction = ({
  isMatching,
  hasValue,
  children,
}: {
  isMatching: boolean;
  hasValue?: boolean;
  children?: ReactNode;
}) => {
  if (isMatching) {
    return null;
  }

  if (hasValue) {
    return (
      <Button
        rounded
        css={{ color: '$infoStatic' }}
        leftIcon={<FontAwesomeIcon icon={regular('pencil')} size="lg" />}
        variant="nude"
        size="reduced"
      />
    );
  }

  return (
    <Button
      variant="secondary"
      tabIndex={-1}
      size="reduced"
      data-testid="set-action"
    >
      {children}
    </Button>
  );
};
