import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentCellDetail } from 'ui/ContentCellDetail';
import { ListCard } from 'ui/ListCard';

import { IBusinessCard } from './BusinessCard.interface';
import { BusinessCardStyles } from './BusinessCard.styles';
import { BusinessRange } from './BusinessRange';
import { LoanBadges } from './LoanBadges';

export const BusinessCard = forwardRef<HTMLAnchorElement, IBusinessCard>(
  (props, ref) => {
    const { t } = useTranslation();
    const { data, ...rest } = props;
    const { business, loans, listedforSale, hasInsights } = data;

    const isValuationAvailable =
      data.valuation && data.valuation.valuation !== undefined
        ? data.valuation.valuation?.low.amount > 0
        : false;

    return (
      <ListCard.Card ref={ref as any} {...rest}>
        <ListCard.TitleWithAction>
          <ListCard.Title>{business.name}</ListCard.Title>
          <ListCard.TitleAction>
            <BusinessCardStyles.BadgeWrapper>
              <LoanBadges
                hasInsights={hasInsights}
                loans={loans}
                listedForSale={listedforSale}
              />
            </BusinessCardStyles.BadgeWrapper>
          </ListCard.TitleAction>
        </ListCard.TitleWithAction>
        <ListCard.ValuesGrid>
          <ListCard.ValueItem>
            <ContentCellDetail.Label>
              {t('BusinessCard:estimated_value_range')}
            </ContentCellDetail.Label>
            <ContentCellDetail.Value>
              {isValuationAvailable ? (
                <BusinessRange
                  low={data.valuation?.valuation.low}
                  high={data.valuation?.valuation.high}
                />
              ) : (
                <BusinessCardStyles.ValuationNotAvailable data-testid="valuation-not-available">
                  {t('BusinessCard:valuation_not_available')}
                </BusinessCardStyles.ValuationNotAvailable>
              )}
            </ContentCellDetail.Value>
          </ListCard.ValueItem>
        </ListCard.ValuesGrid>
      </ListCard.Card>
    );
  }
);
