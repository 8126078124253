import { memo } from 'react';

import { IUseAccountConnector } from 'components/AccountConnectors/AccountConnectors.interface';
import { ProviderImage } from 'components/AccountConnectors/ProviderImage';
import { useAutoOpenConnection } from 'components/AccountConnectors/useAutoOpenConnection';

import { useRutterLink } from './useRutterLink';

export const RutterLink = memo((props: IUseAccountConnector) => {
  const { provider, onSuccess, businessId, render, ...rest } = props;
  const { open, ready, interacting } = useRutterLink({
    provider,
    onSuccess,
    businessId,
  });

  useAutoOpenConnection(provider, open, ready);

  if (render) {
    const renderProps = {
      ...rest,
      disabled: !ready,
      onClick: open,
    };

    return render(renderProps);
  }

  return (
    <button
      disabled={!ready}
      onClick={open}
      data-testid={`rutter-link-${provider}`}
      data-loading={interacting}
      {...rest}
    >
      <ProviderImage provider={provider} />
    </button>
  );
});
