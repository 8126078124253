import { useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';

import api from 'lib/api';
import { ICategory } from 'lib/types';

export const useOtherCategoriesUtils = () => {
  const { data: categoriesApi } = useSWRImmutable<ICategory[]>(
    api.getCategories
  );

  const otherCategory = categoriesApi?.find(
    (category: ICategory) => category.name === 'Other'
  );

  const otherParentCategoryId = otherCategory?.id ?? '';

  const otherCategoryIds = useMemo(
    () =>
      otherCategory?.categories?.map((category: ICategory) => category.id) ??
      [],
    [otherCategory]
  );

  return {
    otherParentCategoryId,
    otherCategoryIds,
  };
};
