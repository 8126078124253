import { IAddTargetBusinessFormFields } from './AddTargetBusinessForm.interface';

export const parseToApi = (data: IAddTargetBusinessFormFields) => ({
  ...data,
  broker: data.broker ?? undefined,
});

export const notUndefinedBroker =
  (errorString: string) => (value: string | null | undefined) =>
    typeof value !== 'undefined' || errorString;
