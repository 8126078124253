import qs from 'query-string';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { Button } from '@boopos/design-system';

import { ConnectionStepWrapper } from 'components/AccountConnectionLayout';
import { parseAmazonRegionsToSelect } from 'components/AccountConnectors';
import { SelectHookForm } from 'components/SelectHookForm';

import api from 'lib/api';
import { IAmazonInitConnectionApi } from 'lib/types';

import { IAmazonConnectorStepOne } from './AmazonConnectorStepOne.interface';
import { StyledForm } from './AmazonConnectorStepOne.styles';

export const AmazonConnectorStepOne: FC<IAmazonConnectorStepOne> = ({
  onSubmit,
}) => {
  const { control, handleSubmit } = useForm<{ regionWithLink: string }>();
  const { query } = qs.parseUrl(window.location.href);
  const { businessId } = query;
  const { t } = useTranslation();

  const { data } = useSWR<IAmazonInitConnectionApi>(
    businessId &&
      api.initMerchantBusinessConnectionSWR('amazon', businessId as string)
  );

  if (!data) return null;

  return (
    <ConnectionStepWrapper data-testid="connect-step-1">
      <StyledForm
        onSubmit={handleSubmit(onSubmit)}
        data-testid="connect-step-1-form"
      >
        <SelectHookForm
          name="regionWithLink"
          control={control}
          inputId="regionWithLink"
          label={t('ConnectAmazonScreen:select_region')}
          options={parseAmazonRegionsToSelect(data.countries, data.path)}
        />
        <Button fullWidth data-testid="button-next">
          {t('ConnectAmazonScreen:connect')}
        </Button>
      </StyledForm>
    </ConnectionStepWrapper>
  );
};
