import { LoanTypesEnum } from 'lib/types';

export enum PortfolioApplicationSteps {
  addBusiness = 'addBusiness',
}

export enum PortfolioApplicationType {
  sell = 'sell',
  valuation = 'valuation',
}

export const LoanTypeByApplicationType = {
  [PortfolioApplicationType.sell]: LoanTypesEnum.sale_seller,
  [PortfolioApplicationType.valuation]: LoanTypesEnum.insights,
};

export const ApplicationTypeByLoanType: {
  [key in keyof typeof LoanTypesEnum]?: PortfolioApplicationType;
} = {
  [LoanTypesEnum.sale_seller]: PortfolioApplicationType.sell,
  [LoanTypesEnum.insights]: PortfolioApplicationType.valuation,
};

export type UsePortfolioApplicationFlowProps = {
  applicationType?: PortfolioApplicationType;
  applicationStep: PortfolioApplicationSteps;
} & {
  loanType?: LoanTypesEnum;
  applicationStep: PortfolioApplicationSteps;
};

export type NavigationPaths = {
  [key in keyof typeof PortfolioApplicationType]: {
    [key in keyof typeof PortfolioApplicationSteps]?: string;
  };
};
