import { motion } from 'framer-motion';

import { styled } from '@boopos/design-system';

export const MotionTabElements = {
  Wrapper: styled('div', {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
  TabsWrapper: styled(motion.div, {
    overflowY: 'hidden',
    overflowX: 'auto',
    display: 'flex',
    backgroundColor: '$surfaceSelected',
    borderRadius: '1rem 1rem 0 0',
  }),
  ChildrenWrapper: styled('div', {
    background: '$backgroundElevated',
    padding: '1.6rem',
    '@bp2': {
      padding: '2.4rem',
    },
    borderRadius: '0 0 1.6rem 1.6rem',
    position: 'relative',
  }),
  Button: styled(motion.button, {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    userSelect: 'none',
    border: 'none',
    appearance: 'none',
    background: 'none',
  }),
  Label: styled(motion.div, {
    zIndex: 3,
  }),
  Background: styled(motion.div, {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    zIndex: 2,
    borderRadius: '1rem 1rem 0 0',
    backgroundColor: '$backgroundElevated',
    variants: {
      hovered: {
        true: {
          zIndex: 1,
          backgroundColor: '$actionSecondaryHover',
        },
      },
    },
  }),
  Children: styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '2.4rem',
  }),
};
