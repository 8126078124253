import { styled } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const OnboardingHeader = {
  Wrapper: styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.6rem',
    color: '$textBodyPrimary',
  }),
  Steps: styled('div', {
    color: '$textBodyTertiary',
  }),
  Title: styled('h1', {
    maxWidth: '52.2rem',
    variants: {
      size: {
        h1: {
          fontHeadingSerif: '$h1',
        },
        h2: {
          fontHeadingSerif: '$h2',
        },
      },
    },
    defaultVariants: {
      size: 'h2',
    },
  }),
  Subtitle: styled('p', {
    color: '$textBodySecondary',
    maxWidth: '52.2rem',
  }),
  ActionsWrapper: styled('div', {
    mt: '4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  Action: styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '4rem',
    width: '4rem',
    color: '$actionNeutralNavigation',
    'a&, button&': {
      cursor: 'pointer',
    },
    variants: {
      alignment: {
        left: {
          justifyContent: 'flex-start',
        },
        right: {
          justifyContent: 'flex-end',
        },
      },
    },
    defaultVariants: {
      alignment: 'left',
    },
  }),
  BackArrow: () => (
    <FontAwesomeIcon icon={regular('arrow-left-long')} size="xl" />
  ),
  CloseXMark: () => <FontAwesomeIcon icon={regular('xmark-large')} size="xl" />,
};
