import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Switch } from '@boopos/design-system';

export const LendingAvailableFilter = () => {
  const { register } = useFormContext();
  const { t } = useTranslation();
  return (
    <Switch
      labelPosition="left"
      label={t('ForSaleFilters:lending_available_label')}
      labelProps={{ css: { fontWeight: 'bold', width: '100%' } }}
      {...register('lendingAvailable')}
    />
  );
};
