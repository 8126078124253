import { ReactNode } from 'react';

import { TooltipStyles } from './InsightsChartTooltip.styles';

export const InsightsChartTooltipRow = ({
  indicatorColor,
  variant = 'regular',
  valueAlign = 'right',
  label,
  value,
}: {
  variant?: 'light' | 'regular' | 'positive' | 'negative';
  valueAlign?: 'left' | 'right';
  indicatorColor?: string;
  label: ReactNode;
  value: ReactNode;
}) => {
  return (
    <TooltipStyles.Row variant={variant}>
      <div>
        <TooltipStyles.Indicator css={{ background: indicatorColor }} />
      </div>
      <TooltipStyles.RowLabel>{label}</TooltipStyles.RowLabel>
      <TooltipStyles.RowValue align={valueAlign}>
        {value}
      </TooltipStyles.RowValue>
    </TooltipStyles.Row>
  );
};
