import { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DealDetail } from 'components/DealDetail';
import { shouldShowCompleteClosingInformation } from 'components/DealTimeline/CompleteClosingInformation';
import { TermSheetHandler } from 'components/TermSheetHandler';

import { ILoanApi, LoanStatus } from 'lib/types';

interface Props extends ComponentProps<typeof DealDetail.SideWrapper> {
  loan?: ILoanApi;
  showInDesktop?: boolean;
}

export const WhatsNext = ({ loan }: Props) => {
  const { t } = useTranslation();

  if (!loan) {
    return null;
  }

  if (loan?.status === LoanStatus.contract_sent) {
    return <>{t('TimelineWhatsNext:status_contract_sent')}</>;
  }

  if (loan?.status === LoanStatus.canceled) {
    return <>{t('TimelineWhatsNext:status_canceled')}</>;
  }

  if (shouldShowCompleteClosingInformation(loan)) {
    return <>{t('TimelineWhatsNext:complete_closing_information')}</>;
  }

  if (loan?.status === LoanStatus.termsheet_sent) {
    return (
      <TermSheetHandler termSheet={loan?.termSheet}>
        <TermSheetHandler.Base>
          {t('TimelineWhatsNext:status_termsheet_sent')}
        </TermSheetHandler.Base>
        <TermSheetHandler.External>
          {t('TimelineWhatsNext:status_termsheet_sent_external')}
        </TermSheetHandler.External>
      </TermSheetHandler>
    );
  }

  if (
    loan?.status === LoanStatus.analysis ||
    loan?.status === LoanStatus.applied
  ) {
    return <>{t('TimelineWhatsNext:status_analysis')}</>;
  }

  if (loan?.canSendToEvaluation) {
    return <>{t('TimelineWhatsNext:can_send_to_evaluation')}</>;
  }

  if (loan?.status === LoanStatus.missing_info) {
    return <>{t('TimelineWhatsNext:initial_missing_info')}</>;
  }

  return null;
};

export const TimelineWhatsNext: FC<Props> = ({
  loan,
  showInDesktop = false,
  ...props
}) => {
  const { t } = useTranslation();

  if (!loan || loan?.status === LoanStatus.contract_signed) {
    return null;
  }

  return (
    <DealDetail.SideWrapper showInDesktop={showInDesktop} {...props}>
      <DealDetail.SideTitle data-testid="whats-next-title">
        {t('TimelineWhatsNext:title')}
      </DealDetail.SideTitle>
      <WhatsNext loan={loan} />
    </DealDetail.SideWrapper>
  );
};
