import { useTranslation } from 'react-i18next';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Menu } from 'components/MainMenu/Menu.styled';
import { MenuItem } from 'components/MainMenu/MenuItem.styled';
import { MenuItemNavLink } from 'components/MainMenu/MenuItemNavLink.styled';
import { MenuItemText } from 'components/MainMenu/MenuItemText.styled';

import {
  CursorFloating,
  fakeMenuItemsCss,
} from './FakeMenuDealFlowSelected.styles';

export const FakeMenuDealFlowSelected = ({
  onClick,
}: {
  onClick: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Menu css={fakeMenuItemsCss} onClick={onClick}>
      <MenuItem>
        <MenuItemNavLink as="div">
          <>
            <FontAwesomeIcon icon={regular('magnifying-glass')} />
            <MenuItemText>{t('DealMovingForwardModal:for_sale')}</MenuItemText>
          </>
        </MenuItemNavLink>
      </MenuItem>
      <MenuItem css={{ position: 'relative' }}>
        <MenuItemNavLink as="div" className="active">
          <>
            <FontAwesomeIcon icon={regular('bookmark')} />
            <MenuItemText>{t('DealMovingForwardModal:deal_flow')}</MenuItemText>
          </>
        </MenuItemNavLink>
        <CursorFloating>
          <FontAwesomeIcon icon={regular('hand-back-point-up')} size="lg" />
        </CursorFloating>
      </MenuItem>
      <MenuItem css={{ mb: 0 }}>
        <MenuItemNavLink as="div">
          <>
            <FontAwesomeIcon icon={regular('briefcase')} />
            <MenuItemText>{t('DealMovingForwardModal:portfolio')}</MenuItemText>
          </>
        </MenuItemNavLink>
      </MenuItem>
    </Menu>
  );
};
