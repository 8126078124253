import { Outlet } from 'react-router-dom';

import { AdditionalDetailsModal } from 'components/AdditionalDetailsModal';
import { ApplicationBusinessPerformanceModal } from 'components/ApplicationBusinessPerformanceModal';
import { ApplicationDealStructureModal } from 'components/ApplicationDealStructureModal';
import {
  BalanceSheetUploadModal,
  CohortsUploadModal,
  ProfitAndLossUploadModal,
} from 'components/BasicDocumentsUploadModal';
import { BusinessConnectionsModal } from 'components/BusinessConnectionsModal';
import { EnquiryModal } from 'components/EnquiryModal';
import { SlateBackgroundBody } from 'components/GlobalStyles';
import {
  PortfolioFirstScreenDecider,
  PortfolioRedirectionHandler,
  oldLoanDetailPath,
} from 'components/RedirectionHandler/PortfolioRedirectionHandler';

import { LoanDocumentType } from 'lib/types';

import { routePaths } from 'router/routePaths';

import { AddShareholderScreen } from 'screens/AddShareholderScreen';
import { BusinessFinancingScreen } from 'screens/BusinessFinancingScreen';
import { BusinessForSaleScreen } from 'screens/BusinessForSaleScreen';
import { BusinessIntegrationsScreen } from 'screens/BusinessIntegrationsScreen';
import { BusinessValuationScreen } from 'screens/BusinessValuationScreen';
import { ConnectAccountScreen } from 'screens/ConnectAccountScreen';
import { EditBusinessScreen } from 'screens/EditBusinessScreen';
import { EnquiriesScreen } from 'screens/EnquiriesScreen';
import { LoanDocumentUploadScreen } from 'screens/LoanDocumentUploadScreen';
import { PortfolioBusinessScreen } from 'screens/PortfolioBusinessScreen';
import { PortfolioListScreen } from 'screens/PortfolioListScreen';
import { PortfolioRequestGrowthLoanScreen } from 'screens/PortfolioRequestGrowthLoanScreen';
import { PortfolioRequestLoanScreen } from 'screens/PortfolioRequestLoanScreen';
import { ShareholdersScreen } from 'screens/ShareholdersScreen';
import { TermSheetScreen } from 'screens/TermSheetScreen';

import { generateSellerApplicationRoutes } from './sellerApplicationRoutes';

export const portfolioRoutes = {
  path: routePaths.portfolio,
  element: <Outlet />,
  children: [
    {
      path: routePaths.portfolio,
      element: <PortfolioListScreen />,
    },
  ],
};

export const portfolioFullScreenRoutes = {
  path: routePaths.portfolio,
  element: (
    <>
      <SlateBackgroundBody />
      <Outlet />
    </>
  ),
  children: [
    {
      path: routePaths.portfolioBusiness,
      element: <Outlet />,
      children: [
        {
          path: routePaths.portfolioBusiness,
          element: <PortfolioBusinessScreen />,
          children: [
            {
              index: true,
              element: <PortfolioFirstScreenDecider />,
            },
            {
              path: oldLoanDetailPath,
              element: <PortfolioRedirectionHandler />,
              children: [
                { path: '*', element: <PortfolioRedirectionHandler /> },
              ],
            },
            {
              path: routePaths.portfolioValuation,
              element: <BusinessValuationScreen />,
            },
            {
              path: routePaths.portfolioFinancing,
              element: <BusinessFinancingScreen />,
            },
            {
              path: routePaths.portfolioForSale,
              element: <BusinessForSaleScreen />,
              children: [
                {
                  path: routePaths.portfolioForSaleDealStructure,
                  element: (
                    <ApplicationDealStructureModal
                      basePath={routePaths.portfolioForSale}
                    />
                  ),
                },
                {
                  path: routePaths.portfolioForSaleBusinessPerformance,
                  element: (
                    <ApplicationBusinessPerformanceModal
                      basePath={routePaths.portfolioForSale}
                    />
                  ),
                },
                {
                  path: routePaths.portfolioForSaleProfitAndLoss,
                  element: (
                    <ProfitAndLossUploadModal
                      basePath={routePaths.portfolioForSale}
                    />
                  ),
                },
                {
                  path: routePaths.portfolioForSaleCohorts,
                  element: (
                    <CohortsUploadModal
                      basePath={routePaths.portfolioForSale}
                    />
                  ),
                },
                {
                  path: routePaths.portfolioForSaleAdditionalDetails,
                  element: (
                    <AdditionalDetailsModal
                      basePath={routePaths.portfolioForSale}
                    />
                  ),
                },
                {
                  path: routePaths.portfolioForSalePaymentProcessors,
                  element: (
                    <BusinessConnectionsModal
                      basePath={routePaths.portfolioForSale}
                    />
                  ),
                },
              ],
            },
            {
              path: routePaths.portfolioEnquiries,
              element: <EnquiriesScreen />,
              children: [
                {
                  path: routePaths.portfolioEnquiry,
                  element: <EnquiryModal />,
                },
              ],
            },
            {
              path: routePaths.portfolioIntegrations,
              element: <BusinessIntegrationsScreen />,
            },
          ],
        },
        {
          path: routePaths.portfolioLoanDetail,
          element: <Outlet />,
          children: [
            {
              path: routePaths.portfolioLoanDetail,
              async lazy() {
                const { LoanDetailScreen } = await import(
                  'screens/LoanDetailScreen'
                );
                return { Component: LoanDetailScreen };
              },
              children: [
                {
                  path: routePaths.portfolioPaymentMethod,
                  async lazy() {
                    const { PortfolioPaymentMethodModal } = await import(
                      'components/PaymentMethodModal/LazyPaymentMethodModal'
                    );
                    return { Component: PortfolioPaymentMethodModal };
                  },
                },
                {
                  path: routePaths.portfolioProfitAndLoss,
                  element: (
                    <ProfitAndLossUploadModal
                      basePath={routePaths.portfolioLoanDetail}
                    />
                  ),
                },
                {
                  path: routePaths.portfolioBalanceSheet,
                  element: (
                    <BalanceSheetUploadModal
                      basePath={routePaths.portfolioLoanDetail}
                    />
                  ),
                },
                {
                  path: routePaths.portfolioCohorts,
                  element: (
                    <CohortsUploadModal
                      basePath={routePaths.portfolioLoanDetail}
                    />
                  ),
                },
                {
                  path: routePaths.portfolioAdditionalDetails,
                  element: (
                    <AdditionalDetailsModal
                      basePath={routePaths.portfolioLoanDetail}
                    />
                  ),
                },
              ],
            },
            {
              path: routePaths.portfolioTermSheet,
              element: (
                <TermSheetScreen goBackUrl={routePaths.portfolioLoanDetail} />
              ),
            },

            {
              path: routePaths.portfolioEinConfirmation,
              element: (
                <LoanDocumentUploadScreen
                  goBackUrl={routePaths.portfolioLoanDetail}
                  goBackUrlOnError={routePaths.portfolio}
                  documentType={LoanDocumentType.companyIdentifier}
                />
              ),
            },
            {
              path: routePaths.portfolioArticlesOfIncorporation,
              element: (
                <LoanDocumentUploadScreen
                  goBackUrl={routePaths.portfolioLoanDetail}
                  goBackUrlOnError={routePaths.portfolio}
                  documentType={LoanDocumentType.articlesOfIncorporation}
                />
              ),
            },
            {
              path: routePaths.portfolioAccounts,
              element: (
                <ConnectAccountScreen
                  goBackUrl={routePaths.portfolioLoanDetail}
                />
              ),
            },
            {
              path: routePaths.portfolioShareholders,
              element: (
                <ShareholdersScreen
                  goBackUrl={routePaths.portfolioLoanDetail}
                  addShareholderPath={routePaths.portfolioShareholdersAdd}
                />
              ),
            },
            {
              path: routePaths.portfolioShareholdersAdd,
              element: (
                <AddShareholderScreen
                  goBackUrl={routePaths.portfolioShareholders}
                />
              ),
            },
          ],
        },
      ],
    },
    {
      path: routePaths.portfolioRequestLoan,
      element: <Outlet />,
      children: [
        {
          path: routePaths.portfolioRequestLoan,
          element: <PortfolioRequestLoanScreen />,
        },
        {
          path: routePaths.portfolioRequestGrowthLoan,
          element: <PortfolioRequestGrowthLoanScreen />,
        },
      ],
    },
    {
      path: routePaths.portfolioEditBusiness,
      element: <EditBusinessScreen />,
    },
    {
      path: routePaths.portfolioBusinessAccounts,
      element: (
        <ConnectAccountScreen goBackUrl={routePaths.portfolioIntegrations} />
      ),
    },

    ...generateSellerApplicationRoutes(routePaths.portfolioApplication),
  ],
};
