import { useTranslation } from 'react-i18next';

import { ContentCell } from 'ui/ContentCell';

import { DocumentsDetails } from 'components/DocumentsDetails';
import { useSubmitForEvaluationError } from 'components/SubmitForEvaluation';

import { LoanDocumentType } from 'lib/types';

import { BasicInformationDetailsPropsWithoutLoan } from './BasicInformationDetails.interface';
import { useDocumentsProps } from './useDocumentsProps';

export const BasicInformationProfitAndLoss = ({
  documents,
  requiredDocuments,
  editable = true,
  showIcon = true,
  path,
}: Exclude<BasicInformationDetailsPropsWithoutLoan, 'paths'> & {
  path: string;
}) => {
  const { t } = useTranslation();

  const { profitAndLoss } = useSubmitForEvaluationError();

  const errorMessage = profitAndLoss
    ? t('BasicInformationDetails:profit_and_loss_required')
    : undefined;

  const {
    visible: pnlVisible,
    contentCellProps,
    hasValue,
  } = useDocumentsProps(
    { documents, requiredDocuments },
    {
      editable,
      documentType: LoanDocumentType.profitAndLoss,
      baseRoute: path,
      error: errorMessage,
    }
  );

  const children = hasValue ? (
    <DocumentsDetails
      documents={documents}
      documentType={LoanDocumentType.profitAndLoss}
    />
  ) : (
    errorMessage
  );

  if (!pnlVisible) return null;

  return (
    <ContentCell
      title={t('BasicInformationDetails:p_n_l')}
      data-testid="pnl"
      showIcon={showIcon}
      {...contentCellProps}
    >
      {children}
    </ContentCell>
  );
};
