import { useCallback, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';

import {
  ActionSideModal,
  ActionSideModalBackHeader,
  ActionSideModalCloseHeader,
  useNavigateFromActionSideModal,
} from 'components/ActionSideModal';
import { useConnectedAccounts } from 'components/ForSaleDetailApplication/AttachPaymentProcessors/useConnectedAccounts';

import api from 'lib/api';
import { IBusinessAccount } from 'lib/types';

import { AccountsListContentPartial } from './AccountListContent.partial';
import { BaseContentPartial } from './BaseContent.partial';
import { ConnectionStatusModal } from './ConnectionStatusModal';

type SelectedScreen = 'base' | 'accountsList';

interface IOutletContext {
  handleRefreshLoan: () => Promise<void>;
  loanId?: string;
}

export const BusinessConnectionsModal = ({
  basePath,
}: {
  basePath: string;
}) => {
  const [selectedScreen, setSelectedScreen] = useState<SelectedScreen>('base');

  const params = useParams<{ businessId: string }>();

  const outletContext = useOutletContext<IOutletContext>() ?? {};
  const { handleRefreshLoan } = outletContext;
  const { onClose } = useNavigateFromActionSideModal(params, { basePath });
  const businessId = params.businessId;

  const { mutate: mutateAccounts } = useConnectedAccounts(businessId);

  const onConnectAccountClick = useCallback(() => {
    setSelectedScreen('accountsList');
  }, []);

  const onBackClick = useCallback(() => {
    setSelectedScreen('base');
  }, []);

  const onSuccess = useCallback(async () => {
    setSelectedScreen('base');
    await mutateAccounts();
    await handleRefreshLoan();
  }, [mutateAccounts, handleRefreshLoan]);

  const onDisconnectAccount = useCallback(
    async (account: IBusinessAccount) => {
      if (!businessId) return;
      await api.merchantsDisconnectAccount({
        provider: account.provider,
        businessId,
        externalId: account.externalId,
      });
      await mutateAccounts();
      await handleRefreshLoan();
    },
    [mutateAccounts, handleRefreshLoan, businessId]
  );

  const BaseHeader = <ActionSideModalCloseHeader onClose={onClose} />;
  const AccountListHeader = <ActionSideModalBackHeader onBack={onBackClick} />;

  return (
    <ActionSideModal
      header={
        <>
          {selectedScreen === 'base' && BaseHeader}
          {selectedScreen === 'accountsList' && AccountListHeader}
        </>
      }
      onClose={onClose}
    >
      {selectedScreen === 'base' && (
        <BaseContentPartial
          onConnectClick={onConnectAccountClick}
          businessId={businessId}
          onClose={onClose}
          onDisconnectAccount={onDisconnectAccount}
        />
      )}
      {selectedScreen === 'accountsList' && (
        <AccountsListContentPartial
          businessId={businessId}
          onSuccess={onSuccess}
        />
      )}
      <ConnectionStatusModal />
    </ActionSideModal>
  );
};
