import { UseFormReturn } from 'react-hook-form';

import { BankAccountType, IFundsBankAccountApi } from 'lib/types';

export interface IFundsBankAccountData extends IFundsBankAccountApi {}

export interface IUseFundsBankAccount {
  loanId: string;
  onSuccess?: () => void;
}

export interface IUseFundsBankAccountReturn {
  formMethods: UseFormReturn<IFundsBankAccountData>;
  onSubmit: (data: IFundsBankAccountData) => Promise<void>;
  onDelete: () => Promise<void>;
  isSuccess: boolean;
  isSubmitting: boolean;
  isWaitingForReview?: boolean;
  isVerified?: boolean;
  isReady: boolean;
}

export const defaultFormValues = {
  country: 'US',
  accountType: BankAccountType.escrow,
};
