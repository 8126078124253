import { Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IBusinessAccount } from 'lib/types';

import { ConnectedAccountsSummary } from './ConnectedAccountsSummary';

export interface SelfConnectedAccountsSummaryProps {
  accounts: IBusinessAccount[];
  title: string;
  onConnect: () => void;
  buttonText: string;
  onDeleteAccount?: () => void;
  allowEdit?: boolean;
}

export const SelfConnectedAccountsSummary = ({
  title,
  accounts,
  onConnect,
  buttonText,
  onDeleteAccount,
  allowEdit = true,
}: SelfConnectedAccountsSummaryProps) => {
  if (!accounts?.length) {
    return null;
  }
  return (
    <>
      <ConnectedAccountsSummary
        title={title}
        accounts={accounts}
        onDeleteAccount={allowEdit ? onDeleteAccount : undefined}
      />
      {allowEdit && (
        <Button
          leftIcon={<FontAwesomeIcon icon={regular('circle-plus')} size="lg" />}
          variant="secondary"
          onClick={onConnect}
        >
          {buttonText}
        </Button>
      )}
    </>
  );
};
