import { ReactNode } from 'react';

import { ContentCell } from 'ui/ContentCell';

import { ClosingStepsNames, ILoanApi } from 'lib/types';

import { ClosingChecklistStepItem } from './ClosingChecklistStepItem';

export interface ClosingChecklistGroupProps {
  title: ReactNode;
  steps: ClosingStepsNames[];
  loan: ILoanApi;
  isLast?: boolean;
  editable?: boolean;
}

export const ClosingChecklistGroup = ({
  title,
  steps,
  isLast,
  loan,
  editable,
  ...props
}: ClosingChecklistGroupProps) => {
  return (
    <>
      <ContentCell.Divider>{title}</ContentCell.Divider>
      <ContentCell.Parent bottomSpaced={!isLast} {...props}>
        {steps.map((step) => (
          <ClosingChecklistStepItem
            key={step}
            step={step}
            loan={loan}
            editable={editable}
          />
        ))}
      </ContentCell.Parent>
    </>
  );
};
