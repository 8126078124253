import { type TooltipRenderProps } from 'ui/InsightsLineChart';

import { YearlyGrowthDataEntry } from './InsightsCharts.interface';
import { YearlyGrowthComparisonChart } from './YearlyGrowthComparisonChart';
import { YearlyGrowthTooltip } from './YearlyGrowthTooltip';

const renderTooltip = (props: TooltipRenderProps) => {
  return <YearlyGrowthTooltip unit="money" {...props} />;
};

export const RevenueGrowthChart = ({
  data,
}: {
  data: YearlyGrowthDataEntry[];
}) => {
  return (
    <YearlyGrowthComparisonChart
      renderTooltip={renderTooltip}
      data={data}
      unit="money"
    />
  );
};
