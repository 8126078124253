import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import {
  ActionSideModal,
  ActionSideModalCloseHeader,
  useNavigateFromActionSideModal,
} from 'components/ActionSideModal';

import Api from 'lib/api';
import { ILoanApi } from 'lib/types';

import { routePaths } from 'router/routePaths';

import { TermSheetModalContent } from './TermSheetModalContent';

const TermSheetModal = () => {
  const params = useParams<{ loanId: string }>();
  const loanId = params.loanId as string;

  const { onClose } = useNavigateFromActionSideModal(
    {
      loanId,
    },
    { basePath: routePaths.dealFlowDetail }
  );

  const { data: loan, error } = useSWR<ILoanApi>(Api.getLoan(loanId));

  return (
    <ActionSideModal
      header={<ActionSideModalCloseHeader onClose={onClose} />}
      onClose={onClose}
    >
      <TermSheetModalContent loan={loan} loanId={loanId} error={error} />
    </ActionSideModal>
  );
};

export { TermSheetModal };
