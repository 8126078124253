import qs from 'query-string';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@boopos/design-system';

import amazonLogo from 'assets/account-providers/amazon-logo.png';

import { ProviderLogo } from 'components/AccountConnectionLayout';
import { ScreenWrapperContentCentered } from 'components/ScreenLayout';

import config from 'lib/config';

import { routePaths } from 'router/routePaths';

import { AmazonConnectorStepOne } from './AmazonConnectorStepOne';

const useSandbox =
  (config.ENVIRONMENT === 'local' || config.ENVIRONMENT === 'development') &&
  config.USE_CONNECTORS_SANDBOX;

export const AmazonConnector = () => {
  const [regionWithLink, setRegionWithLink] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const { t } = useTranslation();

  const onFormOneSubmit = useCallback(
    ({ regionWithLink }: { regionWithLink: string }) => {
      if (!regionWithLink) return;

      setRegionWithLink(regionWithLink);
      const [region, oauthLink] = regionWithLink.split(';');

      if (useSandbox) {
        setCurrentStep(1);
        return;
      }

      const redirectParams = {
        state: region,
        redirect_uri: `${window.location.origin}${routePaths.callbackAmazon}`,
      };

      const redirectUri = qs.stringifyUrl({
        url: oauthLink,
        query: redirectParams,
      });

      window.open(redirectUri, '_top');
    },
    []
  );

  const onFormTwoSubmit = useCallback(() => {
    window.opener.customCallback(regionWithLink);
    window.close();
  }, [regionWithLink]);

  const steps = useMemo(
    () => ({
      0: <AmazonConnectorStepOne onSubmit={onFormOneSubmit} />,
      1: (
        <Button onClick={onFormTwoSubmit} data-testid="connect-sandbox">
          {t('ConnectAmazonScreen:connect_sandbox')}
        </Button>
      ),
    }),
    [onFormOneSubmit, onFormTwoSubmit, t]
  );

  return (
    <ScreenWrapperContentCentered
      data-testid="amazon-connector"
      css={{
        justifyContent: 'unset',
      }}
    >
      <ProviderLogo src={amazonLogo} alt="Amazon logo" />
      {steps[currentStep as keyof typeof steps]}
    </ScreenWrapperContentCentered>
  );
};
