import { defaultCurrency, defaultLocale } from 'lib/constants';
import { parseMoneyFromApi } from 'lib/parse';
import { Currency, Money } from 'lib/types';

import {
  IFormatByCurrency,
  MoneyFormat,
  MoneyFormatConfig,
} from './formatMoney.interface';

const nonBreakingSpace = '\u00A0';

const abbrFormat = {
  style: 'currency',
  currencyDisplay: 'narrowSymbol',
  notation: 'compact',
  signDisplay: 'auto',
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
};

const longFormat = {
  style: 'currency',
  currencyDisplay: 'narrowSymbol',
  notation: 'standard',
  signDisplay: 'auto',
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
};

const FORMAT_BY_CURRENCY: IFormatByCurrency = {
  [Currency.USD]: {
    [MoneyFormat.abbr]: {
      currency: Currency.USD,
      ...abbrFormat,
    },
    [MoneyFormat.long]: {
      currency: Currency.USD,
      ...longFormat,
    },
  },
  [Currency.EUR]: {
    [MoneyFormat.abbr]: {
      currency: Currency.EUR,
      ...abbrFormat,
    },
    [MoneyFormat.long]: {
      currency: Currency.EUR,
      ...longFormat,
    },
  },
};

type FormatMoneyParams = [money?: Money, config?: MoneyFormatConfig];

export const getNumberFormatObject = (
  money?: Money,
  config?: MoneyFormatConfig
): null | {
  numberFormat: Intl.NumberFormat;
  amount: number;
  currency: Currency;
} => {
  if (!money) {
    return null;
  }

  const format = config?.format || MoneyFormat.abbr;
  const parsedMoney = parseMoneyFromApi(money, config?.divider);
  const amount = parsedMoney.amount;
  const currencyOptions =
    FORMAT_BY_CURRENCY[money.currency] || FORMAT_BY_CURRENCY[defaultCurrency];
  const formatOptions = currencyOptions[format];

  return {
    numberFormat: new Intl.NumberFormat(config?.locale || defaultLocale, {
      ...formatOptions,
      ...config?.additionalOptions,
    }),
    amount,
    currency: money.currency,
  };
};

export const getCurrencySymbol = (
  ...params: FormatMoneyParams
): string | undefined => {
  const numberFormatObject = getNumberFormatObject(...params);

  if (!numberFormatObject?.numberFormat) {
    return '';
  }

  return numberFormatObject?.numberFormat
    .formatToParts(1)
    .find((x) => x.type === 'currency')?.value;
};

export const formatMoneyWithoutSymbol = (
  ...params: FormatMoneyParams
): null | string => {
  const numberFormatObject = getNumberFormatObject(...params);

  if (!numberFormatObject?.numberFormat) {
    return null;
  }

  return numberFormatObject.numberFormat
    .formatToParts(numberFormatObject.amount)
    .map((x) => (x.type === 'currency' ? '' : x.value))
    .join('')
    .trim();
};

export const formatMoney = (...params: FormatMoneyParams): null | string => {
  const numberFormatObject = getNumberFormatObject(...params);

  if (!numberFormatObject?.numberFormat) {
    return null;
  }

  return numberFormatObject.numberFormat.format(numberFormatObject.amount);
};

export const formatMoneyWithSpacedSymbol = (...params: FormatMoneyParams) => {
  const [moneyValue] = params ?? [];

  if (!moneyValue) {
    return '';
  }

  const value = formatMoney(...params);

  const formatted = `${moneyValue.amount > 0 ? nonBreakingSpace : ''}${value}`;

  const symbol = getCurrencySymbol(moneyValue) ?? '';

  return formatted.replace(symbol, `${symbol}${nonBreakingSpace}`);
};
