import { ContentCellDetail } from 'ui/ContentCellDetail';

import { TermSheetColumnsShared } from 'components/TermSheet/TermSheetColumnsShared';

import { ITermSheetApi, ListingForSale } from 'lib/types';

import {
  useTermSheetFormattedValues,
  useTermSheetLiterals,
} from './TermSheet.hooks';
import { TermSheetLayout } from './TermSheet.styles';

export const LoanTermSheetColumns = ({
  termSheet,
  id,
  title,
}: {
  id?: string;
  title?: string;
  termSheet?: ITermSheetApi;
}) => {
  const literals = useTermSheetLiterals();

  const {
    amountFormatted,
    maxTermsFormatted,
    maxRoyaltyFormatted,
    maturityLengthFormatted,
    annualInterestFormatted,
    openingFeeFormatted,
  } = useTermSheetFormattedValues({
    amount: termSheet?.amount,
    capMultipliers: termSheet?.capMultipliers,
    royalties: termSheet?.royalties,
    annualInterest: termSheet?.annualInterest,
    openingFee: termSheet?.openingFee,
    maturityLength: termSheet?.maturityLength,
  });

  return (
    <TermSheetLayout.Wrapper>
      <TermSheetLayout.Column>
        <ContentCellDetail.Label>{literals.loanAmount}</ContentCellDetail.Label>
        <ContentCellDetail.Value>{amountFormatted}</ContentCellDetail.Value>
      </TermSheetLayout.Column>

      <TermSheetColumnsShared
        tsType={termSheet?.type}
        maxTermsFormatted={maxTermsFormatted}
        maxRoyaltyFormatted={maxRoyaltyFormatted}
        openingFeeFormatted={openingFeeFormatted}
        annualInterestFormatted={annualInterestFormatted}
        maturityLengthFormatted={maturityLengthFormatted}
        literals={literals}
        loanId={id}
        loanTitle={title}
      />
    </TermSheetLayout.Wrapper>
  );
};

export const ListingTermSheetColumns = ({
  listing,
  blur,
  wrap,
}: {
  listing?: ListingForSale;
  blur?: boolean;
  wrap?: boolean;
}) => {
  const literals = useTermSheetLiterals();

  const termSheet = listing?.termSheet?.value;

  const {
    amountFormatted,
    maxTermsFormatted,
    maxRoyaltyFormatted,
    maturityLengthFormatted,
    annualInterestFormatted,
    openingFeeFormatted,
  } = useTermSheetFormattedValues({
    amount: listing?.amount,
    capMultipliers: termSheet?.capMultipliers,
    royalties: termSheet?.royalties,
    annualInterest: termSheet?.annualInterest,
    openingFee: termSheet?.openingFee,
    maturityLength: termSheet?.maturityLength,
  });

  return (
    <TermSheetLayout.Wrapper wrap={wrap}>
      <TermSheetLayout.Column>
        <ContentCellDetail.Label>{literals.loanAmount}</ContentCellDetail.Label>
        <ContentCellDetail.Value>{amountFormatted}</ContentCellDetail.Value>
      </TermSheetLayout.Column>
      <TermSheetColumnsShared
        tsType={termSheet?.type}
        maxTermsFormatted={maxTermsFormatted}
        maxRoyaltyFormatted={maxRoyaltyFormatted}
        openingFeeFormatted={openingFeeFormatted}
        annualInterestFormatted={annualInterestFormatted}
        maturityLengthFormatted={maturityLengthFormatted}
        literals={literals}
        loanId={listing?.id}
        loanTitle={listing?.title}
        blur={blur}
        bordered={!wrap}
      />
    </TermSheetLayout.Wrapper>
  );
};
