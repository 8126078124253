import { styled } from '@boopos/design-system';

export const Styles = {
  Wrapper: styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '$4',
    px: '$4',
    mt: '$5',
    mb: '$8',

    '@bp2': {
      px: '$8',
    },
  }),
  URLWrapper: styled('div', {
    fontSize: '$2',
    color: '$slate500',
  }),
};
