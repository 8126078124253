import { ChartColors } from 'ui/InsightsShared';

export const useChartStyles = (colors: ChartColors) => {
  const lineActiveDot = {
    r: 7,
    strokeWidth: 2,
    stroke: 'white',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  };

  const axisTick = { fill: colors.tickColor };

  const tooltipCursor = { stroke: colors.tooltipCursor, strokeWidth: 24 };

  const chartMargin = {
    top: 0,
    right: 10,
    left: 0,
    bottom: 0,
  };

  return { lineActiveDot, axisTick, tooltipCursor, chartMargin };
};
