import { useTranslation } from 'react-i18next';

import { FlowStatusFeedback } from 'ui/FlowStatusFeedback';

import AnalyzingDraw from 'assets/illustrations/analyzing.svg';

export const AnalyzingState = () => {
  const { t } = useTranslation();

  return (
    <FlowStatusFeedback
      data-testid="analyzing-insights"
      title={t('BusinessValuation:analyzing_title')}
      description={t('BusinessValuation:analyzing_description')}
      illustration={
        <img src={AnalyzingDraw} alt={t('BusinessValuation:analyzing_title')} />
      }
      fullWidth={false}
      showBackground
    />
  );
};
