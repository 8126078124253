import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, ButtonFeedback } from '@boopos/design-system';

import { AvailableFundsFormWithData } from './AvailableFundsFormWithData';
import { AvailableFundsFormValues } from './ProofOfFunds.interface';
import { ProofOfFundsValidate } from './ProofOfFundsValidate';

interface AppendChildrenProps {
  onSubmit?: (data: AvailableFundsFormValues) => Promise<void>;
  isLoading?: boolean;
  isSuccess?: boolean;
}

const SaveButton = ({
  onSubmit,
  isLoading,
  isSuccess,
}: AppendChildrenProps) => {
  const { t } = useTranslation();
  const { handleSubmit } = useFormContext<AvailableFundsFormValues>();

  if (!onSubmit) {
    return null;
  }

  return (
    <ButtonFeedback
      isLoading={isLoading}
      isSuccess={isSuccess}
      fullWidth
      css={{ mt: '$6' }}
      onClick={handleSubmit(onSubmit)}
    >
      {t('ProofOfFundsAccount:save')}
    </ButtonFeedback>
  );
};

const AppendChildren = (props: AppendChildrenProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Box css={{ mb: '$6' }}>
        {t('ProofOfFundsAccount:proof_of_funds_title')}
      </Box>
      <ProofOfFundsValidate variant="light" continueButtonEnabled={false} />
      <SaveButton {...props} />
    </>
  );
};

export const ProofOfFundsAccount = () => {
  return (
    <Box css={{ mt: '$6' }}>
      <AvailableFundsFormWithData
        continueButtonEnabled={false}
        appendChildren={<AppendChildren />}
      />
    </Box>
  );
};
