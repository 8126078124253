import { styled } from '@boopos/design-system';

import { ActionsWrapper } from './ActionsWrapper';

export const OnboardingLayout = {
  Wrapper: styled('div', {
    maxWidth: '110.8rem',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    px: '1.6rem',
    gap: '4rem',
    pb: '4rem',

    '@bp2': {
      px: '3.2rem',
    },
    variants: {
      verticalSpacing: {
        xl: {
          pt: '2.4rem',
          '@bp2': {
            pt: '9.6rem',
          },
        },
      },
    },
  }),
  Content: styled('div', {
    display: 'flex',
    gap: '4.8rem',
    flexDirection: 'column',
    '@bp2': {
      flexDirection: 'row',
    },
  }),

  ContentWrapper: styled('div', {
    width: '100%',
    maxWidth: '52.2rem',
  }),

  InputHalf: styled('div', {
    width: '100%',
    display: 'flex',
    gap: '1.6rem',
    '> div': {
      flex: 1,
    },
  }),

  PlainText: styled('div', {
    color: '$textBodySecondary',

    '& p:not(:last-child)': {
      mb: '2.4rem',
    },

    '& a': {
      textDecoration: 'none',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),

  ActionsWrapper,
};
