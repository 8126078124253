import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';

import { Button, config } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LayoutSidebarDetail } from 'components/LayoutSidebarDetail';

import { useMeetingUrls } from 'lib/meetings';
import { SellApplicationStatus } from 'lib/types';

import { ActionShareListing } from './ActionShareListing';
import { ActionsSidebarProps } from './ActionsSidebar.interface';
import { PrimaryActions, getPrimaryAction } from './PrimaryActions';

export const ActionsSidebar = ({
  application,
  loanTermSheet,
  business,
  status,
}: ActionsSidebarProps) => {
  const { t } = useTranslation();
  const isDesktop = useMedia(config.media.bp2);
  const { sellerMeetingUrl } = useMeetingUrls();

  if (!application) {
    return null;
  }

  const primaryAction = getPrimaryAction(status);
  const PrimaryAction = !!primaryAction && PrimaryActions[primaryAction];
  const isPublishedStatus = status === SellApplicationStatus.published;

  const primaryProps = {
    className: 'sidebar-primary',
    fullWidth: true,
    rounded: false,
  };

  const secondaryProps = {
    fullWidth: isDesktop,
    rounded: !isDesktop,
  };

  return (
    <LayoutSidebarDetail.ActionSidebar>
      {PrimaryAction && (
        <PrimaryAction application={application} business={business} />
      )}
      <Button
        variant="secondary"
        as="a"
        href={sellerMeetingUrl}
        target="_blank"
        leftIcon={<FontAwesomeIcon icon={regular('comments')} />}
        data-testid="action-talk-to-advisor"
        {...(PrimaryAction ? secondaryProps : primaryProps)}
      >
        {t('ForSaleDetailApplication:actions_talk_to_advisor')}
      </Button>
      {isPublishedStatus && (
        <ActionShareListing
          application={application}
          status={status}
          loanTermSheet={loanTermSheet}
          business={business}
        />
      )}
    </LayoutSidebarDetail.ActionSidebar>
  );
};
