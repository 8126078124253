import {
  PortfolioApplicationRouteWrapper,
  PortfolioApplicationSell,
} from 'components/PortfolioApplication';
import { PortfolioApplicationAdvantages } from 'components/PortfolioApplication/PortfolioApplicationAdvantages/PortfolioApplicationAdvantages';
import { PortfolioApplicationFinished } from 'components/PortfolioApplication/PortfolioApplicationFinished';

import { SellerApplicationRoutePaths } from 'router/sellerApplicationRoutePaths';

export const generateSellerApplicationRoutes = (
  baseApplicationPaths: SellerApplicationRoutePaths
) => [
  {
    path: baseApplicationPaths.index,
    element: <PortfolioApplicationRouteWrapper paths={baseApplicationPaths} />,
    children: [
      {
        index: true,
        element: <PortfolioApplicationAdvantages />,
      },
      {
        path: baseApplicationPaths.addBusiness,
        element: <PortfolioApplicationSell />,
      },
      {
        path: baseApplicationPaths.applicationFinished,
        element: <PortfolioApplicationFinished />,
      },
    ],
  },
];
