import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { ButtonFeedback, createToastFeedback } from '@boopos/design-system';

import { StickyFooter } from 'components/ScreenLayout';

import Api from 'lib/api';

import { routePaths } from 'router/routePaths';

import { IAddTargetBusinessFormFields } from './AddTargetBusinessForm.interface';
import { parseToApi } from './AddTargetBusinessForm.utils';
import { AddTargetBusinessFormFields } from './AddTargetBusinessFormFields';
import { MatchingListingFoundModal } from './MatchingListingFoundModal';

export const AddTargetBusinessForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [matchingListingId, setMatchingListingId] = useState<
    string | undefined
  >(undefined);
  const [success, setSuccess] = useState(false);

  const formMethods = useForm<IAddTargetBusinessFormFields>({
    mode: 'onChange',
    shouldUseNativeValidation: false,
  });

  const { handleSubmit, formState } = formMethods;

  const handleMatchingListing = useCallback((listingId: string) => {
    setMatchingListingId(listingId);
  }, []);

  const handleMatchingListingClose = useCallback(() => {
    setMatchingListingId(undefined);
  }, []);

  const onSubmit = useCallback(
    async (data: IAddTargetBusinessFormFields) => {
      try {
        const { data: loan } = await Api.createWatchlistDeal(parseToApi(data));
        if (loan?.matchingListingId) {
          handleMatchingListing(loan.matchingListingId);
          return;
        }
        const loanId = loan?.id;
        setSuccess(true);
        createToastFeedback({
          type: 'success',
          title: t('AddTargetBusinessForm:success_message'),
        });
        setTimeout(() => {
          loanId &&
            navigate(generatePath(routePaths.dealFlowDetail, { loanId }));
        }, 1000);
      } catch (err) {
        createToastFeedback({
          type: 'warning',
          title: t('AddTargetBusinessForm:error_message'),
        });
        setSuccess(false);
      }
    },
    [navigate, t, handleMatchingListing]
  );

  const isLoading = formState.isSubmitting;

  return (
    <>
      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          data-testid="add-target-business-form"
        >
          <AddTargetBusinessFormFields />
          <StickyFooter>
            <ButtonFeedback
              type="submit"
              fullWidth
              isLoading={isLoading}
              isSuccess={success}
              data-testid="add-target-business-submit"
            >
              {t('AddTargetBusinessForm:add_business_button')}
            </ButtonFeedback>
          </StickyFooter>
        </form>
      </FormProvider>
      <MatchingListingFoundModal
        onClose={handleMatchingListingClose}
        matchingListingId={matchingListingId}
      />
    </>
  );
};
