import React from 'react';

import { Box } from '@boopos/design-system';

import { ContentCell } from 'ui/ContentCell';

import { BasicInformationAdditionalDetails } from 'components/BasicInformationDetails/BasicInformationAdditionalDetails';
import { BasicInformationCohorts } from 'components/BasicInformationDetails/BasicInformationCohorts';
import { BasicInformationProfitAndLoss } from 'components/BasicInformationDetails/BasicInformationProfitAndLoss';
import { AttachAskingPrice } from 'components/ForSaleDetailApplication/AttachAskingPrice';
import { AttachBusinessDetailsFeedback } from 'components/ForSaleDetailApplication/AttachBusinessDetailsFeedback/AttachBusinessDetailsFeedback';
import { AttachPaymentProcessors } from 'components/ForSaleDetailApplication/AttachPaymentProcessors/AttachPaymentProcessors';
import { BusinessPerformance } from 'components/ForSaleDetailApplication/BusinessPerformance';

import {
  ApplicationBusinessApi,
  SaleSellerApplicationApi,
  SellApplicationStatus,
} from 'lib/types';

import { routePaths } from 'router/routePaths';

interface AttachBusinessDetailsProps {
  business?: ApplicationBusinessApi;
  application?: SaleSellerApplicationApi;
  status?: SellApplicationStatus;
  editable?: boolean;
}

export const AttachBusinessDetails = ({
  application,
  business,
  editable = true,
  status,
}: AttachBusinessDetailsProps) => {
  const documents = application?.documents;
  const requiredDocuments = application?.requiredDocuments;

  return (
    <>
      <Box css={{ mb: '1.6rem' }}>
        <AttachBusinessDetailsFeedback
          status={status}
          notes={application?.notes}
        />
      </Box>
      <ContentCell.Parent data-testid="attach-business-details-parent">
        <AttachAskingPrice
          path={routePaths.portfolioForSaleDealStructure}
          application={application}
          editable={editable}
          showIcon={editable}
        />
        <BusinessPerformance
          path={routePaths.portfolioForSaleBusinessPerformance}
          application={application}
          editable={editable}
          showIcon={editable}
        />

        <BasicInformationProfitAndLoss
          documents={documents}
          requiredDocuments={requiredDocuments}
          path={routePaths.portfolioForSaleProfitAndLoss}
          editable={editable}
          showIcon={editable}
        />
        <BasicInformationCohorts
          documents={documents}
          requiredDocuments={requiredDocuments}
          path={routePaths.portfolioForSaleCohorts}
          editable={editable}
          showIcon={editable}
        />
        <AttachPaymentProcessors
          path={routePaths.portfolioForSalePaymentProcessors}
          editPath={routePaths.portfolioForSalePaymentProcessors}
          editable={editable}
          showIcon={editable}
          application={application}
          businessId={business?.id}
        />
        <BasicInformationAdditionalDetails
          documents={documents}
          additionalDetails={application?.additionalDetails ?? ''}
          path={routePaths.portfolioForSaleAdditionalDetails}
          editable={editable}
          showIcon={editable}
        />
      </ContentCell.Parent>
    </>
  );
};
