import { ChangeUnit, TotalChange } from 'components/InsightsCharts/InsightsCharts.interface';
import { dateLabelShort } from './dateLabelFormatter';
import { moneyValueFormatter } from './moneyValueFormatter';
import { percentValueFormatter } from './percentValueFormatter';

interface TableValues {
  fromHeader: string;
  toHeader: string;
  fromValue: string;
  toValue: string;
  changeAbsolute: string;
  changePercentage: string | undefined;
}

export const useTableValues = (
  totalChange?: TotalChange,
  unit: ChangeUnit = 'money'
): TableValues | null => {
  if (!totalChange || !unit) {
    return null;
  }

  const fromHeader = `${dateLabelShort(totalChange.startFrom)} - ${dateLabelShort(totalChange.startTo)}`;
  const toHeader = `${dateLabelShort(totalChange.endFrom)} - ${dateLabelShort(totalChange.endTo)}`;

  const fromValue =
    unit === 'money'
      ? moneyValueFormatter(totalChange.startSum)
      : percentValueFormatter(totalChange.startAverage);

  const toValue =
    unit === 'money'
      ? moneyValueFormatter(totalChange.endSum)
      : percentValueFormatter(totalChange.endAverage);

  const changeAbsolute =
    unit === 'money'
      ? moneyValueFormatter(totalChange.absoluteChange)
      : percentValueFormatter(totalChange.averageChange);

  const changePercentage =
    unit === 'money' && totalChange.percentageChange !== undefined
      ? percentValueFormatter(totalChange.percentageChange)
      : undefined;

  return {
    fromHeader,
    toHeader,
    fromValue,
    toValue,
    changeAbsolute,
    changePercentage,
  };
};
