import { styled } from '@boopos/design-system';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const Item = styled('div', {
  px: '$4',
  mb: '$7',
  color: '$textBodySecondary',
});

export const EnquiryModalStyles = Object.assign(Wrapper, {
  Item,
  Header: styled(Item, {
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    mb: '$3',
  }),
  FullName: styled('h3', {
    fontHeadingSerif: '$h3',
    gridRowStart: 2,
    gridColumn: '1 / span 3',
  }),
  Date: styled(Item, {
    fontSize: '1.4rem',
    color: '$textBodyTertiary',
  }),
  Label: styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '$2',
    color: '$textBodySecondary',
    fontWeight: '$bold',
    mb: '$5',
  }),
  AdvisorNotes: styled('div', {
    mt: '$3',
  }),
  Href: styled('a', {
    display: 'flex',
    alignItems: 'center',
    gap: '$2',
    color: '$textBodySecondary',
  }),
  HrefText: styled('span', {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textDecoration: 'underline',
    color: '$textBodySecondary',
  }),
  Actions: styled(Item, {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '$4',
    position: 'sticky',
    bottom: 0,
    p: '$4',
    m: 0,
    mt: 'auto',
    background: '$white',
    borderTop: '1px solid $slate100',
    zIndex: '$1',

    '& button': {
      flex: 1,
    },
  }),
  Loading: styled(Item, {}),
});
