import { useTranslation } from 'react-i18next';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { usePortfolioList } from 'components/PortfolioList/PortfolioList.hooks';

import { routePaths } from 'router/routePaths';

import { MenuItem } from './MenuItem.styled';
import { MenuItemNavLink } from './MenuItemNavLink.styled';
import { MenuItemText } from './MenuItemText.styled';

export const PortfolioMenuItem = () => {
  const { t } = useTranslation();

  const { isEmpty } = usePortfolioList();

  if (isEmpty) return null;

  return (
    <MenuItem>
      <MenuItemNavLink to={routePaths.portfolio} data-testid="portfolio">
        <>
          <FontAwesomeIcon icon={regular('briefcase')} />
          <MenuItemText>{t('MainMenu:portfolio')}</MenuItemText>
        </>
      </MenuItemNavLink>
    </MenuItem>
  );
};
