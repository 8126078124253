import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import Success from 'assets/illustrations/success.svg';

import { FeatureFlagKeys, useFlags } from 'lib/feature-flags';

import { MaintenanceModeScreenStyles } from './MaintenanceModeScreen.styles';

export const MaintenanceModeScreen = ({ children }: PropsWithChildren) => {
  const maintenanceMode = useFlags(FeatureFlagKeys.maintenanceMode);
  const { t } = useTranslation();

  if (maintenanceMode) {
    return (
      <MaintenanceModeScreenStyles.Wrapper data-testid="maintenance-mode-screen">
        <div>
          <img src={Success} alt={t('MaintenanceModeScreen:title')} />
        </div>
        <MaintenanceModeScreenStyles.Title>
          {t('MaintenanceModeScreen:title')}
        </MaintenanceModeScreenStyles.Title>

        <MaintenanceModeScreenStyles.Subtitle>
          {t('MaintenanceModeScreen:description')}
        </MaintenanceModeScreenStyles.Subtitle>
      </MaintenanceModeScreenStyles.Wrapper>
    );
  }

  return <>{children}</>;
};
