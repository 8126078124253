import FocusTrap from 'focus-trap-react';
import { AnimatePresence } from 'framer-motion';
import React, { useCallback } from 'react';
import { Portal } from 'react-portal';
import { RemoveScroll } from 'react-remove-scroll';
import { useNavigate } from 'react-router-dom';
import { useMedia } from 'react-use';

import { config, styled } from '@boopos/design-system';

import { ScrollStickyHeader } from 'components/ScrollStickyHeader';

import {
  IBaseModal,
  IModal,
  IPageModal,
  IPageModalWrapper,
} from './Modal.interface';
import {
  Backdrop,
  Content,
  ContentContainer,
  ContentWrapper,
  Wrapper,
  animationVariantProps,
  backdropAnimationProps,
} from './Modal.styles';

const noop = () => {
  // noop
};

const BaseModal = ({
  onClose,
  children,
  title,
  position,
  maxWidth,
  headerExtraContent,
  header,
  allowClose = true,
  renderContentContainer = false,
  contentContainerProps = {},
  ...props
}: IBaseModal) => {
  const isDesktop = useMedia(config.media.bp2);
  const animProps =
    (animationVariantProps as any)[position] || animationVariantProps.default;
  const { desktop, mobile } = animProps;
  const contentAnimation = isDesktop ? desktop : mobile;

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const ContentChildren = renderContentContainer ? (
    <ContentContainer {...contentContainerProps}>{children}</ContentContainer>
  ) : (
    children
  );

  return (
    <Portal>
      <FocusTrap
        focusTrapOptions={{
          fallbackFocus: document.body,
          allowOutsideClick: true,
        }}
      >
        <Wrapper>
          <Backdrop
            onClick={allowClose ? handleClose : noop}
            {...backdropAnimationProps}
          />
          <Content
            {...contentAnimation}
            position={position}
            css={{ '@bp2': { maxWidth } }}
            {...props}
          >
            <RemoveScroll forwardProps>
              <ContentWrapper>
                {header ? (
                  header
                ) : (
                  <ScrollStickyHeader
                    onClose={handleClose}
                    allowClose={allowClose}
                    title={title}
                    headerExtraContent={headerExtraContent}
                  />
                )}
                {ContentChildren}
              </ContentWrapper>
            </RemoveScroll>
          </Content>
        </Wrapper>
      </FocusTrap>
    </Portal>
  );
};

export const Modal = ({ opened, ...props }: IModal) => (
  <AnimatePresence>{opened && <BaseModal {...props} />}</AnimatePresence>
);

export const PageModal = ({ children, goBackURL, ...props }: IPageModal) => {
  const navigate = useNavigate();
  const { onClose } = props;

  const handleClose = useCallback(() => {
    if (!goBackURL) {
      onClose?.();
      return;
    }
    navigate(goBackURL, {
      preventScrollReset: true,
    });
  }, [goBackURL, navigate, onClose]);

  return (
    <BaseModal {...props} onClose={handleClose}>
      {children}
    </BaseModal>
  );
};

export const PageModalWrapper = ({ children }: IPageModalWrapper) => (
  <AnimatePresence>{children}</AnimatePresence>
);

export const ModalActions = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1.6rem',
  mt: '2.4rem',
});
