import { styled } from '@boopos/design-system';

export const FormStyles = {
  BlockTitle: styled('div', {
    fontWeight: 'bold',
    mb: '$6',
    variants: {
      topPadded: {
        true: {
          pt: '$6',
        },
      },
    },
  }),
  RadioWrapper: styled('div', {
    mb: '$6',
    '> div:first-child': {
      alignItems: 'flex-start',
    },
  }),
  RadioHelp: styled('div', {
    mt: '$2',
    color: '$slate500',
  }),
};
