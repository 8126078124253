import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import isEmail from 'validator/lib/isEmail';

import { Checkbox, Input, styled } from '@boopos/design-system';

import { CountrySelect } from 'components/CountrySelect';
import { FormFilesUploader } from 'components/FormFilesUploader';

import { getInputErrorProps } from 'lib/forms';

const PersonFormStyles = {
  BlockTitle: styled('div', {
    fontWeight: 'bold',
    mb: '$6',
  }),
};

export const PersonFormFields = ({
  allFieldsDisabled,
  loanId,
}: {
  allFieldsDisabled?: boolean;
  loanId: string;
}) => {
  const { t } = useTranslation();
  const { register, formState, control, watch } = useFormContext();

  const countryWatch = watch('country');

  const identifierLabel =
    countryWatch === 'US'
      ? t('PersonFormFields:ssn_label')
      : t('PersonFormFields:id_number');

  const shareholderWatch = watch('shareholder');
  const directorWatch = watch('director');

  const shareholderAndDirectorRequired = !shareholderWatch && !directorWatch;

  return (
    <>
      <PersonFormStyles.BlockTitle>
        {t('PersonFormFields:legal_entity_role')}
      </PersonFormStyles.BlockTitle>

      <Checkbox
        id="person-shareholder"
        label={t('PersonFormFields:shareholder_label')}
        {...register('shareholder', {
          required: shareholderAndDirectorRequired,
        })}
        disabled={allFieldsDisabled}
        groupSpacing="none"
        {...(shareholderAndDirectorRequired
          ? getInputErrorProps(formState.errors?.shareholder)
          : {})}
      />
      <Checkbox
        label={t('PersonFormFields:director_label')}
        id="person-director"
        {...register('director', {
          required: shareholderAndDirectorRequired,
        })}
        disabled={allFieldsDisabled}
        {...(shareholderAndDirectorRequired
          ? getInputErrorProps(formState.errors?.director)
          : {})}
      />

      <PersonFormStyles.BlockTitle>
        {t('PersonFormFields:person_details')}
      </PersonFormStyles.BlockTitle>

      <Input
        label={t('PersonFormFields:first_name_label')}
        disabled={allFieldsDisabled}
        {...register('firstName', {
          required: true,
        })}
        {...getInputErrorProps(formState.errors?.firstName)}
      />

      <Input
        label={t('PersonFormFields:last_name_label')}
        disabled={allFieldsDisabled}
        {...register('lastName', {
          required: true,
        })}
        {...getInputErrorProps(formState.errors?.lastName)}
      />

      <Input
        label={t('PersonFormFields:email_label')}
        disabled={allFieldsDisabled}
        {...register('email', {
          required: true,
          validate: (email: string) =>
            isEmail(email) || t('PersonFormFields:email_invalid'),
        })}
        {...getInputErrorProps(formState.errors?.email)}
      />

      <CountrySelect
        name="country"
        inputId="person-country"
        control={control}
        rules={{ required: true }}
        disabled={allFieldsDisabled}
      />

      <Input
        label={identifierLabel}
        id="person-identifier"
        disabled={allFieldsDisabled}
        {...register('identifier', {
          required: true,
        })}
        {...getInputErrorProps(formState.errors?.identifier)}
      />

      <FormFilesUploader
        title={t('PersonFormFields:passport_upload')}
        control={control}
        name="passport.files"
        maxFiles={5}
        disabled={allFieldsDisabled}
        uploadParams={{ loanId }}
      />
    </>
  );
};
