import { styled } from '@boopos/design-system';

export const Title = styled('h2', {
  fontSize: '2.4rem',
  lineHeight: '3.2rem',
  mt: '$4',
  '@bp2': {
    mt: '3.5rem',
  },
});

export const Description = styled('div', {
  mt: '$4',
  mb: '$6',
});

export const Wrapper = styled('div', {
  margin: '0 auto',
  maxWidth: '40rem',
  position: 'absolute',
  inset: 0,
  overflow: 'auto',
  px: '$4',
});
