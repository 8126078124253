import { styled } from '@boopos/design-system';

export const Styles = {
  ActionsContainer: styled('div', {
    display: 'flex',
    gap: '2.4rem',
    'button, a': {
      px: 0,
      '&, &:hover': {
        background: 'transparent',
      },
    },
    mb: '4rem',
  }),
  FilesWrapper: styled('div', {
    mb: '4rem',
  }),
};
