import { styled } from '@boopos/design-system';

export const DetailItem = styled('div', {
  display: 'flex',
  mb: '$6',
});

export const DetailItemLiteral = styled('div', {
  flex: 1,
  fontWeight: 'bold',
});

export const DetailItemAmount = styled('div', {
  position: 'relative',
});

const StyledSvg = styled('svg', {
  pointerEvents: 'none',
});

export const HandWrittenCircle = (props: any) => (
  <StyledSvg
    width={51}
    height={38}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m43.575 6.657 1.355 1.046c1.788 1.426 3.421 3.233 4.654 5.753 1.695 3.376 1.88 7.18.493 10.936-.709 1.95-1.695 3.47-2.835 4.802-2.25 2.568-4.777 4.184-7.427 5.468-3.883 1.855-7.858 2.853-11.895 3.186-4.376.38-8.721.047-13.067-.999-3.143-.713-6.255-1.854-9.183-3.946a16.24 16.24 0 0 1-3.267-3.09C-.154 26.531-.709 21.442.924 16.926c.801-2.235 1.942-4.09 3.205-5.706 3.02-3.851 6.503-6.276 10.2-8.035C19.415.76 24.591-.238 29.892.047c3.513.19 7.026.714 10.416 2.235 1.017.428 1.972 1.094 2.958 1.664.247.143.463.38.678.618.555.666.524 1.237-.092 1.855-.062.095-.154.142-.277.238Zm-15.1-4.232v-.143c-.186 0-.401-.047-.586-.047-4.284.047-8.505.998-12.635 2.9-3.575 1.617-6.995 3.899-9.923 7.56-1.171 1.474-2.25 3.09-3.02 5.135-1.418 3.804-.955 7.94 1.264 10.603a13.697 13.697 0 0 0 2.711 2.473c2.589 1.854 5.332 2.853 8.105 3.614 4.006 1.093 8.043 1.378 12.08 1.236 4.746-.19 9.43-1.332 13.93-3.709 2.126-1.141 4.16-2.52 5.978-4.565 1.11-1.283 2.065-2.805 2.681-4.754.678-2.188.74-4.422-.062-6.562a14.758 14.758 0 0 0-1.11-2.282c-1.324-2.187-2.927-3.709-4.714-4.898-2.219-1.474-4.5-2.567-6.872-3.28-3.143-.999-6.318-1.474-9.492-.476-.154.048-.37.143-.493.048-.215-.19-.493-.428-.555-.714-.061-.285.124-.76.278-.998.185-.238.462-.333.709-.428.585-.333 1.14-.523 1.725-.713Zm9.86 1.521c1.51.761 2.99 1.474 4.5 2.235-1.387-1.379-2.897-1.95-4.5-2.235Z"
      fill="#BEF264"
    />
  </StyledSvg>
);

export const HandWrittenLine = (props: any) => (
  <StyledSvg
    width={60}
    height={6}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 4C10.705 2.81 34.092.904 58 2.806"
      stroke="#A3E635"
      strokeWidth={3}
      strokeLinecap="round"
    />
  </StyledSvg>
);

export const MultipliersWrapper = styled('div', {
  width: '100%',
  overflowY: 'visible',
  overflowX: 'auto',
  '@bp2': {
    display: 'flex',
    justifyContent: 'center',
    overflowY: 'visible',
    overflowX: 'visible',
  },
  '&::-webkit-scrollbar': {
    height: '.8rem',
  },
  '&::-webkit-scrollbar-track': {
    background: '$white',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '$slate200',
    borderRadius: '.8rem',
  },
  variants: {
    showScroll: {
      true: {
        display: 'block',
        justifyContent: 'flex-start',
        overflowY: 'visible',
        overflowX: 'auto',
      },
    },
  },
});

export const MultipliersContainer = styled('div', {
  display: 'flex',
  width: 'fit-content',
  flexWrap: 'nowrap',
  pb: '$2',
});

export const MultipliersChild = styled('div', {
  minWidth: '9.5rem',
  textAlign: 'center',
  p: '$4',
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
  mr: '$4',
  whiteSpace: 'nowrap',
  background: '$white',
});

export const MultiplierHint = styled('div', {
  fontSize: '1rem',
  lineHeight: '1rem',
  color: '$slate400',
  whiteSpace: 'nowrap',
});

export const MultiplierYear = styled('div', {
  mb: '$1',
  whiteSpace: 'nowrap',
});

export const MultiplierNumber = styled('div', {
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
});

export const AmortizationTable = {
  Table: styled('table', {
    width: '100%',
    border: '1px solid $slate300',
    borderRadius: '$md',
    overflow: 'hidden',
    borderSpacing: 0,
  }),
  Th: styled('thead', {
    bc: '$slate200',
  }),
  TBody: styled('tbody', {
    '& td': {
      fontFamily: '$mono',
    },
  }),
  Tr: styled('tr', {
    borderBottom: '1px solid $slate300',
  }),
  Td: styled('td', {
    p: '$2',
    border: 'none',
  }),
};
