import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import {
  AmazonCallbackScreen,
  ErpCallbackScreen,
  StripeCallbackScreen,
} from 'components/AccountConnectors/screens';
import { ProtectedRoute } from 'components/ProtectedRoute';

import { CompanionModeScreen } from 'lib/companion/CompanionModeScreen';

import {
  BaseRouterProvider,
  BaseRouterProviderLayout,
} from 'providers/BaseRouterProvider';
import { GuestRoutesProvider } from 'providers/GuestRoutesProvider';
import { RootProvider } from 'providers/RootProvider';
import { UrlTokenProvider } from 'providers/UrlTokenProvider';

import { BrokerListingGrantAccessScreen } from 'screens/BrokerListingGrantAccessScreen';
import { ConnectAccountInvitationScreen } from 'screens/ConnectAccountInvitationScreen';
import { ConnectAccountScreen } from 'screens/ConnectAccountScreen';
import { CustomConnectionScreen } from 'screens/CustomConnectionScreen';
import { ErrorElementScreen } from 'screens/ErrorElementScreen';
import { LogoutScreen } from 'screens/LogoutScreen';

import { defaultLandingUrl, routePaths } from './routePaths';
import {
  accountRoutes,
  brokerLandingRoutes,
  confidentialityAgreementRoutes,
  dealFlowRoutes,
  landingRoutes,
  listingsRoutes,
  onboardingRoutes,
  portfolioFullScreenRoutes,
  portfolioRoutes,
  qualifyRoutes,
  watchlistRoutes,
} from './routes';
import { businessInsightsRoutes } from './routes/businessInsightsRoutes';
import { forSaleDetailRoutes, forSaleRoutes } from './routes/forSaleRoutes';
import { getFinancingRoutes } from './routes/getFinancingRoutes';
import { investmentCriteriaRoutes } from './routes/investmentCriteriaRoutes';
import { sellerShareRoutes } from './routes/sellerShareRoutes';
import { unlockListingRoutes } from './routes/unlockListingRoutes';

const notFoundRoute: RouteObject = {
  path: '*',
  element: <Navigate to={routePaths.base} replace />,
};

const logoutRoute: RouteObject = {
  path: routePaths.logout,
  element: <LogoutScreen />,
};

const guestRoutes: RouteObject = {
  path: routePaths.base,
  element: (
    <GuestRoutesProvider>
      <Outlet />
    </GuestRoutesProvider>
  ),
  children: [
    {
      path: routePaths.companion,
      element: <CompanionModeScreen />,
    },
    {
      path: routePaths.callbackErp,
      element: <ErpCallbackScreen />,
    },
    {
      path: routePaths.callbackStripe,
      element: <StripeCallbackScreen />,
    },
    {
      path: routePaths.callbackAmazon,
      element: <AmazonCallbackScreen />,
    },
    {
      path: routePaths.brokerListingGrantAccess,
      element: <BrokerListingGrantAccessScreen />,
    },
    {
      path: routePaths.initCustomProviderConnection,
      element: (
        <UrlTokenProvider>
          <CustomConnectionScreen />
        </UrlTokenProvider>
      ),
    },
    {
      path: routePaths.invitationConnectAccounts,
      element: (
        <UrlTokenProvider>
          <Outlet />
        </UrlTokenProvider>
      ),
      children: [
        {
          path: routePaths.invitationConnectAccounts,
          element: <ConnectAccountInvitationScreen />,
        },
        {
          path: routePaths.invitationConnectAccountsAdd,
          element: (
            <ConnectAccountScreen
              goBackUrl={routePaths.invitationConnectAccounts}
              showInfoHint={false}
            />
          ),
        },
      ],
    },
    unlockListingRoutes,
    getFinancingRoutes,
  ],
};

const protectedRoutes: RouteObject = {
  path: routePaths.base,
  element: <ProtectedRoute component={BaseRouterProvider} />,
  children: [
    {
      index: true,
      element: <Navigate to={defaultLandingUrl} />,
    },
    {
      path: routePaths.base,
      element: <BaseRouterProviderLayout />,
      children: [
        listingsRoutes,
        ...forSaleRoutes,
        accountRoutes,
        portfolioRoutes,
        watchlistRoutes,
        dealFlowRoutes,
        sellerShareRoutes,
        brokerLandingRoutes,
      ],
    },
    {
      path: routePaths.base,
      element: <Outlet />,
      children: [
        ...landingRoutes,
        ...forSaleDetailRoutes,
        businessInsightsRoutes,
        portfolioFullScreenRoutes,
        investmentCriteriaRoutes,
        onboardingRoutes,
        qualifyRoutes,
        ...confidentialityAgreementRoutes,
      ],
    },
  ],
};

export const appRoutes: RouteObject[] = [
  {
    path: routePaths.base,
    errorElement: <ErrorElementScreen />,
    element: (
      <RootProvider>
        <Outlet />
      </RootProvider>
    ),
    children: [
      notFoundRoute,
      logoutRoute,
      // Guest routes (unprotected)
      guestRoutes,
      // Protected routes
      protectedRoutes,
    ],
  },
];
