import { useTranslation } from 'react-i18next';

import {
  AvailableFundsFormWithData,
  ProofOfFundsValidate,
  useProofOfFundsData,
  useQualifyProofOfFundsFlow,
} from 'components/ProofOfFunds';
import { QualifyScreenWrapper } from 'components/QualifyScreenWrapper';

import { routePaths } from 'router/routePaths';

import { QualificationScreens, useStepsCounter } from './useStepsCounter';

export const QualifyProofOfFunds = () => {
  const { t } = useTranslation();
  const { onUpdateAvailableFunds } = useQualifyProofOfFundsFlow();

  const { step, totalSteps } = useStepsCounter(
    QualificationScreens.proofOfFunds
  );

  return (
    <QualifyScreenWrapper
      title={t('QualifyScreen:proof_of_funds_title')}
      subtitle={t('QualifyScreen:proof_of_funds_description')}
      step={step}
      totalSteps={totalSteps}
      prevScreen={routePaths.qualifyProfile}
      data-testid="qualify-proof-of-fund"
    >
      <AvailableFundsFormWithData onSuccess={onUpdateAvailableFunds} />
    </QualifyScreenWrapper>
  );
};

export const QualifyProofOfFundsValidate = () => {
  const { t } = useTranslation();
  const { availableFundsFormatted } = useProofOfFundsData();
  const { onProofOfFundsValidate } = useQualifyProofOfFundsFlow();

  const { step, totalSteps } = useStepsCounter(
    QualificationScreens.proofOfFundsValidate
  );

  return (
    <QualifyScreenWrapper
      title={t('QualifyScreen:proof_of_funds_validate_title', {
        amount: availableFundsFormatted,
      })}
      subtitle={t('QualifyScreen:proof_of_funds_validate_description')}
      step={step}
      totalSteps={totalSteps}
      prevScreen={routePaths.qualifyProofOfFund}
      data-testid="qualify-proof-of-fund-validate"
    >
      <ProofOfFundsValidate
        onSuccess={onProofOfFundsValidate}
        variant="light"
      />
    </QualifyScreenWrapper>
  );
};
