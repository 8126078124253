import { styled, theme } from '@boopos/design-system';
import { colorAndBackground } from '@boopos/utils';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const defaultAlpha = 0.2;

const EmptyBankIcon = styled('div', {
  width: '4rem',
  height: '4rem',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...colorAndBackground(theme.colors.slate400.value, defaultAlpha),
});

interface EmptyWithIconProps {
  primaryColor?: string;
}

export const EmptyWithIcon = ({
  primaryColor,
  ...rest
}: EmptyWithIconProps) => {
  const css = primaryColor
    ? colorAndBackground(primaryColor, defaultAlpha)
    : undefined;

  return (
    <EmptyBankIcon {...(css ? { css } : {})} {...rest}>
      <FontAwesomeIcon icon={regular('building-columns')} />
    </EmptyBankIcon>
  );
};
