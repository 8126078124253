import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { formatDate } from '@boopos/utils';

import {
  BenchmarkData,
  IApiBusinessScores,
  ScoreTypesEnum,
} from 'components/BusinessInsights';
import { InsightsCardsWithCharts } from 'components/InsightsCharts/InsightsCardsWithCharts';
import { ValuationCard } from 'components/ValuationCard';
import { SellBusinessBanner } from 'components/ValuationCard/SellBusinessBanner';

import api, { apiV2 } from 'lib/api';

import { ScreenLayout } from './BusinessValuationScreen.styles';
import { AnalyzingState } from './states/AnalyzingState';

const benchmarks = Object.keys(ScoreTypesEnum).filter((key) => key);

const getScore = (
  type: ScoreTypesEnum,
  { businessScores }: { businessScores?: IApiBusinessScores }
) => {
  return businessScores?.scores?.[type];
};

export const BusinessValuationScreen = () => {
  const { t } = useTranslation();
  const { businessId } = useParams() || {};

  const { data: insightsData } = useSWR<IApiBusinessScores>(
    businessId && api.getBusinessScores(businessId)
  );

  const { data: business } = useSWR(
    businessId && api.getPortfolioBusiness(businessId)
  );

  const hasInsights = !!Object.keys(insightsData?.scores ?? {}).length;

  const benchmarkScores = useMemo(
    () =>
      benchmarks.reduce(
        (acc, type) => ({
          ...acc,
          [type]: getScore(type as ScoreTypesEnum, {
            businessScores: insightsData,
          }),
        }),
        {}
      ),
    [insightsData]
  );

  if (!hasInsights) {
    return (
      <ScreenLayout.Wrapper data-testid="valuation-screen-empty">
        <AnalyzingState />
      </ScreenLayout.Wrapper>
    );
  }

  const { analysisDate, type: businessType } = insightsData?.scores ?? {};

  return (
    <ScreenLayout.Wrapper>
      <ScreenLayout.ColumnsWrapper>
        <ScreenLayout.Heading data-testid="valuation-heading">
          {t('BusinessValuation:valuation_heading')}
        </ScreenLayout.Heading>
        <ScreenLayout.Date>
          {analysisDate &&
            t('BusinessValuation:underwriting_date', {
              analysisDate: formatDate(analysisDate, 'MMMM YYYY'),
            })}
        </ScreenLayout.Date>
      </ScreenLayout.ColumnsWrapper>
      <ValuationCard />
      <SellBusinessBanner />
      <ScreenLayout.BenchmarksSection>
        <ScreenLayout.Heading data-testid="benchmark-insights-heading">
          {t('BusinessValuation:benchmark_insights_heading')}
        </ScreenLayout.Heading>
        <ScreenLayout.BenchmarksDescription>
          {t('BusinessValuation:benchmark_insights_description')}
        </ScreenLayout.BenchmarksDescription>
        <InsightsCardsWithCharts
          scores={benchmarkScores as Record<ScoreTypesEnum, BenchmarkData>}
          businessType={businessType}
          chartsService={businessId && apiV2.businessInsightsCharts(businessId)}
          cohortsService={businessId && apiV2.businessCohortsCharts(businessId)}
          businessName={business?.name}
        />
      </ScreenLayout.BenchmarksSection>
    </ScreenLayout.Wrapper>
  );
};
