import { styled } from '@boopos/design-system';

export const Styles = {
  Wrapper: styled('div', {
    mb: '3.2rem',
  }),
  SliderLabel: styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1.6rem',
    mt: '2.4rem',
    '> div': {
      mb: 0,
      width: 'calc(50% - 1.6rem)',
      flex: 1,
    },
  }),
  SliderLabelColumn: styled('div', {
    py: '.8rem',
    flex: 1,
    width: '50%',
  }),
  SliderLabelText: styled('div', {
    fontSize: '1.4rem',
    lineHeight: '2.2rem',
    color: '$slate700',
    mb: '.8rem',
  }),
  SliderLabelValue: styled('div', {
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    color: '$slate900',
    fontWeight: 'bold',
    fontFamily: '$mono',
  }),
};
