import { ReactNode, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ButtonFeedback, InlineFeedback } from '@boopos/design-system';

import { BusinessForm, IBusinessFormFields } from 'components/BusinessForm';

import { IAddBusinessForm } from './AddBusinessForm.interface';

const baseRenderActionChildren = (actionChildren: ReactNode) => (
  <>{actionChildren}</>
);

export const AddBusinessForm = ({
  onSuccess,
  copies,
  saveEndpoint,
  submitButtonProps = { fullWidth: true },
  renderActionChildren = baseRenderActionChildren,
}: IAddBusinessForm) => {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const formMethods = useForm<IBusinessFormFields>();
  const { handleSubmit, formState } = formMethods;

  const isLoading = formState.isSubmitting;
  const { submitButtonLabel } = copies;

  const handleErrorRemove = useCallback(() => {
    setHasError(false);
  }, []);

  const onSubmit = useCallback(
    async (formData: IBusinessFormFields) => {
      try {
        const { data } = await saveEndpoint(formData);
        onSuccess(data);
        setIsSuccess(true);
      } catch (err) {
        setHasError(true);
        setIsSuccess(false);
      }
    },
    [saveEndpoint, onSuccess]
  );

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={handleErrorRemove}
        data-testid="add-business-form"
      >
        <BusinessForm copies={copies} />
        {hasError && (
          <InlineFeedback
            variant="warning"
            css={{ mb: '$8' }}
            heading={t('AddBusinessForm:error_message')}
          />
        )}
        {renderActionChildren(
          <ButtonFeedback
            type="submit"
            isLoading={isLoading}
            isSuccess={isSuccess && !hasError}
            data-testid="add-business-form-submit"
            data-action-primary
            {...submitButtonProps}
          >
            {submitButtonLabel}
          </ButtonFeedback>
        )}
      </form>
    </FormProvider>
  );
};
