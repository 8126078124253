import { FilesPreview, IFilesPreview } from 'components/FilesPreview';
import { UploadZone } from 'components/UploadZone';
import { IUploadZone } from 'components/UploadZone/UploadZone.interface';

export const FilesUploader = ({
  upload,
  preview,
  disabled,
}: {
  disabled?: boolean;
  upload: IUploadZone;
  preview: IFilesPreview;
}) => {
  const maxFiles = upload.maxFiles || 1;
  const multiple = maxFiles > 1;
  const shouldShowUploader = maxFiles > preview.files.length && !disabled;

  return (
    <>
      <FilesPreview disabled={disabled} {...preview} />
      {shouldShowUploader && <UploadZone {...upload} multiple={multiple} />}
    </>
  );
};
