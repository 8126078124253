import { styled } from '@boopos/design-system';

export const MultipleRangeTooltipStyles = {
  Wrapper: styled('div', {
    background: '$backgroundInverted',
    color: '$textBodyInverted',
    padding: '.4rem .8rem',
    borderRadius: '.8rem',
    textAlign: 'center',
    fontSize: '1.4rem',
    lineHeight: '2.4rem',
    display: 'block',
    position: 'static',
    outline: 'none',
  }),
  Content: styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '$1',
    fontSize: '1.4rem',
  }),
  Title: styled('p', {
    fontSize: '$2',
  }),
};
