import { useTranslation } from 'react-i18next';

import { TermSheetHandler } from 'components/TermSheetHandler';

import { ILoanApi, LoanTypesEnum } from 'lib/types';

export const useSubtitle = (loan: ILoanApi) => {
  const { t } = useTranslation();
  const buyerInsightsEnabled = !!loan.businessInsights;

  if (loan.type === LoanTypesEnum.sale_buyer) {
    return t('DealTimelineStep:deal_accept_loan_terms_sale_buyer_subtitle');
  }

  return (
    <TermSheetHandler termSheet={loan?.termSheet}>
      <TermSheetHandler.Base>
        {buyerInsightsEnabled
          ? t('DealTimelineStep:deal_accept_loan_terms_subtitle_with_insights')
          : t('DealTimelineStep:deal_accept_loan_terms_subtitle')}
      </TermSheetHandler.Base>
      <TermSheetHandler.External>
        {t(
          'DealTimelineStep:deal_accept_loan_terms_subtitle_external_terms_base'
        )}
        {buyerInsightsEnabled && (
          <>
            {' '}
            {t(
              'DealTimelineStep:deal_accept_loan_terms_subtitle_external_terms_insights'
            )}
          </>
        )}
        <div>
          {t(
            'DealTimelineStep:deal_accept_loan_terms_subtitle_external_terms_contact'
          )}
        </div>
      </TermSheetHandler.External>
    </TermSheetHandler>
  );
};

export const useTitle = (loan: ILoanApi) => {
  const { t } = useTranslation();
  const buyerInsightsEnabled = !!loan.businessInsights;

  return (
    <TermSheetHandler termSheet={loan?.termSheet}>
      <TermSheetHandler.Base>
        {buyerInsightsEnabled
          ? t('DealTimelineStep:deal_accept_loan_terms_title_with_insights')
          : t('DealTimelineStep:deal_accept_loan_terms_title')}
      </TermSheetHandler.Base>
      <TermSheetHandler.External>
        {t('DealTimelineStep:deal_accept_loan_terms_title_external_terms')}
      </TermSheetHandler.External>
    </TermSheetHandler>
  );
};
