import { useTranslation } from 'react-i18next';

import { truncateValue } from '@boopos/utils';

import {
  IMultipleRange,
  IValuationHistogramData,
} from 'components/ValuationCard/ValuationCard.interface';

import { MultipleRangeTooltipStyles } from 'screens/BusinessValuationScreen/HistogramChart/MultipleRangeTooltip/MultipleRangeTooltip.styles';

interface IMultipleRangeTooltipProps {
  active?: boolean;
  payload?: {
    payload: IValuationHistogramData;
  }[];
  multiple: IMultipleRange;
  businessTypeName: string;
}

export const MultipleRangeTooltip = ({
  active,
  payload,
  multiple,
  businessTypeName,
}: IMultipleRangeTooltipProps) => {
  const { t } = useTranslation();
  const match = payload?.[0]?.payload?.match;

  if (!active || !match) {
    return null;
  }

  return (
    <MultipleRangeTooltipStyles.Wrapper data-testid="multiple-range-tooltip">
      <MultipleRangeTooltipStyles.Title>
        {t('BusinessValuation:histogram_chart_tooltip_title', {
          businessTypeName,
        })}
      </MultipleRangeTooltipStyles.Title>
      <MultipleRangeTooltipStyles.Content>
        {truncateValue(multiple.low)}× - {truncateValue(multiple.high)}×
      </MultipleRangeTooltipStyles.Content>
    </MultipleRangeTooltipStyles.Wrapper>
  );
};
