import { Link } from 'react-router-dom';

import { ILoanApi } from 'lib/types';

import { AdditionalDetailsIcon } from './AdditionalDetails.styles';
import { notEditableProps, useInfoPath } from './BasicInformationDetails.utils';

export const useAdditionalDetailsProps = (
  loan: ILoanApi,
  { editable, path }: { editable: boolean; path: string }
) => {
  const { url: additionalDetailsPath, isMatching } = useInfoPath(path);

  const defaultProps = {
    as: Link,
    to: additionalDetailsPath,
  };

  const additionalDetailsProps = isMatching
    ? {
        variant: 'active' as const,
      }
    : {
        customIcon: <AdditionalDetailsIcon />,
      };

  if (!editable) return notEditableProps.props;

  return { ...defaultProps, ...additionalDetailsProps };
};
