import {
  TermSheetHandlerInternal,
  TermSheetHandlerTypeProps,
} from './TermSheetHandler.types';

export const TermSheetByType = ({ children }: TermSheetHandlerTypeProps) => (
  <>{children}</>
);

TermSheetByType.defaultProps = {
  __internalTermSheetHandler: TermSheetHandlerInternal.ByTpe,
};
