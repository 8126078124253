import qs from 'query-string';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FullScreenLoader } from 'components/FullScreenLoader';

import api from 'lib/api';

export const UrlTokenProvider = ({ children }: PropsWithChildren<any>) => {
  const { token } = useParams();
  const { query } = qs.parseUrl(window.location.href);
  const { token: queryToken } = query;
  const [isTokenReady, setIsTokenReady] = useState(false);

  useEffect(() => {
    (async () => {
      if (token || queryToken) {
        await api.setAccessTokenGetter(() => token ?? queryToken);
        setIsTokenReady(true);
      }
    })();
  }, [token, queryToken]);

  if (!isTokenReady) {
    return <FullScreenLoader data-testid="loading-token" />;
  }

  return children;
};
