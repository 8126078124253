import { useLocation, useParams } from 'react-router-dom';

import { generatePaths } from './generatePaths';

export const useRoutePathMatches = (routePathsList: string[]) => {
  const params = useParams();
  const { pathname } = useLocation();

  return routePathsList.map(generatePaths(params)).includes(pathname);
};
