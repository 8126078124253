import { styled } from '@boopos/design-system';

export const MaintenanceModeScreenStyles = {
  Wrapper: styled('div', {
    position: 'absolute',
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  }),
  Title: styled('div', {
    fontSize: '$5',
    fontWeight: '$bold',
    mt: '$8',
  }),
  Subtitle: styled('div', {
    fontSize: '$3',
    color: '$slate400',
    mt: '$4',
  }),
};
