import { InlineMessage } from 'ui/InlineMessage';

import { IDealStatusFeedbackProps } from './DealStatusFeedback.interface';
import { useDealStatusFeedback } from './useDealStatusFeedback';

export const DealStatusFeedback = ({ loan }: IDealStatusFeedbackProps) => {
  const statusFeedbackConfig = useDealStatusFeedback({
    loan,
  });

  if (!statusFeedbackConfig) {
    return null;
  }

  return <InlineMessage {...(statusFeedbackConfig as any)} theme="elevated" />;
};
