import { ITabsMenuProps } from './TabsMenu.interface';
import { TabMenuStyles } from './TabsMenu.styles';

export const TabsMenu = ({ children, ...props }: ITabsMenuProps) => {
  return (
    <TabMenuStyles.Wrapper {...props}>
      <TabMenuStyles.List>{children}</TabMenuStyles.List>
    </TabMenuStyles.Wrapper>
  );
};
