import { useTranslation } from 'react-i18next';

import { Heading } from 'components/InsightsCards/partials';
import { IValuationData } from 'components/ValuationCard/ValuationCard.interface';
import { CurrencyValue } from 'components/ValuationCard/partials/CurrencyValue';

import { Styles } from './EstimatedValueRange.styles';

export const EstimatedValueRange = ({
  valuation,
  isValuationAvailable,
}: {
  valuation: IValuationData;
  isValuationAvailable: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Heading
        heading={t('Insights:ValuationCard:estimated_value_range')}
        tooltip={t('Insights:ValuationCard:estimated_value_range_description')}
        tooltipId="estiamted-value-range-tooltip"
      />
      {isValuationAvailable ? (
        <Styles.Wrapper data-testid="valuation-range">
          <CurrencyValue value={valuation.low} />
          <Styles.Dash>-</Styles.Dash>
          <CurrencyValue value={valuation.high} />
        </Styles.Wrapper>
      ) : (
        <Styles.NotAvailable data-testid="valuation-not-available">
          {t('Insights:ValuationCard:valuation_not_available')}
        </Styles.NotAvailable>
      )}
    </div>
  );
};
