import { useCallback, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { LinkedInCVFrom } from 'components/LinkedInCVForm';
import { preloadProofOfFunds } from 'components/ProofOfFunds';
import { QualifyScreenWrapper } from 'components/QualifyScreenWrapper';

import { useTrackUserGoals } from 'lib/hooks/useTrackUserGoals';

import { routePaths } from 'router/routePaths';

import { QualificationScreens, useStepsCounter } from './useStepsCounter';

export const QualifyProfile = () => {
  const { t } = useTranslation();
  const [, startTransition] = useTransition();
  const navigate = useNavigate();
  const { trackBuyerGoal } = useTrackUserGoals();

  const handleOnSuccess = useCallback(async () => {
    await preloadProofOfFunds();
    await trackBuyerGoal();

    setTimeout(() => {
      startTransition(() => {
        navigate(routePaths.qualifyProofOfFund);
      });
    }, 500);
  }, [navigate, trackBuyerGoal]);

  const { step, totalSteps } = useStepsCounter(QualificationScreens.profile);

  return (
    <QualifyScreenWrapper
      step={step}
      totalSteps={totalSteps}
      title={t('QualifyScreen:profile_title')}
      subtitle={t('QualifyScreen:profile_subtitle')}
      data-testid="qualify-profile"
    >
      <LinkedInCVFrom onSuccess={handleOnSuccess} />
    </QualifyScreenWrapper>
  );
};
