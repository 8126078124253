import { useTranslation } from 'react-i18next';

import { formatMoney } from 'lib/format';
import { MoneyFormat } from 'lib/format/money/formatMoney.interface';
import { Money, MultiplierTuple } from 'lib/types';

interface TermSheetDetailsProps {
  capMultipliers?: MultiplierTuple[];
  royalties?: MultiplierTuple[];
  amount?: Money;
  annualInterest?: number;
  openingFee?: number;
  maturityLength?: number;
}

const plainTuple = (tuple: [number, number | null][]) =>
  tuple.map(([first]) => {
    return first;
  });

export const useTermSheetDetails = (props?: TermSheetDetailsProps) => {
  const {
    capMultipliers,
    royalties,
    amount,
    maturityLength,
    openingFee,
    annualInterest,
  } = props ?? {};

  const multipliers = plainTuple(capMultipliers || []);
  const plainRoyalties = plainTuple(royalties || []);

  const maxTerms = multipliers.length;
  const maxRoyalty = Math.max(
    ...(plainRoyalties.length ? plainRoyalties : [0])
  );

  return {
    amount,
    maxTerms,
    maxRoyalty,
    maturityLength,
    openingFee,
    annualInterest,
  };
};

export const useTermSheetLiterals = () => {
  const { t } = useTranslation();

  return {
    loanAmount: t('TermSheetDetails:loan_amount'),
    maxTerms: t('TermSheetDetails:max_terms'),
    maxRoyalty: t('TermSheetDetails:max_royalty'),
    termSheet: t('TermSheetDetails:term_sheet'),
    duration: t('TermSheetDetails:term_sheet_duration'),
    openingFee: t('TermSheetDetails:term_sheet_opening_fee'),
    annualInterest: t('TermSheetDetails:term_sheet_annual_interest'),
  };
};

export const useTermSheetFormattedValues = (props?: TermSheetDetailsProps) => {
  const { t } = useTranslation();

  const values = useTermSheetDetails(props);

  const {
    amount,
    maxTerms,
    maxRoyalty,
    maturityLength,
    openingFee,
    annualInterest,
  } = values || {};

  const amountFormatted = amount
    ? formatMoney(amount, {
        format: MoneyFormat.long,
      })
    : '';

  const maxTermsFormatted = t('TermSheetDetails:year', {
    count: maxTerms ?? 0,
  });

  const maturityLengthFormatted = t('TermSheetDetails:month', {
    count: maturityLength ?? 0,
  });

  return {
    amountFormatted,
    maxTermsFormatted,
    maxRoyaltyFormatted: `${maxRoyalty}%`,
    maturityLengthFormatted,
    openingFeeFormatted: `${openingFee ?? 0}%`,
    annualInterestFormatted: `${annualInterest ?? 0}%`,
  };
};
