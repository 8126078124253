import { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { mutate } from 'swr';

import { Box } from '@boopos/design-system';

import { BackButton } from 'components/BackButton';
import { LayoutHideMenu } from 'components/Layout';
import { ProfileForm } from 'components/ProfileForm';
import {
  AnimatedScreenSlideWrapper,
  SubNavigationFirstLevelContent,
} from 'components/ScreenLayout';
import { SubScreenTitle } from 'components/SubScreenTitle';

import api from 'lib/api';

import { routePaths } from 'router/routePaths';

export const ProfileScreen = () => {
  const { t } = useTranslation();

  const handleSuccess = useCallback(async () => {
    await mutate(api.getQualification);
  }, []);

  const title = t('QualificationScreenMenu:profile_section');

  return (
    <AnimatedScreenSlideWrapper>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <LayoutHideMenu hideOn="all" shouldShowMenuOnUnmount={false} />
      <SubNavigationFirstLevelContent>
        <BackButton to={routePaths.accountQualification} />
        <SubScreenTitle>{title}</SubScreenTitle>
        <Box css={{ mt: '$6' }}>
          <ProfileForm onSuccess={handleSuccess} />
        </Box>
      </SubNavigationFirstLevelContent>
    </AnimatedScreenSlideWrapper>
  );
};
