import React from 'react';
import { FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '@boopos/design-system';

import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  MainErrorMessage,
  SecondaryErrorMessage,
  ErrorFeedback as StyledErrorFeedback,
} from './ErrorFeedback.styled';

interface IErrorFeedback {
  multiple?: boolean;
  fileRejections: FileRejection[];
  errors: string[];
  onOpenUploadDialog: () => void;
  onRemoveError: () => void;
}

export const ErrorFeedback = ({
  multiple,
  fileRejections,
  errors,
  onRemoveError,
  onOpenUploadDialog,
}: IErrorFeedback) => {
  const { t } = useTranslation();

  return (
    <StyledErrorFeedback>
      <Button
        rounded
        variant="nude"
        leftIcon={<FontAwesomeIcon icon={solid('circle-x')} size="lg" />}
        onClick={onRemoveError}
        data-testid="close-error-button"
      />
      <Box>
        <MainErrorMessage>
          {t('FileUploaderErrors:upload_process_error', {
            count: +(fileRejections.length === 1),
          })}
        </MainErrorMessage>
        <SecondaryErrorMessage data-testid="upload-error">
          {errors[0]}
        </SecondaryErrorMessage>
      </Box>
      <Button
        data-testid="error-upload-button"
        variant="secondary"
        leftIcon={<FontAwesomeIcon icon={regular('cloud-arrow-up')} />}
        onClick={onOpenUploadDialog}
      >
        {t('FileUploader:upload_action', { count: +!multiple })}
      </Button>
    </StyledErrorFeedback>
  );
};
