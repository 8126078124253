import { useTranslation } from 'react-i18next';

import { Button, styled } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ModalOverSideModal } from 'components/ModalOverSideModal';

const SendingForReviewFirstTimeModalStyles = {
  Explanation: styled('div', {
    color: '$slate500',
    mb: '4rem',
    ul: {
      listStyle: 'disc',
      ml: '2.4rem',
      li: {
        mb: '1.6rem',
      },
    },
  }),
};

export const SendingForReviewFirstTimeModal = ({
  visible,
  onSendForReview,
  onDoItLater,
}: {
  visible: boolean;
  onSendForReview: (e: any) => void;
  onDoItLater: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <ModalOverSideModal
      visible={visible}
      data-testid="sending-for-review-first-time-confirmation"
    >
      <ModalOverSideModal.Title>
        {t('SendingForReviewFirstTimeModal:title')}
      </ModalOverSideModal.Title>
      <ModalOverSideModal.Subtitle>
        {t('SendingForReviewFirstTimeModal:how_works')}
      </ModalOverSideModal.Subtitle>

      <SendingForReviewFirstTimeModalStyles.Explanation>
        <ul>
          <li>{t('SendingForReviewFirstTimeModal:explanation_first')}</li>

          <li>{t('SendingForReviewFirstTimeModal:explanation_second')}</li>
        </ul>
      </SendingForReviewFirstTimeModalStyles.Explanation>

      <ModalOverSideModal.Subtitle css={{ mb: '4rem' }}>
        {t('SendingForReviewFirstTimeModal:confirm_title')}
      </ModalOverSideModal.Subtitle>
      <ModalOverSideModal.CTAContainer>
        <Button
          type="button"
          variant="secondary"
          data-testid="do-it-later-button"
          onClick={onDoItLater}
        >
          {t('SendingForReviewFirstTimeModal:later')}
        </Button>
        <Button
          onClick={onSendForReview}
          data-testid="confirm-send-review-button"
          leftIcon={<FontAwesomeIcon icon={regular('check')} />}
        >
          {t('SendingForReviewFirstTimeModal:send_for_review')}
        </Button>
      </ModalOverSideModal.CTAContainer>
    </ModalOverSideModal>
  );
};
