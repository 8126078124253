import { styled } from '@boopos/design-system';

export const CenteredContentScreen = {
  Wrapper: styled('div', {
    maxWidth: '104.4rem',
    px: '$4',
    pb: '$6',
    m: '0 auto',

    '@bp2': {
      px: '$8',
      pt: 0,
      pb: 0,
    },
  }),
  Content: styled('div', {
    maxWidth: 490,
    mx: 'auto',
    mb: '8rem',
    mt: '1.6rem',
    '@bp2': { mt: '4rem' },
  }),
  Title: styled('h1', {
    fontSize: '2.4rem',
    fontWeight: '$bolder',
    lineHeight: '3.2rem',
    mb: '$6',

    '&:has(+ h2) ': {
      mb: '$4',
    },
  }),
  Description: styled('h2', {
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    color: '$slate800',
    fontWeight: '400',
    mb: '$6',
  }),
};
