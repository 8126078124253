import { useCallback } from 'react';

import { ForSaleCard } from 'components/ForSaleCard';
import {
  IApiForSaleFilters,
  IForSaleFiltersForm,
  parseForSaleFiltersToApi,
} from 'components/ForSaleFilters';
import { ForSaleListMatching } from 'components/ForSaleListMatching';

import { apiV2 } from 'lib/api';
import {
  setLastElementRef,
  useInfiniteScroll,
} from 'lib/hooks/useInfiniteScroll';
import { usePaginatedResponse } from 'lib/hooks/usePaginatedResponse';
import { ListingForSaleReduced } from 'lib/types';

import { ForSaleListError } from './ForSaleListError';
import { ForSaleListLoading } from './ForSaleListLoading';

interface Props {
  filters: IForSaleFiltersForm;
  onResetFilters: () => void;
  onApplyPartialFilters: (value: Partial<IForSaleFiltersForm>) => void;
}

export const ForSaleList = ({
  filters,
  onResetFilters,
  onApplyPartialFilters,
}: Props) => {
  const parsedFilters = parseForSaleFiltersToApi(filters);

  const {
    data,
    isEmpty,
    isFirstLoading,
    isReachingEnd,
    error,
    loadNextPage,
    mutate,
  } = usePaginatedResponse<ListingForSaleReduced, IApiForSaleFilters>(
    apiV2.getListings,
    {
      fetcher: apiV2.filterListingsPost.bind(apiV2),
      queryParams: parsedFilters,
    }
  );

  const lastElementRef = useInfiniteScroll(isReachingEnd, loadNextPage);

  const isError = error && !data.length;

  const onSuccess = useCallback(async () => {
    await mutate();
  }, [mutate]);

  if (isError) {
    return <ForSaleListError />;
  }

  if (isFirstLoading) {
    return <ForSaleListLoading />;
  }

  return (
    <>
      {data.map((listing, index) => (
        <ForSaleCard
          key={listing.id}
          listing={listing}
          onSuccess={onSuccess}
          ref={setLastElementRef(data, index, lastElementRef)}
        />
      ))}

      {isReachingEnd && (
        <ForSaleListMatching
          isEmpty={isEmpty}
          filters={filters}
          onResetFilters={onResetFilters}
          onApplyPartialFilters={onApplyPartialFilters}
        />
      )}
    </>
  );
};
