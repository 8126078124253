import { useMemo } from 'react';
import { Line, LineChart } from 'recharts';

import { theme } from '@boopos/design-system';

import { parseRevenueHistoryForChart } from 'lib/parse';
import { RevenueHistory } from 'lib/types';

export const ListingTinyChart = ({
  revenueHistory,
}: {
  revenueHistory: RevenueHistory;
}) => {
  const data = useMemo(() => {
    return parseRevenueHistoryForChart(revenueHistory);
  }, [revenueHistory]);

  return (
    <LineChart width={63} height={24} data={data}>
      <Line
        type="monotone"
        dataKey="revenue"
        stroke={theme.colors.slate600.computedValue}
        strokeWidth={1}
        dot={false}
      />
    </LineChart>
  );
};
