import { useTranslation } from 'react-i18next';

import { Button } from '@boopos/design-system';

import { NewUpdateBanner } from './NewUpdateBanner.styles';
import { useNewUpdateAvailable } from './useNewUpdateAvailable';

export const AppUpdatesHandler = () => {
  const { t } = useTranslation();
  const { newVersionAvailable, reloadPage } = useNewUpdateAvailable();

  if (!newVersionAvailable) {
    return null;
  }

  return (
    <NewUpdateBanner data-testid="new-update-banner">
      <div>{t('AppUpdatesHandler:new_version_text')}</div>
      <div>
        <Button
          onClick={reloadPage}
          data-testid="new-update-reload"
          css={{ whiteSpace: 'nowrap' }}
        >
          {t('AppUpdatesHandler:reload_action')}
        </Button>
      </div>
    </NewUpdateBanner>
  );
};
