import { ButtonFeedback } from '@boopos/design-system';

import { Modal } from 'components/Modal';

import { IConfirmationModal } from './ConfirmationModal.interface';
import {
  ConfirmationModalActionsContainer,
  ConfirmationModalDescription,
  ConfirmationModalTitle,
  ConfirmationModalWrapper,
} from './ConfirmationModal.styles';

const onClose = () => {
  // noop
};

export const ConfirmationModal = ({
  opened,
  title,
  description,
  confirmButtonProps,
  cancelButtonProps,
}: IConfirmationModal) => {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      position="centered"
      maxWidth={320}
      allowClose={false}
    >
      <ConfirmationModalWrapper>
        <ConfirmationModalTitle>{title}</ConfirmationModalTitle>
        {!!description && (
          <ConfirmationModalDescription>
            {description}
          </ConfirmationModalDescription>
        )}
        <ConfirmationModalActionsContainer>
          <ButtonFeedback {...confirmButtonProps} />
          <ButtonFeedback {...cancelButtonProps} />
        </ConfirmationModalActionsContainer>
      </ConfirmationModalWrapper>
    </Modal>
  );
};
