import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { IBusinessFormFields } from 'components/BusinessForm';

import { apiV2 } from 'lib/api';
import { LoanTypesEnum } from 'lib/types';

export const useAddApplicationBusiness = ({
  loanType,
  afterSuccessPath,
}: {
  afterSuccessPath: string;
  loanType?: LoanTypesEnum;
}) => {
  const navigate = useNavigate();

  const addBusiness = useCallback(
    async (data: IBusinessFormFields) => {
      if (!loanType) return;
      const {
        data: { loan, business },
      } = await apiV2.createBusinessWithLoan({
        business: { ...data },
        application: { loanType },
      });
      return { data: { loan, business } };
    },
    [loanType]
  );

  const onSuccess = useCallback(
    ({
      loan,
      business,
    }: {
      business: { id: string };
      loan: { id: string };
    }) => {
      navigate(
        generatePath(afterSuccessPath, {
          loanId: loan.id,
          businessId: business.id,
        })
      );
    },
    [afterSuccessPath, navigate]
  );

  return {
    addBusiness,
    onSuccess,
  };
};
