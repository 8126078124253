import { styled } from '@boopos/design-system';

export const Menu = styled('ul', {
  listStyleType: 'none',
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  flex: 1,
  '@bp2': {
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});
