import { defaultCurrency } from 'lib/constants';
import { Money } from 'lib/types';

export const parseMoneyFromApi = (
  money: Money | undefined,
  divider: number = 100
) => {
  if (!money) {
    return {
      amount: 0,
      currency: defaultCurrency,
    };
  }

  return {
    amount: money.amount / divider,
    currency: money.currency,
  };
};
