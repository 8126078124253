import type * as Stitches from '@stitches/react';

import { styled } from '@boopos/design-system';

import { modifyVariantsForStory } from 'ui/storyUtils';

export const avatarVariants = {
  slate: {
    backgroundColor: '$decorationSlateSoft',
    color: '$decorationSlateDark',
  },
  pink: {
    backgroundColor: '$decorationPinkSoft',
    color: '$decorationPinkDark',
  },
  orange: {
    backgroundColor: '$decorationOrangeSoft',
    color: '$decorationOrangeDark',
  },
  blue: {
    backgroundColor: '$decorationBlueSoft',
    color: '$decorationBlueDark',
  },
  yellow: {
    backgroundColor: '$decorationYellowSoft',
    color: '$decorationYellowDark',
  },
};

export const Avatar = styled('div', {
  width: '4.2rem',
  height: '4.2rem',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 500,
  variants: {
    variant: avatarVariants,
  },
  defaultVariants: {
    variant: 'slate',
  },
});

type Props = {};
type AvatarVariants = Stitches.VariantProps<typeof Avatar>;

export const AvatarStory = modifyVariantsForStory<
  AvatarVariants,
  Props,
  typeof Avatar
>(Avatar);
