import { styled } from '@boopos/design-system';

export const EmptyStateStyles = {
  Wrapper: styled('div', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '56rem',
    margin: '10rem auto 0 auto',
  }),
  Picture: styled('div', {
    mb: '$4',
  }),
  Title: styled('h3', {
    fontHeadingSerif: '$h3',
    textAlign: 'center',
  }),
  Description: styled('div', {
    textAlign: 'center',
    mt: '$4',
  }),
};
