import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

export const getSentryIntegrations = ({
  organization,
  project,
}: {
  organization?: string;
  project?: number;
} = {}) => {
  return [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    ...(organization && project
      ? [new posthog.SentryIntegration(posthog, organization, project)]
      : []),
  ];
};
