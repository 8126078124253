import Linkify from 'linkify-react';

import { styled } from '@boopos/design-system';

const StyledWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',
});

export const FormatUserInputText = ({
  text,
  linkify = true,
  wrapped = false,
}: {
  text: string | undefined;
  linkify?: boolean;
  wrapped?: boolean;
}) => {
  if (!text) {
    return null;
  }

  const FormattedText = text
    .split('\n')
    .filter((notEmpty) => !!notEmpty)
    .map((partial, n) => <div key={`${partial}${n}`}>{partial}</div>);

  const Content = linkify ? (
    <Linkify options={{ target: '_blank' }}>{FormattedText}</Linkify>
  ) : (
    <>{FormattedText}</>
  );

  if (wrapped) {
    return <StyledWrapper>{Content}</StyledWrapper>;
  }

  return Content;
};
