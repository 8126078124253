import {
  AvailableFundsForm,
  AvailableFundsFormLoading,
  AvailableFundsFormProps,
} from './AvailableFundsForm';
import { useProofOfFundsData } from './useProofOfFundsData';

export const AvailableFundsFormWithData = (
  props: Omit<AvailableFundsFormProps, 'defaultValues'>
) => {
  const proofOfFundsData = useProofOfFundsData();

  if (!proofOfFundsData?.defaultValues) {
    return <AvailableFundsFormLoading />;
  }

  return (
    <>
      <AvailableFundsForm
        defaultValues={proofOfFundsData.defaultValues}
        {...props}
      />
    </>
  );
};
