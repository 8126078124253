import { cloneElement } from 'react';
import {
  DropzoneInputProps,
  DropzoneRootProps,
  FileRejection,
  useDropzone,
} from 'react-dropzone';

import { ErrorFeedback } from './ErrorFeeback';
import { UploadButton } from './UploadButton';
import { IUploadZone } from './UploadZone.interface';
import { Dropzone } from './UploadZone.styled';
import { defaultAcceptedFileTypes, defaultMaxSizeMb } from './UploadZone.utils';
import { useUploadZone } from './useUploadZone';

export interface UploadZoneBaseProps {
  isDragActive: boolean;
  hasErrors: boolean;
  isUploading: boolean;
  externalError?: boolean;
  getRootProps: <T extends DropzoneRootProps>(props?: T) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
  errors: string[];
  multiple?: boolean;
  fileRejections: FileRejection[];
  handleRemoveError: () => void;
  open: () => void;
}

const UploadZoneBase = ({
  isDragActive,
  hasErrors,
  isUploading,
  externalError,
  getRootProps,
  getInputProps,
  errors,
  multiple,
  fileRejections,
  handleRemoveError,
  open,
}: UploadZoneBaseProps) => {
  return (
    <Dropzone
      data-testid="upload-zone"
      active={isDragActive}
      activeError={isDragActive && hasErrors}
      isUploading={isUploading}
      externalError={externalError}
      {...getRootProps()}
    >
      <input data-testid="drop-input" {...getInputProps()} />

      {hasErrors ? (
        <ErrorFeedback
          errors={errors}
          multiple={multiple}
          fileRejections={fileRejections}
          onRemoveError={handleRemoveError}
          onOpenUploadDialog={open}
        />
      ) : (
        <UploadButton
          isUploading={isUploading}
          multiple={multiple}
          hasErrors={hasErrors}
        />
      )}
    </Dropzone>
  );
};

export const UploadZone = ({
  maxSizeMb = defaultMaxSizeMb,
  accept = defaultAcceptedFileTypes,
  maxFiles = 1,
  multiple,
  uploadParams,
  onFilesUploaded,
  externalError,
  noDrag,
  noClick,
  children,
}: IUploadZone) => {
  const {
    acceptedFileTypes,
    maxSizeBytes,
    isUploading,
    errors,
    onDropFilesAccepted,
    onDropFilesRejected,
    onFileError,
    handleRemoveError,
  } = useUploadZone({
    accept,
    maxSizeMb,
    uploadParams,
    onFilesUploaded,
  });

  const hasErrors = !!errors.length;

  const { getRootProps, getInputProps, isDragActive, open, fileRejections } =
    useDropzone({
      onDropAccepted: onDropFilesAccepted,
      onDropRejected: onDropFilesRejected,
      onFileDialogOpen: handleRemoveError,
      onError: onFileError,
      noDrag,
      multiple,
      maxFiles,
      accept: acceptedFileTypes,
      maxSize: maxSizeBytes,
      noClick: hasErrors || noClick,
    });

  const passedProps = {
    isDragActive,
    hasErrors,
    isUploading,
    externalError,
    getRootProps,
    getInputProps,
    errors,
    multiple,
    fileRejections,
    handleRemoveError,
    open,
  };

  if (children) {
    return cloneElement(children, {
      ...children.props,
      ...passedProps,
    });
  }

  return <UploadZoneBase {...passedProps} />;
};
