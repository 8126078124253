import { PropsWithChildren } from 'react';

import { BooposAnimatedLogoHandler } from 'components/BooposAnimatedLogoHandler';

export const GuestRoutesProvider = ({ children }: PropsWithChildren<any>) => {
  return (
    <>
      <BooposAnimatedLogoHandler />
      {children}
    </>
  );
};
