import { useTranslation } from 'react-i18next';

import { RenderIfNotEmpty } from 'components/RenderIfNotEmpty';

import { FormatUserInputText } from 'lib/format';
import { ListingForSale } from 'lib/types';

import { BusinessAge } from './BusinessAge';
import { ListingDetailStyles } from './ListingDetail.styles';
import { ListingInfoItem } from './ListingInfoItem';
import { SellerTypeRender } from './SellerTypeRender';

export const BusinessOverview = ({ listing }: { listing: ListingForSale }) => {
  const { t } = useTranslation();

  return (
    <>
      <ListingDetailStyles.InfoGrid>
        <ListingInfoItem title={t('BusinessOverview:business_type')}>
          {listing.businessTypeName}
        </ListingInfoItem>
        <RenderIfNotEmpty value={listing.businessUrl}>
          <SellerTypeRender.Proprietary sellerType={listing?.sellerType}>
            <ListingInfoItem
              title={t('BusinessOverview:business_url')}
              blur={listing.businessUrl?.redacted}
            >
              <a
                href={listing.businessUrl?.value}
                target="_blank"
                rel="noreferrer"
              >
                {listing.businessUrl?.value}
              </a>
            </ListingInfoItem>
          </SellerTypeRender.Proprietary>
        </RenderIfNotEmpty>
        <SellerTypeRender.Broker sellerType={listing?.sellerType}>
          <ListingInfoItem
            title={t('BusinessOverview:broker_url')}
            blur={listing?.dealSource?.redacted}
          >
            <a
              href={listing.dealSource?.value?.url}
              target="_blank"
              rel="noreferrer"
            >
              {listing.dealSource?.value?.url}
            </a>
          </ListingInfoItem>
        </SellerTypeRender.Broker>
        <RenderIfNotEmpty value={listing.businessAge?.value}>
          <ListingInfoItem
            title={t('BusinessOverview:business_age')}
            blur={listing.businessAge?.redacted}
          >
            <BusinessAge businessAge={listing?.businessAge} />
          </ListingInfoItem>
        </RenderIfNotEmpty>

        <RenderIfNotEmpty value={listing.businessLocation?.value}>
          <ListingInfoItem
            title={t('BusinessOverview:business_location')}
            blur={listing.businessLocation?.redacted}
          >
            {listing.businessLocation?.value}
          </ListingInfoItem>
        </RenderIfNotEmpty>

        <RenderIfNotEmpty value={listing.teamSize?.value}>
          <ListingInfoItem
            title={t('BusinessOverview:team_size')}
            blur={listing.teamSize?.redacted}
          >
            {listing.teamSize?.value}
          </ListingInfoItem>
        </RenderIfNotEmpty>
        <RenderIfNotEmpty value={listing.activeCustomersNumber?.value}>
          <ListingInfoItem
            title={t('BusinessOverview:active_customers')}
            blur={listing.activeCustomersNumber?.redacted}
          >
            {listing.activeCustomersNumber?.value}
          </ListingInfoItem>
        </RenderIfNotEmpty>
      </ListingDetailStyles.InfoGrid>
      <ListingInfoItem
        title={t('BusinessOverview:description')}
        numeric={false}
        variant="body"
      >
        <FormatUserInputText wrapped text={listing.description} />
      </ListingInfoItem>
    </>
  );
};
