import { useCallback, useState } from 'react';
import useSWR from 'swr';

import { Merchants } from 'components/AccountConnectors';
import { useClosingStepMatchStatus } from 'components/DealClosingCheckList';

import Api from 'lib/api';
import {
  ClosingCheckListStepStatus,
  IBusinessAccount,
  IBusinessInvitation,
  ILoanApi,
} from 'lib/types';

import {
  IUseAccountConnectionModal,
  IUseAccountConnectionModalReturn,
  merchantTypeByClosingStep,
} from './AccountConnectionModal.interface';
import {
  filterByAccountType,
  filterConnectedByMe,
  filterConnectedBySeller,
} from './AccountConnectionModal.utils';

export const useAccountConnectionModal = ({
  loanId,
  accountType,
  excludedProvider = Merchants.bank,
}: IUseAccountConnectionModal): IUseAccountConnectionModalReturn => {
  const { data: loan, mutate } = useSWR<ILoanApi>(Api.getLoan(loanId));

  const [connectModalOpened, setConnectModalOpened] = useState(false);
  const [inviteModalOpened, setInviteModalOpened] = useState(false);

  const stepName = merchantTypeByClosingStep[accountType];

  const isVerified = useClosingStepMatchStatus({
    loan,
    status: ClosingCheckListStepStatus.verified,
    name: stepName,
  });

  const openConnectModal = useCallback(() => {
    setConnectModalOpened(true);
  }, []);

  const closeConnectModal = useCallback(() => {
    setConnectModalOpened(false);
  }, []);

  const openInviteModal = useCallback(() => {
    setInviteModalOpened(true);
  }, []);

  const closeInviteModal = useCallback(() => {
    setInviteModalOpened(false);
  }, []);

  const closeWatcher = useCallback(
    (onClose: () => void) => () => {
      if (connectModalOpened) {
        closeConnectModal();
        return;
      }
      if (inviteModalOpened) {
        closeInviteModal();
        return;
      }

      onClose();
    },
    [connectModalOpened, closeConnectModal, inviteModalOpened, closeInviteModal]
  );

  const onInvitationSent = useCallback(async () => {
    await mutate();
    closeInviteModal();
  }, [mutate, closeInviteModal]);

  const onAccountConnected = useCallback(async () => {
    await mutate();
    closeConnectModal();
  }, [mutate, closeConnectModal]);

  const onDeleteAccount = useCallback(async () => {
    await mutate();
  }, [mutate]);

  const businessInvitations = filterByAccountType<IBusinessInvitation>(
    accountType,
    loan?.businessInvitations
  );

  const selfConnectedAccounts = filterByAccountType<IBusinessAccount>(
    accountType,
    filterConnectedByMe(loan?.accounts),
    excludedProvider
  );

  const sellerConnectedAccounts = filterByAccountType<IBusinessAccount>(
    accountType,
    filterConnectedBySeller(loan?.accounts),
    excludedProvider
  );

  const hasInvitations = businessInvitations.length > 0;
  const hasConnectedAccounts =
    sellerConnectedAccounts.length > 0 || selfConnectedAccounts.length > 0;

  const isEmpty = !hasInvitations && !hasConnectedAccounts;

  return {
    ready: !!loan,
    businessId: loan?.business?.id,
    businessInvitations,

    hasInvitations,
    hasConnectedAccounts,
    isEmpty,

    selfConnectedAccounts,
    sellerConnectedAccounts,

    connectModalOpened,
    openConnectModal,
    closeConnectModal,

    inviteModalOpened,
    openInviteModal,
    closeInviteModal,

    onInvitationSent,
    onAccountConnected,
    onDeleteAccount,

    closeWatcher,
    isVerified,
  };
};
