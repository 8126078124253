import { useTranslation } from 'react-i18next';

import { TimelineItemNowProps } from 'components/DealTimeline/DealTimeline.interface';
import { stepAnimatedProps } from 'components/DealTimeline/StepAnimation';
import { DealTimelineStep } from 'components/DealTimelineStep';

import { ILoanApi, LoanStatus } from 'lib/types';

export const shouldShowDealEvaluating = (loan: ILoanApi) => {
  return [LoanStatus.applied, LoanStatus.analysis].includes(loan?.status);
};

export const DealEvaluating = ({ children, loan }: TimelineItemNowProps) => {
  const { t } = useTranslation();

  if (!shouldShowDealEvaluating(loan)) {
    return null;
  }

  return (
    <DealTimelineStep
      title={t('DealTimelineStep:deal_evaluating_title')}
      subtitle={t('DealTimelineStep:deal_evaluating_subtitle')}
      data-testid="deal-evaluating"
      {...stepAnimatedProps}
    >
      {children}
    </DealTimelineStep>
  );
};
