import { formatMoney } from 'lib/format';
import { Currency } from 'lib/types';

export const axisMoneyFormat = (value: number | string) => {
  const valueFormatted = formatMoney(
    {
      amount: Number(value),
      currency: Currency.USD,
    },
    { divider: 1 }
  );

  if (!valueFormatted) {
    return '';
  }

  return valueFormatted;
};
