import { useTranslation } from 'react-i18next';

import { Badge } from 'ui/Badge';

import { EnquiryStatus } from 'lib/types';

const BadgeByStatus = {
  [EnquiryStatus.started]: () => {
    const { t } = useTranslation();
    return <Badge variant="pink">{t('EnquiryStatus:started')}</Badge>;
  },
  [EnquiryStatus.in_conversations]: () => {
    const { t } = useTranslation();
    return <Badge>{t('EnquiryStatus:in_conversations')}</Badge>;
  },
  [EnquiryStatus.due_diligence]: () => {
    const { t } = useTranslation();
    return <Badge variant="yellow">{t('EnquiryStatus:due_diligence')}</Badge>;
  },
  [EnquiryStatus.closing]: () => {
    const { t } = useTranslation();
    return <Badge variant="blue">{t('EnquiryStatus:closing')}</Badge>;
  },
  [EnquiryStatus.sold]: () => {
    const { t } = useTranslation();
    return <Badge variant="green">{t('EnquiryStatus:sold')}</Badge>;
  },
  [EnquiryStatus.rejected]: () => {
    const { t } = useTranslation();
    return <Badge variant="red">{t('EnquiryStatus:rejected')}</Badge>;
  },
};

export const EnquiryStatusBadge = ({ status }: { status?: EnquiryStatus }) => {
  if (!status) return null;
  const BadgeStatus = BadgeByStatus[status] ?? (() => null);
  return <BadgeStatus />;
};
