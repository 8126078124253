import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { FullScreenLoader } from 'components/FullScreenLoader';

import {
  AuthForcedState,
  AuthParams,
  AuthParamsDefault,
} from 'lib/auth/authParams';
import { useCompanionMode } from 'lib/companion';

import { IProtectedRoute } from './ProtectedRoute.interface';

const OnRedirecting = () => (
  <FullScreenLoader data-testid="loading-redirecting" />
);

const useLoginOptions = () => {
  const [params] = useSearchParams();
  const location = useLocation() as { state: AuthForcedState };

  const screenHint =
    params?.get(AuthParams.authHint) ??
    location?.state?.authHint ??
    AuthParamsDefault.authHint;

  const userGoal =
    params?.get(AuthParams.userGoal) ??
    location?.state?.userGoal ??
    AuthParamsDefault.userGoal;

  const userEmail = params?.get(AuthParams.userEmail) ?? undefined;

  const loginOptions = useMemo(() => {
    return {
      authorizationParams: {
        screen_hint: screenHint,
        'ext-user-goal': userGoal,
        login_hint: userEmail,
      },
    };
  }, [screenHint, userGoal, userEmail]);

  return loginOptions;
};

export const ProtectedRoute = ({ component }: IProtectedRoute) => {
  const { isCompanionMode } = useCompanionMode();

  const loginOptions = useLoginOptions();

  const Component = useMemo(
    () =>
      withAuthenticationRequired(component, {
        onRedirecting: OnRedirecting,
        loginOptions,
      }),
    [component, loginOptions]
  );

  const CompanionComponent = component;

  if (isCompanionMode) {
    return <CompanionComponent />;
  }

  return <Component />;
};
