import { JSX } from 'react';

import { InsightsLineChart, TooltipRenderProps } from 'ui/InsightsLineChart';
import { defaultChartColors } from 'ui/InsightsShared';

import { ChangeUnit, YearlyGrowthDataEntry } from './InsightsCharts.interface';
import { axisDateFormat } from './utils/axisDateFormat';
import { axisMoneyFormat } from './utils/axisMoneyFormat';

const xAxisDataKey = (entry: YearlyGrowthDataEntry) => {
  return entry.rangeStartDate;
};

const oldestValuesLineDataKey = (entry: YearlyGrowthDataEntry) => {
  return entry.rangeStartValue;
};

const newestValuesLineDataKey = (entry: YearlyGrowthDataEntry) => {
  return entry.rangeEndValue;
};

const yAxisTickFormatterObject = {
  money: axisMoneyFormat,
  percentage: (value: number) => `${value}%`,
};

export const YearlyGrowthComparisonChart = ({
  data,
  renderTooltip,
  unit,
}: {
  data: YearlyGrowthDataEntry[];
  renderTooltip?: (props: TooltipRenderProps) => JSX.Element;
  unit?: ChangeUnit;
}) => {
  const yAxisTickFormatter = unit
    ? yAxisTickFormatterObject[unit]
    : axisMoneyFormat;

  return (
    <InsightsLineChart<YearlyGrowthDataEntry>
      xAxisDataKey={xAxisDataKey}
      xAxisTickFormatter={axisDateFormat}
      yAxisTickFormatter={yAxisTickFormatter}
      oldestValuesLineDataKey={oldestValuesLineDataKey}
      newestValuesLineDataKey={newestValuesLineDataKey}
      chartColors={defaultChartColors}
      renderTooltip={renderTooltip}
      data={data}
    />
  );
};
