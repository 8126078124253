import { useTranslation } from 'react-i18next';

import { Text } from '@boopos/design-system';

import { formatMoney } from 'lib/format';
import { MoneyFormat } from 'lib/format/money/formatMoney.interface';
import { Money } from 'lib/types';

import { IDealStructurePreview } from './DealStructurePreview.interface';
import {
  SummaryItem,
  SummarySection,
  TextMoney,
} from './DealStructurePreview.styles';

export const DealStructurePreview = ({
  previewData,
  enable,
}: IDealStructurePreview) => {
  const { t } = useTranslation();
  const { totalBusinessPrice, requestedAmount, downPayment } = previewData;

  if (!totalBusinessPrice) return null;

  return (
    <SummarySection enable={enable} data-testid="deal-structure-summary">
      <SummaryItem>
        <Text>{t('DealStructureForm:total_price_label')}</Text>
        <TextMoney>
          {formatMoney(totalBusinessPrice, {
            format: MoneyFormat.long,
          })}
        </TextMoney>
      </SummaryItem>
      <SummaryItem>
        <Text>{t('DealStructureForm:loan_amount_label')}</Text>
        <TextMoney>
          {formatMoney(requestedAmount as Money, {
            format: MoneyFormat.long,
          })}
        </TextMoney>
      </SummaryItem>
      <SummaryItem>
        <Text>{t('DealStructureForm:cash_equity_label')}</Text>
        <TextMoney bold>
          {formatMoney(downPayment as Money, {
            format: MoneyFormat.long,
          })}
        </TextMoney>
      </SummaryItem>
    </SummarySection>
  );
};
