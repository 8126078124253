import { atom, useAtom } from 'jotai';
import { PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import useSWR from 'swr';

import { FullScreenLoader } from 'components/FullScreenLoader';

import { useAnalytics } from 'lib/analytics';
import api, { apiV2 } from 'lib/api';
import { useAuth } from 'lib/auth';
import { IUser } from 'lib/types';

import { UserContext } from './UserContext';

const identifiedAtom = atom(false);

export const UserProvider = ({
  children,
  timeOutForIdentify = 1000,
}: PropsWithChildren<any>) => {
  const [identified, setIdentified] = useAtom(identifiedAtom);
  const { isAuthenticated } = useAuth();
  const { data: user, mutate } = useSWR(isAuthenticated && apiV2.getMe());

  const { identify } = useAnalytics();

  const updateUser = useCallback(
    async (data: Partial<IUser>, config?: { v2?: boolean }) => {
      await (config?.v2 ? apiV2.updateMe(data) : api.updateMe(data));
      await mutate();
    },
    [mutate]
  );

  const value = useMemo(
    () => ({
      user,
      isAuthenticated,
      updateUser,
      mutate,
    }),
    [user, isAuthenticated, updateUser, mutate]
  );

  useEffect(() => {
    if (!isAuthenticated || !user) {
      return;
    }

    /*
    * Some browser extensions like Ghostery any others anonymize extensions
    * are blocking the "identify" call. The execution cannot be controlled
    * by a try-catch statement, as it breaks outside the execution context of
    * the application.

    * That's why we use a Promise.any function, allowing us to wait 1s for the
    * identify call to be executed.
    * */
    const identifyOrTimeOut = [
      identify(user),
      new Promise((resolve) => setTimeout(resolve, timeOutForIdentify)),
    ];

    (async () => {
      await Promise.any(identifyOrTimeOut);
      setIdentified(true);
    })();
  }, [isAuthenticated, user, identify, timeOutForIdentify, setIdentified]);

  if (!isAuthenticated || (isAuthenticated && !user) || !identified) {
    return <FullScreenLoader data-testid="loading" />;
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
