import { ErrorBoundary } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath, useRouteError } from 'react-router-dom';

import { Button } from '@boopos/design-system';

import ErrorImage from 'assets/illustrations/canceled.svg';

import { routePaths } from 'router/routePaths';

import { Styles } from './ErrorElementScreen.styles';

const ErrorThrower = () => {
  throw useRouteError();
};

export const ErrorElementScreenFallback = () => {
  const { t } = useTranslation();

  return (
    <Styles.Wrapper data-testid="error-element-screen">
      <div>
        <img src={ErrorImage} alt={t('ErrorElementScreen:title')} />
      </div>
      <Styles.Title>{t('ErrorElementScreen:title')}</Styles.Title>

      <Styles.Subtitle>{t('ErrorElementScreen:description')}</Styles.Subtitle>

      <Button as={Link} to={generatePath(routePaths.base)}>
        {t('ErrorElementScreen:reload_page')}
      </Button>
    </Styles.Wrapper>
  );
};

export const ErrorElementScreen = () => {
  return (
    <ErrorBoundary fallback={<ErrorElementScreenFallback />}>
      <ErrorThrower />
    </ErrorBoundary>
  );
};
