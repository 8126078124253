import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { createToastFeedback } from '@boopos/design-system';

import { usePersistenceAtom } from 'components/ClosingChecklistPersistence';
import { useClosingStepMatchStatus } from 'components/DealClosingCheckList';

import Api, { parseApiError } from 'lib/api';
import {
  ClosingCheckListStepStatus,
  ClosingStepsNames,
  ILoanApi,
} from 'lib/types';

import { acquiringLegalEntityAtom } from './AcquiringLegalEntity.atoms';
import { defaultFormValues } from './AcquiringLegalEntity.constants';
import {
  IAcquiringLegalEntityData,
  IUseAcquiringLegalEntity,
  IUseAcquiringLegalEntityReturn,
} from './AcquiringLegalEntity.interface';
import { parseFromApi, parseToApi } from './AcquiringLegalEntity.utils';
import { useApiErrorsLiterals } from './useApiErrorsLiterals';

export const useAcquiringLegalEntity = ({
  loanId,
  onSuccess,
}: IUseAcquiringLegalEntity): IUseAcquiringLegalEntityReturn => {
  const { t } = useTranslation();
  const { data: loan, mutate: refreshLoan } = useSWR<ILoanApi>(
    Api.getLoan(loanId)
  );

  const apiErrors = useApiErrorsLiterals();

  const [success, setSuccess] = useState(false);
  const isWaitingForReview = useClosingStepMatchStatus({
    loan,
    status: ClosingCheckListStepStatus.waiting,
    name: ClosingStepsNames.legalEntity,
  });

  const isVerified = useClosingStepMatchStatus({
    loan,
    status: ClosingCheckListStepStatus.verified,
    name: ClosingStepsNames.legalEntity,
  });

  const acquiringLegalEntity = useMemo(
    () => loan?.acquiringLegalEntity,
    [loan]
  );

  const {
    value: legalEntity,
    setValue: setLegalEntity,
    reset: resetLegalEntity,
  } = usePersistenceAtom<IAcquiringLegalEntityData>(
    acquiringLegalEntityAtom,
    loanId
  );

  const defaultValues = loan?.acquiringLegalEntity
    ? parseFromApi(loan.acquiringLegalEntity)
    : legalEntity || defaultFormValues;

  const formMethods = useForm<IAcquiringLegalEntityData>({
    defaultValues,
  });

  const handleOnChange = useCallback(
    (data: IAcquiringLegalEntityData) => {
      if (acquiringLegalEntity) return;
      setLegalEntity(data);
    },
    [setLegalEntity, acquiringLegalEntity]
  );

  const { watch, formState, setError } = formMethods;

  const handleOnDelete = useCallback(async () => {
    try {
      await Api.deleteLegalEntity({ loanId });
      if (acquiringLegalEntity) {
        setLegalEntity(parseFromApi(acquiringLegalEntity));
      }
      setTimeout(() => {
        refreshLoan();
      }, 1000);
    } catch (err) {
      createToastFeedback({
        title: t('DealClosingCheckList:unexpected_error_canceling_review'),
        type: 'error',
      });
    }
  }, [loanId, refreshLoan, acquiringLegalEntity, setLegalEntity, t]);

  const triggerUnexpectedError = useCallback(() => {
    createToastFeedback({
      type: 'error',
      title: t('DealClosingCheckList:unexpected_error_sending_review'),
    });
  }, [t]);

  const handleSubmitError = useCallback(
    (err: any) => {
      const [parsedError] = parseApiError(err);
      if (!parsedError) {
        triggerUnexpectedError();
        return;
      }

      const errorLiteral = (apiErrors as any)?.[parsedError.name]?.[
        parsedError.error
      ];

      if (!errorLiteral) {
        triggerUnexpectedError();
        return;
      }

      setError(parsedError.name as any, {
        message: errorLiteral,
      });
    },
    [apiErrors, setError, triggerUnexpectedError]
  );

  const onSubmit = useCallback(
    async (data: IAcquiringLegalEntityData) => {
      try {
        await Api.updateLegalEntity({ loanId }, parseToApi(data));
        resetLegalEntity();
        setSuccess(true);
        setTimeout(() => {
          refreshLoan();
          onSuccess?.();
        }, 1000);
      } catch (err: any) {
        handleSubmitError(err);
      }
    },
    [loanId, onSuccess, resetLegalEntity, refreshLoan, handleSubmitError]
  );

  const isSubmitting = formState?.isSubmitting;

  useEffect(() => {
    const subscription = watch((value) =>
      handleOnChange(value as IAcquiringLegalEntityData)
    );
    return () => subscription.unsubscribe();
  }, [handleOnChange, watch]);

  return {
    formMethods,
    onSubmit,
    onDelete: handleOnDelete,
    isSubmitting,
    isSuccess: success,
    isWaitingForReview,
    isVerified,
    isReady: !!loan,
  };
};
