import { useTranslation } from 'react-i18next';

import { Box } from '@boopos/design-system';
import { formatDate } from '@boopos/utils';

import { BuyerInsightsBuyBanner } from 'components/BuyerInsights/BuyerInsightsBuyBanner';
import { useBuyerInsightsData } from 'components/BuyerInsights/useBuyerInsightsData';
import { InsightsCardsWithCharts } from 'components/InsightsCharts/InsightsCardsWithCharts';

import { apiV2 } from 'lib/api';
import { IBusinessInsights } from 'lib/types';

import { ScreenLayout } from 'screens/BusinessValuationScreen/BusinessValuationScreen.styles';

export const BuyerBusinessInsights = ({
  businessInsights,
  businessName,
  onUnlock,
  ...rest
}: {
  businessInsights?: IBusinessInsights;
  businessName?: string;
  onUnlock?: () => void;
}) => {
  const { t } = useTranslation();

  const { locked, price, businessType, benchmarkScores, analysisDate } =
    useBuyerInsightsData({
      businessInsights,
      businessId: businessInsights?.businessId,
    });

  return (
    <Box css={{ mt: '3rem' }} {...rest}>
      {locked && (
        <BuyerInsightsBuyBanner
          longDescription
          price={price}
          businessInsightsId={businessInsights?.id}
          businessId={businessInsights?.businessId}
          onUnlock={onUnlock}
          css={{ mb: '$6' }}
        />
      )}

      {!locked && !!analysisDate && (
        <ScreenLayout.Date
          css={{ textAlign: 'right' }}
          data-testid="buyer-business-insights-date"
        >
          {analysisDate &&
            t('BusinessValuation:underwriting_date', {
              analysisDate: formatDate(analysisDate, 'MMMM YYYY'),
            })}
        </ScreenLayout.Date>
      )}

      <InsightsCardsWithCharts
        scores={benchmarkScores}
        businessType={businessType}
        businessName={businessName}
        chartsService={apiV2.businessInsightsChartsForBuyer(
          businessInsights?.businessId ?? ''
        )}
        cohortsService={apiV2.businessCohortsChartsForBuyer(
          businessInsights?.businessId ?? ''
        )}
        blur={locked}
      />
    </Box>
  );
};
