import { useTranslation } from 'react-i18next';

import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { StaticTooltip } from 'ui/Tooltip';

import { ListingUnlockAction } from 'components/ListingUnlock';
import { RenderIfNotEmpty } from 'components/RenderIfNotEmpty';
import { TermSheetHandler } from 'components/TermSheetHandler';

import { FormatUserInputText } from 'lib/format';
import { ListingForSale } from 'lib/types';

import { BusinessOverview } from './BusinessOverview';
import { ConfidentialNotice } from './ConfidentialNotice';
import { DealSource } from './DealSource';
import { FinancialDocuments } from './FinancialDocuments';
import { InfoMemoDescription, InfoMemoPreview } from './InfoMemoPreview';
import { ListingDetailStyles } from './ListingDetail.styles';
import { ListingFinancialData } from './ListingFinancialData';
import { ListingInfoContent } from './ListingInfoContent';
import { TermSheetDetails } from './TermSheetDetails';

export interface ListingDetailProps {
  listing?: ListingForSale;
}

export const ListingDetail = ({ listing }: ListingDetailProps) => {
  const { t } = useTranslation();

  const listingInfoContentAction = listing?.redacted ? (
    <ListingUnlockAction requiresAgreement={listing?.requiresAgreement} />
  ) : null;

  if (!listing) {
    return null;
  }

  return (
    <ListingDetailStyles.Wrapper>
      <StaticTooltip id="listing-detail-tooltip" />
      <ListingInfoContent
        icon={regular('chart-mixed-up-circle-dollar')}
        title={t('ListingDetail:financials_title')}
      >
        <ListingFinancialData listing={listing} />
      </ListingInfoContent>
      <ListingInfoContent
        icon={regular('briefcase')}
        title={t('ListingDetail:business_overview_title')}
        childrenAction={listingInfoContentAction}
      >
        <BusinessOverview listing={listing} />
      </ListingInfoContent>
      <TermSheetHandler termSheet={listing.termSheet?.value}>
        <TermSheetHandler.Base>
          <ListingInfoContent
            icon={solid('bank')}
            title={t('ListingDetail:financing_title')}
            childrenAction={listingInfoContentAction}
          >
            <TermSheetDetails
              listing={listing}
              blur={listing?.termSheet?.redacted}
              wrap
            />
          </ListingInfoContent>
        </TermSheetHandler.Base>
      </TermSheetHandler>
      <RenderIfNotEmpty value={listing.sellingReason}>
        <ListingInfoContent
          icon={regular('message-dollar')}
          title={t('ListingDetail:selling_reason_title')}
        >
          <FormatUserInputText text={listing.sellingReason} />
        </ListingInfoContent>
      </RenderIfNotEmpty>
      <InfoMemoPreview listing={listing}>
        {(children) => (
          <ListingInfoContent
            icon={solid('file-shield')}
            title={t('ListingDetail:information_memorandum_title')}
          >
            {children}
            <InfoMemoDescription />
          </ListingInfoContent>
        )}
      </InfoMemoPreview>
      <FinancialDocuments listing={listing}>
        {(children) => (
          <ListingInfoContent
            icon={regular('paperclip')}
            title={t('ListingDetail:financial_documents_title')}
            childrenAction={listingInfoContentAction}
          >
            {children}
          </ListingInfoContent>
        )}
      </FinancialDocuments>
      <ListingInfoContent
        icon={solid('user')}
        title={t('ListingDetail:deal_source_title')}
      >
        <DealSource listing={listing} />
      </ListingInfoContent>

      <ConfidentialNotice listing={listing} />
    </ListingDetailStyles.Wrapper>
  );
};
