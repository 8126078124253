import { useTranslation } from 'react-i18next';

import { Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ProviderImage } from 'components/AccountConnectors';
import { MerchantInitiatorComponent } from 'components/AccountConnectors/AccountConnectors.constants';

import { MerchantObject } from './BusinessConnections.types';
import { ItemStyles } from './Styles';

export const BusinessConnectionCard = ({
  merchant,
  businessId,
  onSuccess,
}: {
  merchant: MerchantObject;
  businessId: string;
  onSuccess: () => void;
}) => {
  const { t } = useTranslation();

  const Component = MerchantInitiatorComponent[merchant.value];

  return (
    <ItemStyles.Card>
      <ItemStyles.Icon>
        <ProviderImage provider={merchant.value} imageType="rounded" />
      </ItemStyles.Icon>
      <ItemStyles.Name>
        {merchant.label}
        <ItemStyles.Subtitle>{merchant.subtitle}</ItemStyles.Subtitle>
      </ItemStyles.Name>

      {!!Component && (
        <Component
          provider={merchant.value}
          onSuccess={onSuccess}
          businessId={businessId}
          render={(props) => {
            return (
              <Button
                variant="secondary"
                leftIcon={<FontAwesomeIcon icon={regular('plug')} />}
                size="reduced"
                {...props}
              >
                {t('PaymentProcessorCard:connect')}
              </Button>
            );
          }}
        />
      )}
    </ItemStyles.Card>
  );
};
