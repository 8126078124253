import { Money } from 'lib/types';

function round(value: number) {
  return Math.round((value + Number.EPSILON) * 100) / 100;
}

export const parseMoneyToApi = (
  money: Money,
  multiplier: number = 100
): Money => ({
  amount: round(money.amount * multiplier),
  currency: money.currency,
});
