import { useTranslation } from 'react-i18next';

import { Badge } from 'ui/Badge';

import { QualificationStatusApi } from './QualificationStatus.interface';
import { useQualificationStatus } from './hooks';

export const QualificationStatusBadge = ({
  status,
}: {
  status: string | undefined;
}) => {
  const { t } = useTranslation();

  const badgeByStatus: any = {
    [QualificationStatusApi.EMPTY]: (
      <Badge variant="yellow" data-testid="badge-empty">
        {t('QualificationStatusBadge:empty')}
      </Badge>
    ),
    [QualificationStatusApi.UNDER_REVIEW]: (
      <Badge variant="yellow" data-testid="badge-under-review">
        {t('QualificationStatusBadge:in_review')}
      </Badge>
    ),
    [QualificationStatusApi.MISSING_INFO]: (
      <Badge variant="yellow" data-testid="badge-missing-info">
        {t('QualificationStatusBadge:more_info_needed')}
      </Badge>
    ),
    [QualificationStatusApi.QUALIFIED]: (
      <Badge variant="green" data-testid="badge-qualified">
        {t('QualificationStatusBadge:qualified')}
      </Badge>
    ),
    [QualificationStatusApi.REJECTED]: (
      <Badge variant="red" data-testid="badge-rejected">
        {t('QualificationStatusBadge:rejected')}
      </Badge>
    ),
  };

  if (!status || !badgeByStatus[status]) {
    return null;
  }

  return badgeByStatus[status];
};

const QualificationStatusAccountBadgeQualified = ({
  hasItemsUnderReview,
}: {
  hasItemsUnderReview?: boolean;
}) => {
  const { t } = useTranslation();

  if (hasItemsUnderReview) {
    return (
      <Badge variant="yellow" data-testid="account-badge-qualified-pending">
        {t('QualificationStatusBadge:in_review')}
      </Badge>
    );
  }

  return (
    <Badge variant="green" data-testid="account-badge-qualified">
      {t('QualificationStatusBadge:qualified')}
    </Badge>
  );
};

export const QualificationStatusAccountBadge = () => {
  const { t } = useTranslation();
  const { status, hasItemsUnderReview } = useQualificationStatus();

  const badgeByStatus: any = {
    [QualificationStatusApi.EMPTY]: (
      <Badge variant="yellow" data-testid="account-badge-empty">
        {t('QualificationStatusBadge:empty')}
      </Badge>
    ),
    [QualificationStatusApi.UNDER_REVIEW]: (
      <Badge variant="yellow" data-testid="account-badge-under-review">
        {t('QualificationStatusBadge:in_review')}
      </Badge>
    ),
    [QualificationStatusApi.MISSING_INFO]: (
      <Badge variant="yellow" data-testid="account-badge-missing-info">
        {t('QualificationStatusBadge:more_info_needed')}
      </Badge>
    ),
    [QualificationStatusApi.QUALIFIED]: (
      <QualificationStatusAccountBadgeQualified
        hasItemsUnderReview={hasItemsUnderReview}
      />
    ),
    [QualificationStatusApi.REJECTED]: (
      <Badge variant="red" data-testid="account-badge-rejected">
        {t('QualificationStatusBadge:not_qualified')}
      </Badge>
    ),
  };

  if (!status || !badgeByStatus[status]) {
    return null;
  }

  return badgeByStatus[status];
};
