import { theme } from '@boopos/design-system';

export const useCohortsCellColor = (
  valuesArray?: (string | number | undefined | null)[]
) => {
  const numberValuesArray =
    valuesArray?.filter((val) => val !== null)?.map((val) => Number(val)) ?? [];

  const minValue = Math.min(...numberValuesArray);
  const maxValue = Math.max(...numberValuesArray);
  const range = maxValue - minValue;

  const getColor = (value: string | number | undefined | null) => {
    const numberValue = Number(value);

    if (value === '' || value === null) {
      return 'white';
    }

    if (!valuesArray?.length) {
      return 'white';
    }

    if (range === 0 || !numberValuesArray.length) {
      return 'white';
    }

    const normalizedValue = (numberValue - minValue) / range;

    if (normalizedValue >= 0.75) {
      return theme.colors.bl500.computedValue;
    }

    if (normalizedValue >= 0.5) {
      return theme.colors.bl400.computedValue;
    }

    if (normalizedValue >= 0.25) {
      return theme.colors.bl300.computedValue;
    }

    return theme.colors.bl100.computedValue;
  };

  return { getColor };
};
