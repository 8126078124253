import { KeyboardEvent, useCallback } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useController } from 'react-hook-form';

import { Input } from '@boopos/design-system';

import config from 'lib/config';

import { AddressAutocompleteProps } from './AddressAutocomplete.interface';
import { parsePlaceToAddress } from './AddressAutocomplete.utils';

const defaultOptions = {
  componentRestrictions: { country: 'us' },
  types: ['address'],
  strictBounds: false,
};

export const AddressAutocomplete = ({
  label,
  rules,
  control,
  name,
  options = defaultOptions,
  onPlaceSelected,
  ...props
}: AddressAutocompleteProps) => {
  const handlePlaceSelected = useCallback(
    (place: google.maps.places.PlaceResult) => {
      const address = parsePlaceToAddress(place);
      if (!address) return;
      onPlaceSelected(address);
    },
    [onPlaceSelected]
  );

  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: config.GOOGLE_MAPS_API_KEY,
    onPlaceSelected: handlePlaceSelected,
    options,
  });

  const preventSubmit = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }, []);

  const { field } = useController({
    name,
    control,
    rules,
  });

  return (
    <Input
      autoComplete="off"
      label={label}
      {...field}
      {...props}
      onKeyDown={preventSubmit}
      onKeyUp={preventSubmit}
      ref={ref}
    />
  );
};
