import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Loader } from 'components/FullScreenLoader';
import { HelpText } from 'components/UploadZone/UploadZone.styled';

interface IUploadButton {
  isUploading?: boolean;
  multiple?: boolean;
  hasErrors?: boolean;
}

export const UploadButton = ({
  multiple = false,
  hasErrors,
  isUploading,
}: IUploadButton) => {
  const { t } = useTranslation();

  if (isUploading) {
    return (
      <>
        <Loader fileUpload data-testid="uploading-file-loader" />
        <HelpText error={hasErrors}>
          {t('FileUploader:uploading_message', { count: +!multiple })}
        </HelpText>
      </>
    );
  }

  return (
    <>
      <Button
        data-testid="upload-button"
        variant="secondary"
        disabled={isUploading}
        leftIcon={<FontAwesomeIcon icon={regular('cloud-arrow-up')} />}
        type="button"
      >
        {t('FileUploader:upload_action', { count: +!multiple })}
      </Button>
      <HelpText error={hasErrors}>
        {t('FileUploader:drop_option_message', { count: +!multiple })}
      </HelpText>
    </>
  );
};
