import React, { ReactNode } from 'react';

export const transitionConfig = {
  type: 'tween',
  ease: 'easeOut',
  duration: 0.15,
};

export const getChildProps = (child: ReactNode) =>
  React.isValidElement(child) && child.props;
