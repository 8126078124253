import { AnimatePresence, motion } from 'framer-motion';
import { PropsWithChildren, useCallback } from 'react';

import { styled } from '@boopos/design-system';

const ModalOverSideModalStyles = {
  Wrapper: styled('div', {
    zIndex: 100,
    position: 'absolute',
    inset: 0,
    display: 'flex',
    alignItems: 'flex-end',
  }),
  Backdrop: styled(motion.div, {
    position: 'absolute',
    inset: 0,
    backgroundColor: '$slate900',
    opacity: 0.4,
  }),
  Content: styled(motion.div, {
    width: '100%',
    p: '2.4rem',
    pb: '4.8rem',
    backgroundColor: '$white',
    position: 'relative',
    zIndex: 101,
  }),
  Title: styled('h2', {
    fontSize: '3.2rem',
    lineHeight: '4.2rem',
    mb: '4rem',
  }),
  Subtitle: styled('h3', {
    fontSize: '1.8rem',
    lineHeight: '2.4rem',
    mb: '2.4rem',
  }),
  Description: styled('div', {
    color: '$slate500',
    mb: '4rem',
  }),
  CTAContainer: styled('div', {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.6rem',
  }),
};

interface Props {
  visible: boolean;
  onClose?: () => void;
}

const ModalOverSideModalParent = (props: PropsWithChildren<Props>) => {
  const { visible, children, onClose, ...rest } = props;

  const onBackdropClick = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <AnimatePresence>
      {visible && (
        <ModalOverSideModalStyles.Wrapper {...rest}>
          <ModalOverSideModalStyles.Backdrop
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            onClick={onBackdropClick}
          />
          <ModalOverSideModalStyles.Content
            initial={{ translateY: '100%' }}
            animate={{ translateY: 0 }}
            exit={{ translateY: '100%' }}
          >
            {children}
          </ModalOverSideModalStyles.Content>
        </ModalOverSideModalStyles.Wrapper>
      )}
    </AnimatePresence>
  );
};

export const ModalOverSideModal = Object.assign(ModalOverSideModalParent, {
  Title: ModalOverSideModalStyles.Title,
  Subtitle: ModalOverSideModalStyles.Subtitle,
  Description: ModalOverSideModalStyles.Description,
  CTAContainer: ModalOverSideModalStyles.CTAContainer,
});
