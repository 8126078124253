import { styled } from '@boopos/design-system';

export const EnableDisableContent = styled('div', {
  opacity: 0.4,
  pointerEvents: 'none',
  transition: 'opacity 0.4s ease-out',

  variants: {
    enable: {
      true: {
        opacity: 1,
        pointerEvents: 'all',
      },
    },
  },
});

export const SellerFinancingSection = styled(EnableDisableContent, {
  mt: '$4',

  'input[name="sellerFinancing"]': {
    fontWeight: '$extraBold',
  },
});

export const RequestedAmountSection = styled(EnableDisableContent, {
  mt: '$9',

  'input[name="requestedAmount"]': {
    fontWeight: '$extraBold',
  },
});

export const RequestedAmountHeading = styled('h4', {
  mb: '$4',
  fontSize: '$4',
});

export const RangeSliderContent = styled('div', {
  mt: '-1.5rem',
});
