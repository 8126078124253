import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

import { getLocaleFile, missingKeyHandler } from './i18n.utils';

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-chained-backend
  .use(ChainedBackend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    keySeparator: ':',
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    lng: 'en', // hardcoded lang to avoid loading more translation files. Remove this when other languages exist
    fallbackLng: 'en',
    initImmediate: false,
    react: {
      useSuspense: true,
    },
    saveMissing: true,
    missingKeyHandler,
    backend: {
      backends: [LocalStorageBackend, resourcesToBackend(getLocaleFile)],
      backendOptions: [
        {
          defaultVersion: Date.now(), // generate a new version every build to refresh
        },
      ],
    },
  });

export default i18n;
