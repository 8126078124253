import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useConfidentialityAgreementBanner } from 'components/ConfidentialityAgreementBanner';

import { useListingUnlockModal } from './useListingUnlockModal';

export const useListingUnlockAction = (requiresAgreement?: boolean) => {
  const { open } = useListingUnlockModal();

  const { showConfidentialityAgreementBanner, path, state } =
    useConfidentialityAgreementBanner({ requiresAgreement });

  const navigate = useNavigate();

  const handleOpen = useCallback(() => {
    if (showConfidentialityAgreementBanner && path) {
      navigate(path, { state });
      return;
    }
    open();
  }, [open, path, state, showConfidentialityAgreementBanner, navigate]);

  return { open: handleOpen };
};
