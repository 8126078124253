import { styled } from '@boopos/design-system';

const Wrapper = styled('div', {
  borderBottom: '.1rem solid $slate200',
  overflowX: 'auto',

  '&::-webkit-scrollbar': {
    height: '4px',
  },

  '&::-webkit-scrollbar-track': {
    background: '$white',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '$slate200',
  },

  '@bp2': {
    overflowX: 'hidden',
  },
});

const Link = styled('a', {
  px: '$4',
  py: '$3',
  color: '$slate500',
  cursor: 'pointer',
  fontWeight: 500,
  textDecoration: 'none',
  borderBottom: '.2rem solid transparent',
  transition: 'all 0.3s',

  '&.active, &:hover': {
    color: '$black',
  },

  '&.active': {
    borderBottomColor: '$black',
  },

  '&:hover': {
    borderRadius: '.8rem .8rem 0 0',
    backgroundColor: '$slate100',
  },
});

const List = styled('div', {
  display: 'flex',
  listStyleType: 'none',
  whiteSpace: 'nowrap',
});

export const TabMenuStyles = {
  Wrapper,
  List,
  Link,
};
