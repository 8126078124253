import { styled } from '@boopos/design-system';

export const HeatMapTableStyles = {
  Wrapper: styled('div', {
    overflowX: 'auto',
  }),
  Table: styled('table', {
    width: '100%',
    borderCollapse: 'collapse',
    'th, td': {
      fontSize: '1.4rem',
      lineHeight: '2.4rem',
      padding: '.8rem .8rem',
      textAlign: 'center',
    },
  }),
  THead: styled('thead', {
    'tr th': {
      color: '$textBodyPlaceholder',
      fontWeight: 400,
    },
  }),
  TBody: styled('tbody', {
    'tr td:first-child': {
      textAlign: 'left',
      whiteSpace: 'nowrap',
      color: '$textBodyPlaceholder',
      position: 'sticky',
      left: 0,
      background: '#fff',
    },
  }),
  Tr: styled('tr', {}),
  Th: styled('th', {
    whiteSpace: 'nowrap',
  }),
  Td: styled('td', {}),
};
