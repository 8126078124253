import { ClosingChecklist, GenericData } from 'lib/types';

import { ClosingChecklistParsed } from './DealClosingCheckList.interface';

export const parseClosingChecklist = (
  closingChecklist?: GenericData<ClosingChecklist[]>
): ClosingChecklistParsed => {
  if (!closingChecklist) return {};

  return closingChecklist.data.reduce(
    (acc, item) => ({
      ...acc,
      [item.name]: { ...item },
    }),
    {}
  );
};
