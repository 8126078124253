import {
  TotalChange,
  YearlyGrowthDataEntry,
} from 'components/InsightsCharts/InsightsCharts.interface';

import { getAverage } from './getAverage';
import { getPercentageChange } from './getPercentageChange';

const getValues = (
  range: YearlyGrowthDataEntry[],
  key: keyof YearlyGrowthDataEntry
) => {
  return range.map((item) => item[key]);
};

const sumValues = (values: (number | string)[]) => {
  return values.reduce((acc, val) => Number(acc) + Number(val), 0) as number;
};

export const calculateTotalChange = (
  range: YearlyGrowthDataEntry[]
): TotalChange => {
  const [first] = range ?? [];
  const last = range[range.length - 1];

  const startValues = getValues(range, 'rangeStartValue');
  const endValues = getValues(range, 'rangeEndValue');

  const startSum = sumValues(startValues);
  const endSum = sumValues(endValues);

  const startAverage = getAverage(startSum, startValues);
  const endAverage = getAverage(endSum, endValues);

  const averageChange = getAverage(endSum - startSum, startValues);
  const absoluteChange = endSum - startSum;
  const percentageChange = getPercentageChange(absoluteChange, startSum);

  return {
    startFrom: first?.rangeStartDate,
    startTo: last?.rangeStartDate,
    startSum,

    endFrom: first?.rangeEndDate,
    endTo: last?.rangeEndDate,
    endSum,

    absoluteChange,
    percentageChange,

    startAverage,
    endAverage,
    averageChange,
  };
};
