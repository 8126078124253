import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { createToastFeedback } from '@boopos/design-system';

import { usePersistenceAtom } from 'components/ClosingChecklistPersistence';
import { useClosingStepMatchStatus } from 'components/DealClosingCheckList';

import Api from 'lib/api';
import {
  ClosingCheckListStepStatus,
  ClosingStepsNames,
  ILoanApi,
} from 'lib/types';

import { fundsBankAccountAtom } from './FundsBankAccount.atoms';
import {
  IFundsBankAccountData,
  IUseFundsBankAccount,
  IUseFundsBankAccountReturn,
  defaultFormValues,
} from './FundsBankAccount.interface';

export const useFundsBankAccount = ({
  loanId,
  onSuccess,
}: IUseFundsBankAccount): IUseFundsBankAccountReturn => {
  const { t } = useTranslation();
  const { data: loan, mutate: refreshLoan } = useSWR<ILoanApi>(
    Api.getLoan(loanId)
  );

  const [success, setSuccess] = useState(false);
  const isWaitingForReview = useClosingStepMatchStatus({
    loan,
    status: ClosingCheckListStepStatus.waiting,
    name: ClosingStepsNames.fundsBankAccount,
  });

  const isVerified = useClosingStepMatchStatus({
    loan,
    status: ClosingCheckListStepStatus.verified,
    name: ClosingStepsNames.fundsBankAccount,
  });

  const apiFundsBankAccount = useMemo(() => loan?.fundsBankAccount, [loan]);

  const {
    value: fundsBankAccount,
    setValue: setFundsBankAccount,
    reset: resetFundsBankAccount,
  } = usePersistenceAtom<IFundsBankAccountData>(fundsBankAccountAtom, loanId);

  const defaultValues =
    apiFundsBankAccount || fundsBankAccount || defaultFormValues;

  const formMethods = useForm<IFundsBankAccountData>({
    defaultValues,
  });

  const handleOnChange = useCallback(
    (data: IFundsBankAccountData) => {
      if (apiFundsBankAccount) return;
      setFundsBankAccount(data);
    },
    [setFundsBankAccount, apiFundsBankAccount]
  );

  const { watch, formState } = formMethods;

  const handleOnDelete = useCallback(async () => {
    try {
      await Api.deleteFundsBankAccount({ loanId });
      if (apiFundsBankAccount) {
        setFundsBankAccount(apiFundsBankAccount);
      }
      setTimeout(() => {
        refreshLoan();
      }, 1000);
    } catch (err) {
      createToastFeedback({
        title: t('DealClosingCheckList:unexpected_error_canceling_review'),
        type: 'error',
      });
    }
  }, [loanId, refreshLoan, apiFundsBankAccount, setFundsBankAccount, t]);

  const onSubmit = useCallback(
    async (data: IFundsBankAccountData) => {
      try {
        await Api.updateFundsBankAccount({ loanId }, data);
        resetFundsBankAccount();
        setSuccess(true);
        setTimeout(() => {
          refreshLoan();
          onSuccess?.();
        }, 1000);
      } catch (err: any) {
        console.error(err);
        createToastFeedback({
          type: 'error',
          title: t('DealClosingCheckList:unexpected_error_sending_review'),
        });
      }
    },
    [loanId, refreshLoan, t, setSuccess, onSuccess, resetFundsBankAccount]
  );

  const isSubmitting = formState?.isSubmitting;

  useEffect(() => {
    const subscription = watch((value) =>
      handleOnChange(value as IFundsBankAccountData)
    );
    return () => subscription.unsubscribe();
  }, [handleOnChange, watch]);

  return {
    formMethods,
    onSubmit,
    onDelete: handleOnDelete,
    isSubmitting,
    isSuccess: success,
    isWaitingForReview,
    isVerified,
    isReady: !!loan,
  };
};
