import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { ButtonFeedback, InlineFeedback } from '@boopos/design-system';

import { FullScreenLoader } from 'components/FullScreenLoader';

import { apiV2 } from 'lib/api';

import {
  Description,
  Title,
  Wrapper,
} from './BrokerListingGrantAccessScreen.styles';

export const BrokerListingGrantAccessScreen = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const params = useParams();

  const token = params?.token;

  const { data, error } = useSWR(
    token && apiV2.getListingEnquiryByToken(token)
  );

  const name = data?.buyer.fullName;
  const businessName = data?.business.name;
  const listingId = data?.listing.id;

  const handleGrantAccess = useCallback(async () => {
    if (!token || !listingId) return;
    try {
      setIsLoading(true);
      await apiV2.acceptListingEnquiry(listingId, token);
      setSuccess(true);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [listingId, token]);

  if (error) {
    return (
      <Wrapper data-testid="error-wrapper">
        <InlineFeedback
          heading={t('BrokerListingGrantAccessScreen:error_message')}
        />
      </Wrapper>
    );
  }

  if (data === undefined) {
    return <FullScreenLoader data-testid="loading" />;
  }

  if (success) {
    return (
      <Wrapper
        css={{ mt: '$4', '@bp2': { mt: '$6' } }}
        data-testid="success-wrapper"
      >
        <InlineFeedback
          heading={t('BrokerListingGrantAccessScreen:success_message', {
            name,
          })}
          variant="success"
          expandable={false}
          defaultExpanded
        >
          {t('BrokerListingGrantAccessScreen:close_window_message')}
        </InlineFeedback>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Title data-testid="title">
        {t('BrokerListingGrantAccessScreen:confirmation_title', { name })}
      </Title>
      <Description data-testid="description">
        {t('BrokerListingGrantAccessScreen:confirmation_message', {
          name,
          businessName,
        })}
      </Description>
      <ButtonFeedback
        fullWidth
        onClick={handleGrantAccess}
        isLoading={isLoading}
        isSuccess={success}
        data-testid="grant-access-button"
      >
        {t('BrokerListingGrantAccessScreen:confirm_action')}
      </ButtonFeedback>
    </Wrapper>
  );
};
