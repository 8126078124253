import { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { BackButton } from 'components/BackButton';
import { LayoutHideMenu } from 'components/Layout';
import { QualificationScreenMenu } from 'components/QualificationScreenMenu';
import { QualificationStatusFeedback } from 'components/QualificationStatus';
import {
  SubNavigationSecondLevelContainer,
  SubNavigationSecondLevelContent,
} from 'components/ScreenLayout';
import { SubScreenTitle } from 'components/SubScreenTitle';

import { routePaths } from 'router/routePaths';

export const QualificationScreen = memo(
  ({ renderWrapperElements = true }: { renderWrapperElements?: boolean }) => {
    const { t } = useTranslation();
    return (
      <>
        <Helmet>
          <title>{t('QualificationScreen:qualification_title')}</title>
        </Helmet>
        <LayoutHideMenu hideOn="all" shouldShowMenuOnUnmount={false} />
        {renderWrapperElements && (
          <>
            <BackButton to={routePaths.account} />
            <SubNavigationSecondLevelContainer>
              <SubScreenTitle css={{ '@bp2': { mt: 0 } }}>
                {t('QualificationScreen:qualification_title')}
              </SubScreenTitle>
              <QualificationStatusFeedback />
              <QualificationScreenMenu />
            </SubNavigationSecondLevelContainer>
          </>
        )}
        <SubNavigationSecondLevelContent>
          <Outlet />
        </SubNavigationSecondLevelContent>
      </>
    );
  }
);
