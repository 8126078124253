import { CleaveOptions } from 'cleave.js/options';
import Cleave from 'cleave.js/react';
import { ReactNode, forwardRef } from 'react';

import { Input } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CleaveHookFormInput } from 'components/CleaveHookFormInput';

import {
  cleaveMoneyInputOptions,
  negativeCleaveMoneyInputOptions,
} from 'lib/format';
import { Currency } from 'lib/types';

interface ICurrencyInput {
  name: string;
  currency: Currency;
  value?: string | number;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  cleaveOptions?: CleaveOptions;
  allowNegative?: boolean;

  [x: string]: any;
}

interface ICurrencyHookInput extends ICurrencyInput {
  control: any;
}

const icons: { [key in Currency]: ReactNode } = {
  [Currency.USD]: (
    <FontAwesomeIcon icon={regular('dollar')} data-testid="dollar-icon" />
  ),
  [Currency.EUR]: (
    <FontAwesomeIcon icon={regular('euro')} data-testid="euro-icon" />
  ),
};

const iconProps = {
  [Currency.USD]: { leftIcon: icons[Currency.USD] },
  [Currency.EUR]: { rightIcon: icons[Currency.EUR] },
};

const getOptions = (props: ICurrencyHookInput | ICurrencyInput) => {
  if (props.cleaveOptions) {
    return props.cleaveOptions;
  }
  return props?.allowNegative
    ? negativeCleaveMoneyInputOptions[props.currency]
    : cleaveMoneyInputOptions[props.currency];
};

export const CurrencyInput = forwardRef<HTMLInputElement, ICurrencyInput>(
  (props, ref) => (
    <Input
      {...props}
      as={Cleave}
      htmlRef={ref}
      inputMode="tel"
      options={getOptions(props)}
      {...(iconProps[props.currency] ?? {})}
    />
  )
);

export const CurrencyHookInput = (props: ICurrencyHookInput) => (
  <CleaveHookFormInput
    {...props}
    inputMode="tel"
    options={getOptions(props)}
    {...(iconProps[props.currency] ?? {})}
  />
);
