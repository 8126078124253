import { useTranslation } from 'react-i18next';

import { useSubmitForEvaluationStep } from 'components/SubmitForEvaluation';

import { IApiDocument, LoanDocumentType } from 'lib/types';

interface UseDocumentsProps {
  documents?: IApiDocument[];
  requiredDocuments?: LoanDocumentType[];
}

interface UseDocumentsPropsConfig {
  baseRoute: string;
  editable: boolean;
  documentType: LoanDocumentType;
  error?: string;
}

const emptyArray: any[] = [];

export const useDocumentsProps = (
  props: UseDocumentsProps,
  config: UseDocumentsPropsConfig
) => {
  const { t } = useTranslation();

  const { editable, baseRoute, documentType, error } = config;

  const documents = props?.documents ?? (emptyArray as IApiDocument[]);
  const requiredDocuments =
    props?.requiredDocuments ?? (emptyArray as LoanDocumentType[]);

  const { contentCellProps, hasValue } = useSubmitForEvaluationStep({
    path: baseRoute,
    entity: documents,
    editable,
    actionLiteral: t('BasicInformationDetails:upload'),
    hasValueGetter: (entity) =>
      !!entity?.find((document) => document.type === documentType),
    error,
  });

  const visible = requiredDocuments.includes(documentType) || hasValue;

  return {
    visible,
    contentCellProps,
    hasValue,
  };
};
