import React from 'react';
import { useTranslation } from 'react-i18next';

import { LayoutSidebarDetail } from 'components/LayoutSidebarDetail';

import { SaleSellerApplicationApi, SellApplicationStatus } from 'lib/types';

import { SecureAndConfidentialBadge } from 'components/LayoutSidebarDetail/LayoutSidebarDetail';

interface ByStatusProps {
  application?: SaleSellerApplicationApi;
}

export const ContentByStatus = {
  [SellApplicationStatus.missing_info]: (_: ByStatusProps) => {
    return <SecureAndConfidentialBadge />;
  },
  [SellApplicationStatus.under_review]: (_: ByStatusProps) => {
    const { t } = useTranslation();
    return (
      <>
        <LayoutSidebarDetail.SidebarTitle>
          {t('ForSaleDetailApplication:whats_next_header')}
        </LayoutSidebarDetail.SidebarTitle>
        <LayoutSidebarDetail.SidebarContent data-testid="sidebar-under-review">
          {t('ForSaleDetailApplication:whats_next_applied')}
        </LayoutSidebarDetail.SidebarContent>
      </>
    );
  },
  [SellApplicationStatus.ready_to_publish]: (_: ByStatusProps) => {
    const { t } = useTranslation();
    return (
      <>
        <LayoutSidebarDetail.SidebarTitle>
          {t('ForSaleDetailApplication:whats_next_header')}
        </LayoutSidebarDetail.SidebarTitle>
        <LayoutSidebarDetail.SidebarContent data-testid="sidebar-ready-to-publish">
          {t('ForSaleDetailApplication:whats_next_ready_to_publish')}
        </LayoutSidebarDetail.SidebarContent>
      </>
    );
  },
  [SellApplicationStatus.published]: (_: ByStatusProps) => {
    const { t } = useTranslation();
    return (
      <>
        <LayoutSidebarDetail.SidebarTitle>
          {t('ForSaleDetailApplication:sell_faster_tips_header')}
        </LayoutSidebarDetail.SidebarTitle>
        <LayoutSidebarDetail.SidebarContent data-testid="sidebar-published">
          <LayoutSidebarDetail.SidebarHint>
            <LayoutSidebarDetail.SidebarHintTitle>
              {t('ForSaleDetailApplication:sell_faster_tip_1_header')}
            </LayoutSidebarDetail.SidebarHintTitle>
            <div>{t('ForSaleDetailApplication:sell_faster_tip_1_content')}</div>
          </LayoutSidebarDetail.SidebarHint>
          <LayoutSidebarDetail.SidebarHint>
            <LayoutSidebarDetail.SidebarHintTitle>
              {t('ForSaleDetailApplication:sell_faster_tip_2_header')}
            </LayoutSidebarDetail.SidebarHintTitle>
            <div>{t('ForSaleDetailApplication:sell_faster_tip_2_content')}</div>
          </LayoutSidebarDetail.SidebarHint>
          <LayoutSidebarDetail.SidebarHint>
            <LayoutSidebarDetail.SidebarHintTitle>
              {t('ForSaleDetailApplication:sell_faster_tip_3_header')}
            </LayoutSidebarDetail.SidebarHintTitle>
            <div>{t('ForSaleDetailApplication:sell_faster_tip_3_content')}</div>
          </LayoutSidebarDetail.SidebarHint>
        </LayoutSidebarDetail.SidebarContent>
      </>
    );
  },
  [SellApplicationStatus.rejected]: (_: ByStatusProps) => null,
};
