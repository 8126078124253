import { useTranslation } from 'react-i18next';

import { truncateValue } from '@boopos/utils';

import { CardSection } from 'ui/CardSection';

import { InsightsCardProps } from './InsightsCard.interface';
import { CommonView, Heading, Loading, useRoundPercentil } from './partials';

export const RevenueGrowthCard = ({
  percentile,
  avg,
  basicView,
  blur,
  ...rest
}: InsightsCardProps) => {
  const { t } = useTranslation();
  const roundedPercentile = useRoundPercentil(percentile);
  const isLoading = !percentile && !avg;

  return (
    <CardSection {...rest}>
      <Heading
        heading={t('Insights:RevenueGrowthCard:heading')}
        tooltip={t('Insights:RevenueGrowthCard:tooltip_description')}
        tooltipId="revenue-growth"
      />
      {isLoading ? (
        <Loading />
      ) : (
        <CommonView
          basicView={basicView}
          blur={blur}
          percentile={roundedPercentile}
          valueDescription={t('Insights:RevenueGrowthCard:value_description', {
            avg: truncateValue(avg),
          })}
          rankDescription={t('Insights:RevenueGrowthCard:rank_description', {
            percentile: truncateValue(roundedPercentile),
          })}
        />
      )}
    </CardSection>
  );
};
