import { ICategoriesApi } from 'lib/types';

import { parseCategoriesToApi } from './parseCategoriesToApi';

export const parseCategoriesToComponent = (data: ICategoriesApi) => {
  const categories = parseCategoriesToApi(data); // Ensure categories are in the correct format

  return Object.keys(categories).reduce(
    (acc, key) => {
      acc.category = {
        ...acc.category,
        [key]: data[key],
      };

      return acc;
    },
    {
      category: {},
    }
  );
};
