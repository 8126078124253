import { useRef } from 'react';
import useSWR from 'swr';

import config from 'lib/config';

import { IUseNewUpdateAvailable } from './useNewUpdateAvailable.interface';

const appVersionFile = `/app-version.json`;
const currentVersion = config.APP_VERSION;

const reloadPage = () => window.location.reload();

const fetcher = (url: string) =>
  window.fetch(`${url}?nc=${Date.now()}`).then((res) => res.json());

export const useNewUpdateAvailable = (): IUseNewUpdateAvailable => {
  const random = useRef(Date.now());

  const { data, error } = useSWR([appVersionFile, random], {
    fetcher,
  });

  const latestVersion = !!data && data.version;

  const newVersionAvailable =
    latestVersion && !error && latestVersion !== currentVersion;

  return {
    newVersionAvailable,
    reloadPage,
  };
};
