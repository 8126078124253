import { MouseEvent, useCallback, useState } from 'react';

import Api from 'lib/api';

interface IUseForSaleFavorite {
  listingId?: string;
  onSuccess?: () => Promise<void>;
}

export const useForSaleFavourite = ({
  listingId,
  onSuccess,
}: IUseForSaleFavorite) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const addFavourite = useCallback(
    async (e?: MouseEvent<HTMLButtonElement>) => {
      e?.preventDefault?.();
      e?.stopPropagation?.();
      if (!listingId) return;
      setLoading(true);
      await Api.addFavourite(listingId);
      await onSuccess?.();

      setLoading(false);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 1000);
    },
    [listingId, onSuccess]
  );

  const removeFavourite = useCallback(
    async (e?: MouseEvent<HTMLButtonElement>) => {
      e?.preventDefault?.();
      e?.stopPropagation?.();
      if (!listingId) return;
      setLoading(true);
      await Api.removeFavourite(listingId);
      await onSuccess?.();
      setLoading(false);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 1000);
    },
    [listingId, onSuccess]
  );

  return { addFavourite, removeFavourite, success, loading };
};
