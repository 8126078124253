import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  useSubmitForEvaluationError,
  useSubmitForEvaluationStep,
} from 'components/SubmitForEvaluation';

import { formatMoney } from 'lib/format';
import { MoneyFormat } from 'lib/format/money/formatMoney.interface';
import { SaleSellerApplicationApi } from 'lib/types';

import { BusinessPerformanceProps } from './BusinessPerformance.interface';

export const useBusinessPerformance = (props: BusinessPerformanceProps) => {
  const { path, application, editable = true } = props;

  const { t } = useTranslation();

  const revenueFormatted = formatMoney(application?.ttmRevenue, {
    format: MoneyFormat.long,
  });
  const profitFormatted = formatMoney(application?.ttmProfit, {
    format: MoneyFormat.long,
  });

  const { businessPerformance } = useSubmitForEvaluationError();

  const errorMessage = businessPerformance
    ? t('ForSaleDetailApplication:business_performance_required')
    : undefined;

  const { contentCellProps, hasValue } =
    useSubmitForEvaluationStep<SaleSellerApplicationApi>({
      path,
      entity: application,
      editable,
      actionLiteral: t('ForSaleDetailApplication:set_asking_price'),
      hasValueGetter: (application) =>
        !!application?.ttmRevenue?.amount && !!application?.ttmProfit?.amount,
      error: errorMessage,
    });

  const children = (
    <>
      {hasValue && (
        <div>
          {t('ForSaleDetailApplication:business_performance_description', {
            revenue: revenueFormatted,
            profit: profitFormatted,
          })}
        </div>
      )}
    </>
  );

  return {
    contentCellProps,
    children: hasValue ? children : errorMessage,
    hasValue,
  };
};
