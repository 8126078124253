import { useCallback, useEffect } from 'react';

import { useInvestmentCriteriaNavigation } from './useInvestmentCriteriaNavigation';
import { useParentCategories } from './useParentCategories';
import { useParentCategoriesApiUtils } from './useParentCategoriesApiUtils';
import { GENERIC_OTHER } from './useRollingFlow';
import { useSaveInvestmentCriteria } from './useSaveInvestmentCriteria';

export const useInvestmentCriteriaScreen = () => {
  const { navigateToNextPath } = useInvestmentCriteriaNavigation();

  const {
    handleSelectParentCategories,
    syncParentCategories,
    parentCategories,
  } = useParentCategories();

  const { parseCategoriesToApi, parseOtherBusinessTypesToApi } =
    useParentCategoriesApiUtils();

  const { loading, success, investmentCriteria, save } =
    useSaveInvestmentCriteria({
      onSave: navigateToNextPath,
    });

  const continueFlow = useCallback(() => {
    navigateToNextPath();
  }, [navigateToNextPath]);

  const handleSave = useCallback(async () => {
    await save({
      ...investmentCriteria,
      categories: parseCategoriesToApi(),
      otherBusinessTypes: parseOtherBusinessTypesToApi(),
    });
  }, [
    investmentCriteria,
    parseCategoriesToApi,
    parseOtherBusinessTypesToApi,
    save,
  ]);

  const continueDisabled = !parentCategories.length;

  useEffect(() => {
    if (!investmentCriteria?.categories) return;
    syncParentCategories();
  }, [investmentCriteria?.categories, syncParentCategories]);

  return {
    genericOtherId: GENERIC_OTHER,
    loading,
    success,
    continueDisabled,
    parentCategories,
    handleSelectParentCategories,
    continueFlow,
    save: handleSave,
  };
};
