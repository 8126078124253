import { styled } from '@boopos/design-system';

export const ControlWrapper = styled('div', {
  border: '2px solid $borderInteractiveDefault',
  borderRadius: '.8rem',
  padding: '$2 $4',
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',

  variants: {
    menuIsOpen: {
      true: {
        cursor: 'default',
      },
      false: {
        '&:hover': {
          background: '$surfaceSelected',
          borderColor: '$borderInteractiveHover',
        },
      },
    },
    isFocused: {
      true: {
        boxShadow: '0 0 0 4px $colors$borderInteractiveFocus',
      },
    },
  },
  compoundVariants: [
    {
      menuIsOpen: true,
      isFocused: true,
      css: {
        borderColor: '$borderInteractiveActive',
        boxShadow: 'none',
      },
    },
  ],
});

export const DropdownIndicatorWrapper = styled('div', {
  transition: 'all 0.3s ease',
  transform: 'rotate(0deg)',
  color: '$actionNeutralInteraction',

  variants: {
    menuIsOpen: {
      true: {
        transform: 'rotate(180deg)',
      },
    },
  },
});

export const ValueContainerWrapper = styled('div', {
  display: 'inline-flex',
  flexWrap: 'nowrap',
  gap: '$2',
});

export const MenuWrapper = styled('div', {
  backgroundColor: '$white',
  borderRadius: '.8rem',
  mt: '$2',
  boxShadow:
    '0px 0px 8px 0px rgba(0, 0, 0, 0.05), 0px 2px 8px 0px rgba(0, 0, 0, 0.10)',
});

export const OptionWrapper = styled('div', {
  p: '$3 $4',
  cursor: 'pointer',

  '&:active': {
    background: '$surfaceSubdued',
  },

  variants: {
    isSelected: {
      true: {
        background: '$surfaceSelected',
      },
    },
    isFocused: {
      true: {
        background: '$surfaceElevated',
      },
    },
    isDisabled: {
      true: {
        color: '$textBodyDisabled',
        background: '$actionDisabledDisabled',
        cursor: 'default',
      },
    },
  },

  compoundVariants: [
    {
      isSelected: true,
      isFocused: true,
      css: {
        background: '$surfaceSelected',
      },
    },
  ],
});

export const SingleValueWrapper = styled('span', {
  display: 'inline-flex',
  fontSize: '1.6rem',
  lineHeight: '2.4rem',
  fontFamily: '$mono',
  color: '$textBodyTertiary',
});
