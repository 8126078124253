import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { Button } from '@boopos/design-system';

import { ConnectAccountModalProps } from 'components/AccountConnectionModal/AccountConnectionModal.interface';
import {
  MerchantsByType,
  useAccountConnectorsStatus,
} from 'components/AccountConnectors';
import { Loader } from 'components/FullScreenLoader';
import { ModalOverSideModal } from 'components/ModalOverSideModal';

import {
  ConnectAccountModalStyles,
  buttonClassName,
} from './ConnectAccountModal.styles';

const animationVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

export const ConnectAccountModal = ({
  onSuccess,
  businessId,
  visible,
  onClose,
  title,
  merchantType,
}: ConnectAccountModalProps) => {
  const { t } = useTranslation();
  const { isConnecting, cancelConnection } = useAccountConnectorsStatus();

  const connectors = MerchantsByType[merchantType];

  return (
    <ModalOverSideModal
      visible={visible}
      onClose={onClose}
      data-testid="connect-account-modal"
    >
      <ModalOverSideModal.Title>{title}</ModalOverSideModal.Title>

      <AnimatePresence>
        {isConnecting && (
          <ConnectAccountModalStyles.WaitingWrapper
            variants={animationVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <ConnectAccountModalStyles.WaitingContent>
              <Loader />
              <ConnectAccountModalStyles.WaitingText>
                {t('ConnectAccountModal:waiting')}
              </ConnectAccountModalStyles.WaitingText>
              <Button variant="secondary" fullWidth onClick={cancelConnection}>
                {t('ConnectAccountModal:cancel')}
              </Button>
            </ConnectAccountModalStyles.WaitingContent>
          </ConnectAccountModalStyles.WaitingWrapper>
        )}
      </AnimatePresence>

      <ConnectAccountModalStyles.AccountsWrapper>
        {connectors.map(([provider, MerchantComponent]) => (
          <MerchantComponent
            key={provider}
            provider={provider}
            onSuccess={onSuccess}
            businessId={businessId}
            className={buttonClassName()}
          />
        ))}
      </ConnectAccountModalStyles.AccountsWrapper>
    </ModalOverSideModal>
  );
};
