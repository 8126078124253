import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ButtonFeedback, InlineFeedback } from '@boopos/design-system';

import { CurrencyHookInput } from 'components/CurrencyInput';
import { useRequestLoanForms } from 'components/RequestLoanForms/useRequestLoanForms';

import api from 'lib/api';
import { defaultCurrency } from 'lib/constants';
import { getInputErrorProps } from 'lib/forms';
import { LoanTypesEnum } from 'lib/types';

import { IRequestGrowthLoanForm } from './RequestGrowthLoanForm.interface';
import { parseGrowthLoanToApi } from './RequestGrowthLoanForm.utils';

export const RequestGrowthLoanForm = () => {
  const { t } = useTranslation();

  const {
    businessId,
    error,
    success,
    setError,
    setSuccess,
    navigateToLoanDetail,
    handleErrorRemove,
  } = useRequestLoanForms();

  const defaultValues = useMemo(
    () => ({
      type: LoanTypesEnum.growth,
    }),
    []
  );

  const { handleSubmit, formState, control } = useForm<IRequestGrowthLoanForm>({
    defaultValues,
  });

  const { isSubmitting, errors } = formState;

  const onSubmit = useCallback(
    async (formData: IRequestGrowthLoanForm) => {
      try {
        const { data: loanData } = await api.createLoan(
          businessId as string,
          parseGrowthLoanToApi(formData)
        );

        setSuccess(true);

        setTimeout(() => {
          navigateToLoanDetail(loanData.id);
        }, 1500);
      } catch (err) {
        console.error(err);
        setError(true);
      }
    },
    [businessId, navigateToLoanDetail, setError, setSuccess]
  );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onChange={handleErrorRemove}
      data-testid="request-growth-loan-form"
    >
      <CurrencyHookInput
        label={t('RequestGrowthLoanForm:loan_amount_label')}
        data-testid="requested-amount-input"
        name="requestedAmount"
        currency={defaultCurrency}
        control={control}
        required
        autoFocus
        rules={{
          required: t('RequestGrowthLoanForm:loan_amount_required'),
        }}
        {...getInputErrorProps(errors.requestedAmount)}
        groupSpacing="xl"
      />
      {error && (
        <InlineFeedback
          data-testid="error-feedback"
          variant="warning"
          css={{ mb: '$6' }}
          heading={t('RequestGrowthLoanForm:error_message')}
        />
      )}
      <ButtonFeedback
        type="submit"
        fullWidth
        isLoading={isSubmitting}
        isSuccess={success}
        data-testid="create-loan-submit-button"
      >
        {t('RequestGrowthLoanForm:continue_action')}
      </ButtonFeedback>
    </form>
  );
};
