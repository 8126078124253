import { styled } from '@boopos/design-system';

export const Styles = {
  Wrapper: styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '.8rem',
  }),
  Dash: styled('span', {
    color: '$textBodyPlaceholder',
    fontSize: '3.2rem',
    lineHeight: '3.2rem',
    fontWeight: '$bold',
  }),
  Legend: styled('div', {
    width: '1.4rem',
    height: '1.4rem',
    borderRadius: '1.8rem',
    display: 'flex',
    marginRight: '$2',
    backgroundColor: '$infoFeatured',
  }),
  NotAvailable: styled('div', {
    color: '$textBodyTertiary',
    fontWeight: '$bold',
  }),
};
