import { useTranslation } from 'react-i18next';

import { InsightsComparisonTable } from 'ui/InsightsComparisonTable';
import { defaultChartColors } from 'ui/InsightsShared';

import {
  ChangeUnit,
  ChangeVariant,
  TotalChange,
} from './InsightsCharts.interface';
import { useTableValues } from './utils/useTableValues';

export const YearlyGrowthTable = ({
  totalChange,
  unit,
  negativeVariant = 'negative',
  positiveVariant = 'positive',
}: {
  totalChange?: TotalChange;
  unit?: ChangeUnit;
  negativeVariant?: ChangeVariant;
  positiveVariant?: ChangeVariant;
}) => {
  const { t } = useTranslation();
  const variant =
    totalChange?.absoluteChange && totalChange.absoluteChange > 0
      ? positiveVariant
      : negativeVariant;

  const values = useTableValues(totalChange, unit);

  if (!values) {
    return null;
  }

  return (
    <InsightsComparisonTable.Table data-testid="yearly-growth-table">
      <InsightsComparisonTable.Row>
        <InsightsComparisonTable.Th>
          <InsightsComparisonTable.Indicator
            css={{ background: defaultChartColors.bl200 }}
          />
          {values.fromHeader}
        </InsightsComparisonTable.Th>
        <InsightsComparisonTable.Td>
          {values.fromValue}
        </InsightsComparisonTable.Td>
      </InsightsComparisonTable.Row>
      <InsightsComparisonTable.Row>
        <InsightsComparisonTable.Th>
          <InsightsComparisonTable.Indicator
            css={{ background: defaultChartColors.bl500 }}
          />
          {values.toHeader}
        </InsightsComparisonTable.Th>
        <InsightsComparisonTable.Td>
          {values.toValue}
        </InsightsComparisonTable.Td>
      </InsightsComparisonTable.Row>
      <InsightsComparisonTable.Row>
        <InsightsComparisonTable.Th>
          {t('InsightsCharts:total_change')}
        </InsightsComparisonTable.Th>
        <InsightsComparisonTable.Td variant={variant}>
          {values.changeAbsolute}
          {!!values.changePercentage && (
            <InsightsComparisonTable.Badge>
              {values.changePercentage}
            </InsightsComparisonTable.Badge>
          )}
        </InsightsComparisonTable.Td>
      </InsightsComparisonTable.Row>
    </InsightsComparisonTable.Table>
  );
};
