import { styled } from '@boopos/design-system';

export const ModalStyles = {
  Description: styled('div', {
    color: '$textBodySecondary',
    mb: '4rem',
  }),
  Image: styled('img', {
    maxWidth: '100%',
    px: '4rem',
    mb: '4rem',
  }),
  Actions: styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.6rem',
  }),
};
