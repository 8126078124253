import { styled } from '@boopos/design-system';

export const HeaderStyles = {
  Wrapper: styled('div', {
    px: '$4',

    '@bp2': {
      px: '$8',
    },
  }),
};
