import { AnimatePresence } from 'framer-motion';
import { MouseEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';

import { ButtonFeedback } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  ActionSideModalCommonStyles,
  bottomNavAnimationVariants,
} from 'components/ActionSideModal';
import { CancelReviewConfirmation } from 'components/CancelReviewConfirmation';
import { getPersistenceKey } from 'components/ClosingChecklistPersistence';

import { SendingForReviewFirstTimeModal } from './SendingForReviewFirstTimeModal';

interface Props {
  isLoading?: boolean;
  isSuccess?: boolean;
  isWaitingForReview?: boolean;
  isVerified?: boolean;

  onCancelReview?: () => Promise<void>;
  onSendForReview?: () => Promise<void>;
  sendForReviewDisabled?: boolean;

  hideCancelAction?: boolean;

  isFormValid?: boolean;
}
export const LoanSideModalSendReview = ({
  isLoading,
  isSuccess,
  isWaitingForReview,
  isVerified,
  onCancelReview,
  onSendForReview,
  sendForReviewDisabled,
  hideCancelAction,
  isFormValid,
}: Props) => {
  const [isFirstTimeReview, setIsFirstTimeReview] = useLocalStorage(
    getPersistenceKey('firstTimeReviewModal'),
    true
  );
  const [isCanceling, setIsCanceling] = useState(false);
  const [cancelReviewModalVisible, setCancelReviewModalVisible] =
    useState(false);
  const [firstTimeReviewModalVisible, setFirstTimeReviewModalVisible] =
    useState(false);

  const { t } = useTranslation();

  const handleCancelReview = useCallback(() => {
    setCancelReviewModalVisible(true);
  }, []);

  const hideCancelConfirmation = useCallback(() => {
    setCancelReviewModalVisible(false);
  }, []);

  const confirmCancelReview = useCallback(async () => {
    hideCancelConfirmation();
    setIsCanceling(true);
    await onCancelReview?.();
    setTimeout(() => {
      setIsCanceling(false);
    }, 1000);
  }, [onCancelReview, hideCancelConfirmation]);

  const handleOnSendForReview = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      if (isFormValid && isFirstTimeReview) {
        setFirstTimeReviewModalVisible(true);
        return;
      }
      await onSendForReview?.();
    },
    [
      onSendForReview,
      isFirstTimeReview,
      setFirstTimeReviewModalVisible,
      isFormValid,
    ]
  );

  const confirmSendForReview = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setIsFirstTimeReview(false);

      setFirstTimeReviewModalVisible(false);
      await onSendForReview?.();
    },
    [onSendForReview, setIsFirstTimeReview]
  );

  const hideFirstTimeReviewModal = useCallback(() => {
    setFirstTimeReviewModalVisible(false);
  }, []);

  return (
    <>
      <AnimatePresence>
        <ActionSideModalCommonStyles.BottomNav
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={bottomNavAnimationVariants}
          data-testid="loan-side-modal-send-review"
        >
          {isWaitingForReview && (
            <>
              <ActionSideModalCommonStyles.BottomNavLeftText>
                {t('DealClosingCheckList:waiting_review')}
              </ActionSideModalCommonStyles.BottomNavLeftText>
              {!hideCancelAction && (
                <ButtonFeedback
                  type="button"
                  variant="secondary"
                  leftIcon={
                    <FontAwesomeIcon icon={regular('xmark')} size="lg" />
                  }
                  onClick={handleCancelReview}
                  isLoading={isCanceling}
                  data-testid="cancel-review-button"
                >
                  {t('DealClosingCheckList:cancel_review')}
                </ButtonFeedback>
              )}
            </>
          )}
          {isVerified && (
            <ActionSideModalCommonStyles.BottomNavCenterText>
              <FontAwesomeIcon
                icon={regular('check-double')}
                size="lg"
                pull="left"
              />
              {t('DealClosingCheckList:verified')}
            </ActionSideModalCommonStyles.BottomNavCenterText>
          )}
          {!isWaitingForReview && !isVerified && (
            <ActionSideModalCommonStyles.BottomActionWrapper>
              <ButtonFeedback
                type="button"
                isLoading={isLoading}
                isSuccess={isSuccess}
                data-testid="send-review-button"
                onClick={handleOnSendForReview}
                disabled={sendForReviewDisabled}
              >
                {t('DealClosingCheckList:send_review')}
              </ButtonFeedback>
            </ActionSideModalCommonStyles.BottomActionWrapper>
          )}
        </ActionSideModalCommonStyles.BottomNav>
      </AnimatePresence>

      <CancelReviewConfirmation
        visible={cancelReviewModalVisible}
        onCancelClick={confirmCancelReview}
        onNotCancelClick={hideCancelConfirmation}
      />

      <SendingForReviewFirstTimeModal
        visible={firstTimeReviewModalVisible}
        onSendForReview={confirmSendForReview}
        onDoItLater={hideFirstTimeReviewModal}
      />
    </>
  );
};
