import { useCallback } from 'react';

import { useVerifiedDisposableAmount } from 'components/QualificationStatus';

import { parseCategoriesToApi } from 'lib/parse';

import {
  ForSaleInlineFiltersProps,
  ForSaleSortedByEnum,
  ICategoriesApi,
} from './ForSaleFilters.interface';
import { parseBrokerFilters } from './ForSaleFilters.utils';
import { useForSaleFiltersConfig } from './useForSaleFiltersConfig';

const useRangeFilterSelected = (
  defaultValue?: number[],
  currentValue?: number[]
): boolean => {
  const [minSelected, maxSelected] = currentValue ?? [];
  const [minDefault, maxDefault] = defaultValue ?? [];

  return (
    (!!minSelected && minDefault !== minSelected) ||
    (!!maxSelected && maxDefault !== maxSelected)
  );
};

export const useForSaleSelectedFilters = ({
  filters,
}: Omit<ForSaleInlineFiltersProps, 'onApplyFilter'>) => {
  const filtersConfig = useForSaleFiltersConfig();

  const activeAskPrice = useRangeFilterSelected(
    filtersConfig?.askPrice?.defaultValue,
    filters?.askPriceRange
  );

  const activeProfitMultiplier = useRangeFilterSelected(
    filtersConfig?.profitMultiplier?.defaultValue,
    filters?.profitMultiplierRange
  );

  const activeProfit = useRangeFilterSelected(
    filtersConfig?.profit?.defaultValue,
    filters?.profitRange
  );

  const activeRevenue = useRangeFilterSelected(
    filtersConfig?.revenue?.defaultValue,
    filters?.revenue
  );

  const activeCategories = parseCategoriesToApi(
    filters?.category as ICategoriesApi
  );

  const isProprietary = !!filters?.isProprietary;
  const brokerFilterParsed = parseBrokerFilters(filters);

  const activeBrokers =
    !!brokerFilterParsed?.brokersIn?.length ||
    isProprietary ||
    !!brokerFilterParsed?.brokersNotIn?.length;

  const lendingAvailable = !!filters?.lendingAvailable;

  const numberActiveFilters = [
    activeAskPrice,
    activeProfit,
    activeRevenue,
    activeProfitMultiplier,
    activeBrokers,
    lendingAvailable,
    Object.keys(activeCategories).length,
  ].filter((exist) => !!exist).length;

  const favoriteSelected = !!filters?.favorite;
  const contactedSelected = !!filters?.contacted;
  const canAffordSelected = !!filters?.canAfford;
  const moreFiltersSelected = !!numberActiveFilters;
  const sortedBySelected = filters?.sortedBy ?? ForSaleSortedByEnum.Relevance;

  const justFavoriteSelected =
    favoriteSelected &&
    ![moreFiltersSelected, contactedSelected, canAffordSelected].includes(true);

  return {
    favoriteSelected,
    contactedSelected,
    canAffordSelected,
    justFavoriteSelected,
    moreFiltersSelected,
    sortedBySelected,
    moreFiltersCount: numberActiveFilters,
    activeRevenue,
    activeBrokers,
    activeProfit,
    activeProfitMultiplier,
    activeCategoriesSelected: Object.keys(activeCategories).length,
    activeAskPrice,
  };
};

export const useForSaleInlineFilters = ({
  filters,
  onApplyFilter,
}: ForSaleInlineFiltersProps) => {
  const selectedFilters = useForSaleSelectedFilters({ filters });
  const verifiedDisposableAmount = useVerifiedDisposableAmount();

  const handleFavorite = useCallback(() => {
    onApplyFilter({ favorite: !filters?.favorite });
  }, [onApplyFilter, filters]);

  const handleContacted = useCallback(() => {
    onApplyFilter({ contacted: !filters?.contacted });
  }, [onApplyFilter, filters]);

  const handleCanAfford = useCallback(() => {
    onApplyFilter({ canAfford: !filters?.canAfford });
  }, [onApplyFilter, filters]);

  const handleSortedBy = useCallback(
    (sortedValue: ForSaleSortedByEnum) => {
      onApplyFilter({ sortedBy: sortedValue });
    },
    [onApplyFilter]
  );

  return {
    handleFavorite,
    handleContacted,
    handleCanAfford,
    handleSortedBy,
    ...selectedFilters,
    showCanAfford: !!verifiedDisposableAmount?.amount,
  };
};
