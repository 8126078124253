import { ReactNode } from 'react';
import { useMatch, useParams } from 'react-router-dom';

import { generatePathSafe } from 'lib/utils/generatePathSafe';

import { useContentCellProps } from './useContentCellProps';

interface SubmitForEvaluationStep<T> {
  path: string;
  editable?: boolean;
  actionLiteral?: ReactNode;
  hasValueGetter?: (entity?: T) => boolean;
  entity?: T;
  error?: string;
}

export const useSubmitForEvaluationStep = <T,>({
  path,
  editable = true,
  actionLiteral,
  hasValueGetter = (_?: T) => false,
  entity,
  error,
}: SubmitForEvaluationStep<T>) => {
  const params = useParams();
  const toPath = generatePathSafe(path, params);
  const isMatching = !!useMatch(toPath ?? '');

  const hasValue = hasValueGetter(entity);

  const contentCellProps = useContentCellProps({
    isMatching,
    hasValue,
    editable,
    actionLiteral,
    toPath,
    error,
  });

  return {
    hasValue,
    contentCellProps,
  };
};
