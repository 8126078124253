import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

import api from 'lib/api';
import {
  addNewFilesToCurrentDocuments,
  deleteFileFromCurrentDocuments,
  getDocumentFiles,
} from 'lib/files';
import { IUploadedFile, IWatchListDeal } from 'lib/types';
import { noop } from 'lib/utils/noop';

import {
  IUseWatchListDocumentsUpload,
  IUseWatchListDocumentsUploadReturn,
} from './WatchListDocumentsUpload.interface';

export const useWatchListDocumentsUpload = ({
  loanId,
  documentType,
  onGeneralError,
}: IUseWatchListDocumentsUpload): IUseWatchListDocumentsUploadReturn => {
  const {
    data: currentDeal,
    mutate,
    error,
  } = useSWR<IWatchListDeal>(api.getWatchlistDeal(loanId));

  const [isUploading, setIsUploading] = useState(false);

  const onStart = useCallback(() => {
    setIsUploading(true);
  }, []);

  const onFinish = useCallback(() => {
    setIsUploading(false);
  }, []);

  const onFilesUploaded = useCallback(
    async (uploadedFiles: IUploadedFile[]) => {
      const newDocuments = addNewFilesToCurrentDocuments(
        currentDeal?.documents,
        documentType,
        uploadedFiles
      );
      await api.updateBusinessDealDocuments(loanId, {
        documents: newDocuments,
      });
      await mutate();
    },
    [loanId, documentType, currentDeal, mutate]
  );

  const deleteFile = useCallback(
    async (fileId: string) => {
      const newDocuments = deleteFileFromCurrentDocuments(
        currentDeal?.documents,
        documentType,
        fileId
      );
      await api.updateBusinessDealDocuments(loanId, {
        documents: newDocuments,
      });
      await mutate();
    },
    [loanId, documentType, currentDeal, mutate]
  );

  const files = useMemo(
    () => getDocumentFiles(documentType, currentDeal?.documents),
    [documentType, currentDeal?.documents]
  );

  const isLoading = typeof currentDeal === 'undefined';

  useLayoutEffect(() => {
    if (!error || !onGeneralError) return;
    onGeneralError().then(noop);
  }, [error, onGeneralError]);

  return {
    isLoading,
    isUploading,
    onStart,
    onFinish,
    onFilesUploaded,
    files,
    deleteFile,
  };
};
