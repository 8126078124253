import { useCallback } from 'react';
import { useController } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { IApiFile, IUploadedFile } from 'lib/types';

const emptyArray: IUploadedFile[] = [];

export const useFormFilesUploader = ({
  name,
  control,
  rules = { required: true },
}: {
  name: string;
  control: any;
  rules?: any;
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
  });

  const { value, onChange } = field;
  const safeValue: IApiFile[] = value || emptyArray;

  const onFilesUploaded = useCallback(
    (files: IUploadedFile[]) => {
      const newFiles = files.map((file: IUploadedFile) => ({
        id: uuidv4(),
        name: file.fileName,
        url: file.url,
        downloadUrl: file.downloadUrl,
      }));
      const newValue = [...safeValue, ...newFiles];
      onChange(newValue);
    },
    [onChange, safeValue]
  );

  const onDeleteFile = useCallback(
    (fileId: string) => {
      const newValue = safeValue.filter((file) => file.id !== fileId);
      onChange(newValue);
    },
    [safeValue, onChange]
  );

  const hasError = !!fieldState.error;

  return { onFilesUploaded, onDeleteFile, error: hasError, files: safeValue };
};
