import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputGroup } from '@boopos/design-system';
import { valueGreaterOrEqualThanXOrError } from '@boopos/utils';

import { CurrencyHookInput } from 'components/CurrencyInput';

import { formatMoney } from 'lib/format';
import { MoneyFormat } from 'lib/format/money/formatMoney.interface';
import { getInputErrorProps } from 'lib/forms';
import { parseMoneyToApi } from 'lib/parse';
import { Currency } from 'lib/types';

const MIN_AVAILABLE_FUNDS = 40_000;

const minAvailableFundsMoney = parseMoneyToApi({
  amount: MIN_AVAILABLE_FUNDS,
  currency: Currency.USD,
});

const groupCss = { position: 'relative' };

export const AvailableFundsInput = () => {
  const formMethods = useFormContext();

  const { register, control, watch, formState } = formMethods;

  const { t } = useTranslation();

  const currency = watch('currency');

  const minInvestmentFormatted = formatMoney(minAvailableFundsMoney, {
    format: MoneyFormat.long,
  });

  return (
    <>
      <input type="hidden" value={Currency.USD} {...register('currency')} />
      <InputGroup css={groupCss}>
        <CurrencyHookInput
          label={t('AvailableFundsForm:label')}
          data-testid="available-funds-input"
          name="availableFunds"
          rules={{
            required: t('AvailableFundsForm:required'),
            validate: valueGreaterOrEqualThanXOrError(
              MIN_AVAILABLE_FUNDS,
              t('AvailableFundsForm:minimum_investment', {
                value: minInvestmentFormatted,
              })
            ),
          }}
          currency={currency}
          control={control}
          {...getInputErrorProps(formState?.errors?.availableFunds)}
        />
      </InputGroup>
    </>
  );
};
