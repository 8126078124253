import { defaultCurrency } from 'lib/constants';
import { parseCategoriesToApi, parseMoneyToApi } from 'lib/parse';
import { ICategoriesApi } from 'lib/types';

import {
  IApiForSaleFilters,
  IForSaleFiltersForm,
} from './ForSaleFilters.interface';

export const parseBrokerFilters = (
  data: IForSaleFiltersForm
): { brokersIn?: string[]; brokersNotIn?: string[] } => {
  const otherBrokers = data?.otherBrokers ?? false;
  const availableBrokersArray = data?.availableBrokers?.split(',') ?? [];

  const brokers = (data?.brokers ?? []) || [];

  if (otherBrokers) {
    return {
      brokersNotIn: availableBrokersArray?.filter(
        (broker) => !brokers.includes(broker)
      ),
    };
  }

  if (brokers?.length) {
    return {
      brokersIn: brokers,
    };
  }

  return {};
};

const parseForSaleFiltersToApi = (
  data: IForSaleFiltersForm
): IApiForSaleFilters => {
  const safeData = data ?? {};
  const {
    category = {},
    askPriceRange,
    currency = defaultCurrency,
    profitMultiplierRange,
    favorite,
    lendingAvailable,
    contacted,
    canAfford,
    profitRange,
    revenue,
    isProprietary,
    sortedBy,
  } = safeData;

  const categories = parseCategoriesToApi(category as ICategoriesApi);

  const filters: IApiForSaleFilters = {};
  const brokerFilters = parseBrokerFilters(safeData);

  const moneyWithCurrency = (amount: number | string) =>
    parseMoneyToApi({
      amount: Number(amount),
      currency,
    });

  if (Object.keys(categories).length) {
    filters.categories = categories;
  }

  if (askPriceRange) {
    const [min, max] = askPriceRange;
    filters.minAskPrice = moneyWithCurrency(min);
    filters.maxAskPrice = moneyWithCurrency(max);
  }

  if (profitRange) {
    const [min, max] = profitRange;
    filters.minProfit = moneyWithCurrency(min);
    filters.maxProfit = moneyWithCurrency(max);
  }

  if (revenue) {
    const [min, max] = revenue;
    filters.minRevenue = moneyWithCurrency(min);
    filters.maxRevenue = moneyWithCurrency(max);
  }

  if (profitMultiplierRange) {
    const [min, max] = profitMultiplierRange;
    filters.minProfitMultiplier = Number(min);
    filters.maxProfitMultiplier = Number(max);
  }

  if (brokerFilters?.brokersIn?.length) {
    filters.brokersIn = brokerFilters.brokersIn;
  }

  if (brokerFilters?.brokersNotIn?.length) {
    filters.brokersNotIn = brokerFilters.brokersNotIn;
    filters.isProprietary = !!isProprietary;
  }

  if (favorite) {
    filters.favorite = favorite;
  }

  if (contacted) {
    filters.contacted = contacted;
  }

  if (canAfford) {
    filters.canAfford = canAfford;
  }

  if (lendingAvailable) {
    filters.lendingAvailable = lendingAvailable;
  }

  if (isProprietary) {
    filters.isProprietary = isProprietary;
  }

  if (sortedBy) {
    filters.sortedBy = sortedBy;
  }

  return filters;
};

export { parseForSaleFiltersToApi };
