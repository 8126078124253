import { useTranslation } from 'react-i18next';
import { Link, generatePath, useParams } from 'react-router-dom';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ContentCell } from 'ui/ContentCell';

import { ScoreTypesEnum } from 'components/BusinessInsights';
import { BuyerInsightsBuyBanner } from 'components/BuyerInsights/BuyerInsightsBuyBanner';
import { BuyerInsightsPreview } from 'components/BuyerInsights/BuyerInsightsPreview';
import { useBuyerInsightsData } from 'components/BuyerInsights/useBuyerInsightsData';
import {
  QualityOfRevenuesCard,
  RevenueGrowthCard,
} from 'components/InsightsCards';

import { IBusinessInsights } from 'lib/types';

export interface BuyerInsightsTimelinePreviewProps {
  businessInsights?: IBusinessInsights;
  businessId: string;
  insightsPath: string;
  onUnlock?: () => void;
}

const BuyerInsightsSummary = ({
  businessInsights,
  businessId,
  insightsPath,
  onUnlock,
}: {
  businessInsights: IBusinessInsights;
  businessId: string;
  insightsPath: string;
  onUnlock?: () => void;
}) => {
  const { t } = useTranslation();
  const params = useParams();

  const { locked, price, businessType, benchmarkScores } = useBuyerInsightsData(
    { businessInsights, businessId }
  );

  const insightsPathResolved = generatePath(insightsPath, params);

  const previewRevenueGrowthData = benchmarkScores[ScoreTypesEnum.growth];
  const previewQualityOfRevenuesData = benchmarkScores[ScoreTypesEnum.products];

  const showSeeMore = !locked;

  return (
    <>
      <BuyerInsightsPreview.Container data-testid="buyer-insights-timeline-preview">
        <ContentCell.Divider>
          {t('BuyerInsightsTimelinePreview:title')}
        </ContentCell.Divider>
        <BuyerInsightsPreview.Wrapper columns={showSeeMore ? 3 : 2}>
          <RevenueGrowthCard
            percentile={previewRevenueGrowthData?.percentile}
            avg={previewRevenueGrowthData?.avg}
            basicView={true}
            as={Link}
            to={insightsPathResolved}
            blur={locked}
          />
          <QualityOfRevenuesCard
            businessType={businessType}
            percentile={previewQualityOfRevenuesData?.percentile}
            basicView={true}
            as={Link}
            to={insightsPathResolved}
            blur={locked}
          />
          {showSeeMore && (
            <BuyerInsightsPreview.MoreInsightsCard
              as={Link}
              to={insightsPathResolved}
              border={true}
            >
              <BuyerInsightsPreview.SeeMoreCardTextWrapper>
                {t('BuyerInsightsTimelinePreview:more_insights')}
                <FontAwesomeIcon icon={regular('arrow-right')} size="lg" />
              </BuyerInsightsPreview.SeeMoreCardTextWrapper>
            </BuyerInsightsPreview.MoreInsightsCard>
          )}
        </BuyerInsightsPreview.Wrapper>
        {locked && (
          <BuyerInsightsBuyBanner
            price={price}
            insightsPath={insightsPathResolved}
            businessInsightsId={businessInsights.id}
            businessId={businessInsights.businessId}
            css={{ mt: '$4' }}
            onUnlock={onUnlock}
          />
        )}
      </BuyerInsightsPreview.Container>
    </>
  );
};

export const BuyerInsightsTimelinePreview = ({
  businessInsights,
  businessId,
  insightsPath,
  onUnlock,
}: BuyerInsightsTimelinePreviewProps) => {
  if (!businessInsights) {
    return null;
  }

  return (
    <BuyerInsightsSummary
      businessInsights={businessInsights}
      businessId={businessId}
      insightsPath={insightsPath}
      onUnlock={onUnlock}
    />
  );
};
