import { AnimatePresence, LayoutGroup } from 'framer-motion';
import { Children, useCallback, useMemo } from 'react';

import { MotionTabsProps } from './MotionTabs.interface';
import { MotionTabElements } from './MotionTabs.styles';
import { getChildProps, transitionConfig } from './MotionTabs.utils';
import { useMotionTabs } from './useMotionTabs';
import { useMotionTabsStyles } from './useMotionTabsStyles';

export const MotionTabs = ({
  children,
  tabs,
  initial,
  styles,
  onChange,
  ...rest
}: MotionTabsProps) => {
  const { tabsChildren, hovered, setHovered, selected, setSelected } =
    useMotionTabs({
      initial,
      children: tabs,
    });

  const { wrapperStyles, selectedStyles, hoverStyles } =
    useMotionTabsStyles(styles);

  const layoutGroupId = useMemo(() => crypto.randomUUID(), []);
  const handleHoverEnd = useCallback(() => setHovered(null), [setHovered]);

  const handleHoverStart = useCallback(
    (i: number) => () => setHovered(i),
    [setHovered]
  );

  const handleFocus = useCallback(
    (i: number) => () => setHovered(i),
    [setHovered]
  );

  const handleClick = useCallback(
    (childId: string) => () => {
      setSelected(childId);
      onChange?.(childId);
    },
    [setSelected, onChange]
  );

  const selectedChildren = useMemo(() => {
    return Children.toArray(children).find(
      (child) => getChildProps(child)['data-id-selected'] === selected
    );
  }, [children, selected]);

  return (
    <MotionTabElements.Wrapper {...rest}>
      <MotionTabElements.TabsWrapper
        onHoverEnd={handleHoverEnd}
        css={{ ...wrapperStyles }}
      >
        <LayoutGroup id={layoutGroupId}>
          {tabsChildren.map((child, i) => {
            const childrenProps = getChildProps(child);
            const childId = childrenProps.id;

            return (
              <MotionTabElements.Button
                key={childId}
                onHoverStart={handleHoverStart(i)}
                onFocus={handleFocus(i)}
                onClick={handleClick(childId)}
                {...childrenProps}
              >
                <MotionTabElements.Label>
                  {getChildProps(child).children}
                </MotionTabElements.Label>
                {i === hovered && (
                  <MotionTabElements.Background
                    transition={transitionConfig}
                    layoutId="hover"
                    hovered={true}
                    css={{
                      ...hoverStyles,
                    }}
                  />
                )}
                <AnimatePresence>
                  {childId === selected && (
                    <MotionTabElements.Background
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={transitionConfig}
                      layoutId="selected"
                      css={{
                        ...selectedStyles,
                      }}
                    />
                  )}
                </AnimatePresence>
              </MotionTabElements.Button>
            );
          })}
        </LayoutGroup>
      </MotionTabElements.TabsWrapper>
      <MotionTabElements.ChildrenWrapper>
        {selectedChildren}
      </MotionTabElements.ChildrenWrapper>
    </MotionTabElements.Wrapper>
  );
};

MotionTabs.Tab = MotionTabElements.Button;
MotionTabs.Children = MotionTabElements.Children;
