import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CenteredContentScreen } from 'ui/CenteredContentScreen';

import { BackButton } from 'components/BackButton';
import { AnimatedScreenOpacityWrapper } from 'components/ScreenLayout';
import { TermSheetSummary } from 'components/TermSheetSummary';

import api from 'lib/api';
import { ILoanApi, LoanTypesEnum } from 'lib/types';

interface ITermSheetScreen {
  goBackUrl: string;
}

const TermSheetReady = ({
  loan,
  goBackUrl,
}: {
  loan: ILoanApi;
  goBackUrl: string;
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const { id: loanId, business, termSheet } = loan;
  const businessId = business?.id;
  const businessName = business?.name;

  const generatedGoBackUrl = generatePath(goBackUrl, {
    ...(businessId ? { businessId } : {}),
    ...(loanId ? { loanId } : {}),
  });

  const headerLiterals = {
    [LoanTypesEnum.growth]: t('TermSheetScreen:title_growth'),
    [LoanTypesEnum.purchase]: t('TermSheetScreen:title_common'),
    [LoanTypesEnum.sale_buyer]: t('TermSheetScreen:title_common'),
    [LoanTypesEnum.sale_seller]: t('TermSheetScreen:title_common'),
    [LoanTypesEnum.insights]: t('TermSheetScreen:title_common'),
  };

  const descriptionLiterals = {
    [LoanTypesEnum.growth]: t('TermSheetScreen:description_growth', {
      businessName,
    }),
    [LoanTypesEnum.purchase]: t('TermSheetScreen:description_common', {
      businessName,
    }),
    [LoanTypesEnum.sale_buyer]: t('TermSheetScreen:description_common', {
      businessName,
    }),
    [LoanTypesEnum.sale_seller]: t('TermSheetScreen:description_common', {
      businessName,
    }),
    [LoanTypesEnum.insights]: t('TermSheetScreen:description_common', {
      businessName,
    }),
  };

  const headerLiteral = headerLiterals[loan.type];
  const descriptionLiteral = descriptionLiterals[loan.type];

  return (
    <>
      <Helmet>
        <title>{headerLiteral}</title>
      </Helmet>
      <CenteredContentScreen.Wrapper>
        <BackButton
          to={generatedGoBackUrl}
          state={{ backUrl: location.state?.backUrl }}
          hideOnDesktop={false}
          topSpace
          Icon={<FontAwesomeIcon icon={regular('xmark')} size="xl" />}
        />
        <CenteredContentScreen.Content>
          <CenteredContentScreen.Title>
            {headerLiteral}
          </CenteredContentScreen.Title>
          <CenteredContentScreen.Description data-testid="term-sheet-description">
            {descriptionLiteral}
          </CenteredContentScreen.Description>
          <TermSheetSummary
            termSheet={termSheet}
            loan={loan}
            businessName={businessName}
            goBackUrl={generatedGoBackUrl}
          />
        </CenteredContentScreen.Content>
      </CenteredContentScreen.Wrapper>
    </>
  );
};

export const TermSheetScreen = (props: ITermSheetScreen) => {
  const params = useParams();
  const { goBackUrl } = props;

  const { data: loan } = useSWR<ILoanApi>(
    params.loanId && api.getWatchlistDeal(params.loanId)
  );

  return (
    <AnimatedScreenOpacityWrapper data-testid="term-sheet-screen">
      {loan && <TermSheetReady loan={loan} goBackUrl={goBackUrl} />}
    </AnimatedScreenOpacityWrapper>
  );
};
