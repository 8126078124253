import { useTranslation } from 'react-i18next';

import EmptyStatePicture from 'assets/illustrations/enquiries-empty-state.svg';

import { EmptyStateStyles } from './EmptyState.styles';

export const EmptyState = () => {
  const { t } = useTranslation();

  return (
    <EmptyStateStyles.Wrapper>
      <EmptyStateStyles.Picture>
        <img
          src={EmptyStatePicture}
          alt={t('EnquiriesScreen:empty_state_title')}
        />
      </EmptyStateStyles.Picture>
      <EmptyStateStyles.Title>
        {t('EnquiriesScreen:empty_state_title')}
      </EmptyStateStyles.Title>
      <EmptyStateStyles.Description>
        {t('EnquiriesScreen:empty_state_description')}
      </EmptyStateStyles.Description>
    </EmptyStateStyles.Wrapper>
  );
};
