import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Banner, BannerSecondaryAction } from 'ui/Banner';

import { useConfidentialityAgreementBanner } from 'components/ConfidentialityAgreementBanner';
import { useVerifiedDisposableAmount } from 'components/QualificationStatus';

import { formatMoney } from 'lib/format';
import { ListingForSale, LoanAffordability } from 'lib/types';

import { routePaths } from 'router/routePaths';

export interface DealOutsideLendingScopeProps {
  listing?: ListingForSale;
}

export const DealOutsideLendingScope = ({
  listing,
}: DealOutsideLendingScopeProps) => {
  const { t } = useTranslation();
  const verifiedDisposableAmount = useVerifiedDisposableAmount();

  const { showConfidentialityAgreementBanner } =
    useConfidentialityAgreementBanner({
      requiresAgreement: listing?.requiresAgreement,
    });

  if (listing?.loanAffordability !== LoanAffordability.notAffordable) {
    return null;
  }

  if (showConfidentialityAgreementBanner) {
    return null;
  }

  const description = (
    <Trans
      i18nKey="DealLendingScope:not_financial_fit"
      values={{
        amount: verifiedDisposableAmount?.formatted,
        requiredFunds: formatMoney(listing?.requiredFunds),
      }}
    >
      <strong />
    </Trans>
  );

  return (
    <Banner
      css={{ my: '3.2rem' }}
      icon={<>🔒</>}
      description={description}
      variant="secondary"
    >
      <BannerSecondaryAction
        as={Link}
        to={routePaths.qualificationProofOfFunds}
        data-testid="listing-not-affordable"
      >
        {t('DealLendingScope:not_financial_fit_cta')}
      </BannerSecondaryAction>
    </Banner>
  );
};
