import { atomWithStorage } from 'jotai/utils';

import {
  BasePersistenceAtom,
  getPersistenceKey,
} from 'components/ClosingChecklistPersistence';

import { IAssetPurchaseAgreementData } from './AssetPurchaseAgreement.interface';

export const assetPurchaseAgreementAtom = atomWithStorage<
  BasePersistenceAtom<IAssetPurchaseAgreementData>
>(getPersistenceKey('assetPurchaseAgreement'), {});
