import { useEffect } from 'react';

import { handleHSClassName, hubspotGlobalStyles } from './hubspot.utils';
import { useHubspotWidgetPosition } from './useHubspotWidgetPosition';

export const HSWidgetPosition = () => {
  const { isDesktop, mainMenuHiddenOn } = useHubspotWidgetPosition();

  useEffect(() => {
    (async () => {
      await handleHSClassName(isDesktop, mainMenuHiddenOn);
    })();
  }, [isDesktop, mainMenuHiddenOn]);

  return <>{hubspotGlobalStyles()}</>;
};
