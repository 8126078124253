import { ReactNode, useCallback, useState } from 'react';

import { getBase64Image } from 'lib/images';

interface BankAccountImageProps {
  logo: string;
  name?: string;
  fallback?: ReactNode;
  getSrc?: (src: string) => string;
}

export const BankAccountImage = ({
  logo,
  name,
  fallback,
  ...props
}: BankAccountImageProps) => {
  const [error, setError] = useState(false);

  const onError = useCallback(() => {
    setError(true);
  }, []);

  if (error) {
    return <>{fallback}</>;
  }

  return (
    <img src={getBase64Image(logo)} onError={onError} alt={name} {...props} />
  );
};
