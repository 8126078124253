import qs from 'query-string';
import { useCallback } from 'react';

import {
  IUseAccountConnector,
  IUseAccountConnectorReturn,
} from 'components/AccountConnectors/AccountConnectors.interface';
import { useSharedHandlers } from 'components/AccountConnectors/useSharedHandlers';

import api from 'lib/api';

import { routePaths } from 'router/routePaths';

const AUTHORIZE_URL = 'https://connect.stripe.com/oauth/authorize';

export const useStripeLink = ({
  provider,
  businessId,
  onSuccess,
}: IUseAccountConnector): IUseAccountConnectorReturn => {
  const { handleSuccess, setWindowRef, startConnection } = useSharedHandlers({
    provider,
    businessId,
    onSuccess,
  });

  const open = useCallback(async () => {
    startConnection();
    const { data } = await api.initMerchantBusinessConnection(
      provider,
      businessId
    );

    const clientId = data.token;

    window.stripeCallback = handleSuccess;

    const back = `${window.location.protocol}//${window.location.host}${routePaths.callbackStripe}`;

    const authorizeParams = {
      response_type: 'code',
      scope: 'read_only',
      client_id: clientId,
      redirect_uri: back,
    };

    const url = `${AUTHORIZE_URL}?${qs.stringify(authorizeParams)}`;

    setWindowRef(window.open(url));
  }, [setWindowRef, handleSuccess, businessId, provider, startConnection]);

  return { open, ready: true, error: null };
};
