import { useCallback } from 'react';

import { styled } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PersonCardStyles = {
  Wrapper: styled('a', {
    cursor: 'pointer',
    textDecoration: 'none',
    color: '$slate900',
    display: 'flex',
    p: '$4',
    boxShadow:
      '0px 1px 4px rgba(0, 0, 0, 0.15), 0px 0px 4px rgba(0, 0, 0, 0.05)',
    borderRadius: '.8rem',
    mb: '$6',
  }),
  PersonIconWrapper: styled('div', {
    mr: '$3',
    lineHeight: 0,
  }),
  ArrowIconWrapper: styled('div', {
    lineHeight: 0,
    ml: '$3',
  }),
  NameWrapper: styled('div', {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    flex: 1,
  }),
};

interface PersonCardProps {
  firstName: string;
  lastName: string;
  onClick: (id: string) => void;
  id: string;
}

export const PersonCard = ({
  firstName,
  lastName,
  id,
  onClick,
  ...rest
}: PersonCardProps) => {
  const handleClick = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  return (
    <PersonCardStyles.Wrapper {...rest} onClick={handleClick}>
      <PersonCardStyles.PersonIconWrapper>
        <FontAwesomeIcon icon={regular('user')} size="lg" />
      </PersonCardStyles.PersonIconWrapper>
      <PersonCardStyles.NameWrapper>
        {firstName} {lastName}
      </PersonCardStyles.NameWrapper>
      <PersonCardStyles.ArrowIconWrapper>
        <FontAwesomeIcon icon={regular('arrow-right')} size="lg" />
      </PersonCardStyles.ArrowIconWrapper>
    </PersonCardStyles.Wrapper>
  );
};
