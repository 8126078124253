import { useTranslation } from 'react-i18next';

import emptyInsights from 'assets/illustrations/empty-insights.svg';

import { ListScreenLayoutEmpty } from 'components/ListScreenLayout';

export const BuyerBusinessInsightsEmpty = ({
  businessName,
}: {
  businessName: string;
}) => {
  const { t } = useTranslation();
  return (
    <ListScreenLayoutEmpty.Wrapper data-testid="empty-insights">
      <img
        src={emptyInsights}
        alt={t('DealFlowEmptyInsights:title', {
          businessName,
        })}
      />
      <ListScreenLayoutEmpty.Title>
        {t('DealFlowEmptyInsights:title', {
          businessName,
        })}
      </ListScreenLayoutEmpty.Title>
      <ListScreenLayoutEmpty.Subtitle>
        {t('DealFlowEmptyInsights:subtitle')}
      </ListScreenLayoutEmpty.Subtitle>
    </ListScreenLayoutEmpty.Wrapper>
  );
};
