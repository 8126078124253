import { styled } from '@boopos/design-system';

const LayoutWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  backgroundColor: '$slate50',
  minHeight: '100vh',
});

const LayoutWrapperInner = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '128rem',
  margin: '0 auto',
  '@bp2': {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
});

export { LayoutWrapper, LayoutWrapperInner };
