import { StepContent } from './StepContent';
import { StepSubtitle } from './StepSubtitle';
import { StepTime } from './StepTime';
import { StepTitle } from './StepTitle';
import { StepWrapper } from './StepWrapper';

export const Timeline = {
  Step: StepWrapper,
  Time: StepTime,
  Title: StepTitle,
  Subtitle: StepSubtitle,
  Content: StepContent,
};
