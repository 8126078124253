import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContentCell } from 'ui/ContentCell';

import { BusinessPerformanceProps } from './BusinessPerformance.interface';
import { useBusinessPerformance } from './useBusinessPerformance';

export const BusinessPerformance = (props: BusinessPerformanceProps) => {
  const { t } = useTranslation();

  const { showIcon = true, editable = true } = props;

  const { contentCellProps, children, hasValue } =
    useBusinessPerformance(props);

  if (!editable && !hasValue) return null;

  return (
    <ContentCell
      title={t('ForSaleDetailApplication:business_performance')}
      showIcon={showIcon}
      {...contentCellProps}
    >
      {children}
    </ContentCell>
  );
};
