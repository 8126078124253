import { motion } from 'framer-motion';

import { css, styled } from '@boopos/design-system';

export const ConnectAccountModalStyles = {
  AccountsWrapper: styled('div', {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gridGap: '$4',
  }),
  WaitingWrapper: styled(motion.div, {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: '$white',
    position: 'absolute',
    inset: 0,
    zIndex: 1,
  }),
  WaitingContent: styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '25rem',
  }),
  WaitingText: styled('div', {
    my: '$4',
  }),
};

export const buttonClassName = css({
  cursor: 'pointer',
  border: 0,
  margin: 0,
  padding: 0,
  width: '100%',
  borderRadius: '1.6rem',
  background: '$white',
  px: '$4',
  py: '$6',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow:
    ' 0px 1px 4px rgba(0, 0, 0, 0.15), 0px 0px 4px rgba(0, 0, 0, 0.05)',
  '&[disabled]': {
    opacity: 0.5,
  },
  img: {
    height: '6.3rem',
  },
});
