import React from 'react';
import { Link } from 'react-router-dom';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { routePaths } from 'router/routePaths';

export const HeaderBackButton = () => {
  return (
    <Link to={routePaths.forSale}>
      <FontAwesomeIcon icon={regular('arrow-left-long')} size="lg" />
    </Link>
  );
};
