import { useCallback, useMemo } from 'react';
import Select from 'react-select';

import {
  DropdownFilterItemProps,
  DropdownFilterOption,
} from './DropdownFilter.interface';
import {
  Control,
  DropdownIndicator,
  Menu,
  Option,
  SingleValue,
  ValueContainer,
} from './DropdownFilter.partials';

export const DropdownFilterItem = ({
  onSelect,
  selected,
  options,
  filterName,
}: DropdownFilterItemProps) => {
  const handleOnSelect = useCallback(
    (option: DropdownFilterOption | null) => {
      if (!option) {
        return;
      }
      onSelect(option.value);
    },
    [onSelect]
  );

  const defaultValue = useMemo(
    () =>
      options.find((option: DropdownFilterOption) => option.value === selected),
    [options, selected]
  );

  return (
    <Select
      defaultValue={defaultValue}
      onChange={handleOnSelect as (option: unknown) => void}
      options={options}
      menuPortalTarget={document.body}
      isSearchable={false}
      isClearable={false}
      unstyled={true}
      components={{
        Control,
        ValueContainer,
        DropdownIndicator,
        Menu,
        Option,
        SingleValue: (props) => (
          <SingleValue {...props} filterName={filterName} />
        ),
      }}
    />
  );
};
