import React, { useCallback, useMemo } from 'react';
import { mutate } from 'swr';

import { ModuleByActivityName } from 'components/TimelineActivities';

import api from 'lib/api';
import { usePaginatedResponse } from 'lib/hooks/usePaginatedResponse';
import { IActivityApi } from 'lib/types';

import { DealTimelineProps } from './DealTimeline.interface';
import { knownActivities } from './DealTimeline.utils';
import { DealTimelineListing } from './DealTimelineListing';
import { DealTimelineNow } from './DealTimelineNow';

export const DealTimeline = ({ loan }: DealTimelineProps) => {
  const loanId = loan.id;

  const queryParams = useMemo(
    () => ({ loanId, nameIn: knownActivities.join(',') }),
    [loanId]
  );
  const { data, mutate: mutateActivity } = usePaginatedResponse<IActivityApi>(
    api.getLoanActivity,
    {
      queryParams,
    }
  );

  const onSuccess = useCallback(
    async (callback?: () => void) => {
      const { data: refreshedLoan } = await api.getLoanHttp(loanId);
      callback?.();
      setTimeout(async () => {
        await mutate(api.getLoan(loanId), refreshedLoan);
        mutateActivity().then();
      }, 1000);
    },
    [mutateActivity, loanId]
  );

  return (
    <>
      <DealTimelineNow
        loan={loan}
        onSuccess={onSuccess}
        isLast={!data.length}
      />
      {data.map((activity, index) => {
        const Component = ModuleByActivityName[activity.name];
        if (!Component) return null;
        return (
          <Component
            key={activity.name}
            date={activity.date}
            loan={loan}
            isLast={index === data.length - 1 && !loan?.listing}
          />
        );
      })}

      <DealTimelineListing listingId={loan?.listing?.id} />
    </>
  );
};
