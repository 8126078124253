import { useTranslation } from 'react-i18next';

import { styled } from '@boopos/design-system';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { StaticTooltip } from 'ui/Tooltip';

export const OuterPadding = styled('div', {
  mx: '$4',
  '@bp2': {
    mx: '$8',
  },
});

export const LayoutSidebarDetail = {
  PageWrapper: styled('div', {
    mx: '$4',
    '@bp2': {
      mx: '$8',
    },
  }),
  Wrapper: styled('div', {
    width: '100%',
    pb: '4rem',
    '@bp2': {
      display: 'grid',
      gridTemplateColumns: 'minmax(64rem, 72rem) 1fr',
      gridColumnGap: '4.8rem',
    },
  }),
  ListContent: styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  }),
  Content: styled('div', {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  }),
  Sidebar: styled('div', {
    paddingBottom: '9.6rem',
    width: '100%',
    '@bp2': {
      pb: 0,
    },
  }),
  ActionSidebar: styled('div', {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '1.6rem',
    background: '$backgroundBase',
    borderTop: '2px solid $borderBaseDefault',
    display: 'flex',
    gap: '1.6rem',
    flexWrap: 'nowrap',
    flexDirection: 'row-reverse',
    '*': {
      whiteSpace: 'nowrap',
    },
    'button, a': {
      textOverflow: 'ellipsis',
      width: '100%',
      maxWidth: '4.8rem',
      flex: 1,
    },
    '.sidebar-primary': {
      width: '100%',
      maxWidth: 'none',
    },
    '@bp2': {
      position: 'static',
      inset: 'auto',
      padding: 0,
      background: 'none',
      gap: '.8rem',
      border: 0,
      flexDirection: 'column',
      'button, a': {
        flex: 'auto',
        maxWidth: 'none',
      },
    },
  }),
  Divider: styled('div', {
    py: '1.6rem',
  }),
  ContentHeader: styled('h3', {
    color: '$textBodyTertiary',
    fontWeight: '$bold',
  }),
  ContentSubHeader: styled('div', {
    mb: '1rem',
  }),
  BadgeContainer: styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '.8rem',
  }),
  DateContainer: styled('div', {
    color: '$textBodySecondary',
    fontSize: '1.4rem',
    LineHeight: '2.4rem',
  }),
  ContentSubHint: styled('div', {
    mt: '.8rem',
    fontStyle: 'italic',
  }),
  ContentBlock: styled('div', {
    '&:not(:last-child)': {
      mb: '3.6rem',
    },
  }),
  ContentCell: styled('div', {
    p: '1.6rem',
    borderRadius: '1.6rem',
    background: '$surfaceBase',
  }),
  ContentGrid: styled('div', {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gap: '$4',
    '@bp2': {
      gridTemplateColumns: 'repeat(3, minmax(min-content, 1fr))',
    },
  }),
  ContentGridCell: styled('div', {
    py: '$4',
  }),
  ContentCellSubHeader: styled('div', {
    color: '$textBodyTertiary',
    fontSize: '1.4rem',
  }),
  ContentCellIconValue: styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '$2',
    svg: {
      color: '$infoStatic',
    },
  }),
  SidebarTitle: styled('h3', {
    color: '$textBodyTertiary',
    fontWeight: '$bold',
    mt: '2.4rem',
    mb: '.8rem',
  }),
  SidebarContent: styled('div', {
    color: '$textBodySecondary',
  }),
  SidebarHint: styled('div', {
    p: '1.6rem',
    color: '$textBodySecondary',
  }),
  SidebarHintTitle: styled('div', {
    color: '$textBodyPrimary',
    py: '.8rem',
  }),
};

export const LayoutSidebarDetailContent = {
  BigNumber: styled('div', {
    fontHeadingSerif: '$h3',
    color: '$textBodyPrimary',
  }),
  Hint: styled('div', {
    mt: '1.6rem',
    color: '$textBodySecondary',
  }),
};

const ConfidentialBadge = {
  Wrapper: styled('div', {
    mt: '2.4rem',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    gap: '.8rem',
    color: '$textStatePromotion',
    svg: {
      color: '$infoPositive',
    },
  }),
};

export const SecureAndConfidentialBadge = () => {
  const { t } = useTranslation();

  return (
    <>
      <ConfidentialBadge.Wrapper
        data-tooltip-id="secure-and-confidential-badge"
        data-tooltip-content={t('ForSaleDetailApplication:secure_tooltip')}
      >
        <FontAwesomeIcon icon={solid('shield-check')} />
        {t('ForSaleDetailApplication:secure_badge')}
      </ConfidentialBadge.Wrapper>
      <StaticTooltip id="secure-and-confidential-badge" place="top-start" />
    </>
  );
};
