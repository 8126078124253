const downloadFileBlob = async (
  blob: Blob,
  fileName: string,
  params?: { testId?: string }
) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  if (params?.testId) {
    link.setAttribute('data-testid', params?.testId);
  }
  document.body.appendChild(link);
  link.click();
};

export { downloadFileBlob };
