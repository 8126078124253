import api from 'lib/api';
import { IFile, IUploadedFile } from 'lib/types';

export const uploadFileToS3 = async ({
  file,
  fileName,
  params,
}: IFile): Promise<IUploadedFile> => {
  try {
    const { data } = await api.getUploadUrl({
      file: fileName,
      ...(params || {}),
    });
    const { uploadUrl, downloadUrl, url } = data;

    await api.http.put(uploadUrl, file);

    return {
      fileName,
      downloadUrl,
      url,
    };
  } catch (err: any) {
    throw new Error('Error uploading file', err);
  }
};
