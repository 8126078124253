import { styled } from '@boopos/design-system';

export const Breakdown = {
  Wrapper: styled('div', {
    maxWidth: '40rem',
    mt: '$2',
  }),
  Item: styled('div', {
    mb: '$2',
  }),
  Title: styled('div', {}),
  Description: styled('div', {}),
  Actions: styled('div', {
    mt: '$4',
  }),
};
