import copyToClipboard from 'copy-to-clipboard';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import isEmail from 'validator/lib/isEmail';

import {
  Box,
  ButtonFeedback,
  Input,
  Link,
  Text,
  createToastFeedback,
} from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal } from 'components/Modal';

import api from 'lib/api';
import { formatMoney } from 'lib/format';
import { MoneyFormat } from 'lib/format/money/formatMoney.interface';
import { getInputErrorProps } from 'lib/forms';
import { ILoanApi } from 'lib/types';

interface IShareSellingDealModal {
  opened: boolean;
  onClose: () => void;
  loan: ILoanApi;
}

interface IEmailForm {
  email: string;
}

export const ShareSellingDealModal = ({
  opened,
  onClose,
  loan,
}: IShareSellingDealModal) => {
  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState(false);
  const [copied, setCopied] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const loanId = loan.id;
  const businessName = loan?.business?.name;
  const formattedAmount = formatMoney(loan.amount, {
    format: MoneyFormat.long,
  });

  const { handleSubmit, register, formState, reset } = useForm<IEmailForm>();

  const handleClose = useCallback(() => {
    reset();
    onClose();
    setIsSuccess(false);
    setCopied(false);
    setShareUrl('');
  }, [reset, onClose]);

  const onSubmit = useCallback(
    async (data: IEmailForm) => {
      try {
        await api.shareSellingDeal(loanId, data.email);
        setIsSuccess(true);
        createToastFeedback({
          type: 'success',
          title: t('ShareSellingDealButton:sent_message'),
        });
        setTimeout(() => {
          handleClose();
        }, 1500);
      } catch (err) {
        createToastFeedback({
          type: 'error',
          title: t('ShareSellingDealButton:error_sending'),
        });
      }
    },
    [handleClose, t, loanId]
  );

  const copyShareLink = useCallback(async () => {
    copyToClipboard(shareUrl);
    setCopied(true);
    createToastFeedback({
      type: 'success',
      title: t('ShareSellingDealButton:copied_success'),
    });
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  }, [shareUrl, t]);

  const title = t('ShareSellingDealButton:share_title', { businessName });

  const handleEmailValidation = useCallback(
    (email: string) =>
      isEmail(email) || t('ShareSellingDealButton:email_invalid'),
    [t]
  );

  useEffect(() => {
    if (!opened || !loanId) {
      return;
    }

    const handleShareLinkGet = async () => {
      const { data } = await api.generateSellingDeal(loanId);
      setShareUrl(data.url);
    };

    handleShareLinkGet().then(() => {
      //noop
    });
  }, [loanId, opened]);

  return (
    <Modal
      opened={opened}
      onClose={handleClose}
      position="bottomSheetCentered"
      maxWidth={400}
      title={title}
    >
      <Box css={{ px: '$4', pb: '$6' }} data-testid="share-selling-deal-modal">
        <Box css={{ mb: '$6' }}>
          {t('ShareSellingDealButton:grant_access_message', {
            amount: formattedAmount,
          })}
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Input
              type="email"
              label={t('ShareSellingDealButton:email_label')}
              data-testid="email-input"
              {...register('email', {
                required: t('ShareSellingDealButton:email_required'),
                validate: handleEmailValidation,
              })}
              {...getInputErrorProps(formState?.errors?.email)}
            />
          </div>
          <ButtonFeedback
            isLoading={formState.isSubmitting}
            isSuccess={isSuccess}
            fullWidth
            variant="secondary"
            leftIcon={<FontAwesomeIcon icon={regular('envelope')} size="lg" />}
            data-testid="send-invitation-button"
          >
            {t('ShareSellingDealButton:send_button')}
          </ButtonFeedback>
        </form>
        <Box css={{ mt: '$6' }}>
          <Text css={{ mb: '$2' }}>
            {t('ShareSellingDealButton:share_link')}
          </Text>
          <Link
            css={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'block',
            }}
          >
            {shareUrl}
          </Link>
          <ButtonFeedback
            fullWidth
            isSuccess={copied}
            variant="secondary"
            rightIcon={<FontAwesomeIcon icon={regular('files')} size="lg" />}
            css={{ mt: '$4' }}
            onClick={copyShareLink}
            data-testid="copy-share-link-button"
          >
            {t('ShareSellingDealButton:copy_action')}
          </ButtonFeedback>
        </Box>
      </Box>
    </Modal>
  );
};
