import Skeleton from 'react-loading-skeleton';

import { Box } from '@boopos/design-system';

import { CardSection } from 'ui/CardSection';

import { LoadingStyles } from './ValuationLoading.styles';

export const ValuationLoading = () => {
  return (
    <CardSection data-testid="valuation-card-loading" css={{ mb: '3.6rem' }}>
      <Box>
        <Skeleton count={2} />
      </Box>
      <LoadingStyles.ColumnWrapper>
        <Box>
          <Skeleton count={1} height={161} borderRadius={8} />
        </Box>
        <Box>
          <Skeleton count={3} />
          <Skeleton width={200} />
        </Box>
      </LoadingStyles.ColumnWrapper>
    </CardSection>
  );
};
