import Skeleton from 'react-loading-skeleton';

import { Box } from '@boopos/design-system';

import { CreditReportForm, CreditReportFormProps } from './CreditReportForm';
import {
  UseQualifyCreditReportProps,
  useQualifyCreditReport,
} from './useQualifyCreditReport';

const CreditReportFormLoading = () => {
  return (
    <Box css={{ minHeight: 550, mt: '3.2rem' }}>
      <Skeleton width={100} borderRadius={8} />
      <Skeleton height={52} borderRadius={8} />
      <Box css={{ mt: '3.2rem' }} />
      <Skeleton width={120} borderRadius={8} />
      <Skeleton height={52} borderRadius={8} />
    </Box>
  );
};

export const CreditReportFormWithData = (
  props: Partial<CreditReportFormProps> &
    Partial<UseQualifyCreditReportProps> & { cache: boolean }
) => {
  const { parsedCreditReport, updateCreditReport } = useQualifyCreditReport({
    onSuccess: props.onSuccess,
    cache: props.cache,
  });

  if (!parsedCreditReport) {
    return <CreditReportFormLoading />;
  }

  return (
    <CreditReportForm
      defaultValues={parsedCreditReport}
      onSubmit={updateCreditReport}
      {...props}
    />
  );
};
