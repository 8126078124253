import { Merchants } from './AccountConnectors.interface';

export const merchantLiterals: { [key in Merchants]: string } = {
  [Merchants.amazon]: 'Amazon',
  [Merchants.stripe]: 'Stripe',
  [Merchants.paddle]: 'Paddle',
  [Merchants.shopify]: 'Shopify',
  [Merchants.woocommerce]: 'WooCommerce',
  [Merchants.bank]: 'Bank account',
  [Merchants.square]: 'Square',
  [Merchants.magento]: 'Magento',
  [Merchants.ebay]: 'eBay',
  [Merchants.xero]: 'Xero',
  [Merchants.sage]: 'Sage',
  [Merchants.quickbooks]: 'QuickBooks',
  [Merchants.zoho]: 'Zoho',
};

export const merchantLiteralsSubtitles: { [key in Merchants]?: string } = {
  [Merchants.bank]: '(Plaid)',
};
