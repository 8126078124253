import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { Button } from '@boopos/design-system';

import { ContentCell } from 'ui/ContentCell';

import { parseClosingChecklist } from 'components/DealClosingCheckList';

import {
  ClosingCheckListStepStatus,
  ClosingStepsNames,
  ILoanApi,
} from 'lib/types';

import { routePaths } from 'router/routePaths';

import { OptionalClosingStepName } from './ClosingChecklistDetails.interface';

const successStatuses = [
  ClosingCheckListStepStatus.waiting,
  ClosingCheckListStepStatus.verified,
];

const warningStatuses = [
  ClosingCheckListStepStatus.missingInfo,
  ClosingCheckListStepStatus.completed,
];

export const useStepTitle = (step: ClosingStepsNames): ReactNode => {
  const { t } = useTranslation();

  const titleLiterals: OptionalClosingStepName = {
    [ClosingStepsNames.letterOfIntent]: t(
      'DealTimelineStep:closing_letter_of_intent'
    ),
    [ClosingStepsNames.fundsBankAccount]: t(
      'DealTimelineStep:closing_funds_bank_account'
    ),
    [ClosingStepsNames.legalEntity]: t('DealTimelineStep:closing_legal_entity'),
    [ClosingStepsNames.shareholdersAndDirectors]: t(
      'DealTimelineStep:closing_shareholders_and_directors'
    ),
    [ClosingStepsNames.contractSigners]: t(
      'DealTimelineStep:closing_contract_signers'
    ),
    [ClosingStepsNames.revenueSources]: t(
      'DealTimelineStep:closing_revenue_sources'
    ),
    [ClosingStepsNames.ERPTools]: t('DealTimelineStep:closing_erp_tools'),
    [ClosingStepsNames.assetPurchaseAgreement]: t(
      'DealTimelineStep:closing_asset_purchase_agreement'
    ),
    [ClosingStepsNames.paymentMethod]: t(
      'DealTimelineStep:closing_payment_method'
    ),
    [ClosingStepsNames.revenueBankAccount]: t(
      'DealTimelineStep:revenue_bank_account'
    ),
  };

  return titleLiterals[step] ?? step;
};

export const useStepPath = (
  step: ClosingStepsNames,
  loanId: string
): string | undefined => {
  const stepPaths: OptionalClosingStepName = {
    [ClosingStepsNames.letterOfIntent]: routePaths.dealFlowLetterOfIntent,
    [ClosingStepsNames.fundsBankAccount]: routePaths.dealFlowFundsBankAccount,
    [ClosingStepsNames.legalEntity]: routePaths.dealFlowLegalEntity,
    [ClosingStepsNames.shareholdersAndDirectors]:
      routePaths.dealFlowShareHolders,
    [ClosingStepsNames.contractSigners]: routePaths.dealFlowSigners,
    [ClosingStepsNames.revenueSources]: routePaths.dealFlowRevenues,
    [ClosingStepsNames.ERPTools]: routePaths.dealFlowErp,
    [ClosingStepsNames.assetPurchaseAgreement]:
      routePaths.dealFlowAssetPurchaseAgreement,
    [ClosingStepsNames.paymentMethod]: routePaths.paymentMethod,
    [ClosingStepsNames.revenueBankAccount]:
      routePaths.dealFlowRevenueBankAccount,
  };

  const routePath = stepPaths[step];

  if (!routePath || !loanId) return undefined;

  return generatePath(routePath, { loanId });
};

export const useStepVariant = (step: ClosingStepsNames, loan: ILoanApi) => {
  const parsedClosingChecklist = parseClosingChecklist(loan?.closingChecklist);

  const status = parsedClosingChecklist[step]?.status;

  if (!status) return undefined;

  if (successStatuses.includes(status)) {
    return 'success';
  }

  if (warningStatuses.includes(status)) {
    return 'warning';
  }

  return undefined;
};

export const useStepDisabled = (step: ClosingStepsNames, loan: ILoanApi) => {
  const parsedClosingChecklist = parseClosingChecklist(loan?.closingChecklist);

  const status = parsedClosingChecklist[step]?.status;

  return status === ClosingCheckListStepStatus.disabled;
};

export const useStepAction = (
  step: ClosingStepsNames,
  loan: ILoanApi,
  isActive?: boolean
) => {
  const { t } = useTranslation();

  const uploadLiteral = t('DealTimelineStep:closing_upload');
  const connectLiteral = t('DealTimelineStep:closing_connect');
  const addLiteral = t('DealTimelineStep:closing_add');

  const actionLiterals: OptionalClosingStepName = {
    [ClosingStepsNames.letterOfIntent]: uploadLiteral,
    [ClosingStepsNames.revenueSources]: connectLiteral,
    [ClosingStepsNames.revenueBankAccount]: connectLiteral,
    [ClosingStepsNames.ERPTools]: connectLiteral,
    [ClosingStepsNames.assetPurchaseAgreement]: uploadLiteral,
  };

  const defaultActionLiteral = addLiteral;

  const parsedClosingChecklist = parseClosingChecklist(loan?.closingChecklist);

  const status = parsedClosingChecklist[step]?.status;

  if (!status) return null;

  if (status === ClosingCheckListStepStatus.verified) {
    return (
      <ContentCell.HintMessage>
        {t('DealTimelineStep:closing_verified')}
      </ContentCell.HintMessage>
    );
  }

  if (status === ClosingCheckListStepStatus.waiting) {
    return (
      <ContentCell.HintMessage>
        {t('DealTimelineStep:closing_in_review')}
      </ContentCell.HintMessage>
    );
  }

  if (status === ClosingCheckListStepStatus.missingInfo) {
    return (
      <ContentCell.HintMessage>
        {t('DealTimelineStep:needs_changes')}
      </ContentCell.HintMessage>
    );
  }

  if (!isActive) {
    return null;
  }

  return (
    <Button variant="secondary" tabIndex={-1}>
      {actionLiterals[step] ?? defaultActionLiteral}
    </Button>
  );
};
