import { styled } from '@boopos/design-system';

export const AnimationWrapper = styled('div', {
  width: '100%',
  position: 'fixed',
  zIndex: 9999,
  top: 0,
  bottom: 0,
  backgroundColor: '$backgroundBase',
  display: 'flex',
  alignItems: 'center',

  '& > div:first-child': {
    width: '30rem',
    margin: '0 auto',
  },
});
