import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input, Radio } from '@boopos/design-system';

import { CountrySelect } from 'components/CountrySelect';
import { USStateSelect } from 'components/USStateSelect';

import { getInputErrorProps } from 'lib/forms';
import { BankAccountType } from 'lib/types';

import { IFundsBankAccountData } from './FundsBankAccount.interface';
import { FormStyles } from './FundsBankAccount.styles';

interface Props {
  allFieldsDisabled?: boolean;
}

export const FundsBankAccountFields = ({ allFieldsDisabled }: Props) => {
  const { t } = useTranslation();

  const { register, formState, watch, control, setValue } =
    useFormContext<IFundsBankAccountData>();

  const isUS = watch('country') === 'US';

  const escrowTypeLabel = (
    <>
      <div>{t('FundsBankAccountModal:escrow_label')}</div>
      <FormStyles.RadioHelp>
        {t('FundsBankAccountModal:escrow_description')}
      </FormStyles.RadioHelp>
    </>
  ) as any; // This is to avoid changing the FSL Radio component

  useEffect(() => {
    if (!isUS) {
      setValue('state', '');
    }
  }, [isUS, setValue]);

  return (
    <>
      <FormStyles.BlockTitle>
        {t('FundsBankAccountModal:who_is_receiving')}
      </FormStyles.BlockTitle>

      <FormStyles.RadioWrapper>
        <Radio
          label={escrowTypeLabel}
          value={BankAccountType.escrow}
          id="account-type-escrow"
          disabled={allFieldsDisabled}
          {...register('accountType', { required: true })}
        />
      </FormStyles.RadioWrapper>
      <FormStyles.RadioWrapper>
        <Radio
          label={t('FundsBankAccountModal:seller_label')}
          value={BankAccountType.seller}
          id="account-type-seller"
          disabled={allFieldsDisabled}
          {...register('accountType', { required: true })}
        />
      </FormStyles.RadioWrapper>
      <FormStyles.RadioWrapper>
        <Radio
          label={t('FundsBankAccountModal:myself_label')}
          value={BankAccountType.buyer}
          id="account-type-buyer"
          disabled={allFieldsDisabled}
          {...register('accountType', { required: true })}
        />
      </FormStyles.RadioWrapper>

      <FormStyles.BlockTitle topPadded>
        {t('FundsBankAccountModal:beneficiary_details')}
      </FormStyles.BlockTitle>
      <Input
        label={t('FundsBankAccountModal:name_label')}
        disabled={allFieldsDisabled}
        id="beneficiary-name"
        {...register('accountHolderName', {
          required: true,
        })}
        {...getInputErrorProps(formState.errors?.accountHolderName)}
      />
      <Input
        label={t('FundsBankAccountModal:address_label')}
        disabled={allFieldsDisabled}
        id="beneficiary-address"
        {...register('accountHolderAddress', {
          required: true,
        })}
        {...getInputErrorProps(formState.errors?.accountHolderAddress)}
      />

      <FormStyles.BlockTitle topPadded>
        {t('FundsBankAccountModal:bank_details')}
      </FormStyles.BlockTitle>

      <Input
        label={t('FundsBankAccountModal:bank_name_label')}
        disabled={allFieldsDisabled}
        id="bank-name"
        {...register('bankName', {
          required: true,
        })}
        {...getInputErrorProps(formState.errors?.bankName)}
      />

      <CountrySelect
        name="country"
        inputId="fund-bank-account-country"
        control={control}
        disabled={allFieldsDisabled}
        rules={{ required: true }}
        {...getInputErrorProps(formState.errors?.country)}
      />

      {isUS && (
        <USStateSelect
          name="state"
          inputId="fund-bank-account-state"
          control={control}
          disabled={allFieldsDisabled}
          rules={{
            required: true,
            shouldUnregister: true,
          }}
          {...getInputErrorProps(formState.errors?.state)}
        />
      )}

      <Input
        label={t('FundsBankAccountModal:city_label')}
        disabled={allFieldsDisabled}
        id="bank-account-city"
        {...register('city', {
          required: true,
        })}
        {...getInputErrorProps(formState.errors?.city)}
      />

      <Input
        label={t('FundsBankAccountModal:account_number_label')}
        disabled={allFieldsDisabled}
        id="bank-account-number"
        {...register('accountNumber', {
          required: true,
        })}
        {...getInputErrorProps(formState.errors?.accountNumber)}
      />

      <Input
        label={
          isUS
            ? t('FundsBankAccountModal:routing_number_label_us')
            : t('FundsBankAccountModal:routing_number_label_non_us')
        }
        disabled={allFieldsDisabled}
        id="bank-account-routing-number"
        {...register('routingNumber', {
          required: true,
        })}
        {...getInputErrorProps(formState.errors?.routingNumber)}
      />
    </>
  );
};
