import { motion } from 'framer-motion';

import { Button, Link, styled } from '@boopos/design-system';

export const FilePreviewWrapper = styled(motion.div, {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '$slate100',
  border: '2px solid $slate100',
  py: '$2',
  pl: '$4',
  pr: '$2',
  ai: 'center',
  borderRadius: '.8rem',
  transition: 'all .2s ease-out',
  minHeight: '5.9rem',

  '&:not(:last-child)': {
    mb: '$4',
  },

  '&:hover': {
    backgroundColor: '$slate200',
    borderColor: '$black',
  },

  variants: {
    removeHover: {
      true: {
        backgroundColor: '$red50 !important',
        borderColor: '$red500 !important',

        '& a': {
          color: '$red600 !important',
        },
      },
    },
  },
});

export const FilePreviewName = styled('div', {
  flex: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const FilePreviewLink = styled(Link, {
  display: 'flex',
  gap: '$2',
  color: '$slate900',
  whiteSpace: 'nowrap',
  textDecoration: 'underline',

  variants: {
    removeHover: {
      true: {
        color: '$red600',
      },
    },
  },
});

export const FilePreviewIcon = styled('div', {
  lineHeight: 0,
});

export const FilePreviewNameText = styled('span', {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

export const RemoveFileButton = styled(Button, {
  border: 'none',
  borderRadius: '.8rem',
  display: 'flex',
  padding: '$2',
  background: 'transparent !important',
  transition: 'none',

  '& svg': {
    color: '$slate900',
  },

  '&:hover': {
    backgroundColor: 'red !important',

    '& svg': {
      color: '$white',
    },
  },
});
