import {
  ActionSideModal,
  ActionSideModalCloseHeader,
  ActionSideModalCommonStyles,
  ActionSideModalLoading,
} from 'components/ActionSideModal';

import { useAccountConnectionModal } from './AccountConnectionModal.hooks';
import { AccountConnectionModalProps } from './AccountConnectionModal.interface';
import {
  BottomNav,
  ConnectAccountModal,
  EmptyState,
  InvitationsSummary,
  SendInvitationModal,
} from './partials';
import { SelfConnectedAccountsSummary } from './partials/SelfConnectedAccountsSummary';

export const AccountConnectionModal = (props: AccountConnectionModalProps) => {
  const { copies, onClose, loanId, accountType } = props;

  const {
    openConnectModal,
    businessInvitations,
    sellerConnectedAccounts,
    selfConnectedAccounts,
    connectModalOpened,
    closeConnectModal,
    inviteModalOpened,
    openInviteModal,
    closeInviteModal,
    closeWatcher,
    businessId,
    ready,
    onInvitationSent,
    onAccountConnected,
    hasInvitations,
    isEmpty,
    onDeleteAccount,
    isVerified,
  } = useAccountConnectionModal({ loanId, accountType });

  const handleClose = closeWatcher(onClose);

  const NotReadyContent = <ActionSideModalLoading />;

  const ReadyContent = (
    <>
      {isEmpty && (
        <EmptyState
          inviteTitle={copies.inviteTitle}
          inviteDescription={copies.inviteDescription}
          inviteButtonText={copies.sendInvite}
          onInvite={openInviteModal}
          connectTitle={copies.connectTitle}
          connectDescription={copies.connectDescription}
          connectButtonText={copies.connectMySelf}
          onConnect={openConnectModal}
        />
      )}
      <SelfConnectedAccountsSummary
        accounts={selfConnectedAccounts}
        title={copies.selfConnectedTitle}
        onConnect={openConnectModal}
        buttonText={copies.connectAnother}
        onDeleteAccount={onDeleteAccount}
        allowEdit={!isVerified}
      />
      <InvitationsSummary
        accountsTitle={copies.connectedBySellerTitle}
        title={copies.invitationsTitle}
        buttonText={copies.sendAnotherInvite}
        onInvite={openInviteModal}
        invitations={businessInvitations}
        accounts={sellerConnectedAccounts}
        onDeleteAccount={onDeleteAccount}
        allowEdit={!isVerified}
      />
    </>
  );

  return (
    <ActionSideModal
      header={<ActionSideModalCloseHeader onClose={handleClose} />}
      onClose={handleClose}
    >
      <ActionSideModalCommonStyles.Title data-testid="revenue-sources-modal-title">
        {copies.mainModalTitle}
      </ActionSideModalCommonStyles.Title>
      <ActionSideModalCommonStyles.Subtitle>
        {copies.mainModalSubtitle}
      </ActionSideModalCommonStyles.Subtitle>

      {ready ? ReadyContent : NotReadyContent}

      {!isEmpty && (
        <BottomNav
          isVerified={isVerified}
          hasInvitations={hasInvitations}
          hasSelfConnected={!!selfConnectedAccounts?.length}
          onContinue={onClose}
        />
      )}

      {!!businessId && (
        <SendInvitationModal
          title={copies.inviteModalTitle}
          businessId={businessId}
          description={copies.inviteModalDescription}
          visible={inviteModalOpened}
          onClose={closeInviteModal}
          onSuccess={onInvitationSent}
          accountType={accountType}
        />
      )}

      {!!businessId && (
        <ConnectAccountModal
          title={copies.connectModalTitle}
          visible={connectModalOpened}
          businessId={businessId}
          onSuccess={onAccountConnected}
          onClose={closeConnectModal}
          merchantType={accountType}
        />
      )}
    </ActionSideModal>
  );
};
