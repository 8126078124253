import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import useSWR from 'swr';

import { usePersistenceAtom } from 'components/ClosingChecklistPersistence';
import { useClosingStepMatchStatus } from 'components/DealClosingCheckList';

import Api from 'lib/api';
import {
  ClosingCheckListStepStatus,
  ClosingStepsNames,
  ILoanApi,
} from 'lib/types';

import { contractSignersAtom } from './ContractSignersModal.atoms';
import {
  ContractSignersData,
  IUseContractSigners,
  IUseContractSignersReturn,
} from './ContractSignersModal.interface';
import { parseFromApi } from './ContractSignersModal.utils';

export const useContractSigners = ({
  loanId,
  onSuccess,
}: IUseContractSigners): IUseContractSignersReturn => {
  const [success, setSuccess] = useState(false);
  const { data: loan, mutate: refreshLoan } = useSWR<ILoanApi>(
    Api.getLoan(loanId)
  );

  const isWaitingForReview = useClosingStepMatchStatus({
    loan,
    status: ClosingCheckListStepStatus.waiting,
    name: ClosingStepsNames.contractSigners,
  });

  const isVerified = useClosingStepMatchStatus({
    loan,
    status: ClosingCheckListStepStatus.verified,
    name: ClosingStepsNames.contractSigners,
  });

  const people = loan?.contractPeople || [];

  const {
    value: contractSignersData,
    setValue: setContractSignersData,
    reset: resetContractSignersData,
  } = usePersistenceAtom<ContractSignersData>(contractSignersAtom, loanId);

  const signerIds = useMemo(() => {
    const parsed = parseFromApi(loan?.contractPeople);
    if (!parsed?.signerIds?.length) return undefined;
    return parsed.signerIds;
  }, [loan?.contractPeople]);

  const formMethods = useForm<ContractSignersData>({
    defaultValues: signerIds ? { signerIds } : contractSignersData,
  });

  const { watch } = formMethods;

  const onDelete = useCallback(async () => {
    await Api.deleteContractSigners(loanId);
    if (signerIds) setContractSignersData({ signerIds });
    setTimeout(() => {
      refreshLoan();
    }, 1000);
  }, [refreshLoan, loanId, signerIds, setContractSignersData]);

  const onSubmit = useCallback(
    async (data: ContractSignersData) => {
      try {
        await Api.updateContractSigners(loanId, data);
        resetContractSignersData();
        setSuccess(true);

        setTimeout(() => {
          refreshLoan();
          onSuccess?.();
        }, 1000);
      } catch (e) {
        console.error(e);
      }
    },
    [loanId, refreshLoan, onSuccess, resetContractSignersData]
  );

  const onChange = useCallback(
    (data: ContractSignersData) => {
      if (signerIds) return;
      setContractSignersData(data);
    },
    [setContractSignersData, signerIds]
  );

  useEffect(() => {
    const subscription = watch((value) =>
      onChange(value as ContractSignersData)
    );
    return () => subscription.unsubscribe();
  }, [onChange, watch]);

  return {
    isWaitingForReview,
    isVerified,
    isSubmitting: formMethods.formState.isSubmitting,
    isSuccess: success,
    people,
    formMethods,
    onSubmit,
    onDelete,
    onChange,
  };
};
