import qs from 'query-string';
import { Navigate, generatePath, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { FullScreenLoader } from 'components/FullScreenLoader';

import api from 'lib/api';

import { routePaths } from 'router/routePaths';

import { IGetBrokerListing } from './BrokerLandingScreen.interface';

const NotFoundListingFallback = ({
  broker,
  brokerListingId,
}: {
  broker: string;
  brokerListingId: string;
}) => {
  const params = {
    utm_source: broker,
    utm_content: brokerListingId,
    utm_medium: 'broker',
    missing_broker_listing: true,
  };

  return (
    <Navigate
      to={{ pathname: routePaths.forSale, search: qs.stringify(params) }}
    />
  );
};

export const BrokerLandingScreen = () => {
  const { broker, brokerListingId } = useParams<{
    broker: string;
    brokerListingId: string;
  }>();

  const { data, error } = useSWR<IGetBrokerListing>(
    broker && brokerListingId && api.getBrokerListing(broker, brokerListingId)
  );

  if (!broker || !brokerListingId || error) {
    return <Navigate to={routePaths.forSale} />;
  }

  if (!data) {
    return <FullScreenLoader data-testid="broker-landing-loading" />;
  }

  if (!data?.listingId) {
    return (
      <NotFoundListingFallback
        brokerListingId={brokerListingId}
        broker={broker}
      />
    );
  }

  const params = {
    utm_source: broker,
    utm_content: brokerListingId,
    utm_medium: 'broker',
  };

  const listingPath = generatePath(routePaths.forSaleDetail, {
    listingId: data?.listingId,
  });

  return (
    <Navigate to={{ pathname: listingPath, search: qs.stringify(params) }} />
  );
};
