import { atomWithStorage } from 'jotai/utils';

import {
  BasePersistenceAtom,
  getPersistenceKey,
} from 'components/ClosingChecklistPersistence';

import { IShareholdersAndDirectorsData } from './ShareholdersAndDirectors.interface';

export const shareHoldersAndDirectorsAtom = atomWithStorage<
  BasePersistenceAtom<IShareholdersAndDirectorsData>
>(getPersistenceKey('shareholdersAndDirectors'), {});
