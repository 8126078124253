import { ICategoriesApi } from 'lib/types';

export const parseCategoriesToApi = (data: ICategoriesApi) =>
  Object.keys(data || {}).reduce((acc: any, key) => {
    const _acc = acc;
    const isEmpty = data[key] !== undefined && data[key]?.length;

    if (isEmpty) {
      _acc[key] = data[key];
    }

    return _acc;
  }, {});
