import * as Sentry from '@sentry/react';

import config from 'lib/config';

const missingKeyHandler = (
  lngs: readonly string[],
  ns: string,
  key: string,
  fallbackValue: string
) => {
  if (config.ENVIRONMENT === 'local') {
    return;
  }

  Sentry.captureMessage(`Missing translation`, {
    level: 'error',
    extra: {
      lngs,
      ns,
      key,
      fallbackValue,
    },
  });
};

const getLocaleFile = async (
  lng: string,
  ns: string,
  callback: (...args: any[]) => void
) => {
  import(`/public/locales/${lng}/${ns}.json`)
    .then((resources) => {
      callback(null, resources);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { missingKeyHandler, getLocaleFile };
