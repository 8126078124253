import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Input, Text } from '@boopos/design-system';

import ShopifyApiKey from 'assets/shopifyprivate-apikey.png';
import ShopifyRevealToken from 'assets/shopifyprivate-revealtoken.png';

import { ConnectionStepWrapper } from 'components/AccountConnectionLayout';
import {
  ExplainerImage,
  FlexWrapper,
  HighlightedText,
  LinkLike,
  StepContent,
  StepItem,
  StepLabel,
} from 'components/AccountConnectors/AccountConnectorsSteps';
import { createShopifyLoginUrl } from 'components/AccountConnectors/createShopifyLoginUrl';

import { IConnectShopifyStepTwo } from './ConnectShopifyStepTwo.interface';

const ConnectShopifyStepTwo: FC<IConnectShopifyStepTwo> = ({
  storeName,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm<{
    apiToken: string;
    apiKey: string;
  }>();

  return (
    <ConnectionStepWrapper
      css={{ maxWidth: '60rem', minHeight: '100vh' }}
      data-testid="connect-step-2"
    >
      <StepItem>
        <StepLabel>1</StepLabel>
        <StepContent>
          <LinkLike href={createShopifyLoginUrl(storeName)} target="_blank">
            {t('ConnectShopifyScreen:click_custom_app_development')}
          </LinkLike>
          <Text css={{ fontSize: '$2', opacity: 0.85 }}>
            {t('ConnectShopifyScreen:custom_app_development')}
          </Text>
        </StepContent>
      </StepItem>
      <StepItem>
        <StepLabel>2</StepLabel>
        <StepContent>
          <Text>{t('ConnectShopifyScreen:click_create')}</Text>
        </StepContent>
      </StepItem>
      <StepItem>
        <StepLabel>3</StepLabel>
        <StepContent>
          <Text>{t('ConnectShopifyScreen:add_values')}</Text>
          <FlexWrapper>
            <Text>{t('ConnectShopifyScreen:app_name')}</Text>
            <HighlightedText>
              {t('ConnectShopifyScreen:boopos')}
            </HighlightedText>
          </FlexWrapper>
        </StepContent>
      </StepItem>
      <StepItem>
        <StepLabel>4</StepLabel>
        <StepContent>
          <Text as="span">{t('ConnectShopifyScreen:click')}</Text>
          <Text bold as="span">
            {t('ConnectShopifyScreen:configure_title')}
          </Text>
          <Text as="span">{t('ConnectShopifyScreen:add_permissions')}</Text>
          <FlexWrapper>
            <Text>{t('ConnectShopifyScreen:products')}</Text>
            <HighlightedText>
              {t('ConnectShopifyScreen:read_access')}
            </HighlightedText>
          </FlexWrapper>
          <FlexWrapper>
            <Text>{t('ConnectShopifyScreen:orders')}</Text>
            <HighlightedText>
              {t('ConnectShopifyScreen:read_access')}
            </HighlightedText>
          </FlexWrapper>
          <FlexWrapper>
            <Text>{t('ConnectShopifyScreen:customers')}</Text>
            <HighlightedText>
              {t('ConnectShopifyScreen:read_access')}
            </HighlightedText>
          </FlexWrapper>
        </StepContent>
      </StepItem>
      <StepItem>
        <StepLabel>5</StepLabel>
        <StepContent>
          <Text>{t('ConnectShopifyScreen:click_save')}</Text>
        </StepContent>
      </StepItem>
      <StepItem>
        <StepLabel>6</StepLabel>
        <StepContent>
          <Text>{t('ConnectShopifyScreen:click_install')}</Text>
        </StepContent>
      </StepItem>
      <StepItem>
        <StepLabel>7</StepLabel>
        <StepContent>
          <Text>{t('ConnectShopifyScreen:reveal_tokens')}</Text>
        </StepContent>
      </StepItem>
      <StepItem>
        <ExplainerImage src={ShopifyRevealToken} alt="" />
      </StepItem>
      <StepItem>
        <ExplainerImage src={ShopifyApiKey} alt="" />
      </StepItem>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="connect-step-2-form">
        <Input
          {...register('apiToken')}
          type="password"
          placeholder="*************"
          label={t('ConnectShopifyScreen:access_token_label')}
          required
          fullWidth
          data-testid="api-token-field"
        />

        <Input
          {...register('apiKey')}
          type="text"
          placeholder="111114e91e382357bc61b5e7067edd39"
          label={t('ConnectShopifyScreen:api_key_label')}
          required
          fullWidth
          data-testid="api-key-field"
        />
        <Button fullWidth>{t('ConnectShopifyScreen:finish')}</Button>
      </form>
    </ConnectionStepWrapper>
  );
};

export { ConnectShopifyStepTwo };
