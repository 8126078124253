import { forwardRef } from 'react';
import {
  ControlProps,
  DropdownIndicatorProps,
  MenuProps,
  OptionProps,
  ValueContainerProps,
} from 'react-select';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SingleValueProps } from './DropdownFilter.interface';
import {
  ControlWrapper,
  DropdownIndicatorWrapper,
  MenuWrapper,
  OptionWrapper,
  SingleValueWrapper,
  ValueContainerWrapper,
} from './DropdownFilter.styles';

export const Control = forwardRef<HTMLDivElement, ControlProps>((props) => {
  const { innerProps, innerRef, children, isFocused, selectProps } = props;

  return (
    <ControlWrapper
      ref={innerRef as any}
      {...innerProps}
      isFocused={isFocused}
      menuIsOpen={selectProps.menuIsOpen}
    >
      {children}
    </ControlWrapper>
  );
});

export const ValueContainer = (props: ValueContainerProps) => {
  const { children } = props;
  return <ValueContainerWrapper>{children}</ValueContainerWrapper>;
};

export const SingleValue = (props: SingleValueProps) => {
  const { children } = props;

  return (
    <>
      {props.filterName}
      <SingleValueWrapper>{children}</SingleValueWrapper>
    </>
  );
};

export const DropdownIndicator = (props: DropdownIndicatorProps) => {
  const { innerProps, selectProps } = props;

  return (
    <DropdownIndicatorWrapper
      {...(innerProps as any)}
      menuIsOpen={selectProps.menuIsOpen}
    >
      <FontAwesomeIcon icon={regular('chevron-down')} size="lg" />
    </DropdownIndicatorWrapper>
  );
};

export const Menu = (props: MenuProps) => {
  const { innerProps, innerRef, children } = props;

  return (
    <MenuWrapper ref={innerRef as any} {...innerProps}>
      {children}
    </MenuWrapper>
  );
};

export const Option = (props: OptionProps) => {
  const { innerProps, innerRef, children } = props;

  return (
    <OptionWrapper ref={innerRef as any} {...props} {...innerProps}>
      {children}
    </OptionWrapper>
  );
};
