import React from 'react';
import { useTranslation } from 'react-i18next';

import { DealTimelineStep } from 'components/DealTimelineStep';
import { SellerTypeRender } from 'components/ListingDetail/SellerTypeRender';
import { TermSheetHandler } from 'components/TermSheetHandler';

import { ListingForSale, ListingSellerType } from 'lib/types';

import { ListingUnlocked } from './ListingUnlocked';

export const shouldShowDealUnlocked = (listing: ListingForSale) => {
  return !!listing?.dealApplication?.grantedAt;
};

const useSubtitle = (listing: ListingForSale) => {
  const { t } = useTranslation();

  if (listing?.sellerType === ListingSellerType.proprietary) {
    return t('ForSaleTimeline:deal_unlocked_subtitle_proprietary');
  }

  return (
    <TermSheetHandler termSheet={listing.termSheet?.value}>
      <TermSheetHandler.Base>
        <div>{t('ForSaleTimeline:deal_unlocked_subtitle')}</div>
        <div>{t('ForSaleTimeline:deal_unlocked_subtitle_terms')}</div>
      </TermSheetHandler.Base>
      <TermSheetHandler.External>
        <div>{t('ForSaleTimeline:deal_unlocked_subtitle')}</div>
      </TermSheetHandler.External>
      <TermSheetHandler.Empty>
        <div>{t('ForSaleTimeline:deal_unlocked_subtitle')}</div>
      </TermSheetHandler.Empty>
    </TermSheetHandler>
  );
};

export const DealUnlocked = ({ listing }: { listing: ListingForSale }) => {
  const { t } = useTranslation();

  const subtitle = useSubtitle(listing);

  if (!shouldShowDealUnlocked(listing)) {
    return null;
  }

  const title = (
    <>
      <SellerTypeRender.Broker sellerType={listing?.sellerType}>
        {t('ForSaleTimeline:deal_unlocked_title')}
      </SellerTypeRender.Broker>
      <SellerTypeRender.Proprietary sellerType={listing?.sellerType}>
        {t('ForSaleTimeline:deal_unlocked_title_proprietary')}
      </SellerTypeRender.Proprietary>
    </>
  );

  return (
    <DealTimelineStep
      title={title}
      subtitle={subtitle}
      date={listing.dealApplication?.grantedAt}
      data-testid="deal-unlocked-activity"
      forceNowVariant
    >
      <ListingUnlocked listing={listing} />
    </DealTimelineStep>
  );
};
