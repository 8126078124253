import { useTranslation } from 'react-i18next';

import { Badge } from 'ui/Badge';

import { LoanStatusBadge } from 'components/LoanStatusBadge';

import { ILoanApi, LoanStatusTags } from 'lib/types';

export const LoanBadges = ({
  hasInsights,
  loans,
  listedForSale,
}: {
  hasInsights?: boolean;
  listedForSale?: boolean;
  loans?: ILoanApi[];
}) => {
  const { t } = useTranslation();

  const numberOfLoans = loans?.length ?? 0;
  const loansBadgeColor = numberOfLoans > 0 ? 'blue' : 'neutral';
  const testId = numberOfLoans > 0 ? 'loans-badge' : 'zero-loans-badge';

  return (
    <>
      {listedForSale && (
        <LoanStatusBadge status={LoanStatusTags.listedForSale} />
      )}
      {hasInsights && (
        <Badge variant="pink" data-testid="insights-loan-badge">
          {t('BusinessCard:valuation_and_insights')}
        </Badge>
      )}
      {!!numberOfLoans && (
        <Badge variant={loansBadgeColor} data-testid={testId}>
          {t('BusinessCard:number_of_loans', { count: numberOfLoans })}
        </Badge>
      )}
    </>
  );
};
