import { useCallback, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, generatePath, useParams } from 'react-router-dom';

import { Button, InlineFeedback, Text } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CenteredContentScreen } from 'ui/CenteredContentScreen';

import { BackButton } from 'components/BackButton';
import { FullScreenLoader } from 'components/FullScreenLoader';
import { AnimatedScreenOpacityWrapper } from 'components/ScreenLayout';

import { routePaths } from 'router/routePaths';

import { BusinessIntegrations } from 'screens/BusinessIntegrationsScreen/BusinessIntegrations';

import { BoxesWrapper } from './ConnectAccountInvitationScreen.styles';
import { UnauthorizedError } from './UnauthorizedError';
import { useConnectAccountInvitation } from './useConnectAccountInvitation';

export const ConnectAccountInvitationScreen = () => {
  const { t } = useTranslation();
  const { businessId, token } = useParams();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const {
    isLoading,
    isUnauthorized,
    userName,
    businessName,
    businessAccounts,
    refreshBusinessAccounts,
    invitationAccountType,
  } = useConnectAccountInvitation({ businessId });

  const connectAccountsPath =
    generatePath(routePaths.invitationConnectAccountsAdd, {
      businessId,
      token,
    }) + window.location.search;

  const handleOnRemove = useCallback(refreshBusinessAccounts, [
    refreshBusinessAccounts,
  ]);

  const screenTitle = t('ConnectAccountInvitationScreen:title');

  if (isUnauthorized) {
    return <UnauthorizedError />;
  }

  if (isLoading) {
    return <FullScreenLoader data-testid="loading" />;
  }

  return (
    <>
      <Helmet>
        <title>{screenTitle}</title>
      </Helmet>
      <AnimatedScreenOpacityWrapper>
        <CenteredContentScreen.Wrapper>
          <CenteredContentScreen.Content>
            <BackButton
              to={routePaths.base}
              hideOnDesktop={false}
              topSpace
              Icon={<FontAwesomeIcon icon={regular('xmark')} size="lg" />}
            />
            <CenteredContentScreen.Title>
              {screenTitle}
            </CenteredContentScreen.Title>
            <CenteredContentScreen.Description data-testid="description">
              {t('ConnectAccountInvitationScreen:description', {
                userName,
                businessName,
              })}
            </CenteredContentScreen.Description>
            <BoxesWrapper>
              <BusinessIntegrations
                businessId={businessId as string}
                addIntegrationPath={generatePath(
                  routePaths.invitationConnectAccountsAdd,
                  {
                    businessId,
                    token,
                  }
                )}
                accounts={businessAccounts}
                onRemove={handleOnRemove}
                hideHeader
                wrapperCss={{
                  px: 0,
                }}
                cardProps={{
                  css: {
                    gridColumn: 'span 12',
                  },
                }}
              />
            </BoxesWrapper>
            <InlineFeedback
              css={{ mb: '$8' }}
              variant="info"
              expandable
              heading={t('ConnectAccountInvitationScreen:explanation_title')}
            >
              <Text css={{ mb: '$4' }}>
                {t(
                  'ConnectAccountInvitationScreen:explanation_first_paragraph'
                )}
              </Text>
              <Text>
                {t(
                  'ConnectAccountInvitationScreen:explanation_seconds_paragraph'
                )}
              </Text>
            </InlineFeedback>
            <Button
              as={Link}
              to={connectAccountsPath}
              state={{ invitationAccountType }}
              fullWidth
              ref={buttonRef}
            >
              {t('ConnectAccountInvitationScreen:connect_button')}
            </Button>
          </CenteredContentScreen.Content>
        </CenteredContentScreen.Wrapper>
      </AnimatedScreenOpacityWrapper>
    </>
  );
};
