import { startTransition, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Success from 'assets/illustrations/success.svg';

import { Modal } from 'components/Modal';

import { useMeetingUrls } from 'lib/meetings';

import { routePaths } from 'router/routePaths';

import {
  ButtonsWrapper,
  ContentWrapper,
  IllustrationBg,
} from './QualificationApprovedModal.styles';
import { useQualificationApprovedModal } from './useQualificationApprovedModal';

export const QualificationApprovedModal = () => {
  const { t } = useTranslation();
  const { opened, closeModal } = useQualificationApprovedModal();
  const navigate = useNavigate();

  const { qualificationMeetingUrl } = useMeetingUrls();

  const onSeeListingsClick = useCallback(() => {
    closeModal();
    startTransition(() => {
      navigate(routePaths.forSale);
    });
  }, [closeModal, navigate]);

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      position="centered"
      maxWidth={340}
      title={t('QualificationScreen:have_been_qualified')}
    >
      <Box css={{ px: '$4' }}>
        <IllustrationBg>
          <img
            src={Success}
            alt={t('QualificationScreen:have_been_qualified')}
          />
        </IllustrationBg>
        <ContentWrapper>
          {t('QualificationScreen:qualified_success_message')}
        </ContentWrapper>
        <ButtonsWrapper>
          <Button
            leftIcon={<FontAwesomeIcon icon={regular('comment-dots')} />}
            fullWidth
            as="a"
            href={qualificationMeetingUrl}
            target="_blank"
          >
            {t('QualificationScreen:book_meeting')}
          </Button>
          <Button fullWidth variant="secondary" onClick={onSeeListingsClick}>
            {t('QualificationScreen:see_business_action')}
          </Button>
        </ButtonsWrapper>
      </Box>
    </Modal>
  );
};
