import { AnimatePresence } from 'framer-motion';
import { Outlet } from 'react-router-dom';

import { OnboardingBackgroundPattern } from 'components/OnboardingLayout';

import { useRoutePathMatches } from 'lib/utils/useRoutePathMatches';

import { routePaths } from 'router/routePaths';

const BACKGROUND_PATTERN_WHITELIST: string[] = [
  routePaths.onboarding,
  routePaths.onboardingBuyTarget,
  routePaths.onboardingProfileBuy,
  routePaths.onboardingProfileSell,
  routePaths.onboardingSellerApplication.index,
  routePaths.onboardingSellerApplication.applicationFinished,
  routePaths.portfolioApplication.applicationFinished,
];

export const OnboardingOutlet = () => {
  const showBackgroundPattern = useRoutePathMatches(
    BACKGROUND_PATTERN_WHITELIST
  );

  return (
    <>
      <Outlet />
      <AnimatePresence>
        {showBackgroundPattern && <OnboardingBackgroundPattern />}
      </AnimatePresence>
    </>
  );
};
