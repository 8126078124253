import { useMemo, useState } from 'react';
import { UseFormWatch } from 'react-hook-form';
import { useDebounce } from 'react-use';
import useSWR from 'swr';

import { safeStringify } from 'components/DealTimeline/SubmitForEvaluation/SubmitForEvaluation.utils';

import { apiV2 } from 'lib/api';

import {
  IApiForSaleFilters,
  IForSaleFiltersForm,
} from './ForSaleFilters.interface';
import { parseForSaleFiltersToApi } from './ForSaleFilters.utils';

interface ForSaleTotalsResponse {
  totalDocs?: number;
}

export const useForSaleTotals = (watch: UseFormWatch<IForSaleFiltersForm>) => {
  const values = watch();
  const [filtersForPreview, setFiltersForPreview] =
    useState<IApiForSaleFilters | null>(null);

  const stringValues = useMemo(() => safeStringify(values), [values]);

  const { data } = useSWR<ForSaleTotalsResponse>(
    filtersForPreview && apiV2.getListings(filtersForPreview),
    {
      fetcher: apiV2.filterListingsPost.bind(apiV2),
    }
  );

  useDebounce(
    () => {
      setFiltersForPreview(parseForSaleFiltersToApi(values));
    },
    500,
    [stringValues]
  );

  return {
    total: data?.totalDocs,
  };
};
