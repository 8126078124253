import { ReactNode } from 'react';

import { BooposAnimatedLogoHandler } from 'components/BooposAnimatedLogoHandler';

import { AnalyticsPageViewHandler } from 'lib/analytics';
import { HSIdentify, HSPageView, HSWidgetPosition } from 'lib/hubspot';
import { SentryUserHandler } from 'lib/sentry';

import { AccessTokenProvider } from 'providers/AccessTokenProvider';
import { UserProvider } from 'providers/UserProvider';

export const AuthenticatedRoutesProvider = ({
  children,
}: {
  children: ReactNode;
}) => (
  <AccessTokenProvider>
    <UserProvider>
      <AnalyticsPageViewHandler />
      <HSIdentify />
      <HSPageView />
      <HSWidgetPosition />
      <BooposAnimatedLogoHandler />
      <SentryUserHandler />
      {children}
    </UserProvider>
  </AccessTokenProvider>
);
