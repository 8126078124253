import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { Input } from '@boopos/design-system';
import { requiredWithTrim, validUrl } from '@boopos/utils';

import { SelectHookForm } from 'components/SelectHookForm';

import api from 'lib/api';
import { mapBusinessTypesForSelect, otherCategory } from 'lib/businessType';
import { getInputErrorProps } from 'lib/forms';

export const BusinessForm = ({ copies }: any) => {
  const { t } = useTranslation();
  const { data: categories } = useSWR(api.getCategories());
  const { register, formState, control, watch } = useFormContext();

  const businessTypesForSelect = mapBusinessTypesForSelect(categories);
  const watchedType = watch('businessType');
  const { nameLabel, websiteLabel, typeLabel } = copies;

  return (
    <>
      <Input
        label={nameLabel}
        autoFocus
        {...register('name', {
          validate: requiredWithTrim(
            t('AddBusinessForm:business_required_error')
          ),
        })}
        {...getInputErrorProps(formState?.errors?.name)}
        data-testid="name-input"
      />
      <SelectHookForm
        label={typeLabel}
        name="businessType"
        control={control}
        rules={{
          required: t('AddBusinessForm:business_type_required_error'),
        }}
        options={businessTypesForSelect}
        value={businessTypesForSelect.find(
          ({ value }) => value === watchedType
        )}
        inputId="business-type-id"
        {...getInputErrorProps(formState.errors?.businessType)}
      />
      {watchedType === otherCategory && (
        <Input
          placeholder={t('AddBusinessForm:type_of_business')}
          {...register('businessTypeOther', {
            required: t('AddBusinessForm:business_type_required'),
            shouldUnregister: true,
          })}
          {...getInputErrorProps(formState.errors?.businessTypeOther)}
          data-testid="business-types-other-input"
        />
      )}
      <Input
        label={websiteLabel}
        {...register('url', {
          required: t('AddBusinessForm:website_required_error'),
          validate: (v) => validUrl(v) || t('AddBusinessForm:url_error'),
        })}
        {...getInputErrorProps(formState?.errors?.url)}
        data-testid="url-input"
        groupSpacing="xl"
      />
    </>
  );
};
