import { IApiDocument } from 'lib/types';

import { getCurrentDocumentsFilteringType } from './getCurrentDocumentsFilteringType';
import { getCurrentOrEmptyDocument } from './getDocumentFiles';

export const deleteFileFromCurrentDocuments = (
  documents: IApiDocument[] | undefined,
  documentType: string,
  fileId: string
) => {
  const currentDocuments = getCurrentDocumentsFilteringType(
    documents,
    documentType
  );

  const currentDocument = getCurrentOrEmptyDocument(documentType, documents);

  return [
    ...currentDocuments,
    {
      ...currentDocument,
      files: currentDocument.files.filter((file) => file.id !== fileId),
    },
  ];
};
