import { useAtom } from 'jotai';
import { PropsWithChildren, ReactNode } from 'react';

import { SlateBackgroundBody } from 'components/GlobalStyles';
import { MainMenu } from 'components/MainMenu';

import { mainMenuHiddenAtom } from './Layout.atoms';
import { LayoutContent } from './LayoutContent.styled';
import { LayoutWrapperInner } from './LayoutWrapper.styled';

const Layout = ({
  children,
  layoutBanner,
}: PropsWithChildren<{ layoutBanner?: ReactNode }>) => {
  const [mainMenuHiddenOn] = useAtom(mainMenuHiddenAtom);

  return (
    <>
      {layoutBanner}
      <SlateBackgroundBody />
      <LayoutWrapperInner>
        <MainMenu hiddenOn={mainMenuHiddenOn} />
        <LayoutContent>{children}</LayoutContent>
      </LayoutWrapperInner>
    </>
  );
};

export { Layout };
