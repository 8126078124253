import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Switch } from '@boopos/design-system';

import { AccordionCategories } from 'components/AccordionCategories';

import { parseCategoriesToApi, parseCategoriesToComponent } from 'lib/parse';
import { ICategoriesApi } from 'lib/types';

import { useUser } from 'providers/UserProvider';

import {
  CategoriesWrapper,
  EmailTxt,
  SwitchBox,
} from './UserNotifications.styled';

export const UserNotifications = () => {
  const { t } = useTranslation();

  const { user, updateUser } = useUser();

  const formMethods = useForm({
    defaultValues: {
      marketingOptIn: user?.marketingOptIn,
      ...parseCategoriesToComponent(user?.categories as ICategoriesApi),
    },
  });

  const { register, getValues } = formMethods;

  const onSubmitValues = () => {
    const { marketingOptIn, category } = getValues();

    updateUser({
      marketingOptIn: marketingOptIn,
      categories: parseCategoriesToApi(category),
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form data-testid="user-notifications-form" onChange={onSubmitValues}>
        <SwitchBox>
          <Switch
            data-testid="marketing-opt-in"
            id="switch-notifications"
            label={
              <>
                <div>{t('NotificationsScreen:label')}</div>
                <EmailTxt data-testid="email">{user?.email}</EmailTxt>
              </>
            }
            groupSpacing="none"
            {...register('marketingOptIn')}
          />
        </SwitchBox>
        <CategoriesWrapper>
          <AccordionCategories defaultExpanded={false} />
        </CategoriesWrapper>
      </form>
    </FormProvider>
  );
};
