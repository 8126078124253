import { useTranslation } from 'react-i18next';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NudeButtonWithShadow } from 'components/NudeButtonWithShadow';

import { ActionSideModalStyles } from './ActionSideModal.styles';

export const ActionSideModalBackHeader = ({
  onBack,
}: {
  onBack: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <ActionSideModalStyles.CloseWrapper>
      <NudeButtonWithShadow
        leftIcon={<FontAwesomeIcon icon={regular('arrow-left')} />}
        onClick={onBack}
      >
        {t('Common:back')}
      </NudeButtonWithShadow>
    </ActionSideModalStyles.CloseWrapper>
  );
};
