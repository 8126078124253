import { Navigate } from 'react-router-dom';

import { defaultLandingUrl } from 'router/routePaths';

import { useUnlockNavigate } from './useUnlockNavigate';

export const UnlockListingScreen = () => {
  const navigateTo = useUnlockNavigate();

  if (!navigateTo) {
    return <Navigate to={defaultLandingUrl} />;
  }

  return <Navigate to={navigateTo.pathname} state={navigateTo.state} replace />;
};
