import { PropsWithChildren } from 'react';
import { useInView } from 'react-intersection-observer';
import { useMedia } from 'react-use';

import { config, styled } from '@boopos/design-system';

export const SectionPartials = {
  Title: styled('h2', {
    fontSize: '2.4rem',
    lineHeight: '3.2rem',
    mb: '$2',
  }),
  Description: styled('div', {
    mb: '$6',
  }),
  StickyFooter: styled('div', {
    py: '$4',
    variants: {
      sticky: {
        true: {
          zIndex: 100,
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          px: '$4',
          background: '#fff',
          boxShadow: 'rgb(0 0 0 / 25%) 2px 10px 50px -12px',
        },
      },
    },
  }),
  Wrapper: styled('div', {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '0 auto',
    '@bp2': {
      px: 0,
      maxWidth: 390,
    },
  }),
};

export const StickyFooter = ({ children }: PropsWithChildren<{}>) => {
  const isDesktop = useMedia(config.media.bp2);
  const { ref: stickyRef, entry: stickyEntry } = useInView();

  const { ref: sentinelRef, inView: isSentinelInView } = useInView({
    threshold: 0,
  });

  const heightReplicant = stickyEntry?.boundingClientRect?.height ?? 0;
  const displayReplicant = isSentinelInView ? 'none' : 'block';

  return (
    <>
      <SectionPartials.StickyFooter
        sticky={!isDesktop && !isSentinelInView}
        ref={stickyRef}
      >
        {children}
      </SectionPartials.StickyFooter>
      {!isDesktop && (
        <>
          <div
            data-testid="sticky-height-replicant"
            style={{
              height: heightReplicant,
              display: displayReplicant,
            }}
          />
          <div ref={sentinelRef} />
        </>
      )}
    </>
  );
};
