import { useAtom } from 'jotai';
import { useEffect } from 'react';
import useSWR from 'swr';

import { qualificationStatusAtom } from 'components/QualificationStatus/QualificationStatus.atoms';
import { IQualificationStatus } from 'components/QualificationStatus/QualificationStatus.interface';

import api from 'lib/api';

export const useQualificationStatus = () => {
  const { data } = useSWR<IQualificationStatus>(api.getQualification);

  const [qualificationStatusAtomValue, setQualificationStatus] = useAtom(
    qualificationStatusAtom
  );

  useEffect(() => {
    if (!data) return;
    setQualificationStatus(data);
  }, [data, setQualificationStatus]);

  return qualificationStatusAtomValue;
};
