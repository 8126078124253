import { Trans, useTranslation } from 'react-i18next';

import { MotionTabs } from 'ui/MotionTabs';

import { BenchmarkData, ScoreTypesEnum } from 'components/BusinessInsights';
import {
  MarketingSpendCard,
  ProfitabilityCard,
  QualityOfRevenuesCard,
  RevenueGrowthCard,
} from 'components/InsightsCards';
import { useInsightsCharts } from 'components/InsightsCharts/useInsightsCharts';

import { BusinessTypesEnum } from 'lib/types';

import { InsightsChartsCard } from './InsightsChartsCard.styles';
import { percentValueFormatter } from './utils/percentValueFormatter';

const useCardsData = ({
  scores,
  blur,
}: {
  scores: Record<ScoreTypesEnum, BenchmarkData>;
  blur?: boolean;
}) => {
  const revenueGrowthData = scores[ScoreTypesEnum.growth];
  const profitabilityData = scores[ScoreTypesEnum.profit];
  const marketingSpendData = scores[ScoreTypesEnum.tacos];
  const qualityOfRevenuesData = scores[ScoreTypesEnum.products];

  return {
    revenueGrowth: {
      percentile: revenueGrowthData?.percentile,
      percentileFormatted: percentValueFormatter(
        revenueGrowthData?.percentile,
        true
      ),
      avg: revenueGrowthData?.avg,
    },
    profitability: {
      percentile: profitabilityData?.percentile,
      percentileFormatted: percentValueFormatter(
        profitabilityData?.percentile,
        true
      ),
      avg: profitabilityData?.avg,
    },
    marketingSpend: {
      percentile: marketingSpendData?.percentile,
      percentileFormatted: percentValueFormatter(
        marketingSpendData?.percentile,
        true
      ),
      avg: marketingSpendData?.avg,
    },
    qualityOfRevenues: {
      percentile: qualityOfRevenuesData?.percentile,
      percentileFormatted: percentValueFormatter(
        qualityOfRevenuesData?.percentile,
        true
      ),
      avg: qualityOfRevenuesData?.avg,
    },
  };
};

export const InsightsCardsWithCharts = ({
  businessType,
  chartsService,
  cohortsService,
  scores,
  blur,
  businessName,
}: {
  scores: Record<ScoreTypesEnum, BenchmarkData>;
  blur?: boolean;
  businessType?: string;
  businessName?: string;
  chartsService?: string;
  cohortsService?: string;
}) => {
  const { t } = useTranslation();

  const cards = useCardsData({ scores, blur });

  const charts = useInsightsCharts({
    chartsService,
    cohortsService,
  });

  const tabs = [
    <MotionTabs.Tab
      key="revenue-growth"
      id="revenue-growth"
      css={{ width: 210 }}
    >
      <RevenueGrowthCard
        basicView
        percentile={cards.revenueGrowth.percentile}
        avg={cards.revenueGrowth.avg}
        transparent
        blur={blur}
      />
    </MotionTabs.Tab>,
    <MotionTabs.Tab
      key="quality-of-revenues"
      id="quality-of-revenues"
      css={{ width: 210 }}
    >
      <QualityOfRevenuesCard
        basicView
        businessType={businessType as BusinessTypesEnum}
        percentile={cards.qualityOfRevenues.percentile}
        transparent
        blur={blur}
      />
    </MotionTabs.Tab>,
    <MotionTabs.Tab key="profitability" id="profitability" css={{ width: 210 }}>
      <ProfitabilityCard
        basicView
        percentile={cards.profitability.percentile}
        avg={cards.profitability.avg}
        transparent
        blur={blur}
      />
    </MotionTabs.Tab>,
    <MotionTabs.Tab
      key="marketing-spend"
      id="marketing-spend"
      css={{ width: 210 }}
    >
      <MarketingSpendCard
        basicView
        percentile={cards.marketingSpend.percentile}
        avg={cards.marketingSpend.avg}
        transparent
        blur={blur}
      />
    </MotionTabs.Tab>,
  ];

  const descriptions = {
    revenueGrowth: (
      <Trans
        i18nKey="InsightsCardsWithCharts:revenue_growth_description"
        values={{
          businessName,
          percentage: cards.revenueGrowth.percentileFormatted,
          businessType,
        }}
      >
        <strong />
      </Trans>
    ),
    qualityOfRevenues: (
      <Trans
        i18nKey="InsightsCardsWithCharts:quality_of_revenues_description"
        values={{
          businessName,
          percentage: cards.qualityOfRevenues.percentileFormatted,
          businessType,
        }}
      >
        <strong />
      </Trans>
    ),
    profitability: (
      <Trans
        i18nKey="InsightsCardsWithCharts:profitability_description"
        values={{
          businessName,
          percentage: cards.profitability.percentileFormatted,
          businessType,
        }}
      >
        <strong />
      </Trans>
    ),
    marketingSpend: (
      <Trans
        i18nKey="InsightsCardsWithCharts:marketing_spend_description"
        values={{
          businessName,
          percentage: cards.marketingSpend.percentileFormatted,
          businessType,
        }}
      >
        <strong />
      </Trans>
    ),
  };

  const qualityOfRevenuesText =
    businessType !== BusinessTypesEnum.subscriptionBased
      ? t('InsightsCardsWithCharts:quality_of_revenues_hint_non_subscription', {
          businessName,
        })
      : undefined;

  return (
    <MotionTabs
      tabs={tabs}
      css={{
        mb: '6rem',
        width: '100vw',
        ml: '-1.6rem',
        '@bp2': { width: 'auto', ml: 0 },
      }}
    >
      <MotionTabs.Children data-id-selected="revenue-growth">
        {blur && <InsightsChartsCard.BlurBackdrop />}
        <InsightsChartsCard.Description>
          {descriptions.revenueGrowth}
        </InsightsChartsCard.Description>
        {charts.revenueGrowth}
      </MotionTabs.Children>
      <MotionTabs.Children data-id-selected="quality-of-revenues">
        {blur && <InsightsChartsCard.BlurBackdrop />}
        <InsightsChartsCard.Description>
          {descriptions.qualityOfRevenues}
        </InsightsChartsCard.Description>
        {qualityOfRevenuesText ? (
          <InsightsChartsCard.Hint>
            {qualityOfRevenuesText}
          </InsightsChartsCard.Hint>
        ) : (
          <>
            {charts.customerRetentionCohorts}
            {charts.revenueRetentionCohorts}
          </>
        )}
      </MotionTabs.Children>
      <MotionTabs.Children data-id-selected="profitability">
        {blur && <InsightsChartsCard.BlurBackdrop />}
        <InsightsChartsCard.Description>
          {descriptions.profitability}
        </InsightsChartsCard.Description>
        <>{charts.profitGrowth}</>
        <>{charts.unitEconomics}</>
      </MotionTabs.Children>
      <MotionTabs.Children data-id-selected="marketing-spend">
        {blur && <InsightsChartsCard.BlurBackdrop />}
        <InsightsChartsCard.Description>
          {descriptions.marketingSpend}
        </InsightsChartsCard.Description>
        {charts.tacosGrowth}
      </MotionTabs.Children>
    </MotionTabs>
  );
};
