import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@boopos/design-system';

import { ConfirmationModal } from 'components/ConfirmationModal';

interface IRejectButton {
  onConfirmReject: () => Promise<void>;
}

export const RejectButton = ({ onConfirmReject }: IRejectButton) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenConfirm = useCallback(() => setOpened(true), []);
  const handleCloseConfirm = useCallback(() => setOpened(false), []);

  const handleReject = useCallback(async () => {
    try {
      setIsLoading(true);
      await onConfirmReject();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      handleCloseConfirm();
    }
  }, [handleCloseConfirm, onConfirmReject]);

  const confirmButtonProps = {
    variant: 'primary',
    isLoading,
    children: t('EnquiryModal:reject_confirmation_action'),
    onClick: handleReject,
  };

  const cancelButtonProps = {
    variant: 'secondary',
    children: t('EnquiryModal:reject_confirmation_cancel'),
    onClick: handleCloseConfirm,
  };

  return (
    <>
      <Button
        variant="secondary"
        onClick={handleOpenConfirm}
        data-testid="reject-action-button"
      >
        {t('EnquiryModal:reject_action')}
      </Button>
      <ConfirmationModal
        opened={opened}
        title={t('EnquiryModal:reject_confirmation_title')}
        description={t('EnquiryModal:reject_confirmation_description')}
        confirmButtonProps={confirmButtonProps}
        cancelButtonProps={cancelButtonProps}
      />
    </>
  );
};
