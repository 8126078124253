import { styled } from '@boopos/design-system';

export const Styles = {
  AdvantagesWrapper: styled('div', {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '$backgroundElevated',
    borderRadius: '1.6rem',
    gap: '4.8rem',
    padding: '2.4rem',
    width: '100%',
    mb: '4rem',
    '@bp2': {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, minmax(0, max-content))',
      rowGap: 0,
    },
  }),
  Advantage: styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '2.4rem',
  }),
  Step: styled('div', {
    color: '$textBodyTertiary',
    fontSize: '1.4rem',
  }),
  AdvantageTitle: styled('div', {
    fontSize: '2rem',
    lineHeight: '2.4rem',
    fontWeight: '$bold',
    minHeight: '4.8rem',
  }),
  AdvantageDescription: styled('div', {}),
};
