import { styled } from '@boopos/design-system';

export const ContentCellDetail = {
  Wrapper: styled('div', {
    my: '.8rem',
  }),
  IconWrapper: styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    width: '2.4rem',
    height: '2.4rem',
  }),
  Label: styled('div', {
    display: 'inline-flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    color: '$textBodyTertiary',
    fontSize: '1.4rem',
    lineHeight: '2.4rem',
  }),
  Value: styled('div', {
    color: '$textBodySecondary',
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    whiteSpace: 'nowrap',

    variants: {
      overflow: {
        true: {},
        false: {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },
      },
      variant: {
        value: {
          fontWeight: 700,
          a: {
            color: '$textBodySecondary',
            textDecoration: 'underline',
          },
        },
        body: {
          whiteSpace: 'normal',
          fontWeight: 400,
        },
      },
      blur: {
        true: {
          userSelect: 'none',
          pointer: 'default',
          filter: 'blur(7px)',
          a: {
            pointerEvents: 'none',
          },
        },
      },
      numeric: {
        true: {
          fontFamily: '$mono',
        },
      },
      size: {
        big: {
          fontSize: '3.2rem',
          lineHeight: '4rem',
          fontWeight: 500,
        },
        medium: {
          fontHeadingSerif: '$h3',
        },
      },
    },
    defaultVariants: {
      numeric: true,
      variant: 'value',
      overflow: false,
    },
  }),
  Action: styled('button', {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'underline',
    textUnderlineOffset: '0.3rem',
    border: 0,
    background: 'transparent',
    cursor: 'pointer',
    color: '$textBodySecondary',
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    fontWeight: 700,
    whiteSpace: 'nowrap',
    variants: {
      light: {
        true: {
          fontWeight: 'normal',
        },
      },
      isDisabled: {
        true: {
          cursor: 'default',
        },
      },
    },
  }),
};
