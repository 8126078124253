import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import isEmail from 'validator/lib/isEmail';

import {
  ButtonFeedback,
  Input,
  createToastFeedback,
} from '@boopos/design-system';

import { MerchantType } from 'components/AccountConnectors';
import { ModalOverSideModal } from 'components/ModalOverSideModal';

import Api from 'lib/api';
import { getInputErrorProps, wait } from 'lib/forms';

export interface SendInvitationModalProps {
  title: string;
  businessId: string;
  description: string;
  visible: boolean;
  onClose: () => void;
  onSuccess?: () => Promise<void>;
  accountType: MerchantType;
}

interface SendInvitationModalForm {
  email: string;
}
export const SendInvitationModal = ({
  visible,
  businessId,
  onClose,
  title,
  description,
  onSuccess,
  accountType,
}: SendInvitationModalProps) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { register, handleSubmit, formState, reset, setFocus } =
    useForm<SendInvitationModalForm>();

  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (data: SendInvitationModalForm) => {
      setLoading(true);
      try {
        await Api.inviteToMerchantConnection(businessId, {
          email: data.email,
          accountType,
        });
        setSuccess(true);
        setLoading(false);
        await wait(500);
        await onSuccess?.();
      } catch (err) {
        console.error(err);
        createToastFeedback({
          type: 'error',
          title: t('SendInvitationModal:error_sending'),
        });
      } finally {
        setLoading(false);
      }
    },
    [businessId, onSuccess, accountType, t]
  );

  const handleEmailValidation = useCallback(
    (email: string) =>
      isEmail(email) || t('SendInvitationModal:email_not_valid'),
    [t]
  );

  useEffect(() => {
    if (visible) return;
    setSuccess(false);
    setLoading(false);
    reset({ email: '' });
  }, [visible, reset]);

  useEffect(() => {
    if (!visible) return;
    setTimeout(() => setFocus('email'), 500);
  }, [visible, setFocus]);

  return (
    <ModalOverSideModal
      visible={visible}
      onClose={onClose}
      data-testid="send-invitation-modal"
    >
      <ModalOverSideModal.Title>{title}</ModalOverSideModal.Title>
      <ModalOverSideModal.Description>
        {description}
      </ModalOverSideModal.Description>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label={t('SendInvitationModal:email_label')}
          data-testid="send-invitation-modal-email"
          {...getInputErrorProps(formState?.errors?.email)}
          {...register('email', {
            required: t('SendInvitationModal:email_required'),
            validate: handleEmailValidation,
          })}
          groupSpacing="xl"
        />

        <ButtonFeedback
          isLoading={loading}
          isSuccess={success}
          data-testid="send-invitation-modal-submit"
        >
          {t('SendInvitationModal:send')}
        </ButtonFeedback>
      </form>
    </ModalOverSideModal>
  );
};
