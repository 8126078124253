import { useCallback, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { formatDate } from '@boopos/utils';

import { apiV2 } from 'lib/api';
import { EnquiryApi } from 'lib/types';

import { IEnquiryModalOutletContext } from './EnquiryModal.interface';

export const useEnquiryModal = () => {
  const navigate = useNavigate();
  const params = useParams();
  const privateSellerNotesRef = useRef<undefined | HTMLTextAreaElement>();
  const [isSaving, setIsSaving] = useState(false);

  const { enquiryId } = params ?? {};

  const { data: enquiry, mutate: mutateEnquiry } = useSWR<EnquiryApi>(
    enquiryId && apiV2.getListingEnquiry(enquiryId)
  );

  const { goBackURL, handleRefreshEnquiries } =
    useOutletContext<IEnquiryModalOutletContext>() ?? {};

  const advisorTooltipId = 'advisor-tooltip';
  const fullName = `${enquiry?.buyer?.firstName} ${enquiry?.buyer?.lastName}`;

  const firstContactedDate = enquiry?.createdAt
    ? formatDate(enquiry?.createdAt, 'MMMM Do')
    : '-';

  const handleBack = useCallback(() => {
    navigate(goBackURL);
  }, [goBackURL, navigate]);

  const handleSave = useCallback(async () => {
    if (!enquiryId) return;

    setIsSaving(true);

    const notes = privateSellerNotesRef?.current?.value;

    await apiV2.saveEnquiry(enquiryId, { privateSellerNotes: notes });
    await handleRefreshEnquiries?.();
    await mutateEnquiry?.();

    setIsSaving(false);

    handleBack();
  }, [enquiryId, handleBack, handleRefreshEnquiries, mutateEnquiry]);

  const handleReject = useCallback(async () => {
    if (!enquiryId) return;

    await apiV2.rejectEnquiry(enquiryId);
    await handleRefreshEnquiries?.();
    await mutateEnquiry?.();
    handleBack();
  }, [enquiryId, handleBack, handleRefreshEnquiries, mutateEnquiry]);

  return {
    isSaving,
    enquiry,
    goBackURL,
    advisorTooltipId,
    fullName,
    firstContactedDate,
    privateSellerNotesRef,
    handleSave,
    handleReject,
  };
};
