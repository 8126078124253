import { routePaths } from 'router/routePaths';

import {
  GetValuationLanding,
  SellBusinessLanding,
} from 'screens/LandingScreens';

export const landingRoutes = [
  {
    path: routePaths.getValuationLanding,
    element: <GetValuationLanding />,
  },
  {
    path: routePaths.sellBusinessLanding,
    element: <SellBusinessLanding />,
  },
  {
    path: routePaths.freeValuation,
    lazy: () => import('screens/LandingScreens/FreeValuationLanding'),
  },
];
