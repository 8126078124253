import { styled } from '@boopos/design-system';

import { CardSection } from 'ui/CardSection';

export const LoanCardStyles = {
  Card: styled(CardSection, {
    textDecoration: 'none',
    display: 'grid',
    gap: '3.8rem',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',

    '&:hover, &:active': {
      boxShadow: '0 0 0 2px inset $colors$slate200',
    },

    '&:not(:first-child)': {
      mt: '$4',
    },

    '@bp2': {
      gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    },
  }),
  Item: styled('div', {
    variants: {
      dateColumn: {
        true: {
          alignSelf: 'center',
          fontSize: '1.2rem',
          lineHeight: '1.6rem',
          color: '$slate500',

          '@bp2': {
            textAlign: 'right',
          },
        },
      },
      columnSpan: {
        true: {
          gridColumn: 'span 2',
        },
      },
    },
  }),
  Type: styled('div', {
    color: '$slate600',
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    fontWeight: 700,
    whiteSpace: 'nowrap',
    mb: '$2',
  }),
  Status: styled('div', {
    display: 'inline-flex',
    alignItems: 'flex-end',
    gap: '$2',
  }),
};
