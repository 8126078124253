import { useTranslation } from 'react-i18next';

import { InsightsComparisonTable } from 'ui/InsightsComparisonTable';
import { defaultChartColors } from 'ui/InsightsShared';

import { UnitEconomicTotals } from './InsightsCharts.interface';
import { moneyValueFormatter } from './utils/moneyValueFormatter';
import { percentValueFormatter } from './utils/percentValueFormatter';

export const UnitEconomicsTable = ({
  totals,
}: {
  totals?: UnitEconomicTotals;
}) => {
  const { t } = useTranslation();

  if (!totals) {
    return null;
  }

  return (
    <InsightsComparisonTable.Table>
      <InsightsComparisonTable.Row>
        <InsightsComparisonTable.Th>
          <InsightsComparisonTable.Indicator
            css={{ background: defaultChartColors.bl200 }}
          />
          {t('InsightsCharts:total_revenues')}
        </InsightsComparisonTable.Th>
        <InsightsComparisonTable.Td>
          {moneyValueFormatter(totals.revenuesSum)}
        </InsightsComparisonTable.Td>
      </InsightsComparisonTable.Row>
      <InsightsComparisonTable.Row>
        <InsightsComparisonTable.Th>
          <InsightsComparisonTable.Indicator
            css={{ background: defaultChartColors.bl500 }}
          />
          {t('InsightsCharts:avg_cogs')}
        </InsightsComparisonTable.Th>
        <InsightsComparisonTable.Td>
          {percentValueFormatter(totals.cogsAverage)}
        </InsightsComparisonTable.Td>
      </InsightsComparisonTable.Row>
      <InsightsComparisonTable.Row>
        <InsightsComparisonTable.Th>
          <InsightsComparisonTable.Indicator
            css={{ background: defaultChartColors.bl900 }}
          />
          {t('InsightsCharts:sde')}
        </InsightsComparisonTable.Th>
        <InsightsComparisonTable.Td>
          {percentValueFormatter(totals.profitAverage)}
        </InsightsComparisonTable.Td>
      </InsightsComparisonTable.Row>
    </InsightsComparisonTable.Table>
  );
};
