import { useMemo } from 'react';

import { IValuationHistogramData } from 'components/ValuationCard/ValuationCard.interface';

import { chartConfig } from './HistogramChart.utils';

export const useHistogramChart = (data: IValuationHistogramData[]) => {
  const getMatchedIntervalColor = (match: boolean) => {
    return match ? chartConfig.barColorMatched : chartConfig.barColor;
  };

  const renderCustomTick = ({
    x,
    y,
    payload,
  }: {
    x: number;
    y: number;
    payload: { value: number | string };
  }) => (
    <text
      x={x}
      y={y + 10}
      style={chartConfig.xAxisTickStyles}
      textAnchor="middle"
    >
      {payload.value}x
    </text>
  );

  const xAxisTicks = useMemo(() => {
    const tickValues = [];
    const dataLength = data.length;
    const desiredTickCount = 6;

    for (let i = 0; i < dataLength; i += desiredTickCount) {
      tickValues.push(data[i].floor);
    }

    if (tickValues[tickValues.length - 1] !== data[dataLength - 1].floor) {
      tickValues.push(data[dataLength - 1].floor);
    }

    return tickValues;
  }, [data]);

  return { getMatchedIntervalColor, renderCustomTick, xAxisTicks };
};
