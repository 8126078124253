import { formatMoney } from 'lib/format';
import { MoneyFormat } from 'lib/format/money/formatMoney.interface';
import i18n from 'lib/i18';
import { ILoanApi } from 'lib/types';

export const loanFormattedAmount = (loan: ILoanApi): string => {
  const amountToFormat = loan.maxAmount ? loan.maxAmount : loan.amount;
  const showUpTo = !!loan.maxAmount;

  const formattedAmount = formatMoney(amountToFormat, {
    format: MoneyFormat.long,
  });

  if (showUpTo) {
    return i18n.t('LoanDetailScreen:up_to_amount', {
      amount: formattedAmount,
    });
  }

  return formattedAmount as string;
};

export const LoanFormattedAmount = ({ loan }: { loan: ILoanApi }) => (
  <>{loanFormattedAmount(loan)}</>
);
