import { AnimatePresence } from 'framer-motion';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonFeedback } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NavigationCardSkeleton } from 'ui/NavigationCard';

import { BankAccountsPreview } from 'components/BankAccountsPreview';
import { DocumentsPreview } from 'components/DocumentsPreview';

import { AnimatedBox } from './AnimatedBox';
import { ConnectWithPlaid } from './ConnectWithPlaid';
import { ProofOfFundsVariants } from './ProofOfFunds.interface';
import {
  UploadAdditionalDocuments,
  UploadProofOfFundsDocuments,
  removeAction,
} from './ProofOfFundsDocuments';
import { useProofOfFundsData } from './useProofOfFundsData';
import { useProofOfFundsUpdate } from './useProofOfFundsUpdate';

interface ProofOfFundsValidateProps {
  onSuccess?: () => void;
  continueButtonEnabled?: boolean;
  variant?: ProofOfFundsVariants;
}

const ProofOfFundsValidateLoading = ({
  variant,
}: {
  variant?: ProofOfFundsVariants;
}) => {
  return (
    <div data-testid="proof-of-funds-validate-loading">
      <NavigationCardSkeleton variant={variant} />
      <NavigationCardSkeleton variant={variant} />
    </div>
  );
};

export const ProofOfFundsValidate = ({
  onSuccess,
  variant = 'default',
  continueButtonEnabled = true,
}: ProofOfFundsValidateProps) => {
  const { connectWithPlaid, loadingPlaid, disconnectAccount } =
    useProofOfFundsUpdate();
  const { bankAccounts, files, loadingData } = useProofOfFundsData();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const { t } = useTranslation();

  const hasBankAccounts = !!bankAccounts?.length;
  const hasFiles = !!files?.length;
  const shouldShowMainActions = !hasBankAccounts && !hasFiles;
  const shouldShowContinueButton = hasBankAccounts || hasFiles;

  const handleContinue = useCallback(async () => {
    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setIsLoading(false);
    setIsSuccess(true);
    setTimeout(() => {
      setIsSuccess(false);
      onSuccess?.();
    }, 1000);
  }, [onSuccess]);

  if (loadingData) {
    return <ProofOfFundsValidateLoading variant={variant} />;
  }

  return (
    <>
      <AnimatePresence mode="wait">
        {shouldShowMainActions && (
          <AnimatedBox key="main-actions">
            <ConnectWithPlaid
              variant={variant}
              data-testid="connect-with-plaid"
            />
            <UploadProofOfFundsDocuments
              variant={variant}
              data-testid="upload-manually"
            />
          </AnimatedBox>
        )}

        {!shouldShowMainActions && (
          <AnimatedBox key="connections">
            <BankAccountsPreview
              bankAccounts={bankAccounts}
              onConnectAccount={connectWithPlaid}
              isLoadingConnect={loadingPlaid}
              onDisconnectAccount={disconnectAccount}
              variant={variant}
            />

            <DocumentsPreview
              files={files}
              showIfEmpty={hasBankAccounts}
              additionalContent={
                <UploadAdditionalDocuments variant={variant} />
              }
              removeAction={removeAction}
              variant={variant}
            />
          </AnimatedBox>
        )}
      </AnimatePresence>

      {continueButtonEnabled && shouldShowContinueButton && (
        <ButtonFeedback
          isLoading={isLoading}
          isSuccess={isSuccess}
          onClick={handleContinue}
          css={{ mt: '4rem' }}
          data-testid="proof-of-funds-validate-continue"
          rightIcon={<FontAwesomeIcon icon={regular('arrow-right')} />}
        >
          {t('ProofOfFundsValidate:continue')}
        </ButtonFeedback>
      )}
    </>
  );
};
