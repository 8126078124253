import { ActivityName } from 'lib/types';

export const knownActivities = [
  ActivityName.targetBusinessAdded,
  ActivityName.sendForEvaluation,
  ActivityName.termSheetSigned,
  ActivityName.closingInformationCompleted,
  ActivityName.sellerContacted,
  ActivityName.dealUnlocked,
];
