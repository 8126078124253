import * as Stitches from '@stitches/react';
import { ReactNode } from 'react';

import { styled } from '@boopos/design-system';

const ActionsWrapperStyles = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '1.6rem',
  position: 'fixed',
  bottom: '0',
  left: '0',
  right: '0',
  padding: '2rem',
  background: '$backgroundElevated',
  boxShadow:
    '0px 0px 16px 0px rgba(0, 0, 0, 0.05), 0px 4px 16px 0px rgba(0, 0, 0, 0.10)',
  '@bp2': {
    position: 'static',
    padding: 0,
    boxShadow: 'none',
    background: 'none',
  },
  '> [data-action-primary]': {
    flex: 1,
    order: 1,
    '@bp2': {
      order: 0,
      flex: 'none',
    },
  },
  '> *': {
    whiteSpace: 'nowrap',
  },
});

const ActionsWrapperSpace = styled('div', {
  height: '8.8rem',
  '@bp2': {
    display: 'none',
  },
});

export const ActionsWrapper = ({
  children,
  ...rest
}: {
  children: ReactNode;
  css?: Stitches.CSS;
}) => {
  return (
    <>
      <ActionsWrapperStyles {...rest}>{children}</ActionsWrapperStyles>
      <ActionsWrapperSpace />
    </>
  );
};
