import { styled } from '@boopos/design-system';

export const TooltipStyles = {
  Wrapper: styled('div', {
    backgroundColor: 'white',
    padding: '1.2rem',
    boxShadow:
      '0px 2px 8px 0px rgba(0, 0, 0, 0.10), 0px 0px 8px 0px rgba(0, 0, 0, 0.05)',
    borderRadius: '.8rem',
    fontSize: '1.4rem',
    lineHeight: '2.4rem',
  }),
  Header: styled('div', {
    display: 'table-caption',
    justifyContent: 'flex-start',
  }),
  Table: styled('div', {
    display: 'table',
    width: '100%',
  }),
  Row: styled('div', {
    display: 'table-row',
    variants: {
      variant: {
        light: {
          color: '$textBodyTertiary',
        },
        positive: {
          color: '$infoPositive',
        },
        negative: {
          color: '$infoNegative',
        },
        regular: {},
      },
    },
  }),
  Indicator: styled('div', {
    width: '1.2rem',
    height: '1.2rem',
    borderRadius: '50%',
  }),
  RowLabel: styled('div', {
    display: 'table-cell',
    paddingLeft: '.8rem',
    paddingRight: '.8rem',
    width: '100%',
    whiteSpace: 'nowrap',
  }),
  RowValue: styled('div', {
    display: 'table-cell',
    paddingLeft: '1.6rem',
    whiteSpace: 'nowrap',
    variants: {
      align: {
        left: {
          textAlign: 'left',
        },
        right: {
          textAlign: 'right',
        },
      },
    },
    defaultVariants: {
      align: 'right',
    },
  }),
};
