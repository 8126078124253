import { styled } from '@boopos/design-system';

export const ListScreenLayout = {
  Wrapper: styled('div', {
    width: '100%',
    px: '1.6rem',
    '@bp2': {
      px: '3.2rem',
    },
  }),
  Container: styled('div', {
    width: '100%',
    px: '1.6rem',
    '@bp2': {
      px: '3.2rem',
    },
  }),
  List: styled('div', {
    mt: '2.4rem',
    '@bp2': {
      mt: '4.8rem',
    },
  }),
};

export const ListScreenLayoutEmpty = {
  Wrapper: styled('div', {
    m: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pt: '3.6rem',
    maxWidth: '46.8rem',
    textAlign: 'center',
    gap: '2.4rem',
  }),
  Title: styled('div', {
    fontHeadingSerif: '$h3',
  }),
  Subtitle: styled('div', {
    color: '$textBodyTertiary',
  }),
  ActionContainer: styled('div', {
    width: '100%',
  }),
  SecondaryActionContainer: styled('div', {
    '> a': {
      color: '$slate600',
    },
  }),
};
