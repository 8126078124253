import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DeleteConfirmationAction } from 'components/ConfirmationModal';

import {
  FilePreviewLink,
  FilePreviewName,
  FilePreviewWrapper,
} from './WatchListDocumentsUpload.styles';

interface IFilePreview {
  id: string;
  downloadUrl: string;
  name: string;
  onDelete: (fileId: string) => void;
}

const animationVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
};

export const FilePreview = ({
  id,
  downloadUrl,
  name,
  onDelete,
}: IFilePreview) => {
  const { t } = useTranslation();

  return (
    <FilePreviewWrapper
      key={downloadUrl}
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={animationVariants}
    >
      <FilePreviewName>
        <FilePreviewLink href={downloadUrl} target="_blank" title={name}>
          {name}
        </FilePreviewLink>
      </FilePreviewName>
      <DeleteConfirmationAction
        title={t('WatchListDocumentsUpload:delete_confirm')}
        onDelete={onDelete}
        deleteParams={id}
      >
        <Button
          rounded
          variant="nude"
          leftIcon={<FontAwesomeIcon icon={regular('trash')} size="lg" />}
          data-testid="delete-file"
        >
          {t('WatchListDocumentsUpload:delete_action')}
        </Button>
      </DeleteConfirmationAction>
    </FilePreviewWrapper>
  );
};
