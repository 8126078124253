import React from 'react';

import { BuyerInsightsTimelinePreview } from 'components/BuyerInsights/BuyerInsightsTimelinePreview';
import { ListingDetail } from 'components/ListingDetail';
import { useListingUnlockAction } from 'components/ListingUnlock/useListingUnlockAction';
import { BusinessPreApprovedActivity } from 'components/TimelineActivities/BusinessPreApprovedActivity';

import { ListingForSale } from 'lib/types';

import { routePaths } from 'router/routePaths';

export const ForSaleBusinessPreApproved = ({
  listing,
  showInsights,
}: {
  listing: ListingForSale;
  showInsights: boolean;
}) => {
  const { open } = useListingUnlockAction(listing?.requiresAgreement);

  return (
    <BusinessPreApprovedActivity
      date={listing.publishedAt}
      forceNowVariant={!listing?.dealApplication?.requestedAt}
      isLast
    >
      <ListingDetail listing={listing} />
      {showInsights && (
        <BuyerInsightsTimelinePreview
          businessInsights={listing?.businessInsights}
          businessId={listing?.businessInsights?.businessId ?? ''}
          insightsPath={routePaths.forSaleInsights}
          onUnlock={open}
        />
      )}
    </BusinessPreApprovedActivity>
  );
};
