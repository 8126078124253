import { Trans, useTranslation } from 'react-i18next';

import { AcceptedFilesEnum } from 'components/UploadZone/UploadZone.interface';

import config from 'lib/config';
import { LoanDocumentType } from 'lib/types';
import { useStopPropagation } from 'lib/useStopPropagation';

import { BasicDocumentsUploadModal } from './BasicDocumentsUploadModal';

export const CohortsUploadModal = ({ basePath }: { basePath: string }) => {
  const { t } = useTranslation();
  const stopPropagation = useStopPropagation();

  const props = {
    title: t('BasicDocumentsUploadModal:cohorts_title'),
    subtitle: (
      <Trans i18nKey="BasicDocumentsUploadModal:cohorts_subtitle">
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a
          href={config.COHORTS_ARTICLE_URL}
          target="_blank"
          onClick={stopPropagation}
          rel="noreferrer"
        />
      </Trans>
    ),
    documentType: LoanDocumentType.cohorts,
    acceptedFiles: [
      AcceptedFilesEnum.csv,
      AcceptedFilesEnum.xls,
      AcceptedFilesEnum.xlsx,
    ],
  };

  return <BasicDocumentsUploadModal basePath={basePath} {...props} />;
};
