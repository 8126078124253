import { styled } from '@boopos/design-system';

const LayoutContent = styled('main', {
  flex: 1,
  position: 'relative',
  pb: '7.2rem',
  '@bp2': {
    pb: 0,
  },
});

export { LayoutContent };
