import { styled } from '@boopos/design-system';

export const ForSaleListMatchingStyles = {
  Wrapper: styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    my: '$9',
  }),
  Title: styled('div', {
    fontHeadingSerif: '$h3',
    color: '$textBodyPrimary',
    mb: '$4',
  }),
  Description: styled('div', {
    color: '$textBodySecondary',
    mb: '$4',
  }),
  Items: styled('div', {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '$3',
  }),
};
