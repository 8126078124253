import { useTranslation } from 'react-i18next';

import { ContentTable } from 'ui/ContentTable';

import { RenderMoneyFormatted } from 'components/RenderMoneyFormatted';

import { IDealStructure } from 'lib/types';

interface DealStructureDetailsProps {
  purchaseDealStructure?: IDealStructure;
}

const moneyFormattedProps = {
  renderSeparator: () => null,
  labelComponent: ContentTable.Label,
  valueComponent: ContentTable.Value,
  valueProps: {
    numeric: true,
  },
};

export const DealStructureDetails = ({
  purchaseDealStructure,
}: DealStructureDetailsProps) => {
  const { t } = useTranslation();

  if (!purchaseDealStructure) {
    return null;
  }

  return (
    <ContentTable.Wrapper>
      <RenderMoneyFormatted
        title={t('DealStructureDetails:business_price')}
        amount={purchaseDealStructure.businessPrice}
        labelProps={{ 'data-testid': 'business-price' }}
        {...moneyFormattedProps}
      />
      <RenderMoneyFormatted
        title={t('DealStructureDetails:inventory_price')}
        amount={purchaseDealStructure.inventoryPrice}
        {...moneyFormattedProps}
      />
      <RenderMoneyFormatted
        title={t('DealStructureDetails:seller_financing')}
        amount={purchaseDealStructure.sellerFinancing}
        {...moneyFormattedProps}
      />
      <RenderMoneyFormatted
        title={t('DealStructureDetails:loan_amount')}
        amount={purchaseDealStructure.requestedAmount}
        {...moneyFormattedProps}
      />
    </ContentTable.Wrapper>
  );
};
