import { css, styled } from '@boopos/design-system';

export const screenMaxWidthCentered = css({
  maxWidth: '125rem',
  overflow: 'hidden',
  margin: '0 auto',
});

export const ScreenTitle = styled('h1', {
  fontWeight: 'bold',
  fontSize: '2.4rem',
  lineHeight: '4rem',
  mt: '$4',
  mb: '$6',
  '@bp2': {
    fontSize: '3.2rem',
  },
});

export const ScreenWrapper = styled('div', {
  px: '$4',
  py: '$6',
  '@bp2': {
    px: '3.2rem',
  },
});

export const FullWidthHeightScroll = styled('div', {
  width: '100%',
});

export const SubNavigationContainer = styled('div', {
  mt: '2.4rem',
  '@bp2': {
    display: 'flex',
    mt: '4rem',
  },
});

export const SubNavigationContent = styled('div', {
  '@bp2': {
    display: 'flex',
    flex: 1,
    pl: '$5',
    maxWidth: '90.6rem',
  },
});

export const SubNavigationFullWidth = styled('div', {
  flex: 1,
});

export const SubNavigationFirstLevelContent = styled('div', {
  flex: 1,

  variants: {
    end: {
      true: {
        '@bp2': {
          maxWidth: '100%',
        },
      },
    },
  },
});

export const SubNavigationMenuContainer = styled('div', {
  width: '100%',
  '@bp2': {
    flex: 1,
    maxWidth: 360,
    pr: '$5',
  },
});

export const SubNavigationSecondLevelContainer = styled('div', {
  width: '100%',

  '@bp2': {
    flex: 1,
    maxWidth: 380,
    minWidth: 240,
    pr: '$5',
  },
});

export const SubNavigationSecondLevelContent = styled('div', {
  width: '100%',
  '@bp2': { flex: 1, minWidth: 370, pl: '$6' },
});

export const ScreenWrapperContentCentered = styled('div', {
  display: 'flex',
  inset: 0,
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  p: '$6',
  maxWidth: '60rem',
  mx: 'auto',
  minHeight: '100vh',
});
