import useSWRImmutable from 'swr/immutable';

import api from 'lib/api';
import { ICategoriesApi, ICategory } from 'lib/types';

const useBusinessCategories = () => {
  const { data } = useSWRImmutable<ICategory[]>(api.getCategories);

  const categories = [...(data || [])];

  const categoriesMap: ICategoriesApi = categories.reduce(
    (acc, category) => ({
      ...acc,
      [category.id]: (category.categories || []).map(({ id }) => id),
    }),
    {}
  );

  return {
    isLoading: !data,
    categories,
    categoriesMap,
  };
};

export { useBusinessCategories };
