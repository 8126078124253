import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContentCell } from 'ui/ContentCell';

import { DocumentsDetails } from 'components/DocumentsDetails';
import { BusinessPerformance } from 'components/ForSaleDetailApplication/BusinessPerformance';
import { BusinessType } from 'components/ForSaleDetailApplication/BusinessType';
import { FormatUrl } from 'components/FormatUrl';

import { FormatUserInputText } from 'lib/format';
import { ForSaleApplicationApi, LoanDocumentType } from 'lib/types';

import { routePaths } from 'router/routePaths';

interface ReadBusinessDetailsProps {
  sellApplication?: ForSaleApplicationApi;
}

export const ReadBusinessDetails = ({
  sellApplication,
}: ReadBusinessDetailsProps) => {
  const { t } = useTranslation();

  const pnlWasRequired =
    sellApplication?.application?.requiredDocuments?.includes(
      LoanDocumentType.profitAndLoss
    );
  const cohortsWasRequired =
    sellApplication?.application?.requiredDocuments?.includes(
      LoanDocumentType.cohorts
    );

  return (
    <ContentCell.Parent data-testid="read-business-details">
      <ContentCell
        title={t('ForSaleDetailApplication:business_type')}
        showIcon={false}
        active={false}
      >
        <BusinessType business={sellApplication?.business} />
      </ContentCell>

      <ContentCell
        title={t('ForSaleDetailApplication:business_website')}
        showIcon={false}
        active={false}
      >
        <FormatUrl url={sellApplication?.business?.url} showLink={false} />
      </ContentCell>

      {!!sellApplication?.listing?.description && (
        <ContentCell
          title={t('ForSaleDetailApplication:business_description')}
          showIcon={false}
          active={false}
        >
          <FormatUserInputText text={sellApplication?.listing?.description} />
        </ContentCell>
      )}

      <BusinessPerformance
        path={routePaths.portfolioForSaleBusinessPerformance}
        showIcon={false}
        editable={false}
        application={sellApplication?.application}
      />

      {pnlWasRequired && (
        <ContentCell
          title={t('ForSaleDetailApplication:p_n_l')}
          showIcon={false}
          active={false}
        >
          <DocumentsDetails
            documents={sellApplication?.application?.documents}
            documentType={LoanDocumentType.profitAndLoss}
            showVisibility
          />
        </ContentCell>
      )}

      {cohortsWasRequired && (
        <ContentCell
          title={t('ForSaleDetailApplication:cohorts')}
          showIcon={false}
          active={false}
        >
          <DocumentsDetails
            documents={sellApplication?.application?.documents}
            documentType={LoanDocumentType.cohorts}
            showVisibility
          />
        </ContentCell>
      )}
    </ContentCell.Parent>
  );
};
