import { ChartColors } from 'ui/InsightsShared';

export const useChartStyles = (colors: ChartColors) => {
  const axisTick = { fill: colors.tickColor };

  const tooltipCursor = { fill: colors.tooltipCursor };

  const xAxisPadding = { left: 8, right: 8 };
  const xAxisTick = { ...axisTick, dx: -20 };

  const chartMargin = {
    top: 0,
    right: 0,
    left: 0,
    bottom: 20,
  };

  return { axisTick, tooltipCursor, chartMargin, xAxisPadding, xAxisTick };
};
