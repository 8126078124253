import { IQualificationStatusCreditReport } from 'components/QualificationStatus';

import { ICreditReportForm, Residency } from './CreditReport.interface';

const getAddress = (
  data: ICreditReportForm
): IQualificationStatusCreditReport['address'] => {
  if (data?.residency === Residency.nonUs) {
    return null;
  }

  return data?.address;
};

const getSSN = (data: ICreditReportForm) => {
  if (data?.residency === Residency.nonUs) {
    return '';
  }

  return data?.ssn;
};

export const parseFromApi = (
  data?: IQualificationStatusCreditReport
): ICreditReportForm => {
  return {
    address: {
      city: data?.address?.city ?? '',
      state: data?.address?.state ?? '',
      zipCode: data?.address?.zipCode ?? '',
      street: data?.address?.street ?? '',
    },
    ssn: data?.ssn ?? '',
    residency: data?.residency ?? Residency.us,
    isSharingAuthorized: true,
  };
};

export const parseToApi = (
  data: ICreditReportForm
): IQualificationStatusCreditReport => {
  const address = getAddress(data);
  const ssn = getSSN(data);

  return {
    residency: data?.residency ?? Residency.us,
    address,
    ssn,
  };
};

export const shouldUpdateSSN = (
  data: IQualificationStatusCreditReport,
  dirtyFields: any
) => {
  if (data?.residency === Residency.nonUs) {
    return true;
  }

  return !!dirtyFields?.ssn;
};
