import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createToastFeedback } from '@boopos/design-system';

import api from 'lib/api';
import { ILoanApi } from 'lib/types';

import { useSubmitActionStatus } from './SubmitForEvaluation.utils';

export const useSubmitForEvaluation = ({
  loan,
  onSuccess,
}: {
  loan: ILoanApi;
  onSuccess: (cb?: () => void) => Promise<void>;
}) => {
  const loanId = loan?.id;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const { disabled, isResubmit } = useSubmitActionStatus(loan);

  const evaluate = useCallback(async () => {
    try {
      setLoading(true);
      await api.sendDealToEvaluation(loanId);

      await onSuccess(() => {
        setLoading(false);
        setSuccess(true);
      });
    } catch (err) {
      setLoading(false);
      console.error(err);
      createToastFeedback({
        type: 'error',
        title: t('DealTimelineStep:submit_for_evaluation_error'),
      });
    }
  }, [loanId, onSuccess, t]);

  return {
    evaluate,
    isResubmit,
    loading,
    success,
    disabled,
  };
};
