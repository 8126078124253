import { routePaths } from 'router/routePaths';

export const whiteListNotRedirecting = [
  routePaths.sellerShareDeal,
  routePaths.brokerLanding,
  routePaths.getValuationLanding,
  routePaths.sellBusinessLanding,
];

export const whiteListRouteObjects = whiteListNotRedirecting.map(
  (routePath) => ({
    path: routePath,
  })
);
