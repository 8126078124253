import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { Checkbox, Link, Text } from '@boopos/design-system';

import { ICreditReportForm } from 'components/CreditReport/CreditReport.interface';
import { Modal } from 'components/Modal';

import { getInputErrorProps } from 'lib/forms';

export const AuthorizeCheckbox = () => {
  const { t } = useTranslation();

  const [isCreditProfileAgreementModalOpen, toggleCreditProfileAgreementModal] =
    useState(false);

  const { formState, register } = useFormContext<ICreditReportForm>();

  const openModal = useCallback(() => {
    toggleCreditProfileAgreementModal(true);
  }, []);

  const closeModal = useCallback(() => {
    toggleCreditProfileAgreementModal(false);
  }, []);

  return (
    <>
      <Checkbox
        {...register('isSharingAuthorized', {
          required: t('CreditReportForm:consent_required'),
        })}
        id="consent-switch"
        data-testid="consent-switch"
        defaultChecked
        label={
          (
            <Trans i18nKey="CreditReportForm:legal_text">
              <Link
                data-testid="credit-profile-agreement-link"
                href="#"
                onClick={openModal}
              />
            </Trans>
          ) as any
        }
        {...getInputErrorProps(formState?.errors?.isSharingAuthorized)}
      />
      <Modal
        data-testid="credit-profile-agreement-modal"
        opened={isCreditProfileAgreementModalOpen}
        onClose={closeModal}
        position="bottomSheetCentered"
        maxWidth={450}
        allowClose={true}
      >
        <Text css={{ pt: '$2', pb: '$8', px: '$4' }}>
          {t('CreditReportForm:long_legal_text')}
        </Text>
      </Modal>
    </>
  );
};
