import React, { ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ILoanApi } from 'lib/types';

import { ShareSellingDealModal } from './ShareSellingDealModal';

export const ShareSellingDealButton = ({
  loan,
  children,
  ...rest
}: {
  loan: ILoanApi;
  children?: ReactNode;
  variant?: any;
  css?: any;
}) => {
  const [modalOpened, setModalOpened] = useState(false);
  const { t } = useTranslation();

  const onClose = useCallback(() => {
    setModalOpened(false);
  }, []);

  const open = useCallback(() => {
    setModalOpened(true);
  }, []);

  if (!loan.isSellerSaleLoan) {
    return null;
  }

  return (
    <>
      <Button
        fullWidth
        css={{ mb: '$4' }}
        leftIcon={<FontAwesomeIcon icon={regular('share')} />}
        onClick={open}
        data-testid="share-selling-deal-button"
        {...rest}
      >
        {children ?? t('ShareSellingDealButton:share_button')}
      </Button>
      <ShareSellingDealModal
        opened={modalOpened}
        onClose={onClose}
        loan={loan}
      />
    </>
  );
};
