import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import Api from 'lib/api';
import { ILoanApi } from 'lib/types';

import { BuyerBusinessInsights } from './BuyerBusinessInsights';
import { BuyerBusinessInsightsEmpty } from './BuyerBusinessInsightsEmpty';

export const DealFlowBusinessInsights = () => {
  const params = useParams();
  const { loanId } = params ?? {};
  const { data: loan } = useSWR<ILoanApi>(loanId && Api.getLoan(loanId));

  if (loan && !loan.businessInsights) {
    return <BuyerBusinessInsightsEmpty businessName={loan?.business?.name} />;
  }

  return (
    <BuyerBusinessInsights
      businessInsights={loan?.businessInsights}
      businessName={loan?.business?.name}
      data-testid="deal-flow-business-insights"
    />
  );
};
