import { TooltipProps } from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

import { css, theme } from '@boopos/design-system';

export interface ChartColors {
  bl200: string;
  bl500: string;
  bl900: string;
  tickColor: string;
  tooltipCursor: string;
  cartesianGrid: string;
}

export const defaultChartColors: ChartColors = {
  bl200: theme.colors.bl200.value,
  bl500: theme.colors.bl500.value,
  bl900: theme.colors.bl900.value,
  tickColor: theme.colors.textBodyDisabled.value,
  cartesianGrid: theme.colors.decorationSlateBright.value,
  tooltipCursor: 'rgba(214, 214, 214, 0.20)',
};

export type TooltipRenderProps = TooltipProps<ValueType, NameType>;

export const chartCSS = css({
  '& .recharts-cartesian-axis-tick text tspan': {
    fontSize: '1.4rem',
  },
});
