import Skeleton from 'react-loading-skeleton';

import { Box, styled } from '@boopos/design-system';

export const FullScreenFunnelLayout = {
  Wrapper: styled('div', {
    maxWidth: '104.4rem',
    px: '$4',
    pb: '$6',
    m: '0 auto',

    '@bp2': {
      px: '$8',
      pt: 0,
      pb: 0,
    },
  }),
  Header: styled('div', {
    pt: '1.6rem',
    mb: '1.6rem',
    '@bp2': {
      pt: '4rem',
    },
  }),
  ExitLink: styled('a', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: '$actionNeutralNavigation',
    width: '4rem',
    height: '4rem',
    textDecoration: 'none',
    cursor: 'pointer',
  }),
  Content: styled('div', {
    mt: '3.2rem',
    mx: 'auto',
    maxWidth: '39rem',
  }),
  Steps: styled('div', {
    fontWeight: '$bold',
    mb: '2.4rem',
    color: '$textBodyTertiary',
  }),
  Title: styled('h3', {
    fontHeadingSerif: '$h3',
  }),
  Description: styled('div', {
    mt: '1.6rem',
    color: '$textBodyTertiary',
  }),
  CloudWords: styled('div', {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    columnGap: '.8rem',
    rowGap: '1.6rem',
    my: '4rem',
    '> *': {
      whiteSpace: 'nowrap',
    },
    variants: {
      overflow: {
        true: {
          '@bp2': {
            ml: '-4rem',
            mr: '-4rem',
          },
        },
      },
      marginLess: {
        true: {
          my: 0,
        },
      },
    },
    defaultVariants: {
      overflow: false,
    },
  }),
  FormContainer: styled('div', {
    my: '4rem',
  }),
  CallToAction: styled('div', {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '.8rem',
    m: '0 auto',
    maxWidth: '27rem',
  }),
  LoadingContent: () => (
    <>
      <Skeleton width="100%" />
      <Skeleton width="80%" />
      <Skeleton width="50%" />
      <Box css={{ mt: '4rem' }} />
      <Skeleton width="100%" />
    </>
  ),
  InputWrapper: styled('div', {
    mb: '4rem',
  }),
};
