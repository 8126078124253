import { useLandingScreenMemo } from 'providers/FirstAppScreenDecider/LandingScreenMemo';
import { useUser } from 'providers/UserProvider';

import { routePaths } from 'router/routePaths';

export const useFinishLandingUrl = () => {
  const { user } = useUser();
  const landingScreen = useLandingScreenMemo();

  const hasConfidentialityAgreementAccepted = user?.confidentialityAgreement;

  const landingUrl = hasConfidentialityAgreementAccepted
    ? landingScreen
    : routePaths.confidentialityAgreement;

  return { landingUrl };
};
