import { IApiFile } from 'lib/types';

export const getExtraProps = (showLink?: boolean, file?: IApiFile) => {
  if (!showLink || !file?.downloadUrl) {
    return {};
  }

  return {
    as: 'a',
    isLink: true,
    target: '_blank',
    rel: 'noopener noreferrer',
    href: file.downloadUrl,
  };
};
