import { useTranslation } from 'react-i18next';

import { TimelineItemNowProps } from 'components/DealTimeline/DealTimeline.interface';
import { stepAnimatedProps } from 'components/DealTimeline/StepAnimation';
import { DealTimelineStep } from 'components/DealTimelineStep';

import { ILoanApi, LoanStatus } from 'lib/types';

export const shouldShowFundingApproved = (loan: ILoanApi) => {
  return [LoanStatus.contract_sent, LoanStatus.contract_signed].includes(
    loan?.status
  );
};

export const FundingApproved = ({ children, loan }: TimelineItemNowProps) => {
  const { t } = useTranslation();

  if (!shouldShowFundingApproved(loan)) {
    return null;
  }

  const isSigned = loan?.status === LoanStatus.contract_signed;

  return (
    <DealTimelineStep
      title={t('DealTimelineStep:funding_approved_title')}
      subtitle={
        isSigned
          ? t('DealTimelineStep:funding_approved_contract_signed_subtitle')
          : t('DealTimelineStep:funding_approved_contract_sent_subtitle')
      }
      data-testid="deal-funding-approved"
      {...stepAnimatedProps}
    >
      {children}
    </DealTimelineStep>
  );
};
