import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useLandingScreenMemo } from 'providers/FirstAppScreenDecider/LandingScreenMemo';

import { OnboardingProfileScreen } from './OnboardingProfileScreen';

export const ProfileBuyScreen = () => {
  const { t } = useTranslation();
  const landingScreenMemo = useLandingScreenMemo();

  const copies = useMemo(
    () => ({
      title: t('OnboardingProfileScreen:title_buy'),
    }),
    [t]
  );

  return (
    <OnboardingProfileScreen
      data-testid="profile-buy-screen"
      copies={copies}
      saveButtonProps={{
        children: t('OnboardingProfileScreen:save_cta_buy'),
      }}
      nextScreen={landingScreenMemo}
    />
  );
};
