import { CohortsRawData } from 'components/InsightsCharts/InsightsCharts.interface';

export const isCohortsEmpty = (rawData?: CohortsRawData[]) => {
  if (!rawData?.length) {
    return true;
  }

  return rawData.every((item) =>
    item.retentionPercentage?.every((percentage) => percentage === null)
  );
};
