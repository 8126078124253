import { PropsWithChildren } from 'react';

import { TimelineStepVariant } from './Timeline.interface';
import { TimelineStyles } from './Timeline.styles';

interface StepTitleProps {
  variant?: TimelineStepVariant;
}

export const StepTitle = ({
  children,
  variant,
}: PropsWithChildren<StepTitleProps>) => (
  <TimelineStyles.Title variant={variant}>{children}</TimelineStyles.Title>
);
