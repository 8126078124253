import { useTranslation } from 'react-i18next';

import { TimelineItemNowProps } from 'components/DealTimeline/DealTimeline.interface';
import { stepAnimatedProps } from 'components/DealTimeline/StepAnimation';
import { DealTimelineStep } from 'components/DealTimelineStep';

import { ILoanApi, LoanStatus } from 'lib/types';

export const shouldShowDealBasicInformation = (loan: ILoanApi) => {
  return [LoanStatus.missing_info].includes(loan?.status) && !loan?.notes;
};

export const DealBasicInformation = ({
  children,
  loan,
}: TimelineItemNowProps) => {
  const { t } = useTranslation();

  if (!shouldShowDealBasicInformation(loan)) {
    return null;
  }

  const isReady = loan?.canSendToEvaluation;

  const props = isReady
    ? {
        title: t('DealTimelineStep:ready_for_evaluation_title'),
        subtitle: t('DealTimelineStep:ready_for_evaluation_subtitle'),
        'data-testid': 'deal-basic-information-ready',
      }
    : {
        title: t('DealTimelineStep:basic_information_title'),
        subtitle: t('DealTimelineStep:basic_information_subtitle'),
        'data-testid': 'deal-basic-information',
      };

  return (
    <DealTimelineStep {...props} {...stepAnimatedProps}>
      {children}
    </DealTimelineStep>
  );
};
