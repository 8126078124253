import { atomWithStorage } from 'jotai/utils';

import {
  BasePersistenceAtom,
  getPersistenceKey,
} from 'components/ClosingChecklistPersistence';

import { IAcquiringLegalEntityData } from './AcquiringLegalEntity.interface';

export const acquiringLegalEntityAtom = atomWithStorage<
  BasePersistenceAtom<IAcquiringLegalEntityData>
>(getPersistenceKey('legalEntity'), {});
