import { styled } from '@boopos/design-system';

export const ContentTable = {
  Wrapper: styled('div', {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, max-content))',
    columnGap: '2.6rem',
    color: '$textBodyTertiary',
    'a, a:visited': {
      color: '$textBodyTertiary',
      textDecoration: 'none',
    },
  }),
  Label: styled('span', {}),
  Value: styled('span', {
    color: '$textBodyPrimary',
    '&& a, && a:visited': {
      color: '$textBodyPrimary',
      textDecoration: 'none',
    },
    variants: {
      numeric: {
        true: {
          fontFamily: '$mono',
        },
      },
      noWrap: {
        true: {
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },
      },
    },
  }),
};
