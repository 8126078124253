import { useTranslation } from 'react-i18next';

import { NavigationCard } from 'ui/NavigationCard';

import PlaidLogo from 'assets/plaid-logo.svg';

import { BankAccountsPreview } from 'components/BankAccountsPreview';

import { IBusinessAccount } from 'lib/types';

import { useRevenueBankAccountPlaid } from './RevenueBankAccountPlaid.hooks';

export interface RevenueBankAccountPlaidProps {
  businessId: string;
  accounts?: IBusinessAccount[];
  onSuccess?: () => Promise<void>;
  disabled?: boolean;
}

export const RevenueBankAccountPlaid = ({
  businessId,
  accounts,
  onSuccess,
  disabled,
}: RevenueBankAccountPlaidProps) => {
  const { t } = useTranslation();

  const { open, loading, bankAccounts, disconnectAccount } =
    useRevenueBankAccountPlaid({
      onSuccess,
      businessId,
      accounts,
    });

  if (bankAccounts?.length) {
    return (
      <BankAccountsPreview
        bankAccounts={bankAccounts}
        onConnectAccount={!disabled ? open : undefined}
        isLoadingConnect={loading}
        onDisconnectAccount={!disabled ? disconnectAccount : undefined}
      />
    );
  }

  return (
    <NavigationCard
      icon={
        <img
          src={PlaidLogo}
          width={105}
          height={40}
          alt={t('RevenueBankAccountPlaid:title')}
        />
      }
      title={t('RevenueBankAccountPlaid:title')}
      description={t('RevenueBankAccountPlaid:description')}
      as="button"
      onClick={open}
      loading={loading}
      data-testid="revenue-bank-account-plaid"
    />
  );
};
