import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  ApplicationDealStructureFormProps,
  DealStructureFormValues,
} from './ApplicationDealStructureForm.interface';
import { DealStructureFormFields } from './DealStructureFormFields';

export const ApplicationDealStructureForm = ({
  defaultValues,
  actionsComponent,
  onSubmit,
  onError,
  as,
}: ApplicationDealStructureFormProps) => {
  const Form = as ?? 'form';

  const [isSuccess, setIsSuccess] = useState(false);

  const { control, formState, handleSubmit } = useForm<DealStructureFormValues>(
    {
      defaultValues,
    }
  );

  const onSubmitInternal = useCallback(
    async (data: DealStructureFormValues) => {
      try {
        await onSubmit(data);
        setIsSuccess(true);
      } catch (err) {
        onError?.(err);
        console.error(err);
        setIsSuccess(false);
      }
    },
    [onSubmit, onError]
  );

  const isLoading = formState.isSubmitting;

  return (
    <Form
      onSubmit={handleSubmit(onSubmitInternal)}
      data-testid="deal-structure-form"
    >
      <DealStructureFormFields formState={formState} control={control} />
      {actionsComponent?.({ isSuccess, isLoading })}
    </Form>
  );
};
