import { AppState, Auth0Provider } from '@auth0/auth0-react';
import React, { PropsWithChildren, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import config from 'lib/config';

export const AuthProvider = ({
  children,
}: PropsWithChildren<any>): React.JSX.Element | null => {
  const navigate = useNavigate();

  const domain = config.AUTH0_DOMAIN;
  const clientId = config.AUTH0_CLIENT_ID;
  const audience = config.AUTH0_AUDIENCE;

  const onRedirectCallback = useCallback(
    (appState: AppState | undefined) => {
      navigate(appState?.returnTo ?? window.location.pathname, {
        state: { isFirstLogin: true },
      });
    },
    [navigate]
  );

  if (!(domain && clientId && audience)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience,
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
