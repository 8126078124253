import { useTranslation } from 'react-i18next';

import { DealTimelineStep } from 'components/DealTimelineStep';

import { PastActivityProps } from './PastActivity.interface';

export const CompletedClosingInformationActivity = ({
  children,
  date,
  ...rest
}: PastActivityProps) => {
  const { t } = useTranslation();

  return (
    <DealTimelineStep
      title={t('DealTimelineStep:completed_closing_information_title')}
      date={date}
      data-testid="activity-completed-closing-information"
      {...rest}
    >
      {children}
    </DealTimelineStep>
  );
};
