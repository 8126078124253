import { Trans } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { ListingForSale } from 'lib/types';

import { routePaths } from 'router/routePaths';

import { ListingDetailStyles } from './ListingDetail.styles';

export const ConfidentialNotice = ({
  listing,
}: {
  listing?: ListingForSale;
}) => {
  const location = useLocation();
  if (!listing?.requiresAgreement) {
    return null;
  }
  return (
    <ListingDetailStyles.StaticText data-testid="confidential-notice">
      <Trans i18nKey="ListingDetail:confidential_information_notice">
        <Link
          to={routePaths.confidentialityAgreementFull}
          state={{ backUrl: location.pathname }}
        />
      </Trans>
    </ListingDetailStyles.StaticText>
  );
};
