import { styled } from '@boopos/design-system';

export const LoadingModalInner = styled('div', {
  p: '$6',
  display: 'flex',
  flexDirection: 'column',
  jc: 'center',
  ai: 'center',
  gap: '$5',
});

export const LoadingModalIcon = styled('span', {
  variants: {
    success: {
      true: {
        svg: {
          color: '$lime600',
        },
      },
      false: {
        svg: {
          color: '$red600',
        },
      },
    },
  },
});
