const animationVariants = {
  hidden: { opacity: 0, height: 0, overflow: 'hidden' },
  visible: {
    opacity: 1,
    height: 'auto',
    overflow: 'initial',
  },
};

const stepAnimatedProps = {
  variants: animationVariants,
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
};

export { stepAnimatedProps };
