import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonFeedback } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DeleteConfirmationAction } from 'components/ConfirmationModal';
import { useProofOfFundsUpdate } from 'components/ProofOfFunds/useProofOfFundsUpdate';

export const RemoveFileButton = ({ fileId }: { fileId: string }) => {
  const { t } = useTranslation();
  const [deleting, setDeleting] = useState(false);
  const { deleteFile } = useProofOfFundsUpdate();

  const onDelete = useCallback(async () => {
    setDeleting(true);
    await deleteFile(fileId);
    setDeleting(false);
  }, [deleteFile, fileId]);

  return (
    <DeleteConfirmationAction
      title={t('ProofOfFundsValidate:delete_confirm')}
      onDelete={onDelete}
    >
      <ButtonFeedback
        leftIcon={<FontAwesomeIcon icon={regular('trash')} size="lg" />}
        variant="nude"
        isLoading={deleting}
        data-testid="delete-file-button"
        size="reduced"
        rounded
      />
    </DeleteConfirmationAction>
  );
};

export const removeAction = (fileId: string) => {
  return <RemoveFileButton fileId={fileId} />;
};
