import { styled } from '@boopos/design-system';
import { cutText } from '@boopos/utils';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AdditionalDetailsStyles = {
  IconWrapper: styled('div', {
    lineHeight: 0,
    color: '$slate500',
  }),
  Preview: styled('div', {
    color: '$slate500',
    fontStyle: 'italic',
  }),
};

export const AdditionalDetailsParent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',
});

export const AdditionalDetailsIcon = () => {
  return (
    <AdditionalDetailsStyles.IconWrapper>
      <FontAwesomeIcon icon={regular('plus')} size="lg" />
    </AdditionalDetailsStyles.IconWrapper>
  );
};

export const AdditionalDetailsPreview = ({
  additionalDetails,
}: {
  additionalDetails?: string;
}) => {
  if (!additionalDetails) return null;
  return (
    <AdditionalDetailsStyles.Preview>
      {cutText(additionalDetails, 70)}
    </AdditionalDetailsStyles.Preview>
  );
};
