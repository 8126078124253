import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, styled } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PageHeader } from 'ui/PageHeader';

import { DealDetail } from 'components/DealDetail';

import { ApiErrorBody } from 'lib/types';

import { routePaths } from 'router/routePaths';

import { HeaderBackButton } from './HeaderBackButton';

const Styles = {
  Container: styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.6rem',
    maxWidth: '48rem',
    mb: '3.2rem',
  }),
  IconWrapper: styled('div', {
    color: '$infoStatic',
  }),
  Title: styled('h2', {
    fontHeadingSerif: '$h2',
  }),
};

export const ForSalePageError = ({ error }: { error?: ApiErrorBody }) => {
  const { t } = useTranslation();

  const copiesByErrorCode: {
    [key: number]: {
      title: string;
      message: string;
      cta: string;
      icon: ReactNode;
    };
  } = {
    404: {
      title: t('ForSalePageError:listing_not_found_title'),
      message: t('ForSalePageError:listing_not_found_message'),
      cta: t('ForSalePageError:listing_not_found_cta'),
      icon: <FontAwesomeIcon icon={regular('face-hushed')} size="4x" />,
    },
    403: {
      title: t('ForSalePageError:listing_not_available_title'),
      message: t('ForSalePageError:listing_not_available_message'),
      cta: t('ForSalePageError:listing_not_available_cta'),
      icon: <FontAwesomeIcon icon={regular('eye-slash')} size="4x" />,
    },
  };

  const copies =
    copiesByErrorCode[error?.code ?? 404] ?? copiesByErrorCode[404];

  return (
    <>
      <Helmet>
        <title>{copies.title}</title>
      </Helmet>
      <DealDetail.Wrapper data-testid="for-sale-detail-error">
        <DealDetail.ContainerWrapper>
          <Styles.Container>
            <PageHeader
              title=""
              childrenTop={<HeaderBackButton />}
              data-testid="for-sale-page-header-error"
            />
            <Styles.IconWrapper>{copies.icon}</Styles.IconWrapper>
            <Styles.Title>{copies.title}</Styles.Title>
            <div>{copies.message}</div>
          </Styles.Container>
          <Button as={Link} to={routePaths.forSale}>
            {copies.cta}
          </Button>
        </DealDetail.ContainerWrapper>
      </DealDetail.Wrapper>
    </>
  );
};
