import { useTranslation } from 'react-i18next';

import { LoanTypesEnum } from 'lib/types';

export const useLoanTypeText = (type: LoanTypesEnum) => {
  const { t } = useTranslation();

  if (LoanTypesEnum.growth === type) {
    return t('LoanCard:loan_type_growth');
  }

  if (LoanTypesEnum.sale_seller === type) {
    return t('LoanCard:loan_type_sale');
  }

  if ([LoanTypesEnum.sale_buyer, LoanTypesEnum.purchase].includes(type)) {
    return t('LoanCard:loan_type_purchase');
  }
};
