import { AnimatePresence } from 'framer-motion';
import { useCallback, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';

import { ButtonFeedback } from '@boopos/design-system';

import {
  ActionSideModal,
  ActionSideModalCloseHeader,
  ActionSideModalCommonStyles,
  bottomNavAnimationVariants,
  useNavigateFromActionSideModal,
} from 'components/ActionSideModal';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { FormFilesUploader } from 'components/FormFilesUploader';

import { IBasicDocumentsUploadModalProps } from './BasicDocumentsUploadModal.interface';
import { useBasicDocumentsUpload } from './useBasicDocumentsUpload';

interface IOutletContext {
  handleRefreshLoan: () => Promise<void>;
  loanId?: string;
}

export const BasicDocumentsUploadModal = ({
  title,
  subtitle,
  documentType,
  acceptedFiles,
  basePath,
}: IBasicDocumentsUploadModalProps) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState<boolean>(false);

  const outletContext = useOutletContext<IOutletContext>() || {};
  const { handleRefreshLoan } = outletContext;

  const params = useParams();
  const loanId = outletContext.loanId ?? (params.loanId as string);

  const { onClose } = useNavigateFromActionSideModal(params, { basePath });

  const handleSuccess = useCallback(async () => {
    await handleRefreshLoan?.();
    setTimeout(() => {
      onClose();
    }, 200);
  }, [onClose, handleRefreshLoan]);

  const { onSubmit, formMethods, isSubmitting, isSuccess, isDirty } =
    useBasicDocumentsUpload({
      loanId,
      documentType,
      onSuccess: handleSuccess,
    });

  const { handleSubmit, control } = formMethods;
  const submitForm = handleSubmit(onSubmit);

  const handleClose = useCallback(() => {
    isDirty ? setOpened(true) : handleSuccess();
  }, [isDirty, handleSuccess]);

  const handleConfirm = useCallback(() => {
    setOpened(false);
    handleSubmit(onSubmit)();
  }, [handleSubmit, onSubmit]);

  const handleReject = useCallback(() => {
    setOpened(false);
    void handleSuccess();
  }, [handleSuccess]);

  const confirmButtonProps = {
    variant: 'primary',
    children: t('BasicDocumentsUploadModal:unsaved_save'),
    onClick: handleConfirm,
  };

  const cancelButtonProps = {
    variant: 'secondary',
    children: t('BasicDocumentsUploadModal:unsaved_close'),
    onClick: handleReject,
  };

  return (
    <>
      <ActionSideModal
        header={<ActionSideModalCloseHeader onClose={handleClose} />}
        onClose={handleClose}
      >
        <ActionSideModalCommonStyles.Title data-testid="upload-documents-modal-title">
          {title}
        </ActionSideModalCommonStyles.Title>
        <ActionSideModalCommonStyles.Subtitle>
          {subtitle}
        </ActionSideModalCommonStyles.Subtitle>
        <FormProvider {...formMethods}>
          <ActionSideModalCommonStyles.Form onSubmit={submitForm}>
            <FormFilesUploader
              name="documentFiles"
              control={control}
              accept={acceptedFiles}
              maxFiles={5}
              uploadParams={{ loanId }}
              disabled={isSubmitting || isSuccess}
            />
            <AnimatePresence>
              <ActionSideModalCommonStyles.BottomNav
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={bottomNavAnimationVariants}
              >
                <ActionSideModalCommonStyles.BottomNavLeftText />
                <ButtonFeedback
                  isLoading={isSubmitting}
                  isSuccess={isSuccess}
                  disabled={isSubmitting}
                >
                  {t('BasicDocumentsUploadModal:save_action')}
                </ButtonFeedback>
              </ActionSideModalCommonStyles.BottomNav>
            </AnimatePresence>
          </ActionSideModalCommonStyles.Form>
        </FormProvider>
      </ActionSideModal>
      <ConfirmationModal
        opened={opened}
        title={t('BasicDocumentsUploadModal:unsaved_title')}
        description={t('BasicDocumentsUploadModal:unsaved_subtitle')}
        confirmButtonProps={confirmButtonProps}
        cancelButtonProps={cancelButtonProps}
      />
    </>
  );
};
