import { useTranslation } from 'react-i18next';

import { truncateValue } from '@boopos/utils';

import { CardSection } from 'ui/CardSection';

import { BusinessTypesEnum } from 'lib/types';

import { QualityOfRevenuesCardProps } from './InsightsCard.interface';
import { CommonView, Heading, Loading, useRoundPercentil } from './partials';

export const QualityOfRevenuesCard = ({
  businessType,
  percentile,
  blur,
  basicView,
  ...rest
}: QualityOfRevenuesCardProps) => {
  const { t } = useTranslation();
  const roundedPercentile = useRoundPercentil(percentile);
  const isLoading = !percentile;

  const isSubscriptionBased =
    businessType === BusinessTypesEnum.subscriptionBased;

  const tooltipDescription = isSubscriptionBased
    ? t('Insights:QualityOfRevenuesCard:tooltip_description_subscription_based')
    : t('Insights:QualityOfRevenuesCard:tooltip_description');

  return (
    <CardSection {...rest}>
      <Heading
        heading={t('Insights:QualityOfRevenuesCard:heading')}
        tooltip={tooltipDescription}
        tooltipId="quality-of-revenues"
      />
      {isLoading ? (
        <Loading />
      ) : (
        <CommonView
          basicView={basicView}
          blur={blur}
          percentile={roundedPercentile}
          rankDescription={t(
            'Insights:QualityOfRevenuesCard:rank_description',
            {
              percentile: truncateValue(roundedPercentile),
            }
          )}
        />
      )}
    </CardSection>
  );
};
