import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContentCell } from 'ui/ContentCell';

import { AttachAskingPriceProps } from './AttachAskingPrice.interface';
import { useAttachAskingPrice } from './useAttachAskingPrice';

export const AttachAskingPrice = (props: AttachAskingPriceProps) => {
  const { t } = useTranslation();

  const { application, showIcon = true } = props;

  const { contentCellProps, children } = useAttachAskingPrice(props);

  if (!application) {
    return null;
  }

  return (
    <ContentCell
      title={t('ForSaleDetailApplication:asking_price_cell_title')}
      showIcon={showIcon}
      {...contentCellProps}
    >
      {children}
    </ContentCell>
  );
};
