import { generatePath } from 'react-router-dom';

import { routePaths } from 'router/routePaths';

interface IUseLoanDetailRoutes {
  loan: {
    id: string;
    business: {
      id: string;
    };
  };
}

export const useLoanDetailRoutes = ({ loan }: IUseLoanDetailRoutes) => {
  const { id: loanId, business } = loan;
  const businessId = business?.id;

  const safeBusinessId = businessId ? { businessId } : {};
  const safeLoanId = loanId ? { loanId } : {};

  const safeLoanIdAndBusinessId = { ...safeBusinessId, ...safeLoanId };

  const financingPath = generatePath(routePaths.portfolioFinancing, {
    ...safeBusinessId,
  });

  const profitAndLossPath = generatePath(routePaths.portfolioProfitAndLoss, {
    ...safeLoanIdAndBusinessId,
  });

  const balanceSheetPath = generatePath(routePaths.portfolioBalanceSheet, {
    ...safeLoanIdAndBusinessId,
  });

  const cohortsPath = generatePath(routePaths.portfolioCohorts, {
    ...safeLoanIdAndBusinessId,
  });

  const reviewTermSheetPath = generatePath(routePaths.portfolioTermSheet, {
    ...safeLoanIdAndBusinessId,
  });

  const einConfirmationPath = generatePath(
    routePaths.portfolioEinConfirmation,
    {
      ...safeLoanIdAndBusinessId,
    }
  );

  const articlesOfIncorporationPath = generatePath(
    routePaths.portfolioArticlesOfIncorporation,
    {
      ...safeLoanIdAndBusinessId,
    }
  );

  const shareholdersPath = generatePath(routePaths.portfolioShareholders, {
    ...safeLoanIdAndBusinessId,
  });

  const shareholdersAddPath = generatePath(
    routePaths.portfolioShareholdersAdd,
    {
      ...safeLoanIdAndBusinessId,
    }
  );

  const addAccountPath = generatePath(routePaths.portfolioAccounts, {
    ...safeLoanIdAndBusinessId,
  });

  return {
    financingPath,
    profitAndLossPath,
    balanceSheetPath,
    cohortsPath,
    reviewTermSheetPath,
    einConfirmationPath,
    articlesOfIncorporationPath,
    shareholdersPath,
    shareholdersAddPath,
    addAccountPath,
  };
};
