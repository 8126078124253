import { styled } from '@boopos/design-system';

export const FormStyles = {
  Split: styled('div', {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gridGap: '$4',
  }),
  UploaderWrapper: styled('div', {
    mb: '4.8rem',
  }),
};
