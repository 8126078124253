import React from 'react';
import { Navigate, generatePath, useParams } from 'react-router-dom';

import { routePaths } from 'router/routePaths';

import { generateRedirectionChild } from './RedirectionHandler.helpers';

const replacePath = (path: string) => path.replace('financing', 'loans');

export const oldLoanDetailPath = replacePath(routePaths.portfolioLoanDetail);

const redirectionMapping = [
  [oldLoanDetailPath, routePaths.portfolioLoanDetail],

  [
    replacePath(routePaths.portfolioPaymentMethod),
    routePaths.portfolioPaymentMethod,
  ],

  [
    replacePath(routePaths.portfolioProfitAndLoss),
    routePaths.portfolioProfitAndLoss,
  ],

  [
    replacePath(routePaths.portfolioBalanceSheet),
    routePaths.portfolioBalanceSheet,
  ],

  [replacePath(routePaths.portfolioCohorts), routePaths.portfolioCohorts],
  [replacePath(routePaths.portfolioTermSheet), routePaths.portfolioTermSheet],

  [
    replacePath(routePaths.portfolioEinConfirmation),
    routePaths.portfolioEinConfirmation,
  ],

  [
    replacePath(routePaths.portfolioArticlesOfIncorporation),
    routePaths.portfolioArticlesOfIncorporation,
  ],

  [replacePath(routePaths.portfolioAccounts), routePaths.portfolioAccounts],

  [
    replacePath(routePaths.portfolioShareholders),
    routePaths.portfolioShareholders,
  ],

  [
    replacePath(routePaths.portfolioShareholdersAdd),
    routePaths.portfolioShareholdersAdd,
  ],

  [
    replacePath(routePaths.portfolioShareholdersAdd),
    routePaths.portfolioShareholdersAdd,
  ],
];

export const PortfolioRedirectionHandler = () => {
  const RedirectionChild = generateRedirectionChild({
    redirections: redirectionMapping,
  });

  return RedirectionChild ? (
    RedirectionChild
  ) : (
    <Navigate to={routePaths.portfolio} replace />
  );
};

export const PortfolioFirstScreenDecider = () => {
  const params = useParams();
  const firstScreen = generatePath(routePaths.portfolioForSale, params);
  return <Navigate to={firstScreen} replace />;
};
