import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@boopos/design-system';

import { AmortizationTable } from 'components/TermSheetSummary/TermSheetSummary.styles';

import { formatMoney } from 'lib/format';
import { MoneyFormat } from 'lib/format/money/formatMoney.interface';
import { IAmortizationTableItem } from 'lib/types';

const moneyItemFormatConfig = {
  format: MoneyFormat.long,
  additionalOptions: {
    minimumFractionDigits: 2,
  },
};

export const AmortizationTableComponent = ({
  amortizationTable,
}: {
  amortizationTable: IAmortizationTableItem[];
}) => {
  const { t } = useTranslation();

  const tableItems = useMemo(() => {
    return amortizationTable.map(({ total, capital, interest }, index) => {
      return (
        <AmortizationTable.Tr key={`am-tr-${index}`}>
          <AmortizationTable.Td>{index + 1}</AmortizationTable.Td>
          <AmortizationTable.Td>
            {formatMoney(total, moneyItemFormatConfig)}
          </AmortizationTable.Td>
          <AmortizationTable.Td>
            {formatMoney(capital, moneyItemFormatConfig)}
          </AmortizationTable.Td>
          <AmortizationTable.Td>
            {formatMoney(interest, moneyItemFormatConfig)}
          </AmortizationTable.Td>
        </AmortizationTable.Tr>
      );
    });
  }, [amortizationTable]);

  return (
    <Box css={{ mt: '$6' }}>
      <AmortizationTable.Table>
        <AmortizationTable.Th>
          <AmortizationTable.Tr>
            <AmortizationTable.Td>
              {t('TermSheetSummary:mo')}
            </AmortizationTable.Td>
            <AmortizationTable.Td>
              {t('TermSheetSummary:payment')}
            </AmortizationTable.Td>
            <AmortizationTable.Td>
              {t('TermSheetSummary:principal')}
            </AmortizationTable.Td>
            <AmortizationTable.Td>
              {t('TermSheetSummary:interest')}
            </AmortizationTable.Td>
          </AmortizationTable.Tr>
        </AmortizationTable.Th>
        <AmortizationTable.TBody>{tableItems}</AmortizationTable.TBody>
      </AmortizationTable.Table>
    </Box>
  );
};
