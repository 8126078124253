import { HTMLAttributes, JSX } from 'react';

export enum Merchants {
  amazon = 'amazon',
  shopify = 'shopify',
  paddle = 'paddle',
  woocommerce = 'woocommerce',
  square = 'square',
  magento = 'magento',
  ebay = 'ebay',
  stripe = 'stripe',

  bank = 'bank',

  xero = 'xero',
  sage = 'sage',
  quickbooks = 'quickbooks',
  zoho = 'zoho',
}

export interface IUseAccountConnector
  extends HTMLAttributes<HTMLButtonElement> {
  provider: keyof typeof Merchants;
  onSuccess: (() => void) | (() => Promise<void>);
  businessId: string;
  token?: string;
  render?: (props: AccountConnectorRenderProps) => JSX.Element;
}

interface AccountConnectorRenderProps
  extends Omit<
    IUseAccountConnector,
    'provider' | 'businessId' | 'onSuccess' | 'token' | 'onClick' | 'render'
  > {
  disabled: boolean;
  onClick: () => Promise<void>;
}

export interface IUseAccountConnectorReturn {
  open: () => Promise<void>;
  ready: boolean;
  error: string | null | ErrorEvent;
  interacting?: boolean;
  onExit?: () => void;
}

export interface IStripeCallbackParams {
  code: string;
}

export interface IUseAccountConnectorsStatusReturn {
  isConnecting: boolean;
  success: boolean;
  error: boolean;
  startConnection: () => void;
  endConnection: () => void;
  addError: () => void;
  removeError: () => void;
  addSuccess: () => void;
  removeSuccess: () => void;
  reset: () => void;
  cancelConnection: () => void;
}

export enum MerchantType {
  revenueSource = 'revenueSource',
  erp = 'erp',
}
