import { ReactNode } from 'react';

import { formatMoney } from 'lib/format';
import {
  MoneyFormat,
  MoneyFormatConfig,
} from 'lib/format/money/formatMoney.interface';
import { Money } from 'lib/types';

export interface RenderMoneyFormattedProps {
  title: ReactNode;
  amount?: Money;
  moneyConfig?: MoneyFormatConfig;
  renderSeparator?: () => ReactNode;
  renderIfZero?: boolean;
  labelComponent?: any;
  valueComponent?: any;
  valueProps?: any;
  labelProps?: any;
}

const defaultRenderSeparator = () => ': ';
const defaultMoneyConfig = {
  format: MoneyFormat.long,
};

export const RenderMoneyFormatted = ({
  title,
  amount,
  moneyConfig = defaultMoneyConfig,
  renderSeparator = defaultRenderSeparator,
  renderIfZero = false,
  labelComponent = 'span',
  valueComponent = 'span',
  valueProps = {},
  labelProps = {},
}: RenderMoneyFormattedProps) => {
  if (!amount) {
    return null;
  }

  if (!renderIfZero && amount.amount === 0) {
    return null;
  }

  const Label = labelComponent;
  const Value = valueComponent;

  return (
    <>
      <Label {...labelProps}>{title}</Label>
      {renderSeparator()}
      <Value {...valueProps}>{formatMoney(amount, moneyConfig)}</Value>
    </>
  );
};
