import { useEffect, useRef } from 'react';

export const useUnload = (handler: Function, deps: any[] = []) => {
  const eventListenerRef = useRef<(event: BeforeUnloadEvent) => void>(() => {
    // noop
  });

  useEffect(() => {
    eventListenerRef.current = (event: BeforeUnloadEvent) => {
      handler(event);
    };
  }, [handler]);

  useEffect(() => {
    const eventListener = (event: BeforeUnloadEvent) =>
      eventListenerRef.current(event);
    window.addEventListener('unload', eventListener);
    return () => {
      window.removeEventListener('unload', eventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
