import React, { useCallback, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { OnboardingLayout } from 'components/OnboardingLayout';
import { PortfolioApplicationFlowPaths } from 'components/PortfolioApplication/PortfolioApplication.interface';

import { routePaths } from 'router/routePaths';
import { SellerApplicationRoutePaths } from 'router/sellerApplicationRoutePaths';

import { PortfolioApplicationContext } from './PortfolioApplicationRouteWrapper.interface';
import { useClosePath } from './useClosePath';

const useCloseConfirmationModal = (
  flowPaths: PortfolioApplicationFlowPaths
) => {
  const navigate = useNavigate();

  const closePath = useClosePath(flowPaths);

  const onLeaveForLater = useCallback(() => {
    if (!closePath) {
      return;
    }
    navigate(closePath);
  }, [closePath, navigate]);

  const onCloseClick = useCallback(() => {
    onLeaveForLater();
  }, [onLeaveForLater]);

  return {
    onCloseClick,
    onLeaveForLater,
    closePath,
  };
};

export const PortfolioApplicationRouteWrapper = ({
  paths,
}: {
  paths: SellerApplicationRoutePaths;
}) => {
  const flowPaths = useMemo<PortfolioApplicationFlowPaths>(
    () => ({
      sellAddBusiness: paths.addBusiness,
      valuationAddBusiness: paths.valuation,
      applicationFinished: paths.applicationFinished,
      /*
       * These routes are hardcoded because they are not part of the flow.
       * The other routes should be dynamic and should be able to be changed
       * from the outside.
       * */
      fallback: routePaths.portfolio,
      sellLandingAfterFinish: routePaths.portfolioBusiness,
      valuationLandingAfterFinish: routePaths.portfolioValuation,
    }),
    [paths]
  );

  const { onCloseClick, closePath } = useCloseConfirmationModal(flowPaths);

  const portfolioApplicationContext: PortfolioApplicationContext = useMemo(
    () => ({
      flowPaths,
      closePath,
      onCloseClick,
    }),
    [flowPaths, closePath, onCloseClick]
  );

  return (
    <OnboardingLayout.Wrapper data-testid="route-wrapper">
      <Outlet context={portfolioApplicationContext} />
    </OnboardingLayout.Wrapper>
  );
};
