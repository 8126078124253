import { Merchants } from 'components/AccountConnectors';

import {
  BankAccountMetadata,
  IApiDocument,
  IBusinessAccount,
  IUploadedFile,
  Money,
} from 'lib/types';

export enum QualificationStatusApi {
  EMPTY = 'empty',
  UNDER_REVIEW = 'under_review',
  MISSING_INFO = 'missing_info',
  QUALIFIED = 'qualified',
  VERIFIED = 'verified',
  REJECTED = 'rejected',
}

export interface IQualificationBankAccount extends IBusinessAccount {
  description: string;
  id: string;
  _id: string;
  name: string;
  externalId: string;
  provider: Merchants.bank;
  metadata?: BankAccountMetadata;
}

export interface IQualificationStatusFunds {
  disposableAmount?: Money;
  status?: QualificationStatusApi;
  notes?: string;
  documents?: IApiDocument[];
  bankAccounts?: IQualificationBankAccount[];
  verifiedDisposableAmount?: Money;
  maxFunding?: Money;
}

export interface IQualificationCreditReportAddress {
  street: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
}

export interface IQualificationStatusCreditReport {
  status?: QualificationStatusApi;
  empty?: boolean;
  residency?: string;
  ssn?: string;
  address?: IQualificationCreditReportAddress | null;
}

export interface IQualificationStatusLinkedin {
  status?: QualificationStatusApi;
  empty?: boolean;
}

export interface IQualificationStatus {
  status?: QualificationStatusApi;
  hasItemsUnderReview?: boolean;
  funds: IQualificationStatusFunds;
  creditReport: IQualificationStatusCreditReport;
  linkedin: IQualificationStatusLinkedin;
  userNotes?: string;
  notesMissingInfo?: string;
  notesRejected?: string;
  qualified?: boolean;
}

export interface IUseProofOfFunds {
  funds: IQualificationStatusFunds;
  updateDisposableAmount: (value: Money) => Promise<void>;
  updateProofOfFundDocuments: (files: IUploadedFile[]) => Promise<void>;
  deleteFile: (fileId: string) => Promise<void>;
  hasDocumentsUploaded: boolean;
  hasBankAccountsConnected: boolean;
  isReady: boolean;
  missingInfoNotes?: string;
}
