import { PortfolioApplicationFlowPaths } from 'components/PortfolioApplication/PortfolioApplication.interface';

import { LoanTypesEnum } from 'lib/types';

import {
  ApplicationTypeByLoanType,
  NavigationPaths,
  PortfolioApplicationSteps,
  PortfolioApplicationType,
} from './PortfolioApplicationFlow.interface';

export const getNavigationPaths = (
  flowPaths: PortfolioApplicationFlowPaths
): NavigationPaths => ({
  [PortfolioApplicationType.sell]: {
    [PortfolioApplicationSteps.addBusiness]: flowPaths.sellLandingAfterFinish,
  },
  [PortfolioApplicationType.valuation]: {
    [PortfolioApplicationSteps.addBusiness]: flowPaths.sellLandingAfterFinish,
  },
});

export const getApplicationTypeByLoanType = ({
  loanType,
  applicationType,
}: {
  loanType?: LoanTypesEnum;
  applicationType?: PortfolioApplicationType;
}) => {
  if (applicationType) {
    return applicationType;
  }

  if (loanType) {
    return ApplicationTypeByLoanType[loanType] ?? PortfolioApplicationType.sell;
  }

  return PortfolioApplicationType.sell;
};
