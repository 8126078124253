import qs from 'query-string';
import { useCallback } from 'react';
import { generatePath } from 'react-router-dom';

import { IUseAccountConnector } from 'components/AccountConnectors/AccountConnectors.interface';
import { useSharedHandlers } from 'components/AccountConnectors/useSharedHandlers';

import { useAuth } from 'lib/auth';
import { defaultWindowFeatures, parseWindowFeatures } from 'lib/utils';

import { routePaths } from 'router/routePaths';

export const useCustomLink = ({
  provider,
  businessId,
  onSuccess,
  token,
}: IUseAccountConnector) => {
  const { handleSuccess, setWindowRef, startConnection, addError } =
    useSharedHandlers({ provider, businessId, onSuccess });

  const { getAccessToken } = useAuth();

  const open = useCallback(async () => {
    try {
      startConnection();

      const query = {
        token: token ?? (await getAccessToken()),
        businessId,
      };

      const urlToOpen = `${generatePath(
        routePaths.initCustomProviderConnection,
        {
          provider,
        }
      )}?${qs.stringify(query)}`;

      window.customCallback = handleSuccess;

      setWindowRef(
        window.open(
          urlToOpen,
          `connect-${provider}`,
          parseWindowFeatures(defaultWindowFeatures)
        )
      );
    } catch (e) {
      addError();
    }
  }, [
    setWindowRef,
    handleSuccess,
    provider,
    startConnection,
    addError,
    businessId,
    getAccessToken,
    token,
  ]);

  return { open, ready: true, error: null };
};
