import * as RadixSlider from '@radix-ui/react-slider';

import { styled } from '@boopos/design-system';

export const SliderRoot = styled(RadixSlider.Root, {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  userSelect: 'none',
  touchAction: 'none',
  width: '100%',
  height: '2.4rem',
});

export const SliderTrack = styled(RadixSlider.Track, {
  backgroundColor: '$decorationBeigeSoft',
  position: 'relative',
  flexGrow: 1,
  borderRadius: '1.6rem',
  height: '.8rem',
});

export const SliderRange = styled(RadixSlider.Range, {
  position: 'absolute',
  backgroundColor: '$decorationOliveBright',
  borderRadius: '9999px',
  height: '100%',
});

export const SliderThumb = styled(RadixSlider.Thumb, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  lineHeight: 0,
  width: '2.4rem',
  height: '2.4rem',
  backgroundColor: '$surfaceElevated',
  border: '2px solid $borderInteractiveDefault',
  borderRadius: '2.4rem',
  '&:hover': {
    backgroundColor: '$surfaceSelected',
    borderColor: '$borderInteractiveHover',
  },
  '&active': {
    borderColor: '$borderInteractiveHover',
  },
  '&:focus': {
    outline: 'none',
    borderColor: '$borderInteractiveHover',
  },
});
