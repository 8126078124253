import { ReactElement, cloneElement, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { Box, ButtonFeedback } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AvailableFundsInput } from './AvailableFundsInput';
import { AvailableFundsFormValues } from './ProofOfFunds.interface';
import { preloadProofOfFunds } from './preloadProofOfFunds';
import { useProofOfFundsUpdate } from './useProofOfFundsUpdate';

export interface AvailableFundsFormProps {
  continueButtonEnabled?: boolean;
  defaultValues: AvailableFundsFormValues;
  onSuccess?: () => Promise<void> | void;
  appendChildren?: ReactElement;
}

export const AvailableFundsFormLoading = () => {
  return (
    <div data-testid="available-funds-form-loading">
      <Skeleton height={24} borderRadius={8} width={100} />
      <Skeleton height={40} borderRadius={8} />
      <Box css={{ mt: '$6' }} />
      <Skeleton height={40} borderRadius={8} />
    </div>
  );
};

export const AvailableFundsForm = ({
  defaultValues,
  continueButtonEnabled = true,
  onSuccess,
  appendChildren,
}: AvailableFundsFormProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { t } = useTranslation();

  const formMethods = useForm<AvailableFundsFormValues>({ defaultValues });

  const { handleSubmit } = formMethods;

  const { updateDisposableAmount } = useProofOfFundsUpdate();

  const onSubmit = useCallback(
    async (data: AvailableFundsFormValues) => {
      setIsLoading(true);
      const { data: updatedData } = await updateDisposableAmount(data);
      await preloadProofOfFunds(updatedData);
      setIsLoading(false);
      setIsSuccess(true);
      setTimeout(async () => {
        setIsSuccess(false);
        await onSuccess?.();
      }, 1000);
    },
    [updateDisposableAmount, onSuccess]
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AvailableFundsInput />
        {continueButtonEnabled && (
          <ButtonFeedback
            isLoading={isLoading}
            isSuccess={isSuccess}
            data-testid="available-funds-continue-button"
            css={{ mt: '$4' }}
            rightIcon={<FontAwesomeIcon icon={regular('arrow-right')} />}
          >
            {t('AvailableFundsForm:continue')}
          </ButtonFeedback>
        )}
      </form>
      {appendChildren
        ? cloneElement(appendChildren, {
            ...appendChildren,
            onSubmit,
            isLoading,
            isSuccess,
          })
        : null}
    </FormProvider>
  );
};
