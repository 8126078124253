import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { BackButton } from 'components/BackButton';
import { InvestmentCriteriaBanner } from 'components/InvestmentCriteriaBanner';
import { useInvestmentCriteriaBanner } from 'components/InvestmentCriteriaBanner/useInvestmentCriteriaBanner';
import { InvestmentCriteriaBreakdown } from 'components/InvestmentCriteriaBreakdown';
import { LayoutHideMenu } from 'components/Layout';
import {
  AnimatedScreenSlideWrapper,
  SubNavigationFullWidth,
} from 'components/ScreenLayout';
import { SubScreenTitle } from 'components/SubScreenTitle';

import { HSWidgetHide } from 'lib/hubspot';

import { routePaths } from 'router/routePaths';

export const InvestmentCriteriaProfileScreen = () => {
  const { t } = useTranslation();
  const { isInvestmentCriteriaCompleted } = useInvestmentCriteriaBanner();

  return (
    <>
      <Helmet>
        <title>{t('AccountScreen:investment_thesis_title')}</title>
      </Helmet>
      <AnimatedScreenSlideWrapper>
        <LayoutHideMenu hideOn="all" shouldShowMenuOnUnmount={false} />
        <SubNavigationFullWidth>
          <BackButton to={routePaths.account} />
          {isInvestmentCriteriaCompleted ? (
            <>
              <SubScreenTitle>
                {t('AccountScreen:investment_thesis_title')}
              </SubScreenTitle>
              <InvestmentCriteriaBreakdown />
            </>
          ) : (
            <InvestmentCriteriaBanner />
          )}
        </SubNavigationFullWidth>
        <HSWidgetHide />
      </AnimatedScreenSlideWrapper>
    </>
  );
};
