import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ContentCell } from 'ui/ContentCell';

import { useSubmitForEvaluationStep } from 'components/SubmitForEvaluation';

import { IBusinessAccount } from 'lib/types';

import { AccountsDetails } from './AccountsDetails';
import { AttachPaymentProcessorsProps } from './AttachPaymentProcessors.interface';
import { useConnectedAccounts } from './useConnectedAccounts';

export const AttachPaymentProcessors = (
  props: AttachPaymentProcessorsProps
) => {
  const location = useLocation();
  const { t } = useTranslation();

  const { editPath, businessId, path, showIcon, editable = true } = props;

  const { accounts } = useConnectedAccounts(businessId);

  const hasAccounts = !!accounts?.length;
  const finalPath = hasAccounts ? editPath : path;

  const { contentCellProps, hasValue } = useSubmitForEvaluationStep<
    IBusinessAccount[]
  >({
    path: finalPath,
    entity: accounts,
    editable,
    actionLiteral: t('ForSaleDetailApplication:connect_payment_processors'),
    hasValueGetter: (entity) => !!entity?.length,
  });

  const extraProps = contentCellProps.to
    ? { state: { goBackState: location.pathname } }
    : {};

  if (!editable && !hasValue) return null;

  return (
    <ContentCell
      title={t(
        'ForSaleDetailApplication:connect_payment_processors_cell_title'
      )}
      showIcon={showIcon}
      {...contentCellProps}
      {...extraProps}
    >
      <AccountsDetails accounts={accounts} />
    </ContentCell>
  );
};
