import { IApiDocument, IUploadedFile } from 'lib/types';

import { getCurrentDocumentsFilteringType } from './getCurrentDocumentsFilteringType';
import { getCurrentOrEmptyDocument } from './getDocumentFiles';

export const addNewFilesToCurrentDocuments = (
  documents: IApiDocument[] | undefined,
  documentType: string,
  uploadedFiles: IUploadedFile[] | undefined
) => {
  const currentDocuments = getCurrentDocumentsFilteringType(
    documents,
    documentType
  );

  const currentDocument = getCurrentOrEmptyDocument(documentType, documents);

  const newFiles = (uploadedFiles || []).map((file) => ({
    name: file.fileName,
    url: file.url,
  }));

  return [
    ...currentDocuments,
    {
      ...currentDocument,
      files: [...currentDocument.files, ...newFiles],
    },
  ];
};
