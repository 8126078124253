import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { InsightsChartsCard } from './InsightsChartsCard.styles';

const useChartsOrEmpty = <T,>(
  exists: (rawData?: T) => boolean,
  rawData?: T,
  error?: unknown
) => {
  const { t } = useTranslation();

  const isLoading = rawData === undefined && !error;
  const isEmpty = (rawData !== undefined && !exists(rawData)) || error;

  const emptyChart = (
    <InsightsChartsCard.Empty>
      <FontAwesomeIcon icon={regular('cloud-slash')} size="3x" />
      <div>{t('InsightsCharts:chart_error')}</div>
    </InsightsChartsCard.Empty>
  );

  const getChart = (chart: React.ReactNode) => {
    if (isLoading) {
      return (
        <div>
          <Skeleton />
          <Skeleton width="50%" />
          <Skeleton width="35%" />
        </div>
      );
    }

    if (isEmpty) {
      return emptyChart;
    }

    return chart;
  };

  return { getChart };
};

export { useChartsOrEmpty };
