import { getPercentageChange } from './getPercentageChange';

export function calculateChanges(oldValue: number, newValue: number) {
  const oldestValue = oldValue ?? 0;
  const newestValue = newValue ?? 0;

  const absoluteChange = newestValue - oldestValue;
  const percentageChange = getPercentageChange(absoluteChange, oldestValue);

  return {
    absoluteChange,
    percentageChange,
  };
}
