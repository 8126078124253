import { useTranslation } from 'react-i18next';

import { theme } from '@boopos/design-system';

import { ListingChart } from 'components/ListingChart';

import { formatMoney } from 'lib/format';
import { MoneyFormat } from 'lib/format/money/formatMoney.interface';
import { ListingForSale } from 'lib/types';

import { ListingDetailStyles } from './ListingDetail.styles';
import { ListingInfoItem } from './ListingInfoItem';

const activeDot = {
  fill: theme.colors.slate700.computedValue,
};

export const ListingFinancialData = ({
  listing,
}: {
  listing: ListingForSale;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ListingInfoItem
        title={t('ListingFinancialData:asking_price')}
        size="medium"
      >
        {(listing.askPrice?.amount ?? 0) === 0 ? (
          <span className="">
            {t('ListingFinancialData:price_on_application')}
          </span>
        ) : (
          formatMoney(listing.askPrice, { format: MoneyFormat.long })
        )}
      </ListingInfoItem>
      <ListingDetailStyles.InfoGrid>
        {(listing.revenue?.amount ?? 0) !== 0 && (
          <ListingInfoItem
            title={t('ListingFinancialData:ttm_revenue')}
            tipHelp={t('ListingFinancialData:ttm_revenue_tip_help')}
          >
            {formatMoney(listing.revenue, { format: MoneyFormat.long })}
          </ListingInfoItem>
        )}
        {(listing.profit?.amount ?? 0) !== 0 && (
          <ListingInfoItem
            title={t('ListingFinancialData:ttm_profit')}
            tipHelp={t('ListingFinancialData:ttm_profit_tip_help')}
          >
            {formatMoney(listing.profit, { format: MoneyFormat.long })}
          </ListingInfoItem>
        )}
      </ListingDetailStyles.InfoGrid>
      <ListingChart
        revenueHistory={listing.revenueHistory}
        currency={listing?.revenueMonth?.currency}
        height={72}
        fill={theme.colors.decorationOliveSoft.computedValue}
        areaProps={{
          activeDot,
        }}
        tooltipReduced
      />
    </>
  );
};
