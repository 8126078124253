import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  AuthParams,
  AuthParamsBuyerTargets,
  AuthParamsUserGoals,
} from 'lib/auth/authParams';

export interface OnboardingLocationState {
  autoSetGoal?: keyof typeof AuthParamsUserGoals;
  autoSetBuyerTarget?: keyof typeof AuthParamsBuyerTargets;
  firstName?: string;
  lastName?: string;
  nextScreenOverwrite?: string;
}

export const useOnboardingState = () => {
  const [searchParams] = useSearchParams();

  return useMemo(() => {
    const {
      [AuthParams.userGoal]: userGoal,
      [AuthParams.firstName]: firstName,
      [AuthParams.lastName]: lastName,
      [AuthParams.landingPage]: nextScreenOverwrite,
      [AuthParams.buyerTarget]: buyerTarget,
    } = Object.fromEntries(searchParams.entries());
    return {
      autoSetGoal: userGoal as keyof typeof AuthParamsUserGoals | undefined,
      autoSetBuyerTarget: buyerTarget as
        | keyof typeof AuthParamsBuyerTargets
        | undefined,
      firstName,
      lastName,
      nextScreenOverwrite,
    } as OnboardingLocationState;
  }, [searchParams]);
};
