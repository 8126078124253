import { keyframes, styled } from '@boopos/design-system';

const pulse = keyframes({
  '0%': { boxShadow: '0 0 0 0px rgba(69,144,50, 0.4)' },
  '100%': { boxShadow: '0 0 0 10px rgba(69,144,50, 0)' },
});

/*
 * animation: `${spin} .675s linear 0s infinite normal`,*/

export const AccountStyles = {
  Hint: styled('span', {
    color: '$textBodyTertiary',
  }),
  StatusMessage: styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '.8rem',
    color: '$infoPositive',
  }),
  Pulse: styled('span', {
    animation: `${pulse} 2s infinite`,
    color: '$textBodyTertiary',
    width: '.8rem',
    height: '.8rem',
    borderRadius: '50%',
    boxShadow: '0px 0px 1px 1px $colors$infoPositive',
    background: '$infoPositive',
  }),
};
