import { useTranslation } from 'react-i18next';

import { DealTimelineStep } from 'components/DealTimelineStep';

import { PastActivityProps } from './PastActivity.interface';

export const TargetBusinessAddedActivity = ({
  date,
  children,
  ...rest
}: PastActivityProps) => {
  const { t } = useTranslation();

  return (
    <DealTimelineStep
      title={t('DealTimelineStep:target_business_added')}
      date={date}
      data-testid="activity-target-business-added"
      {...rest}
    >
      {children}
    </DealTimelineStep>
  );
};
