import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ButtonFeedback } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  ILinkedInCVInputForm,
  LinkedInCVInput,
} from 'components/LinkedInCVInput';

import { useUser } from 'providers/UserProvider';

import { parseFromApi, parseToApi } from './LinkedInCVForm.utils';

interface LinkedInCVFromProps {
  onSuccess?: () => Promise<void> | void;
}

export const LinkedInCVFrom = ({ onSuccess }: LinkedInCVFromProps) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const { t } = useTranslation();
  const { user, updateUser } = useUser();

  const formMethods = useForm<ILinkedInCVInputForm>({
    defaultValues: parseFromApi(user),
  });

  const { formState, handleSubmit, reset } = formMethods;

  const onSubmit = useCallback(
    async (data: ILinkedInCVInputForm) => {
      await updateUser(parseToApi(data));
      await onSuccess?.();
      reset(data);
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 1000);
    },
    [updateUser, reset, onSuccess]
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <LinkedInCVInput />
        <ButtonFeedback
          type="submit"
          isLoading={formState.isSubmitting}
          isSuccess={isSuccess}
          rightIcon={<FontAwesomeIcon icon={regular('arrow-right')} />}
        >
          {t('QualifyScreen:continue_action')}
        </ButtonFeedback>
      </form>
    </FormProvider>
  );
};
