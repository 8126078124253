import { useTranslation } from 'react-i18next';

import { Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  ActionSideModalCommonStyles,
  bottomNavAnimationVariants,
} from 'components/ActionSideModal';

import { FormatUserInputText } from 'lib/format/literal/FormatUserInputText';

export const BottomNav = ({
  hasInvitations,
  hasSelfConnected,
  onContinue,
  isVerified,
}: {
  hasInvitations: boolean;
  hasSelfConnected: boolean;
  onContinue: () => void;
  isVerified?: boolean;
}) => {
  const { t } = useTranslation();

  const ContinueButton = (
    <Button
      type="button"
      variant="secondary"
      data-testid="continue-button"
      onClick={onContinue}
      rightIcon={<FontAwesomeIcon icon={regular('arrow-right')} />}
    >
      {t('AccountConnectionModal:continue')}
    </Button>
  );

  return (
    <ActionSideModalCommonStyles.BottomNav
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={bottomNavAnimationVariants}
      data-testid="account-connection-modal-bottom-nav"
    >
      {isVerified && (
        <ActionSideModalCommonStyles.BottomNavCenterText>
          <FontAwesomeIcon
            icon={regular('check-double')}
            size="lg"
            pull="left"
          />
          {t('DealClosingCheckList:verified')}
        </ActionSideModalCommonStyles.BottomNavCenterText>
      )}
      {!isVerified && (
        <>
          {hasInvitations && (
            <ActionSideModalCommonStyles.BottomNavLeftText data-testid="invitations-text">
              {t('AccountConnectionModal:invitation_sent')}
            </ActionSideModalCommonStyles.BottomNavLeftText>
          )}
          {hasSelfConnected && (
            <ActionSideModalCommonStyles.BottomNavLeftText data-testid="accounts-text">
              <FormatUserInputText
                text={t('AccountConnectionModal:accounts_will_be_reviewed')}
              />
            </ActionSideModalCommonStyles.BottomNavLeftText>
          )}
          {ContinueButton}
        </>
      )}
    </ActionSideModalCommonStyles.BottomNav>
  );
};
