import { truncateValue } from '@boopos/utils';

import { Styles } from './Range.styles';

export interface RangeProps {
  value: number;
  maxValue: number;
  status: 'ok' | 'ko';
  blur?: boolean;
}

export const Range = ({ value, maxValue, status, blur }: RangeProps) => {
  return (
    <Styles.Wrapper>
      <Styles.Light status={status} />
      <Styles.Value blur={blur}>{truncateValue(value)}</Styles.Value>
      <Styles.MaxValue>/{maxValue}</Styles.MaxValue>
    </Styles.Wrapper>
  );
};
