import { Styles } from './Heading.styles';
import { Tooltip } from './Tooltip';

export interface Props {
  heading: string;
  tooltip: string;
  tooltipId: string;
}

export const Heading = ({ heading, tooltip, tooltipId }: Props) => {
  return (
    <Styles.Heading>
      {heading}
      <Tooltip id={tooltipId} description={tooltip} />
    </Styles.Heading>
  );
};
