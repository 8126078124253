import { styled } from '@boopos/design-system';

const fakeMenuItemsCss = {
  background: '$slate50',
  px: '$2',
  py: '$3',
  borderRadius: '.8rem',
  mt: '$4',
  mx: 'auto',
  '@bp2': {
    maxWidth: 81,
  },
};

const CursorFloating = styled('div', {
  position: 'absolute',
  zIndex: 1,
  bottom: -16,
  right: 10,
  transform: 'rotate(-30deg)',
});

export { fakeMenuItemsCss, CursorFloating };
