import { ReactNode, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { CurrencyInput } from 'components/CurrencyInput';
import { NumberInput } from 'components/NumberInput/NumberInput';
import { SliderHookForm } from 'components/SliderHookForm';

import { defaultCurrency } from 'lib/constants';
import { Currency } from 'lib/types';

import { Styles } from './RangeFilter.styles';

interface RangeFilterProps {
  name: string;
  min: number;
  max: number;
  defaultValue: number[];
  step: number;
  minLabel?: ReactNode;
  maxLabel?: ReactNode;
  inputType?: 'currency' | 'number';
}

const getInputType = (
  inputType: 'currency' | 'number',
  { currency }: { currency?: Currency }
) => {
  if (inputType === 'currency') {
    return { Component: CurrencyInput, props: { currency } };
  }

  return { Component: NumberInput, props: {} };
};

export const RangeFilter = ({
  name,
  min,
  max,
  minLabel,
  maxLabel,
  defaultValue,
  inputType = 'currency',
  step,
}: RangeFilterProps) => {
  const { watch, control, setValue } = useFormContext();

  const value = watch(name) ?? defaultValue;

  const currency = watch('currency') ?? defaultCurrency;

  const [minSelected, maxSelected] = value;

  const onChangeMin = useCallback(
    (e: any) => {
      setValue(name, [e.target.rawValue, maxSelected]);
    },
    [maxSelected, name, setValue]
  );

  const onChangeMax = useCallback(
    (e: any) => {
      setValue(name, [minSelected, e.target.rawValue]);
    },
    [minSelected, name, setValue]
  );

  const { Component: InputField, props: inputProps } = getInputType(inputType, {
    currency,
  });

  return (
    <Styles.Wrapper>
      <SliderHookForm
        name={name}
        min={min}
        max={max}
        step={step}
        defaultValue={defaultValue}
        control={control}
      />
      <Styles.SliderLabel data-testid="field-value">
        <InputField
          label={minLabel}
          name={name}
          onChange={onChangeMin}
          value={minSelected}
          fullWidth
          groupSpacing="none"
          {...inputProps}
        />
        <InputField
          label={maxLabel}
          name={name}
          onChange={onChangeMax}
          value={maxSelected}
          fullWidth
          groupSpacing="none"
          {...inputProps}
        />
      </Styles.SliderLabel>
    </Styles.Wrapper>
  );
};
