import { KeyboardEvent, MouseEvent, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { KeywordsForm } from 'screens/InvestmentCriteriaScreen/InvestmentCriteria.interface';

import { useInvestmentCriteriaNavigation } from './useInvestmentCriteriaNavigation';
import { useSaveInvestmentCriteria } from './useSaveInvestmentCriteria';

const apiKeywords = [
  'App',
  'Website',
  'Marketplace',
  'Crypto',
  'Shopify',
  'SEO',
  'Google Ads',
  'Facebook Ads',
  'LinkedIn',
  'AI',
  'Project Management',
  'Design',
  'Marketing',
  'Copywriting',
  'Data Visualisation',
  'Fintech',
  'Payment Gateways',
];

export const useInvestmentCriteriaKeywordsScreen = () => {
  const { exitPath, navigateToNextPath } = useInvestmentCriteriaNavigation();

  const { loading, success, investmentCriteria, save } =
    useSaveInvestmentCriteria({
      onSave: navigateToNextPath,
    });

  const formMethods = useForm<KeywordsForm>({
    defaultValues: {
      keywords: [],
      customKeywords: [],
    },
  });

  const { watch, setValue } = formMethods;
  const keywords = watch('keywords');
  const customKeywords = watch('customKeywords');
  const isContinueDisabled = !(keywords.length || customKeywords.length);

  const handleSubmit = useCallback(
    (data: KeywordsForm) => {
      save({
        ...investmentCriteria,
        keywords: [...data.keywords, ...data.customKeywords].filter(
          (value, index, self) => self.indexOf(value) === index
        ),
      });
    },
    [investmentCriteria, save]
  );

  const onPresetKeywordClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      const keyword = e.currentTarget.dataset.keyword;
      if (!keyword) {
        return;
      }

      if (keywords.includes(keyword)) {
        setValue(
          'keywords',
          keywords.filter((k) => k !== keyword)
        );
        return;
      }

      setValue('keywords', [...keywords, keyword]);
    },
    [setValue, keywords]
  );

  const onRemoveCustomKeyword = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      const keyword = e.currentTarget.dataset.keyword;
      if (!keyword) {
        return;
      }

      setValue(
        'customKeywords',
        customKeywords.filter((k) => k !== keyword)
      );

      setValue(
        'keywords',
        keywords.filter((k) => k !== keyword)
      );
    },
    [setValue, customKeywords, keywords]
  );

  const onInputKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') {
        return;
      }

      e.preventDefault();
      e.stopPropagation();

      const value = e.currentTarget.value;

      if (!value) {
        return;
      }

      setValue('customKeywords', [
        ...customKeywords.filter((keyword) => keyword !== value),
        value,
      ]);

      e.currentTarget.value = '';
    },
    [setValue, customKeywords]
  );

  const onSubmit = formMethods.handleSubmit(handleSubmit);

  useEffect(() => {
    if (!investmentCriteria?.keywords) return;

    setValue('keywords', investmentCriteria.keywords);

    setValue(
      'customKeywords',
      investmentCriteria.keywords.filter(
        (x: string) => !apiKeywords.includes(x)
      )
    );
  }, [investmentCriteria?.keywords, setValue]);

  return {
    apiKeywords,
    keywords,
    customKeywords,
    exitPath,
    loading,
    success,
    isContinueDisabled,
    onPresetKeywordClick,
    onInputKeyDown,
    onRemoveCustomKeyword,
    onSubmit,
  };
};
