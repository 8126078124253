import { styled } from '@boopos/design-system';

export const StyledCard = styled('div', {
  p: '$4',
  borderRadius: '.8rem',
  background: '$white',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.15), 0px 0px 4px rgba(0, 0, 0, 0.05)',
  mb: '$4',
});

export const StyledNameContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  '> div:first-child': {
    flex: 1,
  },
});

export const StyledName = styled('div', {
  fontSize: '1.8rem',
  lineHeight: '2.4rem',
  fontWeight: 'bold',
  display: 'flex',
});

export const StyledBadgesContainer = styled('div', {
  display: 'flex',
  '> div:not(:last-child)': {
    mr: '$1',
  },
});

export const StyledData = styled('div', {
  mt: '$4',
});
