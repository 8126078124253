import { Residency } from 'components/CreditReport';
import { useQualificationStatus } from 'components/QualificationStatus';

export const useUserResidency = () => {
  const { creditReport } = useQualificationStatus();

  const isUsResident = creditReport?.residency === Residency.us;

  return {
    isUsResident,
  };
};
