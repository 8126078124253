import * as Sentry from '@sentry/react';
import { useCallback } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { useAsync } from 'react-use';

import { Box } from '@boopos/design-system';

import { BuyerInsightsCheckout } from 'components/BuyerInsights/BuyerInsights.interface';
import { FullScreenLoader } from 'components/FullScreenLoader';

import Api from 'lib/api';

import { defaultLandingUrl } from 'router/routePaths';

const BusinessInsightsCallback = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const successPath =
    searchParams.get('navigateAfterConfirmation') ?? defaultLandingUrl;

  const { businessInsightsId, businessId } = params;

  const handleConfirm = useCallback(
    async (businessInsightsId: string, businessId: string) => {
      try {
        const { data } =
          await Api.getBusinessInsightsCheckout<BuyerInsightsCheckout>(
            businessInsightsId,
            businessId,
            {}
          );
        return data;
      } catch (err) {
        console.error('Error confirming business insights checkout', err);
        Sentry.captureException(err);
        return undefined;
      }
    },
    []
  );

  const { loading, value } = useAsync(async () => {
    const actualBusinessId = businessId ?? '';
    if (!businessInsightsId) return;
    return await handleConfirm(businessInsightsId, actualBusinessId);
  }, [businessInsightsId]);

  if (loading) {
    return (
      <Box css={{ position: 'fixed', inset: 0 }} data-testid="loading">
        <FullScreenLoader />
      </Box>
    );
  }

  if (!value) {
    return (
      <Navigate
        to={{
          pathname: successPath,
          search: `?error=1`,
        }}
        replace
      />
    );
  }

  return <Navigate to={successPath} replace />;
};

export { BusinessInsightsCallback };
