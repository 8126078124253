import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';

import { InlineMessage } from 'ui/InlineMessage';

import { BusinessCard } from 'components/BusinessCard';

import {
  setLastElementRef,
  useInfiniteScroll,
} from 'lib/hooks/useInfiniteScroll';

import { routePaths } from 'router/routePaths';

import { usePortfolioList } from './PortfolioList.hooks';
import { PortfolioListEmptyState } from './PortfolioListEmptyState';

export const PortfolioList = () => {
  const { t } = useTranslation();

  const { data, isReachingEnd, loadNextPage, isEmpty, isFirstLoading, error } =
    usePortfolioList();

  const lastElementRef = useInfiniteScroll(isReachingEnd, loadNextPage);

  if (error) {
    return (
      <InlineMessage
        type="warning"
        heading={t('PortfolioList:error_message')}
        data-testid="portfolio-list-error"
      />
    );
  }

  if (isFirstLoading) {
    return (
      <div data-testid="portfolio-list-loading">
        {t('PortfolioList:loading_message')}
      </div>
    );
  }

  if (isEmpty) {
    return <PortfolioListEmptyState />;
  }

  return (
    <div data-testid="portfolio-list">
      {data.map((item, index) => (
        <BusinessCard
          key={item.business.id}
          as={Link}
          ref={setLastElementRef(data, index, lastElementRef)}
          data={item}
          to={generatePath(routePaths.portfolioBusiness, {
            businessId: item.business.id,
          })}
        />
      ))}
    </div>
  );
};
