import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  OnboardingHeader,
  OnboardingLayout,
} from 'components/OnboardingLayout';

import { ConfidentialityAgreementStyles } from './ConfidentialityAgreement.styles';
import { useConfidentialityAgreement } from './useConfidentialityAgreement';

export const ConfidentialityAgreement = () => {
  const { t } = useTranslation();
  const { handleAgree, handleBack, agreementTranslations } =
    useConfidentialityAgreement();
  const goBackProps = null;

  return (
    <OnboardingLayout.Wrapper data-testid="confidentiality-agreement-screen">
      <OnboardingHeader.Wrapper>
        <OnboardingHeader.ActionsWrapper>
          <OnboardingHeader.Action {...(goBackProps ?? {})}>
            {!!goBackProps && <OnboardingHeader.BackArrow />}
          </OnboardingHeader.Action>
          <OnboardingHeader.Action
            as="a"
            data-testid="skip-button"
            onClick={handleBack}
            alignment="right"
          >
            <OnboardingHeader.CloseXMark />
          </OnboardingHeader.Action>
        </OnboardingHeader.ActionsWrapper>
        <OnboardingHeader.Steps />
        <OnboardingHeader.Title>
          {t('ConfidentialityAgreement:title')}
        </OnboardingHeader.Title>
        <OnboardingHeader.Subtitle>
          {t('ConfidentialityAgreement:description')}
        </OnboardingHeader.Subtitle>
      </OnboardingHeader.Wrapper>
      <OnboardingLayout.Content>
        <OnboardingLayout.ContentWrapper>
          <ConfidentialityAgreementStyles.AgreementContent>
            <ConfidentialityAgreementStyles.AgreementScrollContent
              dangerouslySetInnerHTML={{
                __html: `<p>${t(
                  'ConfidentialityAgreement:policy_title'
                )}</p>${agreementTranslations}`,
              }}
            />
          </ConfidentialityAgreementStyles.AgreementContent>
          <Button
            onClick={handleAgree}
            rightIcon={<FontAwesomeIcon icon={regular('arrow-right')} />}
          >
            {t('ConfidentialityAgreement:agree_action')}
          </Button>
        </OnboardingLayout.ContentWrapper>
      </OnboardingLayout.Content>
    </OnboardingLayout.Wrapper>
  );
};
