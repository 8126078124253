import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { useRoundPercentil } from 'components/InsightsCards/partials';

import api from 'lib/api';

import { HistogramChart } from 'screens/BusinessValuationScreen/HistogramChart';

import { EstimatedMultipleRange } from './EstimatedMultipleRange';
import { EstimatedValueRange } from './EstimatedValueRange';
import { IValuationApi } from './ValuationCard.interface';
import { Styles } from './ValuationCard.styles';
import { ValuationLoading } from './ValuationLoading';

export const ValuationCard = () => {
  const { t } = useTranslation();
  const { businessId } = useParams() || {};

  const { data } = useSWR<IValuationApi>(
    businessId && api.getBusinessValuation(businessId)
  );

  const valuationData = data?.valuation;

  const multiplePercentile = useRoundPercentil(
    valuationData?.multiple?.percentile ?? 0
  );

  if (!valuationData) {
    return <ValuationLoading />;
  }

  const isValuationAvailable = valuationData.valuation.low.amount > 0;

  return (
    <Styles.ValuationSection data-testid="valuation-card">
      <Styles.EstimatedValuesSection>
        <EstimatedValueRange
          isValuationAvailable={isValuationAvailable}
          valuation={valuationData.valuation}
        />
        <EstimatedMultipleRange
          isValuationAvailable={isValuationAvailable}
          multiple={valuationData.multiple}
        />
      </Styles.EstimatedValuesSection>
      <Styles.Text>
        {t('Insights:ValuationCard:valuation_rank', {
          percentile: multiplePercentile,
          businessType: valuationData.type,
        })}
      </Styles.Text>
      <Styles.HistogramSection border={true}>
        <Styles.Text>
          {t('Insights:ValuationCard:histogram_legend', {
            businessType: valuationData.type,
          })}
        </Styles.Text>
        <HistogramChart
          data={valuationData.bins}
          multiple={valuationData.multiple}
          businessTypeName={valuationData.type}
        />
      </Styles.HistogramSection>
    </Styles.ValuationSection>
  );
};
