import { useCallback } from 'react';
import useSWR from 'swr';

import { urlHostnameMatchHostnameList, validUrl } from '@boopos/utils';

import Api from 'lib/api';

import { UseBusinessWebsiteValidationConfig } from './useBusinessWebsiteValidation.interface';

const emptyArray: string[] = [];

export const useBusinessWebsiteValidation = (
  config: UseBusinessWebsiteValidationConfig
) => {
  const { shouldValidateBrokerUrl, invalidUrlMessage, brokerUrlMessage } =
    config;

  const { data } = useSWR<{ url: string }[]>(Api.getBrokersList());

  const brokerUrls = data?.map((broker) => broker.url) ?? emptyArray;

  const isBrokerUrl = useCallback(
    async (value: string) => {
      if (!shouldValidateBrokerUrl) {
        return false;
      }
      return urlHostnameMatchHostnameList(value, brokerUrls, {
        wwwMatching: true,
      });
    },
    [shouldValidateBrokerUrl, brokerUrls]
  );

  const isNotABrokerUrl = useCallback(
    async (v: string) => {
      const isBroker = await isBrokerUrl(v);
      return !isBroker || brokerUrlMessage;
    },
    [brokerUrlMessage, isBrokerUrl]
  );

  const isValidUrl = useCallback(
    (value: string) => validUrl(value) || invalidUrlMessage,
    [invalidUrlMessage]
  );

  return { isNotABrokerUrl, isValidUrl };
};
