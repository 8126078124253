import React, { MouseEvent, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { InlineFeedback, Link } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  ERP_LIST,
  MERCHANTS_LIST,
  MerchantType,
  Merchants,
  useAccountConnectorsStatus,
} from 'components/AccountConnectors';
import { BankLink } from 'components/AccountConnectors/bank/BankLink';
import { BackButton } from 'components/BackButton';
import { ConnectAccountInvitationModal } from 'components/ConnectAccountInvitationModal';
import { LayoutHideMenu } from 'components/Layout';
import { LoadingModal } from 'components/LoadingModal';
import {
  AnimatedScreenOpacityWrapper,
  FullWidthHeightScroll,
  ScreenWrapper,
} from 'components/ScreenLayout';

import { generatePathSafe } from 'lib/utils/generatePathSafe';

import {
  BankAccountIconWrapper,
  BankAccountWrapper,
  BoxesWrapper,
  Wrapper,
  buttonClassName,
} from './ConnectAccountScreen.styles';
import { RenderBasedOnInvitationAccountType } from './RenderBasedOnInvitationAccountType';

interface IConnectAccountScreen {
  showInfoHint?: boolean;
  goBackUrl: string;
}

const ConnectAccountHint = () => {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);

  const handleGivePermissionsClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.stopPropagation();
      setIsOpened(true);
    },
    []
  );

  const handleClose = useCallback(() => {
    setIsOpened(false);
  }, []);

  return (
    <>
      <InlineFeedback
        data-testid="connect-account-hint"
        variant="info"
        expandable
        defaultExpanded={true}
        heading={t('ConnectAccountScreen:invitation_title')}
        actions={{
          primary: (
            <Link
              data-testid="connect-account-hint-link"
              css={{ color: '$slate700' }}
              onClick={handleGivePermissionsClick}
            >
              {t('ConnectAccountScreen:give_access')}
            </Link>
          ),
        }}
      >
        {t('ConnectAccountScreen:invitation_description')}
      </InlineFeedback>
      <ConnectAccountInvitationModal opened={isOpened} onClose={handleClose} />
    </>
  );
};

export const ConnectAccountScreen = (props: IConnectAccountScreen) => {
  const params = useParams();
  const { businessId, token } = params;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { goBackUrl, showInfoHint = true } = props;

  const goBackPath = generatePathSafe(goBackUrl, params) ?? '';

  const location = useLocation();
  const { invitationAccountType, goBackState } = (location?.state || {}) as {
    invitationAccountType: MerchantType | undefined;
    goBackState?: string;
  };

  const {
    isConnecting,
    success,
    error,
    cancelConnection,
    reset,
    addSuccess,
    endConnection,
  } = useAccountConnectorsStatus();

  const handleSuccess = useCallback(() => {
    addSuccess();
    endConnection();
  }, [addSuccess, endConnection]);

  const navigateOnContinue = goBackState ?? goBackPath;

  const handleContinue = useCallback(() => {
    reset();
    navigate(navigateOnContinue);
  }, [navigateOnContinue, navigate, reset]);

  return (
    <>
      <Helmet>
        <title>{t('ConnectAccountScreen:add_new')}</title>
      </Helmet>
      <AnimatedScreenOpacityWrapper>
        <FullWidthHeightScroll>
          <LayoutHideMenu />
          <ScreenWrapper>
            <Wrapper>
              <BackButton
                hideOnDesktop={false}
                Icon={<FontAwesomeIcon icon={regular('xmark')} size="lg" />}
                to={navigateOnContinue}
              />
              {!!businessId && !invitationAccountType && (
                <BankAccountWrapper>
                  <BankAccountIconWrapper>
                    <FontAwesomeIcon
                      icon={regular('building-columns')}
                      size="2xl"
                    />
                  </BankAccountIconWrapper>
                  <BankLink
                    businessId={businessId}
                    provider={Merchants.bank}
                    onSuccess={handleSuccess}
                  >
                    {t('ConnectAccountScreen:connect_with_plaid')}
                  </BankLink>
                </BankAccountWrapper>
              )}
              <BoxesWrapper>
                <RenderBasedOnInvitationAccountType
                  invitationAccountType={invitationAccountType}
                  desiredType={MerchantType.revenueSource}
                >
                  {MERCHANTS_LIST.map(([provider, MerchantComponent]) => (
                    <MerchantComponent
                      key={provider}
                      provider={provider}
                      onSuccess={handleSuccess}
                      businessId={businessId}
                      className={buttonClassName()}
                      token={token}
                    />
                  ))}
                </RenderBasedOnInvitationAccountType>
                <RenderBasedOnInvitationAccountType
                  invitationAccountType={invitationAccountType}
                  desiredType={MerchantType.erp}
                >
                  {ERP_LIST.map(([provider, ErpComponent]) => (
                    <ErpComponent
                      key={provider}
                      provider={provider}
                      onSuccess={handleSuccess}
                      businessId={businessId}
                      className={buttonClassName()}
                    />
                  ))}
                </RenderBasedOnInvitationAccountType>
              </BoxesWrapper>
              {showInfoHint && <ConnectAccountHint />}
            </Wrapper>
          </ScreenWrapper>
        </FullWidthHeightScroll>
      </AnimatedScreenOpacityWrapper>
      <LoadingModal
        loadingText={t('ConnectAccountScreen:loading')}
        successText={t('ConnectAccountScreen:success_message')}
        errorText={t('ConnectAccountScreen:error_message')}
        isLoading={isConnecting}
        isSuccess={success}
        isError={error}
        onCancel={cancelConnection}
        onContinue={handleContinue}
        opened={isConnecting || success || error}
      />
    </>
  );
};
