import { ReactNode } from 'react';
import { Navigate, matchRoutes, useLocation } from 'react-router-dom';

import { useUserGoals } from 'providers/UserProvider/useUserGoals';
import { whiteListRouteObjects } from 'providers/notRedirectingWhitelist';

import { routePaths } from 'router/routePaths';

import { useOnboardingState } from 'screens/LandingScreens/useOnboardingState';

import { LandingScreenMemo } from './LandingScreenMemo';

interface ILocationState {
  isFirstLogin: boolean;
}

export const FirstAppScreenDecider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const location = useLocation();
  const { hasGoals } = useUserGoals();

  const isFirstLogin = (location?.state as ILocationState)?.isFirstLogin;
  const matchWhiteListedRoute = matchRoutes(whiteListRouteObjects, location);

  const landingScreenMemo = `${location.pathname}${location.search}`;

  const onboardingState = useOnboardingState();

  if (!isFirstLogin || matchWhiteListedRoute) {
    return <>{children}</>;
  }

  if (!hasGoals) {
    return (
      <>
        <LandingScreenMemo pathname={landingScreenMemo} />
        <Navigate to={routePaths.onboarding} state={onboardingState} />
      </>
    );
  }

  return <>{children}</>;
};
