import { useTranslation } from 'react-i18next';
import { NavLink, generatePath, useMatch, useParams } from 'react-router-dom';

import { TabMenuItem, TabsMenu } from 'ui/TabsMenu';

import { IBusinessInsights } from 'lib/types';

export interface BuyerInsightsTabMenuProps {
  businessInsights?: IBusinessInsights;
  loanApplicationPath: string;
  businessInsightsPath: string;
}

export const BuyerInsightsTabMenu = (props: BuyerInsightsTabMenuProps) => {
  const params = useParams();
  const { businessInsights, loanApplicationPath, businessInsightsPath } = props;
  const { t } = useTranslation();
  const enabled = !!businessInsights;

  const loanApplicationEnd = useMatch(businessInsightsPath);

  if (!enabled) {
    return null;
  }

  return (
    <TabsMenu data-testid="buyer-insights-tab-menu" css={{ mt: '$4' }}>
      <TabMenuItem
        as={NavLink}
        to={generatePath(loanApplicationPath, params)}
        replace
        end={!!loanApplicationEnd}
      >
        {t('BuyerInsightsTabMenu:details')}
      </TabMenuItem>
      <TabMenuItem
        as={NavLink}
        to={generatePath(businessInsightsPath, params)}
        replace
      >
        {t('BuyerInsightsTabMenu:business_insights')}
      </TabMenuItem>
    </TabsMenu>
  );
};
