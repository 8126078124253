import {
  InsightsPreparedData,
  UnitEconomicTotals,
} from 'components/InsightsCharts/InsightsCharts.interface';

import { getAverage } from './getAverage';

const getSum = (
  data: InsightsPreparedData[],
  key: keyof InsightsPreparedData
) => {
  return data.reduce((acc, curr) => Number(acc) + Number(curr[key]), 0);
};

export const calculateUnitEconomicTotals = (
  data: InsightsPreparedData[]
): UnitEconomicTotals => {
  const revenuesSum = getSum(data, 'revenueAmount');
  const cogsPercentageSum = getSum(data, 'cogsPercentageOfRevenue');
  const profitPercentageSum = getSum(data, 'profitPercentageOfRevenue');

  const cogsAverage = getAverage(cogsPercentageSum, data);
  const profitAverage = getAverage(profitPercentageSum, data);

  return {
    revenuesSum,
    cogsAverage,
    profitAverage,
  };
};
