import { useTranslation } from 'react-i18next';

import { Heading } from 'components/InsightsCards/partials';
import { IMultipleRange } from 'components/ValuationCard/ValuationCard.interface';
import { MultiplierValue } from 'components/ValuationCard/partials/MultiplierValue';

import { Styles } from './EstimatedMultipleRange.styles';

export const EstimatedMultipleRange = ({
  multiple,
  isValuationAvailable,
}: {
  multiple: IMultipleRange;
  isValuationAvailable: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Heading
        heading={t('Insights:ValuationCard:estimated_multiple_range')}
        tooltip={t(
          'Insights:ValuationCard:estimated_multiple_range_description'
        )}
        tooltipId="estiamted-multiple-range-tooltip"
      />
      {isValuationAvailable ? (
        <Styles.Wrapper data-testid="multiple-range">
          <Styles.Legend />
          <MultiplierValue value={multiple.low} />
          <Styles.Dash>-</Styles.Dash>
          <MultiplierValue value={multiple.high} />
        </Styles.Wrapper>
      ) : (
        <Styles.NotAvailable data-testid="valuation-not-available">
          {t('Insights:ValuationCard::multiple_range_not_available')}
        </Styles.NotAvailable>
      )}
    </div>
  );
};
