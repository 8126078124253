export const sliceStartAndFinish = (
  value: string = '',
  sliceChars: number = 4
) => {
  if (value?.length <= sliceChars * 2) return value;

  const first = value.slice(0, sliceChars);
  const last = value.slice(-sliceChars);

  const lastWithDots = last ? `...${last}` : '';

  return `${first}${lastWithDots}`;
};
