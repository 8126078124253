import React, { ReactNode } from 'react';

export const RenderIfDefined = ({
  value,
  children,
}: {
  value?: number | null;
  children: ReactNode;
}) => {
  if (value === undefined || value === null) {
    return null;
  }

  return <>{children}</>;
};
