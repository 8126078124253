import { styled } from '@boopos/design-system';

export const ScreenLayout = {
  Wrapper: styled('div', {
    px: '$4',
    mb: '4rem',
    mt: '$9',

    '@bp2': {
      px: '$8',
    },
  }),
  Actions: styled('div', {
    display: 'flex',
    justifyContent: 'flex-end',
    mb: '$4',
  }),
  Content: styled('div', {}),
  EmptyStateMessage: styled('p', {
    width: '46.8rem',
    margin: '10rem auto',
    textAlign: 'center',
    color: '$slate700',
  }),
};
