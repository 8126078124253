import { useTranslation } from 'react-i18next';

import { ClosingStepsNames, ILoanApi } from 'lib/types';

import {
  businessApaConfirmation,
  dealClosingSteps,
  prepareContractSteps,
  repaymentSteps,
} from './ClosingChecklistDetails.utils';
import { ClosingChecklistGroup } from './ClosingChecklistGroup';
import { DueDiligenceMissingInfo } from './DueDiligenceMissingInfo';

export const ClosingChecklistDetails = ({
  loan,
  editable = true,
}: {
  loan: ILoanApi;
  editable?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <DueDiligenceMissingInfo loan={loan} />

      <ClosingChecklistGroup
        title={t('DealTimelineStep:closing_deal_information_title')}
        steps={dealClosingSteps.filter(
          (step) => step !== ClosingStepsNames.termSheet
        )}
        loan={loan}
        data-testid="deal-closing-deal-information"
        editable={editable}
      />

      <ClosingChecklistGroup
        title={t('DealTimelineStep:closing_prepare_contract_title')}
        steps={prepareContractSteps}
        loan={loan}
        data-testid="deal-closing-prepare-contract"
        editable={editable}
      />

      <ClosingChecklistGroup
        title={t('DealTimelineStep:closing_repayment_title')}
        steps={repaymentSteps}
        loan={loan}
        data-testid="deal-closing-repayment"
        editable={editable}
      />

      <ClosingChecklistGroup
        title={t('DealTimelineStep:closing_apa_title')}
        steps={businessApaConfirmation}
        loan={loan}
        data-testid="deal-closing-apa"
        editable={editable}
        isLast
      />
    </>
  );
};
