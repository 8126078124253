import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@boopos/design-system';

import qualifyFaces from 'assets/qualify-faces.png';

import { QualifyAction } from 'components/QualificationBanner';
import {
  QualificationStatusApi,
  useQualificationStatus,
  useVerifiedDisposableAmount,
} from 'components/QualificationStatus';

import { formatMoney } from 'lib/format';
import { ListingForSale, LoanAffordability } from 'lib/types';

import { routePaths } from 'router/routePaths';

import { ModalStyles } from './Modal.styles';
import { useListingUnlockModal } from './useListingUnlockModal';

const GotoQualification = ({ children }: { children: ReactNode }) => {
  const { close } = useListingUnlockModal();
  const { t } = useTranslation();
  return (
    <QualifyAction>
      {(props) => (
        <Button fullWidth onClick={close} {...props}>
          {t('ListingUnlockModal:missing_info_cta')}
        </Button>
      )}
    </QualifyAction>
  );
};

export const useListingUnlockModalConfig = (listing?: ListingForSale) => {
  const qualification = useQualificationStatus();
  const verifiedDisposableAmount = useVerifiedDisposableAmount();
  const { close } = useListingUnlockModal();
  const { t } = useTranslation();

  if (qualification?.status === QualificationStatusApi.EMPTY) {
    return {
      title: t('ListingUnlockModal:empty_title'),
      content: (
        <>
          <ModalStyles.Description>
            {t('ListingUnlockModal:empty_description')}
          </ModalStyles.Description>
          <ModalStyles.Image
            src={qualifyFaces}
            alt={t('ListingUnlockModal:empty_description')}
          />
          <QualifyAction>
            {(props) => (
              <Button fullWidth onClick={close} {...props}>
                {t('ListingUnlockModal:empty_cta')}
              </Button>
            )}
          </QualifyAction>
        </>
      ),
    };
  }

  if (qualification?.status === QualificationStatusApi.UNDER_REVIEW) {
    return {
      title: t('ListingUnlockModal:under_review_title'),
      content: (
        <>
          <ModalStyles.Description>
            {t('ListingUnlockModal:under_review_description')}
          </ModalStyles.Description>
          <Button fullWidth onClick={close}>
            {t('ListingUnlockModal:under_review_cta')}
          </Button>
        </>
      ),
    };
  }

  const DoItLater = (
    <Button variant="secondary" fullWidth onClick={close}>
      {t('ListingUnlockModal:do_it_later')}
    </Button>
  );

  if (qualification?.status === QualificationStatusApi.MISSING_INFO) {
    return {
      title: t('ListingUnlockModal:missing_info_title'),
      content: (
        <>
          <ModalStyles.Description>
            {t('ListingUnlockModal:missing_info_description')}
          </ModalStyles.Description>
          <ModalStyles.Actions>
            <GotoQualification>
              {t('ListingUnlockModal:missing_info_cta')}
            </GotoQualification>
            {DoItLater}
          </ModalStyles.Actions>
        </>
      ),
    };
  }

  if (qualification?.status === QualificationStatusApi.REJECTED) {
    return {
      title: t('ListingUnlockModal:rejected_title'),
      content: (
        <>
          <ModalStyles.Description>
            {t('ListingUnlockModal:rejected_description')}
          </ModalStyles.Description>
          <ModalStyles.Actions>
            <GotoQualification>
              {t('ListingUnlockModal:rejected_cta')}
            </GotoQualification>
            {DoItLater}
          </ModalStyles.Actions>
        </>
      ),
    };
  }

  if (listing && listing?.loanAffordability !== LoanAffordability.affordable) {
    return {
      title: t('DealLendingScope:not_financial_fit_title'),
      content: (
        <>
          <ModalStyles.Description>
            {t('DealLendingScope:not_financial_fit_content', {
              amount: verifiedDisposableAmount?.formatted,
              requiredFunds: formatMoney(listing?.requiredFunds),
            })}
          </ModalStyles.Description>
          <ModalStyles.Actions>
            <Button
              fullWidth
              onClick={close}
              as={Link}
              to={routePaths.qualificationProofOfFunds}
            >
              {t('DealLendingScope:not_financial_fit_cta')}
            </Button>

            {DoItLater}
          </ModalStyles.Actions>
        </>
      ),
    };
  }

  return undefined;
};
