import { styled } from '@boopos/design-system';

export const PagePartials = {
  AbsoluteWrapper: styled('div', {
    position: 'absolute',
    inset: 0,
    overflow: 'hidden',
    '@bp2': {
      overflow: 'auto',
    },
  }),
};
