import React, { useCallback } from 'react';

import { Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DeleteConfirmationAction } from 'components/ConfirmationModal';

import api from 'lib/api';
import { ILoanApi, LoanStatus } from 'lib/types';

import { useDeleteDealConfirm } from './useDeleteDealConfirm';

interface IDeleteDealConfirmProps {
  status?: keyof typeof LoanStatus;
  loanId?: string;
  onActionSuccess?: () => void;
  loan?: ILoanApi;
}

export const DeleteDealConfirm = ({
  onActionSuccess,
  loan,
  ...props
}: IDeleteDealConfirmProps) => {
  const status = loan ? loan?.status : props.status;
  const loanId = loan ? loan.id : props.loanId;

  const message = useDeleteDealConfirm({ status });

  const handleDeleteDeal = useCallback(async () => {
    if (!loanId) return;
    await api.deleteBusinessDeal(loanId);
    onActionSuccess?.();
  }, [loanId, onActionSuccess]);

  if (!message) return null;

  return (
    <DeleteConfirmationAction
      title={message.title}
      description={message.description}
      deleteIcon={<FontAwesomeIcon icon={regular('trash')} size="lg" />}
      onDelete={handleDeleteDeal}
    >
      <Button
        data-testid="delete-deal-button"
        variant="secondary"
        leftIcon={<FontAwesomeIcon icon={regular('trash')} size="lg" />}
        rounded
      />
    </DeleteConfirmationAction>
  );
};
