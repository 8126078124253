import { styled } from '@boopos/design-system';

const FilePreviewAction = styled('div', {
  borderRadius: '.8rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '4.8rem',
  height: '4.8rem',
  backgroundColor: '$actionSecondaryDefault',
  color: '$textButtonSecondary',
});

export const FilePreview = {
  Thumbnail: styled('div', {
    width: '100%',
    maxWidth: '13rem',
    background: '$backgroundInverted',
    color: '$textBodyInverted',
    padding: '1.6rem',
    fontHeadingSerif: '$h4',
    fontSize: '1.3rem',
    display: 'flex',
    alignItems: 'center',
    height: '8.7rem',
  }),
  Wrapper: styled('a', {
    borderRadius: '1.6rem',
    backgroundColor: '$surfaceBase',
    border: '1px solid $borderStatic',
    overflow: 'hidden',
    display: 'flex',
    gap: '1.6rem',
    '&, &:hover': {
      textDecoration: 'none',
    },
    cursor: 'pointer',
    pr: '1.6rem',
    alignItems: 'center',
    '&:hover': {
      [`${FilePreviewAction}`]: {
        backgroundColor: '$actionSecondaryHover',
      },
    },
  }),
  Action: FilePreviewAction,
  Content: styled('div', {
    display: 'flex',
    alignItems: 'center',
    color: '$textBodySecondary',
    fontWeight: 500,
    flex: 1,
    variants: {
      blur: {
        true: {
          filter: 'blur(4px)',
        },
      },
    },
  }),
};
