import { Helmet } from 'react-helmet-async';
import { Outlet, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { styled } from '@boopos/design-system';

import { AnimatedScreenOpacityWrapper } from 'components/ScreenLayout';

import api from 'lib/api';
import { IBusinessApi } from 'lib/types';

import { BusinessDetailHeader } from './BusinessDetailHeader';
import { BusinessDetailInfo } from './BusinessDetailInfo';
import { BusinessDetailLoading } from './BusinessDetailLoading';
import { BusinessDetailTabsMenu } from './BusinessDetailTabsMenu';

const PortfolioLayout = styled('div', {
  maxWidth: '113.6rem',
  margin: '0 auto',
});

export const BusinessDetailScreen = () => {
  const params = useParams();
  const { businessId } = params || {};

  const { data: business } = useSWR<IBusinessApi>(
    businessId && api.getPortfolioBusiness(businessId)
  );

  const isLoading = typeof business === 'undefined';
  const title = business?.name;

  const renderBusinessDetail = () => {
    if (isLoading) {
      return <BusinessDetailLoading />;
    }

    return (
      <>
        <BusinessDetailHeader title={title} businessId={businessId} />
        <BusinessDetailInfo business={business} />
        <BusinessDetailTabsMenu />
        <Outlet />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AnimatedScreenOpacityWrapper data-testid="business-detail-screen">
        <PortfolioLayout>{renderBusinessDetail()}</PortfolioLayout>
      </AnimatedScreenOpacityWrapper>
    </>
  );
};
