import { MerchantType, Merchants } from 'components/AccountConnectors';

import {
  ClosingStepsNames,
  IBusinessAccount,
  IBusinessInvitation,
} from 'lib/types';

export interface AccountConnectionModalProps {
  loanId: string;

  onClose: () => void;

  copies: {
    mainModalTitle: string;
    mainModalSubtitle: string;

    inviteTitle: string;
    inviteDescription: string;
    sendInvite: string;
    sendAnotherInvite: string;
    invitationsTitle: string;

    connectedBySellerTitle: string;
    selfConnectedTitle: string;

    connectTitle: string;
    connectDescription: string;
    connectMySelf: string;
    connectAnother: string;

    connectModalTitle: string;

    inviteModalTitle: string;
    inviteModalDescription: string;
  };

  accountType: MerchantType;
}

export interface IUseAccountConnectionModal {
  loanId: string;
  accountType: MerchantType;
  excludedProvider?: Merchants;
}

export interface IUseAccountConnectionModalReturn {
  ready: boolean;
  businessId?: string;
  businessInvitations: IBusinessInvitation[];
  selfConnectedAccounts: IBusinessAccount[];
  sellerConnectedAccounts: IBusinessAccount[];

  connectModalOpened: boolean;

  openConnectModal: () => void;
  closeConnectModal: () => void;

  inviteModalOpened: boolean;
  openInviteModal: () => void;
  closeInviteModal: () => void;
  onInvitationSent: () => Promise<void>;
  onAccountConnected: () => Promise<void>;
  onDeleteAccount: () => Promise<void>;

  closeWatcher: (onClose: () => void) => () => void;

  hasInvitations: boolean;
  hasConnectedAccounts: boolean;
  isEmpty: boolean;

  isVerified?: boolean;
}

export interface ConnectAccountModalProps {
  onSuccess: () => void;
  onClose: () => void;
  businessId: string;
  title: string;
  visible: boolean;
  merchantType: MerchantType;
}

export type ObjectWithAccountType = {
  accountType?: MerchantType;
  provider?: Merchants | string;
};

export const merchantTypeByClosingStep: {
  [key in MerchantType]: ClosingStepsNames;
} = {
  [MerchantType.revenueSource]: ClosingStepsNames.revenueSources,
  [MerchantType.erp]: ClosingStepsNames.ERPTools,
};
