import { ILoanApi, LoanStatus } from 'lib/types';

export const beforeSendingToEvaluationStatuses = [LoanStatus.missing_info];
export const evaluatingStatuses = [LoanStatus.applied, LoanStatus.analysis];
export const termSheetReadyStatuses = [LoanStatus.termsheet_sent];

export const dueDiligencePreparationStatuses = [
  LoanStatus.termsheet_signed,
  LoanStatus.due_diligence_missing_info,
];

export const dealFlowDueDiligencePreparationStatuses = [
  LoanStatus.termsheet_signed,
  LoanStatus.due_diligence_applied,
  LoanStatus.due_diligence_missing_info,
];

export const onDueDiligenceStatuses = [
  LoanStatus.due_diligence_applied,
  LoanStatus.due_diligence_analysis,
  LoanStatus.contract_sent,
  LoanStatus.contract_signed,
];

export const fundedStatues = [LoanStatus.funded];
export const repaidStatuses = [LoanStatus.repaid];
export const canceledStatuses = [LoanStatus.canceled];

export const terminatedStatuses = [
  LoanStatus.rolled_up,
  LoanStatus.refinanced,
  LoanStatus.taken_over,
];

export const overdueStatuses = [LoanStatus.write_off, LoanStatus.defaulted];

export const useLoanDetailScreenStatus = ({ loan }: { loan: ILoanApi }) => {
  const beforeSendingToEvaluation = beforeSendingToEvaluationStatuses.includes(
    loan.status
  );

  const evaluating = evaluatingStatuses.includes(loan.status);

  const termSheetReady = termSheetReadyStatuses.includes(loan.status);
  const dueDiligencePreparation = dueDiligencePreparationStatuses.includes(
    loan.status
  );

  const onDueDiligence = onDueDiligenceStatuses.includes(loan.status);
  const funded = fundedStatues.includes(loan.status);
  const repaid = repaidStatuses.includes(loan.status);
  const canceled = canceledStatuses.includes(loan.status);
  const terminated = terminatedStatuses.includes(loan.status);
  const overdue = overdueStatuses.includes(loan.status);

  return {
    beforeSendingToEvaluation,
    evaluating,
    termSheetReady,
    dueDiligencePreparation,
    onDueDiligence,
    funded,
    repaid,
    canceled,
    terminated,
    overdue,
  };
};
