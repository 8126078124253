import { Currency, ICategoriesForm, Money } from 'lib/types';

export type { ICategoriesApi, ICategory } from 'lib/types';

export interface IApiFiltersCategory {
  [key: string]: string[];
}

export interface IForSaleFiltersForm extends Partial<ICategoriesForm> {
  currency?: Currency;
  lendingAvailable?: boolean;
  askPriceRange?: number[];
  profitMultiplierRange?: number[];
  profitRange?: number[];
  revenue?: number[];
  brokers?: string[];
  otherBrokers?: boolean;
  availableBrokers?: string;
  favorite?: boolean;
  contacted?: boolean;
  canAfford?: boolean;
  isProprietary?: boolean;
  sortedBy?: ForSaleSortedByEnum;
}

export interface IForSaleFiltersContext {
  onSubmitFilters: (data: IForSaleFiltersForm) => void;
  onResetFilters: () => void;
  defaultFilters: IForSaleFiltersForm;
}

export interface IForSaleFilters {
  onSubmit: (data: IForSaleFiltersForm) => void;
  onReset: () => void;
  defaultValues: IForSaleFiltersForm;
}

export enum ForSaleSortedByEnum {
  Newest = 'newest',
  Relevance = 'relevance',
}

export interface IApiForSaleFilters {
  categories?: IApiFiltersCategory;
  lendingAvailable?: boolean;
  minAskPrice?: Money;
  maxAskPrice?: Money;
  minProfit?: Money;
  maxProfit?: Money;
  minRevenue?: Money;
  maxRevenue?: Money;
  minProfitMultiplier?: number;
  maxProfitMultiplier?: number;
  brokersIn?: string[];
  brokersNotIn?: string[];
  favorite?: boolean;
  contacted?: boolean;
  canAfford?: boolean;
  isProprietary?: boolean;
  sortedBy?: ForSaleSortedByEnum;
}

export interface ForSaleInlineFiltersProps {
  filters: IForSaleFiltersForm;
  onApplyFilter: (filter: Partial<IForSaleFiltersForm>) => void;
}
