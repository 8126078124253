import { formatMoney } from 'lib/format';

import { useQualificationStatus } from './useQualificationStatus';

export const useVerifiedDisposableAmount = () => {
  const { funds } = useQualificationStatus();

  const verifiedDisposableAmount = funds?.verifiedDisposableAmount;

  if (!verifiedDisposableAmount?.amount) {
    return null;
  }

  const verifiedDisposableAmountFormatted = formatMoney(
    verifiedDisposableAmount
  );

  return {
    amount: verifiedDisposableAmount,
    formatted: verifiedDisposableAmountFormatted,
  };
};
