import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Text } from '@boopos/design-system';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Loader } from 'components/FullScreenLoader';
import { Modal } from 'components/Modal';

import { ILoadingModal } from './LoadingModal.interface';
import { LoadingModalIcon, LoadingModalInner } from './LoadingModal.styles';

const LoadingModal: FC<ILoadingModal> = ({
  opened,
  loadingText,
  successText,
  errorText,
  isLoading,
  isSuccess,
  onContinue,
  onCancel,
}) => {
  const { t } = useTranslation();

  const getCurrentText = useMemo(() => {
    if (isLoading)
      return (
        <Text css={{ textAlign: 'center' }} data-testid="text-loading">
          {loadingText}
        </Text>
      );
    if (isSuccess)
      return (
        <Text css={{ textAlign: 'center' }} data-testid="text-success">
          {successText}
        </Text>
      );

    return (
      <Text css={{ textAlign: 'center' }} data-testid="text-error">
        {errorText}
      </Text>
    );
  }, [loadingText, successText, errorText, isLoading, isSuccess]);

  const getCurrentAction = useMemo(() => {
    if (isLoading)
      return (
        <Button
          fullWidth
          onClick={onCancel}
          variant="secondary"
          data-testid="cancel-button"
        >
          {t('LoadingModal:cancel')}
        </Button>
      );
    return (
      <Button fullWidth onClick={onContinue} data-testid="close-button">
        {t('LoadingModal:continue')}
      </Button>
    );
  }, [isLoading, onContinue, onCancel, t]);

  const getStatusIndicator = useMemo(() => {
    if (isLoading) return <Loader />;
    if (isSuccess)
      return (
        <FontAwesomeIcon
          icon={solid('circle-check')}
          data-testid="selected-icon"
          size="2xl"
        />
      );
    return <FontAwesomeIcon icon={solid('circle-x')} size="2xl" />;
  }, [isLoading, isSuccess]);

  return (
    <Modal
      opened={opened}
      allowClose={false}
      maxWidth={350}
      position="centered"
      onClose={() => {}}
    >
      <LoadingModalInner>
        <LoadingModalIcon success={isSuccess}>
          {getStatusIndicator}
        </LoadingModalIcon>
        {getCurrentText}
        {getCurrentAction}
      </LoadingModalInner>
    </Modal>
  );
};

export { LoadingModal };
