import { MerchantType, Merchants } from 'components/AccountConnectors';

import { IBusinessAccount } from 'lib/types';

import { ObjectWithAccountType } from './AccountConnectionModal.interface';

export const filterByAccountType = <T extends ObjectWithAccountType>(
  accountType: MerchantType,
  items?: T[],
  excludedProvider?: Merchants
) =>
  (items ?? [])
    .filter((item) => !excludedProvider || excludedProvider !== item.provider)
    .filter((item) => {
      if (!item.accountType) return true;
      return item.accountType === accountType;
    });

const connectedByMe = (account: IBusinessAccount) =>
  typeof account.connectedByMe === 'undefined' || account.connectedByMe;

export const filterConnectedByMe = (accounts?: IBusinessAccount[]) => {
  return (accounts ?? []).filter(connectedByMe);
};

export const filterConnectedBySeller = (accounts?: IBusinessAccount[]) => {
  return (accounts ?? []).filter((account) => !connectedByMe(account));
};
