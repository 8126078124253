import { generatePath, useParams } from 'react-router-dom';
import useSWR from 'swr';

import api from 'lib/api';
import { IBusinessApi } from 'lib/types';

import { routePaths } from 'router/routePaths';

import { BusinessIntegrations } from './BusinessIntegrations';
import { BusinessIntegrationsEmptyState } from './BusinessIntegrationsEmptyState';

export const BusinessIntegrationsScreen = () => {
  const params = useParams();
  const { businessId } = params || {};

  const { data: business, mutate } = useSWR<IBusinessApi>(
    businessId && api.getPortfolioBusiness(businessId)
  );

  const accounts = business?.accountsConnectedPending;

  const hasIntegrations = !!(
    accounts && Object.values(accounts).flat(1).length
  );

  if (!hasIntegrations) {
    return <BusinessIntegrationsEmptyState businessId={businessId as string} />;
  }

  return (
    <BusinessIntegrations
      businessId={businessId as string}
      accounts={accounts}
      onRemove={mutate}
      addIntegrationPath={generatePath(routePaths.portfolioBusinessAccounts, {
        businessId,
      })}
    />
  );
};
