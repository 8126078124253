import { useTranslation } from 'react-i18next';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ContentCellDetail } from 'ui/ContentCellDetail';

import { useDownloadTermsheet } from 'lib/hooks/useDownloadTermsheet';

export const TermSheetDownload = ({
  loanId,
  title,
  blur,
}: {
  loanId?: string;
  title?: string;
  blur?: boolean;
}) => {
  const { t } = useTranslation();
  const [download, downloading] = useDownloadTermsheet(loanId, title);

  const disabled = blur || downloading || !loanId;

  return (
    <ContentCellDetail.Action
      onClick={blur ? undefined : download}
      disabled={disabled}
      isDisabled={disabled}
      light
      data-testid="download-termsheet"
    >
      {downloading ? (
        <FontAwesomeIcon
          icon={regular('arrow-rotate-right')}
          data-testid="downloading"
          pull="left"
          fixedWidth
          spin
        />
      ) : (
        <FontAwesomeIcon
          icon={regular('file-arrow-down')}
          fixedWidth
          pull="left"
        />
      )}
      {t('TermSheetDetails:see_details')}
    </ContentCellDetail.Action>
  );
};
