import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, ButtonFeedback, Input } from '@boopos/design-system';

import { FilterItem } from 'ui/Filter';

import { GoBackHeader } from './Header';
import { FullScreenFunnelLayout } from './InvestmentCriteriaScreen.styles';
import { useInvestmentCriteriaKeywordsScreen } from './hooks/useInvestmentCriteriaKeywordsScreen';

export const InvestmentCriteriaKeywordsScreen = () => {
  const { t } = useTranslation();

  const {
    apiKeywords,
    customKeywords,
    keywords,
    exitPath,
    loading,
    success,
    isContinueDisabled,
    onSubmit,
    onPresetKeywordClick,
    onRemoveCustomKeyword,
    onInputKeyDown,
  } = useInvestmentCriteriaKeywordsScreen();

  return (
    <>
      <GoBackHeader />
      <FullScreenFunnelLayout.Content>
        <FullScreenFunnelLayout.Steps>
          {t('InvestmentCriteria:steps_number', { from: 4, to: 4 })}
        </FullScreenFunnelLayout.Steps>
        <FullScreenFunnelLayout.Title>
          {t('InvestmentCriteria:keywords_title')}
        </FullScreenFunnelLayout.Title>

        <FullScreenFunnelLayout.Description>
          {t('InvestmentCriteria:keywords_description')}
        </FullScreenFunnelLayout.Description>
        <form
          onSubmit={onSubmit}
          data-testid="investment-criteria-keywords-form"
        >
          <FullScreenFunnelLayout.CloudWords overflow>
            {apiKeywords.map((keyword) => (
              <FilterItem
                type="button"
                key={keyword}
                data-keyword={keyword}
                onClick={onPresetKeywordClick}
                selected={keywords.includes(keyword)}
              >
                {keyword}
              </FilterItem>
            ))}
          </FullScreenFunnelLayout.CloudWords>
          <FullScreenFunnelLayout.InputWrapper>
            <Input
              name="ownKeyword"
              label={t('InvestmentCriteria:own_keyword_label')}
              subtext={t('InvestmentCriteria:own_keyword_hint')}
              onKeyDown={onInputKeyDown}
            />
            {!!customKeywords?.length && (
              <FullScreenFunnelLayout.CloudWords overflow marginLess>
                {customKeywords.map((keyword) => (
                  <FilterItem
                    type="button"
                    key={keyword}
                    selected={true}
                    onClick={onRemoveCustomKeyword}
                    data-keyword={keyword}
                  >
                    {keyword}
                  </FilterItem>
                ))}
              </FullScreenFunnelLayout.CloudWords>
            )}
          </FullScreenFunnelLayout.InputWrapper>

          <FullScreenFunnelLayout.CallToAction>
            <ButtonFeedback
              type="submit"
              fullWidth
              isLoading={loading}
              isSuccess={success}
              disabled={isContinueDisabled}
            >
              {t('InvestmentCriteria:continue')}
            </ButtonFeedback>

            <Button variant="tertiary" fullWidth as={Link} to={exitPath}>
              {t('InvestmentCriteria:leave_for_later')}
            </Button>
          </FullScreenFunnelLayout.CallToAction>
        </form>
      </FullScreenFunnelLayout.Content>
    </>
  );
};
