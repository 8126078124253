import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  ActionSideModal,
  ActionSideModalBackHeader,
  ActionSideModalCloseHeader,
  ActionSideModalCommonStyles,
  ActionSideModalLoading,
  useNavigateFromActionSideModal,
} from 'components/ActionSideModal';
import { LoanSideModalSendReview } from 'components/LoanSideModalSendReview';

import { routePaths } from 'router/routePaths';

import { PersonCard } from './PersonCard';
import { PersonDetailForm } from './PersonDetailForm';
import { useShareholdersAndDirectors } from './ShareholdersAndDirectors.hooks';

export const ShareholdersAndDirectorsModal = () => {
  const params = useParams<{ loanId: string }>();
  const loanId = params.loanId as string;

  const { t } = useTranslation();

  const { onClose } = useNavigateFromActionSideModal(
    {
      loanId,
    },
    { basePath: routePaths.dealFlowDetail }
  );

  const handleSuccess = useCallback(() => {
    onClose();
  }, [onClose]);

  const {
    isDetailView,
    onDetailBack,
    formMethods,
    onSubmit,
    onAddNewPerson,
    onCancelReview,
    onEditPerson,
    people,
    selectedPerson,
    onDeletePerson,
    onSendForReview,
    isSuccess,
    isLoading,
    isWaitingForReview,
    isVerified,
    isReady,
  } = useShareholdersAndDirectors({ loanId, onSuccess: handleSuccess });

  const allowEdit = !isWaitingForReview && !isVerified;

  const ListView = !isDetailView && (
    <>
      <ActionSideModalCommonStyles.Title data-testid="shareholders-and-directors-title">
        {t('ShareholdersAndDirectorsModal:title')}
      </ActionSideModalCommonStyles.Title>
      {people.map((person) => (
        <PersonCard
          key={person.id}
          id={person.id}
          firstName={person.firstName}
          lastName={person.lastName}
          onClick={onEditPerson}
        />
      ))}
      {allowEdit && (
        <Button
          variant="secondary"
          leftIcon={<FontAwesomeIcon icon={regular('user-plus')} size="lg" />}
          onClick={onAddNewPerson}
        >
          {t('ShareholdersAndDirectorsModal:add_new_person')}
        </Button>
      )}
      {isReady && (
        <LoanSideModalSendReview
          isLoading={isLoading}
          isSuccess={isSuccess}
          isWaitingForReview={isWaitingForReview}
          isVerified={isVerified}
          onCancelReview={onCancelReview}
          onSendForReview={onSendForReview}
          sendForReviewDisabled={!people?.length}
        />
      )}
    </>
  );

  const DetailView = isDetailView && (
    <PersonDetailForm
      person={selectedPerson}
      formMethods={formMethods}
      onDeletePerson={onDeletePerson}
      onSubmit={onSubmit}
      allowEdit={allowEdit}
      loanId={loanId}
    />
  );

  return (
    <ActionSideModal
      header={
        isDetailView ? (
          <ActionSideModalBackHeader onBack={onDetailBack} />
        ) : (
          <ActionSideModalCloseHeader onClose={onClose} />
        )
      }
      onClose={onClose}
    >
      {ListView}
      {DetailView}
      {!isReady && <ActionSideModalLoading />}
    </ActionSideModal>
  );
};
