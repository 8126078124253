import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input } from '@boopos/design-system';

import { IAcquiringLegalEntityData } from 'components/AcquiringLegalEntityModal/AcquiringLegalEntity.interface';
import { CountrySelect } from 'components/CountrySelect';
import { FormFilesUploader } from 'components/FormFilesUploader';
import { USStateSelect } from 'components/USStateSelect';

import { getInputErrorProps } from 'lib/forms';

import { CityField } from './CityField';
import { FormStyles } from './FormStyles';

interface Props {
  allFieldsDisabled?: boolean;
  loanId: string;
}

export const AcquiringLegalEntityFormFields = ({
  allFieldsDisabled,
  loanId,
}: Props) => {
  const { t } = useTranslation();

  const { register, formState, control, watch, setValue } =
    useFormContext<IAcquiringLegalEntityData>();

  const country = watch('country');

  useEffect(() => {
    if (country !== 'US') {
      setValue('state', '');
    }
  }, [country, setValue]);

  return (
    <>
      <Input
        label={t('AcquiringLegalEntityForm:legal_name')}
        disabled={allFieldsDisabled}
        id="legal-entity-name"
        {...register('name', {
          required: true,
        })}
        {...getInputErrorProps(formState.errors?.name)}
      />
      <Input
        label={t('AcquiringLegalEntityForm:tax_id_name')}
        disabled={allFieldsDisabled}
        {...register('taxId', {
          required: true,
        })}
        {...getInputErrorProps(formState.errors?.taxId)}
      />
      <Input
        label={t('AcquiringLegalEntityForm:street_name')}
        disabled={allFieldsDisabled}
        {...register('street', {
          required: true,
        })}
        {...getInputErrorProps(formState.errors?.street)}
      />
      <CountrySelect
        name="country"
        inputId="legal-entity-country"
        disabled={allFieldsDisabled}
        control={control}
        rules={{ required: true }}
      />
      <FormStyles.Split>
        {country === 'US' ? (
          <USStateSelect
            name="state"
            inputId="legal-entity-state"
            disabled={allFieldsDisabled}
            control={control}
            rules={{ required: true }}
            {...getInputErrorProps(formState.errors?.state)}
          />
        ) : (
          <CityField
            register={register}
            formState={formState}
            disabled={allFieldsDisabled}
          />
        )}
        <Input
          label={t('AcquiringLegalEntityForm:zipcode_name')}
          disabled={allFieldsDisabled}
          {...register('zipCode', {
            required: true,
          })}
          {...getInputErrorProps(formState.errors?.zipCode)}
        />
      </FormStyles.Split>
      {country === 'US' ? (
        <CityField
          register={register}
          formState={formState}
          disabled={allFieldsDisabled}
        />
      ) : null}

      <FormStyles.UploaderWrapper>
        <FormFilesUploader
          title={t('AcquiringLegalEntityForm:ein_name')}
          control={control}
          name="einConfirmationLetterFiles"
          maxFiles={1}
          disabled={allFieldsDisabled}
          uploadParams={{ loanId }}
        />
      </FormStyles.UploaderWrapper>
      <FormStyles.UploaderWrapper>
        <FormFilesUploader
          title={t('AcquiringLegalEntityForm:articles_name')}
          control={control}
          name="articlesOfIncorporationFiles"
          maxFiles={5}
          disabled={allFieldsDisabled}
          uploadParams={{ loanId }}
        />
      </FormStyles.UploaderWrapper>
    </>
  );
};
