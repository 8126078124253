import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { ParsedPlaceToAddress } from './AddressAutocomplete.interface';

const options = { shouldValidate: true };

export const useOnPlaceSelected = () => {
  const formMethods = useFormContext();
  const { setValue } = formMethods;

  return useCallback(
    (parsedPlace: ParsedPlaceToAddress) => {
      setValue('address.state', parsedPlace.state, options);
      setValue('address.street', parsedPlace.street, options);
      setValue('address.zipCode', parsedPlace.zipCode, options);
      setValue('address.city', parsedPlace.city, options);
    },
    [setValue]
  );
};
