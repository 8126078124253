import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import {
  OnboardingHeader,
  OnboardingLayout,
} from 'components/OnboardingLayout';

import { useLandingScreenMemo } from 'providers/FirstAppScreenDecider/LandingScreenMemo';

import { QualificationProvideLaterModal } from './QualificationProvideLaterModal';
import { IQualifyScreenWrapper } from './QualifyScreenWrapper.interface';

export const QualifyScreenWrapper = ({
  title,
  subtitle,
  step = 1,
  totalSteps = 1,
  prevScreen,
  allowSkip = true,
  disabled = false,
  children,
  ...props
}: IQualifyScreenWrapper) => {
  const { t } = useTranslation();
  const [provideLaterModalOpened, setProvideLaterModalOpened] = useState(false);
  const navigate = useNavigate();

  const landingUrl = useLandingScreenMemo();

  const closeConfirmation = useCallback(() => {
    setProvideLaterModalOpened(false);
  }, []);

  const openConfirmation = useCallback(() => {
    setProvideLaterModalOpened(true);
  }, []);

  const onLeaveForLater = useCallback(() => {
    navigate(landingUrl);
  }, [navigate, landingUrl]);

  const tabTitle = `${step}/${totalSteps} - ${title}`;

  const goBackProps = {
    as: Link,
    to: prevScreen ?? landingUrl,
    replace: true,
  };

  return (
    <>
      <Helmet>
        <title>{tabTitle}</title>
      </Helmet>
      <OnboardingLayout.Wrapper {...props}>
        <OnboardingHeader.Wrapper>
          <OnboardingHeader.ActionsWrapper>
            <OnboardingHeader.Action {...(goBackProps ?? {})}>
              {!!goBackProps && <OnboardingHeader.BackArrow />}
            </OnboardingHeader.Action>
            {!!allowSkip && (
              <OnboardingHeader.Action
                as="a"
                data-testid="skip-button"
                onClick={openConfirmation}
                alignment="right"
              >
                <OnboardingHeader.CloseXMark />
              </OnboardingHeader.Action>
            )}
          </OnboardingHeader.ActionsWrapper>
          <OnboardingHeader.Steps data-testid="steps">
            {t('QualifyScreenWrapper:step_counter', {
              step,
              totalSteps,
            })}
          </OnboardingHeader.Steps>
          <OnboardingHeader.Title>{title}</OnboardingHeader.Title>
          {!!subtitle && (
            <OnboardingHeader.Subtitle>{subtitle}</OnboardingHeader.Subtitle>
          )}
        </OnboardingHeader.Wrapper>
        <OnboardingLayout.Content>
          <OnboardingLayout.ContentWrapper>
            {children}
          </OnboardingLayout.ContentWrapper>
        </OnboardingLayout.Content>
      </OnboardingLayout.Wrapper>
      <QualificationProvideLaterModal
        opened={provideLaterModalOpened}
        onClose={closeConfirmation}
        onLeaveForLater={onLeaveForLater}
      />
    </>
  );
};
