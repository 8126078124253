import React from 'react';
import { useTranslation } from 'react-i18next';

import { DealTimelineStep } from 'components/DealTimelineStep';

import { ListingForSale, ListingSellerType } from 'lib/types';

import { shouldShowDealUnlocked } from './DealUnlocked';

export const shouldShowRequestedFullDetails = (listing: ListingForSale) => {
  return !!listing?.dealApplication?.requestedAt;
};

export const RequestedFullDetails = ({
  listing,
  showDescription,
}: {
  listing: ListingForSale;
  showDescription?: boolean;
}) => {
  const { t } = useTranslation();

  if (!shouldShowRequestedFullDetails(listing)) {
    return null;
  }

  const copies = listing?.sellerType
    ? {
        [ListingSellerType.broker]: {
          title: t('ForSaleTimeline:full_details_requested_title'),
          description: t('ForSaleTimeline:full_details_requested_content'),
        },

        [ListingSellerType.proprietary]: {
          title: t('ForSaleTimeline:full_details_requested_title_proprietary'),
          description: t(
            'ForSaleTimeline:full_details_requested_content_proprietary'
          ),
        },
      }[listing?.sellerType]
    : { title: '', description: '' };

  return (
    <DealTimelineStep
      title={copies.title}
      date={listing.dealApplication?.requestedAt}
      data-testid="full-details-requested"
      forceNowVariant={!shouldShowDealUnlocked(listing)}
    >
      {showDescription && copies.description}
    </DealTimelineStep>
  );
};
