import { PropsWithChildren } from 'react';

import { TimelineStyles } from './Timeline.styles';

interface StepSubtitleProps {}

export const StepSubtitle = ({
  children,
}: PropsWithChildren<StepSubtitleProps>) => (
  <TimelineStyles.Subtitle>{children}</TimelineStyles.Subtitle>
);
