import { styled } from '@boopos/design-system';

import { ContentCellDetail } from 'ui/ContentCellDetail';

export const TermSheetLayout = {
  Wrapper: styled('div', {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gap: '$4',
    '@bp2': {
      gridTemplateColumns: 'repeat(5, minmax(min-content, 1fr))',
    },
    variants: {
      wrap: {
        true: {
          '@bp2': {
            gridTemplateColumns: 'repeat(4, minmax(min-content, 1fr))',
          },
        },
      },
    },
  }),
  Column: styled('div', {
    py: '$2',
    variants: {
      bordered: {
        true: {
          '@bp2': {
            borderRight: '1px solid $slate300',
          },
        },
      },
      blur: {
        true: {
          [`& ${ContentCellDetail.Value}, & ${ContentCellDetail.Action}`]: {
            userSelect: 'none',
            pointer: 'default',
            filter: 'blur(7px)',
          },
        },
      },
    },
  }),
};
