import {
  ParsedPlaceToAddress,
  PlainPlace,
} from './AddressAutocomplete.interface';

export const plainAddressComponents = (
  addressComponents?: google.maps.GeocoderAddressComponent[]
): PlainPlace | undefined => {
  return addressComponents?.reduce?.((acc, component) => {
    if (!component.types?.length) {
      return acc;
    }
    return {
      ...acc,
      [component.types[0]]: {
        shortName: component.short_name,
        longName: component.long_name,
      },
    };
  }, {});
};

export const getStreet = (plainPlace: PlainPlace): string => {
  const streetNumber = plainPlace?.street_number?.shortName;
  const route = plainPlace?.route?.longName;

  return `${streetNumber ?? ''} ${route ?? ''}`.trim();
};

export const parsePlaceToAddress = (
  place: google.maps.places.PlaceResult
): ParsedPlaceToAddress | undefined => {
  const plainPlace = plainAddressComponents(place?.address_components);

  if (!plainPlace) {
    return undefined;
  }

  const state = plainPlace?.administrative_area_level_1?.shortName ?? '';
  const street = getStreet(plainPlace);

  const zipCode = plainPlace?.postal_code?.shortName ?? '';
  const city = plainPlace?.locality?.longName ?? '';

  return {
    state,
    street,
    zipCode,
    city,
  };
};
