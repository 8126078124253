import { useMatch, useParams } from 'react-router-dom';

import { generatePathSafe } from 'lib/utils/generatePathSafe';

import { routePaths } from 'router/routePaths';

import { GENERIC_OTHER, useRollingFlow } from './useRollingFlow';

export const useNextPath = () => {
  const { businessTypeId } = useParams<{ businessTypeId: string }>();
  const { rollTroughIds } = useRollingFlow();

  const indexScreen = useMatch(routePaths.investmentCriteria);
  const businessTypeScreen = useMatch(
    routePaths.investmentCriteriaBusinessType
  );
  const priceScreen = useMatch(routePaths.investmentCriteriaPrice);
  const profitScreen = useMatch(routePaths.investmentCriteriaProfit);
  const keywordsScreen = useMatch(routePaths.investmentCriteriaKeywords);
  const businessTypeOther = useMatch(
    routePaths.investmentCriteriaBusinessTypeOther
  );

  if (indexScreen) {
    const [firstSelected] = rollTroughIds;

    if (firstSelected) {
      return generatePathSafe(routePaths.investmentCriteriaBusinessType, {
        businessTypeId: firstSelected,
      });
    }

    return routePaths.investmentCriteriaPrice;
  }

  if ((businessTypeId || businessTypeOther) && businessTypeScreen) {
    const findableId = businessTypeOther ? GENERIC_OTHER : businessTypeId;
    const nextId = rollTroughIds.findIndex((id) => findableId === id);
    const navigateToPath = generatePathSafe(
      routePaths.investmentCriteriaBusinessType,
      {
        businessTypeId: rollTroughIds[nextId + 1],
      }
    );

    if (!navigateToPath) {
      return routePaths.investmentCriteriaPrice;
    }

    return navigateToPath;
  }

  if (priceScreen) {
    return routePaths.investmentCriteriaProfit;
  }

  if (profitScreen) {
    return routePaths.investmentCriteriaKeywords;
  }

  if (keywordsScreen) {
    return routePaths.investmentCriteriaSuccess;
  }

  return null;
};
