import * as Sentry from '@sentry/react';

import {
  IQualificationStatus,
  IQualificationStatusCreditReport,
} from 'components/QualificationStatus';

import api from 'lib/api';
import { PrivacySSNApi } from 'lib/privacy-api';

class CreditReportApi {
  privacySSNApi: PrivacySSNApi;

  constructor() {
    this.privacySSNApi = new PrivacySSNApi();
  }

  private async readFromPiiano(): Promise<{ ssn?: string } | undefined> {
    const ssn = await this.privacySSNApi.getSSN();
    return { ssn };
  }

  private async getPrivacyDataSafe() {
    try {
      const response = await this.readFromPiiano();
      return { ssn: response?.ssn };
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }

  private async getCreditReport() {
    const { data: qualificationData } =
      await api.http.get<IQualificationStatus>(api.getQualification());
    const { creditReport = {} } = qualificationData ?? {};
    return creditReport;
  }

  async get() {
    const creditReport = await this.getCreditReport();
    const privacyData = await this.getPrivacyDataSafe();

    return { ...creditReport, ssn: privacyData?.ssn };
  }

  async save(creditReport: IQualificationStatusCreditReport) {
    const { ssn, ...rest } = creditReport;

    return await api.updateQualificationStatusCreditReport(rest);
  }

  async saveSSN(ssn?: string) {
    try {
      return await Promise.all([this.privacySSNApi.updateSSN({ ssn })]);
    } catch (err) {
      console.error('Error saving SSN', err);
      Sentry.captureException(err);
    }
  }
}

const creditReportInstance = new CreditReportApi();

export default creditReportInstance;
