import { Navigate } from 'react-router-dom';

import { useEffectOnce } from 'lib/hooks/useEffectOnce';
import { useTrackUserGoals } from 'lib/hooks/useTrackUserGoals';
import { noop } from 'lib/utils/noop';

import { routePaths } from 'router/routePaths';

import { useOnboardingState } from 'screens/LandingScreens/useOnboardingState';

export const SellBusinessLanding = () => {
  const { trackSellerGoal } = useTrackUserGoals();

  useEffectOnce(() => {
    trackSellerGoal().then(noop).catch(noop);
  });

  const onboardingState = useOnboardingState();

  return (
    <Navigate
      to={routePaths.onboardingProfileSell}
      state={onboardingState}
      replace
    />
  );
};
