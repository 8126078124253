import { ForSaleRedirectionHandler } from 'components/RedirectionHandler/ForSaleRedirectionHandler';

import { routePaths } from 'router/routePaths';

export const listingsRoutes = {
  path: routePaths.listings,
  element: <ForSaleRedirectionHandler />,
  children: [
    {
      path: '*',
      element: <ForSaleRedirectionHandler />,
    },
  ],
};
