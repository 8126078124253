import { useTranslation } from 'react-i18next';

import { formatDate } from '@boopos/utils';

import { SellApplicationStatus } from 'lib/types';

import { ListingStatusDateProps } from './ListingStatusDate.inteface';

const format = 'MMM Do';

export const useListingStatusDate = ({
  sellApplication,
}: ListingStatusDateProps) => {
  const { t } = useTranslation();
  if (!sellApplication) return null;

  if (
    [
      SellApplicationStatus.ready_to_publish,
      SellApplicationStatus.rejected,
    ].includes(sellApplication.status) &&
    !!sellApplication.reviewedAt
  ) {
    return t('ForSaleDetailApplication:reviewed_on', {
      date: formatDate(sellApplication.reviewedAt, format),
    });
  }

  if (
    [SellApplicationStatus.published].includes(sellApplication.status) &&
    !!sellApplication.listedAt
  ) {
    return t('ForSaleDetailApplication:listed_on', {
      date: formatDate(sellApplication.listedAt, format),
    });
  }

  return t('ForSaleDetailApplication:created_on', {
    date: formatDate(sellApplication.createdAt, format),
  });
};
